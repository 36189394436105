import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSearchConfiguration, CloudApiConfigService, SearchFilter, SearchFilterResult, AuthService } from '@razberi-ui/api/cloud-api';
import { Account, AccountSubscriptionStatus, AccountSubscriptionType, AccountType } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageSidePanelService, UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { AccountService } from '../../../services/account.service';
import { AccountPanelToggleEvent } from '../../../data-types/account-panel-toggle-event.model';
import { UnitGlobalSearchModalComponent } from '../../units/unit-global-search-modal/unit-global-search-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';

export enum ViewMode {
	Alerts = 0,
	Tasks = 1,
}

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-accordian',
	templateUrl: './account-accordian.component.html',
	styleUrls: ['./account-accordian.component.scss'],
})
export class AccountAccordianComponent {
	@Input() panelTemplate: TemplateRef<any>;
	@Input() accountIds: number[];
	@Input() showRefresh: boolean = true;
	@Input() showSerialNumberSearch: boolean = false;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() requireSubscription: boolean = false;
	@Input() refresh$: Observable<void>;
	@Input() dataSource: string;
	@Input() emptyResultMessage: string;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();
	@Output() panelToggleEvent: EventEmitter<AccountPanelToggleEvent> = new EventEmitter<AccountPanelToggleEvent>();

	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfig: DataSearchConfiguration;
	accountsResult: SearchFilterResult<Account>;
	pageNumber: number = 1;
	pageSize: number = 10;
	isGlobal: boolean = false;
	expandedAccountIds: string[] = [];
	subscriptions: Subscription = new Subscription();
	isInitialized: boolean = false;
	viewModeRef: any = ViewMode;
	viewModeSelected: ViewMode = ViewMode.Alerts;

	get pagingStatus() {
		return this.utils.helpers.getPagingStatus(this.pageNumber, this.pageSize, this.accountsResult.totalCount);
	}

	constructor(
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly accountService: AccountService,
		private readonly monitorCloudApiConfigService: CloudApiConfigService,
		private readonly pageSidePanelService: PageSidePanelService,
		private readonly utils: UtilitiesService
	) {}

	ngOnInit() {
		this.searchFilter = { ...this.initialSearchFilter };
		this.isGlobal = this.authService.data.account.type === AccountType.Global;
		this.searchConfig = { showSearchTerm: true, showAccounts: true, forSubscribedAccounts: this.requireSubscription };
		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getAccounts();
				})
			);
		if (this.dataSource?.toLowerCase() === 'manage') this.viewModeSelected = ViewMode.Tasks;
		else this.viewModeSelected = ViewMode.Alerts;

		this.getAccounts();
	}

	getAccounts() {
		const subFilter: SearchFilter = {};
		if (!this.isGlobal && this.requireSubscription) {
			subFilter.accountSubscriptionTypes = [AccountSubscriptionType.Trial, AccountSubscriptionType.Monitor, AccountSubscriptionType.Manage];
			if (this.viewModeSelected === ViewMode.Tasks)
				subFilter.accountSubscriptionTypes = subFilter.accountSubscriptionTypes.filter((t) => t !== AccountSubscriptionType.Monitor);
			subFilter.accountSubscriptionStatuses = [AccountSubscriptionStatus.Active, AccountSubscriptionStatus.Expiring];
		}

		const filter = {
			pageNumber: this.pageNumber,
			pageSize: this.pageSize,
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			sortAsc: true,
			...subFilter,
			...this.searchFilter,
		};

		const taskFilter = { ...filter };
		taskFilter.pageSize = 10000; // ensure enough results are returned for all accounts

		forkJoin({
			accountData: this.accountService.api.getAccountsFilter(filter),
		}).subscribe({
			next: (result) => {
				this.accountsResult = result.accountData;
				this.pageNumber = result.accountData.pageNumber;
				this.pageSize = result.accountData.pageSize;
				this.isInitialized = true;
			},
			error: (error) => {
				this.alertMessageService.error('Error getting accounts.', error);
			},
		});
	}

	getAccountDetails(account: Account, activeTabKey: string, requireSubscription: boolean) {
		const hasSubscription = this.hasSubscription(account);
		if (requireSubscription === true && hasSubscription !== true) {
			this.subscriptionService.helpers.showInvalidSubscriptionModal();
			return;
		}

		this.pageSidePanelService.helpers.openAccountSidePanel(account.name, activeTabKey, account.accountId);
	}

	onUpdate() {
		if (this.refresh$) this.update.emit();
		else this.getAccounts();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getAccounts();
	}

	onPageChange(pageNumber: number) {
		this.pageNumber = pageNumber;
		this.getAccounts();
	}

	onPageSizeChange(pageSize: number) {
		this.pageSize = pageSize;
		this.getAccounts();
	}

	onPanelChange(event) {
		if (event) {
			let panelExpanded = false;
			if (this.expandedAccountIds.find((l) => l === event.panelId)) {
				this.expandedAccountIds = this.expandedAccountIds.filter((l) => l !== event.panelId);
			} else {
				this.expandedAccountIds.push(event.panelId);
				this.expandedAccountIds = this.expandedAccountIds.sort();
				panelExpanded = true;
			}

			this.panelToggleEvent.emit({ isExpanded: panelExpanded, account: this.accountsResult.results?.find((a) => a.accountId == event.panelId) });
		}
	}

	preventRowToggle(event) {
		event.stopPropagation();
	}

	hasSubscription(account: Account): boolean {
		return this.subscriptionService.helpers.isGlobalOrIsTypeAndStatusValid(account.subscriptionType, account.subscriptionStatus);
	}

	showSerialSearch() {
		const modal = this.modalService.open(UnitGlobalSearchModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
	}

	getImageUrl(path: string): string {
		return `${this.monitorCloudApiConfigService.data.config.apiHost}${path}`;
	}
}
