import { Component } from '@angular/core';

@Component({
	selector: 'app-root-monitor-cloud-dashboard-outlet-vms-health',
	templateUrl: './dashboard-outlet-vms-health.component.html',
	styleUrls: ['./dashboard-outlet-vms-health.component.scss'],
})
export class DashboardOutletVmsHealthComponent {
	alertTypeIds: number[] = [26, 27, 28, 29, 30, 31];

	constructor() {}
}
