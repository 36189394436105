import { Injectable } from '@angular/core';
import { AlertNotificationTest, CloudApiService } from '@razberi-ui/api/cloud-api';
import { AlertProfile } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AlertProfileService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAlertProfiles: (accountId: number): Observable<AlertProfile[]> => {
				return this.cloudApiService.alertProfiles.getAlertProfiles(accountId);
			},
			getAlertProfile: (accountId: number, alertProfileId: number): Observable<AlertProfile> => {
				return this.cloudApiService.alertProfiles.getAlertProfile(accountId, alertProfileId);
			},
			createAlertProfile: (accountId: number, alertProfileDto: AlertProfile): Observable<AlertProfile> => {
				return this.cloudApiService.alertProfiles.createAlertProfile(accountId, alertProfileDto);
			},
			updateAlertProfile: (accountId: number, alertProfileId: number, alertProfileDto: AlertProfile): Observable<AlertProfile> => {
				return this.cloudApiService.alertProfiles.updateAlertProfile(accountId, alertProfileId, alertProfileDto);
			},
			deleteAlertProfile: (accountId: number, alertProfileId: number): Observable<any> => {
				return this.cloudApiService.alertProfiles.deleteAlertProfile(accountId, alertProfileId);
			},
			deleteAlertProfiles: (accountId: number, alertProfileIds: number[]): Observable<void> => {
				return this.cloudApiService.alertProfiles.deleteAlertProfiles(accountId, alertProfileIds);
			},
			sendTest: (testDetailsDto: AlertNotificationTest) => {
				return this.cloudApiService.alertProfiles.sendTest(testDetailsDto);
			},
			sendTestById: (accountId: number, alertProfileId: number): Observable<any> => {
				return this.cloudApiService.alertProfiles.sendTestById(accountId, alertProfileId);
			},
		};
	}
}
