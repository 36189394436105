import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
	DataSearchConfiguration,
	CloudApiConfigService,
	CoreServerSettingsService,
	SearchFilter,
	SearchFilterResult,
	AuthService,
} from '@razberi-ui/api/cloud-api';
import { Account, AccountStatus, AccountType, Alert, AlertStatus, Network, Unit } from '@razberi-ui/core/data-types';
import {
	AlertMessageService,
	ConfirmModalConfiguration,
	ConfirmService,
	DateRange,
	MultiSelectType,
	PageSidePanelService,
	TableItemSelectMode,
	TableServerSidePagingConfig,
	TableSettings,
} from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged, from, groupBy, mergeMap, of, zip } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import * as moment from 'moment';
import { DeviceEditModalComponent } from '../../devices/device-edit-modal/device-edit-modal.component';
import { UnitService } from '../../../services/unit.service';
import { DashboardService } from '../../../services/dashboard.service';
import { ReportService } from '../../../services/report.service';
import { HttpResponse } from '@angular/common/http';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-alert-table',
	templateUrl: './alert-table.component.html',
	styleUrls: ['./alert-table.component.scss'],
})
export class AlertTableComponent {
	@Input() accountIds: number[];
	@Input() alertTypeIds: number[];
	@Input() dateRange: DateRange;
	@Input() getClosed: boolean = false;
	@Input() showAccount: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() alertsData: Alert[];
	@Input() network: Network;
	@Input() dataSource: string;
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	alerts: Alert[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isDataSourceReady: boolean = false;
	isInitialized: boolean = false;

	constructor(
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly alertsService: AlertService,
		private readonly reportService: ReportService,
		private readonly dashboardService: DashboardService,
		private readonly configService: CloudApiConfigService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly sidePanelService: PageSidePanelService,
		private readonly confirmService: ConfirmService,
		private readonly unitService: UnitService
	) {}

	ngOnInit() {
		if (this.dataSource?.startsWith('dashboard')) {
			this.subscriptions.add(
				this.dashboardService.streams.selectedAccounts$.pipe(distinctUntilChanged()).subscribe((accounts: Account[]) => {
					if (accounts) {
						this.accountIds = accounts.map((account: Account) => account.accountId);
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getAlerts();
					}
				})
			);
			this.subscriptions.add(
				this.dashboardService.streams.selectedDateRange$.pipe(distinctUntilChanged()).subscribe((dateRange: DateRange) => {
					if (dateRange) {
						this.dateRange = dateRange;
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getAlerts();
					}
				})
			);
		} else if (this.dataSource?.startsWith('network-detail')) {
			this.isDataSourceReady = this.network?.networkId > 0 && this.accountIds?.length == 1;
		} else {
			this.initialSearchFilter = { startTimestamp: moment().startOf('day').subtract(30, 'days').toDate() };
			if (
				!this.accountIds?.length &&
				this.authService.data.account.type === AccountType.Global &&
				this.settingsService.data.settings?.cloudUI?.preloadSearchFilterAccounts === true
			)
				this.initialSearchFilter.accounts = [1, 2, 3, 4, 5].map((accountId: number) => {
					return { accountId: accountId };
				});
		}
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showDateRange: !this.dataSource,
			showAccounts: this.showAccount === true && this.dataSource?.startsWith('dashboard') !== true,
			showLocations: true,
			showTags: true,
			showAlertTypes: true,
			showAlertStatuses: this.getClosed !== true,
			forSubscribedAccounts: true,
		};

		const ts: TableSettings = {
			itemSelectMode: this.getClosed === true ? TableItemSelectMode.None : TableItemSelectMode.Multi,
			allowSelectAll: true,
			useAdvancedSearch: this.showSearch,
			useEdit: true,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.alertsData ? false : true,
			serverSidePagingAndFilterConfig: this.alertsData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'alertId',
			columnConfig: [
				{
					primaryKey: 'timestamp',
					header: 'Timestamp',
					useForSearch: true,
					width: '220px',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return { text: moment(value).format('l LTS'), icon: 'clock' };
					},
				},
				{
					primaryKey: 'unitName',
					header: 'Device',
					cellType: 'link-button',
					transform: (value) => {
						return !value ? { text: '--', disabled: true } : { text: value, icon: 'server' };
					},
					clickHandlerFunction: (params) => {
						this.sidePanelService.helpers.openUnitSidePanel(
							params.name,
							'details',
							params.accountId,
							params.unitId,
							this.unitService.helpers.userCanEditUnit(),
							(device) => {
								this.editDevice(device);
							}
						);
					},
				},
				{
					primaryKey: 'accountName',
					header: 'Account',
					cellType: 'router-link',
					transform: (value, tableItem) => {
						if (value) {
							const c: any = {
								text: value,
								route: ['/accounts', tableItem.accountId],
								subtexts: [],
								disabled: tableItem.accountStatus === AccountStatus.Pending,
							};
							if (tableItem.accountFaviconUrl) c.image = `${this.configService.data.config.apiHost}${tableItem.accountFaviconUrl}`;
							else c.icon = 'building';
							return c;
						} else return { text: '--', disabled: true };
					},
				},
				{
					primaryKey: 'locationName',
					header: 'Location',
					cellType: 'icon',
					transform: (value) => {
						return !value ? { text: '--' } : { text: value, icon: 'map-marker-alt' };
					},
				},
				{ primaryKey: 'alertTypeName', header: 'Alert Type' },
				{ primaryKey: 'message', header: 'Alert Description', width: '400px', cellType: 'alert-message' },
				{ primaryKey: 'status', header: 'Status', cellType: 'alert-status' },
			],
			headerButtons: [
				{
					label: 'Close',
					faIcon: 'bell-slash',
					clickHandlerFunction: (selected, excluded, multiSelectType, selectedCount) => {
						const config: ConfirmModalConfiguration = {
							title: 'Confirm Alert Close',
							html: `<p>${selectedCount} alerts have been selected and will be closed.</p><p>Would you like to continue?</p>`,
						};
						this.confirmService
							.confirm(config)
							.then((result) => {
								if (multiSelectType === MultiSelectType.All) {
									this.closeAlertsFilter(excluded?.value);
								} else {
									this.closeAlertItems(selected.value);
								}
							})
							.finally(() => {});
					},
				},
				{
					label: 'Acknowledge',
					faIcon: 'eye',
					clickHandlerFunction: (selected, excluded, multiSelectType, selectedCount) => {
						const config: ConfirmModalConfiguration = {
							title: 'Confirm Alert Acknowledge',
							html: `<p>${selectedCount} alerts have been selected and will be acknowledged.</p><p>Would you like to continue?</p>`,
						};
						this.confirmService
							.confirm(config)
							.then((result) => {
								if (multiSelectType === MultiSelectType.All) {
									this.acknowledgeAlertsFilter(excluded?.value);
								} else {
									this.acknowledgeAlertItems(selected.value);
								}
							})
							.finally(() => {});
					},
				},
			],
			stickyHeaderButtons: [],
			editActions: (tableItem) => {
				const actions = [
					{
						label: 'View Device Details',
						faIcon: 'info-circle',
						clickHandlerFunction: (params) => {
							this.sidePanelService.helpers.openUnitSidePanel(
								params.name,
								'details',
								params.accountId,
								params.unitId,
								this.unitService.helpers.userCanEditUnit(),
								(device) => {
									this.editDevice(device);
								}
							);
						},
						enabled: tableItem.accountId > 0 && tableItem.unitId > 0,
					},
				];

				if (this.getClosed !== true) {
					actions.push({
						label: 'Acknowledge Alert',
						faIcon: 'eye',
						clickHandlerFunction: (params) => {
							this.acknowledgeAlertItems([params]);
						},
						enabled: true,
					});
					actions.push({
						label: 'Close Alert',
						faIcon: 'bell-slash',
						clickHandlerFunction: (params) => {
							this.closeAlertItems([params]);
						},
						enabled: true,
					});
				}

				return actions;
			},
		};
		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getAlerts();
				},
			});
		if (
			!this.showAccount === true ||
			(this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)
		) {
			const accountNameIdx = ts.columnConfig.findIndex((col) => col.primaryKey === 'accountName');
			if (accountNameIdx >= 0) ts.columnConfig.splice(accountNameIdx, 1);
		}
		this.tableSettings = ts;

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getAlerts();
				})
			);

		this.getAlerts();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized) this.getAlerts();
	}

	getAlerts() {
		if (this.alertsData) {
			this.alerts = [...this.alertsData];
			this.isInitialized = true;
		} else {
			if (!this.dataSource || this.isDataSourceReady) {
				const filter: SearchFilter = this.getFilter();
				filter.pageNumber = this.pageNumber;
				filter.pageSize = this.pageSize;

				let o: Observable<SearchFilterResult<Alert>>;
				if (this.dataSource === 'network-detail') {
					if ((this.network?.networkId || 0) == 0) {
						this.alertMessageService.warning('The network provided for the alerts is not valid');
						this.alerts = [];
						this.isInitialized = true;
						return;
					}
					o = this.alertsService.api.getNetworkAlerts(
						this.network.accountId,
						this.network.networkId,
						this.network.managedDevices?.map((md) => md.managedDeviceKey),
						filter,
						30
					);
				} else {
					o = this.alertsService.api.getAlerts(filter);
				}

				o.subscribe((result) => {
					let { pageNumber, pageSize, totalCount, results } = result;
					this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber, pageSize, totalCount };
					this.alerts = results;
					this.isInitialized = true;
				});
			}
		}
	}

	editDevice(device: Unit) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = device.accountId;
		modal.componentInstance.unitId = device.unitId;
		modal.result.then(
			(_) => {
				this.getAlerts();
			},
			(_) => {}
		);
	}

	acknowledgeAlertItems(alerts: Alert[]) {
		from(alerts)
			.pipe(
				groupBy(
					(a) => a.accountId,
					(a) => a.alertId
				),
				mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
				mergeMap((group) => this.alertsService.api.acknowledgeAlerts(group[0], group[1])),
				toArray()
			)
			.subscribe({
				next: (result) => {
					this.alertMessageService.success('Acknowledged selected alerts.');
					this.getAlerts();
				},
				error: (error) => {
					this.alertMessageService.error('Error acknowledging alerts.', error);
				},
			});
	}

	acknowledgeAlertsFilter(excluded: Alert[]) {
		const filter = this.getFilter();
		filter.excludedObjectIds = excluded?.map((alert) => {
			return alert.alertId;
		});
		this.alertsService.api.acknowledgeAlertsFilter(filter).subscribe({
			next: (result) => {
				if (result > 0) {
					this.alertMessageService.success(`Acknowledged ${result} alerts.`);
				} else {
					this.alertMessageService.warning(`No alerts were acknowledged.`);
				}
				this.getAlerts();
			},
			error: (error) => {
				this.alertMessageService.error('Error acknowledging alerts.', error);
			},
		});
	}

	closeAlertItems(alerts: Alert[]) {
		from(alerts)
			.pipe(
				groupBy(
					(a) => a.accountId,
					(a) => a.alertId
				),
				mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
				mergeMap((group) => this.alertsService.api.closeAlerts(group[0], group[1])),
				toArray()
			)
			.subscribe({
				next: (result) => {
					this.alertMessageService.success('Closed selected alerts.');
					this.getAlerts();
				},
				error: (error) => {
					this.alertMessageService.error('Error closing alerts.', error);
				},
			});
	}

	closeAlertsFilter(excluded: Alert[]) {
		const filter = this.getFilter();
		filter.excludedObjectIds = excluded?.map((alert) => {
			return alert.alertId;
		});
		this.alertsService.api.closeAlertsFilter(filter).subscribe({
			next: (result) => {
				if (result > 0) {
					this.alertMessageService.success(`Closed ${result} alerts.`);
				} else {
					this.alertMessageService.warning(`No alerts were closed.`);
				}

				this.getAlerts();
			},
			error: (error) => {
				this.alertMessageService.error('Error closing alerts.', error);
			},
		});
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getAlerts();
		this.dashboardService.helpers.refresh();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getAlerts();
		this.dashboardService.helpers.refresh();
	}

	getFilter(): SearchFilter {
		const filter: SearchFilter = {
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			alertStatuses: this.getClosed ? [AlertStatus.Closed] : [AlertStatus.New, AlertStatus.Acknowledged],
			alertTypeIds: this.alertTypeIds,
			...this.dateRange,
			...this.searchFilter,
		};
		return filter;
	}

	downloadCsv() {
		const filter = this.getFilter();
		let o: Observable<HttpResponse<Blob>>;
		if (this.dataSource === 'network-detail') {
			if ((this.network?.networkId || 0) == 0) {
				this.alertMessageService.warning('The network provided for the alerts is not valid');
				return;
			}
			o = this.reportService.api.getNetworkAlertsCsv(
				this.network.accountId,
				this.network.networkId,
				this.network.managedDevices?.map((md) => md.managedDeviceKey),
				filter
			);
		} else {
			o = this.reportService.api.getAlertsCsv(filter);
		}

		o.subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting alerts CSV.', error);
			},
		});
	}
}
