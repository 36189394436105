import { Component, Input, SimpleChanges } from '@angular/core';
import { ManagedDeviceType } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-shared-monitor-common-cell-malware-protection-status',
	templateUrl: './cell-malware-protection-status.component.html',
	styleUrls: ['./cell-malware-protection-status.component.scss'],
})
export class CellMalwareProtectionStatusComponent {
	@Input() device: any;

	icon: string;
	color: string;
	text: string;
	tooltip: string;
	isValid: boolean = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = 'exclamation';
		let color: string = 'status-orange';
		let text: string = 'Unknown';
		let tooltip: string = `Malware Protection Status: ${text}`;

		if ([ManagedDeviceType.InternalSwitch, ManagedDeviceType.EndpointDefender, ManagedDeviceType.CngeSwitch].includes(this.device?.type) !== true) {
			return;
		}

		(color =
			this.device?.malwareProtectionEnabled === true ? 'status-green' : this.device?.malwareProtectionEnabled === false ? 'status-red' : 'status-orange'),
			(icon = this.device?.malwareProtectionEnabled === true ? 'check' : this.device?.malwareProtectionEnabled === false ? 'times' : 'exclamation');
		text = this.device?.malwareProtectionEnabled === true ? 'Enabled' : this.device?.malwareProtectionEnabled === false ? 'Disabled' : 'Unknown';
		tooltip = `Camera Defense Status: ${text}`;

		this.icon = icon;
		this.color = color;
		this.text = text;
		this.tooltip = tooltip;
		this.isValid = true;
	}
}
