import { Component, Input } from '@angular/core';
import { ImageUploaderConfig } from '../../data-types/image-uploader-config.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';

@Component({
	selector: 'shared-image-uploader',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
	@Input() config: ImageUploaderConfig;

	constructor(private modalService: NgbModal) {}

	ngOnInit() {
		if (this.config.loadImageAction) this.config.loadImageAction();
	}

	cropImage() {
		const modalRef = this.modalService.open(ImageCropperComponent, { backdrop: 'static', centered: true, size: 'lg' });
		modalRef.componentInstance.config = {
			maxWidth: this.config.maxWidth,
			previewTitle: this.config.label,
			previewDescription: this.config.recommendedText,
			aspectRatio: this.config.aspectRatio,
			shape: this.config.shape,
		};
		modalRef.result.then((result) => {
			if (result.success) this.config.uploadImageAction(result.imageData);
		});
	}

	removeImage() {
		this.config.removeImageAction();
	}
}
