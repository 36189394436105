<div class="d-flex justify-content-center">
	<p class="font-italic fs-5">Initializing Application...</p>
</div>
<!-- <div class="mb-3 d-flex justify-content-center">
    <fa-icon icon="circle-notch" size="3x" [spin]="true"></fa-icon>
</div> -->
<div>
	<div class="progress mt-2 mb-3">
		<div
			class="progress-bar progress-bar-striped progress-bar-animated"
			role="progressbar"
			aria-valuenow="progress"
			aria-valuemin="0"
			aria-valuemax="100"
			[style.width]="progressStyle"></div>
	</div>
</div>
<!-- <div class="d-flex flex-column">
    <div class="d-flex justify-content-center">
        <span class="text-muted">Settings:<fa-icon [icon]="authInitialized === true ? 'circle-check' : 'hourglass'" size="md" class="ms-2" [ngClass]="{ 'text-success': authInitialized === true }"></fa-icon></span>
    </div>
    <div class="d-flex justify-content-center">
        <span class="text-muted">Authentication:<fa-icon [icon]="settingsInitialized === true ? 'circle-check' : 'hourglass'" size="md" class="ms-2" [ngClass]="{ 'text-success': settingsInitialized === true }"></fa-icon></span>
    </div>
</div> -->
