<ng-container *ngIf="result == null">
    <ng-container *ngIf="requiresCredentials !== true">
        <div class="row">
            <div class="col-sm-12">
                <shared-context-message *ngIf="tokenError !== true" type="info"> The system is processing the registration. </shared-context-message>
                <shared-context-message *ngIf="tokenError === true" type="error"> The token provided is not valid. </shared-context-message>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="requiresCredentials === true">
        <p class="mb-4">Please enter credentials for the account you wish to register the device to.</p>
        <app-root-monitor-cloud-auth-login-form
            (isValid)="onLogFormIsValid($event)"
            (changes)="onLogFormChanges($event)"
            (submit)="processRegistrationToken()"
            [hideRememberMe]="true"></app-root-monitor-cloud-auth-login-form>
        <div class="mt-4 d-flex flex-row-reverse justify-content-between align-items-end">
            <button type="button" class="btn btn-primary" (click)="processRegistrationToken()" [disabled]="loginFormIsValid !== true">
                <fa-icon icon="sign-in" class="me-1"></fa-icon>Generate Activation Code
            </button>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="result != null">
    <div class="row">
        <div class="col-sm-12">
            <shared-context-message *ngIf="result.success === true" type="success"> This device has been registered. </shared-context-message>
            <shared-context-message *ngIf="result.success !== true" type="danger"> This device could not be registered. </shared-context-message>
        </div>
    </div>
    <ng-container *ngIf="result.success === true">
        <div class="row mt-4 fs-5">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <b>Model:</b><span class="ms-3">{{ result.unitModelName || 'N/A' }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <b>Serial:</b><span class="ms-3">{{ result.unitSerialNumber || 'N/A' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 text-center fs-5">Use the following code to activate it's warranty and ApplianceDefense license.</div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12 text-center fs-1 fw-bold font-monospace">
                {{ result.activationCode }}
            </div>
        </div>
        <!-- <div class="row mt-2">
            <div class="col-sm-12 text-center fs-5">to activate it's warranty and ApplianceDefense license.</div>
        </div> -->
        <div class="row mt-3">
            <div class="col-sm-10 offset-sm-1 text-center font-italic">
                Use {{ configService.data.config?.baseTitle || 'MonitorCloud' }} to assign this device a location for receiving helpful alerts.
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="result.success !== true">
        <div class="row mt-5" *ngIf="result.errors?.length > 0">
            <div class="col-sm-12 text-center">
                <p>The following errors were encountered during the registration process:</p>
                <p *ngFor="let error of result.errors" class="font-italic text-danger">{{ error }}</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-sm-12 text-center">
                Please check the entry details and scan the QR Code again, or contact this account's
                {{ configService.data.config?.baseTitle || 'MonitorCloud' }} administrator.
            </div>
        </div>
    </ng-container>
</ng-container>
