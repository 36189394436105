import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilitiesService } from '../services/utilities.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
	private _httpRequestQueueCount: number;
	private _spinId: string;
	private _hideId: string;

	constructor(private spinnerService: NgxSpinnerService, private utils: UtilitiesService) {
		this._httpRequestQueueCount = 0;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.headers.has('overrideSpinner') && req.headers.get('overrideSpinner') === 'true') return next.handle(req);

		this._httpRequestQueueCount++;
		if (this._httpRequestQueueCount === 1) {
			this._spinId = this.utils.helpers.getRandomString();
			setTimeout(() => {
				this.spinnerService.show();
			}, 0);
		}

		return next.handle(req).pipe(
			finalize(() => {
				this._httpRequestQueueCount--;
				if (this._httpRequestQueueCount === 0) {
					this._hideId = this._spinId;
					setTimeout(() => {
						if (this._hideId === this._spinId) this.spinnerService.hide();
					}, 250);
				}
			})
		);
	}
}
