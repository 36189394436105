import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-root-monitor-cloud-dashboard-outlet-offline-devices',
	templateUrl: './dashboard-outlet-offline-devices.component.html',
	styleUrls: ['./dashboard-outlet-offline-devices.component.scss'],
})
export class DashboardOutletOfflineDevicesComponent {
	selectedTab: string = 'units';

	constructor(private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.activatedRoute.url.subscribe((url) => {
			if ((url?.length ?? 0) == 0) return;

			if (url[0].path === 'offline-devices') {
				this.selectedTab = 'managed-devices';
			} else {
				this.selectedTab = 'units';
			}
		});
	}
}
