<div *ngIf="settingsService.data.settings?.sms == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<ng-container *ngIf="settingsService.data.settings?.sms != null">
	<div class="mb-3">
		<label for="tags">Selected Provider</label>
		<div class="mt-3">
			<div class="form-check">
				<input
					type="radio"
					id="providerTwilio"
					name="providerTwilio"
					class="form-check-input"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="selectedProvider"
					[value]="providers.TwilioSmsProvider" />
				<label for="providerTwilio" class="form-check-label">Twilio</label>
			</div>
			<div class="form-check">
				<input
					type="radio"
					id="providerTropo"
					name="providerTropo"
					class="form-check-input"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="selectedProvider"
					[value]="providers.TropoSmsProvider" />
				<label for="providerTropo" class="form-check-label">Tropo</label>
			</div>
		</div>
	</div>
	<hr class="w-100 my-4" />
	<ng-container *ngIf="selectedProvider === providers.TwilioSmsProvider">
		<app-root-monitor-cloud-settings-twilio-sms-form></app-root-monitor-cloud-settings-twilio-sms-form>
	</ng-container>
	<ng-container *ngIf="selectedProvider === providers.TropoSmsProvider">
		<app-root-monitor-cloud-settings-tropo-sms-form></app-root-monitor-cloud-settings-tropo-sms-form>
	</ng-container>
</ng-container>
