import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '../../data-types/date-range.model';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
	selector: 'shared-date-range-select',
	templateUrl: './date-range-select.component.html',
	styleUrls: ['./date-range-select.component.scss'],
})
export class DateRangeSelectComponent {
	@Input() options: number[];
	@Input() set dateRange(value: DateRange) {
		this.setDateRange(value);
	}
	get dateRange() {
		return this._dateRange;
	}
	@Output() dateRangeChange: EventEmitter<DateRange>;

	_dateRange: DateRange;
	selectId: string;
	startDate: NgbDate;
	endDate: NgbDate;
	hoveredDate: NgbDate;
	displayDate: boolean;
	radioVal: number;
	initialized: boolean;

	constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private utils: UtilitiesService) {
		this.options = [30, 60, 90];
		this.dateRangeChange = new EventEmitter<DateRange>();
		this.startDate = null;
		this.endDate = null;
		this.displayDate = false;
		this.radioVal = 0;
		this.initialized = false;
	}

	ngOnInit() {
		this.selectId = this.utils.helpers.getRandomString();
		this.initialized = true;
		this.setDateRange(this._dateRange);
	}

	setDays(days: number) {
		this.displayDate = false;
		this.startDate = this.calendar.getPrev(this.calendar.getToday(), 'd', days);
		this.endDate = null;
		this.updateRange(this.startDate, this.endDate);
	}

	setCustom() {
		this.displayDate = true;
		this.startDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 14);
		this.endDate = this.calendar.getToday();
		this.updateRange(this.startDate, this.endDate);
	}

	setAll() {
		this.displayDate = false;
		this.dateRangeChange.emit(null);
	}

	onDateSelection(date: NgbDate) {
		if (!this.startDate && !this.endDate) this.startDate = date;
		else if (!this.endDate && date.after(this.startDate)) this.endDate = date;
		else {
			this.endDate = null;
			this.startDate = date;
		}

		if (this.startDate && this.endDate) this.updateRange(this.startDate, this.endDate);
	}

	updateRange(startDate: NgbDate, endDate: NgbDate) {
		const dr: DateRange = { startTimestamp: this.convertToDate(startDate) };
		if (endDate && !endDate.equals(this.calendar.getToday())) dr.endTimestamp = this.convertToDate(this.calendar.getNext(endDate, 'd', 1));
		this.dateRangeChange.emit(dr);
	}

	convertToDate(date: NgbDate): Date {
		if (!date) return null;
		return moment([date.year, date.month - 1, date.day]).toDate();
	}

	convertToNgbDate(date: Date): NgbDate {
		if (!date) return null;
		return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
	}

	isHovered(date: NgbDate): boolean {
		return this.startDate && !this.endDate && this.hoveredDate && date.after(this.startDate) && date.before(this.hoveredDate);
	}

	isInside(date: NgbDate): boolean {
		return date.after(this.startDate) && date.before(this.endDate);
	}

	isRange(date: NgbDate): boolean {
		return date.equals(this.startDate) || date.equals(this.endDate) || this.isInside(date) || this.isHovered(date);
	}

	validateInput(currentValue: NgbDate, input: string): NgbDate {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

	onStartInput(input: string) {
		this.startDate = this.validateInput(this.startDate, input);
		this.updateRange(this.startDate, this.endDate);
	}

	onEndInput(input: string) {
		this.endDate = this.validateInput(this.endDate, input);
		this.updateRange(this.startDate, this.endDate);
	}

	clear(emit: boolean = false) {
		this.radioVal = 0;
		this.startDate = null;
		this.endDate = null;
		this.displayDate = false;
		if (emit) this.dateRangeChange.emit(null);
	}

	setDateRange(dateRange: DateRange, emit: boolean = false) {
		this._dateRange = dateRange;
		if (!this.initialized) return;
		this.radioVal = 0;
		this.displayDate = false;
		if (dateRange && dateRange.startTimestamp) {
			if (dateRange.endTimestamp) {
				this.radioVal = this.options.length + 1;
				this.displayDate = true;
				this.startDate = this.convertToNgbDate(dateRange.startTimestamp);
				this.endDate = this.calendar.getPrev(this.convertToNgbDate(dateRange.endTimestamp), 'd', 1);
			} else {
				const start: NgbDate = this.convertToNgbDate(dateRange.startTimestamp);
				for (let i in this.options)
					if (start.equals(this.calendar.getPrev(this.calendar.getToday(), 'd', this.options[i]))) {
						this.radioVal = parseInt(i) + 1;
						break;
					}
				if (this.radioVal == 0) {
					this.radioVal = this.options.length + 1;
					this.displayDate = true;
					this.startDate = this.convertToNgbDate(dateRange.startTimestamp);
					this.endDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 14);
				}
			}
		}
		if (emit) this.updateRange(this.startDate, this.endDate);
	}
}
