<div class="row">
    <div class="col-sm-12">
        <h3>Register for a New {{ configService.data.config?.baseTitle || 'MonitorCloud' }} Account</h3>
        <p *ngIf="currentPageMode === pageModeOptions.formDisplay" class="mt-3">
            Please complete the following form to create a new {{ configService.data.config?.baseTitle || 'MonitorCloud' }} account:
        </p>
    </div>
</div>
<ng-container *ngIf="currentPageMode === pageModeOptions.formDisplay">
    <ng-container *ngIf="newAccountBenefitsDisplayed === true">
        <div class="row mt-4">
            <div class="col-sm-12">
                <h6><b>ACCOUNT DETAILS</b></h6>
                <app-root-monitor-cloud-account-form
                    [account]="storedAccount"
                    [isPublicMode]="true"
                    [defaultAccountType]="selectedAccountType"
                    (isValid)="onAccountIsValid($event)"
                    (changes)="onAccountChanges($event)"
                    (submit)="save()"
                    ngbAutoFocus></app-root-monitor-cloud-account-form>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <h6><b>PRIMARY CONTACT DETAILS</b></h6>
                <app-root-monitor-cloud-user-form
                    [user]="storedUser"
                    [isPublicMode]="true"
                    [isCurrentUser]="false"
                    [isPrimaryUser]="true"
                    (isValid)="onUserIsValid($event)"
                    (changes)="onUserChanges($event)"
                    (submit)="save()"></app-root-monitor-cloud-user-form>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 text-end">
                <button class="btn btn-primary" [disabled]="isValid !== true" (click)="save()">Create Account</button>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="currentPageMode === pageModeOptions.resultDisplay">
    <ng-container *ngIf="hasError !== true">
        <div class="row mt-4">
            <div class="col-sm-12 d-flex align-items-center">
                <fa-icon icon="check-circle" class="text-success me-3" size="2x"></fa-icon>
                <span class="h3">Success!</span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 d-flex align-items-center">
                <p>{{ result }}</p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="hasError === true">
        <div class="row mt-4">
            <div class="col-sm-12 d-flex align-items-center">
                <fa-icon icon="exclamation-circle" class="text-danger me-3" size="2x"></fa-icon>
                <span class="h4">Something went wrong...</span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12">
                <p>We are sorry, but there was an error creating the account. The following message was returned:</p>
                <p class="mx-5 mt-3 font-italic">{{ result }}</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 text-end">
                <button class="btn btn-primary" (click)="retry()">Try Again</button>
            </div>
        </div>
    </ng-container>
</ng-container>
