<h4 class="card-title text-center mb-4">Log in to your account</h4>
<app-root-monitor-cloud-auth-login-form
	*ngIf="!ssoEnforced"
	(isValid)="onIsValid($event)"
	(changes)="onChanges($event)"
	(submit)="login()"></app-root-monitor-cloud-auth-login-form>
<p *ngIf="message" class="card-text text-danger text-center">{{ message }}</p>
<div class="d-flex flex-row-reverse justify-content-between align-items-end">
	<div>
		<button
			*ngIf="ssoEnabled && (ssoEnforced || !hideSsoLoginButton)"
			type="button"
			class="btn me-2"
			id="btn-sso"
			(click)="ssoLogin()"
			[ngClass]="{ 'btn-primary': ssoEnforced, 'btn-link': !ssoEnforced }">
			<fa-icon icon="sign-in"></fa-icon> SSO Log In
		</button>
		<button *ngIf="!ssoEnforced" type="button" class="btn btn-primary" id="btn-login" (click)="login()" [disabled]="!isValid">
			<fa-icon icon="sign-in"></fa-icon> Log In
		</button>
	</div>
	<button *ngIf="!ssoEnforced" type="button" class="btn btn-link btn-sm" id="btn-reset-password" (click)="reset()">
		<fa-icon icon="unlock"></fa-icon> Reset Password
	</button>
</div>
