import { Directive, Input, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
	selector: '[vw-draggable]',
})
export class Draggable implements OnInit {
	private topStart: number;
	private leftStart: number;
	private allowDrag: boolean = true;
	private md: boolean;
	private handle: HTMLElement;

	constructor(public element: ElementRef) {}

	ngOnInit() {
		if (this.allowDrag) {
			this.element.nativeElement.style.position = 'relative';

			if (this.handle) this.handle.className += ' cursor-draggable';
			else this.element.nativeElement.className += ' cursor-draggable';
		}
	}

	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent) {
		if (event.button === 2 || (this.handle !== undefined && event.target !== this.handle)) return; // prevents right click drag
		this.md = true;
		this.topStart = event.clientY - this.element.nativeElement.style.top.replace('px', '');
		this.leftStart = event.clientX - this.element.nativeElement.style.left.replace('px', '');
	}

	@HostListener('document:mouseup', ['$event'])
	onMouseUp(event: MouseEvent) {
		this.md = false;
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(event: MouseEvent) {
		if (this.md && this.allowDrag) {
			this.element.nativeElement.style.top = event.clientY - this.topStart + 'px';
			this.element.nativeElement.style.left = event.clientX - this.leftStart + 'px';
		}
	}

	@HostListener('document:mouseleave', ['$event'])
	onMouseLeave(event: MouseEvent) {
		this.md = false;
	}

	//@HostListener('touchstart', ['$event'])
	//onTouchStart(event: TouchEvent) {
	//	this.md = true;
	//	this.topStart = event.changedTouches[0].clientY - this.element.nativeElement.style.top.replace('px', '');
	//	this.leftStart = event.changedTouches[0].clientX - this.element.nativeElement.style.left.replace('px', '');
	//	event.stopPropagation();
	//}

	@HostListener('document:touchend', ['$event'])
	onTouchEnd() {
		this.md = false;
	}

	//@HostListener('document:touchmove', ['$event'])
	//onTouchMove(event: TouchEvent) {
	//	if (this.md && this.allowDrag) {
	//		this.element.nativeElement.style.top = (event.changedTouches[0].clientY - this.topStart) + 'px';
	//		this.element.nativeElement.style.left = (event.changedTouches[0].clientX - this.leftStart) + 'px';
	//	}
	//	event.stopPropagation();
	//}

	@Input('vw-draggable')
	set isDraggable(value: boolean) {
		this.allowDrag = value;
		if (this.allowDrag) {
			if (this.handle) this.handle.className += ' cursor-draggable';
			//else
			//	this.element.nativeElement.className += ' cursor-draggable';
		} else {
			if (this.handle) this.handle.className = this.handle.className.replace(' cursor-draggable', '');
			else this.element.nativeElement.className = this.element.nativeElement.className.replace(' cursor-draggable', '');
		}
	}

	@Input()
	set draggableHandle(theHandle: HTMLElement) {
		this.handle = theHandle;
	}
}
