<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title"><fa-icon icon="server"></fa-icon><span class="ps-2">Target Selector</span></h4>
		<button type="button" class="close" (click)="close()">
			<fa-icon icon="times" size="lg"></fa-icon>
		</button>
	</div>
	<div class="modal-body pt-0">
		<app-root-monitor-cloud-unit-selector-list
			[accountId]="accountId"
			[preSelectedUnits]="preSelectedUnits"
			(selectedUnitsChanged)="selectedUnitsChanged($event)"></app-root-monitor-cloud-unit-selector-list>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="(this.selectedUnits?.length || 0) === 0">
			<fa-icon icon="check"></fa-icon> Use Selected ({{ selectedUnits?.length || 0 }})
		</button>
	</div>
</div>
