import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertProfile, Unit } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, PageConfigService, TableItemSelectMode, TableSettings, MatchType, ToggleSwitchEvent } from '@razberi-ui/shared';
import { AlertProfileService } from '../../../../../services/alert-profile.service';
import { UnitService } from '../../../../../services/unit.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { SearchFilterService } from '../../../../../services/search-filter.service';
import { AlertProfileModalComponent } from '../../../../alert-profiles/alert-profile-modal/alert-profile-modal.component';
import { SelectedUnitsListModalComponent } from '../../../../units/selected-units-list-modal/selected-units-list-modal.component';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-alert-profiles',
	templateUrl: './account-view-tab-alert-profiles.component.html',
	styleUrls: ['./account-view-tab-alert-profiles.component.scss'],
})
export class AccountViewTabAlertProfilesComponent {
	accountId?: number;
	alertProfiles: AlertProfile[];
	devices: Unit[];
	tableSettings: TableSettings;
	isInitialized: boolean = false;
	devicesSearchFilter = {
		tags: {
			type: 'objectArray',
			keyToFilterOn: 'tagId',
			selectionType: MatchType.Any,
			values: [],
		},
		locationId: {
			type: 'property',
			values: [],
		},
	};

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly pageConfigService: PageConfigService,
		private readonly modalService: NgbModal,
		private readonly confirmService: ConfirmService,
		private readonly alertMessageService: AlertMessageService,
		private readonly searchFilterService: SearchFilterService,
		private readonly authService: AuthService,
		private readonly alertProfileService: AlertProfileService,
		private readonly unitService: UnitService
	) {
		this.alertProfiles = [];
		this.devices = [];
		this.tableSettings = {
			useSearch: true,
			itemSelectMode: TableItemSelectMode.Multi,
			useEdit: true,
			headerButtons: [
				{ label: 'Delete', faIcon: 'trash', clickHandlerFunction: (params) => this.deleteAlertProfiles(params.value) },
				{ label: 'Send Tests', faIcon: 'paper-plane', clickHandlerFunction: (params) => this.sendTests(params.value) },
			],
			columnConfig: [
				{
					primaryKey: 'name',
					header: 'Alert Profile Name',
					useForSearch: true,
					cellType: 'link-button',
					transform: (value) => {
						return { text: value, icon: 'bell' };
					},
					clickHandlerFunction: (params) => this.manageAlertProfile(params),
				},
				{ primaryKey: 'alertTypes', header: 'Alert Types', useForSearch: false, cellType: 'pills' },
				{ primaryKey: 'locations', header: 'Locations', useForSearch: true, cellType: 'pills' },
				{ primaryKey: 'tags', header: 'Tags', useForSearch: true, cellType: 'pills' },
				{
					primaryKey: 'devices',
					header: 'Devices',
					useForSearch: true,
					cellType: 'link-button',
					transform: (_, tableItem) => {
						return { text: `${this.calculateDeviceCount(tableItem)} devices`, icon: 'server' };
					},
					clickHandlerFunction: (params) => this.showMatchingDevicesModal(params),
				},
				{
					primaryKey: 'lastNotificationTimestamp',
					header: 'Last Notification',
					useForSearch: false,
					transform: (value) => {
						return moment(value).isValid() ? moment(value).format('l LTS') : null;
					},
				},
				{
					primaryKey: 'enabled',
					header: 'Status',
					useForSearch: false,
					cellType: 'toggle-switch',
					transform: (value) => {
						return { value: value };
					},
					clickHandlerFunction: (params, event) => this.toggleStatus(params, event),
				},
			],
			editActions: [
				{ label: 'Edit Alert Profile', faIcon: 'edit', clickHandlerFunction: (params) => this.manageAlertProfile(params) },
				{ label: 'Copy Alert Profile', faIcon: 'copy', clickHandlerFunction: (params) => this.copyAlertProfile(params) },
				{ label: 'Send Test', faIcon: 'paper-plane', clickHandlerFunction: (params) => this.sendTest(params) },
				{ label: 'Delete', faIcon: 'trash', faIconColor: 'danger', clickHandlerFunction: (params) => this.deleteAlertProfile(params) },
			],
		};
	}

	ngOnInit() {
		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;

		this.pageConfigService.data.toolbarConfig = {
			buttons: [
				{
					text: 'New Alert Profile',
					icon: 'bell-plus',
					color: 'primary',
					click: () => {
						this.manageAlertProfile();
					},
				},
			],
		};

		this.getAlertProfiles();
	}

	ngOnDestroy() {
		this.pageConfigService.data.toolbarConfig = null;
	}

	getAlertProfiles() {
		forkJoin({
			alertProfiles: this.alertProfileService.api.getAlertProfiles(this.accountId),
			devices: this.unitService.api.getUnitsByAccountId(this.accountId),
		}).subscribe({
			next: (result) => {
				this.alertProfiles = result.alertProfiles;
				this.devices = result.devices;
				this.isInitialized = true;
			},
			error: (error) => {
				this.alertMessageService.error('Error getting alert profiles.', error);
			},
		});
	}

	manageAlertProfile(alertProfile?: AlertProfile) {
		const modal = this.modalService.open(AlertProfileModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modal.componentInstance.accountId = this.accountId;
		modal.componentInstance.alertProfileId = alertProfile?.alertProfileId;
		modal.result.then(
			(_) => {
				this.getAlertProfiles();
			},
			(_) => {}
		);
	}

	copyAlertProfile(alertProfile: AlertProfile) {
		const modal = this.modalService.open(AlertProfileModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modal.componentInstance.accountId = this.accountId;
		modal.componentInstance.alertProfile = { ...alertProfile, name: `Copy of ${alertProfile.name}` };
		modal.result.then(
			(_) => {
				this.getAlertProfiles();
			},
			(_) => {}
		);
	}

	toggleStatus(alertProfile: AlertProfile, event: ToggleSwitchEvent) {
		alertProfile.enabled = event.value;
		this.alertProfileService.api.updateAlertProfile(alertProfile.accountId, alertProfile.alertProfileId, alertProfile).subscribe({
			next: (_) => {
				this.alertMessageService.success('Alert profile status changed successfully.');
				// this.alertProfileService.alertProfilesUpdated.next(true);
			},
			error: (error) => {
				alertProfile.enabled = !alertProfile.enabled;
				this.alertMessageService.error('There was an error while changing the alert profile status.', error);
				// this.alertProfileService.alertProfilesUpdated.next(true);
			},
		});
	}

	calculateDeviceCount(alertProfile: AlertProfile): number {
		let filter = this.getSearchFilter(alertProfile);
		let filteredDevices = this.searchFilterService.helpers.applyFilter(this.devices, filter);
		return filteredDevices.length;
	}

	showMatchingDevicesModal(alertProfile: AlertProfile): void {
		let filter = this.getSearchFilter(alertProfile);
		let filteredDevices = this.searchFilterService.helpers.applyFilter(this.devices, filter);
		const modalRef = this.modalService.open(SelectedUnitsListModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modalRef.componentInstance.units = filteredDevices;
	}

	getSearchFilter(alertProfile: AlertProfile) {
		let filter = { ...this.devicesSearchFilter };
		filter.locationId.values = alertProfile.locations.map((loc) => loc.locationId);
		filter.tags.values = alertProfile.tags.map((tag) => tag.tagId);
		filter.tags.selectionType = alertProfile.matchType as MatchType;
		return filter;
	}

	sendTests(alertProfiles: AlertProfile[]) {
		if (!alertProfiles.length) return;
		if (alertProfiles.length === 1) {
			this.sendTest(alertProfiles[0]);
			return;
		}

		this.confirmService
			.confirm({
				title: 'Send test notifications?',
				text: `Are you sure you want to send test alert notifications to all recipients for ${alertProfiles.length} alert profiles?`,
				icon: 'paper-plane',
				confirmButtonText: 'Send Test',
				confirmButtonColor: 'primary',
				confirmButtonIcon: 'paper-plane',
			})
			.then(
				(_) => {
					forkJoin(alertProfiles.map((ad) => this.alertProfileService.api.sendTestById(ad.accountId, ad.alertProfileId))).subscribe({
						next: (_) => {
							this.alertMessageService.success(`Successfully sent test alert notifications to ${alertProfiles.length} alert profiles.`);
						},
						error: (error) => {
							this.alertMessageService.error('There was an error while sending test alert notifications.', error);
						},
					});
				},
				(_) => {}
			);
	}

	sendTest(alertProfile: AlertProfile) {
		this.confirmService
			.confirm({
				title: 'Send test notification?',
				text: `Are you sure you want to send a test alert notification to all recipients for the selected alert profile?`,
				icon: 'paper-plane',
				confirmButtonText: 'Send Test',
				confirmButtonColor: 'primary',
				confirmButtonIcon: 'paper-plane',
			})
			.then(
				(_) => {
					this.alertProfileService.api.sendTestById(alertProfile.accountId, alertProfile.alertProfileId).subscribe({
						next: (_) => {
							this.alertMessageService.success(
								`Successfully sent test alert notification to all recipients for the alert profile ${alertProfile.name}.`
							);
						},
						error: (error) => {
							this.alertMessageService.error('There was an error while sending a test alert notification.', error);
						},
					});
				},
				(_) => {}
			);
	}

	deleteAlertProfile(alertProfile: AlertProfile) {
		this.confirmService
			.confirm({
				title: 'Delete alert profile?',
				text: `Are you sure you want to delete ${alertProfile.name}?`,
				icon: 'bell-slash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(
				(_) => {
					this.alertProfileService.api.deleteAlertProfile(this.accountId, alertProfile.alertProfileId).subscribe({
						next: (_) => {
							this.alertMessageService.success('Alert profile deleted successfully.');
							this.getAlertProfiles();
						},
						error: (error) => {
							this.alertMessageService.error('There was an error while deleting the alert profile.', error);
							this.getAlertProfiles();
						},
					});
				},
				(_) => {}
			);
	}

	deleteAlertProfiles(alertProfiles: AlertProfile[]) {
		if (!alertProfiles.length) return;
		if (alertProfiles.length === 1) {
			this.deleteAlertProfile(alertProfiles[0]);
			return;
		}

		this.confirmService
			.confirm({
				title: 'Delete alert profiles?',
				text: `Are you sure you want to delete ${alertProfiles.length} alert profiles?`,
				icon: 'bell-slash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(
				(_) => {
					let ids: number[] = alertProfiles.map((ad) => ad.alertProfileId);
					this.alertProfileService.api.deleteAlertProfiles(this.accountId, ids).subscribe({
						next: (_) => {
							this.alertMessageService.success('Alert profiles deleted successfully.');
							this.getAlertProfiles();
						},
						error: (error) => {
							this.alertMessageService.error('There was an error while deleting the alert profiles.', error);
							this.getAlertProfiles();
						},
					});
				},
				(_) => {}
			);
	}
}
