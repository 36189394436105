<form [formGroup]="expiryForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row">
		<div class="col">
			<div class="my-2 mx-2">
				<div class="d-flex my-1">
					<div class="w-50">Device Name</div>
					<div>{{ unit.name || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Model Name</div>
					<div>{{ unit.modelName || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Serial Number</div>
					<div>{{ unit.serialNumber || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Registration Timestamp</div>
					<div>{{ (unitFields.registrationTimestamp | date: 'M/d/yyyy h:mm a') || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Activation Timestamp</div>
					<div>{{ (unitFields.activationTimestamp | date: 'M/d/yyyy h:mm a') || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Warranty Expiration</div>
					<div>{{ defaultWarrantyExpiration }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">ApplianceDefense Expiration</div>
					<div>{{ defaultApplianceDefenseExpiration }}</div>
				</div>
				<hr />
				<div class="d-flex my-1">
					<div class="w-50">Manufacture Date</div>
					<div>{{ (unitFields.manufactureTimestamp | date: 'M/d/yyyy':'UTC') || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Standard Warranty Date</div>
					<div>{{ (unitFields.standardWarrantyExpiration | date: 'M/d/yyyy':'UTC') || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Extended Warranty Date</div>
					<div>{{ (unitFields.extendedWarrantyExpiration | date: 'M/d/yyyy':'UTC') || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Extended Warranty Granted</div>
					<div>{{ (unitFields.extendedWarrantyGranted | date: 'M/d/yyyy h:mm a') || '--' }}</div>
				</div>
				<div class="d-flex my-1">
					<div class="w-50">Extended Warranty Revoked</div>
					<div>{{ (unitFields.extendedWarrantyRevoked | date: 'M/d/yyyy h:mm a') || '--' }}</div>
				</div>
				<hr />
			</div>
			<div class="d-flex my-4">
				<div class="mb-3 w-50 mx-2">
					<label for="overrideWarrantyExpiration" class="form-label">Warranty Expiration</label>
					<div class="font-bold mb-2">Default: {{ defaultWarrantyExpiration }}</div>
					<shared-form-date-picker labelForId="overrideWarrantyExpiration" formControlName="overrideWarrantyExpiration"></shared-form-date-picker>
					<shared-input-validation-messages [control]="expiryForm.controls.overrideWarrantyExpiration"></shared-input-validation-messages>
				</div>
				<div class="mb-3 w-50 ms-4">
					<label for="overrideApplianceDefenseExpiration" class="form-label">ApplianceDefense Expiration</label>
					<div class="font-bold mb-2">Default: {{ defaultApplianceDefenseExpiration }}</div>
					<shared-form-date-picker
						labelForId="overrideApplianceDefenseExpiration"
						formControlName="overrideApplianceDefenseExpiration"></shared-form-date-picker>
					<shared-input-validation-messages [control]="expiryForm.controls.overrideApplianceDefenseExpiration"></shared-input-validation-messages>
				</div>
			</div>
			<div *ngIf="isProduction !== true" class="d-flex my-4">
				<div class="mb-3 w-50 mx-2">
					<label for="manufactureTimestamp" class="form-label">Manufacture Date</label>
					<div class="font-bold mb-2">Default: {{ (unitFields.manufactureTimestamp | date: 'M/d/yyyy':'UTC') || '--' }}</div>
					<shared-form-date-picker labelForId="manufactureTimestamp" formControlName="manufactureTimestamp"></shared-form-date-picker>
					<shared-input-validation-messages [control]="expiryForm.controls.manufactureTimestamp"></shared-input-validation-messages>
				</div>
				<div class="mb-3 w-50 ms-4">
					<label for="activationTimestamp" class="form-label">Activation Date</label>
					<div class="font-bold mb-2">Default: {{ (unitFields.activationTimestamp | date: 'M/d/yyyy':'UTC') || '--' }}</div>
					<shared-form-date-picker labelForId="activationTimestamp" formControlName="activationTimestamp"></shared-form-date-picker>
					<shared-input-validation-messages [control]="expiryForm.controls.activationTimestamp"></shared-input-validation-messages>
				</div>
			</div>
		</div>
	</div>
</form>
