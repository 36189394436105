export enum SelectableObjectMode {
	card,
	row,
	expanded,
}

export interface SelectableObject<T> {
	object: T;
	readonly: boolean;
	isHidden: boolean;
	isSelected: boolean;
	mode: SelectableObjectMode;
	onSelected?: any;
}
