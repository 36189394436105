import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchAccountFilter, SearchFilter } from '@razberi-ui/api/cloud-api';
import { Account, EnumItem, EnumService, Location, Tag, Unit, UpdateType } from '@razberi-ui/core/data-types';
import { AlertMessageService, CoreSearchItem, PageSidePanelService, UtilitiesService } from '@razberi-ui/shared';
import { AccountService } from '../../../../services/account.service';
import { LocationService } from '../../../../services/location.service';
import { TagService } from '../../../../services/tag.service';
import { UnitService } from '../../../../services/unit.service';
import { NGXLogger } from 'ngx-logger';
import { Subscription, forkJoin, of } from 'rxjs';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DeviceEditModalComponent } from '../../../devices/device-edit-modal/device-edit-modal.component';
import { SelectableObject, SelectableObjectMode } from '../../../../data-types/selectable-object.model';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-unit-selector-list',
	templateUrl: './unit-selector-list.component.html',
	styleUrls: ['./unit-selector-list.component.scss'],
})
export class UnitSelectorListComponent {
	@Input() accountId: number;
	@Input() preSelectedUnits: Unit[] = [];
	@Input() readonly: boolean = false;
	@Output() selectedUnitsChanged: EventEmitter<Unit[]> = new EventEmitter<Unit[]>();

	selectableModeOptions: any = SelectableObjectMode;
	selectedObjectMode: SelectableObjectMode = SelectableObjectMode.row;

	account: Account;
	availableUnits: Unit[] = [];
	selectableUnits: SelectableObject<Unit>[] = [];
	selectedUnits: Unit[] = [];

	availableTags: CoreSearchItem<Tag>[] = [];
	selectedTags: CoreSearchItem<Tag>[] = [];
	availableLocations: CoreSearchItem<Location>[] = [];
	selectedLocations: CoreSearchItem<Location>[] = [];
	availableUpdateTypes: CoreSearchItem<UpdateType>[] = [];
	selectedUpdateTypes: CoreSearchItem<UpdateType>[] = [];
	availableStatuses: CoreSearchItem<EnumItem>[] = [];
	selectedStatuses: CoreSearchItem<EnumItem>[] = [];

	subscriptions: Subscription = new Subscription();

	searchFilter: SearchFilter;
	showFilters: boolean = false;
	filtersAreApplied: boolean = false;
	searchTermControl = new UntypedFormControl();
	showPagination: boolean = true;
	selectedPage: number = 1;
	selectedPageSize: number = 10;
	totalRecords: number = 0;
	selectedSortBy: string = 'ASC';
	viewSelectedDevices: boolean = false;

	get pagingStatus() {
		return this.utils.helpers.getPagingStatus(this.selectedPage, this.selectedPageSize, this.totalRecords);
	}

	constructor(
		private readonly logger: NGXLogger,
		private readonly utils: UtilitiesService,
		private readonly unitService: UnitService,
		private readonly alertMessageService: AlertMessageService,
		private readonly accountService: AccountService,
		private readonly locationService: LocationService,
		private readonly tagService: TagService,
		private readonly pageSidePanelService: PageSidePanelService,
		private readonly enumService: EnumService,
		private readonly modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.selectObjectMode(this.readonly === true ? SelectableObjectMode.card : SelectableObjectMode.row);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.preSelectedUnits?.length > 0) {
			this.selectedUnits = [];
			this.selectedUnits = [...this.preSelectedUnits];
			this.selectedUnitsChanged.emit(this.selectedUnits);
			this.getAccountData(this.selectedUnits);
		} else {
			this.getAccountData(null);
		}
	}

	getAccountData(selectedUnits: Unit[]) {
		this.account = null;
		this.availableStatuses = [];
		this.availableLocations = [];
		this.availableTags = [];
		this.availableUnits = [];
		this.selectedUnits = selectedUnits ?? [];
		this.totalRecords = 0;
		this.resetSearchFilter();
		this.buildSearchFilter();

		forkJoin({
			account: this.accountService.api.getAccountsFilter(this.searchFilter),
			accountLocations: this.locationService.api.getLocations(this.accountId),
			accountTags: this.tagService.api.getTags(this.accountId),
			accountDevices: this.unitService.api.getUnits(this.searchFilter),
			deviceUpdateTypes: this.unitService.api.getUpdateTypes(),
			deviceStatuses: of(this.enumService.helpers.getDeviceStatuses()),
		}).subscribe({
			next: (results) => {
				let { account, accountLocations, accountTags, accountDevices, deviceUpdateTypes, deviceStatuses } = results;
				this.account = results.account?.results?.find((a) => a.accountId === this.accountId);
				this.availableUnits = results.accountDevices?.results;
				this.availableLocations = results.accountLocations?.map((location: Location) => {
					return { value: location, label: location.name };
				});
				this.availableTags = results.accountTags?.map((tag: Tag) => {
					return { value: tag, label: tag.name };
				});
				this.availableUpdateTypes = results.deviceUpdateTypes?.map((updateType: UpdateType) => {
					return { value: updateType, label: updateType.name };
				});
				this.availableStatuses = results.deviceStatuses?.map((enumItem: EnumItem) => {
					return { value: enumItem, label: enumItem.name };
				});
				this.totalRecords = results.accountDevices?.totalCount;
				this.buildSelectableUnits();
				this.processSelectedUnits();
			},
			error: (error) => {
				this.logger.error('error getting taskData', error);
				this.alertMessageService.error('Error getting task data.', error);
			},
		});
	}

	buildSelectableUnits() {
		this.selectableUnits = [];
		this.availableUnits?.map((unit) => {
			this.selectableUnits.push({
				object: unit,
				readonly: this.readonly,
				isSelected: false,
				isHidden: false,
				mode: this.selectedObjectMode,
				onSelected: (unit) => {
					this.selectUnit(unit);
				},
			});
		});
	}

	processSelectedUnits() {
		this.selectableUnits?.map((selectable) => {
			selectable.isSelected = this.selectedUnits?.findIndex((u) => u.unitId === selectable.object.unitId) > -1;
		});
	}

	buildSearchFilter() {
		this.searchFilter = { pageSize: this.selectedPageSize, pageNumber: this.selectedPage, sortAsc: this.selectedSortBy !== 'DESC' };
		const searchAccountFilter: SearchAccountFilter = { accountId: this.accountId };

		if (this.viewSelectedDevices === true || this.readonly === true) {
			searchAccountFilter.unitIds = this.selectedUnits.map((u) => u.unitId);
		}

		this.searchFilter.searchTerm = this.searchTermControl.value;
		this.searchFilter.unitStatuses = this.selectedStatuses?.map((s) => s.value.value);
		this.searchFilter.updateTypeIds = this.selectedUpdateTypes?.map((s) => s.value.updateTypeId);
		searchAccountFilter.locationIds = this.selectedLocations?.map((s) => s.value.locationId);
		searchAccountFilter.tagIds = this.selectedTags?.map((s) => s.value.tagId);
		this.searchFilter.accounts = [searchAccountFilter];
		this.filtersAreApplied =
			this.searchTermControl.value?.length > 0 ||
			this.selectedLocations?.length > 0 ||
			this.selectedTags?.length > 0 ||
			this.selectedStatuses?.length > 0 ||
			this.selectedUpdateTypes?.length > 0;
	}

	resetSearchFilter() {
		this.selectedPage = 1;
		this.searchTermControl.setValue(null);
		this.selectedStatuses = [];
		this.selectedLocations = [];
		this.selectedTags = [];
		this.selectedUpdateTypes = [];
	}

	getFilteredData() {
		this.buildSearchFilter();
		this.unitService.api.getUnits(this.searchFilter).subscribe((results) => {
			this.availableUnits = results.results;
			this.totalRecords = results.totalCount;
			this.buildSelectableUnits();
			this.processSelectedUnits();
		});
	}

	onApplyFilter() {
		this.selectedPage = 1;
		this.getFilteredData();
		this.showFilters = false;
	}

	onCancelFilter() {
		this.showFilters = false;
	}

	onResetFilter() {
		this.resetSearchFilter();
		this.getFilteredData();
		this.showFilters = false;
	}

	selectUnit(unit: Unit) {
		const exists = this.selectedUnits.some((u) => u.unitId === unit.unitId);
		if (exists === true) this.selectedUnits = this.selectedUnits.filter((u) => u.unitId !== unit.unitId);
		else this.selectedUnits.push(unit);

		this.selectedUnitsChanged.emit(this.selectedUnits);
		if (this.viewSelectedDevices === true && (this.selectedUnits?.length ?? 0) == 0) {
			this.viewSelectedDevices = false;
			this.resetSearchFilter();
			this.getFilteredData();
			this.showFilters = false;
		}
	}

	selectedPageSizeChanged() {
		this.selectedPage = 1;
		this.getFilteredData();
	}

	selectedSortByChanged() {
		this.selectedPage = 1;
		this.getFilteredData();
	}

	selectedViewChanged() {
		this.resetSearchFilter();
		this.getFilteredData();
		this.showFilters = false;
	}

	onTabChange(changeEvent: NgbNavChangeEvent) {
		this.viewSelectedDevices = changeEvent.nextId === 'selected';
		this.resetSearchFilter();
		this.getFilteredData();
		this.showFilters = false;
	}

	selectAllUnits() {
		this.selectableUnits?.map((selectable) => {
			selectable.isSelected = true;
			const exists = this.selectedUnits.some((u) => u.unitId === selectable.object.unitId);
			if (exists !== true) this.selectedUnits.push(selectable.object);
		});
		this.selectedUnitsChanged.emit(this.selectedUnits);
	}

	selectNoUnits() {
		this.selectableUnits?.map((selectable) => {
			selectable.isSelected = false;
			const exists = this.selectedUnits.some((u) => u.unitId === selectable.object.unitId);
			if (exists === true) this.selectedUnits = this.selectedUnits.filter((u) => u.unitId !== selectable.object.unitId);
		});
		this.selectedUnitsChanged.emit(this.selectedUnits);
		if (this.viewSelectedDevices === true && (this.selectedUnits?.length ?? 0) == 0) {
			this.viewSelectedDevices = false;
			this.resetSearchFilter();
			this.getFilteredData();
			this.showFilters = false;
		}
	}

	selectObjectMode(value: SelectableObjectMode) {
		this.selectedObjectMode = value;
		this.selectableUnits?.map((selectable) => {
			selectable.mode = value;
		});
	}

	getPageIndexStatus() {
		const startIndex = (this.selectedPage - 1) * this.selectedPageSize + 1;
		const endIndex = this.selectedPage * this.selectedPageSize;
		return this.totalRecords === 0
			? '--'
			: `${startIndex < this.totalRecords ? startIndex : this.totalRecords}-${endIndex < this.totalRecords ? endIndex : this.totalRecords} of ${
					this.totalRecords
			  }`;
	}

	viewDeviceDetails(unit: Unit) {
		console.log(unit);
		this.pageSidePanelService.helpers.openUnitSidePanel(
			unit.name,
			'details',
			unit.accountId,
			unit.unitId,
			this.unitService.helpers.userCanEditUnit(),
			(unit) => {
				this.editUnit(unit);
			}
		);
	}

	editUnit(unit: Unit) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = unit.accountId;
		modal.componentInstance.unitId = unit.unitId;
		modal.result.then(
			(result) => {
				// this.update.emit();
			},
			(_) => {}
		);
	}
}
