import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-subscription-modal',
	templateUrl: './invalid-subscription-modal.component.html',
	styleUrls: ['./invalid-subscription-modal.component.scss'],
})
export class InvalidSubscriptionModalComponent {
	@Input() subscriptions: string = '';

	constructor(private readonly activeModal: NgbActiveModal, readonly settingsService: CoreServerSettingsService) {}

	close() {
		this.activeModal.dismiss();
	}
}
