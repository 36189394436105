import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
	Account,
	ManageTask,
	ManageTaskParamsFileChangedEvent,
	ManageTaskType,
	Location,
	Tag,
	Unit,
	ManageTaskNotificationType,
	AccountSubscriptionType,
	AccountSubscriptionStatus,
	ManageTaskStatus,
} from '@razberi-ui/core/data-types';
import { NGXLogger } from 'ngx-logger';
import { Subscription, distinctUntilChanged, forkJoin, of } from 'rxjs';
import { AccountService } from '../../../services/account.service';
import { ManageService } from '../../../services/manage.service';
import { AlertMessageService, FormMultiSelectComponent } from '@razberi-ui/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@razberi-ui/api/cloud-api';

export interface TaskData {
	task: ManageTask;
	accountId: number;
	availableAccounts: Account[];
	availableManageTaskTypes: ManageTaskType[];
}

export interface ManageTaskTypeGroup {
	name: string;
	types: ManageTaskType[];
}

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-form',
	templateUrl: './manage-task-form.component.html',
	styleUrls: ['./manage-task-form.component.scss'],
})
export class ManageTaskFormComponent {
	@Input() accountId: number;
	@Input() manageTask: ManageTask;

	@Output() changes: EventEmitter<any> = new EventEmitter<any>();
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('unitsSelect') unitsSelect: FormMultiSelectComponent;

	taskData: TaskData;
	taskForm: UntypedFormGroup;
	uploadFileEvent: ManageTaskParamsFileChangedEvent;
	parametersReady: boolean = false;

	availableDataIsValid: boolean = false;
	availableAccounts: Account[] = [];
	availableManageTaskTypes: ManageTaskType[] = [];
	availableManageTaskGroups: ManageTaskTypeGroup[] = [];

	availableLocations: Location[] = [];
	availableTags: Tag[] = [];
	availableUnits: Unit[] = [];

	subscriptions: Subscription = new Subscription();
	notificationTypeOptions: any = ManageTaskNotificationType;
	notificationTypeKeys = Object.keys(this.notificationTypeOptions)
		.filter((k) => !isNaN(Number(k)))
		.map((k) => {
			return { text: ManageTaskNotificationType[k], value: Number(k) };
		});
	executeImmediately: boolean = true;
	executeAtTime: any = { hour: 14, minute: 0 };
	setConcurrency: boolean = false;
	setErrorThreshold: boolean = false;

	constructor(
		private readonly logger: NGXLogger,
		private readonly authService: AuthService,
		private readonly accountService: AccountService,
		private readonly manageService: ManageService,
		private readonly alertMessageService: AlertMessageService
	) {}

	ngOnInit() {
		this.taskForm = new UntypedFormGroup({
			manageTaskId: new UntypedFormControl(null),
			accountId: new UntypedFormControl(this.accountId),
			accountName: new UntypedFormControl(null),
			manageTaskGuid: new UntypedFormControl(null),
			manageTaskTypeId: new UntypedFormControl(null, [Validators.required]),
			manageTaskTypeName: new UntypedFormControl(null),
			name: new UntypedFormControl(null, [Validators.required]),
			description: new UntypedFormControl(null),
			status: new UntypedFormControl(null),
			parameters: new UntypedFormControl(null),
			executeAt: new UntypedFormControl(null, [Validators.required]),
			concurrent: new UntypedFormControl(0),
			errorHandling: new UntypedFormControl(0),
			locations: new UntypedFormControl([]),
			tags: new UntypedFormControl([]),
			units: new UntypedFormControl([], [Validators.required]),
			manageTaskFiles: new UntypedFormControl([]),
			notificationType: new UntypedFormControl([], [Validators.required]),
		});

		this.subscriptions.add(
			this.taskForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.emitOutput();
			})
		);
		this.subscriptions.add(
			this.taskForm.valueChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.emitOutput();
			})
		);
		this.isValid.emit(this.taskForm.valid);

		// initialize task data
		this.taskData = {
			task: this.manageService.helpers.getDefaultTask(this.authService.data.account.accountId),
			accountId: this.authService.data.account.accountId,
			availableAccounts: [],
			availableManageTaskTypes: [],
		};
	}

	ngOnChanges(changes) {
		if (this.accountId > 0 && this.manageTask != null) {
			this.getTaskData(true);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getTaskData(initialize: boolean) {
		const accountsFilter = {
			accounts: [{ accountId: this.accountId }],
			providerAccountIds: null,
			pageNumber: 1,
			pageSize: 10000,
			accountSubscriptionTypes: [AccountSubscriptionType.Trial, AccountSubscriptionType.Manage],
			accountSubscriptionStatuses: [AccountSubscriptionStatus.Active, AccountSubscriptionStatus.Expiring],
			sortAsc: true,
		};

		const managedAccountsFilter = { ...accountsFilter };
		managedAccountsFilter.accounts = null;
		managedAccountsFilter.providerAccountIds = [this.accountId];

		forkJoin({
			accounts: this.availableAccounts?.length > 0 ? of(null) : this.accountService.api.getAccountsFilter(accountsFilter),
			managedAccounts: this.availableAccounts?.length > 0 ? of(null) : this.accountService.api.getAccountsFilter(managedAccountsFilter),
			manageTaskTypes: this.availableManageTaskTypes?.length > 0 ? of(this.availableManageTaskTypes) : this.manageService.api.getTaskTypes(),
		}).subscribe({
			next: (taskData) => {
				let { accounts, managedAccounts, manageTaskTypes } = taskData;
				this.taskData = {
					task: this.manageTask,
					accountId: this.manageTask.accountId,
					availableAccounts:
						this.availableAccounts?.length > 0 ? this.availableAccounts : [...(accounts?.results || []), ...(managedAccounts?.results || [])],
					availableManageTaskTypes: [...manageTaskTypes],
				};

				this.availableDataIsValid = this.taskData.task.manageTaskId > 0 || this.taskData.availableAccounts?.length > 0;
				if (this.availableDataIsValid !== true) return;

				this.availableAccounts = this.taskData.availableAccounts ?? [];
				this.availableManageTaskTypes = this.taskData.availableManageTaskTypes?.filter((t) => t.taskTypeId > 0 && t.taskTypeId !== 8) || []; // temporarily hide 'run application' task type
				const typeGroups = this.availableManageTaskTypes?.reduce(function (r, a) {
					r[a.groupName] = r[a.groupName] || [];
					r[a.groupName].push(a);
					r[a.groupName].sort((a, b) => {
						return a.name > b.name ? 1 : -1;
					});
					return r;
				}, Object.create(null));
				this.availableManageTaskGroups = Object.keys(typeGroups)
					?.sort((a, b) => {
						return a > b ? 1 : -1;
					})
					?.map((k) => {
						return <ManageTaskTypeGroup>{ name: k, types: typeGroups[k] };
					});

				if (initialize === true) {
					this.setTaskDetail(this.taskData.task);
				}
			},
			error: (error) => {
				this.logger.log('error getting taskData', error);
				this.alertMessageService.error('Error getting task data.', error);
			},
		});
	}

	setTaskDetail(task: ManageTask) {
		if (task != null) {
			this.taskForm.setValue({
				manageTaskId: task.manageTaskId || 0,
				accountId: task.accountId || this.accountId,
				accountName: task.accountName || null,
				manageTaskGuid: task.manageTaskGuid || null,
				manageTaskTypeId: task.manageTaskTypeId || null,
				manageTaskTypeName: task.manageTaskTypeName || null,
				name: task.name || '',
				description: task.description || '',
				status: task.status ?? ManageTaskStatus.Scheduled,
				parameters: task.parameters,
				executeAt: task.executeAt,
				concurrent: task.concurrent || 0,
				errorHandling: task.errorHandling || 0,
				locations: task.locations ?? [],
				tags: task.tags ?? [],
				units: task.units ?? [],
				manageTaskFiles: task.manageTaskFiles ?? [],
				notificationType: task.notificationType ?? ManageTaskNotificationType.None,
			});
			this.executeImmediately = task.scheduledTimestamp == null;
			this.setConcurrency = task.concurrent > 0;
			this.setErrorThreshold = task.errorHandling > 0;
		}
	}

	getTaskTypeName(taskTypeId: number): string {
		return this.availableManageTaskTypes?.find((tt) => tt.taskTypeId === taskTypeId)?.name ?? 'N/A';
	}

	changeTaskAccount() {
		this.accountId = this.taskForm.controls.accountId.value;
		this.taskForm.controls.units.setValue([]);
	}

	submitForm() {
		if (this.taskForm.valid === true && this.parametersReady === true) this.submit.emit();
	}

	selectedUnitsChanged(units: Unit[]) {
		this.taskForm.controls.units.setValue(units);
		setTimeout(() => {
			this.taskForm.controls.units.markAsTouched();
		});
	}

	onParametersChanged(parameters: string) {
		this.taskForm.controls.parameters.setValue(parameters);
		setTimeout(() => {
			this.taskForm.controls.parameters.markAsTouched();
		});
	}

	onFilesSelected(event: ManageTaskParamsFileChangedEvent) {
		this.uploadFileEvent = event;
		this.emitOutput();
	}

	onValid(valid: boolean) {
		this.parametersReady = valid;
		this.emitOutput();
	}

	emitOutput() {
		const isValid = this.taskForm.valid === true && this.parametersReady === true;
		this.isValid.emit(isValid);
		if (isValid === true) {
			const form = { ...this.taskForm.value };
			let uploadFiles: File[] = [];
			let clearExistingFiles = false;
			if (this.uploadFileEvent != null) {
				uploadFiles = this.uploadFileEvent.uploadFiles;
				clearExistingFiles = this.uploadFileEvent.clearExistingFiles;
			}
			this.changes.emit({ taskForm: form, uploadFiles: uploadFiles, clearExistingFiles: clearExistingFiles });
		}
	}
}
