<form [formGroup]="settingsForm" autocomplete="off" novalidate>
	<ng-container>
		<ul ngbNav #panel="ngbNav" [activeId]="selectedTab" class="nav-tabs page-content-tabs">
			<li ngbNavItem="general">
				<a ngbNavLink><fa-icon icon="power-off"></fa-icon> General</a>
				<ng-template ngbNavContent>
					<br />
					<div class="col-12">
						<div class="mb-3">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" formControlName="enable" id="enable" />
								<label class="form-check-label" for="enable">Enable</label>
								<div ngbTooltip="Turn SSO on or off." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
									<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
								</div>
							</div>
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									(change)="confirmEnforceChange($event)"
									formControlName="enforce"
									id="enforce" />
								<label class="form-check-label" for="enforce">Require all logins via SSO</label>
								<div
									ngbTooltip="Disable form based login. Make sure to test login via SSO before checking this."
									placement="right"
									container="body"
									class="d-inline ms-1 align-self-baseline">
									<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="mb-3">
							<label for="adminGroup" class="form-label">Admin Group</label>
							<div
								ngbTooltip="The group returned by the Identity Provider that denotes an admin user. Required."
								placement="right"
								container="body"
								class="d-inline ms-1 align-self-baseline">
								<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
							</div>
							<input type="text" class="form-control" formControlName="adminGroup" id="adminGroup" />
							<shared-input-validation-messages
								*ngIf="readonly !== true"
								[control]="settingsForm.controls.adminGroup"></shared-input-validation-messages>
						</div>
					</div>
					<div class="col-12">
						<div class="mb-3">
							<label for="userGroup" class="form-label">User Group</label>
							<div
								ngbTooltip="The group returned by the Identity Provider that denotes a regular user. Required."
								placement="right"
								container="body"
								class="d-inline ms-1 align-self-baseline">
								<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
							</div>
							<input type="text" class="form-control" formControlName="userGroup" id="userGroup" />
							<shared-input-validation-messages
								*ngIf="readonly !== true"
								[control]="settingsForm.controls.userGroup"></shared-input-validation-messages>
						</div>
					</div>
					<div class="col-12">
						<div class="mb-3">
							<label for="installerGroup" class="form-label">Installer Group</label>
							<div
								ngbTooltip="The group returned by the Identity Provider that denotes an installer user. Optional."
								placement="right"
								container="body"
								class="d-inline ms-1 align-self-baseline">
								<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
							</div>
							<input type="text" class="form-control" formControlName="installerGroup" id="installerGroup" />
							<shared-input-validation-messages
								*ngIf="readonly !== true"
								[control]="settingsForm.controls.installerGroup"></shared-input-validation-messages>
						</div>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem="spSettings">
				<a ngbNavLink><fa-icon icon="certificate"></fa-icon> Service Provider</a>
				<ng-template ngbNavContent>
					<br />
					<div class="col">
						<h4>Signing</h4>
					</div>
					<div class="col-12">
						<div class="mb-3">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" formControlName="signRequests" id="signRequests" />
								<label class="form-check-label" for="signRequests">Sign Requests</label>
								<div
									ngbTooltip="Whether or not to digitally sign sign-in requests to the Identity Provider."
									placement="right"
									container="body"
									class="d-inline ms-1 align-self-baseline">
									<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
								</div>
							</div>
						</div>
					</div>
					<div class="mb-3 col-12">
						<label for="spSignatureCertificate" class="form-label">Signature Certificate Thumbprint</label>
						<div
							ngbTooltip="The thumbprint of the certificate used to sign requests. Certificate must be in the local machine store."
							placement="right"
							container="body"
							class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
						<input type="text" class="form-control" formControlName="spSignatureCertificate" id="spSignatureCertificate" />
						<shared-input-validation-messages
							*ngIf="readonly !== true"
							[control]="settingsForm.controls.spSignatureCertificate"></shared-input-validation-messages>
					</div>
					<br />
					<div class="col">
						<h4>Encryption</h4>
					</div>
					<div class="col-12">
						<div class="mb-3">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" formControlName="encryptAssertions" id="encryptAssertions" />
								<label class="form-check-label" for="encryptAssertions">Assertion Encryption</label>
								<div
									ngbTooltip="Whether or not SAML assertions from the Identity Provider are encrypted."
									placement="right"
									container="body"
									class="d-inline ms-1 align-self-baseline">
									<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
								</div>
							</div>
						</div>
					</div>
					<div class="mb-3 col-12">
						<label for="spEncryptionCertificate" class="form-label">Encryption Certificate Thumbprint</label>
						<div
							ngbTooltip="The thumbprint of the certificate used to encrypt the SAML assertion. Certificate must be in the local machine store."
							placement="right"
							container="body"
							class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
						<input type="text" class="form-control" formControlName="spEncryptionCertificate" id="spEncryptionCertificate" />
						<shared-input-validation-messages
							*ngIf="readonly !== true"
							[control]="settingsForm.controls.spEncryptionCertificate"></shared-input-validation-messages>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem="idpSettings">
				<a ngbNavLink><fa-icon icon="globe"></fa-icon> Identity Provider</a>
				<ng-template ngbNavContent>
					<br />
					<div class="mb-3 col-12">
						<label for="idpEntityId" class="form-label">Entity Id</label>
						<div
							ngbTooltip="The Id specified by the Identity Provider. Assertions must contain this value to pass validation."
							placement="right"
							container="body"
							class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
						<input type="text" class="form-control" formControlName="idpEntityId" id="idpEntityId" />
						<shared-input-validation-messages
							*ngIf="readonly !== true"
							[control]="settingsForm.controls.idpEntityId"></shared-input-validation-messages>
					</div>
					<div class="mb-3 col-12">
						<label for="idpRequestUri" class="form-label">Request URL</label>
						<div
							ngbTooltip="The URL where SSO login requests are sent."
							placement="right"
							container="body"
							class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
						<input type="text" class="form-control" formControlName="idpRequestUri" id="idpRequestUri" />
						<shared-input-validation-messages
							*ngIf="readonly !== true"
							[control]="settingsForm.controls.idpRequestUri"></shared-input-validation-messages>
					</div>
					<div class="mb-3 col-12">
						<label for="idpCertificate" class="form-label">Certificate Thumbprint</label>
						<div
							ngbTooltip="Provided by the Identity Provider. This is used to validate assertion signatures. Certificate must be in the local machine store."
							placement="right"
							container="body"
							class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
						<input type="text" class="form-control" formControlName="idpCertificate" id="idpCertificate" />
						<shared-input-validation-messages
							*ngIf="readonly !== true"
							[control]="settingsForm.controls.idpCertificate"></shared-input-validation-messages>
					</div>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="panel"></div>
	</ng-container>
	<div class="row"></div>
</form>
