<ng-container *ngIf="(selectedUnits?.length || 0) > 0">
	<div>
		<app-root-monitor-cloud-unit-selector-list
			[accountId]="accountId"
			[preSelectedUnits]="selectedUnits"
			[readonly]="true"></app-root-monitor-cloud-unit-selector-list>
	</div>
</ng-container>
<ng-container *ngIf="(selectedUnits?.length || 0) === 0">
	<div class="text-center font-italic box-shadow p-4">No Devices Selected</div>
</ng-container>
<div *ngIf="readonly !== true" class="mt-3">
	<button class="btn btn-secondary" (click)="editSelectedUnits()">Edit Selected Devices</button>
</div>
