import { Component, Input } from '@angular/core';
import { AlertMessageService, PageSidePanelConfig, PageSidePanelService, UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, forkJoin, take } from 'rxjs';
import { AccountService } from '../../../services/account.service';
import { Account, Alert } from '@razberi-ui/core/data-types';
import { AlertService } from '../../../services/alert.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-side-panel',
	templateUrl: './account-side-panel.component.html',
	styleUrls: ['./account-side-panel.component.scss'],
})
export class AccountSidePanelComponent {
	@Input() onEdit$: Observable<void> = new Observable<void>();

	subscriptions: Subscription = new Subscription();
	config: PageSidePanelConfig;
	activeTabKey: string;
	account: Account;
	accountAlerts: Alert[];
	hasSubscription: boolean = false;

	constructor(
		private readonly sidePanelService: PageSidePanelService,
		private readonly accountService: AccountService,
		private readonly alertService: AlertService,
		private readonly alertMessageService: AlertMessageService,
		private readonly utils: UtilitiesService,
		private readonly subscriptionService: SubscriptionService
	) {}

	ngOnInit() {
		this.sidePanelService.streams.sidePanelConfig$.subscribe((config) => {
			if (config == null || config.accountId == null) {
				this.account = null;
				this.activeTabKey = null;
				return;
			}

			if (config.accountId != null && config.accountId !== this.config?.accountId) {
				this.loadAccount(config);
			}

			this.config = config;
			this.activeTabKey = config?.activeTabKey ?? 'details';
		});

		this.onEdit$.subscribe((_) => {
			this.config.editAction(this.account);
		});
	}

	loadAccount(config: PageSidePanelConfig) {
		forkJoin({
			account: this.accountService.api.getAccount(config.accountId),
			notifications: this.alertService.api.getAlertsDays(config.accountId, 30),
		})
			.pipe(take(1))
			.subscribe({
				next: (result) => {
					let { account, notifications } = result;
					this.account = account;
					this.accountAlerts = notifications;

					this.hasSubscription = this.subscriptionService.helpers.isGlobalOrIsTypeAndStatusValid(
						account.subscription?.type,
						account.subscription?.status
					);
				},
				error: (error) => {
					this.alertMessageService.error('Error getting account details.', error);
				},
			});
	}

	dateFormat(date) {
		return this.utils.formatters.asLocalTime(date);
	}
}
