import { Component, Input, SimpleChanges } from '@angular/core';
import { AccountSubscription, AccountSubscriptionStatus, AccountSubscriptionType } from '@razberi-ui/core/data-types';
import * as moment from 'moment';

@Component({
	selector: 'app-root-monitor-cloud-cell-account-subscription-status',
	templateUrl: './cell-account-subscription-status.component.html',
	styleUrls: ['./cell-account-subscription-status.component.scss'],
})
export class CellAccountSubscriptionStatusComponent {
	@Input() subscription: AccountSubscription;
	@Input() tooltip: string;
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	icon: string;
	color: string;
	text: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = null;
		let color: string = 'status-red';
		let text: string = AccountSubscriptionStatus[AccountSubscriptionStatus.Inactive];

		if (this.subscription != null) {
			color =
				this.subscription.status === AccountSubscriptionStatus.Active && this.subscription.devices <= this.subscription.maxDevices
					? 'status-green'
					: this.subscription.status === AccountSubscriptionStatus.Expiring ||
					  (this.subscription.status === AccountSubscriptionStatus.Active && this.subscription.devices > this.subscription.maxDevices)
					? 'status-orange'
					: changes.subscription.currentValue.status === AccountSubscriptionStatus.Expired
					? 'status-red'
					: 'status-black';
			if (this.subscription.type > AccountSubscriptionType.None) {
				if (this.subscription.status === AccountSubscriptionStatus.Pending)
					text = `${AccountSubscriptionType[this.subscription.type]}: ${this.subscription.devices}/${this.subscription.maxDevices} device${
						this.subscription.maxDevices !== 1 ? 's' : ''
					} [${moment.utc(this.subscription.startDate).format('l')} - ${moment.utc(this.subscription.endDate).format('l')}]`;
				else
					text = `${AccountSubscriptionType[this.subscription.type]}: ${this.subscription.devices}/${this.subscription.maxDevices} device${
						this.subscription.maxDevices !== 1 ? 's' : ''
					} [${moment.utc(this.subscription.endDate).format('l')}]`;
			} else
				text = `${AccountSubscriptionType[this.subscription.type]}: ${this.subscription.devices} device${this.subscription.devices !== 1 ? 's' : ''}`;

			this.icon = icon;
			this.color = color;
			this.text = text;
		}
	}
}
