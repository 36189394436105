<form [formGroup]="alertDetail" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row mt-2 mb-3">
		<div class="col-6">
			<label for="name" class="form-label">Alert Profile Name <span class="text-primary">*</span></label>
			<input
				type="text"
				class="form-control"
				formControlName="name"
				id="name"
				[ngClass]="{ 'is-invalid': alertDetail.controls.name.invalid && alertDetail.controls.name.touched }"
				required />
			<shared-input-validation-messages [control]="alertDetail.controls.name"></shared-input-validation-messages>
		</div>
		<div class="col-6">
			<label for="accountName" class="form-label">Account <span class="text-primary">*</span></label>
			<input type="text" class="form-control" id="accountName" formControlName="accountName" />
		</div>
	</div>
	<div class="row mb-3">
		<div class="col-6">
			<label for="description" class="form-label">Description</label>
			<input type="text" class="form-control" formControlName="description" id="description" />
		</div>
		<div class="col-6">
			<label for="enabled" class="form-label">Enabled</label>
			<shared-toggle-switch [value]="enabled" (toggle)="toggleEnabled($event)"></shared-toggle-switch>
		</div>
	</div>
	<div class="row mt-4 mb-3">
		<div class="col d-flex">
			<h4>Alert Types</h4>
			<div
				placement="bottom"
				container="body"
				class="ms-1"
				ngbTooltip="Use to filter and limit alerts types that are sent. If no alerts types are selected, all alerts will pass to alert recipients. Use advanced filter to choose by category.">
				<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
			</div>
		</div>
	</div>
	<div class="mb-3">
		<label for="alertTypes" class="form-label">Selected Alert Types</label>
		<app-root-monitor-cloud-alert-type-selector
			(alertTypesChange)="handleAlertTypesChanged($event)"
			[config]="{ alertTypes: alertTypes, availableAlertTypes: availableAlertTypes }"></app-root-monitor-cloud-alert-type-selector>
	</div>
	<div class="mt-4 mb-3">
		<div class="col">
			<h4>Locations and Tags</h4>
		</div>
	</div>
	<div class="mb-3">
		<label for="locations" class="form-label">Locations</label>
		<shared-form-custom-search-select
			[id]="'locations'"
			[(selectModel)]="locations"
			[items]="availableLocations"
			[multiple]="true"
			(selectModelChange)="updateLocations($event)"></shared-form-custom-search-select>
	</div>
	<div class="mb-3">
		<div class="d-flex">
			<label for="tags" class="form-label">Tags</label>
			<div class="form-check ms-3">
				<input
					type="radio"
					id="tagsAny"
					name="tagsAny"
					class="form-check-input"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="matchType"
					(change)="updateMatchType()"
					[value]="matchTypeRef.Any" />
				<label for="tagsAny" class="form-check-label">Contains any</label>
			</div>
			<div class="form-check ms-3">
				<input
					type="radio"
					id="tagsAll"
					name="tagsAll"
					class="form-check-input"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="matchType"
					(change)="updateMatchType()"
					[value]="matchTypeRef.All" />
				<label for="tagsAll" class="form-check-label">Contains all</label>
			</div>
		</div>
		<div>
			<shared-form-custom-search-select
				[id]="'tags'"
				[(selectModel)]="tags"
				[items]="availableTags"
				[multiple]="true"
				(selectModelChange)="updateTags($event)"></shared-form-custom-search-select>
		</div>
	</div>
	<div>
		<div class="ms-1">{{ filteredDevices.length }} of {{ unfilteredDevices.length }} devices selected</div>
		<div class="ms-1 d-inline-flex" (click)="showSelectedDevicesModal()">
			<div class="p-0 me-1 icon-sm cursor-pointer"><fa-icon icon="search" size="lg"></fa-icon></div>
			<button type="button" class="btn btn-link p-0">View Selected Devices</button>
		</div>
	</div>
	<div class="mt-4 mb-3">
		<div class="col">
			<h4>Alert Distribution</h4>
		</div>
	</div>
	<div class="col">
		<app-root-monitor-cloud-alert-recipients-form
			#recipientsForm
			class="mt-4"
			(addRecipient)="addRecipient($event)"
			[accountUsers]="users"></app-root-monitor-cloud-alert-recipients-form>
	</div>
	<div class="col">
		<shared-data-table [tableSettings]="tableSettings" [tableItems]="recipients"></shared-data-table>
	</div>
</form>
