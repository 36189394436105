import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumService } from './services/enum.service';

@NgModule({
	imports: [CommonModule],
})
export class CoreDataTypesModule {
	static forRoot(): ModuleWithProviders<CoreDataTypesModule> {
		return {
			ngModule: CoreDataTypesModule,
			providers: [EnumService],
		};
	}
}
