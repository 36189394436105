import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-shared-monitor-common-dashboard-micro-bar-graph',
	templateUrl: './dashboard-micro-bar-graph.component.html',
	styleUrls: ['./dashboard-micro-bar-graph.component.scss'],
})
export class DashboardMicroBarGraphComponent implements OnInit {
	@Input() label: string;
	@Input() value: number;
	@Input() percentage: number;

	constructor() {}

	ngOnInit(): void {}
}
