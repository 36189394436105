import { Component, Input, SimpleChanges } from '@angular/core';
import { NetworkLinkStatus, NetworkLinkType } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-network-link-status',
	templateUrl: './cell-network-link-status.component.html',
	styleUrls: ['./cell-network-link-status.component.scss'],
})
export class CellNetworkLinkStatusComponent {
	@Input() data: any;
	@Input() networkLinkStatus: NetworkLinkStatus;
	@Input() networkLinkType: NetworkLinkType;
	@Input() tooltip: string;
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	icon: string;
	color: string;
	text: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = 'link';
		let color: string = 'status-light';
		let text: string = '--';

		if (changes.data && changes.data.currentValue) {
			this.networkLinkStatus = changes.data.currentValue.networkLinkStatus;
			this.networkLinkType = changes.data.currentValue.networkLinkType;
			this.tooltip = changes.data.currentValue.tooltip;
			this.disabled = changes.data.currentValue.disabled;
			this.showText = changes.data.currentValue.showText;
		}

		if (this.networkLinkType != null && this.networkLinkStatus != null) {
			if (this.networkLinkType === NetworkLinkType.Unknown || this.networkLinkType === NetworkLinkType.Unmonitored) {
				color = 'status-light';
				text = 'Unmonitored';
			} else {
				color =
					this.networkLinkStatus === NetworkLinkStatus.Unknown
						? 'status-light'
						: this.networkLinkStatus === NetworkLinkStatus.Connected
						? 'status-green'
						: this.networkLinkStatus === NetworkLinkStatus.Unavailable
						? 'status-orange'
						: 'status-red';
				text =
					this.networkLinkStatus === NetworkLinkStatus.Unknown
						? 'Unknown'
						: this.networkLinkStatus === NetworkLinkStatus.Connected
						? 'Connected'
						: this.networkLinkStatus === NetworkLinkStatus.Unavailable
						? 'Unavailable'
						: 'Disconnected';
			}
		}

		this.icon = icon;
		this.color = color;
		this.text = text;
	}
}
