import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavItem, NavService, PageConfigService, VersionService } from '@razberi-ui/shared';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'theme-razberi-core-sidenav',
	templateUrl: './theme-razberi-core-sidenav.component.html',
	styleUrls: ['./theme-razberi-core-sidenav.component.scss'],
})
export class ThemeRazberiCoreSidenavComponent implements OnInit {
	@Input() isCollapsed: boolean = false;
	@Output() collapse: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscriptions: Subscription = new Subscription();
	version: string;
	accountLogo: string;
	navItems: NavItem[] = [];

	constructor(
		readonly navService: NavService,
		private readonly router: Router,
		private readonly versionService: VersionService,
		readonly pageConfigService: PageConfigService
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.versionService.streams.version$.subscribe((version) => {
				this.version = version ?? 'N/A';
			})
		);
	}

	setNavItem(item: NavItem) {
		if (item.disabled === true) {
			if (item.disabledAction! + null) item.disabledAction();
			return;
		}

		if (this.navService.data.hasError === true) return;

		if (!item.active) {
			// if (item.subscriptionRequired && !this.subscriptionService.isGlobalOrIsValid() && this.authService.account.type !== AccountType.Provider) {
			// 	this.subscriptionService.showInvalidSubscriptionModal();
			// 	return;
			// }

			this.navItems.map((navItem) => {
				navItem.active = false;
			});
			item.active = true;
			this.router.navigate([item.routerLink]);
		}
	}

	toggleCollapse() {
		this.collapse.emit(!this.isCollapsed);
	}
}
