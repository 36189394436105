import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[autofocusIf]',
})
export class AutofocusIfDirective {
	private focus;
	constructor(private el: ElementRef) {}

	ngOnInit() {
		if (this.focus === true || typeof this.focus === 'undefined') this.el.nativeElement.focus();
	}

	@Input() set autofocusIf(condition: boolean) {
		this.focus = condition != false;
	}
}
