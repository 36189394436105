import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '@razberi-ui/shared';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
	selector: 'theme-razberi-core',
	templateUrl: './theme-razberi-core.component.html',
	styleUrls: ['./theme-razberi-core.component.scss'],
})
export class ThemeRazberiCoreComponent implements OnInit {
	@ContentChild('sidePanelOutlet') sidePanelOutletRef?: TemplateRef<any>;

	subscriptions: Subscription = new Subscription();
	isSidenavCollapsed: boolean;
	usePageLayout: boolean = true;
	useCardLayout: boolean = false;
	useFixedLayout: boolean = false;
	cardSize: string = undefined;
	cardSizeClass: string = undefined;
	hideSpinner: boolean = false;

	constructor(private readonly navService: NavService, private readonly activatedRoute: ActivatedRoute) {}

	ngOnInit() {
		this.navService.streams.navEndEvents$
			.pipe(
				map(() => this.activatedRoute),
				map((route) => route.firstChild),
				switchMap((route) => route?.data || of(null)),
				map((data) => {
					this.useCardLayout = data?.useCardLayout === true;
					this.cardSize = data?.cardSize;
					this.useFixedLayout = data?.useFixedLayout === true;
					this.usePageLayout = this.useCardLayout !== true && this.useFixedLayout !== true;
					this.hideSpinner = data?.hideSpinner === true;
					this.setClasses();
				})
			)
			.subscribe();
	}

	ngOnDestroy() {}

	onSidenavCollapse(event: boolean) {
		this.isSidenavCollapsed = event;
	}

	setClasses() {
		switch (this.cardSize) {
			case 'xxxs':
				this.cardSizeClass = 'card-size-xxxs';
				break;
			case 'xxs':
				this.cardSizeClass = 'card-size-xxs';
				break;
			case 'xs':
				this.cardSizeClass = 'card-size-xs';
				break;
			case 'sm':
				this.cardSizeClass = 'card-size-sm';
				break;
			case 'lg':
				this.cardSizeClass = 'card-size-lg';
				break;
			default:
				this.cardSizeClass = 'card-size-md';
				break;
		}
	}
}
