<div class="row">
	<div class="col-sm-12 d-flex flex-column">
		<ng-container *ngIf="(manageTaskInstances?.length || 0) === 0">
			<div class="p-5 w-100 text-center box-shadow">
				<i>No Devices Available</i>
			</div>
		</ng-container>
		<ng-container *ngIf="(manageTaskInstances?.length || 0) > 0">
			<div style="flex: 1">
				<shared-data-table
					*ngIf="isInitialized === true && tableSettings != null"
					[tableSettings]="tableSettings"
					[tableItems]="filteredTaskInstances"
					(pagingChanged)="onPagingChanged($event)">
					<app-root-monitor-cloud-data-table-search-form
						*ngIf="showSearch === true && searchConfig != null && initialSearchFilter != null"
						dataTableSearch
						[searchConfiguration]="searchConfig"
						[accountIds]="[accountId]"
						[initialSearchFilter]="initialSearchFilter"
						(applyFilter)="onSearch($event)"></app-root-monitor-cloud-data-table-search-form>
					<ng-template #dataTableCellTypes let-value="value" let-column="column" let-tableItem="tableItem">
						<app-root-monitor-cloud-data-table-cell-types
							[value]="value"
							[column]="column"
							[tableItem]="tableItem"></app-root-monitor-cloud-data-table-cell-types>
					</ng-template>
				</shared-data-table>
			</div>
		</ng-container>
	</div>
</div>
