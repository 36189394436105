<div class="page-toolbar d-flex justify-content-between align-items-center sticky-top px-3 py-2">
	<div class="page-toolbar-title">
		<span *ngIf="navService.data.breadcrumbsTitle?.length > 0" class="me-2">{{ navService.data.breadcrumbsTitle }}:</span>
		<span *ngFor="let crumb of navService.streams.navBreadcrumbs$ | async; let i = index; let last = last">
			<a *ngIf="crumb.url?.length > 0 && last !== true" [routerLink]="[crumb.url]">{{ crumb.title }}</a>
			<span *ngIf="(crumb.url?.length || 0) == 0 || last === true">{{ crumb.title }}</span>
			<span *ngIf="last !== true" class="mx-2">&gt;</span>
		</span>
	</div>
	<div class="page-toolbar-buttons d-flex flex-row-reverse align-items-center">
		<ng-container pagePortalOutlet [outletKey]="'toolbarOutlet'"></ng-container>
		<button
			*ngFor="let button of configuration?.buttons"
			type="button"
			class="btn ms-2 btn-{{ button.color }}"
			[disabled]="!button.isEnabled"
			(click)="button.click()">
			<fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
			{{ button.text }}
		</button>
		<div *ngFor="let checkbox of configuration?.checkboxes; let rowIndex = index" class="form-check me-3">
			<input
				type="checkbox"
				class="form-check-input"
				id="page-toolbar-checkbox_{{ rowIndex }}"
				[ngModel]="checkbox.isChecked"
				[disabled]="!checkbox.isEnabled"
				(change)="checkbox.change($event.target.checked)" />
			<label for="page-toolbar-checkbox_{{ rowIndex }}" class="form-check-label">
				<fa-icon *ngIf="checkbox.icon" [icon]="checkbox.icon"></fa-icon>
				{{ checkbox.text }}
			</label>
		</div>
	</div>
</div>
