import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageToolbarConfiguration } from '../data-types/page-toolbar-configuration.model';
import { PageFooterConfiguration } from '../data-types/page-footer-configuration.model';

@Injectable({
	providedIn: 'root',
})
export class PageConfigService {
	private store: {
		isAuthenticated: boolean;
		isAuthenticated$: BehaviorSubject<boolean>;
		toolbarConfig: PageToolbarConfiguration;
		toolbarConfig$: BehaviorSubject<PageToolbarConfiguration>;
		footerConfig: PageFooterConfiguration;
		footerConfig$: BehaviorSubject<PageFooterConfiguration>;
	} = {
		isAuthenticated: undefined,
		isAuthenticated$: new BehaviorSubject(false),
		toolbarConfig: undefined,
		toolbarConfig$: new BehaviorSubject(undefined),
		footerConfig: undefined,
		footerConfig$: new BehaviorSubject(undefined),
	};

	constructor() {}

	get data() {
		const store = this.store;

		return {
			get isAuthenticated(): boolean {
				return store.isAuthenticated;
			},
			set isAuthenticated(value: boolean) {
				store.isAuthenticated = value;
				store.isAuthenticated$.next(store.isAuthenticated);
			},
			set toolbarConfig(value: PageToolbarConfiguration) {
				store.toolbarConfig = value;
				store.toolbarConfig$.next(store.toolbarConfig);
			},
			set footerConfig(value: PageFooterConfiguration) {
				store.footerConfig = value;
				store.footerConfig$.next(store.footerConfig);
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get isAuthenticated$(): Observable<boolean> {
				return store.isAuthenticated$.asObservable();
			},
			get toolbarConfig$(): Observable<PageFooterConfiguration> {
				return store.toolbarConfig$.asObservable();
			},
			get footerConfig$(): Observable<PageToolbarConfiguration> {
				return store.footerConfig$.asObservable();
			},
		};
	}
}
