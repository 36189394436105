<div class="d-flex align-items-center">
	<div *ngIf="user?.isPrimary == true" class="d-inline-flex context-pill pill-primary me-1">Primary User</div>
	<div *ngIf="user?.status === UserStatusOptions.Pending" class="d-inline-flex context-pill pill-warning me-1">Pending</div>
	<div *ngIf="user?.status === UserStatusOptions.Locked" class="d-inline-flex context-pill pill-danger me-1">Locked</div>
	<ng-container *ngFor="let role of user?.roles | slice: 0:displayCount">
		<div *ngIf="roleIsValid(role) == true" class="d-inline-flex context-pill pill-dark me-1">{{ getRoleName(role) }}</div>
	</ng-container>
	<div ngbDropdown *ngIf="user?.roles?.length > displayCount">
		<span ngbDropdownToggle class="tag-cell-drop ps-2 pe-2" (click)="$event.stopPropagation()">+ {{ user?.roles?.length - displayCount }}</span>
		<div class="p-2" ngbDropdownMenu>
			<ng-container *ngFor="let role of user.roles | slice: displayCount">
				<div class="mb-1 me-1">{{ getRoleName(role) }}</div>
			</ng-container>
		</div>
	</div>
	<div *ngIf="(user?.roles?.length || 0) === 0">
		<div class="d-inline-flex context-pill pill-dark">Default User</div>
	</div>
</div>
