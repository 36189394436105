import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertMessageService, ConfirmService, PageConfigService, TableItemSelectMode, TableSettings } from '@razberi-ui/shared';
import { LocationService } from '../../../../../services/location.service';
import { LocationModalComponent } from '../../../../locations/location-modal/location-modal.component';
import { Location } from '@razberi-ui/core/data-types';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-locations',
	templateUrl: './account-view-tab-locations.component.html',
	styleUrls: ['./account-view-tab-locations.component.scss'],
})
export class AccountViewTabLocationsComponent {
	accountId?: number;
	locations: Location[];
	tableSettings: TableSettings;
	isInitialized: boolean = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private alertMessageService: AlertMessageService,
		private pageConfigService: PageConfigService,
		private confirmService: ConfirmService,
		private authService: AuthService,
		private locationService: LocationService
	) {
		this.locations = [];
		this.tableSettings = {
			useSearch: true,
			itemSelectMode: TableItemSelectMode.Multi,
			useEdit: true,
			usePageSize: true,
			hidePagination: false,
			uniqueId: 'locationId',
			columnConfig: [
				{
					primaryKey: 'name',
					header: 'Name',
					useForSearch: true,
					cellType: 'link-button',
					width: '400px',
					transform: (value) => {
						return { text: value, icon: 'map-marker-alt' };
					},
					clickHandlerFunction: (params, val) => {
						this.manageLocation(params);
					},
				},
				{ primaryKey: 'description', header: 'Description', useForSearch: true },
			],
			headerButtons: [
				{
					label: 'Delete',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteLocations(params.value);
					},
				},
			],
			editActions: [
				{
					label: 'Edit',
					faIcon: 'edit',
					clickHandlerFunction: (params) => {
						this.manageLocation(params);
					},
				},
				{
					label: 'Delete',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteLocation(params);
					},
				},
			],
		};
	}

	ngOnInit() {
		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;
		this.pageConfigService.data.toolbarConfig = {
			buttons: [
				{
					text: 'New Location',
					icon: 'plus',
					color: 'primary',
					click: () => {
						this.manageLocation();
					},
				},
			],
		};
		this.getLocations();
	}

	ngOnDestroy() {
		this.pageConfigService.data.toolbarConfig = null;
	}

	getLocations() {
		this.locationService.api.getLocations(this.accountId ?? this.authService.data.account.accountId).subscribe({
			next: (locations: Location[]) => {
				this.locations = locations.sort((l1: Location, l2: Location) => (l1.name > l2.name ? 1 : -1));
				this.isInitialized = true;
			},
			error: (error) => {
				this.alertMessageService.error('Error getting locations.', error);
			},
		});
	}

	manageLocation(location?: Location) {
		const modal = this.modalService.open(LocationModalComponent, { backdrop: 'static', centered: true });
		if (location) modal.componentInstance.locationId = location.locationId;
		modal.componentInstance.accountId = this.accountId ?? this.authService.data.account.accountId;
		modal.result.then(
			(_) => {
				this.getLocations();
			},
			(_) => {}
		);
	}

	deleteLocation(location: Location) {
		this.confirmService
			.confirm({
				title: 'Delete location?',
				text: `Are you sure you want to delete ${location.name}?`,
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(() => {
				this.locationService.api.deleteLocation(this.accountId, location.locationId).subscribe({
					next: (_) => {
						this.alertMessageService.success('Location deleted.');
						this.getLocations();
					},
					error: (error) => {
						this.alertMessageService.error('Error deleting location.', error);
					},
				});
			});
	}

	deleteLocations(locations: Location[]) {
		this.confirmService
			.confirm({
				title: 'Delete locations?',
				text: `Are you sure you want to delete ${locations.length} locations?`,
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(() => {
				let locIds = locations.map((loc) => loc.locationId);
				this.locationService.api.deleteLocations(this.accountId, locIds).subscribe({
					next: (_) => {
						this.alertMessageService.success('Locations deleted.');
						this.getLocations();
					},
					error: (error) => {
						this.alertMessageService.error('Error deleting locations.', error);
					},
				});
			});
	}
}
