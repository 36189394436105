<div class="page-container">
	<div class="page-content container-fluid">
		<ng-container *ngIf="(manageTask?.manageTaskId || 0) === 0">
			<div class="container py-4">
				<div class="row justify-content-center mb-5">
					<div class="col-10">
						<app-root-monitor-cloud-manage-task-form
							[accountId]="accountId"
							[manageTask]="manageTask"
							(changes)="onChanges($event)"
							(isValid)="onIsValid($event)"
							(submit)="(onSubmit)"></app-root-monitor-cloud-manage-task-form>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="manageTask?.manageTaskId > 0">
			<div class="mt-3">
				<div class="row">
					<div class="col-sm-9 d-flex align-items-center justify-content-start">
						<div class="flex flex-column">
							<div class="h3 mb-1">{{ manageTask?.name || 'Unkown Task' }}</div>
							<div class="text-muted">ID: {{ manageTask?.manageTaskGuid?.toUpperCase() || '--' }}</div>
						</div>
					</div>
					<div class="col-sm-3 d-flex justify-content-end">
						<div class="d-flex align-items-center">
							<div
								class="cursor-pointer"
								(click)="toggleRefresh()"
								[ngClass]="{ 'status-color-green': refreshActive === true }"
								title="Click to {{ refreshActive === true ? 'disable' : 'enable' }} auto refresh">
								<fa-icon icon="sync" size="lg"></fa-icon>
								<b class="ms-3 me-3">Auto Refresh</b>
								<span *ngIf="refreshActive !== true" class="font-italic">DISABLED</span>
								<span *ngIf="refreshActive === true" class="font-italic">{{ refreshRemaining }} secs</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-sm-9">
						<div class="row box-shadow p-3 me-3">
							<div class="col-sm-7">
								<div class="row">
									<div class="col-sm-4 mt-3 font-thick">
										<label>Status</label>
									</div>
									<div class="col-sm-8 mt-3 d-flex">
										<app-root-monitor-cloud-cell-manage-task-status
											[status]="manageTask?.status"></app-root-monitor-cloud-cell-manage-task-status>
										<ng-template #statusFailedTooltip>
											Tasks fail if not complete within<br />
											2 hours, or 15 mins after its<br />
											last target completes.
										</ng-template>
										<div
											*ngIf="manageTask?.status === taskStatusOptions.Failed"
											[ngbTooltip]="statusFailedTooltip"
											placement="right"
											container="body"
											class="d-inline ms-1 align-self-baseline">
											<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
										</div>
									</div>
									<div class="col-sm-4 mt-3 font-thick">
										<label>Task Type</label>
									</div>
									<div class="col-sm-8 mt-3">
										{{ manageTask?.manageTaskTypeName || '--' }}
									</div>
									<div class="col-sm-4 mt-3 font-thick">
										<label>Account</label>
									</div>
									<div class="col-sm-8 mt-3">
										{{ manageTask?.accountName || '--' }}
									</div>
									<div class="col-sm-4 mt-3 font-thick">
										<label>Notifications</label>
									</div>
									<div class="col-sm-8 mt-3">
										{{ manageTask?.notificationType != null ? notificationTypeOptions[manageTask?.notificationType] : 'None' }}
										{{ manageTask?.notificationType == notificationTypeOptions.Both ? ' (Email + SMS)' : '' }}
									</div>
									<div class="col-sm-4 mt-3 font-thick">
										<label>Task Description</label>
									</div>
									<div class="col-sm-8 mt-3">
										<shared-expandable-display-string
											[value]="manageTask?.description"
											[expandIndex]="50"></shared-expandable-display-string>
									</div>
								</div>
							</div>
							<div class="col-sm-5">
								<div class="row">
									<div class="col-sm-6 mt-3 font-thick">
										<label>
											Concurrent &nbsp;
											<div
												ngbTooltip="The number instances that will run the task at the same time.  Set to '0' to run all instances at once."
												placement="right"
												container="body"
												class="d-inline ms-1 align-self-baseline">
												<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
											</div>
										</label>
									</div>
									<div class="col-sm-6 mt-3">
										{{ manageTask?.concurrent || 0 }}
									</div>
									<div class="col-sm-6 mt-3 font-thick">
										<label>
											Error Threshold &nbsp;
											<div
												ngbTooltip="The maximum number of instances that experience errors before a task is failed.   Set to “0” for the task to continue regardless of instance status."
												placement="right"
												container="body"
												class="d-inline ms-1 align-self-baseline">
												<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
											</div>
										</label>
									</div>
									<div class="col-sm-6 mt-3">
										{{ manageTask?.errorHandling || 0 }}
									</div>
									<div class="col-sm-6 mt-3 font-thick">
										<label>Start At</label>
									</div>
									<div class="col-sm-6 mt-3">
										{{ manageTask?.executeAt != null ? (manageTask?.executeAt | date: 'MM/dd/yy @ HH:mm') : '--' }}
									</div>
									<div class="col-sm-6 mt-3 font-thick">
										<label>Started</label>
									</div>
									<div class="col-sm-6 mt-3">
										{{ manageTask?.startTimestamp != null ? (manageTask?.startTimestamp | date: 'MM/dd @ HH:mm:ss') : '--' }}
									</div>
									<div class="col-sm-6 mt-3 font-thick">
										<label>Completed</label>
									</div>
									<div class="col-sm-6 mt-3">
										{{ manageTask?.completeTimestamp != null ? (manageTask?.completeTimestamp | date: 'MM/dd @ HH:mm:ss') : '--' }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="row p-3 box-shadow" style="background-color: #fafafa">
							<div>
								<h5>Device Summary ({{ manageTask?.totalInstances || '0' }})</h5>
							</div>
							<div class="row mt-3 mx-3">
								<div class="col-sm-8 mt-1 font-thick status-color-black">
									<label><fa-icon icon="clock" class="me-2"></fa-icon>Scheduled</label>
								</div>
								<div class="col-sm-4 mt-1">
									{{ manageTask?.scheduledInstances || '--' }}
								</div>
								<div class="col-sm-8 mt-1 font-thick status-color-blue">
									<label><fa-icon icon="play" class="me-2"></fa-icon>Executing</label>
								</div>
								<div class="col-sm-4 mt-1">
									{{ manageTask?.executingInstances || '--' }}
								</div>
								<div class="col-sm-8 mt-1 font-thick status-color-green">
									<label><fa-icon icon="check" class="me-2"></fa-icon>Succeeded</label>
								</div>
								<div class="col-sm-4 mt-1">
									{{ manageTask?.succeededInstances || '--' }}
								</div>
								<div class="col-sm-8 mt-1 font-thick status-color-red">
									<label><fa-icon icon="times" class="me-2"></fa-icon>Failed</label>
								</div>
								<div class="col-sm-4 mt-1">
									{{ manageTask?.failedInstances || '--' }}
								</div>
								<div class="col-sm-8 mt-1 font-thick status-color-orange">
									<label><fa-icon icon="ban" class="me-2"></fa-icon>Cancelled</label>
								</div>
								<div class="col-sm-4 mt-1">
									{{ manageTask?.cancelledInstances || '--' }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-3">
					<ul
						ngbNav
						#panel="ngbNav"
						[activeId]="
							manageTask?.status === taskStatusOptions.Scheduled && (manageTask?.manageTaskInstances?.length || 0) === 0
								? 'task-parameters'
								: 'task-instances'
						"
						class="nav-tabs page-content-tabs">
						<li ngbNavItem="task-parameters">
							<a ngbNavLink><fa-icon icon="clipboard-check"></fa-icon> Task Parameters</a>
							<ng-template ngbNavContent>
								<div class="p-3 mt-3">
									<app-root-monitor-cloud-manage-task-form-parameters
										[accountId]="manageTask?.accountId"
										[manageTaskTypeId]="manageTask?.manageTaskTypeId"
										[parameters]="manageTask?.parameters"
										[taskFiles]="manageTask?.manageTaskFiles"
										[readonly]="true"
										class="w-100"></app-root-monitor-cloud-manage-task-form-parameters>
								</div>
							</ng-template>
						</li>
						<li ngbNavItem="task-targets">
							<a ngbNavLink><fa-icon icon="clipboard-check"></fa-icon> Task Targets ({{ manageTask?.units?.length || 0 }})</a>
							<ng-template ngbNavContent>
								<div class="py-3">
									<app-root-monitor-cloud-unit-selector
										[accountId]="manageTask?.accountId"
										[preSelectedUnits]="manageTask?.units"
										[readonly]="true"></app-root-monitor-cloud-unit-selector>
								</div>
							</ng-template>
						</li>
						<li ngbNavItem="task-instances">
							<a ngbNavLink><fa-icon icon="server"></fa-icon> Task Devices ({{ manageTask?.manageTaskInstances?.length || 0 }})</a>
							<ng-template ngbNavContent>
								<div
									*ngIf="manageTask?.status === taskStatusOptions.Scheduled && (manageTask?.manageTaskInstances?.length || 0) === 0"
									class="p-5 w-100 text-center font-italic box-shadow">
									No devices available - task not started.
								</div>
								<div *ngIf="manageTask?.status !== taskStatusOptions.Scheduled || manageTask?.manageTaskInstances?.length > 0" class="py-3">
									<app-root-monitor-cloud-manage-task-instance-table
										[accountId]="accountId"
										[manageTaskId]="manageTask?.manageTaskId"
										[manageTaskInstances]="manageTask?.manageTaskInstances"></app-root-monitor-cloud-manage-task-instance-table>
								</div>
							</ng-template>
						</li>
					</ul>
					<div [ngbNavOutlet]="panel"></div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
