import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'shared-cell-header-sub',
	templateUrl: './cell-header-sub.component.html',
	styleUrls: ['./cell-header-sub.component.scss'],
})
export class CellHeaderSubComponent {
	@Input() data: any;
	@Input() header: string;
	@Input() sub: string;

	ngOnChanges(changes: SimpleChanges) {
		if (this.data?.header?.length > 0 || this.data?.sub?.length > 0) {
			this.header = this.data?.header?.length > 0 ? this.data?.header : '--';
			this.sub = this.data?.sub?.length > 0 ? this.data?.sub : '--';
		}

		if ((this.header?.length || 0) == 0 && (this.sub?.length || 0) == 0) {
			this.header = '--';
			this.sub = null;
		}
	}
}
