import { AlertMessageType } from './alert-message-type.model';

export class AlertMessage {
	id: string;
	type: AlertMessageType;
	message: string;
	autoClose: boolean;
	keepAfterRouteChange: boolean;
	fade: boolean;

	constructor(init?: Partial<AlertMessage>) {
		Object.assign(this, init);
	}
}
