import { Account, AlertType, Camera, EnumItem, ManageTaskType, RecordingServer, Tag, Unit, UpdateType, User, VmsService } from '@razberi-ui/core/data-types';
import { CoreSearchItem, DateRange } from '@razberi-ui/shared';

export class SearchFilterItems {
	accounts?: CoreSearchItem<Account>[] = [];
	accountTypes?: CoreSearchItem<EnumItem>[] = [];
	accountStatuses?: CoreSearchItem<EnumItem>[] = [];
	accountSubscriptionTypes?: CoreSearchItem<EnumItem>[] = [];
	accountSubscriptionStatuses?: CoreSearchItem<EnumItem>[] = [];
	providerAccounts?: CoreSearchItem<Account>[] = [];
	users?: CoreSearchItem<User>[] = [];
	userRoles?: CoreSearchItem<EnumItem>[] = [];
	userStatuses?: CoreSearchItem<EnumItem>[] = [];
	locations?: CoreSearchItem<Location>[] = [];
	tags?: CoreSearchItem<Tag>[] = [];
	units?: CoreSearchItem<Unit>[] = [];
	unitStatuses?: CoreSearchItem<EnumItem>[] = [];
	alertTypes?: CoreSearchItem<AlertType>[] = [];
	alertStatuses?: CoreSearchItem<EnumItem>[] = [];
	vmsServices?: CoreSearchItem<VmsService>[] = [];
	recordingServers?: CoreSearchItem<RecordingServer>[] = [];
	cameras?: CoreSearchItem<Camera>[] = [];
	manageTaskStatuses?: CoreSearchItem<EnumItem>[] = [];
	manageTaskTypes?: CoreSearchItem<ManageTaskType>[] = [];
	dateRange?: DateRange;
	searchTerm?: string;
	updateTypes?: CoreSearchItem<UpdateType>[] = [];
	managedDeviceTypes?: CoreSearchItem<EnumItem>[] = [];
	managedDeviceStatuses?: CoreSearchItem<EnumItem>[] = [];
	networkLinkStatuses?: CoreSearchItem<EnumItem>[] = [];
}
