import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@razberi-ui/core/data-types';
import { AccountService } from '../../../services/account.service';
import { NavService } from '@razberi-ui/shared';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view',
	templateUrl: './account-view.component.html',
	styleUrls: ['./account-view.component.scss'],
})
export class AccountViewComponent {
	accountId?: number;
	account?: Account;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly navService: NavService,
		private readonly authService: AuthService,
		private readonly accountsService: AccountService
	) {}

	ngOnInit() {
		if (this.activatedRoute.snapshot.paramMap.has('accountId')) this.accountId = Number(this.activatedRoute.snapshot.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;
		this.accountsService.api.getAccount(this.accountId).subscribe((account: Account) => {
			this.account = account;
			this.navService.helpers.addTitlePlaceholder({ key: '{{name}}', value: account.name });
		});
	}

	ngOnDestroy() {
		this.navService.helpers.removeTitlePlaceholder('{{name}}');
	}
}
