import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToggleSwitchEvent } from '../../data-types/toggle-switch-event.model';
import { UtilitiesService } from '../../services/utilities.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'shared-toggle-switch',
	templateUrl: './toggle-switch.component.html',
	styleUrls: ['./toggle-switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ToggleSwitchComponent,
			multi: true,
		},
	],
})
export class ToggleSwitchComponent implements ControlValueAccessor {
	@ViewChild('input', { static: true, read: ElementRef }) inputElementRef: ElementRef;
	@Input() text: string;
	@Input() id: string;
	@Input() value: boolean;
	@Input() disabled: boolean;
	@Output() toggle: EventEmitter<ToggleSwitchEvent> = new EventEmitter<ToggleSwitchEvent>();

	propagateChange = (_: any) => {};
	propagateTouch = () => {};
	//value: boolean;
	isReadonly: boolean = false;

	constructor(private readonly utils: UtilitiesService) {
		this.id = `toggle_${this.utils.helpers.getRandomString()}`;
	}

	setValue(obj: any): void {
		this.value = obj;
	}

	writeValue(obj: any): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isReadonly = isDisabled;
	}

	onChange($event) {
		this.value = $event.target.checked;
		this.propagateTouch();
		this.propagateChange(this.value);
		let cancelled: boolean = false;
		this.toggle.emit({
			value: this.value,
			cancel: () => {
				cancelled = true;
				this.value = !$event.target.checked;
				$event.preventDefault();
			},
		});
	}
}
