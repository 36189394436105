import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Tag } from '@razberi-ui/core/data-types';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-tag-form',
	templateUrl: './tag-form.component.html',
	styleUrls: ['./tag-form.component.scss'],
})
export class TagFormComponent {
	@Input() tag: Tag;
	@Input() reset$: Observable<void>;
	@Output() isValid: EventEmitter<boolean>;
	@Output() changes: EventEmitter<Tag>;
	@Output() submit: EventEmitter<void>;
	tagForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private fb: UntypedFormBuilder) {
		this.tagForm = this.fb.group({
			name: [null, [Validators.required]],
			description: [null, [Validators.required]],
		});
		this.subscriptions = new Subscription();
		this.isValid = new EventEmitter<boolean>();
		this.changes = new EventEmitter<Tag>();
		this.submit = new EventEmitter<void>();
	}

	ngOnInit() {
		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.tagForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.tagForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.tagForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		this.tagForm.updateValueAndValidity();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.tag?.currentValue) this.tagForm.patchValue(changes.tag.currentValue);
	}

	submitForm() {
		if (this.tagForm.valid) this.submit.emit();
	}
}
