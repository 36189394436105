<form id="form-device" [formGroup]="deviceForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row">
		<div class="col">
			<div class="mb-3 col">
				<label for="serialNumber" class="form-label">Serial number <span class="text-primary">*</span></label>
				<input type="text" class="form-control" id="serialNumber" formControlName="serialNumber" required />
				<shared-input-validation-messages [control]="deviceForm.controls.serialNumber"></shared-input-validation-messages>
			</div>
			<div class="mb-3 col">
				<label for="modelName" class="form-label">Model name <span class="text-primary">*</span></label>
				<input type="text" class="form-control" id="modelName" formControlName="modelName" required />
				<shared-input-validation-messages [control]="deviceForm.controls.modelName"></shared-input-validation-messages>
			</div>
			<div class="mb-3 col">
				<label for="name" class="form-label"
					>Friendly name <span class="text-primary"><fa-icon icon="info-circle" ngbTooltip="Custom name for this device"></fa-icon></span
				></label>
				<input type="text" class="form-control" id="name" formControlName="name" />
			</div>
			<div *ngIf="canEditAccount">
				<div class="mb-3 col">
					<label for="accountId" class="form-label">Account <span class="text-primary">*</span></label>
					<ng-select
						labelForId="accountId"
						formControlName="accountId"
						[items]="accounts"
						bindValue="accountId"
						bindLabel="name"
						[clearable]="false"
						required></ng-select>
					<shared-input-validation-messages [control]="deviceForm.controls.accountId"></shared-input-validation-messages>
				</div>
			</div>
			<div class="mb-3 col">
				<label for="locationId" class="form-label">Location</label>
				<ng-select labelForId="locationId" formControlName="locationId" [items]="locations" bindValue="locationId" bindLabel="name">
					<ng-template ng-notfound-tmp>
						<div class="ng-option ng-option-disabled">No locations found</div>
					</ng-template>
					<ng-template ng-footer-tmp>
						<div (click)="addNewLocation()" class="add-new text-primary">+ Add new location</div>
					</ng-template>
				</ng-select>
			</div>
			<div class="mb-3 col">
				<label for="tagIds" class="form-label"
					>Tags <span class="text-primary"><fa-icon icon="info-circle" ngbTooltip="Relevant keywords for this device"></fa-icon></span
				></label>
				<ng-select
					labelForId="tagIds"
					formControlName="tagIds"
					[items]="tags"
					bindValue="tagId"
					bindLabel="name"
					[multiple]="true"
					[closeOnSelect]="false">
					<ng-template ng-notfound-tmp>
						<div class="ng-option ng-option-disabled">No tags found</div>
					</ng-template>
					<ng-template ng-footer-tmp>
						<div (click)="addNewTag()" class="add-new text-primary">+ Add new tag</div>
					</ng-template>
				</ng-select>
			</div>
		</div>
	</div>
</form>
