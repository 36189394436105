<div class="container mt-5">
	<div class="row justify-content-center">
		<div class="col-8">
			<app-root-monitor-cloud-account-form
				[isCurrentAccount]="isCurrentAccount"
				[isCurrentPrimaryUser]="isCurrentPrimaryUser"
				[editorAccountType]="editorAccountType"
				[account]="account"
				[admins]="admins"
				[providers]="providers"
				(isValid)="onAccountIsValid($event)"
				(changes)="onAccountChanges($event)"
				(submit)="onSubmit()"></app-root-monitor-cloud-account-form>
		</div>
	</div>
	<div *ngIf="showNote" class="row justify-content-center mt-5">
		<div class="col-8">
			<app-root-monitor-cloud-account-note-form
				[accountNote]="accountNote"
				(isValid)="onAccountNoteIsValid($event)"
				(changes)="onAccountNoteChanges($event)"
				(submit)="onSubmit()"></app-root-monitor-cloud-account-note-form>
		</div>
	</div>
</div>
