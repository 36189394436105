import { ModuleWithProviders, NgModule } from '@angular/core';
import { CellCameraDefenseStatusComponent } from './components/core/cell-types/cell-camera-defense-status/cell-camera-defense-status.component';
import { CellDeviceStatusComponent } from './components/core/cell-types/cell-device-status/cell-device-status.component';
import { CellMalwareProtectionStatusComponent } from './components/core/cell-types/cell-malware-protection-status/cell-malware-protection-status.component';
import { SharedModule } from '@razberi-ui/shared';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DashboardCarouselCardComponent } from './components/dashboard/dashboard-carousel-card/dashboard-carousel-card.component';
import { DashboardCarouselComponent } from './components/dashboard/dashboard-carousel/dashboard-carousel.component';
import { DashboardHalfCircleGraphComponent } from './components/dashboard/dashboard-half-circle-graph/dashboard-half-circle-graph.component';
import { DashboardLabelValueComponent } from './components/dashboard/dashboard-label-value/dashboard-label-value.component';
import { DashboardMicroBarGraphComponent } from './components/dashboard/dashboard-micro-bar-graph/dashboard-micro-bar-graph.component';
import { DataTableCellTypesComponent } from './components/core/data-table-cell-types/data-table-cell-types.component';

@NgModule({
	imports: [SharedModule, SlickCarouselModule],
	declarations: [
		CellCameraDefenseStatusComponent,
		CellDeviceStatusComponent,
		CellMalwareProtectionStatusComponent,
		DashboardCarouselComponent,
		DashboardCarouselCardComponent,
		DashboardLabelValueComponent,
		DashboardMicroBarGraphComponent,
		DashboardHalfCircleGraphComponent,
		DataTableCellTypesComponent,
	],
	exports: [
		SlickCarouselModule, // nmeeded ???
		CellCameraDefenseStatusComponent,
		CellDeviceStatusComponent,
		CellMalwareProtectionStatusComponent,
		DashboardCarouselComponent,
		DashboardCarouselCardComponent,
		DashboardLabelValueComponent,
		DashboardMicroBarGraphComponent,
		DashboardHalfCircleGraphComponent,
		DataTableCellTypesComponent,
	],
})
export class AppSharedMonitorCommonModule {
	static forRoot(): ModuleWithProviders<AppSharedMonitorCommonModule> {
		return {
			ngModule: AppSharedMonitorCommonModule,
			providers: [],
		};
	}
}
