<ng-container *ngIf="disabled !== true">
	<button
		type="button"
		class="btn btn-link p-0 text-start"
		style="font-weight: 500"
		[ngbPopover]="tipContent"
		popOverTitle="Agent Name"
		[disablePopover]="true"
		triggers="mouseenter:mouseleave"
		container="body"
		(click)="onClick($event)">
		<div>{{ title }}</div>
	</button>
	<div *ngIf="subTitle?.length > 0" class="text-sm">{{ subTitle }}</div>
</ng-container>
<ng-container *ngIf="disabled === true">
	<div [ngbPopover]="tipContent" popOverTitle="Agent Name" [disablePopover]="true" triggers="mouseenter:mouseleave" container="body">
		<div style="font-weight: 500">{{ title }}</div>
		<div *ngIf="subTitle?.length > 0" class="text-sm">{{ subTitle }}</div>
	</div>
</ng-container>
<ng-template #tipContent>
	<div class="p-2">
		<table>
			<tr>
				<td>
					<span class="me-3">Name</span>
				</td>
				<td class="text-muted">
					{{ unit?.name || '--' }}
				</td>
			</tr>
			<tr>
				<td class="me-3">
					<span class="me-3">Serial</span>
				</td>
				<td class="text-muted">
					{{ unit?.serialNumber || '--' }}
				</td>
			</tr>
			<tr>
				<td class="me-3">
					<span class="me-3">Type</span>
				</td>
				<td class="text-muted">{{ unit?.manufacturer || '--' }} / {{ unit?.modelName || '--' }}</td>
			</tr>
		</table>
	</div>
</ng-template>
