<app-root-monitor-cloud-account-accordian
	*ngIf="isInitialized === true && groupByAccount === true"
	[panelTemplate]="accountAuditLogs"
	[requireSubscription]="true"
	[refresh$]="refresh$"
	(update)="onUpdate()"
	dataSource="audit-logs">
	<ng-template #accountAuditLogs let-account let-hasSubscription="hasSubscription">
		<app-root-monitor-cloud-audit-log-table
			*ngIf="account != null"
			[accountIds]="[account.accountId]"
			[showLocation]="true"
			[showDownloadCsv]="hasSubscription"></app-root-monitor-cloud-audit-log-table>
	</ng-template>
</app-root-monitor-cloud-account-accordian>
<app-root-monitor-cloud-audit-log-table
	*ngIf="isInitialized === true && groupByAccount !== true"
	[showAccount]="true"
	[showLocation]="true"
	[refresh$]="refresh$"
	(update)="onUpdate()"></app-root-monitor-cloud-audit-log-table>
