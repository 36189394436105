import { UserComplianceRegion } from '@razberi-ui/core/data-types';
import { UserMultiAuthTokenType } from './data-types/user-multi-auth-token-type.model';

export const CloudApiConstants = {
	apiPath: {
		api: {
			getVersion: `/api/version`,
		},
		account: {
			getAccountsFilter: `/api/accounts`,
			getAccounts: `/api/accounts`,
			getProviderAccounts: `/api/accounts/providers`,
			getAccount: (accountId: number) => `/api/accounts/${accountId}`,
			getCurrentAccount: `/api/accounts/current`,
			createAccount: `/api/accounts`,
			createAccountPublic: `/api/accounts/public`,
			updateAccount: (accountId: number) => `/api/accounts/${accountId}`,
			updateCurrentAccount: `/api/accounts/current`,
			deleteAccount: (accountId: number) => `/api/accounts/${accountId}`,
			getAccountLogo: (accountId: number) => `/api/accounts/${accountId}/logo`,
			getAccountFavicon: (accountId: number) => `/api/accounts/${accountId}/favicon`,
			getAccountCustomEmailFooter: (accountId: number) => `/api/accounts/${accountId}/customEmailFooter`,
			setAccountLogo: (accountId: number) => `/api/accounts/${accountId}/logo`,
			setAccountFavicon: (accountId: number) => `/api/accounts/${accountId}/favicon`,
			setAccountCustomEmailFooter: (accountId: number) => `/api/accounts/${accountId}/customEmailFooter`,
			removeAccountLogo: (accountId: number) => `/api/accounts/${accountId}/logo`,
			removeAccountFavicon: (accountId: number) => `/api/accounts/${accountId}/favicon`,
			removeAccountCustomEmailFooter: (accountId: number) => `/api/accounts/${accountId}/customEmailFooter`,
			getCurrentAccountLogo: `/api/accounts/current/logo`,
			getCurrentAccountFavicon: `/api/accounts/current/favicon`,
			getCurrentAccountCustomEmailFooter: `/api/accounts/current/customEmailFooter`,
			setCurrentAccountLogo: `/api/accounts/current/logo`,
			setCurrentAccountFavicon: `/api/accounts/current/favicon`,
			setCurrentAccountCustomEmailFooter: `/api/accounts/current/customEmailFooter`,
			removeCurrentAccountLogo: `/api/accounts/current/logo`,
			removeCurrentAccountFavicon: `/api/accounts/current/favicon`,
			removeCurrentAccountCustomEmailFooter: `/api/accounts/current/customEmailFooter`,
			getAccountNote: (accountId: number) => `/api/accounts/${accountId}/note`,
			setAccountNote: (accountId: number) => `/api/accounts/${accountId}/note`,
			getAccountData: (accountId: number) => `/api/accounts/${accountId}/data`,
			getCurrentAccountData: `/api/accounts/current/data`,
			getAccountsData: `/api/accounts/data`,
		},
		accountSubscription: {
			getAccountSubscription: (accountId: number) => `/api/account-subscriptions/${accountId}`,
			updateAccountSubscription: (accountId: number) => `/api/account-subscriptions/${accountId}`,
		},
		alert: {
			getAlerts: `/api/alerts`,
			getAlertTotals: (accountId: number) => `/api/alerts/totals/account/${accountId}`,
			getDeviceAlertTotals: (accountId: number) => `/api/alerts/totals/units/account/${accountId}`,
			acknowledgeAlerts: (accountId: number) => `/api/alerts/acknowledge/account/${accountId}`,
			acknowledgeAlertsFilter: `/api/alerts/acknowledge/filter`,
			closeAlerts: (accountId: number) => `/api/alerts/close/account/${accountId}`,
			closeAlertsFilter: `/api/alerts/close/filter`,
			getAlertTypes: `/api/alerts/types`,
			getAllAlertTypes: `/api/alerts/types/all`,
			getNetworkAlerts: (accountId: number, networkId: number) => `/api/alerts/network/${accountId}/${networkId}`,
		},
		alertProfiles: {
			getAlertProfiles: (accountId: number) => `/api/alert-profiles/account/${accountId}`,
			getAlertProfile: (accountId: number, alertProfileId: number) => {
				return `/api/alert-profiles/${alertProfileId}/account/${accountId}`;
			},
			createAlertProfile: (accountId: number) => `/api/alert-profiles/account/${accountId}`,
			updateAlertProfile: (accountId: number, alertProfileId: number) => {
				return `/api/alert-profiles/${alertProfileId}/account/${accountId}`;
			},
			deleteAlertProfile: (accountId: number, alertProfileId: number) => {
				return `/api/alert-profiles/${alertProfileId}/account/${accountId}`;
			},
			deleteAlertProfiles: (accountId: number) => `/api/alert-profiles/account/${accountId}`,
			sendTestById: (accountId: number, alertProfileId: number) => {
				return `/api/alert-profiles/${alertProfileId}/account/${accountId}/test`;
			},
			sendTest: `/api/alert-profiles/test`,
		},
		auditLog: {
			getAuditLogs: `/api/audit-logs`,
		},
		auth: {
			token: '/api/auth/token',
			logout: '/api/users/logout',
			samlSignIn: `/api/auth/saml2/SignIn`,
		},
		dashboard: {
			getDashboard: `/api/dashboard`,
			getCybersecurityDevices: `/api/dashboard/cybersecurity-devices`,
			getMalwareProtectionDevices: `/api/dashboard/malware-protection-devices`,
			getCameraDefenseDevices: `/api/dashboard/camera-defense-devices`,
			getOfflineDevices: `/api/dashboard/offline-devices`,
			getCameraStreams: `/api/dashboard/camera-streams`,
			getRecordingServers: `/api/dashboard/recording-servers`,
		},
		images: {
			getImage: (token: string) => `/api/images/${token}`,
		},
		location: {
			getLocations: (accountId: number) => `/api/locations/account/${accountId}`,
			getLocation: (accountId: number, locationId: number) => `/api/locations/${locationId}/account/${accountId}`,
			createLocation: (accountId: number) => `/api/locations/account/${accountId}`,
			updateLocation: (accountId: number, locationId: number) => `/api/locations/${locationId}/account/${accountId}`,
			deleteLocation: (accountId: number, locationId: number) => `/api/locations/${locationId}/account/${accountId}`,
			deleteLocations: (accountId: number) => `/api/locations/account/${accountId}`,
		},
		manage: {
			getTasks: `/api/manage`,
			getTask: (accountId: number, manageTaskId: number) => `/api/manage/${accountId}/${manageTaskId}`,
			createTask: (accountId: number) => `/api/manage/${accountId}/multipart`,
			createTaskFile: (accountId: number, manageTaskId: number) => `/api/manage/files/${accountId}/${manageTaskId}`,
			getTaskFile: (accountId: number, manageTaskFileId: number) => `/api/manage/file/${accountId}/${manageTaskFileId}`,
			cancelTask: (accountId: number, manageTaskId: number) => `/api/manage/${accountId}/${manageTaskId}`,
			getTaskTypes: `/api/manage/types`,
			getAllTaskTypes: `/api/manage/types/all`,
			getTaskStats: `/api/manage/stats`,
			manageTasksCsv: `/api/manage/report/manage-tasks`,
			manageTaskInstancesCsv: (accountId: number, manageTaskId: number) => `/api/manage/report/manage-task-instances/${accountId}/${manageTaskId}`,
			manageTasksExport: `/api/manage/export/manage-tasks`,
		},
		managedDevice: {
			getManagedDevices: `/api/managed-devices`,
			getManagedDevice: (accountId: number, managedDeviceId: number) => `/api/managed-devices/${accountId}/${managedDeviceId}`,
			getManagedDevicesForUnits: `/api/managed-devices/list`,
			updateManagedDevice: (accountId: number, managedDeviceId: number) => `/api/managed-devices/${accountId}/${managedDeviceId}`,
			managedDevicesCsv: `/api/managed-devices/report`,
		},
		multiAuth: {
			checkMultiAuth: `/api/multi-auth/check`,
			multiAuthExists: `/api/multi-auth/exists`,
			sendMultiAuth: (type: UserMultiAuthTokenType) => `/api/multi-auth/send/${type}`,
			verifyMultiAuth: `/api/multi-auth/verify`,
			configureSmsMultiAuth: `/api/multi-auth/configure/sms`,
			configureSmsMultiAuthVerify: `/api/multi-auth/configure/sms/verify`,
		},
		networks: {
			getNetworks: `/api/networks`,
			getNetwork: (accountId: number, networkId: number) => `/api/networks/${accountId}/${networkId}`,
			createNetwork: (accountId: number) => `/api/networks/${accountId}`,
			updateNetwork: (accountId: number) => `/api/networks/${accountId}`,
			deleteNetwork: (accountId: number, networkId: number) => `/api/networks/${accountId}/${networkId}`,
			getNetworkAvailableDevices: `/api/networks/available-devices`,
		},
		reports: {
			accountsCsv: `/api/reports/accounts`,
			devicesCsv: `/api/reports/devices`,
			cybersecurityDevicesCsv: `/api/reports/devices/cybersecurity`,
			malwareProtectionDevicesCsv: `/api/reports/devices/malware-protection`,
			cameraDefenseDevicesCsv: `/api/reports/devices/camera-defense`,
			offlineDevicesCsv: `/api/reports/devices/offline`,
			cameraStreamsCsv: `/api/reports/devices/camera-streams`,
			recordingServersCsv: `/api/reports/devices/recording-servers`,
			lookupDevicesCsv: (lookup: string) => `/api/reports/devices/lookup/${lookup}`,
			alertsCsv: `/api/reports/alerts`,
			networkAlertsCsv: (accountId: number, networkId: number) => `/api/reports/alerts/network/${accountId}/${networkId}`,
			auditLogsCsv: `/api/reports/audit-logs`,
			accountStatusPdf: `/api/reports/account-status`,
			accountStatusSinglePdf: (accountId: number) => `/api/reports/account-status/accounts/${accountId}`,
			accountStatusMultiPdf: `/api/reports/account-status/accounts`,
			userComplianceCsv: `/api/reports/user-compliance/accounts`,
			userComplianceCsvFilter: `/api/reports/user-compliance/accounts/filter`,
		},
		settings: {
			getSettings: `/api/settings`,
			getCoreSettings: `/api/settings/core`,
			getFullSettings: `/api/settings/full`,
			getLoginSettings: `/api/settings/login`,
			exportSettings: `/api/settings/export`,
			setGlobal: `/api/settings/global`,
			setSmtp: `/api/settings/smtp`,
			setSso: `/api/settings/sso`,
			setCloudApi: `/api/settings/cloudapi`,
			setSinkApi: `/api/settings/sinkapi`,
			setCloudAgent: `/api/settings/cloudagent`,
			setMonitorAgent: `/api/settings/monitoragent`,
			setManage: `/api/settings/manage`,
			setCompliance: `/api/settings/compliance`,
			setAws: '/api/settings/aws',
			setFileStore: '/api/settings/filestore',
			setSms: '/api/settings/sms',
			getSettingsDashboard: '/api/settings/dashboard',
			testSmtp: '/api/settings/smtp/test',
		},
        setup: {
			tokenRequiresCredentials: `/api/setup/register/token/requires-credentials`,
			registerToken: `/api/setup/register/token`,
			getBenefitsProvider: `/api/setup/benefits/provider`,
			getBenefitsCustomer: `/api/setup/benefits/customer`,
		},
		tag: {
			getTags: (accountId: number) => `/api/tags/account/${accountId}`,
			getTag: (accountId: number, tagId: number) => `/api/tags/${tagId}/account/${accountId}`,
			createTag: (accountId: number) => `/api/tags/account/${accountId}`,
			updateTag: (accountId: number, tagId: number) => `/api/tags/${tagId}/account/${accountId}`,
			deleteTag: (accountId: number, tagId: number) => `/api/tags/${tagId}/account/${accountId}`,
			deleteTags: (accountId: number) => `/api/tags/account/${accountId}`,
		},
		unit: {
			registerUnit: `/api/units/register`,
			getUnits: `/api/units`,
			getUnitsByAccountId: (accountId: number) => `/api/units/account/${accountId}`,
			getUnitsByLookup: (lookup: string) => `/api/units/lookup/${lookup}`,
			getAccountsUnits: `/api/units/accounts`,
			getUnit: (accountId: number, unitIdOrKey: string) => `/api/units/${unitIdOrKey}/account/${accountId}`,
			getUpdatesForUnit: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}/updates`,
			updateUnit: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}`,
			deleteUnit: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}`,
			deleteUnitPermanently: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}/permanent`,
			getAccountsVmsServices: `/api/units/vms-services/accounts`,
			getActivationCode: (accountId: number, unitIdOrKey: string) => `/api/units/${unitIdOrKey}/account/${accountId}/activation-code`,
			getUnitFields: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}/fields`,
			setUnitWarranty: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}/warranty`,
			deleteUnitFields: (accountId: number, unitId: number) => `/api/units/${unitId}/account/${accountId}/fields`,
			getUnitFieldsByUnitKey: (unitKey: string) => `/api/units/${unitKey}/fields`,
			setUnitWarrantyByUnitKey: (unitKey: string) => `/api/units/${unitKey}/warranty`,
			getUpdateTypes: `/api/units/updates/types`,
			getUnitAndDeviceTotals: (accountId: number) => `/api/units/${accountId}/totals`,
		},
		user: {
			getUsers: `/api/users`,
			getUsersByAccountId: (accountId: number) => `/api/users/account/${accountId}`,
			getAccountsUsers: `/api/users/accounts`,
			getUser: (userId: number) => `/api/users/${userId}`,
			getCurrentUser: `/api/users/current`,
			createUser: `/api/users`,
			updateUser: (userId: number) => `/api/users/${userId}`,
			updateCurrentUser: `/api/users/current`,
			deleteUser: (userId: number) => `/api/users/${userId}`,
			setUserPassword: (userId: number) => `/api/users/${userId}/password`,
			setCurrentUserPassword: `/api/users/current/password`,
			getUserAvatar: (userId: number) => `/api/users/${userId}/avatar`,
			getCurrentUserAvatar: `/api/users/current/avatar`,
			setUserAvatar: (userId: number) => `/api/users/${userId}/avatar`,
			setCurrentUserAvatar: `/api/users/current/avatar`,
			removeUserAvatar: (userId: number) => `/api/users/${userId}/avatar`,
			removeCurrentUserAvatar: `/api/users/current/avatar`,
			getUserSessions: (userId: number) => `/api/users/${userId}/sessions`,
			getCurrentUserSessions: `/api/users/current/sessions`,
			deleteUserSession: (userId: number, sessionId: string) => `/api/users/${userId}/sessions/${sessionId}`,
			deleteCurrentUserSession: (sessionId: string) => `/api/users/current/sessions/${sessionId}`,
			checkMultiAuth: `/api/users/multi-auth/check`,
			multiAuthExists: `/api/users/multi-auth/exists`,
			sendMultiAuth: (type: UserMultiAuthTokenType) => `/api/users/multi-auth/send/${type}`,
			verifyMultiAuth: `/api/users/multi-auth/verify`,
			configureSmsMultiAuth: `/api/users/multi-auth/configure/sms`,
			configureSmsMultiAuthVerify: `/api/users/multi-auth/configure/sms/verify`,
		},
		userCompliance: {
			getUserCompliances: `/api/user-compliances`,
			getGdprAgreement: '/api/user-compliances/gdpr/agreement',
			getGdprPdf: '/api/user-compliances/gdpr/pdf',
			signGdpr: '/api/user-compliances/gdpr/sign',
			getResellerAgreement: (region: UserComplianceRegion) => `/api/user-compliances/reseller/agreement/${region}`,
			getResellerPdf: `/api/user-compliances/reseller/pdf`,
			signReseller: (region: UserComplianceRegion, consented: boolean) => `/api/user-compliances/reseller/sign/${region}/${consented}`,
		},
		userEmailTokens: {
			requestResetPasswordToken: `/api/user-email-tokens/reset-password`,
			verifyNewUserToken: (token: string) => `/api/user-email-tokens/new-user/${token}`,
			verifyResetPasswordToken: (token: string) => `/api/user-email-tokens/reset-password/${token}`,
			finalizeNewUserToken: (token: string) => `/api/user-email-tokens/new-user/${token}`,
			finalizeResetPasswordToken: (token: string) => `/api/user-email-tokens/reset-password/${token}`,
		},
	},
	storageKeys: {
		authToken: 'authToken',
		email: 'email',
	},
};
