import { Component, EventEmitter, Output } from '@angular/core';
import { PageSidePanelService } from '../../services/page-side-panel.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../services/nav.service';
import { PageSidePanelConfig } from '../../data-types/page-side-panel-config.model';

@Component({
	selector: 'shared-page-side-panel-outlet',
	templateUrl: './page-side-panel-outlet.component.html',
	styleUrls: ['./page-side-panel-outlet.component.scss'],
})
export class PageSidePanelOutletComponent {
	@Output() onEdit: EventEmitter<void> = new EventEmitter();

	config: PageSidePanelConfig;
	isVisible: boolean = false;
	subscriptions: Subscription = new Subscription();

	constructor(readonly sidePanelService: PageSidePanelService, private readonly navigationService: NavService) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.navigationService.streams.navStartEvents$.subscribe((_) => {
				this.config = null;
				this.isVisible = false;
				this.sidePanelService.helpers.closeSidePanel();
			})
		);

		this.subscriptions.add(
			this.sidePanelService.streams.sidePanelConfig$.subscribe((config) => {
				this.config = config;
			})
		);

		this.subscriptions.add(
			this.sidePanelService.streams.isOpen$.subscribe((isOpen) => {
				this.isVisible = isOpen;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	closePanel() {
		this.sidePanelService.helpers.closeSidePanel();
	}

	editClick() {
		this.onEdit.emit();
		// this.sidePanelService.helpers.closeSidePanel();
	}
}
