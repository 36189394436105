import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'enumToOptions',
})
export class EnumToOptionsPipe implements PipeTransform {
	transform(value: Object, ...args: unknown[]): Object {
		if (value == null) return null;
		return Object.keys(value)
			.filter((e) => !isNaN(+e))
			.map((o) => {
				return { value: +o, label: value[o] };
			});
	}
}
