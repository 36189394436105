<div class="side-panel-row">
	<div class="side-panel-label">Type</div>
	<div class="side-panel-value">{{ getDeviceType() }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Status</div>
	<div class="side-panel-value">{{ getDeviceStatus() }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Name</div>
	<div class="side-panel-value">{{ managedDevice?.name || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Manufacturer</div>
	<div class="side-panel-value">{{ managedDevice?.manufacturer || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Model</div>
	<div class="side-panel-value">{{ managedDevice?.modelName || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Location</div>
	<div class="side-panel-value">{{ managedDevice?.processedLocationName || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Serial</div>
	<div class="side-panel-value">{{ managedDevice?.serialNumber || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Mac Address</div>
	<div class="side-panel-value">{{ formatMacAddress(managedDevice?.macAddress) || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">IP Address</div>
	<div class="side-panel-value">{{ managedDevice?.ipAddress || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Gateway</div>
	<div class="side-panel-value">{{ managedDevice?.gateway || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Firmware</div>
	<div class="side-panel-value">{{ managedDevice?.firmware || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Ports</div>
	<div class="side-panel-value">{{ managedDevice?.ports || '--' }}</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Registered</div>
	<div class="side-panel-value">
		{{ managedDevice?.registerTimestamp != null ? (managedDevice?.registerTimestamp | date: 'MM/dd/yy @ HH:mm') : '--' }}
	</div>
</div>
<div class="side-panel-row">
	<div class="side-panel-label">Updated</div>
	<div class="side-panel-value">
		{{ managedDevice?.updateTimestamp != null ? (managedDevice?.updateTimestamp | date: 'MM/dd/yy @ HH:mm') : '--' }}
	</div>
</div>
<div class="device-name my-3">Extended Properties</div>
<div class="mb-3">
	<ng-container *ngIf="managedDevice?.customData?.length > 0">
		<ng-container
			*ngIf="
				managedDevice?.type === managedDeviceTypes.InternalSwitch ||
				managedDevice?.type === managedDeviceTypes.EndpointDefender ||
				managedDevice?.type === managedDeviceTypes.CngeSwitch
			">
			<div class="side-panel-row">
				<div class="side-panel-label">Max PoE Power</div>
				<div class="side-panel-value">{{ managedDevice?.maximumPoEPower || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">PoE Consumption Power</div>
				<div class="side-panel-value">{{ managedDevice?.poeConsumptionPower || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">Firmware License Name</div>
				<div class="side-panel-value">{{ managedDevice?.firmwareLicenseName || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">Firmware License Features</div>
				<div class="side-panel-value">{{ managedDevice?.firmwareLicenseFeatures || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">Firmware License Date</div>
				<div class="side-panel-value">
					{{ managedDevice?.firmwareLicenseDate != null ? (managedDevice?.firmwareLicenseDate | date: 'MM/dd/yy @ HH:mm') : '--' }}
				</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">Firmware License Expiration</div>
				<div class="side-panel-value">
					{{ managedDevice?.firmwareLicenseExpiration != null ? (managedDevice?.firmwareLicenseExpiration | date: 'MM/dd/yy @ HH:mm') : '--' }}
				</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">CameraDefense Enabled</div>
				<div class="side-panel-value">{{ managedDevice?.cameraDefenseEnabled === true ? 'True' : 'False' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">CameraHealth Enabled</div>
				<div class="side-panel-value">{{ managedDevice?.cameraHealthEnabled === true ? 'True' : 'False' }}</div>
			</div>
		</ng-container>
		<ng-container *ngIf="managedDevice?.type === managedDeviceTypes.SnmpDevice">
			<div class="side-panel-row">
				<div class="side-panel-label">Max PoE Power</div>
				<div class="side-panel-value">{{ managedDevice?.maximumPoEPower || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">PoE Consumption Power</div>
				<div class="side-panel-value">{{ managedDevice?.poeConsumptionPower || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">SNMP Services</div>
				<div class="side-panel-value">{{ managedDevice?.snmpServices || '--' }}</div>
			</div>
		</ng-container>
		<ng-container *ngIf="managedDevice?.type === managedDeviceTypes.PingDevice">
			<div class="side-panel-row">
				<div class="side-panel-label">Host</div>
				<div class="side-panel-value">{{ managedDevice?.host || '--' }}</div>
			</div>
		</ng-container>
		<ng-container *ngIf="managedDevice?.type === managedDeviceTypes.HttpDevice">
			<div class="side-panel-row">
				<div class="side-panel-label">Target Url</div>
				<div class="side-panel-value">{{ managedDevice?.targetUrl || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">Http Status Code</div>
				<div class="side-panel-value">{{ managedDevice?.httpResponseStatusCode || '--' }}</div>
			</div>
			<div class="side-panel-row">
				<div class="side-panel-label">Http Status Message</div>
				<div class="side-panel-value">{{ managedDevice?.httpResponseStatusLabel || '--' }}</div>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="(managedDevice?.type || 0) == 0 || (managedDevice?.customData?.length ?? 0) == 0">
		<div class="font-italic">No data available.</div>
	</ng-container>
</div>
