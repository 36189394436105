import { Component, Input } from '@angular/core';
import { TableColumnSetting } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';

@Component({
	selector: 'app-root-monitor-cloud-data-table-cell-types',
	templateUrl: './data-table-cell-types.component.html',
	styleUrls: ['./data-table-cell-types.component.scss'],
})
export class DataTableCellTypesComponent {
	@Input() value: any;
	@Input() tableItem: any;
	@Input() column: TableColumnSetting;

	constructor(private readonly logger: NGXLogger) {}

	transformValue(value, column, tableItem) {
		let transformedValue = column.transform ? column.transform(value, tableItem) : value;
		if (transformedValue === 0) transformedValue = '0';
		this.logger.trace('transformValue', value, column, tableItem, transformedValue);
		if (transformedValue) return transformedValue;
		return '--';
	}

	handleCellClick(header, data, value?) {
		if (header.clickHandlerFunction != null) header.clickHandlerFunction(data, value);
	}
}
