<form [formGroup]="parametersForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="action" class="form-label">Action <span class="text-primary">*</span></label>
		<div ngbTooltip="Specify the MSI action to perform." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<select class="form-select" id="action" name="action" formControlName="action" required>
			<option *ngFor="let action of actions" [ngValue]="action.value">{{ action.name }}</option>
		</select>
		<shared-input-validation-messages [control]="parametersForm.controls.action"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="url" class="form-label">MSI Download URL</label>
		<div ngbTooltip="Specify a URL from which to download an MSI file." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<input type="text" class="form-control" id="url" name="url" formControlName="url" />
		<shared-input-validation-messages [control]="parametersForm.controls.url"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="file" class="form-label">MSI File</label>
		<div
			ngbTooltip="Upload an MSI file to be installed. Max file upload size: 2 GB."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<div *ngIf="readonly !== true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div class="pb-2">
					<div class="form-check">
						<input
							type="radio"
							id="fileExisting"
							name="fileExisting"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.Existing"
							(change)="resetFileEvent()" />
						<label for="fileExisting" class="form-check-label">Use Previous MSI File</label>
					</div>
					<div class="form-check ms-3">
						<input
							type="radio"
							id="fileNew"
							name="fileNew"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.New"
							(change)="resetFileEvent()" />
						<label for="fileNew" class="form-check-label">Upload New MSI File</label>
					</div>
				</div>
				<div class="p-3 box-shadow">
					<ng-contaier *ngIf="fileMode === fileModeOptions.Existing">
						<div class="mb-3 font-italic">Use the existing MSI file from the copied task:</div>
						<div *ngFor="let taskFile of taskFiles">
							<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">
								<input class="me-3" type="checkbox" readonly="readonly" [checked]="true" />
								{{ taskFile.fileName }}
							</button>
						</div>
					</ng-contaier>
					<ng-contaier *ngIf="fileMode === fileModeOptions.New">
						<div class="mb-3 font-italic">Upload a new script:</div>
						<shared-file-upload [reset$]="reset$" name="file" (fileSelected)="fileSelected($event)"></shared-file-upload>
					</ng-contaier>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) === 0">
				<shared-file-upload [reset$]="reset$" name="file" (fileSelected)="fileSelected($event)"></shared-file-upload>
			</ng-container>
		</div>
		<div *ngIf="readonly === true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div *ngFor="let taskFile of taskFiles">
					<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">{{ taskFile.fileName }}</button>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) == 0">
				<i>No files available</i>
			</ng-container>
		</div>
	</div>
	<div class="mb-3 col-12">
		<label for="args" class="form-label">Arguments</label>
		<div ngbTooltip="(Optional) Specify any additional msiexec.exe arguments." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<input type="text" class="form-control" id="args" name="args" formControlName="args" />
		<shared-input-validation-messages [control]="parametersForm.controls.args"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input type="checkbox" class="form-check-input" id="allowReboot" name="allowReboot" formControlName="allowReboot" />
			<label for="allowReboot" class="form-check-label">Allow Reboot</label>
			<div
				ngbTooltip="(Optional) Specify whether to allow the device to reboot after installing the MSI file."
				placement="right"
				container="body"
				class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
			</div>
		</div>
	</div>
</form>
