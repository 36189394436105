import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'shared-page-toolbar-outlet',
	templateUrl: './page-toolbar-outlet.component.html',
	styleUrls: ['./page-toolbar-outlet.component.scss'],
})
export class PageToolbarOutletComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
