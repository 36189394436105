import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { Account } from '@razberi-ui/core/data-types';
import { NavItem } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubscriptionService } from '../../../services/subscription.service';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-page-tabs',
	templateUrl: './page-tabs.component.html',
	styleUrls: ['./page-tabs.component.scss'],
})
export class PageTabsComponent {
	@Input() account?: Account;

	tabs: NavItem[] = [];
	tabsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>(this.tabs);
	tabs$: Observable<NavItem[]> = this.tabsSubject.asObservable();
	route$: Observable<string>;

	constructor(
		private readonly logger: NGXLogger,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		let account: Account = changes.account?.currentValue ?? this.account;
		let r: ActivatedRoute = this.activatedRoute.root.firstChild;
		while (true) {
			if (!r.firstChild) {
				if (r.parent.routeConfig.children) {
					this.tabs = r.parent.routeConfig.children
						.filter((r) => r.path !== '')
						.filter((r) => {
							if (r.data.roles && r.data.roles.some((r) => this.authService.helpers.userHasRole(r) == true) != true) return false;
							if (r.data.accounts && r.data.accounts.indexOf(this.authService.data.account.type) === -1) return false;
							if (account && r.data.forAccounts && r.data.forAccounts.indexOf(account.type) === -1) return false;
							if (
								account &&
								r.data.allowedSubscriptionTypes != null &&
								this.subscriptionService.helpers.hasAllowedSubscriptionTypes(this.authService.data.account, r.data.allowedSubscriptionTypes) !==
									true
							)
								return false;
							if (
								account &&
								r.data.forAllowedSubscriptionTypes != null &&
								this.subscriptionService.helpers.hasAllowedSubscriptionTypes(account, r.data.forAllowedSubscriptionTypes) !== true
							)
								return false;
							return true;
						})
						.map((rc) => {
							return {
								id: rc.path,
								routerLink: rc.path,
								icon: rc.data.icon,
								title: rc.data.title,
								active: false,
								show: true,
								allowedSubscriptionTypes: null,
							};
						});
					this.route$ = this.router.events.pipe(
						filter((event: Event) => event instanceof NavigationEnd),
						map((event: NavigationEnd) => {
							let ra: ActivatedRoute = this.activatedRoute.root.firstChild;
							while (ra.firstChild) ra = ra.firstChild;
							return ra.routeConfig.path;
						}),
						startWith(r.routeConfig.path)
					);
					this.tabsSubject.next(this.tabs);
				}
				break;
			}
			r = r.firstChild;
		}
	}

	ngOnDestroy() {
		this.tabsSubject.complete();
	}
}
