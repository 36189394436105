import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Account, AccountSubscription, AccountSubscriptionStatus, AccountSubscriptionType, AccountType, User, UserRole } from '@razberi-ui/core/data-types';
import { InvalidSubscriptionModalComponent } from '../components/subscriptions/invalid-subscription-modal/invalid-subscription-modal.component';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {
	constructor(private readonly modalService: NgbModal, private readonly authService: AuthService) {}

	get helpers() {
		return {
			isValid: (accountSubscription: AccountSubscription = null): boolean => {
				const sub: AccountSubscription = accountSubscription ?? this.authService.data.account.subscription;
				return sub.isValid && this.helpers.isTypeValid(sub.type) && this.helpers.isStatusValid(sub.status);
			},
			isTypeValid: (accountSubscriptionType: AccountSubscriptionType): boolean => {
				return accountSubscriptionType > AccountSubscriptionType.None;
			},
			isStatusValid: (accountSubscriptionStatus: AccountSubscriptionStatus): boolean => {
				return accountSubscriptionStatus === AccountSubscriptionStatus.Active || accountSubscriptionStatus === AccountSubscriptionStatus.Expiring;
			},
			isTypeAndStatusValid: (accountSubscriptionType: AccountSubscriptionType, accountSubscriptionStatus: AccountSubscriptionStatus): boolean => {
				return (
					accountSubscriptionType > AccountSubscriptionType.None &&
					(accountSubscriptionStatus === AccountSubscriptionStatus.Active || accountSubscriptionStatus === AccountSubscriptionStatus.Expiring)
				);
			},
			isTypeAllowedAndStatusValid: (
				accountSubscriptionType: AccountSubscriptionType,
				accountSubscriptionStatus: AccountSubscriptionStatus,
				allowedTypes: AccountSubscriptionType[]
			): boolean => {
				return (
					(allowedTypes == null || allowedTypes.some((at) => at === accountSubscriptionType) === true) &&
					(accountSubscriptionStatus === AccountSubscriptionStatus.Active || accountSubscriptionStatus === AccountSubscriptionStatus.Expiring)
				);
			},
			isGlobalOrIsValid: (accountSubscription: AccountSubscription = null): boolean => {
				return this.authService.data.account.type === AccountType.Global || this.helpers.isValid(accountSubscription);
			},
			isGlobalOrIsValidMinLevel: (accountSubscription: AccountSubscription = null): boolean => {
				return this.authService.data.account.type === AccountType.Global || this.helpers.isValid(accountSubscription);
			},
			isGlobalOrIsTypeValid: (accountSubscriptionType: AccountSubscriptionType): boolean => {
				return this.authService.data.account.type === AccountType.Global || accountSubscriptionType > AccountSubscriptionType.None;
			},
			isGlobalOrIsStatusValid: (accountSubscriptionStatus: AccountSubscriptionStatus): boolean => {
				return (
					this.authService.data.account.type === AccountType.Global ||
					accountSubscriptionStatus === AccountSubscriptionStatus.Active ||
					accountSubscriptionStatus === AccountSubscriptionStatus.Expiring
				);
			},
			isGlobalOrIsTypeAndStatusValid: (
				accountSubscriptionType: AccountSubscriptionType,
				accountSubscriptionStatus: AccountSubscriptionStatus
			): boolean => {
				return (
					this.authService.data.account.type === AccountType.Global ||
					(accountSubscriptionType > AccountSubscriptionType.None &&
						(accountSubscriptionStatus === AccountSubscriptionStatus.Active || accountSubscriptionStatus === AccountSubscriptionStatus.Expiring))
				);
			},
			showInvalidSubscriptionModal: (): void => {
				this.modalService.open(InvalidSubscriptionModalComponent, { backdrop: 'static', centered: true });
			},
			hasAllowedSubscriptionTypes: (account: Account, allowedSubscriptionTypes: AccountSubscriptionType[]): boolean => {
				return (
					account.type === AccountType.Global ||
					(allowedSubscriptionTypes?.length ?? 0) === 0 ||
					account.type === AccountType.Provider ||
					this.helpers.isTypeAllowedAndStatusValid(account.subscription.type, account.subscription.status, [
						...allowedSubscriptionTypes,
						AccountSubscriptionType.Trial,
					])
				);
			},
			hasManageWriteAccess: (account: Account, user: User): boolean => {
				return (
					this.authService.helpers.userHasAnyRole([UserRole.Administrator, UserRole.ManageAdmin], user) == true &&
					(account.type === AccountType.Global ||
						account.type === AccountType.Provider ||
						this.helpers.isTypeAllowedAndStatusValid(account.subscription.type, account.subscription.status, [
							AccountSubscriptionType.Trial,
							AccountSubscriptionType.Manage,
						]))
				);
			},
			hasManageReadAccess: (account: Account, user: User): boolean => {
				return (
					account.type === AccountType.Global ||
					account.type === AccountType.Provider ||
					this.helpers.isTypeAllowedAndStatusValid(account.subscription.type, account.subscription.status, [
						AccountSubscriptionType.Trial,
						AccountSubscriptionType.Manage,
					])
				);
			},
		};
	}
}
