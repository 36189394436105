import { Component, Input } from '@angular/core';
import { ManagedDevice, ManagedDeviceStatus, ManagedDeviceType, ManagedDeviceTypeAttributes } from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';

@Component({
	selector: 'app-root-monitor-cloud-managed-device-details-side-panel',
	templateUrl: './managed-device-details-side-panel.component.html',
	styleUrls: ['./managed-device-details-side-panel.component.scss'],
})
export class ManagedDeviceDetailsSidePanelComponent {
	@Input() managedDevice: ManagedDevice;

	managedDeviceTypes: any = ManagedDeviceType;

	constructor(private readonly utils: UtilitiesService) {}

	ngOnInit(): void {}

	getDeviceType() {
		return ManagedDeviceTypeAttributes.get(this.managedDevice?.type)?.label ?? '--';
	}

	getDeviceStatus() {
		return this.managedDevice?.status != null ? ManagedDeviceStatus[this.managedDevice?.status] : '--';
	}

	formatMacAddress(macAddress: string) {
		if ((macAddress?.length || 0) == 0) return '--';
		return this.utils.formatters.asMacAddress(macAddress);
	}
}
