import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@razberi-ui/api/cloud-api';
import { UserRole } from '@razberi-ui/core/data-types';
import { PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';

@Component({
	selector: 'app-root-monitor-cloud-networks',
	templateUrl: './networks.component.html',
	styleUrls: ['./networks.component.scss'],
})
export class NetworksComponent {
	constructor() {}
	// canManageNetworks: boolean = false;
	// isInitialized: boolean = false;

	// constructor(
	//     private readonly pageConfigService: PageConfigService,
	//     private readonly authService: AuthService,
	//     private readonly router: Router
	// ) { }

	// ngOnInit(): void {
	// 	this.canManageNetworks = this.authService.helpers.userHasRole(UserRole.Administrator);

	// 	const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
	// 	if (this.canManageNetworks === true) {
	// 		c.buttons.push({
	// 			text: 'New Network',
	// 			icon: 'plus',
	// 			color: 'primary',
	// 			click: () => {
	// 				this.showNewNetwork();
	// 			},
	// 		});
	// 	}
	// 	this.pageConfigService.data.toolbarConfig = c;
	// 	this.isInitialized = true;
	// }

	// ngOnDestroy() {
	// 	this.pageConfigService.data.toolbarConfig = null;
	// }

	// showNewNetwork() {
	// 	this.router.navigate(['networks', '0']);
	// }
}
