import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertMessageService, ConfirmService, ImageUploaderConfig } from '@razberi-ui/shared';
import { AccountService } from '../../../../../services/account.service';
import { AuthService, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-branding',
	templateUrl: './account-view-tab-branding.component.html',
	styleUrls: ['./account-view-tab-branding.component.scss'],
})
export class AccountViewTabBrandingComponent {
	accountId: number;
	customEmailFooterForm: UntypedFormGroup;
	originalCustomEmailFooter: string;
	customEmailFooterMaxLength: number = 1000;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly accountService: AccountService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly confirmService: ConfirmService
	) {}

	logoConfig: ImageUploaderConfig = {
		image: null,
		placeholder: '/assets/core/images/img/avatar.jpg',
		type: 'vertical',
		label: 'Logo',
		shape: 'square',
		maxWidth: '180',
		aspectRatio: 3,
		recommendedText: '180x60 jpg/png recommended',
		loadImageAction: () => {
			this.accountService.api.getAccountLogo(this.accountId).subscribe({
				next: (imageDto) => {
					if (imageDto && imageDto.imageData) this.logoConfig.image = imageDto.imageData;
				},
				error: (_) => {},
			});
		},
		uploadImageAction: (image) => {
			this.accountService.api.setAccountLogo(this.accountId, { imageData: image }).subscribe({
				next: (_) => {
					this.logoConfig.image = image;
					this.alertMessageService.success('Logo uploaded.');
				},
				error: (error) => {
					this.alertMessageService.error('Error uploading logo.', error);
				},
			});
		},
		removeImageAction: () => {
			this.accountService.api.removeAccountLogo(this.accountId).subscribe({
				next: (_) => {
					this.logoConfig.image = null;
					this.alertMessageService.success('Logo removed.');
				},
				error: (error) => {
					this.alertMessageService.error('Error removing logo.', error);
				},
			});
		},
	};

	faviconConfig: ImageUploaderConfig = {
		image: null,
		placeholder: '/assets/core/images/img/avatar.jpg',
		type: 'vertical',
		label: 'Favicon',
		shape: 'square',
		maxWidth: '32',
		aspectRatio: 1,
		recommendedText: '32x32 png recommended',
		loadImageAction: () => {
			this.accountService.api.getAccountFavicon(this.accountId).subscribe({
				next: (imageDto) => {
					if (imageDto && imageDto.imageData) this.faviconConfig.image = imageDto.imageData;
				},
				error: (_) => {},
			});
		},
		uploadImageAction: (image) => {
			this.accountService.api.setAccountFavicon(this.accountId, { imageData: image }).subscribe({
				next: (_) => {
					this.faviconConfig.image = image;
					this.alertMessageService.success('Favicon uploaded.');
				},
				error: (error) => {
					this.alertMessageService.error('Error uploading favicon.', error);
				},
			});
		},
		removeImageAction: () => {
			this.accountService.api.removeAccountFavicon(this.accountId).subscribe({
				next: (_) => {
					this.faviconConfig.image = null;
					this.alertMessageService.success('Favicon removed.');
				},
				error: (error) => {
					this.alertMessageService.error('Error removing favicon.', error);
				},
			});
		},
	};

	ngOnInit() {
		this.customEmailFooterForm = this.formBuilder.group({
			customEmailFooter: [null, [Validators.required, Validators.maxLength(this.customEmailFooterMaxLength)]],
		});

		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;

		this.loadCustomEmailFooter();
	}

	loadCustomEmailFooter() {
		this.accountService.api.getAccountCustomEmailFooter(this.accountId).subscribe((result) => {
			this.customEmailFooterForm.controls.customEmailFooter.setValue(result);
			this.originalCustomEmailFooter = result;
		});
	}

	submitForm() {
		if (this.customEmailFooterForm.valid) {
			this.accountService.api.setAccountCustomEmailFooter(this.accountId, this.customEmailFooterForm.controls.customEmailFooter.value).subscribe((_) => {
				this.originalCustomEmailFooter = this.customEmailFooterForm.controls.customEmailFooter.value;
				this.alertMessageService.success('The account custom email footer was successfully updated.');
			});
		}
	}

	removeCustomEmailFooter() {
		this.confirmService
			.confirm({
				title: 'Remove Custom Email Footer?',
				text: `Are you sure you want to remove the custom email footer for this account?`,
				icon: 'times',
				confirmButtonText: 'Remove',
				confirmButtonColor: 'primary',
				confirmButtonIcon: 'times',
			})
			.then(
				(_) => {
					this.accountService.api.removeAccountCustomEmailFooter(this.accountId).subscribe((_) => {
						this.customEmailFooterForm.reset();
						this.customEmailFooterForm.controls.customEmailFooter.setValue(null);
						this.originalCustomEmailFooter = null;
						this.alertMessageService.success('The account custom email footer was successfully removed.');
					});
				},
				(_) => {}
			);
	}
}
