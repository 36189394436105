import { Injectable } from '@angular/core';
import { ElectronApi } from '../data-types/electron.api';
import { ElectronWindow } from '../data-types/electron.window';

declare let window: ElectronWindow;

@Injectable()
export class ElectronService {
	private _electronApi: ElectronApi;

	private get electronApi(): ElectronApi {
		if (!this._electronApi) {
			if (window && window.electronApi) {
				this._electronApi = window.electronApi;
				return this._electronApi;
			}
			return null;
		}
		return this._electronApi;
	}

	public get isElectronApp(): boolean {
		return !!window.navigator.userAgent.match(/Electron/);
	}

	public get isMacOS(): boolean {
		return this.isElectronApp && this.electronApi.platform === 'darwin';
	}

	public get isWindows(): boolean {
		return this.isElectronApp && this.electronApi.platform === 'win32';
	}

	public get isLinux(): boolean {
		return this.isElectronApp && this.electronApi.platform === 'linux';
	}

	public get isX86(): boolean {
		return this.isElectronApp && this.electronApi.arch === 'ia32';
	}

	public get isX64(): boolean {
		return this.isElectronApp && this.electronApi.arch === 'x64';
	}

	public get isArm(): boolean {
		return this.isElectronApp && this.electronApi.arch === 'arm';
	}

	public getVersion(): Promise<string> {
		if (!this.isElectronApp) return this.rejectNotAnElectronApp<string>();
		return this.electronApi.getVersion();
	}

	public getApiHost(): Promise<string> {
		if (!this.isElectronApp) return this.rejectNotAnElectronApp<string>();
		return this.electronApi.getApiHost();
	}

	public getSsoResponse(): Promise<string> {
		if (!this.isElectronApp) return this.rejectNotAnElectronApp<string>();
		return this.electronApi.getSsoResponse();
	}

	public auth(username: string, password: string): Promise<void> {
		if (!this.isElectronApp) return this.rejectNotAnElectronApp<void>();
		return this.electronApi.auth(username, password);
	}

	public openUrl(url: string): Promise<void> {
		if (!this.isElectronApp) return this.rejectNotAnElectronApp<void>();
		return this.electronApi.openUrl(url);
	}

	public exit(): Promise<void> {
		if (!this.isElectronApp) return this.rejectNotAnElectronApp<void>();
		return this.electronApi.exit();
	}

	public onAuthChallenge(listener: (event: Electron.IpcRendererEvent, ...args: any[]) => void) {
		if (!this.isElectronApp) throw new Error('Not an electron app.');
		return this.electronApi.onAuthChallenge(listener);
	}

	private rejectNotAnElectronApp<T>() {
		return Promise.reject<T>('Not an electron app.');
	}
}
