import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account, AccountStat, AccountSubscription, AccountType, UserRole } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageConfigService } from '@razberi-ui/shared';
import { SubscriptionService } from '../../../../../services/subscription.service';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from '../../../../../services/account.service';
import { AccountSubscriptionService } from '../../../../../services/account-subscription.service';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-subscription',
	templateUrl: './account-view-tab-subscription.component.html',
	styleUrls: ['./account-view-tab-subscription.component.scss'],
})
export class AccountViewTabSubscriptionComponent {
	accountId?: number;
	canManageSubscription: boolean;
	accountSubscription: AccountSubscription;
	accountStat: AccountStat;
	isValid: boolean;
	isValidSubject: BehaviorSubject<boolean>;
	formAccountSubscription: AccountSubscription;
	validSubscription: boolean;

	constructor(
		private readonly pageConfigService: PageConfigService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly accountSubscriptionService: AccountSubscriptionService,
		private readonly accountService: AccountService
	) {
		this.canManageSubscription = false;
		this.isValidSubject = new BehaviorSubject<boolean>(false);
		this.validSubscription = false;
	}

	ngOnInit() {
		this.validSubscription = this.subscriptionService.helpers.isGlobalOrIsValid();

		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;
		this.canManageSubscription =
			this.authService.data.account.type === AccountType.Global ||
			(this.authService.data.account.type === AccountType.Provider && this.authService.helpers.userHasRole(UserRole.Administrator) == true);

		if (this.canManageSubscription)
			this.pageConfigService.data.toolbarConfig = {
				buttons: [
					{
						text: 'Save Subscription',
						icon: 'check',
						color: 'primary',
						isEnabled$: this.isValidSubject.asObservable(),
						click: () => {
							this.onSubmit();
						},
					},
				],
			};

		this.getAccount();
	}

	ngOnDestroy() {
		this.isValidSubject.complete();
		this.pageConfigService.data.toolbarConfig = null;
	}

	getAccount() {
		this.accountService.api.getAccount(this.accountId).subscribe({
			next: (account: Account) => {
				this.accountSubscription = account.subscription;
				this.accountStat = account.stat;
			},
			error: (error) => {
				this.alertMessageService.error('Error loading account subscription.', error);
			},
		});
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
		this.isValidSubject.next(this.isValid);
	}

	onChanges(accountSubscription: AccountSubscription) {
		this.formAccountSubscription = accountSubscription;
	}

	onSubmit() {
		if (this.isValidSubject.value === true) {
			this.formAccountSubscription.accountId = this.accountId;
			this.accountSubscriptionService.api.updateAccountSubscription(this.formAccountSubscription).subscribe({
				next: (_) => {
					this.alertMessageService.success('Account subscription saved.');
					this.getAccount();
				},
				error: (error) => {
					this.alertMessageService.error('Error saving account subscription.', error);
				},
			});
		}
	}
}
