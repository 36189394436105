<form id="form-email" class="row" [formGroup]="emailForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="col-sm-12 mb-3">
		<div class="input-group">
			<div class="input-group-prepend">
				<span class="input-group-text"><fa-icon icon="user"></fa-icon></span>
			</div>
			<input id="email" type="text" class="form-control" placeholder="Email" formControlName="email" name="email" required />
		</div>
		<shared-input-validation-messages [control]="emailForm.controls.email" [clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
	</div>
</form>
