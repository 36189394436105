<app-root-monitor-cloud-account-accordian
	*ngIf="isInitialized === true && groupByAccount === true && canManageAccounts === true"
	[panelTemplate]="accountDevices"
	[showSerialNumberSearch]="true"
	[refresh$]="refresh$"
	(update)="onUpdate()"
	(panelToggleEvent)="panelToggleEvent($event)"
	dataSource="devices">
	<ng-template #accountDevices let-account>
		<ng-container [ngTemplateOutlet]="tabs" [ngTemplateOutletContext]="{ account: account }"></ng-container>
	</ng-template>
</app-root-monitor-cloud-account-accordian>
<ng-container
	*ngIf="isInitialized === true && groupByAccount !== true && canManageAccounts === true"
	[ngTemplateOutlet]="tabs"
	[ngTemplateOutletContext]="{ account: null }"></ng-container>
<app-root-monitor-cloud-location-devices
	*ngIf="isInitialized === true && canManageAccounts !== true"
	[refresh$]="refresh$"
	(update)="onUpdate()"></app-root-monitor-cloud-location-devices>

<ng-template #tabs let-account="account">
	<ul ngbNav #panel="ngbNav" activeId="units" class="nav-tabs page-content-tabs">
		<li ngbNavItem="units">
			<a ngbNavLink><fa-icon icon="server"></fa-icon> Agents{{ account?.unitTotal != null ? ' (' + account.unitTotal + ')' : '' }}</a>
			<ng-template ngbNavContent>
				<app-root-monitor-cloud-unit-table
					*ngIf="account == null"
					[showAccount]="true"
					[showSerialNumberSearch]="true"></app-root-monitor-cloud-unit-table>
				<app-root-monitor-cloud-unit-table
					*ngIf="account != null"
					[accountIds]="[account.accountId]"
					[showDownloadCsv]="showDownloadCsv(account)"></app-root-monitor-cloud-unit-table>
			</ng-template>
		</li>
		<li ngbNavItem="managed-devices">
			<a ngbNavLink
				><fa-icon icon="router"></fa-icon> Network Devices{{ account?.managedDeviceTotal != null ? ' (' + account.managedDeviceTotal + ')' : '' }}</a
			>
			<ng-template ngbNavContent>
				<app-root-monitor-cloud-managed-device-table *ngIf="account == null" [showAccount]="true"></app-root-monitor-cloud-managed-device-table>
				<app-root-monitor-cloud-managed-device-table
					*ngIf="account != null"
					[accountIds]="[account.accountId]"
					[showDownloadCsv]="showDownloadCsv(account)"></app-root-monitor-cloud-managed-device-table>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="panel"></div>
</ng-template>
