<form [formGroup]="parametersForm" autocomplete="off" novalidate>
	<!-- <div class="row mb-3">
		<div class="col-12">
			<label for="minVersion">Minimum Powershell Version</label>
			<input *ngIf="readonly === true" type="text" class="form-control" formControlName="minVersion" id="minVersion" [readonly]="readonly" />
			<select *ngIf="readonly !== true" class="form-control" id="minVersion" formControlName="minVersion">
				<option value="3">3.0</option>
				<option value="4">4.0</option>
				<option value="5">5.0</option>
			</select>
		</div>
	</div> -->
	<div class="mb-3 col-12">
		<label for="script" class="form-label">Script</label>
		<div ngbTooltip="Max file upload size: 2 GB." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<div *ngIf="readonly !== true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div class="pb-2">
					<div class="form-check">
						<input
							type="radio"
							id="fileExisting"
							name="fileExisting"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.Existing"
							(change)="resetFileEvent()" />
						<label for="fileExisting" class="form-check-label">Use Previous Script</label>
					</div>
					<div class="form-check ms-3">
						<input
							type="radio"
							id="fileNew"
							name="fileNew"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.New"
							(change)="resetFileEvent()" />
						<label for="fileNew" class="form-check-label">Upload New Script</label>
					</div>
				</div>
				<div class="p-3 box-shadow">
					<ng-contaier *ngIf="fileMode === fileModeOptions.Existing">
						<div class="mb-3 font-italic">Use the existing script from the copied task:</div>
						<div *ngFor="let taskFile of taskFiles">
							<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">
								<input class="me-3" type="checkbox" readonly="readonly" [checked]="true" />
								{{ taskFile.fileName }}
							</button>
						</div>
					</ng-contaier>
					<ng-contaier *ngIf="fileMode === fileModeOptions.New">
						<div class="mb-3 font-italic">Upload a new script:</div>
						<shared-file-upload [reset$]="reset$" name="script" (fileSelected)="fileSelected($event)"></shared-file-upload>
					</ng-contaier>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) === 0">
				<shared-file-upload [reset$]="reset$" name="script" (fileSelected)="fileSelected($event)"></shared-file-upload>
			</ng-container>
		</div>
		<div *ngIf="readonly === true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div *ngFor="let taskFile of taskFiles">
					<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">{{ taskFile.fileName }}</button>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) == 0">
				<i>No files available</i>
			</ng-container>
		</div>
	</div>
</form>
