export enum LoginResult {
	Success,
	Error,
	InvalidCredentials,
	ChangePassword,
	UserPending,
	AccountPending,
	UserNotAllowed,
	UserLocked,
	InvalidUser,
	InvalidAccount,
	InvalidClientId,
	Offline,
	Saml2AssertionFailed,
	Saml2MissingAttributes,
	Saml2MissingGroup,
	Saml2Error,
	InvalidGDPR,
}
