import { SortDirection } from './sortable.model';

export class TableColumnSetting {
	primaryKey: string;
	header?: string;
	format?: string;
	useForSearch?: boolean;
	width?: string;
	transform?: any;
	path?: any[];
	clickHandlerFunction?: any;
	cellType?: string;
	hide?: boolean;
	subscriptionValid?: boolean;
	subscriptionRows?: string;
	disableSort?: boolean;
	serverSortBy?: string;
}

export class TableSettings {
	columnConfig: TableColumnSetting[];
	headerButtons?: TableHeaderButton[];
	stickyHeaderButtons?: TableHeaderButton[];
	tableRowButtons?: TableRowButton[];
	editActions?: any;
	useSearch?: boolean = false;
	useAdvancedSearch?: boolean = false;
	useServerSidePagingAndFiltering?: boolean = false;
	serverSidePagingAndFilterConfig?: TableServerSidePagingConfig;
	itemSelectMode?: TableItemSelectMode = TableItemSelectMode.None;
	allowSelectAll?: boolean = false;
	useEdit?: boolean = false;
	usePageSize?: boolean = true;
	useDays?: boolean = true;
	hidePagination?: boolean = true;
	uniqueId?: string;
	hideTotal?: boolean = false;
	hasChildren?: boolean = false;
	allSelected?: boolean = false;
	hideFilter?: boolean = false;
	checkboxAction?: any;
	emptyResultMessage?: string;
	hideEmptyResultMessage?: boolean = false;
	actionHeaderLabel?: string = '';
	disableScrollTop?: boolean = false;
}

export interface TableSearchResult {
	tableItems: any[];
	total: number;
}

export interface TableState {
	page: number;
	pageSize: number;
	searchTerm: string;
	sortColumn: string;
	sortDirection: SortDirection;
	totalVisible: number;
}

export interface TableServerSidePagingConfig {
	pageNumber?: null | number;
	pageSize?: null | number;
	totalCount?: null | number;
}

export class TableHeaderButton {
	clickHandlerFunction: any;
	label: string;
	class?: string;
	icon?: string;
	faIcon?: string;
	faIconColor?: string;
}

export class TableRowButton {
	clickHandlerFunction: any;
	label: string;
	class?: string;
	icon?: string;
	faIcon?: string;
	bottom?: boolean;
}

export enum MultiSelectType {
	None = 0,
	Page = 1,
	All = 2,
}

export enum TableItemSelectMode {
	None = 0,
	Multi = 1,
	Single = 2,
}
