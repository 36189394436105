<div *ngIf="instances?.length > 0" class="d-flex flex-wrap">
	<div
		*ngIf="scheduled > 0"
		class="status status-black cursor-default d-flex me-1 mb-1"
		[ngbTooltip]="getTooltip('scheduled')"
		placement="top"
		container="body">
		<fa-icon icon="clock" size="md"></fa-icon>
		{{ scheduled }}
	</div>
	<div
		*ngIf="executing > 0"
		class="status status-blue cursor-default d-flex me-1 mb-1"
		[ngbTooltip]="getTooltip('executing')"
		placement="top"
		container="body">
		<fa-icon icon="play" size="md"></fa-icon>
		{{ executing }}
	</div>
	<div
		*ngIf="succeeded > 0"
		class="status status-green cursor-default d-flex me-1 mb-1"
		[ngbTooltip]="getTooltip('succeeded')"
		placement="top"
		container="body">
		<fa-icon icon="check" size="md"></fa-icon>
		{{ succeeded }}
	</div>
	<div *ngIf="failed > 0" class="status status-red cursor-default d-flex me-1 mb-1" [ngbTooltip]="getTooltip('failed')" placement="top" container="body">
		<fa-icon icon="exclamation-triangle" size="md"></fa-icon>
		{{ failed }}
	</div>
	<div *ngIf="cancelled > 0" class="status status-orange cursor-default d-flex mb-1" [ngbTooltip]="getTooltip('cancelled')" placement="top" container="body">
		<fa-icon icon="ban" size="md"></fa-icon>
		{{ cancelled }}
	</div>
</div>
<div *ngIf="(instances?.length || 0) === 0" class="d-flex">--</div>
