import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Unit, UnitFields } from '@razberi-ui/core/data-types';
import { AlertMessageService, AppEnvironment } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';
import { UnitService } from '../../../services/unit.service';
import { Observable } from 'rxjs';
import { UnitWarrantyDto } from '@razberi-ui/api/cloud-api';
import * as moment from 'moment';

@Component({
	selector: 'app-root-monitor-cloud-unit-override-warranty-modal',
	templateUrl: './unit-override-warranty-modal.component.html',
	styleUrls: ['./unit-override-warranty-modal.component.scss'],
})
export class UnitOverrideWarrantyModalComponent {
	@Input() accountId: number;
	@Input() unitId: number;
	@Input() unit: Unit;
	@Input() unitFields: UnitFields;

	isProduction: boolean = false;
	isValid: boolean = false;
	formUnitFields: {
		manufactureTimestamp: Date;
		activationTimestamp: Date;
		overrideWarrantyExpiration: Date;
		overrideApplianceDefenseExpiration: Date;
	};

	constructor(
		private readonly environment: AppEnvironment,
		private readonly logger: NGXLogger,
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly unitService: UnitService
	) {}

	ngOnInit() {
		this.isProduction = this.environment.production;
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(formUnitFields: any) {
		this.formUnitFields = formUnitFields;
	}

	save() {
		if (this.isValid) {
			let o: Observable<any>;
			let f: UnitWarrantyDto = {
				accountId: this.accountId,
				unitId: this.unitId,
				unitKey: this.unitFields.unitKey,
				manufactureTimestamp: moment.utc(this.formUnitFields.manufactureTimestamp).isValid()
					? moment(this.formUnitFields.manufactureTimestamp).toISOString()
					: null,
				activationTimestamp: moment.utc(this.formUnitFields.activationTimestamp).isValid()
					? moment(this.formUnitFields.activationTimestamp).toISOString()
					: null,
				warrantyExpiration: moment.utc(this.formUnitFields.overrideWarrantyExpiration).isValid()
					? moment(this.formUnitFields.overrideWarrantyExpiration).toISOString()
					: null,
				applianceDefenseExpiration: moment.utc(this.formUnitFields.overrideApplianceDefenseExpiration).isValid()
					? moment(this.formUnitFields.overrideApplianceDefenseExpiration).toISOString()
					: null,
			};
			this.logger.log('save', f);
			o = this.unitService.api.setUnitWarranty(this.accountId, this.unitId, f);
			o.subscribe({
				next: (result) => {
					this.alertMessageService.success(`Warranty dates saved.`);
					this.activeModal.close();
				},
				error: (error) => {
					this.alertMessageService.error(`Error saving warranty dates.`, error);
				},
			});
		}
	}

	delete() {
		this.unitService.api.deleteUnitFields(this.accountId, this.unitId).subscribe({
			next: (result) => {
				this.alertMessageService.success(`Deleted unit fields record.`);
				this.activeModal.close();
			},
			error: (error) => {
				this.alertMessageService.error(`Error deleting unit fields record.`, error);
			},
		});
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
