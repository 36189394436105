import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'shared-cell-status',
	templateUrl: './cell-status.component.html',
	styleUrls: ['./cell-status.component.scss'],
})
export class CellStatusComponent {
	@Input() data: any;
	@Input() icon: string;
	@Input() color: string;
	@Input() text: string;
	@Input() tooltip: string;
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			this.icon = changes.data.currentValue.icon;
			this.color = changes.data.currentValue.color;
			this.text = changes.data.currentValue.text;
			this.tooltip = changes.data.currentValue.tooltip;
			this.disabled = changes.data.currentValue.disabled;
			this.showText = changes.data.currentValue.showText;
		}

		if (this.color?.length > 0 && this.color?.startsWith('status-') !== true) {
			this.color = `status-${this.color}`;
		}
	}
}
