import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSearchConfiguration, CloudApiConfigService, CoreServerSettingsService, SearchFilter, AuthService } from '@razberi-ui/api/cloud-api';
import { Account, AccountStatus, AccountType, RecordingServer, RecordingServerStatus, Unit } from '@razberi-ui/core/data-types';
import { AlertMessageService, DateRange, PageSidePanelService, TableServerSidePagingConfig, TableSettings } from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { UnitService } from '../../../services/unit.service';
import { ReportService } from '../../../services/report.service';
import { DashboardService } from '../../../services/dashboard.service';
import * as moment from 'moment';
import { DeviceEditModalComponent } from '../../devices/device-edit-modal/device-edit-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-vms-recording-servers-table',
	templateUrl: './vms-recording-servers-table.component.html',
	styleUrls: ['./vms-recording-servers-table.component.scss'],
})
export class VmsRecordingServersTableComponent {
	@Input() accountIds: number[];
	@Input() dateRange: DateRange;
	@Input() getOffline: boolean = false;
	@Input() showAccount: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() recordingServersData: RecordingServer[];
	@Input() dataSource: string;
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	recordingServers: RecordingServer[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isDataSourceReady: boolean = false;
	isInitialized: boolean = false;

	constructor(
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly unitService: UnitService,
		private readonly reportService: ReportService,
		private readonly dashboardService: DashboardService,
		private readonly configService: CloudApiConfigService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly pageSidePanelService: PageSidePanelService
	) {}

	ngOnInit() {
		if (this.dataSource?.startsWith('dashboard')) {
			this.subscriptions.add(
				this.dashboardService.streams.selectedAccounts$.pipe(distinctUntilChanged()).subscribe((accounts: Account[]) => {
					if (accounts) {
						this.accountIds = accounts.map((account: Account) => account.accountId);
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getRecordingServers();
					}
				})
			);
			this.subscriptions.add(
				this.dashboardService.streams.selectedDateRange$.pipe(distinctUntilChanged()).subscribe((dateRange: DateRange) => {
					if (dateRange) {
						this.dateRange = dateRange;
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getRecordingServers();
					}
				})
			);
		} else {
			this.initialSearchFilter = { startTimestamp: moment().startOf('day').subtract(30, 'days').toDate() };
			if (
				!this.accountIds?.length &&
				this.authService.data.account.type === AccountType.Global &&
				this.settingsService.data.settings?.cloudUI?.preloadSearchFilterAccounts === true
			)
				this.initialSearchFilter.accounts = [1, 2, 3, 4, 5].map((accountId: number) => {
					return { accountId: accountId };
				});
		}

		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showDateRange: !this.dataSource,
			showAccounts: this.showAccount === true && this.dataSource?.startsWith('dashboard') !== true,
			showUnits: true,
			showLocations: true,
			showTags: true,
			showVmsServices: true,
			showRecordingServers: true,
			forSubscribedAccounts: true,
		};

		const ts: TableSettings = {
			useAdvancedSearch: this.showSearch,
			useEdit: true,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.recordingServersData ? false : true,
			serverSidePagingAndFilterConfig: this.recordingServersData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'recordingServerId',
			headerButtons: [],
			stickyHeaderButtons: [],
			columnConfig: [
				{
					primaryKey: 'updateTimestamp',
					header: 'Last Update',
					useForSearch: true,
					width: '220px',
					cellType: 'icon',
					transform: (value) => {
						return { text: moment(value).format('l LTS'), icon: 'clock' };
					},
				},
				{
					primaryKey: 'initialOutage',
					header: 'Initial Outage',
					useForSearch: true,
					cellType: 'icon',
					transform: (value) => {
						return moment(value).isValid() ? { text: moment(value).format('l LTS'), icon: 'clock' } : { text: '--' };
					},
				},
				{
					primaryKey: 'unitName',
					header: 'Device',
					cellType: 'link-button',
					transform: (value) => {
						return !value ? { text: '--', disabled: true } : { text: value, icon: 'server' };
					},
					clickHandlerFunction: (params) => {
						this.openSidePanel(params, 'details');
					},
				},
				{
					primaryKey: 'accountName',
					header: 'Account',
					cellType: 'router-link',
					transform: (value, tableItem) => {
						if (value) {
							const c: any = {
								text: value,
								route: ['/accounts', tableItem.accountId],
								subtexts: [],
								disabled: tableItem.accountStatus === AccountStatus.Pending,
							};
							if (tableItem.accountFaviconUrl) c.image = `${this.configService.data.config.apiHost}${tableItem.accountFaviconUrl}`;
							else c.icon = 'building';
							return c;
						} else return { text: '--', disabled: true };
					},
				},
				{
					primaryKey: 'locationName',
					header: 'Location',
					cellType: 'icon',
					transform: (value) => {
						return !value ? { text: '--' } : { text: value, icon: 'map-marker-alt' };
					},
				},
				{ primaryKey: 'vmsHostname', header: 'VMS Hostname' },
				{
					primaryKey: 'vmsServiceName',
					header: 'VMS Server Name',
					cellType: 'icon',
					transform: (value) => {
						return !value ? { text: '--' } : { text: value, icon: 'server' };
					},
				},
				{
					primaryKey: 'name',
					header: 'Recording Service Name',
					cellType: 'icon',
					transform: (value) => {
						return !value ? { text: '--' } : { text: value, icon: 'video' };
					},
				},
				{ primaryKey: 'status', header: 'Status', cellType: 'recording-server-status' },
			],
			editActions: [
				{
					label: 'View Service Details',
					faIcon: 'info-circle',
					clickHandlerFunction: (params) => {
						if (this.subscriptionService.helpers.isGlobalOrIsStatusValid(params.accountSubscriptionStatus)) {
							this.openSidePanel(params, 'details');
						} else {
							this.subscriptionService.helpers.showInvalidSubscriptionModal();
						}
					},
				},
				{
					label: 'View Alerts',
					faIcon: 'bell',
					clickHandlerFunction: (params) => {
						if (this.subscriptionService.helpers.isGlobalOrIsStatusValid(params.accountSubscriptionStatus)) {
							this.openSidePanel(params, 'alerts');
						} else {
							this.subscriptionService.helpers.showInvalidSubscriptionModal();
						}
					},
				},
			],
		};
		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getRecordingServers();
				},
			});
		if (!this.showAccount || (this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)) {
			const accountNameIdx = ts.columnConfig.findIndex((col) => col.primaryKey === 'accountName');
			if (accountNameIdx >= 0) ts.columnConfig.splice(accountNameIdx, 1);
		}
		this.tableSettings = ts;

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getRecordingServers();
				})
			);

		this.getRecordingServers();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized) this.getRecordingServers();
	}

	getRecordingServers() {
		if (this.recordingServersData) {
			this.recordingServers = [...this.recordingServersData];
			this.isInitialized = true;
		} else {
			if (!this.dataSource || this.isDataSourceReady) {
				const filter: SearchFilter = {
					pageNumber: this.pageNumber,
					pageSize: this.pageSize,
					accounts: this.accountIds?.map((accountId: number) => {
						return { accountId: accountId };
					}),
					recordingServerStatuses: this.getOffline
						? [
								RecordingServerStatus.Offline,
								RecordingServerStatus.NotAttached,
								RecordingServerStatus.NotConnected,
								RecordingServerStatus.StorageError,
								RecordingServerStatus.Disconnected,
								RecordingServerStatus.Disabled,
						  ]
						: [RecordingServerStatus.Online],
					...this.dateRange,
					...this.searchFilter,
				};

				this.dashboardService.api.getRecordingServers(filter).subscribe((result) => {
					let { pageNumber, pageSize, totalCount, results } = result;
					this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber, pageSize, totalCount };
					this.recordingServers = results;
					this.isInitialized = true;
				});
			}
		}
	}

	openSidePanel(unit: Unit, activeTabKey: string) {
		this.pageSidePanelService.helpers.openUnitSidePanel(
			unit.name,
			activeTabKey,
			unit.accountId,
			unit.unitId,
			this.unitService.helpers.userCanEditUnit(),
			(unit) => {
				this.editUnit(unit);
			}
		);
	}

	editUnit(device: Unit) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = device.accountId;
		modal.componentInstance.unitId = device.unitId;
		modal.result.then(
			(result) => {
				this.getRecordingServers();
			},
			(_) => {}
		);
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getRecordingServers();
		this.dashboardService.helpers.refresh();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getRecordingServers();
		this.dashboardService.helpers.refresh();
	}

	downloadCsv() {
		const filter: SearchFilter = {
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			recordingServerStatuses: this.getOffline
				? [
						RecordingServerStatus.Offline,
						RecordingServerStatus.NotAttached,
						RecordingServerStatus.NotConnected,
						RecordingServerStatus.StorageError,
						RecordingServerStatus.Disconnected,
						RecordingServerStatus.Disabled,
				  ]
				: [RecordingServerStatus.Online],
			...this.dateRange,
			...this.searchFilter,
		};
		this.reportService.api.getRecordingServersCsv(filter).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting alerts CSV.', error);
			},
		});
	}
}
