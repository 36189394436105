export enum UpdateCategory {
	OperatingSystem = 1,
	Firmware = 2,
	Switch = 3,
	Other = 4,
}

export interface UpdateType {
	updateTypeId: number;
	name: string;
	category: UpdateCategory;
	description: string;
	severity: number;
	taskParamType: string;
}
