<div class="layout-fixed-footer d-flex align-items-center">
	<div class="h6 text-muted">v{{ version }}</div>
	<div class="d-flex w-100 align-items-center justify-content-end">
		<button
			*ngFor="let button of configuration?.buttons"
			type="button"
			class="btn m-0 ms-3 {{ button.color }}"
			[disabled]="!button.isEnabled"
			(click)="button.click()">
			<fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
			{{ button.text }}
		</button>
		<div *ngFor="let checkbox of configuration?.checkboxes; let rowIndex = index" class="form-check me-3">
			<input
				type="checkbox"
				class="form-check-input"
				id="page-toolbar-checkbox_{{ rowIndex }}"
				[ngModel]="checkbox.isChecked"
				[disabled]="!checkbox.isEnabled"
				(change)="checkbox.change($event.target.checked)" />
			<label for="page-toolbar-checkbox_{{ rowIndex }}" class="form-check-label">
				<fa-icon *ngIf="checkbox.icon" [icon]="checkbox.icon"></fa-icon>
				{{ checkbox.text }}
			</label>
		</div>
	</div>
</div>
