import { ChangeDetectorRef, Component } from '@angular/core';
import { NavService, PageConfigService, PageFooterConfiguration, VersionService } from '@razberi-ui/shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'theme-razberi-core-fixed-footer',
	templateUrl: './theme-razberi-core-fixed-footer.component.html',
	styleUrls: ['./theme-razberi-core-fixed-footer.component.scss'],
})
export class ThemeRazberiCoreFixedFooterComponent {
	subscriptions: Subscription = new Subscription();
	buttonSubscriptions: Subscription = new Subscription();
	checkboxSubscriptions: Subscription = new Subscription();
	version: string;
	configuration: PageFooterConfiguration = { buttons: [], checkboxes: [] };

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly versionService: VersionService,
		private readonly pageConfigService: PageConfigService,
		private readonly navService: NavService
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.versionService.streams.version$.subscribe((version) => {
				this.version = version ?? 'N/A';
			})
		);

		this.navService.streams.navEndEvents$.subscribe((_) => {
			this.configuration = { buttons: [], checkboxes: [] };
		});

		this.subscriptions.add(
			this.pageConfigService.streams.footerConfig$.subscribe((configuration: PageFooterConfiguration) => {
				if (this.buttonSubscriptions) this.buttonSubscriptions.unsubscribe();
				if (this.checkboxSubscriptions) this.checkboxSubscriptions.unsubscribe();

				this.buttonSubscriptions = new Subscription();
				this.checkboxSubscriptions = new Subscription();

				let c: PageFooterConfiguration = configuration;
				if (!c) c = { buttons: [], checkboxes: [] };

				c.buttons?.forEach((b) => {
					if (b.isEnabled$ == null) b.isEnabled = true;
					else
						this.buttonSubscriptions.add(
							b.isEnabled$.subscribe((isEnabled: boolean) => {
								b.isEnabled = isEnabled;
								this.changeDetectorRef.detectChanges();
							})
						);
				});

				c.checkboxes?.forEach((cb) => {
					if (!cb.isEnabled$) cb.isEnabled = true;
					else
						this.checkboxSubscriptions.add(
							cb.isEnabled$.subscribe((isEnabled: boolean) => {
								cb.isEnabled = isEnabled;
								this.changeDetectorRef.detectChanges();
							})
						);
				});

				this.configuration = c;
				this.changeDetectorRef.detectChanges();
			})
		);
	}
}
