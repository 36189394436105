<ng-container *ngIf="(manageTaskTypeId || 0) === 0">
	<div class="text-center font-italic box-shadow p-4">Task Type Not Selected</div>
</ng-container>
<ng-container *ngIf="manageTaskTypeId > 0">
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 1">
		<app-root-monitor-cloud-manage-task-form-parameters-powershell-script
			[accountId]="accountId"
			[parameters]="parameters"
			[taskFiles]="taskFiles"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(filesSelected)="onFilesSelected($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-powershell-script>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 3">
		<app-root-monitor-cloud-manage-task-form-parameters-win-update
			[accountId]="accountId"
			[parameters]="parameters"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-win-update>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 4">
		<app-root-monitor-cloud-manage-task-form-parameters-restart-windows-service
			[accountId]="accountId"
			[parameters]="parameters"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-restart-windows-service>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 7">
		<app-root-monitor-cloud-manage-task-form-parameters-apply-win-update
			[accountId]="accountId"
			[parameters]="parameters"
			[taskFiles]="taskFiles"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(filesSelected)="onFilesSelected($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-apply-win-update>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 8">
		<app-root-monitor-cloud-manage-task-form-parameters-run-application
			[accountId]="accountId"
			[parameters]="parameters"
			[taskFiles]="taskFiles"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(filesSelected)="onFilesSelected($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-run-application>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 9">
		<app-root-monitor-cloud-manage-task-form-parameters-install-msi
			[accountId]="accountId"
			[parameters]="parameters"
			[taskFiles]="taskFiles"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(filesSelected)="onFilesSelected($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-install-msi>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.taskTypeId === 10">
		<app-root-monitor-cloud-manage-task-form-parameters-exec-package
			[accountId]="accountId"
			[parameters]="parameters"
			[taskFiles]="taskFiles"
			[readonly]="readonly"
			(parametersChanged)="onParametersChanged($event)"
			(filesSelected)="onFilesSelected($event)"
			(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters-exec-package>
	</ng-container>
	<ng-container *ngIf="selectedTaskType?.requiresParams !== true">
		<div class="text-center font-italic box-shadow p-4">No parameters required</div>
	</ng-container>
</ng-container>
