<h4 class="card-title text-center">Activate your login</h4>
<div *ngIf="status === 0">
	<p class="card-text mt-3 mb-3">Loading...</p>
</div>
<div *ngIf="status === 1">
	<p class="card-text mt-3">Create a password for your login.</p>
	<app-root-monitor-cloud-auth-password-form
		[isCurrentUser]="true"
		[requireExisting]="false"
		(isValid)="onIsValid($event)"
		(changes)="onChanges($event)"
		(submit)="activate()"></app-root-monitor-cloud-auth-password-form>
	<div class="d-flex flex-row-reverse justify-content-between align-items-end">
		<button id="btn-activate" type="button" class="btn btn-primary" (click)="activate()" [disabled]="!isValid">
			<fa-icon icon="user-lock"></fa-icon> Activate
		</button>
	</div>
</div>
<div *ngIf="status === 2">
	<p class="card-text text-success text-center mt-5 mb-3">Your login has been activated.</p>
	<div class="d-flex flex-row-reverse justify-content-center align-items-end">
		<button id="btn-login" type="button" class="btn btn-link" (click)="login()"><fa-icon icon="sign-in" class="me-1"></fa-icon>Login</button>
	</div>
</div>
<div *ngIf="status === 3">
	<p *ngIf="message" class="card-text text-danger text-center mt-3 mb-3">{{ message }}</p>
	<p class="card-text text-danger text-center mb-3">You can reset your password to activate.</p>
	<div class="d-flex flex-row-reverse justify-content-between align-items-end">
		<button id="btn-login" type="button" class="btn btn-link" (click)="login()"><fa-icon icon="sign-in" class="me-1"></fa-icon>Login</button>
		<button id="btn-reset-password" type="button" class="btn btn-link" (click)="reset()">
			<fa-icon icon="unlock" class="me-1"></fa-icon>Reset Password
		</button>
	</div>
</div>
