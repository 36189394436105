import { Component } from '@angular/core';
import { LoginFormData } from '../../../data-types/login-form-data.model';
import { ActivatedRoute } from '@angular/router';
import { SetupRegistrationResult } from '@razberi-ui/core/data-types';
import { SetupService } from '../../../services/setup.service';
import { CloudApiConfigService } from '@razberi-ui/api/cloud-api';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-register-token-public',
	templateUrl: './register-token-public.component.html',
	styleUrls: ['./register-token-public.component.scss'],
})
export class RegisterTokenPublicComponent {
    token: string;
	tokenError: boolean = false;
	requiresCredentials: boolean = false;
	loginData: LoginFormData;
	loginFormIsValid: boolean;
	result: SetupRegistrationResult;

	constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly setupService: SetupService,
        readonly configService: CloudApiConfigService
    ) {}

	ngOnInit(): void {
		this.token = this.activatedRoute.snapshot.paramMap.get('token');
		if (this.token != null) {
			this.tokenError = false;
			this.setupService.api.tokenRequiresCredentials(this.token).subscribe((result) => {console.log('this.tokenRequiresCredentials', result);
				this.requiresCredentials = result;
				if (result !== true) this.processRegistrationToken();
			});
		} else {
			this.tokenError = true;
		}
	}

	processRegistrationToken() {
		this.setupService.api.registerToken(this.token, this.loginData?.email, this.loginData?.password).subscribe((result) => {
			this.result = result;
		});
	}

	onLogFormIsValid(isValid: boolean) {
		this.loginFormIsValid = isValid;
	}

	onLogFormChanges(loginData: LoginFormData) {
		this.loginData = loginData;
	}
}
