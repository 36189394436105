export interface CoreServerSettings {
	global: GlobalSettings;
	cloudApi: CloudApiSettings;
	sinkApi: SinkApiSettings;
	cloudAgent: CloudAgentSettings;
	monitorAgent: MonitorAgentSettings;
	aws: AwsSettings;
	smtp: SmtpSettings;
	sms: SmsSettings;
	fileStore: FileStoreSettings;
	manage: ManageSettings;
	compliance: ComplianceSettings;
	cloudUI: CloudUISettings;
	sso: SsoSettings;
	rootAccountName: string;
}

export interface GlobalSettings {
	environment: string;
	cloudUri: string;
	cloudApiUri: string;
	sinkApiUri: string;
	settingsInterval: string;
	isPremise: boolean;
	baseTitle: string;
}

export interface CloudApiSettings {
	accessTokenExpireTimeSpan: string;
	refreshTokenExpireTimeSpan: string;
	allowedOrigins: string[];
	recaptchaSecretKey: string;
	enableSwagger: boolean;
}

export interface SinkApiSettings {
	accessTokenExpireTimeSpan: string;
	refreshTokenExpireTimeSpan: string;
	enableSwagger: boolean;
}

export interface CloudAgentSettings {
	processInterval: string;
	provisionInterval: string;
	reportInterval: string;
	pruneInterval: string;
	serverOfflineThreshold: string;
	primaryInstance: string;
	performBackups: boolean;
	accountDatabaseLogins: string;
}

export interface MonitorAgentSettings {
	cloudUri: string;
	cloudApiUri: string;
	sinkApiUri: string;
	settingsInterval: string;
	updateInterval: string;
	unitInterval: string;
	disabledAlertTypes: number[];
	deviceOfflineThreshold: number;
	remoteApplicationDownloadEnabled: boolean;
}

export interface AwsSettings {
	accessKey: string;
	secretKey: string;
	region: string;
}

export interface SmtpSettings {
	host: string;
	port: number;
	useTls: boolean;
	from: string;
	fromDisplay: string;
	username: string;
	password: string;
	allowEdit: boolean;
	disableRazberiDefaultEmailFooter: boolean;
}

export interface SmsSettings {
	provider: string;
	twilioSmsSettings: TwilioSmsSettings;
	tropoSmsSettings: TropoSmsSettings;
}

export interface TwilioSmsSettings {
	accountSid: string;
	authToken: string;
	outgoingNumber: string;
}

export interface TropoSmsSettings {
	username: string;
	password: string;
	outgoingNumber: string;
	internationalOutgoingNumber: string;
	textToken: string;
}

export enum SmsSettingsProvider {
	TwilioSmsProvider,
	TropoSmsProvider,
}

export interface SsoSettings {
	enable: boolean;
	enforce: boolean;
	hideLoginButton: boolean;
	signRequests: boolean;
	encryptAssertions: boolean;
	adminGroup: string;
	userGroup: string;
	installerGroup: string;
	spSignatureCertificate: string;
	spSignatureKey: string;
	spEncryptionCertificate: string;
	spEncryptionKey: string;
	idpEntityId: string;
	idpRequestUri: string;
	idpCertificate: string;
}

export interface FileStoreSettings {
	provider: string;
	s3FileStoreSettings: S3FileStoreSettings;
	localFileStoreSettings: LocalFileStoreSettings;
}

export interface S3FileStoreSettings {
	bucketPrefix: string;
}

export interface LocalFileStoreSettings {
	rootPath: string;
}

export enum FileStoreSettingsProvider {
	LocalFileStoreProvider,
	S3FileStoreProvider,
}

export interface ManageSettings {
	disabledTaskTypes: number[];
}

export interface ComplianceSettings {
	enablePrivacyPolicy: boolean;
	allowPrivacyPolicyEnable: boolean;
	enableResellerAgreement: boolean;
	allowResellerAgreementEnable: boolean;
}

export interface CloudUISettings {
	allowUnitDelete: boolean;
	allowUnitDeletePermanent: boolean;
	allowUnitWarrantyOverride: boolean;
	preloadSearchFilterAccounts: boolean;
	allowDevicePageRegistration: boolean;
}

export interface LoginSettings {
	ssoEnabled: boolean;
	ssoEnforced: boolean;
	hideSsoLoginButton: boolean;
}

export interface SettingsDashboard {
	accounts: number;
	devices: number;
}
