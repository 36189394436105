import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'shared-qr-code',
	templateUrl: './qr-code.component.html',
	styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
	@Input() data: string;
	@Input() width: number = 256;
	@Input() errorCorrectionLevel: string = 'M'; // QR Correction level ('L', 'M', 'Q', 'H')
	@Input() elementType: string = 'canvas'; // 'canvas', 'svg', 'img', 'url' (alias for 'img')

	constructor() {}

	ngOnInit(): void {}
}
