import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataSearchConfiguration, SearchFilter } from '@razberi-ui/api/cloud-api';
import { SortEvent, TableServerSidePagingConfig, TableSettings } from '@razberi-ui/shared';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-root-monitor-cloud-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
	@Input() isInitialized: boolean = false;
	@Input() showSearch: boolean = false;

	// data-table
	@Input() tableItems: any[] = [];
	@Input() tableSettings: TableSettings = { columnConfig: [] };

	@Output() daysChanged = new EventEmitter<string>();
	@Output() getChildren = new EventEmitter();
	@Output() childAction = new EventEmitter();
	@Output() selectedItems = new EventEmitter<any[]>();
	@Output() toggleRow = new EventEmitter<boolean>();
	@Output() pagingChanged = new EventEmitter<TableServerSidePagingConfig>();
	@Output() sortingChanged = new EventEmitter<SortEvent>();

	// data-search
	@Input() searchConfiguration: DataSearchConfiguration;
	@Input() accountIds: number[];
	@Input() alertTypeIds: number[];
	@Input() manageTaskTypeIds: number[];
	@Input() updateTypeIds: number[];
	@Input() initialSearchFilter: SearchFilter;
	@Input() refreshData$: Observable<void>;

	@Output() applyFilter: EventEmitter<SearchFilter> = new EventEmitter();

	onPagingChanged($event) {
		this.pagingChanged.emit($event);
	}
}
