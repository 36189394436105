import { Injectable } from '@angular/core';
import { CloudApiService } from '@razberi-ui/api/cloud-api';
import { AccountSubscription } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AccountSubscriptionService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAccountSubscription: (accountId: number): Observable<AccountSubscription> => {
				return this.cloudApiService.accountSubscriptions.getAccountSubscription(accountId);
			},
			updateAccountSubscription: (accountSubscription: AccountSubscription): Observable<void> => {
				return this.cloudApiService.accountSubscriptions.updateAccountSubscription(accountSubscription);
			},
		};
	}
}
