<form [formGroup]="parametersForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="serviceName" class="form-label">
			Windows Service Name &nbsp;
			<span class="text-primary">*</span>
			<div
				ngbTooltip="Use the service name (not the display name) found in properties. Disabled services cannot be started"
				placement="right"
				container="body"
				class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</label>
		<input type="text" class="form-control" formControlName="serviceName" id="serviceName" required [readonly]="readonly" />
		<shared-input-validation-messages [control]="parametersForm.controls.serviceName"></shared-input-validation-messages>
	</div>
</form>
