import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { AlertMessageService, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { AppRootMonitorCloudConstants } from '../../../app-root-monitor-cloud.constant';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-settings-cloud-api-form',
	templateUrl: './settings-cloud-api-form.component.html',
	styleUrls: ['./settings-cloud-api-form.component.scss'],
})
export class SettingsCloudApiFormComponent implements OnInit {
	subscriptions: Subscription = new Subscription();
	formIsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly: boolean = true;
	settingsForm: UntypedFormGroup;

	constructor(
		readonly settingsService: CoreServerSettingsService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService
	) {}

	ngOnInit(): void {
		this.buildForm();
		this.subscriptions.add(
			this.settingsForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.formIsValid$.next(this.settingsForm.valid === true);
			})
		);

		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				if (settings?.cloudApi != null) this.settingsForm?.patchValue(settings?.cloudApi);
				else this.settingsForm.reset();
				this.configureToolbar();
			})
		);
	}
	buildForm() {
		this.settingsForm = this.formBuilder.group({
			accessTokenExpireTimeSpan: [null, [Validators.required, Validators.pattern(AppRootMonitorCloudConstants.regex.timespan)]],
			refreshTokenExpireTimeSpan: [null, [Validators.required, Validators.pattern(AppRootMonitorCloudConstants.regex.timespan)]],
			allowedOrigins: [null, Validators.required],
			recaptchaSecretKey: [null],
			enableSwagger: [null],
		});
		this.settingsForm.disable();
	}

	configureToolbar() {
		const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
		if (this.readonly === true) {
			c.buttons.push({
				text: 'Edit',
				icon: 'edit',
				color: 'primary',
				isEnabled: this.settingsService.data.settings?.cloudApi != null,
				click: () => {
					this.readonly = false;
					this.settingsForm.enable();
					this.configureToolbar();
				},
			});
		} else {
			c.buttons.push({
				text: 'Cancel Edit',
				icon: 'times-circle',
				color: 'light',
				isEnabled: this.settingsService.data.settings?.cloudApi != null,
				click: () => {
					this.readonly = true;
					this.settingsForm.disable();
					this.configureToolbar();
				},
			});
			c.buttons.push({
				text: 'Save',
				icon: 'check-circle',
				color: 'primary',
				isEnabled$: this.formIsValid$.asObservable(),
				click: () => {
					this.saveSettings();
				},
			});
		}
		this.pageConfigService.data.toolbarConfig = c;
	}

	saveSettings() {
		this.settingsService.api.setCloudApi(this.settingsForm.value).subscribe((_) => {
			this.readonly = true;
			this.settingsForm.disable();
			this.configureToolbar();
			this.alertMessageService.success('The CloudApi settings were updated successfully.');
			this.settingsService.api.getFullSettings().subscribe();
		});
	}
}
