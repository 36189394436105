<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title"><fa-icon icon="server"></fa-icon><span class="ps-2">Selected Devices</span></h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<shared-data-table [tableSettings]="tableSettings" [tableItems]="units" ngbAutoFocus>
			<ng-template #dataTableCellTypes let-value="value" let-column="column" let-tableItem="tableItem">
				<app-root-monitor-cloud-data-table-cell-types
					[value]="value"
					[column]="column"
					[tableItem]="tableItem"></app-root-monitor-cloud-data-table-cell-types>
			</ng-template>
		</shared-data-table>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="close()"><fa-icon icon="times"></fa-icon> Close</button>
	</div>
</div>
