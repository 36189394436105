import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudApiService, SearchFilter, SearchFilterResult, UpdateManagedDeviceDto } from '@razberi-ui/api/cloud-api';
import {
	AccountObjectIds,
	ManagedDevice,
	ManagedDeviceExtensionAgent,
	ManagedDeviceExtensionCngeDevice,
	ManagedDeviceExtensionEndpointDefender,
	ManagedDeviceExtensionInternalSwitch,
	ManagedDeviceExtensionSnmpDevice,
	ManagedDeviceType,
	ManagedDeviceTypeAttributes,
} from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ManagedDeviceService {
	constructor(private readonly cloudApiService: CloudApiService, private readonly utilsService: UtilitiesService) {}

	get api() {
		return {
			getManagedDevices: (searchFilter: SearchFilter): Observable<SearchFilterResult<ManagedDevice>> => {
				return this.cloudApiService.managedDevices.getManagedDevices(searchFilter).pipe(
					map((results) => {
						results?.results?.forEach((d) => this.helpers.mapCustomProperties(d));
						return results;
					})
				);
			},
			getManagedDevice: (accountId: number, managedDeviceId: number): Observable<ManagedDevice> => {
				return this.cloudApiService.managedDevices.getManagedDevice(accountId, managedDeviceId).pipe(
					map((d) => {
						this.helpers.mapCustomProperties(d);
						return d;
					})
				);
			},
			getManagedDevicesForUnits: (accountId: number, unitIds: number[]): Observable<ManagedDevice[]> => {
				const accountObjectIds: AccountObjectIds = {
					accountId: accountId,
					objectIds: unitIds,
				};
				return this.cloudApiService.managedDevices.getManagedDevicesForUnits(accountObjectIds).pipe(
					map((devices) => {
						devices?.forEach((d) => this.helpers.mapCustomProperties(d));
						return devices;
					})
				);
			},
			getManagedDevicesCsv: (searchFilter: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.managedDevices.getManagedDevicesCsv(searchFilter);
			},
			updateManagedDevice: (accountId: number, deviceId: number, updateDto: UpdateManagedDeviceDto): Observable<void> => {
				return this.cloudApiService.managedDevices.updateManagedDevice(accountId, deviceId, updateDto);
			},
		};
	}

	get helpers() {
		return {
			deserializeCustomData: (
				managedDevice: ManagedDevice
			):
				| ManagedDeviceExtensionInternalSwitch
				| ManagedDeviceExtensionEndpointDefender
				| ManagedDeviceExtensionSnmpDevice
				| ManagedDeviceExtensionCngeDevice
				| ManagedDeviceExtensionAgent => {
				if ((managedDevice?.customData?.length || 0) === 0) return null;

				switch (managedDevice.type) {
					case ManagedDeviceType.Agent:
					case ManagedDeviceType.InternalSwitch:
					case ManagedDeviceType.EndpointDefender:
					case ManagedDeviceType.SnmpDevice:
					case ManagedDeviceType.CngeSwitch:
						return JSON.parse(managedDevice.customData);
					case ManagedDeviceType.Unknown:
					default:
						return JSON.parse(managedDevice.customData);
				}
			},
			mapCustomProperties: (device: ManagedDevice) => {
				if (device == null) return;

				device.nameFormatted =
					device.type === ManagedDeviceType.Agent
						? `${device.name} (${device.serialNumber})`
						: `${device.modelName || 'SNMP Device'} (${device.serialNumber || this.utilsService.formatters.asMacAddress(device.macAddress)})`;
				device.typeFormatted = ManagedDeviceTypeAttributes.get(device.type)?.label ?? '--';
				device.typeFormattedPlural = `${device.typeFormatted}${device.type === ManagedDeviceType.InternalSwitch ? 'es' : 's'}`;

				if ((device?.customData?.length ?? 0) === 0) return;

				const customData = this.helpers.deserializeCustomData(device);
				Object.keys(customData).forEach(function (key) {
					device[key] = customData[key];
				});
			},
			IsAgentInternalSwitch: (origin: ManagedDevice, destination: ManagedDevice): boolean => {
				return (
					origin.unitId === destination.unitId &&
					((origin.type === ManagedDeviceType.Agent && destination.type === ManagedDeviceType.InternalSwitch) ||
						(origin.type === ManagedDeviceType.InternalSwitch && destination.type === ManagedDeviceType.Agent))
				);
			},
			IsNeighborFound: (origin: ManagedDevice, destination: ManagedDevice): boolean => {
				return (
					origin.lldpNeighbors?.some((n) => n.macAddress === destination.macAddress) == true ||
					destination.lldpNeighbors?.some((n) => n.macAddress === origin.macAddress) == true
				);
			},
		};
	}
}
