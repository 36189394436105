import {
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
	MultiSelectType,
	TableItemSelectMode,
	TableSearchResult,
	TableServerSidePagingConfig,
	TableSettings,
	TableState,
} from '../../data-types/data-table.model';
import { SortDirection, SortEvent } from '../../data-types/sortable.model';
import { NgbdSortableHeader } from '../../directives/sortable.directive';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
	selector: 'shared-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnDestroy {
	@Input() tableItems = [];
	@Input() tableSettings: TableSettings = { columnConfig: [] };

	@Output() daysChanged = new EventEmitter<string>();
	@Output() getChildren = new EventEmitter();
	@Output() childAction = new EventEmitter();
	@Output() selectedItems = new EventEmitter<any[]>();
	@Output() toggleRow = new EventEmitter<boolean>();
	@Output() pagingChanged = new EventEmitter<TableServerSidePagingConfig>();
	@Output() sortingChanged = new EventEmitter<SortEvent>();

	@ContentChild('dataTableCellTypes') dataTableCellTypesRef?: TemplateRef<any>;
	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
	@ViewChild('scrollTop', { read: ElementRef }) scrollTop: ElementRef;

	constructor(private readonly logger: NGXLogger, private readonly utils: UtilitiesService) {}

	tableId: string;
	total: Observable<number>;
	days: string = '30';
	searchMap: any;
	search: boolean = false;
	showLoader: boolean = false;
	loadingSubscription: Subscription;
	totalVisibleSubscription: Subscription;
	tableItemsSelectedSubscription: Subscription;
	dropOpen: boolean = false;
	selectedCount: number = 0;
	bulkSelection: boolean = false;
	pageSelected: boolean = false;
	allSelected: boolean = false;
	multiSelectTypeOptions: any = MultiSelectType;
	selectedMultiSelectType: MultiSelectType = MultiSelectType.None;
	itemSelectModeOptions: any = TableItemSelectMode;
	retainSelectionOnChange: boolean = false;

	private _state: TableState = {
		page: 1,
		pageSize: 10,
		searchTerm: '',
		sortColumn: 'timestamp',
		sortDirection: 'desc',
		totalVisible: 0,
	};

	_loading$ = new BehaviorSubject<boolean>(true);
	_search$ = new Subject<void>();
	_tableItems$ = new BehaviorSubject<any[]>([]);
	_tableItemsSelected$ = new BehaviorSubject<any[]>([]);
	_tableItemsExcluded$ = new BehaviorSubject<any[]>([]);
	_total$ = 0;

	get tableItems$() {
		return this._tableItems$.asObservable();
	}
	get tableItemsSelected$() {
		return this._tableItemsSelected$;
	}
	get tableItemsExcluded$() {
		return this._tableItemsExcluded$;
	}
	get total$() {
		return this._total$;
	}
	get loading$() {
		return this._loading$.asObservable();
	}
	get usePage() {
		return this.tableSettings.usePageSize;
	}
	get uniqueId() {
		return this.tableSettings.uniqueId;
	}
	get useDays() {
		return this.tableSettings.useDays;
	}
	get useSearch() {
		return this.tableSettings.useSearch;
	}
	get totalVisible() {
		return this._state.totalVisible;
	}
	get bottomPadding() {
		if (this.tableSettings.editActions) {
			const itemsLength = this.tableSettings.editActions.length;
			const base = 40;
			const dropHeight = base * itemsLength;
			if (this.dropOpen) {
				return `${dropHeight}px`;
			} else {
				return '0px';
			}
		} else {
			return '0px';
		}
	}
	get page() {
		return this._state.page;
	}

	set page(page: number) {
		this._set({ page });
		if (this.tableSettings.useServerSidePagingAndFiltering) this.pagingChanged.emit({ pageNumber: this.page, pageSize: this.pageSize });
		this.scrollUp();
	}

	get showing() {
		if (this.tableSettings.useServerSidePagingAndFiltering) return this.totalVisible;
		return this.pageSize > this.total$ ? this.total$ : this.pageSize;
	}

	get pageSize() {
		return this._state.pageSize;
	}

	set pageSize(pageSize: number) {
		this._set({ pageSize });
		if (this.tableSettings.useServerSidePagingAndFiltering) this.pagingChanged.emit({ pageNumber: this.page, pageSize: this.pageSize });
	}

	get searchTerm() {
		return this._state.searchTerm;
	}

	set searchTerm(searchTerm: string) {
		this._set({ searchTerm });
	}

	get selectedCountTitle() {
		return `${this.selectedCount} Selected${
			this.selectedMultiSelectType === MultiSelectType.All && this.tableItemsExcluded$.value?.length > 0
				? ' (' + this.tableItemsExcluded$.value?.length + ' Excluded)'
				: ''
		}`;
	}

	get sortColumn() {
		return this._state.sortColumn;
	}

	set sortColumn(sortColumn: string) {
		this._set({ sortColumn });
	}

	get sortDirection() {
		return this._state.sortDirection;
	}

	set sortDirection(sortDirection: SortDirection) {
		this._set({ sortDirection });
	}

	displaySearch() {
		this.searchTerm = '';
		this.search = !this.search;
	}

	getDropdownPlacement(rowIndex) {
		if (rowIndex + 1 > this.totalVisible - 2) return 'top-right';
		return 'bottom-right';
	}

	onSort({ column, direction }: SortEvent) {
		// resetting other headers
		this.headers.forEach((header) => {
			if (header.sortable !== column) header.direction = '';
		});
		this.sortColumn = column;
		this.sortDirection = direction;
		this.sortingChanged.emit({ column, direction });
	}

	matches(tableItem: any, term: string) {
		if (term && term !== '' && this.searchMap) {
			for (let i = 0; i < this.searchMap.length; i++) {
				if (tableItem[this.searchMap[i]]) {
					const tableItemKey = tableItem[this.searchMap[i]].toString();
					if (tableItemKey && tableItemKey.toLowerCase().includes(term.toLowerCase())) {
						return true;
					}
				}
			}
		} else return true;
	}

	getData(item) {
		this.getChildren.emit(item);
	}

	getChild(event, item, parent) {
		const data = {
			checked: event.target.checked,
			parent: parent,
			data: item,
		};
		this.childAction.emit(data);
	}

	toggleChildren(tableItem) {
		const expanded = tableItem.childData && tableItem.childData.length > 0 ? true : false;
		this.toggleRow.emit(expanded);
	}

	compare(v1, v2) {
		if (typeof v1 === 'string' && typeof v2 === 'string') {
			v1 = v1.toUpperCase();
			v2 = v2.toUpperCase();
		}

		return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
	}

	sort(tableItems: any[], column: string, direction: string): any[] {
		if (direction === '') {
			return tableItems;
		} else {
			return [...tableItems].sort((a, b) => {
				const res = this.compare(a[column] || '', b[column] || '');
				return direction === 'asc' ? res : -res;
			});
		}
	}

	onMultiSelectTypeChange(type: MultiSelectType) {
		this.selectedMultiSelectType = type;
		if (this.selectedMultiSelectType === MultiSelectType.All) {
			this.selectAll(true, false);
		} else if (this.selectedMultiSelectType === MultiSelectType.Page) {
			this.selectAll(true, this.tableSettings.useServerSidePagingAndFiltering !== true);
		} else if (this.selectedMultiSelectType === MultiSelectType.None) {
			this.selectAll(false, false);
		}
	}

	onCheckItem($event, item) {
		if (this.tableSettings.itemSelectMode === TableItemSelectMode.Multi) {
			item.selected = $event.target.checked;
			this.selectItem(item);
			if (this.selectedMultiSelectType === MultiSelectType.Page && this.pageSelected !== true) {
				this.selectedMultiSelectType = MultiSelectType.None;
			} else if (this.selectedMultiSelectType === MultiSelectType.None && this.pageSelected === true) {
				this.selectedMultiSelectType = MultiSelectType.Page;
			}
		} else if (this.tableSettings.itemSelectMode === TableItemSelectMode.Single) {
			this.tableItems.map((item) => (item.selected = false));
			item.selected = $event.target.checked;
			this._tableItemsSelected$.next(item.selected === true ? [item] : []);
			this._tableItemsExcluded$.next([]);
			this.checkAllSelected();
		}
		if (this.tableSettings.checkboxAction != null) {
			this.checkboxAction(item);
		}
	}

	selectAll(select: boolean, visibleOnly: boolean) {
		this.tableItems.map((item) => (item.selected = false));
		const getSelected = () => {
			const found = [...this.tableItems.filter((item) => visibleOnly !== true || item.visible === true)];
			found.map((item) => (item.selected = true));
			return [...this.tableItems.filter((item) => item.selected === true)];
		};
		this._tableItemsSelected$.next(select === true ? getSelected() : []);
		this._tableItemsExcluded$.next([]);
		this.checkAllSelected();
	}

	selectItem(item) {
		this.patchSelectedItems(item);
		this.checkAllSelected();
	}

	checkboxAction(tableItem) {
		if (this.tableSettings.checkboxAction != null) {
			let data = {
				checked: tableItem.selected,
				tableItem: tableItem,
			};
			this.tableSettings.checkboxAction(data);
		}
	}

	isDropOpen($event) {
		this.dropOpen = !this.dropOpen;
	}

	patchSelectedItems(patchItem) {
		const mustAddItem = patchItem.selected === true;
		const selectedItems = this.tableItemsSelected$.getValue() ?? [];
		const excludedItems = this.tableItemsExcluded$.getValue() ?? [];
		if (mustAddItem) {
			this.tableItemsSelected$.next([...selectedItems, patchItem]);
			let excluded = excludedItems.filter((item) => {
				return item[this.tableSettings.uniqueId] !== patchItem[this.tableSettings.uniqueId];
			});
			this.tableItemsExcluded$.next([...excluded]);
		} else {
			this.tableItemsExcluded$.next([...excludedItems, patchItem]);
			let selected = selectedItems.filter((item) => {
				return item[this.tableSettings.uniqueId] !== patchItem[this.tableSettings.uniqueId];
			});
			this.tableItemsSelected$.next([...selected]);
		}
	}

	checkAllSelected() {
		this.bulkSelection = false;
		this.pageSelected = false;
		this.allSelected = false;

		if (this.tableItemsSelected$.value?.length > 0) {
			this.bulkSelection = true;

			if (this.selectedMultiSelectType === MultiSelectType.All) {
				if (this.tableSettings.useServerSidePagingAndFiltering === true && this.tableItemsExcluded$.value?.length == 0) {
					this.allSelected = true;
				} else if (
					this.tableSettings.useServerSidePagingAndFiltering !== true &&
					(this.tableItems?.filter((item) => item.selected !== true)?.length ?? 0) == 0
				) {
					this.allSelected = true;
				}
			} else {
				if (this.tableItemsSelected$.value?.length === this.tableItems?.filter((item) => item.visible === true)?.length) {
					this.pageSelected = true;
				}
			}
		}

		this.selectedCount =
			this.selectedMultiSelectType === MultiSelectType.All
				? this.total$ - (this.tableItemsExcluded$?.value?.length || 0) || 0
				: this.tableItemsSelected$?.value?.length || 0;
	}

	handleCellClick(header, data, value?) {
		if (header.clickHandlerFunction) header.clickHandlerFunction(data, value);
	}

	getStyle(header) {
		if (header.width) {
			return JSON.parse(`{ "width": "${header.width}" }`);
		} else if (header.hide) {
			return JSON.parse(`{ "display": "none" }`);
		} else {
			return {};
		}
	}

	createPathFromArray(config) {
		const { pathArray, value } = config;
		return pathArray.map((item) => {
			if (this.utils.helpers.isPlainObject(item)) return value[item.primaryKey];
			return item;
		});
	}

	transformValue(value, header, tableItem) {
		let transformedValue = header.transform ? header.transform(value, tableItem) : value;
		if (transformedValue === 0) transformedValue = '0';
		this.logger.trace('transformValue', value, header, tableItem, transformedValue);
		if (transformedValue) return transformedValue;
		return '--';
	}

	editAction(tableItem) {
		if (this.tableSettings.editActions.constructor === Array) {
			return this.tableSettings.editActions;
		} else {
			return this.tableSettings.editActions(tableItem);
		}
	}

	_set(patch: Partial<TableState>) {
		if (patch.page != null || patch.pageSize != null) {
			this.retainSelectionOnChange = true;
		}
		Object.assign(this._state, patch);
		this._search$.next();
	}

	_search(): Observable<TableSearchResult> {
		const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
		// 1. sort
		let tableItems: any = this.sort(this.tableItems, sortColumn, sortDirection);
		// 2. filter
		if (this.tableSettings.useSearch) {
			tableItems = tableItems.filter((tableItem) => this.matches(tableItem, searchTerm));
		}
		const total = tableItems.length;
		tableItems.map((item) => (item.visible = false));
		// 3. paginate
		if (this.tableSettings.useServerSidePagingAndFiltering !== true) {
			tableItems = tableItems.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
		}
		// 4. select all
		tableItems.map((item) => {
			item.visible = true;
			const selected = this.tableItemsSelected$.value?.find((selected) => selected[this.tableSettings.uniqueId] === item[this.tableSettings.uniqueId]);
			if (this.selectedMultiSelectType === MultiSelectType.All) {
				const excluded = this.tableItemsExcluded$.value?.find(
					(selected) => selected[this.tableSettings.uniqueId] === item[this.tableSettings.uniqueId]
				);
				item.selected = excluded == null;
				if (selected == null) {
					this.tableItemsSelected$.next([...this.tableItemsSelected$.value, item]);
				}
			} else if (this.selectedMultiSelectType === MultiSelectType.Page || this.selectedMultiSelectType === MultiSelectType.None) {
				item.selected = false;
				if (this.tableItemsSelected$.value?.length > 0) {
					this.tableItemsSelected$.next([]);
				}
				this.selectedMultiSelectType === MultiSelectType.None;
			} else {
				item.selected = selected != null;
			}

			return item;
		});
		this.checkAllSelected();
		return of({ tableItems, total });
	}

	ngOnInit() {
		this.tableId = this.utils.helpers.getRandomString();
		this.allSelected = this.tableSettings.allSelected;
		if (this.tableSettings && this.tableItems) {
			const { useServerSidePagingAndFiltering } = this.tableSettings;
			this.tableItems.map((tableItem, index) => {
				return { ...tableItem, tableItemId: index };
			});
			this.searchMap = [
				...this.tableSettings.columnConfig.map((configItem) => {
					if (configItem.useForSearch) return configItem.primaryKey;
				}),
			];
			this._search$
				.pipe(
					tap(() => this._loading$.next(true)),
					switchMap(() => this._search()),
					tap(() => this._loading$.next(false))
				)
				.subscribe((result) => {
					this._tableItems$.next(result.tableItems);
					this._total$ = !useServerSidePagingAndFiltering ? result.total : this.tableSettings.serverSidePagingAndFilterConfig.totalCount;
				});
			this._search$.next();
		}
		this.loadingSubscription = this.loading$.subscribe((state) => {
			this.showLoader = state;
		});
		this.totalVisibleSubscription = this.tableItems$.subscribe((items) => {
			this._state.totalVisible = items.length;
		});
		this.tableItemsSelectedSubscription = this._tableItemsSelected$.subscribe((selected_items) => {
			this.selectedItems.emit(selected_items);
		});
		this.scrollUp();
	}

	ngOnChanges(changes) {
		// keep selection if paging, else clear
		if (this.retainSelectionOnChange === true) {
			this.retainSelectionOnChange = false;
		} else {
			this.selectedMultiSelectType = MultiSelectType.None;
			this.tableItemsSelected$.next([]);
			this.tableItemsExcluded$.next([]);
			this.bulkSelection = false;
			this.pageSelected = false;
			this.allSelected = false;
		}
		this._search$.next();
	}

	ngOnDestroy() {
		this.loadingSubscription.unsubscribe();
		this.tableItemsSelectedSubscription.unsubscribe();
	}

	preventRowToggle(event) {
		event.stopPropagation();
	}

	itemHasEnabledEditActions(tableItem) {
		if (this.tableSettings.editActions == null) return false;
		let editActions: any[];
		if (this.tableSettings.editActions == null) editActions = [];
		else if (this.tableSettings.editActions.constructor === Array) editActions = this.tableSettings.editActions;
		else editActions = this.tableSettings.editActions(tableItem);

		const enabledActions = editActions.filter((action) => action.enabled === undefined || action.enabled === null || action.enabled === true);
		return enabledActions && enabledActions.length > 0;
	}

	itemIsEnabled(action, tableItem) {
		return action?.enabled === undefined || action?.enabled === null || action?.enabled === true;
	}

	scrollUp(): void {
		if (this.tableSettings.disableScrollTop !== true) setTimeout(() => this.scrollTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' }));
	}
}
