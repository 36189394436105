import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { EnumService, LoginSettings, User, UserRole, UserStatus } from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent {
	@Input() isCurrentUser: boolean;
	@Input() isPrimaryUser: boolean;
	@Input() user?: User;
	@Input() isPublicMode: boolean;
	@Input() reset$?: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<User> = new EventEmitter<User>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	initialized: boolean = false;
	userForm: UntypedFormGroup;
	phoneRequired: boolean = false;
	userRoles: any[];
	selectedUserRoles: any[];
	userStatuses: any[];
	subscriptions: Subscription = new Subscription();
	canEditRoles: boolean = false;
	canEditStatus: boolean = false;
	ssoEnabled: boolean = false;
	ssoEnforced: boolean = false;

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly utils: UtilitiesService,
		private readonly authService: AuthService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly enumService: EnumService
	) {}

	ngOnInit() {
		this.tryInitialize();
	}

	tryInitialize() {
		if (this.initialized === true) return;

		this.userForm = this.formBuilder.group({
			firstName: [null, [Validators.required]],
			lastName: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
			status: [null, [Validators.required]],
			formRoles: [null],
			roles: [[]],
			phone: [null],
			department: [null],
			jobTitle: [null],
			address: [null],
			address2: [null],
			city: [null],
			stateProvince: [null],
			zipPostalCode: [null],
			countryRegion: [null],
		});

		this.userRoles = this.enumService.helpers.getUserRoles().map((ur) => {
			return { ...ur, available: true };
		});
		this.userStatuses = this.enumService.helpers.getUserStatuses();

		this.settingsService.api.getLoginSettings().subscribe((loginSettings: LoginSettings) => {
			this.ssoEnabled = loginSettings?.ssoEnabled ?? false;
			this.ssoEnforced = loginSettings?.ssoEnforced ?? false;
		});

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.userForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.userForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.userForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				const { formRoles, ...v } = value;
				this.changes.emit({ ...v });
			})
		);
		this.subscriptions.add(
			this.userForm
				.get('formRoles')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => {
					if (value?.some((v) => v.value == UserRole.Administrator) == true) {
						this.phoneRequired = true;
						this.userForm.get('phone').setValidators([Validators.required]);
						this.userForm.get('phone').updateValueAndValidity();
					} else {
						this.phoneRequired = false;
						this.userForm.get('phone').clearValidators();
						this.userForm.get('phone').updateValueAndValidity();
					}
				})
		);

		this.initialized = true;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.tryInitialize();
		let isCurrentUser: boolean = changes.isCurrentUser?.currentValue ?? this.isCurrentUser;
		let isPrimaryUser: boolean = changes.isPrimaryUser?.currentValue ?? this.isPrimaryUser;
		let user: User = changes.user?.currentValue ?? this.user;
		if (isPrimaryUser == true) this.userRoles = this.userRoles.filter((ur) => ur.value != UserRole.Installer);
		const adminRole = this.userRoles.find((ur) => ur.value == UserRole.Administrator);

		if (user) {
			const rolesFound =
				user.roles?.filter((r) => this.userRoles.find((ur) => ur.value == r) != null).map((r) => this.userRoles.find((ur) => ur.value == r)) ?? [];
			this.updateRolesFormControlValue(rolesFound, null);
			this.userForm.get('department').enable();
			this.userForm.get('jobTitle').enable();
			this.userForm.get('address').enable();
			this.userForm.get('address2').enable();
			this.userForm.get('city').enable();
			this.userForm.get('stateProvince').enable();
			this.userForm.get('zipPostalCode').enable();
			this.userForm.get('countryRegion').enable();
			this.userStatuses = this.enumService.helpers.getUserStatuses(user.status === UserStatus.Pending, user.status === UserStatus.Locked);
			if (this.isPublicMode === true) this.updateRolesFormControlValue([adminRole], null);
			if (isCurrentUser) {
				this.userForm.get('formRoles').disable();
				this.userForm.get('status').disable();
			} else {
				this.userForm.get('formRoles').enable();
				this.userForm.get('status').enable();
			}
			if (isPrimaryUser) {
				//this.updateRolesFormControlValue([adminRole], null);
				this.userForm.get('formRoles').enable();
				if (user.status === UserStatus.Pending) this.userForm.get('status').enable();
				else this.userForm.get('status').disable();
			}
			if (this.authService.helpers.userHasRole(UserRole.Administrator, user) == true) {
				this.phoneRequired = true;
				this.userForm.get('phone').setValidators([Validators.required]);
				this.userForm.get('phone').updateValueAndValidity();
			} else {
				this.phoneRequired = false;
				this.userForm.get('phone').clearValidators();
				this.userForm.get('phone').updateValueAndValidity();
			}
		} else {
			this.userForm.get('status').setValue(UserStatus.Pending);
			this.userForm.get('status').disable();
			this.userStatuses = this.enumService.helpers.getUserStatuses(true);
			if (isPrimaryUser) {
				this.updateRolesFormControlValue([adminRole], null);
				// this.userForm.get('formRoles').disable();
				this.userForm.get('department').disable();
				this.userForm.get('jobTitle').disable();
				this.userForm.get('address').disable();
				this.userForm.get('address2').disable();
				this.userForm.get('city').disable();
				this.userForm.get('stateProvince').disable();
				this.userForm.get('zipPostalCode').disable();
				this.userForm.get('countryRegion').disable();
				this.phoneRequired = true;
				this.userForm.get('phone').setValidators([Validators.required]);
				this.userForm.get('phone').updateValueAndValidity();
			} else {
				this.userForm.get('formRoles').setValue(null);
				this.userForm.get('formRoles').enable();
				this.userForm.get('department').enable();
				this.userForm.get('jobTitle').enable();
				this.userForm.get('address').enable();
				this.userForm.get('address2').enable();
				this.userForm.get('city').enable();
				this.userForm.get('stateProvince').enable();
				this.userForm.get('zipPostalCode').enable();
				this.userForm.get('countryRegion').enable();
				this.phoneRequired = false;
				this.userForm.get('phone').clearValidators();
				this.userForm.get('phone').updateValueAndValidity();
			}
		}
		if (changes.user && changes.user.currentValue) this.userForm.patchValue(changes.user.currentValue);

		this.canEditRoles =
			this.isPublicMode !== true && ((isCurrentUser == true && isPrimaryUser == true) || (isCurrentUser != true && isPrimaryUser != true));
		this.canEditStatus = this.isPublicMode !== true && isCurrentUser != true && isPrimaryUser != true && user != null;
	}

	submitForm() {
		if (this.userForm.valid) {
			this.submit.emit();
		}
	}

	onUserRolesChanged(controlValue) {
		const added = controlValue?.filter((cv) => this.selectedUserRoles?.some((sr) => sr.value == cv.value) != true);
		const removed = this.selectedUserRoles?.filter((cv) => controlValue?.some((sr) => sr.value == cv.value) != true);
		this.updateRolesFormControlValue(added, removed);
	}

	clearUserRole(clearedRole: any) {
		this.updateRolesFormControlValue(null, [clearedRole]);
	}

	updateRolesFormControlValue(addedRoles: any[], removedRoles: any[]) {
		var modifiedRoles = this.selectedUserRoles?.length > 0 ? [...this.selectedUserRoles] : [];
		if (addedRoles?.length > 0) {
			if (addedRoles.find((ar) => ar.value == UserRole.Installer) != null) {
				modifiedRoles = this.userRoles.filter((r) => r.value == UserRole.Installer);
				this.userRoles.map((ur) => (ur.available = ur.value == UserRole.Installer));
			} else {
				modifiedRoles = modifiedRoles.filter((r) => r.value != UserRole.Installer) ?? [];
				modifiedRoles = [...modifiedRoles, ...addedRoles];
				this.userRoles.map((ur) => (ur.available = ur.value != UserRole.Installer));
			}
		}

		if (removedRoles?.length > 0) {
			if (removedRoles.find((ar) => ar.value == UserRole.Installer) != null) {
				modifiedRoles = [];
				this.userRoles.map((ur) => (ur.available = true));
			} else {
				modifiedRoles = modifiedRoles.filter((r) => removedRoles.some((rr) => rr.value == r.value) != true) ?? [];
				this.userRoles.map((ur) => (ur.available = modifiedRoles.length > 0 ? ur.value != UserRole.Installer : true));
			}
		}

		if (this.isPrimaryUser == true) {
			if (modifiedRoles.find((ar) => ar.value == UserRole.Administrator) == null)
				modifiedRoles = [...modifiedRoles, this.userRoles.find((r) => r.value == UserRole.Administrator)];
			if (modifiedRoles.find((ar) => ar.value == UserRole.Installer) != null)
				modifiedRoles = modifiedRoles.filter((mr) => mr.value != UserRole.Installer);
		}

		this.selectedUserRoles = this.userRoles.filter((ur) => modifiedRoles.some((r) => r.value == ur.value) == true) ?? [];
		this.userForm.controls.formRoles.setValue(this.selectedUserRoles);
		this.userForm.controls.roles.setValue(this.selectedUserRoles.map((sr) => sr.value));
	}
}
