import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DeviceEditFormData } from '../../../data-types/device-edit-form-data.model';
import { Account, Tag, Location } from '@razberi-ui/core/data-types';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-device-edit-form',
	templateUrl: './device-edit-form.component.html',
	styleUrls: ['./device-edit-form.component.scss'],
})
export class DeviceEditFormComponent {
	@Input() userAccountId: number;
	@Input() canEditAccount: boolean;
	@Input() deviceEditData?: DeviceEditFormData;
	@Input() accounts: Account[];
	@Input() locations: Location[];
	@Input() tags: Tag[];
	@Input() reset$?: Observable<void>;
	@Output() accountChange: EventEmitter<number> = new EventEmitter<number>();
	@Output() newLocation: EventEmitter<void> = new EventEmitter<void>();
	@Output() newTag: EventEmitter<void> = new EventEmitter<void>();
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<DeviceEditFormData> = new EventEmitter<DeviceEditFormData>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	deviceForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private readonly fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.buildForm();
		if (this.userAccountId) this.deviceForm.get('accountId').setValue(this.userAccountId);
		if (!this.canEditAccount) this.deviceForm.get('accountId').disable();
		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.deviceForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.deviceForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.deviceForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		this.subscriptions.add(
			this.deviceForm
				.get('accountId')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => {
					if (this.deviceForm.get('locationId').value) this.deviceForm.get('locationId').setValue(null);
					if (this.deviceForm.get('tagIds').value) this.deviceForm.get('tagIds').setValue(null);
					this.accountChange.emit(value);
				})
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.buildForm();
		const deviceEditData: DeviceEditFormData = changes.deviceEditData?.currentValue ?? this.deviceEditData;
		if (deviceEditData != null) {
			this.deviceForm.get('serialNumber').disable();
			this.deviceForm.get('modelName').disable();
		} else {
			this.deviceForm.get('serialNumber').enable();
			this.deviceForm.get('modelName').enable();
		}
		if (changes.deviceEditData && changes.deviceEditData.currentValue) this.deviceForm.patchValue(changes.deviceEditData.currentValue);
	}

	buildForm() {
		if (this.deviceForm != null) {
			return;
		}

		this.deviceForm = this.fb.group({
			serialNumber: [null, [Validators.required]],
			modelName: [null, [Validators.required]],
			name: [null],
			accountId: [null, [Validators.required]],
			locationId: [null],
			tagIds: [null],
		});
	}

	addNewLocation() {
		this.newLocation.emit();
	}

	addNewTag() {
		this.newTag.emit();
	}

	submitForm() {
		if (this.deviceForm.valid) this.submit.emit();
	}
}
