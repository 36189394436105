<div class="context-message message-{{ type }} d-flex align-items-center">
	<div class="me-2 align-self-start">
		<fa-icon [icon]="icon(type)"></fa-icon>
	</div>
	<div class="context-message-container">
		<h6 *ngIf="title != null" class="context-message-title" [ngClass]="{ 'mb-1': content.childNodes?.length > 1 }">
			{{ title }}
		</h6>
		<div #content><ng-content></ng-content></div>
	</div>
</div>
