export const constants = {
	usStates: [
		{ name: 'ALABAMA', abbreviation: 'AL' },
		{ name: 'ALASKA', abbreviation: 'AK' },
		{ name: 'AMERICAN SAMOA', abbreviation: 'AS' },
		{ name: 'ARIZONA', abbreviation: 'AZ' },
		{ name: 'ARKANSAS', abbreviation: 'AR' },
		{ name: 'CALIFORNIA', abbreviation: 'CA' },
		{ name: 'COLORADO', abbreviation: 'CO' },
		{ name: 'CONNECTICUT', abbreviation: 'CT' },
		{ name: 'DELAWARE', abbreviation: 'DE' },
		{ name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
		{ name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
		{ name: 'FLORIDA', abbreviation: 'FL' },
		{ name: 'GEORGIA', abbreviation: 'GA' },
		{ name: 'GUAM', abbreviation: 'GU' },
		{ name: 'HAWAII', abbreviation: 'HI' },
		{ name: 'IDAHO', abbreviation: 'ID' },
		{ name: 'ILLINOIS', abbreviation: 'IL' },
		{ name: 'INDIANA', abbreviation: 'IN' },
		{ name: 'IOWA', abbreviation: 'IA' },
		{ name: 'KANSAS', abbreviation: 'KS' },
		{ name: 'KENTUCKY', abbreviation: 'KY' },
		{ name: 'LOUISIANA', abbreviation: 'LA' },
		{ name: 'MAINE', abbreviation: 'ME' },
		{ name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
		{ name: 'MARYLAND', abbreviation: 'MD' },
		{ name: 'MASSACHUSETTS', abbreviation: 'MA' },
		{ name: 'MICHIGAN', abbreviation: 'MI' },
		{ name: 'MINNESOTA', abbreviation: 'MN' },
		{ name: 'MISSISSIPPI', abbreviation: 'MS' },
		{ name: 'MISSOURI', abbreviation: 'MO' },
		{ name: 'MONTANA', abbreviation: 'MT' },
		{ name: 'NEBRASKA', abbreviation: 'NE' },
		{ name: 'NEVADA', abbreviation: 'NV' },
		{ name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
		{ name: 'NEW JERSEY', abbreviation: 'NJ' },
		{ name: 'NEW MEXICO', abbreviation: 'NM' },
		{ name: 'NEW YORK', abbreviation: 'NY' },
		{ name: 'NORTH CAROLINA', abbreviation: 'NC' },
		{ name: 'NORTH DAKOTA', abbreviation: 'ND' },
		{ name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
		{ name: 'OHIO', abbreviation: 'OH' },
		{ name: 'OKLAHOMA', abbreviation: 'OK' },
		{ name: 'OREGON', abbreviation: 'OR' },
		{ name: 'PALAU', abbreviation: 'PW' },
		{ name: 'PENNSYLVANIA', abbreviation: 'PA' },
		{ name: 'PUERTO RICO', abbreviation: 'PR' },
		{ name: 'RHODE ISLAND', abbreviation: 'RI' },
		{ name: 'SOUTH CAROLINA', abbreviation: 'SC' },
		{ name: 'SOUTH DAKOTA', abbreviation: 'SD' },
		{ name: 'TENNESSEE', abbreviation: 'TN' },
		{ name: 'TEXAS', abbreviation: 'TX' },
		{ name: 'UTAH', abbreviation: 'UT' },
		{ name: 'VERMONT', abbreviation: 'VT' },
		{ name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
		{ name: 'VIRGINIA', abbreviation: 'VA' },
		{ name: 'WASHINGTON', abbreviation: 'WA' },
		{ name: 'WEST VIRGINIA', abbreviation: 'WV' },
		{ name: 'WISCONSIN', abbreviation: 'WI' },
		{ name: 'WYOMING', abbreviation: 'WY' },
	],
	countries: [
		// https://gist.github.com/incredimike/1469814
		// All simple ISO 3166 country data
		// including: 2-character, 3-character, full country name and numeric code.
		// Sorted alphabetical by country name (special characters on bottom)
		{ code: 'AF', code3: 'AFG', name: 'Afghanistan', number: '004' },
		{ code: 'AL', code3: 'ALB', name: 'Albania', number: '008' },
		{ code: 'DZ', code3: 'DZA', name: 'Algeria', number: '012' },
		{ code: 'AS', code3: 'ASM', name: 'American Samoa', number: '016' },
		{ code: 'AD', code3: 'AND', name: 'Andorra', number: '020' },
		{ code: 'AO', code3: 'AGO', name: 'Angola', number: '024' },
		{ code: 'AI', code3: 'AIA', name: 'Anguilla', number: '660' },
		{ code: 'AQ', code3: 'ATA', name: 'Antarctica', number: '010' },
		{ code: 'AG', code3: 'ATG', name: 'Antigua and Barbuda', number: '028' },
		{ code: 'AR', code3: 'ARG', name: 'Argentina', number: '032' },
		{ code: 'AM', code3: 'ARM', name: 'Armenia', number: '051' },
		{ code: 'AW', code3: 'ABW', name: 'Aruba', number: '533' },
		{ code: 'AU', code3: 'AUS', name: 'Australia', number: '036' },
		{ code: 'AT', code3: 'AUT', name: 'Austria', number: '040' },
		{ code: 'AZ', code3: 'AZE', name: 'Azerbaijan', number: '031' },
		{ code: 'BS', code3: 'BHS', name: 'Bahamas', number: '044' },
		{ code: 'BH', code3: 'BHR', name: 'Bahrain', number: '048' },
		{ code: 'BD', code3: 'BGD', name: 'Bangladesh', number: '050' },
		{ code: 'BB', code3: 'BRB', name: 'Barbados', number: '052' },
		{ code: 'BY', code3: 'BLR', name: 'Belarus', number: '112' },
		{ code: 'BE', code3: 'BEL', name: 'Belgium', number: '056' },
		{ code: 'BZ', code3: 'BLZ', name: 'Belize', number: '084' },
		{ code: 'BJ', code3: 'BEN', name: 'Benin', number: '204' },
		{ code: 'BM', code3: 'BMU', name: 'Bermuda', number: '060' },
		{ code: 'BT', code3: 'BTN', name: 'Bhutan', number: '064' },
		{ code: 'BO', code3: 'BOL', name: 'Bolivia (Plurinational State of)', number: '068' },
		{ code: 'BQ', code3: 'BES', name: 'Bonaire, Sint Eustatius and Saba', number: '535' },
		{ code: 'BA', code3: 'BIH', name: 'Bosnia and Herzegovina', number: '070' },
		{ code: 'BW', code3: 'BWA', name: 'Botswana', number: '072' },
		{ code: 'BV', code3: 'BVT', name: 'Bouvet Island', number: '074' },
		{ code: 'BR', code3: 'BRA', name: 'Brazil', number: '076' },
		{ code: 'IO', code3: 'IOT', name: 'British Indian Ocean Territory', number: '086' },
		{ code: 'BN', code3: 'BRN', name: 'Brunei Darussalam', number: '096' },
		{ code: 'BG', code3: 'BGR', name: 'Bulgaria', number: '100' },
		{ code: 'BF', code3: 'BFA', name: 'Burkina Faso', number: '854' },
		{ code: 'BI', code3: 'BDI', name: 'Burundi', number: '108' },
		{ code: 'CV', code3: 'CPV', name: 'Cabo Verde', number: '132' },
		{ code: 'KH', code3: 'KHM', name: 'Cambodia', number: '116' },
		{ code: 'CM', code3: 'CMR', name: 'Cameroon', number: '120' },
		{ code: 'CA', code3: 'CAN', name: 'Canada', number: '124' },
		{ code: 'KY', code3: 'CYM', name: 'Cayman Islands', number: '136' },
		{ code: 'CF', code3: 'CAF', name: 'Central African Republic', number: '140' },
		{ code: 'TD', code3: 'TCD', name: 'Chad', number: '148' },
		{ code: 'CL', code3: 'CHL', name: 'Chile', number: '152' },
		{ code: 'CN', code3: 'CHN', name: 'China', number: '156' },
		{ code: 'CX', code3: 'CXR', name: 'Christmas Island', number: '162' },
		{ code: 'CC', code3: 'CCK', name: 'Cocos (Keeling) Islands', number: '166' },
		{ code: 'CO', code3: 'COL', name: 'Colombia', number: '170' },
		{ code: 'KM', code3: 'COM', name: 'Comoros', number: '174' },
		{ code: 'CD', code3: 'COD', name: 'Congo (the Democratic Republic of the)', number: '180' },
		{ code: 'CG', code3: 'COG', name: 'Congo', number: '178' },
		{ code: 'CK', code3: 'COK', name: 'Cook Islands', number: '184' },
		{ code: 'CR', code3: 'CRI', name: 'Costa Rica', number: '188' },
		{ code: 'HR', code3: 'HRV', name: 'Croatia', number: '191' },
		{ code: 'CU', code3: 'CUB', name: 'Cuba', number: '192' },
		{ code: 'CW', code3: 'CUW', name: 'Curaçao', number: '531' },
		{ code: 'CY', code3: 'CYP', name: 'Cyprus', number: '196' },
		{ code: 'CZ', code3: 'CZE', name: 'Czechia', number: '203' },
		{ code: 'CI', code3: 'CIV', name: "Côte d'Ivoire", number: '384' },
		{ code: 'DK', code3: 'DNK', name: 'Denmark', number: '208' },
		{ code: 'DJ', code3: 'DJI', name: 'Djibouti', number: '262' },
		{ code: 'DM', code3: 'DMA', name: 'Dominica', number: '212' },
		{ code: 'DO', code3: 'DOM', name: 'Dominican Republic', number: '214' },
		{ code: 'EC', code3: 'ECU', name: 'Ecuador', number: '218' },
		{ code: 'EG', code3: 'EGY', name: 'Egypt', number: '818' },
		{ code: 'SV', code3: 'SLV', name: 'El Salvador', number: '222' },
		{ code: 'GQ', code3: 'GNQ', name: 'Equatorial Guinea', number: '226' },
		{ code: 'ER', code3: 'ERI', name: 'Eritrea', number: '232' },
		{ code: 'EE', code3: 'EST', name: 'Estonia', number: '233' },
		{ code: 'SZ', code3: 'SWZ', name: 'Eswatini', number: '748' },
		{ code: 'ET', code3: 'ETH', name: 'Ethiopia', number: '231' },
		{ code: 'FK', code3: 'FLK', name: 'Falkland Islands [Malvinas]', number: '238' },
		{ code: 'FO', code3: 'FRO', name: 'Faroe Islands', number: '234' },
		{ code: 'FJ', code3: 'FJI', name: 'Fiji', number: '242' },
		{ code: 'FI', code3: 'FIN', name: 'Finland', number: '246' },
		{ code: 'FR', code3: 'FRA', name: 'France', number: '250' },
		{ code: 'GF', code3: 'GUF', name: 'French Guiana', number: '254' },
		{ code: 'PF', code3: 'PYF', name: 'French Polynesia', number: '258' },
		{ code: 'TF', code3: 'ATF', name: 'French Southern Territories', number: '260' },
		{ code: 'GA', code3: 'GAB', name: 'Gabon', number: '266' },
		{ code: 'GM', code3: 'GMB', name: 'Gambia', number: '270' },
		{ code: 'GE', code3: 'GEO', name: 'Georgia', number: '268' },
		{ code: 'DE', code3: 'DEU', name: 'Germany', number: '276' },
		{ code: 'GH', code3: 'GHA', name: 'Ghana', number: '288' },
		{ code: 'GI', code3: 'GIB', name: 'Gibraltar', number: '292' },
		{ code: 'GR', code3: 'GRC', name: 'Greece', number: '300' },
		{ code: 'GL', code3: 'GRL', name: 'Greenland', number: '304' },
		{ code: 'GD', code3: 'GRD', name: 'Grenada', number: '308' },
		{ code: 'GP', code3: 'GLP', name: 'Guadeloupe', number: '312' },
		{ code: 'GU', code3: 'GUM', name: 'Guam', number: '316' },
		{ code: 'GT', code3: 'GTM', name: 'Guatemala', number: '320' },
		{ code: 'GG', code3: 'GGY', name: 'Guernsey', number: '831' },
		{ code: 'GN', code3: 'GIN', name: 'Guinea', number: '324' },
		{ code: 'GW', code3: 'GNB', name: 'Guinea-Bissau', number: '624' },
		{ code: 'GY', code3: 'GUY', name: 'Guyana', number: '328' },
		{ code: 'HT', code3: 'HTI', name: 'Haiti', number: '332' },
		{ code: 'HM', code3: 'HMD', name: 'Heard Island and McDonald Islands', number: '334' },
		{ code: 'VA', code3: 'VAT', name: 'Holy See', number: '336' },
		{ code: 'HN', code3: 'HND', name: 'Honduras', number: '340' },
		{ code: 'HK', code3: 'HKG', name: 'Hong Kong', number: '344' },
		{ code: 'HU', code3: 'HUN', name: 'Hungary', number: '348' },
		{ code: 'IS', code3: 'ISL', name: 'Iceland', number: '352' },
		{ code: 'IN', code3: 'IND', name: 'India', number: '356' },
		{ code: 'ID', code3: 'IDN', name: 'Indonesia', number: '360' },
		{ code: 'IR', code3: 'IRN', name: 'Iran (Islamic Republic of)', number: '364' },
		{ code: 'IQ', code3: 'IRQ', name: 'Iraq', number: '368' },
		{ code: 'IE', code3: 'IRL', name: 'Ireland', number: '372' },
		{ code: 'IM', code3: 'IMN', name: 'Isle of Man', number: '833' },
		{ code: 'IL', code3: 'ISR', name: 'Israel', number: '376' },
		{ code: 'IT', code3: 'ITA', name: 'Italy', number: '380' },
		{ code: 'JM', code3: 'JAM', name: 'Jamaica', number: '388' },
		{ code: 'JP', code3: 'JPN', name: 'Japan', number: '392' },
		{ code: 'JE', code3: 'JEY', name: 'Jersey', number: '832' },
		{ code: 'JO', code3: 'JOR', name: 'Jordan', number: '400' },
		{ code: 'KZ', code3: 'KAZ', name: 'Kazakhstan', number: '398' },
		{ code: 'KE', code3: 'KEN', name: 'Kenya', number: '404' },
		{ code: 'KI', code3: 'KIR', name: 'Kiribati', number: '296' },
		{ code: 'KP', code3: 'PRK', name: "Korea (the Democratic People's Republic of)", number: '408' },
		{ code: 'KR', code3: 'KOR', name: 'Korea (the Republic of)', number: '410' },
		{ code: 'KW', code3: 'KWT', name: 'Kuwait', number: '414' },
		{ code: 'KG', code3: 'KGZ', name: 'Kyrgyzstan', number: '417' },
		{ code: 'LA', code3: 'LAO', name: "Lao People's Democratic Republic", number: '418' },
		{ code: 'LV', code3: 'LVA', name: 'Latvia', number: '428' },
		{ code: 'LB', code3: 'LBN', name: 'Lebanon', number: '422' },
		{ code: 'LS', code3: 'LSO', name: 'Lesotho', number: '426' },
		{ code: 'LR', code3: 'LBR', name: 'Liberia', number: '430' },
		{ code: 'LY', code3: 'LBY', name: 'Libya', number: '434' },
		{ code: 'LI', code3: 'LIE', name: 'Liechtenstein', number: '438' },
		{ code: 'LT', code3: 'LTU', name: 'Lithuania', number: '440' },
		{ code: 'LU', code3: 'LUX', name: 'Luxembourg', number: '442' },
		{ code: 'MO', code3: 'MAC', name: 'Macao', number: '446' },
		{ code: 'MG', code3: 'MDG', name: 'Madagascar', number: '450' },
		{ code: 'MW', code3: 'MWI', name: 'Malawi', number: '454' },
		{ code: 'MY', code3: 'MYS', name: 'Malaysia', number: '458' },
		{ code: 'MV', code3: 'MDV', name: 'Maldives', number: '462' },
		{ code: 'ML', code3: 'MLI', name: 'Mali', number: '466' },
		{ code: 'MT', code3: 'MLT', name: 'Malta', number: '470' },
		{ code: 'MH', code3: 'MHL', name: 'Marshall Islands', number: '584' },
		{ code: 'MQ', code3: 'MTQ', name: 'Martinique', number: '474' },
		{ code: 'MR', code3: 'MRT', name: 'Mauritania', number: '478' },
		{ code: 'MU', code3: 'MUS', name: 'Mauritius', number: '480' },
		{ code: 'YT', code3: 'MYT', name: 'Mayotte', number: '175' },
		{ code: 'MX', code3: 'MEX', name: 'Mexico', number: '484' },
		{ code: 'FM', code3: 'FSM', name: 'Micronesia (Federated States of)', number: '583' },
		{ code: 'MD', code3: 'MDA', name: 'Moldova (the Republic of)', number: '498' },
		{ code: 'MC', code3: 'MCO', name: 'Monaco', number: '492' },
		{ code: 'MN', code3: 'MNG', name: 'Mongolia', number: '496' },
		{ code: 'ME', code3: 'MNE', name: 'Montenegro', number: '499' },
		{ code: 'MS', code3: 'MSR', name: 'Montserrat', number: '500' },
		{ code: 'MA', code3: 'MAR', name: 'Morocco', number: '504' },
		{ code: 'MZ', code3: 'MOZ', name: 'Mozambique', number: '508' },
		{ code: 'MM', code3: 'MMR', name: 'Myanmar', number: '104' },
		{ code: 'NA', code3: 'NAM', name: 'Namibia', number: '516' },
		{ code: 'NR', code3: 'NRU', name: 'Nauru', number: '520' },
		{ code: 'NP', code3: 'NPL', name: 'Nepal', number: '524' },
		{ code: 'NL', code3: 'NLD', name: 'Netherlands', number: '528' },
		{ code: 'NC', code3: 'NCL', name: 'New Caledonia', number: '540' },
		{ code: 'NZ', code3: 'NZL', name: 'New Zealand', number: '554' },
		{ code: 'NI', code3: 'NIC', name: 'Nicaragua', number: '558' },
		{ code: 'NE', code3: 'NER', name: 'Niger', number: '562' },
		{ code: 'NG', code3: 'NGA', name: 'Nigeria', number: '566' },
		{ code: 'NU', code3: 'NIU', name: 'Niue', number: '570' },
		{ code: 'NF', code3: 'NFK', name: 'Norfolk Island', number: '574' },
		{ code: 'MP', code3: 'MNP', name: 'Northern Mariana Islands', number: '580' },
		{ code: 'NO', code3: 'NOR', name: 'Norway', number: '578' },
		{ code: 'OM', code3: 'OMN', name: 'Oman', number: '512' },
		{ code: 'PK', code3: 'PAK', name: 'Pakistan', number: '586' },
		{ code: 'PW', code3: 'PLW', name: 'Palau', number: '585' },
		{ code: 'PS', code3: 'PSE', name: 'Palestine, State of', number: '275' },
		{ code: 'PA', code3: 'PAN', name: 'Panama', number: '591' },
		{ code: 'PG', code3: 'PNG', name: 'Papua New Guinea', number: '598' },
		{ code: 'PY', code3: 'PRY', name: 'Paraguay', number: '600' },
		{ code: 'PE', code3: 'PER', name: 'Peru', number: '604' },
		{ code: 'PH', code3: 'PHL', name: 'Philippines', number: '608' },
		{ code: 'PN', code3: 'PCN', name: 'Pitcairn', number: '612' },
		{ code: 'PL', code3: 'POL', name: 'Poland', number: '616' },
		{ code: 'PT', code3: 'PRT', name: 'Portugal', number: '620' },
		{ code: 'PR', code3: 'PRI', name: 'Puerto Rico', number: '630' },
		{ code: 'QA', code3: 'QAT', name: 'Qatar', number: '634' },
		{ code: 'MK', code3: 'MKD', name: 'Republic of North Macedonia', number: '807' },
		{ code: 'RO', code3: 'ROU', name: 'Romania', number: '642' },
		{ code: 'RU', code3: 'RUS', name: 'Russian Federation', number: '643' },
		{ code: 'RW', code3: 'RWA', name: 'Rwanda', number: '646' },
		{ code: 'RE', code3: 'REU', name: 'Réunion', number: '638' },
		{ code: 'BL', code3: 'BLM', name: 'Saint Barthélemy', number: '652' },
		{ code: 'SH', code3: 'SHN', name: 'Saint Helena, Ascension and Tristan da Cunha', number: '654' },
		{ code: 'KN', code3: 'KNA', name: 'Saint Kitts and Nevis', number: '659' },
		{ code: 'LC', code3: 'LCA', name: 'Saint Lucia', number: '662' },
		{ code: 'MF', code3: 'MAF', name: 'Saint Martin (French part)', number: '663' },
		{ code: 'PM', code3: 'SPM', name: 'Saint Pierre and Miquelon', number: '666' },
		{ code: 'VC', code3: 'VCT', name: 'Saint Vincent and the Grenadines', number: '670' },
		{ code: 'WS', code3: 'WSM', name: 'Samoa', number: '882' },
		{ code: 'SM', code3: 'SMR', name: 'San Marino', number: '674' },
		{ code: 'ST', code3: 'STP', name: 'Sao Tome and Principe', number: '678' },
		{ code: 'SA', code3: 'SAU', name: 'Saudi Arabia', number: '682' },
		{ code: 'SN', code3: 'SEN', name: 'Senegal', number: '686' },
		{ code: 'RS', code3: 'SRB', name: 'Serbia', number: '688' },
		{ code: 'SC', code3: 'SYC', name: 'Seychelles', number: '690' },
		{ code: 'SL', code3: 'SLE', name: 'Sierra Leone', number: '694' },
		{ code: 'SG', code3: 'SGP', name: 'Singapore', number: '702' },
		{ code: 'SX', code3: 'SXM', name: 'Sint Maarten (Dutch part)', number: '534' },
		{ code: 'SK', code3: 'SVK', name: 'Slovakia', number: '703' },
		{ code: 'SI', code3: 'SVN', name: 'Slovenia', number: '705' },
		{ code: 'SB', code3: 'SLB', name: 'Solomon Islands', number: '090' },
		{ code: 'SO', code3: 'SOM', name: 'Somalia', number: '706' },
		{ code: 'ZA', code3: 'ZAF', name: 'South Africa', number: '710' },
		{ code: 'GS', code3: 'SGS', name: 'South Georgia and the South Sandwich Islands', number: '239' },
		{ code: 'SS', code3: 'SSD', name: 'South Sudan', number: '728' },
		{ code: 'ES', code3: 'ESP', name: 'Spain', number: '724' },
		{ code: 'LK', code3: 'LKA', name: 'Sri Lanka', number: '144' },
		{ code: 'SD', code3: 'SDN', name: 'Sudan', number: '729' },
		{ code: 'SR', code3: 'SUR', name: 'Suriname', number: '740' },
		{ code: 'SJ', code3: 'SJM', name: 'Svalbard and Jan Mayen', number: '744' },
		{ code: 'SE', code3: 'SWE', name: 'Sweden', number: '752' },
		{ code: 'CH', code3: 'CHE', name: 'Switzerland', number: '756' },
		{ code: 'SY', code3: 'SYR', name: 'Syrian Arab Republic', number: '760' },
		{ code: 'TW', code3: 'TWN', name: 'Taiwan', number: '158' },
		{ code: 'TJ', code3: 'TJK', name: 'Tajikistan', number: '762' },
		{ code: 'TZ', code3: 'TZA', name: 'Tanzania, United Republic of', number: '834' },
		{ code: 'TH', code3: 'THA', name: 'Thailand', number: '764' },
		{ code: 'TL', code3: 'TLS', name: 'Timor-Leste', number: '626' },
		{ code: 'TG', code3: 'TGO', name: 'Togo', number: '768' },
		{ code: 'TK', code3: 'TKL', name: 'Tokelau', number: '772' },
		{ code: 'TO', code3: 'TON', name: 'Tonga', number: '776' },
		{ code: 'TT', code3: 'TTO', name: 'Trinidad and Tobago', number: '780' },
		{ code: 'TN', code3: 'TUN', name: 'Tunisia', number: '788' },
		{ code: 'TR', code3: 'TUR', name: 'Turkey', number: '792' },
		{ code: 'TM', code3: 'TKM', name: 'Turkmenistan', number: '795' },
		{ code: 'TC', code3: 'TCA', name: 'Turks and Caicos Islands', number: '796' },
		{ code: 'TV', code3: 'TUV', name: 'Tuvalu', number: '798' },
		{ code: 'UG', code3: 'UGA', name: 'Uganda', number: '800' },
		{ code: 'UA', code3: 'UKR', name: 'Ukraine', number: '804' },
		{ code: 'AE', code3: 'ARE', name: 'United Arab Emirates', number: '784' },
		{ code: 'GB', code3: 'GBR', name: 'United Kingdom of Great Britain and Northern Ireland', number: '826' },
		{ code: 'UM', code3: 'UMI', name: 'United States Minor Outlying Islands', number: '581' },
		{ code: 'US', code3: 'USA', name: 'United States of America', number: '840' },
		{ code: 'UY', code3: 'URY', name: 'Uruguay', number: '858' },
		{ code: 'UZ', code3: 'UZB', name: 'Uzbekistan', number: '860' },
		{ code: 'VU', code3: 'VUT', name: 'Vanuatu', number: '548' },
		{ code: 'VE', code3: 'VEN', name: 'Venezuela (Bolivarian Republic of)', number: '862' },
		{ code: 'VN', code3: 'VNM', name: 'Viet Nam', number: '704' },
		{ code: 'VG', code3: 'VGB', name: 'Virgin Islands (British)', number: '092' },
		{ code: 'VI', code3: 'VIR', name: 'Virgin Islands (U.S.)', number: '850' },
		{ code: 'WF', code3: 'WLF', name: 'Wallis and Futuna', number: '876' },
		{ code: 'EH', code3: 'ESH', name: 'Western Sahara', number: '732' },
		{ code: 'YE', code3: 'YEM', name: 'Yemen', number: '887' },
		{ code: 'ZM', code3: 'ZMB', name: 'Zambia', number: '894' },
		{ code: 'ZW', code3: 'ZWE', name: 'Zimbabwe', number: '716' },
		{ code: 'AX', code3: 'ALA', name: 'Åland Islands', number: '248' },
	],
	timeZonesSimple: [
		{
			value: -12,
			text: '(GMT -12:00) Eniwetok, Kwajalein',
		},
		{
			value: -11,
			text: '(GMT -11:00) Midway Island, Samoa',
		},
		{
			value: -10,
			text: '(GMT -10:00) Hawaii',
		},
		{
			value: -9,
			text: '(GMT -9:00) Alaska',
		},
		{
			value: -8,
			text: '(GMT -8:00) Pacific Time (US & Canada)',
		},
		{
			value: -7,
			text: '(GMT -7:00) Mountain Time (US & Canada)',
		},
		{
			value: -6,
			text: '(GMT -6:00) Central Time (US & Canada), Mexico City',
		},
		{
			value: -5,
			text: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
		},
		{
			value: -4,
			text: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
		},
		{
			value: -3.5,
			text: '(GMT -3:30) Newfoundland',
		},
		{
			value: -3,
			text: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
		},
		{
			value: -2,
			text: '(GMT -2:00) Mid-Atlantic',
		},
		{
			value: -1,
			text: '(GMT -1:00) Azores, Cape Verde Islands',
		},
		{
			value: 0,
			text: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
		},
		{
			value: 1,
			text: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
		},
		{
			value: 2,
			text: '(GMT +2:00) Kaliningrad, South Africa',
		},
		{
			value: 3,
			text: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
		},
		{
			value: 3.5,
			text: '(GMT +3:30) Tehran',
		},
		{
			value: 4,
			text: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
		},
		{
			value: 4.5,
			text: '(GMT +4:30) Kabul',
		},
		{
			value: 5,
			text: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
		},
		{
			value: 5.5,
			text: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
		},
		{
			value: 5.75,
			text: '(GMT +5:45) Kathmandu',
		},
		{
			value: 6,
			text: '(GMT +6:00) Almaty, Dhaka, Colombo',
		},
		{
			value: 7,
			text: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
		},
		{
			value: 8,
			text: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
		},
		{
			value: 9,
			text: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
		},
		{
			value: 9.5,
			text: '(GMT +9:30) Adelaide, Darwin',
		},
		{
			value: 10,
			text: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
		},
		{
			value: 11,
			text: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
		},
		{
			value: 12,
			text: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
		},
	],
	timeZones: [
		// https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json
		{
			value: 'Dateline Standard Time',
			abbr: 'DST',
			offset: -12,
			isdst: false,
			text: '(UTC-12:00) International Date Line West',
			utc: ['Etc/GMT+12'],
		},
		{
			value: 'UTC-11',
			abbr: 'U',
			offset: -11,
			isdst: false,
			text: '(UTC-11:00) Coordinated Universal Time-11',
			utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
		},
		{
			value: 'Hawaiian Standard Time',
			abbr: 'HST',
			offset: -10,
			isdst: false,
			text: '(UTC-10:00) Hawaii',
			utc: ['Etc/GMT+10', 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
		},
		{
			value: 'Alaskan Standard Time',
			abbr: 'AKDT',
			offset: -8,
			isdst: true,
			text: '(UTC-09:00) Alaska',
			utc: ['America/Anchorage', 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
		},
		{
			value: 'Pacific Standard Time (Mexico)',
			abbr: 'PDT',
			offset: -7,
			isdst: true,
			text: '(UTC-08:00) Baja California',
			utc: ['America/Santa_Isabel'],
		},
		{
			value: 'Pacific Daylight Time',
			abbr: 'PDT',
			offset: -7,
			isdst: true,
			text: '(UTC-07:00) Pacific Time (US & Canada)',
			utc: ['America/Dawson', 'America/Los_Angeles', 'America/Tijuana', 'America/Vancouver', 'America/Whitehorse'],
		},
		{
			value: 'Pacific Standard Time',
			abbr: 'PST',
			offset: -8,
			isdst: false,
			text: '(UTC-08:00) Pacific Time (US & Canada)',
			utc: ['America/Dawson', 'America/Los_Angeles', 'America/Tijuana', 'America/Vancouver', 'America/Whitehorse', 'PST8PDT'],
		},
		{
			value: 'US Mountain Standard Time',
			abbr: 'UMST',
			offset: -7,
			isdst: false,
			text: '(UTC-07:00) Arizona',
			utc: ['America/Creston', 'America/Dawson_Creek', 'America/Hermosillo', 'America/Phoenix', 'Etc/GMT+7'],
		},
		{
			value: 'Mountain Standard Time (Mexico)',
			abbr: 'MDT',
			offset: -6,
			isdst: true,
			text: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
			utc: ['America/Chihuahua', 'America/Mazatlan'],
		},
		{
			value: 'Mountain Standard Time',
			abbr: 'MDT',
			offset: -6,
			isdst: true,
			text: '(UTC-07:00) Mountain Time (US & Canada)',
			utc: [
				'America/Boise',
				'America/Cambridge_Bay',
				'America/Denver',
				'America/Edmonton',
				'America/Inuvik',
				'America/Ojinaga',
				'America/Yellowknife',
				'MST7MDT',
			],
		},
		{
			value: 'Central America Standard Time',
			abbr: 'CAST',
			offset: -6,
			isdst: false,
			text: '(UTC-06:00) Central America',
			utc: [
				'America/Belize',
				'America/Costa_Rica',
				'America/El_Salvador',
				'America/Guatemala',
				'America/Managua',
				'America/Tegucigalpa',
				'Etc/GMT+6',
				'Pacific/Galapagos',
			],
		},
		{
			value: 'Central Standard Time',
			abbr: 'CDT',
			offset: -5,
			isdst: true,
			text: '(UTC-06:00) Central Time (US & Canada)',
			utc: [
				'America/Chicago',
				'America/Indiana/Knox',
				'America/Indiana/Tell_City',
				'America/Matamoros',
				'America/Menominee',
				'America/North_Dakota/Beulah',
				'America/North_Dakota/Center',
				'America/North_Dakota/New_Salem',
				'America/Rainy_River',
				'America/Rankin_Inlet',
				'America/Resolute',
				'America/Winnipeg',
				'CST6CDT',
			],
		},
		{
			value: 'Central Standard Time (Mexico)',
			abbr: 'CDT',
			offset: -5,
			isdst: true,
			text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
			utc: ['America/Bahia_Banderas', 'America/Cancun', 'America/Merida', 'America/Mexico_City', 'America/Monterrey'],
		},
		{
			value: 'Canada Central Standard Time',
			abbr: 'CCST',
			offset: -6,
			isdst: false,
			text: '(UTC-06:00) Saskatchewan',
			utc: ['America/Regina', 'America/Swift_Current'],
		},
		{
			value: 'SA Pacific Standard Time',
			abbr: 'SPST',
			offset: -5,
			isdst: false,
			text: '(UTC-05:00) Bogota, Lima, Quito',
			utc: [
				'America/Bogota',
				'America/Cayman',
				'America/Coral_Harbour',
				'America/Eirunepe',
				'America/Guayaquil',
				'America/Jamaica',
				'America/Lima',
				'America/Panama',
				'America/Rio_Branco',
				'Etc/GMT+5',
			],
		},
		{
			value: 'Eastern Standard Time',
			abbr: 'EDT',
			offset: -4,
			isdst: true,
			text: '(UTC-05:00) Eastern Time (US & Canada)',
			utc: [
				'America/Detroit',
				'America/Havana',
				'America/Indiana/Petersburg',
				'America/Indiana/Vincennes',
				'America/Indiana/Winamac',
				'America/Iqaluit',
				'America/Kentucky/Monticello',
				'America/Louisville',
				'America/Montreal',
				'America/Nassau',
				'America/New_York',
				'America/Nipigon',
				'America/Pangnirtung',
				'America/Port-au-Prince',
				'America/Thunder_Bay',
				'America/Toronto',
				'EST5EDT',
			],
		},
		{
			value: 'US Eastern Standard Time',
			abbr: 'UEDT',
			offset: -4,
			isdst: true,
			text: '(UTC-05:00) Indiana (East)',
			utc: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
		},
		{
			value: 'Venezuela Standard Time',
			abbr: 'VST',
			offset: -4.5,
			isdst: false,
			text: '(UTC-04:30) Caracas',
			utc: ['America/Caracas'],
		},
		{
			value: 'Paraguay Standard Time',
			abbr: 'PYT',
			offset: -4,
			isdst: false,
			text: '(UTC-04:00) Asuncion',
			utc: ['America/Asuncion'],
		},
		{
			value: 'Atlantic Standard Time',
			abbr: 'ADT',
			offset: -3,
			isdst: true,
			text: '(UTC-04:00) Atlantic Time (Canada)',
			utc: ['America/Glace_Bay', 'America/Goose_Bay', 'America/Halifax', 'America/Moncton', 'America/Thule', 'Atlantic/Bermuda'],
		},
		{
			value: 'Central Brazilian Standard Time',
			abbr: 'CBST',
			offset: -4,
			isdst: false,
			text: '(UTC-04:00) Cuiaba',
			utc: ['America/Campo_Grande', 'America/Cuiaba'],
		},
		{
			value: 'SA Western Standard Time',
			abbr: 'SWST',
			offset: -4,
			isdst: false,
			text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
			utc: [
				'America/Anguilla',
				'America/Antigua',
				'America/Aruba',
				'America/Barbados',
				'America/Blanc-Sablon',
				'America/Boa_Vista',
				'America/Curacao',
				'America/Dominica',
				'America/Grand_Turk',
				'America/Grenada',
				'America/Guadeloupe',
				'America/Guyana',
				'America/Kralendijk',
				'America/La_Paz',
				'America/Lower_Princes',
				'America/Manaus',
				'America/Marigot',
				'America/Martinique',
				'America/Montserrat',
				'America/Port_of_Spain',
				'America/Porto_Velho',
				'America/Puerto_Rico',
				'America/Santo_Domingo',
				'America/St_Barthelemy',
				'America/St_Kitts',
				'America/St_Lucia',
				'America/St_Thomas',
				'America/St_Vincent',
				'America/Tortola',
				'Etc/GMT+4',
			],
		},
		{
			value: 'Pacific SA Standard Time',
			abbr: 'PSST',
			offset: -4,
			isdst: false,
			text: '(UTC-04:00) Santiago',
			utc: ['America/Santiago', 'Antarctica/Palmer'],
		},
		{
			value: 'Newfoundland Standard Time',
			abbr: 'NDT',
			offset: -2.5,
			isdst: true,
			text: '(UTC-03:30) Newfoundland',
			utc: ['America/St_Johns'],
		},
		{
			value: 'E. South America Standard Time',
			abbr: 'ESAST',
			offset: -3,
			isdst: false,
			text: '(UTC-03:00) Brasilia',
			utc: ['America/Sao_Paulo'],
		},
		{
			value: 'Argentina Standard Time',
			abbr: 'AST',
			offset: -3,
			isdst: false,
			text: '(UTC-03:00) Buenos Aires',
			utc: [
				'America/Argentina/La_Rioja',
				'America/Argentina/Rio_Gallegos',
				'America/Argentina/Salta',
				'America/Argentina/San_Juan',
				'America/Argentina/San_Luis',
				'America/Argentina/Tucuman',
				'America/Argentina/Ushuaia',
				'America/Buenos_Aires',
				'America/Catamarca',
				'America/Cordoba',
				'America/Jujuy',
				'America/Mendoza',
			],
		},
		{
			value: 'SA Eastern Standard Time',
			abbr: 'SEST',
			offset: -3,
			isdst: false,
			text: '(UTC-03:00) Cayenne, Fortaleza',
			utc: [
				'America/Araguaina',
				'America/Belem',
				'America/Cayenne',
				'America/Fortaleza',
				'America/Maceio',
				'America/Paramaribo',
				'America/Recife',
				'America/Santarem',
				'Antarctica/Rothera',
				'Atlantic/Stanley',
				'Etc/GMT+3',
			],
		},
		{
			value: 'Greenland Standard Time',
			abbr: 'GDT',
			offset: -3,
			isdst: true,
			text: '(UTC-03:00) Greenland',
			utc: ['America/Godthab'],
		},
		{
			value: 'Montevideo Standard Time',
			abbr: 'MST',
			offset: -3,
			isdst: false,
			text: '(UTC-03:00) Montevideo',
			utc: ['America/Montevideo'],
		},
		{
			value: 'Bahia Standard Time',
			abbr: 'BST',
			offset: -3,
			isdst: false,
			text: '(UTC-03:00) Salvador',
			utc: ['America/Bahia'],
		},
		{
			value: 'UTC-02',
			abbr: 'U',
			offset: -2,
			isdst: false,
			text: '(UTC-02:00) Coordinated Universal Time-02',
			utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
		},
		{
			value: 'Mid-Atlantic Standard Time',
			abbr: 'MDT',
			offset: -1,
			isdst: true,
			text: '(UTC-02:00) Mid-Atlantic - Old',
			utc: [],
		},
		{
			value: 'Azores Standard Time',
			abbr: 'ADT',
			offset: 0,
			isdst: true,
			text: '(UTC-01:00) Azores',
			utc: ['America/Scoresbysund', 'Atlantic/Azores'],
		},
		{
			value: 'Cape Verde Standard Time',
			abbr: 'CVST',
			offset: -1,
			isdst: false,
			text: '(UTC-01:00) Cape Verde Is.',
			utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
		},
		{
			value: 'Morocco Standard Time',
			abbr: 'MDT',
			offset: 1,
			isdst: true,
			text: '(UTC) Casablanca',
			utc: ['Africa/Casablanca', 'Africa/El_Aaiun'],
		},
		{
			value: 'UTC',
			abbr: 'UTC',
			offset: 0,
			isdst: false,
			text: '(UTC) Coordinated Universal Time',
			utc: ['America/Danmarkshavn', 'Etc/GMT'],
		},
		{
			value: 'GMT Standard Time',
			abbr: 'GMT',
			offset: 0,
			isdst: false,
			text: '(UTC) Edinburgh, London',
			utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
		},
		{
			value: 'British Summer Time',
			abbr: 'BST',
			offset: 1,
			isdst: true,
			text: '(UTC+01:00) Edinburgh, London',
			utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
		},
		{
			value: 'GMT Standard Time',
			abbr: 'GDT',
			offset: 1,
			isdst: true,
			text: '(UTC) Dublin, Lisbon',
			utc: ['Atlantic/Canary', 'Atlantic/Faeroe', 'Atlantic/Madeira', 'Europe/Dublin', 'Europe/Lisbon'],
		},
		{
			value: 'Greenwich Standard Time',
			abbr: 'GST',
			offset: 0,
			isdst: false,
			text: '(UTC) Monrovia, Reykjavik',
			utc: [
				'Africa/Abidjan',
				'Africa/Accra',
				'Africa/Bamako',
				'Africa/Banjul',
				'Africa/Bissau',
				'Africa/Conakry',
				'Africa/Dakar',
				'Africa/Freetown',
				'Africa/Lome',
				'Africa/Monrovia',
				'Africa/Nouakchott',
				'Africa/Ouagadougou',
				'Africa/Sao_Tome',
				'Atlantic/Reykjavik',
				'Atlantic/St_Helena',
			],
		},
		{
			value: 'W. Europe Standard Time',
			abbr: 'WEDT',
			offset: 2,
			isdst: true,
			text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
			utc: [
				'Arctic/Longyearbyen',
				'Europe/Amsterdam',
				'Europe/Andorra',
				'Europe/Berlin',
				'Europe/Busingen',
				'Europe/Gibraltar',
				'Europe/Luxembourg',
				'Europe/Malta',
				'Europe/Monaco',
				'Europe/Oslo',
				'Europe/Rome',
				'Europe/San_Marino',
				'Europe/Stockholm',
				'Europe/Vaduz',
				'Europe/Vatican',
				'Europe/Vienna',
				'Europe/Zurich',
			],
		},
		{
			value: 'Central Europe Standard Time',
			abbr: 'CEDT',
			offset: 2,
			isdst: true,
			text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
			utc: ['Europe/Belgrade', 'Europe/Bratislava', 'Europe/Budapest', 'Europe/Ljubljana', 'Europe/Podgorica', 'Europe/Prague', 'Europe/Tirane'],
		},
		{
			value: 'Romance Standard Time',
			abbr: 'RDT',
			offset: 2,
			isdst: true,
			text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
			utc: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris'],
		},
		{
			value: 'Central European Standard Time',
			abbr: 'CEDT',
			offset: 2,
			isdst: true,
			text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
			utc: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
		},
		{
			value: 'W. Central Africa Standard Time',
			abbr: 'WCAST',
			offset: 1,
			isdst: false,
			text: '(UTC+01:00) West Central Africa',
			utc: [
				'Africa/Algiers',
				'Africa/Bangui',
				'Africa/Brazzaville',
				'Africa/Douala',
				'Africa/Kinshasa',
				'Africa/Lagos',
				'Africa/Libreville',
				'Africa/Luanda',
				'Africa/Malabo',
				'Africa/Ndjamena',
				'Africa/Niamey',
				'Africa/Porto-Novo',
				'Africa/Tunis',
				'Etc/GMT-1',
			],
		},
		{
			value: 'Namibia Standard Time',
			abbr: 'NST',
			offset: 1,
			isdst: false,
			text: '(UTC+01:00) Windhoek',
			utc: ['Africa/Windhoek'],
		},
		{
			value: 'GTB Standard Time',
			abbr: 'GDT',
			offset: 3,
			isdst: true,
			text: '(UTC+02:00) Athens, Bucharest',
			utc: ['Asia/Nicosia', 'Europe/Athens', 'Europe/Bucharest', 'Europe/Chisinau'],
		},
		{
			value: 'Middle East Standard Time',
			abbr: 'MEDT',
			offset: 3,
			isdst: true,
			text: '(UTC+02:00) Beirut',
			utc: ['Asia/Beirut'],
		},
		{
			value: 'Egypt Standard Time',
			abbr: 'EST',
			offset: 2,
			isdst: false,
			text: '(UTC+02:00) Cairo',
			utc: ['Africa/Cairo'],
		},
		{
			value: 'Syria Standard Time',
			abbr: 'SDT',
			offset: 3,
			isdst: true,
			text: '(UTC+02:00) Damascus',
			utc: ['Asia/Damascus'],
		},
		{
			value: 'E. Europe Standard Time',
			abbr: 'EEDT',
			offset: 3,
			isdst: true,
			text: '(UTC+02:00) E. Europe',
			utc: [
				'Asia/Nicosia',
				'Europe/Athens',
				'Europe/Bucharest',
				'Europe/Chisinau',
				'Europe/Helsinki',
				'Europe/Kiev',
				'Europe/Mariehamn',
				'Europe/Nicosia',
				'Europe/Riga',
				'Europe/Sofia',
				'Europe/Tallinn',
				'Europe/Uzhgorod',
				'Europe/Vilnius',
				'Europe/Zaporozhye',
			],
		},
		{
			value: 'South Africa Standard Time',
			abbr: 'SAST',
			offset: 2,
			isdst: false,
			text: '(UTC+02:00) Harare, Pretoria',
			utc: [
				'Africa/Blantyre',
				'Africa/Bujumbura',
				'Africa/Gaborone',
				'Africa/Harare',
				'Africa/Johannesburg',
				'Africa/Kigali',
				'Africa/Lubumbashi',
				'Africa/Lusaka',
				'Africa/Maputo',
				'Africa/Maseru',
				'Africa/Mbabane',
				'Etc/GMT-2',
			],
		},
		{
			value: 'FLE Standard Time',
			abbr: 'FDT',
			offset: 3,
			isdst: true,
			text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
			utc: [
				'Europe/Helsinki',
				'Europe/Kiev',
				'Europe/Mariehamn',
				'Europe/Riga',
				'Europe/Sofia',
				'Europe/Tallinn',
				'Europe/Uzhgorod',
				'Europe/Vilnius',
				'Europe/Zaporozhye',
			],
		},
		{
			value: 'Turkey Standard Time',
			abbr: 'TDT',
			offset: 3,
			isdst: false,
			text: '(UTC+03:00) Istanbul',
			utc: ['Europe/Istanbul'],
		},
		{
			value: 'Israel Standard Time',
			abbr: 'JDT',
			offset: 3,
			isdst: true,
			text: '(UTC+02:00) Jerusalem',
			utc: ['Asia/Jerusalem'],
		},
		{
			value: 'Libya Standard Time',
			abbr: 'LST',
			offset: 2,
			isdst: false,
			text: '(UTC+02:00) Tripoli',
			utc: ['Africa/Tripoli'],
		},
		{
			value: 'Jordan Standard Time',
			abbr: 'JST',
			offset: 3,
			isdst: false,
			text: '(UTC+03:00) Amman',
			utc: ['Asia/Amman'],
		},
		{
			value: 'Arabic Standard Time',
			abbr: 'AST',
			offset: 3,
			isdst: false,
			text: '(UTC+03:00) Baghdad',
			utc: ['Asia/Baghdad'],
		},
		{
			value: 'Kaliningrad Standard Time',
			abbr: 'KST',
			offset: 3,
			isdst: false,
			text: '(UTC+02:00) Kaliningrad',
			utc: ['Europe/Kaliningrad'],
		},
		{
			value: 'Arab Standard Time',
			abbr: 'AST',
			offset: 3,
			isdst: false,
			text: '(UTC+03:00) Kuwait, Riyadh',
			utc: ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh'],
		},
		{
			value: 'E. Africa Standard Time',
			abbr: 'EAST',
			offset: 3,
			isdst: false,
			text: '(UTC+03:00) Nairobi',
			utc: [
				'Africa/Addis_Ababa',
				'Africa/Asmera',
				'Africa/Dar_es_Salaam',
				'Africa/Djibouti',
				'Africa/Juba',
				'Africa/Kampala',
				'Africa/Khartoum',
				'Africa/Mogadishu',
				'Africa/Nairobi',
				'Antarctica/Syowa',
				'Etc/GMT-3',
				'Indian/Antananarivo',
				'Indian/Comoro',
				'Indian/Mayotte',
			],
		},
		{
			value: 'Moscow Standard Time',
			abbr: 'MSK',
			offset: 3,
			isdst: false,
			text: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
			utc: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd', 'Europe/Minsk'],
		},
		{
			value: 'Samara Time',
			abbr: 'SAMT',
			offset: 4,
			isdst: false,
			text: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
			utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
		},
		{
			value: 'Iran Standard Time',
			abbr: 'IDT',
			offset: 4.5,
			isdst: true,
			text: '(UTC+03:30) Tehran',
			utc: ['Asia/Tehran'],
		},
		{
			value: 'Arabian Standard Time',
			abbr: 'AST',
			offset: 4,
			isdst: false,
			text: '(UTC+04:00) Abu Dhabi, Muscat',
			utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
		},
		{
			value: 'Azerbaijan Standard Time',
			abbr: 'ADT',
			offset: 5,
			isdst: true,
			text: '(UTC+04:00) Baku',
			utc: ['Asia/Baku'],
		},
		{
			value: 'Mauritius Standard Time',
			abbr: 'MST',
			offset: 4,
			isdst: false,
			text: '(UTC+04:00) Port Louis',
			utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
		},
		{
			value: 'Georgian Standard Time',
			abbr: 'GET',
			offset: 4,
			isdst: false,
			text: '(UTC+04:00) Tbilisi',
			utc: ['Asia/Tbilisi'],
		},
		{
			value: 'Caucasus Standard Time',
			abbr: 'CST',
			offset: 4,
			isdst: false,
			text: '(UTC+04:00) Yerevan',
			utc: ['Asia/Yerevan'],
		},
		{
			value: 'Afghanistan Standard Time',
			abbr: 'AST',
			offset: 4.5,
			isdst: false,
			text: '(UTC+04:30) Kabul',
			utc: ['Asia/Kabul'],
		},
		{
			value: 'West Asia Standard Time',
			abbr: 'WAST',
			offset: 5,
			isdst: false,
			text: '(UTC+05:00) Ashgabat, Tashkent',
			utc: [
				'Antarctica/Mawson',
				'Asia/Aqtau',
				'Asia/Aqtobe',
				'Asia/Ashgabat',
				'Asia/Dushanbe',
				'Asia/Oral',
				'Asia/Samarkand',
				'Asia/Tashkent',
				'Etc/GMT-5',
				'Indian/Kerguelen',
				'Indian/Maldives',
			],
		},
		{
			value: 'Yekaterinburg Time',
			abbr: 'YEKT',
			offset: 5,
			isdst: false,
			text: '(UTC+05:00) Yekaterinburg',
			utc: ['Asia/Yekaterinburg'],
		},
		{
			value: 'Pakistan Standard Time',
			abbr: 'PKT',
			offset: 5,
			isdst: false,
			text: '(UTC+05:00) Islamabad, Karachi',
			utc: ['Asia/Karachi'],
		},
		{
			value: 'India Standard Time',
			abbr: 'IST',
			offset: 5.5,
			isdst: false,
			text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
			utc: ['Asia/Kolkata'],
		},
		{
			value: 'Sri Lanka Standard Time',
			abbr: 'SLST',
			offset: 5.5,
			isdst: false,
			text: '(UTC+05:30) Sri Jayawardenepura',
			utc: ['Asia/Colombo'],
		},
		{
			value: 'Nepal Standard Time',
			abbr: 'NST',
			offset: 5.75,
			isdst: false,
			text: '(UTC+05:45) Kathmandu',
			utc: ['Asia/Kathmandu'],
		},
		{
			value: 'Central Asia Standard Time',
			abbr: 'CAST',
			offset: 6,
			isdst: false,
			text: '(UTC+06:00) Nur-Sultan (Astana)',
			utc: ['Antarctica/Vostok', 'Asia/Almaty', 'Asia/Bishkek', 'Asia/Qyzylorda', 'Asia/Urumqi', 'Etc/GMT-6', 'Indian/Chagos'],
		},
		{
			value: 'Bangladesh Standard Time',
			abbr: 'BST',
			offset: 6,
			isdst: false,
			text: '(UTC+06:00) Dhaka',
			utc: ['Asia/Dhaka', 'Asia/Thimphu'],
		},
		{
			value: 'Myanmar Standard Time',
			abbr: 'MST',
			offset: 6.5,
			isdst: false,
			text: '(UTC+06:30) Yangon (Rangoon)',
			utc: ['Asia/Rangoon', 'Indian/Cocos'],
		},
		{
			value: 'SE Asia Standard Time',
			abbr: 'SAST',
			offset: 7,
			isdst: false,
			text: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
			utc: [
				'Antarctica/Davis',
				'Asia/Bangkok',
				'Asia/Hovd',
				'Asia/Jakarta',
				'Asia/Phnom_Penh',
				'Asia/Pontianak',
				'Asia/Saigon',
				'Asia/Vientiane',
				'Etc/GMT-7',
				'Indian/Christmas',
			],
		},
		{
			value: 'N. Central Asia Standard Time',
			abbr: 'NCAST',
			offset: 7,
			isdst: false,
			text: '(UTC+07:00) Novosibirsk',
			utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
		},
		{
			value: 'China Standard Time',
			abbr: 'CST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
			utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
		},
		{
			value: 'North Asia Standard Time',
			abbr: 'NAST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Krasnoyarsk',
			utc: ['Asia/Krasnoyarsk'],
		},
		{
			value: 'Singapore Standard Time',
			abbr: 'MPST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Kuala Lumpur, Singapore',
			utc: ['Asia/Brunei', 'Asia/Kuala_Lumpur', 'Asia/Kuching', 'Asia/Makassar', 'Asia/Manila', 'Asia/Singapore', 'Etc/GMT-8'],
		},
		{
			value: 'W. Australia Standard Time',
			abbr: 'WAST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Perth',
			utc: ['Antarctica/Casey', 'Australia/Perth'],
		},
		{
			value: 'Taipei Standard Time',
			abbr: 'TST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Taipei',
			utc: ['Asia/Taipei'],
		},
		{
			value: 'Ulaanbaatar Standard Time',
			abbr: 'UST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Ulaanbaatar',
			utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
		},
		{
			value: 'North Asia East Standard Time',
			abbr: 'NAEST',
			offset: 8,
			isdst: false,
			text: '(UTC+08:00) Irkutsk',
			utc: ['Asia/Irkutsk'],
		},
		{
			value: 'Japan Standard Time',
			abbr: 'JST',
			offset: 9,
			isdst: false,
			text: '(UTC+09:00) Osaka, Sapporo, Tokyo',
			utc: ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau'],
		},
		{
			value: 'Korea Standard Time',
			abbr: 'KST',
			offset: 9,
			isdst: false,
			text: '(UTC+09:00) Seoul',
			utc: ['Asia/Pyongyang', 'Asia/Seoul'],
		},
		{
			value: 'Cen. Australia Standard Time',
			abbr: 'CAST',
			offset: 9.5,
			isdst: false,
			text: '(UTC+09:30) Adelaide',
			utc: ['Australia/Adelaide', 'Australia/Broken_Hill'],
		},
		{
			value: 'AUS Central Standard Time',
			abbr: 'ACST',
			offset: 9.5,
			isdst: false,
			text: '(UTC+09:30) Darwin',
			utc: ['Australia/Darwin'],
		},
		{
			value: 'E. Australia Standard Time',
			abbr: 'EAST',
			offset: 10,
			isdst: false,
			text: '(UTC+10:00) Brisbane',
			utc: ['Australia/Brisbane', 'Australia/Lindeman'],
		},
		{
			value: 'AUS Eastern Standard Time',
			abbr: 'AEST',
			offset: 10,
			isdst: false,
			text: '(UTC+10:00) Canberra, Melbourne, Sydney',
			utc: ['Australia/Melbourne', 'Australia/Sydney'],
		},
		{
			value: 'West Pacific Standard Time',
			abbr: 'WPST',
			offset: 10,
			isdst: false,
			text: '(UTC+10:00) Guam, Port Moresby',
			utc: ['Antarctica/DumontDUrville', 'Etc/GMT-10', 'Pacific/Guam', 'Pacific/Port_Moresby', 'Pacific/Saipan', 'Pacific/Truk'],
		},
		{
			value: 'Tasmania Standard Time',
			abbr: 'TST',
			offset: 10,
			isdst: false,
			text: '(UTC+10:00) Hobart',
			utc: ['Australia/Currie', 'Australia/Hobart'],
		},
		{
			value: 'Yakutsk Standard Time',
			abbr: 'YST',
			offset: 9,
			isdst: false,
			text: '(UTC+09:00) Yakutsk',
			utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
		},
		{
			value: 'Central Pacific Standard Time',
			abbr: 'CPST',
			offset: 11,
			isdst: false,
			text: '(UTC+11:00) Solomon Is., New Caledonia',
			utc: ['Antarctica/Macquarie', 'Etc/GMT-11', 'Pacific/Efate', 'Pacific/Guadalcanal', 'Pacific/Kosrae', 'Pacific/Noumea', 'Pacific/Ponape'],
		},
		{
			value: 'Vladivostok Standard Time',
			abbr: 'VST',
			offset: 11,
			isdst: false,
			text: '(UTC+11:00) Vladivostok',
			utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
		},
		{
			value: 'New Zealand Standard Time',
			abbr: 'NZST',
			offset: 12,
			isdst: false,
			text: '(UTC+12:00) Auckland, Wellington',
			utc: ['Antarctica/McMurdo', 'Pacific/Auckland'],
		},
		{
			value: 'UTC+12',
			abbr: 'U',
			offset: 12,
			isdst: false,
			text: '(UTC+12:00) Coordinated Universal Time+12',
			utc: ['Etc/GMT-12', 'Pacific/Funafuti', 'Pacific/Kwajalein', 'Pacific/Majuro', 'Pacific/Nauru', 'Pacific/Tarawa', 'Pacific/Wake', 'Pacific/Wallis'],
		},
		{
			value: 'Fiji Standard Time',
			abbr: 'FST',
			offset: 12,
			isdst: false,
			text: '(UTC+12:00) Fiji',
			utc: ['Pacific/Fiji'],
		},
		{
			value: 'Magadan Standard Time',
			abbr: 'MST',
			offset: 12,
			isdst: false,
			text: '(UTC+12:00) Magadan',
			utc: ['Asia/Anadyr', 'Asia/Kamchatka', 'Asia/Magadan', 'Asia/Srednekolymsk'],
		},
		{
			value: 'Kamchatka Standard Time',
			abbr: 'KDT',
			offset: 13,
			isdst: true,
			text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
			utc: ['Asia/Kamchatka'],
		},
		{
			value: 'Tonga Standard Time',
			abbr: 'TST',
			offset: 13,
			isdst: false,
			text: "(UTC+13:00) Nuku'alofa",
			utc: ['Etc/GMT-13', 'Pacific/Enderbury', 'Pacific/Fakaofo', 'Pacific/Tongatapu'],
		},
		{
			value: 'Samoa Standard Time',
			abbr: 'SST',
			offset: 13,
			isdst: false,
			text: '(UTC+13:00) Samoa',
			utc: ['Pacific/Apia'],
		},
	],
};
