<shared-page-toolbar-outlet>
	<app-root-monitor-cloud-dashboard-toolbar-controls (paramsChanged)="onParamsChanged($event)"></app-root-monitor-cloud-dashboard-toolbar-controls>
</shared-page-toolbar-outlet>

<app-shared-monitor-common-dashboard-carousel>
	<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="config">
		<ng-container *ngFor="let card of activeCards" ngxSlickItem [ngTemplateOutlet]="cards" [ngTemplateOutletContext]="{ $implicit: card }"></ng-container>
	</ngx-slick-carousel>
</app-shared-monitor-common-dashboard-carousel>

<ng-template #cards let-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'server-health'"
		[isActive]="true"
		path="server-health"
		heading="Server Health"
		icon="monitor-waveform"
		tooltip="% of servers with no open Server Health alerts. Server Health alerts include all Hardware and OS alert types.">
		<ng-container *ngTemplateOutlet="serverHealth"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'vms-health'"
		path="vms-health"
		heading="VMS Health"
		icon="video"
		tooltip="The average of active recording services averaged with video streams recording.">
		<ng-container *ngTemplateOutlet="vmsHealth"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'iot-health'"
		path="iot-health"
		heading="IoT Health"
		icon="camera-cctv"
		tooltip="% of device ports with no open IoT alerts. Enable IoT alerts by configuring CameraHealth on the switch and EndpointDefender.">
		<ng-container *ngTemplateOutlet="iotHealth"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'cybersecurity-posture'"
		path="cybersecurity-posture"
		heading="Cybersecurity Posture"
		icon="shield-check"
		tooltip="The average of agents using supported malware protection averaged with devices using CameraDefense. Use both measures to improve your overall cybersecurity posture.">
		<ng-container *ngTemplateOutlet="cybersecurityPosture"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'manage-tasks'"
		path="manage-tasks"
		heading="Manage Tasks"
		icon="list-check"
		tooltip="This device's warranty status is shown here.  Offline devices will not show their warranty information.">
		<ng-container *ngTemplateOutlet="manageTasks"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'offline-devices'"
		path="offline-agents"
		heading="Offline Devices"
		icon="display-slash"
		tooltip="Sum of Agents, switches, EndpointDefenders and SNMP Devices not communicating with {{
			settingsService.data.settings?.global?.baseTitle || 'MonitorCloud'
		}}.">
		<ng-container *ngTemplateOutlet="offlineDevices"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
	<app-shared-monitor-common-dashboard-carousel-card
		*ngIf="card.templateName === 'total-alerts'"
		path="total-alerts"
		heading="Total Alerts"
		icon="bell"
		tooltip="Sum of alerts by category.">
		<ng-container *ngTemplateOutlet="totalAlerts"></ng-container>
	</app-shared-monitor-common-dashboard-carousel-card>
</ng-template>

<ng-template #serverHealth>
	<div class="row">
		<div class="col-sm-12">
			<app-shared-monitor-common-dashboard-half-circle-graph
				[percentage]="dashboardData?.serverHealthPercent"></app-shared-monitor-common-dashboard-half-circle-graph>
			<app-shared-monitor-common-dashboard-label-value
				label="Servers with Open Alerts"
				value="{{ dashboardData?.serverHealthAlertUnitCount }}"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Total Servers"
				value="{{ dashboardData?.unitCount }}"></app-shared-monitor-common-dashboard-label-value>
		</div>
	</div>
</ng-template>

<ng-template #vmsHealth>
	<div class="row">
		<div class="col-sm-12">
			<app-shared-monitor-common-dashboard-half-circle-graph
				[percentage]="dashboardData?.vmsHealthPercent"></app-shared-monitor-common-dashboard-half-circle-graph>
			<app-shared-monitor-common-dashboard-label-value
				label="Recorded Video Streams"
				value="{{ dashboardData?.recordedStreamsPercent }} %"
				[clickable]="true"
				(click)="navigate('recorded-streams'); $event.stopPropagation()"
				tooltip="% of recorded video streams reporting as active."></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Recording Services"
				value="{{ dashboardData?.recordingServersPercent }} %"
				[clickable]="true"
				(click)="navigate('recording-servers'); $event.stopPropagation()"
				tooltip="% of recording servers reporting as active."></app-shared-monitor-common-dashboard-label-value>
		</div>
	</div>
</ng-template>

<ng-template #iotHealth>
	<div class="row">
		<div class="col-sm-12">
			<app-shared-monitor-common-dashboard-half-circle-graph
				[percentage]="dashboardData?.iotPercent"></app-shared-monitor-common-dashboard-half-circle-graph>
			<app-shared-monitor-common-dashboard-label-value
				label="Ports with Open IoT Alerts"
				value="{{ dashboardData?.iotAlertPortCount }}"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Total Ports"
				value="{{ dashboardData?.totalPortCount }}"></app-shared-monitor-common-dashboard-label-value>
		</div>
	</div>
</ng-template>

<ng-template #cybersecurityPosture>
	<div class="row">
		<div class="col-sm-12">
			<app-shared-monitor-common-dashboard-half-circle-graph
				[percentage]="dashboardData?.cybersecurityPercent"></app-shared-monitor-common-dashboard-half-circle-graph>
			<app-shared-monitor-common-dashboard-label-value
				label="Malware Protection"
				value="{{ dashboardData?.malwareProtectionPercent }} %"
				[clickable]="true"
				(click)="navigate('malware-protection'); $event.stopPropagation()"
				tooltip="Average of agents with supported malware protection installed."></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="CameraDefense"
				value="{{ dashboardData?.cameraDefensePercent }} %"
				[clickable]="true"
				(click)="navigate('camera-defense'); $event.stopPropagation()"
				tooltip="Average of switches and EndpointDefenders with CameraDefense enabled."></app-shared-monitor-common-dashboard-label-value>
		</div>
	</div>
</ng-template>

<ng-template #manageTasks>
	<div class="row">
		<div class="col-sm-12">
			<h2 class="border-bottom">{{ dashboardData?.totalTasksCount }}</h2>
			<app-shared-monitor-common-dashboard-label-value
				label="Pending"
				tooltip="The number of tasks that are scheduled or executing"
				value="{{ dashboardData?.scheduledTasksCount + dashboardData?.executingTasksCount }}"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Successful"
				value="{{ dashboardData?.successfulTasksCount }}"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Failed"
				value="{{ dashboardData?.failedTasksCount }}"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				[textClass]="dashboardData?.devicesWithUpdatesCount > 0 ? 'status-color-orange' : ''"
				label="Agents with Updates"
				value="{{ dashboardData?.devicesWithUpdatesCount }}"
				tooltip="There {{ dashboardData?.devicesWithUpdatesCount == 1 ? 'is' : 'are' }} {{ dashboardData?.devicesWithUpdatesCount }} agent{{
					dashboardData?.devicesWithUpdatesCount == 1 ? '' : 's'
				}} with an available update."
				[clickable]="true"
				(click)="navigate('device-updates'); $event.stopPropagation()"></app-shared-monitor-common-dashboard-label-value>
		</div>
	</div>
</ng-template>

<ng-template #offlineDevices>
	<div class="row">
		<div class="col-sm-12">
			<h2 class="border-bottom">{{ dashboardData?.totalOfflineCount }}</h2>
			<app-shared-monitor-common-dashboard-label-value
				label="Agents Offline"
				value="{{ dashboardData?.unitOfflineCount }}"
				[clickable]="true"
				(click)="navigate('offline-agents'); $event.stopPropagation()"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Switches Offline"
				value="{{ dashboardData?.switchOfflineCount }}"
				[clickable]="true"
				(click)="navigate('offline-devices'); $event.stopPropagation()"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Endpoint Defenders Offline"
				value="{{ dashboardData?.endpointDefenderOfflineCount }}"
				[clickable]="true"
				(click)="navigate('offline-devices'); $event.stopPropagation()"></app-shared-monitor-common-dashboard-label-value>
			<app-shared-monitor-common-dashboard-label-value
				label="Other Devices Offline"
				value="{{ dashboardData?.otherDeviceOfflineCount }}"
				[clickable]="true"
				(click)="navigate('offline-devices'); $event.stopPropagation()"></app-shared-monitor-common-dashboard-label-value>
		</div>
	</div>
</ng-template>

<ng-template #totalAlerts>
	<div class="row">
		<div class="col-sm-12">
			<h2 class="border-bottom">{{ dashboardData?.totalAlertCount }}</h2>
			<app-shared-monitor-common-dashboard-micro-bar-graph
				label="Server"
				value="{{ dashboardData?.serverAlertCount }}"
				percentage="{{
					dashboardData?.totalAlertCount ? (100 * dashboardData?.serverAlertCount) / dashboardData?.totalAlertCount : 0
				}}"></app-shared-monitor-common-dashboard-micro-bar-graph>
			<app-shared-monitor-common-dashboard-micro-bar-graph
				label="IoT"
				value="{{ dashboardData?.iotAlertCount }}"
				percentage="{{
					dashboardData?.totalAlertCount ? (100 * dashboardData?.iotAlertCount) / dashboardData?.totalAlertCount : 0
				}}"></app-shared-monitor-common-dashboard-micro-bar-graph>
			<app-shared-monitor-common-dashboard-micro-bar-graph
				label="Cybersecurity"
				value="{{ dashboardData?.cybersecurityAlertCount }}"
				percentage="{{
					dashboardData?.totalAlertCount ? (100 * dashboardData?.cybersecurityAlertCount) / dashboardData?.totalAlertCount : 0
				}}"></app-shared-monitor-common-dashboard-micro-bar-graph>
			<app-shared-monitor-common-dashboard-micro-bar-graph
				label="Online / Offline"
				value="{{ dashboardData?.onlineOfflineAlertCount }}"
				percentage="{{
					dashboardData?.totalAlertCount ? (100 * dashboardData?.onlineOfflineAlertCount) / dashboardData?.totalAlertCount : 0
				}}"></app-shared-monitor-common-dashboard-micro-bar-graph>
			<app-shared-monitor-common-dashboard-micro-bar-graph
				label="Expiration"
				value="{{ dashboardData?.expirationAlertCount }}"
				percentage="{{
					dashboardData?.totalAlertCount ? (100 * dashboardData?.expirationAlertCount) / dashboardData?.totalAlertCount : 0
				}}"></app-shared-monitor-common-dashboard-micro-bar-graph>
		</div>
	</div>
</ng-template>
