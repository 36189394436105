<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title"><fa-icon icon="server"></fa-icon><span class="ps-2">Create Update Task</span></h4>
		<button type="button" class="close" (click)="close()">
			<fa-icon icon="times" size="lg"></fa-icon>
		</button>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="(updateTypeCategoryGroups?.size || 0) == 0"> Continue to preview a new update task for the selected devices. </ng-container>
		<ng-container *ngIf="updateTypeCategoryGroups?.size > 0">
			<p>Select the update types you want for the new task.</p>
			<div class="px-5 pb-3">
				<div *ngFor="let updateTypeCategory of updateTypeCategoryGroups | keyvalue">
					<h6 class="mb-3">{{ utils.helpers.addSpacesBeforeCapitals(updateTypeCategory.key) }}</h6>
					<div *ngFor="let updateType of updateTypeCategory.value" class="form-check ms-3">
						<input
							type="checkbox"
							class="form-check-input cursor-pointer mx-3"
							id="updateType_{{ updateType.updateTypeId }}"
							[(ngModel)]="updateType.isSelected"
							[ngModelOptions]="{ standalone: true }"
							(change)="checkValidity()" />
						<label class="form-check-label cursor-pointer" style="white-space: nowrap" for="updateType_{{ updateType.updateTypeId }}">
							{{ updateType.name || 'Unknow Update Type' }}
						</label>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="isValid !== true">
			<fa-icon icon="check"></fa-icon> Preview Task
		</button>
	</div>
</div>
