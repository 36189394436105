import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountStat, AccountSubscription, AccountSubscriptionStatus, AccountSubscriptionType, EnumService } from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import * as moment from 'moment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-subscription-form',
	templateUrl: './account-subscription-form.component.html',
	styleUrls: ['./account-subscription-form.component.scss'],
})
export class AccountSubscriptionFormComponent {
	@Input() accountSubscription: AccountSubscription;
	@Input() accountStat: AccountStat;
	@Input() reset$?: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<AccountSubscription> = new EventEmitter<AccountSubscription>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	accountSubscriptionForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();
	types: any[];
	durations: { value: string; label: string }[];
	enabled: boolean = false;
	selectedDuration: number;
	showStatus: boolean = false;
	status: string = 'Inactive';
	statusColor: string = 'dark';
	statusIcon: string = 'circle';
	timestamp: Date;
	initialized: boolean = false;

	constructor(private readonly formBuilder: UntypedFormBuilder, private readonly utils: UtilitiesService, private readonly enumService: EnumService) {}

	ngOnInit() {
		this.types = this.enumService.helpers.getAccountSubscriptionTypes(true);
		this.durations = this.getDurations();
		this.buildForm();

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.accountSubscriptionForm.reset();
					}, 0)
				)
			);

		this.subscriptions.add(
			this.accountSubscriptionForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.accountSubscriptionForm.valueChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.changes.emit({ ...this.accountSubscriptionForm.getRawValue() });
			})
		);
		this.subscriptions.add(
			this.accountSubscriptionForm
				.get('type')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => {
					if (value > AccountSubscriptionType.None) {
						this.enabled = true;
						this.showStatus = false;
						this.accountSubscriptionForm.get('maxDevices').enable();
						this.durations = this.getDurations(value === AccountSubscriptionType.Trial);
						this.selectedDuration = value === AccountSubscriptionType.Trial ? 0 : 2;
						this.accountSubscriptionForm.get('startDate').enable();
						this.accountSubscriptionForm.get('startDate').setValue(moment.utc().startOf('day').toDate());
						this.accountSubscriptionForm.get('endDate').disable();
					} else {
						this.enabled = false;
						this.selectedDuration = 0;
						this.accountSubscriptionForm.get('maxDevices').disable();
						this.accountSubscriptionForm.get('maxDevices').setValue(null);
						this.accountSubscriptionForm.get('startDate').disable();
						this.accountSubscriptionForm.get('startDate').setValue(null);
						this.accountSubscriptionForm.get('endDate').disable();
						this.accountSubscriptionForm.get('endDate').setValue(null);
					}
					this.accountSubscriptionForm.updateValueAndValidity();
				})
		);
		this.subscriptions.add(
			this.accountSubscriptionForm
				.get('startDate')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => {
					if (value && this.durations[this.selectedDuration].value !== 'custom') this.setEndDate(value, this.durations[this.selectedDuration].value);
				})
		);
		this.initialized = true;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.buildForm();
		if (changes.accountSubscription && changes.accountSubscription.currentValue) {
			this.accountSubscriptionForm.patchValue(changes.accountSubscription.currentValue);
			const subscription: AccountSubscription = changes.accountSubscription?.currentValue ?? this.accountSubscription;
			const stat: AccountStat = changes.accountStat?.currentValue ?? this.accountStat;
			if (subscription && stat) {
				this.showStatus = true;
				this.timestamp = subscription.timestamp;
				this.status = AccountSubscriptionStatus[subscription.status];
				this.statusIcon = this.getStatusIcon(subscription.status);
				this.statusColor =
					subscription.status === AccountSubscriptionStatus.Active
						? 'success'
						: subscription.status === AccountSubscriptionStatus.Expiring || stat.unitTotal > subscription.maxDevices
						? 'warning'
						: subscription.status === AccountSubscriptionStatus.Expired
						? 'danger'
						: 'dark';
				if (subscription.type > AccountSubscriptionType.None) {
					this.enabled = true;
					this.durations = this.getDurations(subscription.type === AccountSubscriptionType.Trial);
					this.onDurationChange(this.getDuration(subscription.startDate, subscription.endDate));
					if (subscription.status === AccountSubscriptionStatus.Expiring) {
						const days: number = moment.utc(subscription.endDate).diff(moment.utc().startOf('day'), 'days');
						this.status = `${AccountSubscriptionStatus[subscription.status]} in ${days} day${days !== 1 ? 's' : ''}`;
					} else if (stat.unitTotal > subscription.maxDevices) this.status = 'Too many devices';
				} else this.enabled = false;
			}
			if (!this.initialized) {
				this.isValid.emit(this.accountSubscriptionForm.valid);
				this.changes.emit({ ...this.accountSubscriptionForm.getRawValue() });
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	buildForm() {
		if (this.accountSubscriptionForm != null) {
			return;
		}

		this.accountSubscriptionForm = this.formBuilder.group({
			type: [null, [Validators.required]],
			maxDevices: [null, [Validators.required]],
			startDate: [null, [Validators.required]],
			endDate: [null, [Validators.required]],
		});
	}

	onDurationChange(index: number) {
		this.selectedDuration = index;
		if (this.durations[this.selectedDuration].value === 'custom') this.accountSubscriptionForm.get('endDate').enable();
		else {
			this.accountSubscriptionForm.get('endDate').disable();
			this.setEndDate(this.accountSubscriptionForm.get('startDate').value, this.durations[this.selectedDuration].value);
		}
	}

	getDuration(startDate: Date, endDate: Date) {
		if (moment.utc(startDate).add(60, 'days').isSame(moment.utc(endDate), 'day')) return 0;
		if (moment.utc(startDate).add(90, 'days').isSame(moment.utc(endDate), 'day')) return 1;
		if (moment.utc(startDate).add(1, 'years').isSame(moment.utc(endDate), 'day')) return 2;
		if (moment.utc(startDate).add(3, 'years').isSame(moment.utc(endDate), 'day')) return 3;
		if (moment.utc(startDate).add(5, 'years').isSame(moment.utc(endDate), 'day')) return 4;
		return 5;
	}

	setEndDate(startDate: Date, duration: string) {
		if (duration !== 'custom') {
			this.accountSubscriptionForm.get('endDate').setValue(
				moment
					.utc(startDate)
					.add(duration.substring(1), duration.startsWith('d') ? 'days' : 'years')
					.toDate()
			);
			this.accountSubscriptionForm.updateValueAndValidity();
		}
	}

	submitForm() {
		if (this.accountSubscriptionForm.valid) this.submit.emit();
	}

	getDurations(isTrial: boolean = false) {
		if (isTrial)
			return [
				{ value: 'd60', label: '60 days' },
				{ value: 'd90', label: '90 days' },
			];
		else
			return [
				{ value: 'd60', label: '60 days' },
				{ value: 'd90', label: '90 days' },
				{ value: 'y1', label: '1 year' },
				{ value: 'y3', label: '3 years' },
				{ value: 'y5', label: '5 years' },
				{ value: 'custom', label: 'custom' },
			];
	}

	getStatusIcon(accountSubscriptionStatus: AccountSubscriptionStatus): string {
		//if (accountSubscriptionStatus === AccountSubscriptionStatus.Inactive)
		//if (accountSubscriptionStatus === AccountSubscriptionStatus.Pending)
		if (accountSubscriptionStatus === AccountSubscriptionStatus.Active) return 'check-circle';
		if (accountSubscriptionStatus === AccountSubscriptionStatus.Expiring) return 'exclamation-circle';
		if (accountSubscriptionStatus === AccountSubscriptionStatus.Expired) return 'times-circle';
		return 'circle';
	}
}
