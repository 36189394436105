import { Injectable } from '@angular/core';
import { CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { AuditLog, SetupRegistrationResult } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SetupService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAuditLogs: (searchFilter?: SearchFilter): Observable<SearchFilterResult<AuditLog>> => {
				return this.cloudApiService.auditLogs.getAuditLogs(searchFilter);
			},
            tokenRequiresCredentials: (token: string): Observable<boolean> => {
                return this.cloudApiService.setup.tokenRequiresCredentials(token);
            },
            registerToken: (token: string, email: string, password: string): Observable<SetupRegistrationResult> => {
                return this.cloudApiService.setup.registerToken(token, email, password);
            },
            getBenefitsProvider: (): Observable<string> => {
                return this.cloudApiService.setup.getBenefitsProvider();
            },
            getBenefitsCustomer: (): Observable<string> => {
                return this.cloudApiService.setup.getBenefitsCustomer();
            }
		};
	}
}