import { Component, Input, SimpleChanges } from '@angular/core';
import { AccountStatus } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-account-status',
	templateUrl: './cell-account-status.component.html',
	styleUrls: ['./cell-account-status.component.scss'],
})
export class CellAccountStatusComponent {
	@Input() status: AccountStatus;
	@Input() tooltip: string;
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	icon: string;
	color: string;
	text: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = 'times-circle';
		let color: string = 'status-red';
		let text: string = AccountStatus[AccountStatus.Disabled];

		if (this.status === AccountStatus.Pending) {
			icon = 'exclamation-circle';
			color = 'status-orange';
			text = AccountStatus[AccountStatus.Pending];
		} else if (this.status === AccountStatus.Enabled) {
			icon = 'check-circle';
			color = 'status-green';
			text = AccountStatus[AccountStatus.Enabled];
		} else if (this.status === AccountStatus.Offline) {
			icon = 'stop-circle';
			color = 'status-black';
			text = AccountStatus[AccountStatus.Offline];
		}

		this.icon = icon;
		this.color = color;
		this.text = text;
	}
}
