<div class="confirm-container">
	<div class="modal-header">
		<h4 class="modal-title">
			<fa-icon [icon]="configuration.icon ? configuration.icon : 'question-circle'"></fa-icon>
			<span class="ps-2">{{ configuration.title }}</span>
		</h4>
		<button type="button" class="btn-close" (click)="close()"></button>
	</div>
	<div class="modal-body">
		<p *ngIf="configuration.text != null">{{ configuration.text }}</p>
		<div *ngIf="configuration.html != null" [innerHTML]="configuration.html"></div>
		<form [formGroup]="passphraseForm" *ngIf="passphraseRequired === true" novalidate>
			<div class="row">
				<div class="col">
					<div>
						<div class="mb-3 col">
							<label class="form-label" for="passphrase">
								{{ configuration.passphraseTitle || 'Passphrase' }}
								<span class="text-primary">*</span>
							</label>
							<input
								type="text"
								class="form-control"
								id="passphrase"
								formControlName="passphrase"
								[ngClass]="{
									'is-invalid': isInvalid('passphrase')
								}"
								required
								[autofocusIf]="passphraseRequired === true"
								autocomplete="off" />
							<div *ngIf="hasErrors('passphrase')" class="invalid-feedback">
								<div *ngIf="hasError('passphrase', 'required')">
									{{ configuration.passphraseTitle || 'Passphrase' }}
									is required.
								</div>
								<div *ngIf="hasError('passphrase', 'mustequal')">
									{{ configuration.passphraseTitle || 'Passphrase' }}
									is not correct.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button
			*ngIf="configuration.hideCancelButton !== true"
			type="button"
			class="btn btn-{{ configuration.cancelButtonColor ? configuration.cancelButtonColor : 'secondary' }} float-end"
			(click)="no()"
			[autofocusIf]="passphraseRequired !== true">
			<fa-icon [icon]="configuration.cancelButtonIcon ? configuration.cancelButtonIcon : 'times'"></fa-icon>
			{{ configuration.cancelButtonText ? configuration.cancelButtonText : 'No' }}
		</button>
		<button
			type="button"
			class="btn btn-{{ configuration.confirmButtonColor ? configuration.confirmButtonColor : 'primary' }} float-end"
			[disabled]="!isValid"
			(click)="yes()">
			<fa-icon [icon]="configuration.confirmButtonIcon ? configuration.confirmButtonIcon : 'check'"></fa-icon>
			{{ configuration.confirmButtonText ? configuration.confirmButtonText : 'Yes' }}
		</button>
	</div>
</div>
