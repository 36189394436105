import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSearchConfiguration, SearchFilter } from '@razberi-ui/api/cloud-api';
import { ManagedDevice, ManagedDeviceType, Network, Location, Tag, ManagedDeviceStatus, ManagedDeviceTypeAttributes } from '@razberi-ui/core/data-types';
import {
	CoreSearchItem,
	PageSidePanelService,
	TableColumnSetting,
	TableItemSelectMode,
	TableServerSidePagingConfig,
	TableSettings,
	UtilitiesService,
} from '@razberi-ui/shared';
import { Subject, Subscription } from 'rxjs';
import { NetworkService } from '../../../services/network.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-network-link-detail-modal',
	templateUrl: './network-link-detail-modal.component.html',
	styleUrls: ['./network-link-detail-modal.component.scss'],
})
export class NetworkLinkDetailModalComponent {
	@Input() accountId: number;
	@Input() network: Network;
	@Input() sourceDevice: ManagedDevice;

	items: CoreSearchItem<any>[];
	selectedItems: CoreSearchItem<any>[];

	selectedDevices: ManagedDevice[];
	subscriptions: Subscription = new Subscription();
	tableSettings: TableSettings;
	availableDevices: ManagedDevice[];
	availableNeighbordDevices: ManagedDevice[];
	availableRemoteDevices: ManagedDevice[];
	pageNumber: number = 1;
	pageSize: number = 10;
	totalCount: number = 0;
	initialSearchFilter: SearchFilter;
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	showFilters: boolean = false;
	isValid: boolean = false;
	searchTerm$: Subject<string> = new Subject<string>();
	searchTerm: string;
	isInitialized: boolean = false;
	isLoadingDevices: boolean = false;
	deviceTypeOptions: any = ManagedDeviceType;
	locations: Location[];
	tags: Tag[];

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly networkService: NetworkService,
		private readonly pageSidePanelService: PageSidePanelService,
		readonly utils: UtilitiesService
	) {}

	ngOnInit() {
		this.initialSearchFilter = { accounts: [{ accountId: this.accountId }] };
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showDateRange: false,
			showAccounts: false,
			forSubscribedAccounts: true,
			showManagedDeviceTypes: true,
			includeManagedDeviceAgentTypes: true,
			showManagedDeviceStatuses: true,
			includeManagedDeviceErrorStatus: false,
			showNetworkLinkStatuses: this.sourceDevice != null,
		};
		this.loadTable();
		this.getManagedDevices();
	}

	loadTable() {
		this.tableSettings = {
			itemSelectMode: this.sourceDevice == null ? TableItemSelectMode.Single : TableItemSelectMode.Multi,
			checkboxAction: (data) => {
				this.toggleSelectedDevice(data.checked, data.tableItem);
			},
			useAdvancedSearch: true,
			useEdit: true,
			usePageSize: true,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: true,
			serverSidePagingAndFilterConfig: { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'managedDeviceId',
			emptyResultMessage: 'No devices available.',
			columnConfig: [
				{
					primaryKey: 'status',
					header: '',
					cellType: 'device-status',
					transform: (value, tableItem) => {
						return {
							deviceType: 'managed-device',
							status: value,
							showText: false,
						};
					},
				},
				{
					primaryKey: 'name',
					header: 'Name',
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return { text: tableItem.type === ManagedDeviceType.Agent ? tableItem?.unit?.name ?? value ?? '---' : value ?? '---' };
					},
					clickHandlerFunction: (params) => {
						this.viewDeviceDetails(params);
					},
				},
				{
					primaryKey: 'type',
					header: 'Type',
					transform: (value, tableItem) => {
						return ManagedDeviceTypeAttributes.get(value)?.label;
					},
				},
				{ primaryKey: 'serialNumber', header: 'Serial' },
				{
					primaryKey: 'macAddress',
					header: 'MAC',
					transform: (value, tableItem) => {
						return this.utils.formatters.asMacAddress(tableItem.macAddress);
					},
				},
				{
					primaryKey: 'suppliedSourceLinkStatus',
					header: 'Link Status',
					cellType: 'network-link-status',
					disableSort: true,
					transform: (value, tableItem) => {
						return this.sourceDevice != null
							? {
									networkLinkStatus: tableItem.suppliedSourceLinkStatus,
									networkLinkType: tableItem.suppliedSourceLinkType,
							  }
							: null;
					},
				},
				{
					primaryKey: 'unit',
					header: 'Managed By',
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return { text: tableItem.type === ManagedDeviceType.Agent ? '---' : value?.name || '--' };
					},
					clickHandlerFunction: (params) => this.viewDeviceDetails(params),
				},
			],
			headerButtons: [],
			stickyHeaderButtons: [],
			editActions: (tableItem) => {
				return [];
			},
		};

		if (this.sourceDevice != null) {
			const selectIdx: number = this.tableSettings.columnConfig.findIndex(
				(columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'managedDeviceId'
			);
			if (selectIdx >= 0) this.tableSettings.columnConfig.splice(selectIdx, 1);
		}
	}

	getManagedDevices() {
		this.isLoadingDevices = true;

		const filter: SearchFilter = {
			pageNumber: this.pageNumber,
			pageSize: this.pageSize,
			sortAsc: true, //this.sortAsc,
			accounts: [{ accountId: this.accountId }],
			searchTerm: this.searchTerm,
			...this.searchFilter,
		};

		if (
			filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Offline) == true &&
			filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Error) != true
		) {
			filter.managedDeviceStatuses.push(ManagedDeviceStatus.Error);
		}

		this.networkService.api.getNetworkAvailableDevices(filter, this.network, this.sourceDevice).subscribe((r) => {
			this.availableDevices = r.results;
			this.pageNumber = r.pageNumber;
			this.pageSize = r.pageSize;
			this.tableSettings.serverSidePagingAndFilterConfig = {
				pageNumber: r.pageNumber,
				pageSize: r.pageSize,
				totalCount: r.totalCount,
			};
			this.isInitialized = true;
			this.isLoadingDevices = false;
		});
	}

	onFilterSearch(searchFilter) {
		this.searchFilter = searchFilter;
		this.getManagedDevices();
	}

	toggleSelectedDevice(selected: boolean, device: ManagedDevice) {
		this.selectedDevices = this.selectedDevices || [];
		if (this.sourceDevice == null) {
			if (selected === true) this.selectedDevices = [device];
			else this.selectedDevices = [];
		} else {
			if (
				selected === true &&
				this.selectedDevices?.some((sd) => sd.unitKey === device.unitKey && sd.managedDeviceKey === device.managedDeviceKey) != true
			)
				this.selectedDevices.push(device);
			else if (
				selected != true &&
				this.selectedDevices?.some((sd) => sd.unitKey === device.unitKey && sd.managedDeviceKey === device.managedDeviceKey) === true
			)
				this.selectedDevices = this.selectedDevices?.filter(
					(sd) => (sd.unitKey === device.unitKey && sd.managedDeviceKey === device.managedDeviceKey) != true
				);
		}

		this.isValid = this.selectedDevices?.length > 0;
	}

	toggleSelecedtDevices(devices: ManagedDevice[]) {
		this.selectedDevices = devices || [];
		this.isValid = this.selectedDevices?.length > 0;
	}

	save() {
		if (this.isValid === true) {
			this.selectedDevices = this.selectedDevices || [];
			if (this.selectedDevices.length > 0) {
				if (this.sourceDevice == null) {
					const first = this.selectedDevices.shift();
					if (first != null) this.networkService.helpers.setNetworkRoot(this.network, first);
				} else {
					this.selectedDevices.map((d) => {
						this.networkService.helpers.addNetworkLink(this.network, this.sourceDevice, d, d.suppliedSourceLinkType, d.suppliedSourceLinkStatus);
					});
				}
			}

			this.activeModal.close(this.network);
		}
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getManagedDevices();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getManagedDevices();
	}

	viewDeviceDetails(managedDevice: ManagedDevice) {
		managedDevice.type === ManagedDeviceType.Agent
			? this.openUnitSidePanel(managedDevice, 'details')
			: this.openManagedDeviceSidePanel(managedDevice, 'details');
	}

	viewDeviceAlerts(managedDevice: ManagedDevice) {
		managedDevice.type === ManagedDeviceType.Agent
			? this.openUnitSidePanel(managedDevice, 'alerts')
			: this.openManagedDeviceSidePanel(managedDevice, 'alerts');
	}

	openUnitSidePanel(managedDevice: ManagedDevice, activeTabKey: string) {
		this.pageSidePanelService.helpers.openUnitSidePanel(
			managedDevice.unit?.name ?? '--',
			activeTabKey,
			managedDevice.accountId,
			managedDevice.unitId,
			false,
			null
		);
	}

	openManagedDeviceSidePanel(managedDevice: ManagedDevice, activeTabKey: string) {
		this.pageSidePanelService.helpers.openManagedDeviceSidePanelById(
			managedDevice.name,
			activeTabKey,
			managedDevice.accountId,
			managedDevice.unitId,
			managedDevice.managedDeviceId
		);
	}

	getTypeLabel(type: ManagedDeviceType) {
		return ManagedDeviceTypeAttributes.get(type)?.label ?? '--';
	}
}
