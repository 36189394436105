<div class="row justify-content-center">
	<div class="col-8 px-5">
		<div class="row mb-5">
			<div class="col">
				<shared-image-uploader [config]="avatarConfig"></shared-image-uploader>
			</div>
		</div>
		<app-root-monitor-cloud-user-form
			[isCurrentUser]="true"
			[isPrimaryUser]="isPrimaryUser"
			[user]="user"
			(isValid)="onIsValid($event)"
			(changes)="onChanges($event)"
			(submit)="onSubmit()"></app-root-monitor-cloud-user-form>
	</div>
</div>
