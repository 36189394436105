import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-shared-monitor-common-dashboard-carousel',
	templateUrl: './dashboard-carousel.component.html',
	styleUrls: ['./dashboard-carousel.component.scss'],
})
export class DashboardCarouselComponent implements OnInit {
	config = {
		slidesToShow: 5,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		infinite: false,
		speed: 300,
		prevArrow: "<img class='slick-prev' src='assets/core/images/svg/angle-left.svg'>",
		nextArrow: "<img class='slick-next' src='assets/core/images/svg/angle-right.svg'>",
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1250,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};

	showSlick: boolean = true;

	constructor(private router: Router) {}

	ngOnInit() {}

	navigate(path) {
		this.router.navigate(['/dashboard', path]);
	}
}
