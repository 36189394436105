import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Alert, AlertStatus } from '@razberi-ui/core/data-types';
import { AlertMessageService, DataPagingStore, UtilitiesService } from '@razberi-ui/shared';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-alert-side-panel',
	templateUrl: './alert-side-panel.component.html',
	styleUrls: ['./alert-side-panel.component.scss'],
})
export class AlertSidePanelComponent {
	@Input() accountId: number;
	@Input() alertItemsFromServer: Alert[];

	@Output() daysChanged = new EventEmitter<string>();
	@Output() updateAlerts = new EventEmitter<any>();

	// @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	subscriptions: Subscription = new Subscription();
	dataPagingStore: DataPagingStore = new DataPagingStore();

	get pagingStatus() {
		return this.utils.helpers.getPagingStatus(this.dataPagingStore.data.pageIndex, this.dataPagingStore.data.pageSize, this.dataPagingStore.data.total);
	}

	constructor(
		private readonly alertMessageService: AlertMessageService,
		private readonly alertsService: AlertService,
		private readonly utils: UtilitiesService
	) {}

	ngOnInit() {
		this.initDataPaginStore();
	}

	initDataPaginStore() {
		const matchFunc = (item: Alert, term: string) => {
			return (
				item.alertTypeName.toLowerCase().includes(term.toLowerCase()) ||
				item.message.toLowerCase().includes(term.toLowerCase()) ||
				item.unitName.toLowerCase().includes(term.toLowerCase()) ||
				item.serialNumber.toLowerCase().includes(term.toLowerCase()) ||
				AlertStatus[item.status].toLowerCase().includes(term.toLowerCase())
			);
		};

		this.dataPagingStore.helpers.init('alertId', matchFunc);
		this.refreshAlertsFromServer();
	}

	ngOnChanges() {
		this.refreshAlertsFromServer();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	// onSort({ column, direction }: SortEvent) {
	// 	// resetting other headers
	// 	this.headers.forEach((header) => {
	// 		if (header.sortable !== column) header.direction = '';
	// 	});

	// 	this.dataPagingStore.sortColumn = column;
	// 	this.dataPagingStore.sortDirection = direction;
	// }

	refreshAlertsFromServer() {
		const alertItems = this.alertItemsFromServer?.filter((alert) => alert.status != AlertStatus.Closed) ?? [];
		this.dataPagingStore.helpers.reset();
		this.dataPagingStore.helpers.load(alertItems);
	}

	acknowledgeSelectedAlerts() {
		let selectedAlertItems = this.dataPagingStore.data.selectedItems
			?.filter((alert) => alert.status !== AlertStatus.Acknowledged)
			.map((alert) => alert.alertId);
		if (selectedAlertItems.length === 0) return;
		this.alertsService.api.acknowledgeAlerts(this.accountId, selectedAlertItems).subscribe({
			next: (result) => {
				this.alertMessageService.success('Acknowledged alerts.');
				this.dataPagingStore.helpers.reset();
				this.updateAlerts.emit(true);
				this.refreshAlertsFromServer();
			},
			error: (error) => {
				this.alertMessageService.error('Error acknowledging alerts.', error);
			},
		});
	}

	closeSelectedAlerts() {
		let selectedAlertItems = this.dataPagingStore.data.selectedItems?.map((alert) => alert.alertId);
		if (!selectedAlertItems.length) return;
		this.alertsService.api.closeAlerts(this.accountId, selectedAlertItems).subscribe({
			next: (result) => {
				this.alertMessageService.success('Closed alerts.');
				this.dataPagingStore.helpers.reset();
				this.updateAlerts.emit(true);
				this.refreshAlertsFromServer();
			},
			error: (error) => {
				this.alertMessageService.error('Error closing alerts.', error);
			},
		});
	}
}
