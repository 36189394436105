<div class="d-flex align-items-center">
	<ng-container *ngFor="let tag of updateTags | slice: 0:displayCount">
		<div
			[ngbPopover]="popoverMain"
			triggers="mouseenter:mouseleave"
			[popoverTitle]="tag.title"
			container="body"
			class="d-inline-flex context-pill pill-{{ getCssContext(tag.referenceTypeId) }} me-1">
			{{ tag.title }}
		</div>
		<ng-template #popoverMain><div [innerHTML]="tag.description"></div></ng-template>
	</ng-container>
	<div ngbDropdown *ngIf="updateTags?.length > displayCount">
		<span ngbDropdownToggle class="tag-cell-drop ps-2 pe-2" (click)="$event.stopPropagation()">...</span>
		<div class="p-2" ngbDropdownMenu>
			<ng-container *ngFor="let tag of updateTags | slice: displayCount">
				<div [ngbPopover]="popoverSub" triggers="mouseenter:mouseleave" [popoverTitle]="tag.title" container="body" class="mb-1 me-1">
					{{ tag.title }}
				</div>
				<ng-template #popoverSub><div [innerHTML]="tag.description"></div></ng-template>
			</ng-container>
		</div>
	</div>
	<div *ngIf="(updateTags?.length || 0) === 0">--</div>
</div>
