import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function DateValid(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors => {
		if (control && control.dirty && control.value) {
			const parsed = new Date(control.value);
			if (parsed instanceof Date !== true || isNaN(parsed.getTime()) === true) {
				control.setErrors({ 'date-valid': control.value });
				return { 'date-valid': control.value };
			}
		}

		return null;
	};
}
