<app-root-monitor-cloud-account-accordian
	*ngIf="isInitialized === true && groupByAccount === true"
	[panelTemplate]="accountAlerts"
	[requireSubscription]="true"
	[refresh$]="refresh$"
	(update)="onUpdate()"
	dataSource="alerts">
	<ng-template #accountAlerts let-account let-hasSubscription="hasSubscription">
		<app-root-monitor-cloud-alert-tabs
			*ngIf="account != null"
			[accountIds]="[account.accountId]"
			[showDownloadCsv]="hasSubscription"></app-root-monitor-cloud-alert-tabs>
	</ng-template>
</app-root-monitor-cloud-account-accordian>
<app-root-monitor-cloud-alert-tabs
	*ngIf="isInitialized === true && groupByAccount !== true"
	[showAccount]="true"
	[refresh$]="refresh$"
	(update)="onUpdate()"></app-root-monitor-cloud-alert-tabs>
