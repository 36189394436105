import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WindowsUpdateManageTaskParams } from '@razberi-ui/core/data-types';
import { CoreSearchItem } from '@razberi-ui/shared';
import { Subscription, distinctUntilChanged } from 'rxjs';

interface ListItem {
	name: string;
	value: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-form-parameters-win-update',
	templateUrl: './win-update.component.html',
	styleUrls: ['./win-update.component.scss'],
})
export class WinUpdateComponent {
	@Input() accountId: number;
	@Input() parameters: string;
	@Input() readonly: boolean;
	@Output() parametersChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

	initialized: boolean = false;
	subscriptions: Subscription = new Subscription();
	deserialized: WindowsUpdateManageTaskParams;
	parametersForm: UntypedFormGroup;

	categoryChoices: CoreSearchItem<ListItem>[];
	selectedCategories: CoreSearchItem<ListItem>[];
	severityLevelChoices: CoreSearchItem<ListItem>[];

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.init();
	}

	init() {
		if (this.initialized !== true) {
			this.buildForm();
			this.subscriptions.add(
				this.parametersForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
					this.emitValidity();
				})
			);
			this.subscriptions.add(
				this.parametersForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
					this.parametersChanged.emit(JSON.stringify(value));
					this.emitValidity();
				})
			);
			this.initialized = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.init();
		this.deserialized = JSON.parse(this.parameters);
		this.parametersForm.reset();
		this.selectedCategories = [];

		if (this.parameters?.length > 0) this.parametersForm.patchValue(this.deserialized);

		if (this.deserialized?.allowReboot == null) this.parametersForm.patchValue({ allowReboot: true });

		if (this.deserialized?.categories?.length > 0) {
			this.deserialized?.categories?.forEach((c) => {
				const found = this.categoryChoices.find((item) => item.value.value === c);
				if (found != null) this.selectedCategories.push(found);
			});
		}

		this.emitValidity();
	}

	onCategoriesSelect(selectedItems: CoreSearchItem<ListItem>[]) {
		const categoryValues: string[] = selectedItems.map((categoryItem: CoreSearchItem<ListItem>) => categoryItem.value.value);
		this.parametersForm.patchValue({ categories: categoryValues });
		this.emitValidity();
	}

	onAllowRebootChange(value: boolean) {
		//just need to kick off emit
		this.emitValidity();
	}

	getReadOnlyValue(field: string) {
		if (field == 'categories') {
			const availableCategories = this.getCategories();
			const selectedCategories =
				this.parametersForm
					.get('categories')
					.value?.toString()
					?.split(',')
					?.map((s) => availableCategories.find((ac) => ac.value === s)?.name || '') || '';
			return selectedCategories || '';
		} else {
			return this.parametersForm.get(field).value != null ? this.parametersForm.get(field).value.toString() : '';
		}
	}

	buildForm() {
		if (this.categoryChoices == null)
			this.categoryChoices = this.getCategories().map((item: ListItem) => {
				return { value: item, label: item.name };
			});

		if (this.parametersForm != null) return;

		this.parametersForm = this.formBuilder.group({
			categories: [null],
			excludeKBs: [null, [Validators.pattern('(KB){0,1}[0-9]{1,7}(?:,[ ]?(KB){0,1}[0-9]{1,7})*')]],
			allowReboot: [null],
		});
	}

	emitValidity() {
		this.valid.emit(this.parametersForm.valid === true);
	}

	launchWinUpdatePage() {
		let searchTerms = '';
		const availableCategories = this.getCategories();
		const selectedCategories =
			this.parametersForm
				.get('categories')
				.value?.toString()
				?.split(',')
				?.map((s) => availableCategories.find((ac) => ac.value === s)?.name || '') || '';
		searchTerms += selectedCategories || '';
		searchTerms += ' ' + (this.parametersForm.get('excludeKBs').value || '');
		searchTerms = searchTerms.replace(/,/g, ' ');
		var url = `https://www.catalog.update.microsoft.com/Search.aspx?q=${searchTerms}`;
		window.open(url, '_blank');
	}

	getCategories(): ListItem[] {
		let categories: ListItem[] = [];
		categories.push({ name: 'Critical Updates', value: 'CriticalUpdates' });
		categories.push({ name: 'Security Updates', value: 'SecurityUpdates' });
		return categories;
	}
}
