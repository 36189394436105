import { Injectable } from '@angular/core';
import { CloudApiService } from '@razberi-ui/api/cloud-api';
import { Location } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LocationService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getLocations: (accountId: number): Observable<Location[]> => {
				return this.cloudApiService.locations.getLocations(accountId);
			},
			getLocation: (accountId: number, locationId: number): Observable<Location> => {
				return this.cloudApiService.locations.getLocation(accountId, locationId);
			},
			createLocation: (accountId: number, location: Location): Observable<Location> => {
				return this.cloudApiService.locations.createLocation(accountId, location);
			},
			updateLocation: (accountId: number, locationId: number, location: Location): Observable<void> => {
				return this.cloudApiService.locations.updateLocation(accountId, locationId, location);
			},
			deleteLocation: (accountId: number, locationId: number): Observable<void> => {
				return this.cloudApiService.locations.deleteLocation(accountId, locationId);
			},
			deleteLocations: (accountId: number, locationIds: number[]): Observable<void> => {
				return this.cloudApiService.locations.deleteLocations(accountId, locationIds);
			},
		};
	}
}
