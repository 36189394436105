<div *ngIf="settingsService.data.settings?.cloudAgent == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.cloudAgent != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="processInterval" class="form-label">Process Interval</label>
		<div ngbTooltip="How often accounts are processed." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="processInterval" id="processInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.processInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="provisionInterval" class="form-label">Provision Interval</label>
		<div ngbTooltip="How often accounts are provisioned." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="provisionInterval" id="provisionInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.provisionInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="reportInterval" class="form-label">Report Interval</label>
		<div ngbTooltip="How often reports are generated." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="reportInterval" id="reportInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.reportInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="pruneInterval" class="form-label">Prune Interval</label>
		<div ngbTooltip="How often old data is pruned." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="pruneInterval" id="pruneInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.pruneInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="serverOfflineThreshold" class="form-label">Server Offline Threshold</label>
		<div ngbTooltip="How long a server must be offline before an alert is generated."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="serverOfflineThreshold" id="serverOfflineThreshold" />
		<shared-input-validation-messages [control]="settingsForm.controls.serverOfflineThreshold"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="primaryInstance" class="form-label">Primary Instance</label>
		<div ngbTooltip="A regex matching the primary instance hostname." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="primaryInstance" id="primaryInstance" />
		<shared-input-validation-messages [control]="settingsForm.controls.primaryInstance"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input class="form-check-input" type="checkbox" formControlName="performBackups" id="performBackups" />
			<label for="performBackups" class="form-check-label">Perform Backups</label>
			<div ngbTooltip="Whether or not to perform database backups when provisioning accounts."
				 placement="right"
				 container="body"
				 class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
		<div class="mb-3 col-12">
			<label for="accountDatabaseLogins" class="form-label">Account Database Logins</label>
			<div ngbTooltip="The semicolon-delimited database logins to grant access to account databases."
				 placement="right"
				 container="body"
				 class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
			<input type="text" class="form-control" formControlName="accountDatabaseLogins" id="accountDatabaseLogins" />
			<shared-input-validation-messages [control]="settingsForm.controls.primaryInstance"></shared-input-validation-messages>
		</div>
	</div>
</form>
