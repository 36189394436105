import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigKey } from '../data-types/app-config-key.model';
import { AppConfig } from '../data-types/app-config.model';
import { AlertMessageService } from '../services/alert-message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private readonly logger: NGXLogger, private readonly appConfig: AppConfig, private readonly alertMessageService: AlertMessageService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (req.headers.has('overrideErrorHandling') && req.headers.get('overrideErrorHandling') === 'true') throw error;

				this.logger.error('Error intercepted.', error);

				// handle blob errors in separate inerceptor
				if (error.error instanceof Blob && error.error.type === 'application/json') throw error;

				let errorMessage = '';
				if (error.status === 0) {
					errorMessage =
						this.appConfig.key === AppConfigKey.MonitorCloud
							? 'Razberi MonitorCloud is unavailable. Try again soon.'
							: 'Razberi MonitorAgent is unavailable. Ensure the Windows service is running and try again.';
				} else if (error.error?.Message !== null) {
					errorMessage = `${error.error.Message}`;
				} else if (error.message?.length > 0) {
					errorMessage = `${error.status !== null ? 'Error Code: ' + error.status + '; ' : ''}Message: ${error.message}`;
				} else {
					errorMessage = 'An unknown error occurred.';
				}

				this.alertMessageService.error(errorMessage);

				throw error;
			})
		);
	}
}
