import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, AccountSubscriptionType, AccountType, AlertStatus, Network, UserRole } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, NavService, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { BehaviorSubject, Subscription, distinctUntilChanged, forkJoin, map, of, switchMap, take } from 'rxjs';
import { NetworkService } from '../../../services/network.service';
import { AccountService } from '../../../services/account.service';
import { AlertService } from '../../../services/alert.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { AuthService, SearchFilter } from '@razberi-ui/api/cloud-api';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-network-detail',
	templateUrl: './network-detail.component.html',
	styleUrls: ['./network-detail.component.scss'],
})
export class NetworkDetailComponent {
	subscriptions: Subscription = new Subscription();
	accountId: number;
	networkId: number;
	network: Network;
	alertsTotal: number = 0;
	devicesTotal: number = 0;
	originalNetwork: Network;
	networkIsModified: boolean;
	networkForm: UntypedFormGroup;
	networkFormIsValid: boolean = false;
	availableAccounts: Account[] = [];
	editNetworkName: boolean = false;
	isGlobalOrProviderAccount: boolean = false;
	canManageNetworks: boolean = false;
	canSave$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	canReset$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	isInitialized: boolean = false;
	showAlerts: boolean = false;
	showTooltip: boolean = false;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly pageConfigService: PageConfigService,
		private readonly alertMessageService: AlertMessageService,
		private readonly navService: NavService,
		private readonly authService: AuthService,
		private readonly confirmService: ConfirmService,
		private readonly networkService: NetworkService,
		private readonly accountService: AccountService,
		private readonly alertService: AlertService,
		private readonly subscriptionService: SubscriptionService
	) {}

	ngOnInit(): void {
		this.canManageNetworks = this.authService.helpers.userHasRole(UserRole.Administrator);
		this.isGlobalOrProviderAccount = [AccountType.Global, AccountType.Provider].includes(this.authService.data.account.type);
		this.buildNetworkForm();
		this.activatedRoute.params.subscribe((params) => {
			this.accountId = params['accountId'] != null ? Number(params['accountId']) : 0;
			if (this.accountId === 0) this.accountId = this.authService.data.account.accountId;
			this.showTooltip = this.isGlobalOrProviderAccount === true;
			this.networkId = params['networkId'] != null ? Number(params['networkId']) : 0;
			this.loadNetwork();
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.navService.helpers.removeTitlePlaceholder('{{name}}');
	}

	buildNetworkForm() {
		this.networkForm = new UntypedFormGroup({
			networkId: new UntypedFormControl(null),
			accountId: new UntypedFormControl(this.accountId, [Validators.required]),
			name: new UntypedFormControl(null, [Validators.required]),
			rootUnitKey: new UntypedFormControl(null, [Validators.required]),
			rootDeviceKey: new UntypedFormControl(null, [Validators.required]),
			locationId: new UntypedFormControl([]),
			networkLinks: new UntypedFormControl([]),
		});

		this.subscriptions.add(
			this.networkForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.networkFormIsValid = status == 'VALID';
				this.checkCanSave();
			})
		);
		this.subscriptions.add(
			this.networkForm.valueChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.checkCanSave();
			})
		);
	}

	loadNetwork() {
		this.isInitialized = false;
		if (this.networkId === 0) {
			if (this.canManageNetworks !== true) {
				this.alertMessageService.warning('You are not permitted to manage networks.', { keepAfterRouteChange: true });
				this.router.navigate(['networks']);
			}

			if ((this.availableAccounts?.length || 0) == 0) {
				this.getAvailableAccounts().subscribe((_) => {
					if (this.availableAccounts.length > 0) {
						this.accountId = this.availableAccounts[0].accountId;
						this.networkForm.controls.accountId.setValue(this.accountId);
						this.configureToolbar();
					}
					this.network = this.networkService.helpers.getDefaultNetwork(this.accountId);
					this.originalNetwork = null;
					this.alertsTotal = 0;
					this.devicesTotal = 0;
					this.networkIsModified = false;
					this.processNetwork();
				});
			}
		} else {
			forkJoin({
				network: this.networkService.api.getNetwork(this.accountId, this.networkId),
			})
				.pipe(
					take(1),
					switchMap((result) => {
						let { network } = result;
						this.network = network;
						this.devicesTotal = network?.managedDevices?.length ?? 0;
						this.originalNetwork = JSON.parse(JSON.stringify(this.network));
						this.networkIsModified = false;
						this.processNetwork();
						return of(result);
					}),
					switchMap((result) => {
						if (result.network?.networkId > 0) {
							const preloadFilter: SearchFilter = {
								alertStatuses: [AlertStatus.New, AlertStatus.Acknowledged],
								pageNumber: 1,
								pageSize: 1,
							};
							return this.alertService.api.getNetworkAlerts(
								this.accountId,
								this.networkId,
								result.network?.managedDevices?.map((d) => d.managedDeviceKey),
								preloadFilter,
								30
							);
						}
					}),
					map((result) => {
						this.alertsTotal = result?.totalCount ?? 0;
					})
				)
				.subscribe({
					next: (_) => {},
					error: (error) => {
						this.alertMessageService.error('Error getting network details.', error);
					},
				});
		}
	}

	processNetwork() {
		this.navService.helpers.addTitlePlaceholder({ key: '{{name}}', value: this.network?.name ?? '--' });
		this.configureToolbar();
		this.networkForm.patchValue(this.network);
		this.editNetworkName = false;
		this.checkCanSave();
		this.isInitialized = true;
	}

	configureToolbar() {
		const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };

		if (this.canManageNetworks === true) {
			if (this.availableAccounts.length > 0 || this.networkId > 0) {
				c.buttons.push({
					text: 'Commit Changes',
					icon: 'check-circle',
					color: 'primary',
					isEnabled$: this.canSave$.asObservable(),
					click: () => {
						this.saveNetwork();
					},
				});
			}

			if (this.network?.networkId > 0) {
				c.buttons.push({
					text: 'Reset',
					icon: 'redo',
					color: 'light',
					isEnabled$: this.canReset$.asObservable(),
					click: () => {
						this.resetClicked();
					},
				});
				c.buttons.push({
					text: 'Delete',
					icon: 'trash',
					color: 'danger',
					click: () => {
						this.deleteNetwork();
					},
				});
			}
		}

		c.buttons.push({
			text: 'View Networks',
			icon: 'network-wired',
			color: 'light',
			click: () => {
				this.router.navigate(['networks']);
			},
		});

		this.pageConfigService.data.toolbarConfig = c;
	}

	resetClicked() {
		this.confirmService
			.confirm({
				title: 'Confirm Reset Network',
				html: 'Any unsaved changes to the network will be cancelled.  Would you like to continue?',
				icon: 'exclamation-triangle',
				confirmButtonText: 'Reset',
				cancelButtonText: 'Cancel',
				confirmButtonColor: 'primary',
			})
			.then(
				(result) => {
					if (this.networkId === 0) {
						this.accountId = this.authService.data.account.accountId;
					}

					this.loadNetwork();
				},
				(_) => {}
			);
	}

	networkChanged(network: Network) {
		network.name = this.networkForm.value.name;
		this.networkForm.patchValue(network);
		this.checkCanSave();
	}

	checkCanSave() {
		this.networkIsModified = this.networkService.helpers.checkNetworkModified(this.networkForm.value, this.originalNetwork);
		this.canReset$.next(this.networkIsModified);
		const isValidAndModified = this.networkFormIsValid === true && this.networkIsModified === true;
		this.canSave$.next(isValidAndModified);
	}

	resetEditNetworkName() {
		this.editNetworkName = false;
		this.networkForm.controls.name.setValue(this.originalNetwork.name);
	}

	getAvailableAccounts() {
		return this.accountService.api.getAccountsData().pipe(
			map((accounts) => {
				this.availableAccounts = accounts.filter((acc) =>
					this.subscriptionService.helpers.isTypeAllowedAndStatusValid(acc.subscription?.type, acc.subscription?.status, [
						AccountSubscriptionType.Manage,
						AccountSubscriptionType.Trial,
					])
				);
			})
		);
	}

	networkAccountChanged(event) {
		event.srcElement.blur();
		event.preventDefault();

		if (this.networkIsModified === true) {
			this.confirmService
				.confirm({
					title: 'Confirm Account Change',
					html: 'Changing the account will reset the network back to the default state. Would you like to continue?',
					icon: 'exclamation-triangle',
					confirmButtonText: 'Change Account',
					cancelButtonText: 'Cancel',
					confirmButtonColor: 'primary',
				})
				.then(
					(_) => {
						this.changeAccount();
					},
					(_) => {
						this.networkForm.controls.accountId.setValue(this.accountId);
					}
				);
		} else {
			this.changeAccount();
		}
	}

	changeAccount() {
		this.accountId = this.networkForm.value?.accountId;
		const network = this.networkService.helpers.getDefaultNetwork(this.accountId);
		network.name = this.networkForm.value.name;
		this.networkForm.patchValue(network);
		this.network = network;
		this.checkCanSave();
	}

	saveNetwork() {
		if (this.networkForm.value?.networkId === 0) {
			this.networkService.api.createNetwork(this.accountId, this.networkForm.value).subscribe((result) => {
				this.alertMessageService.success('The network was successfully created.', { keepAfterRouteChange: true });
				this.router.navigate(['networks', result.networkId, 'account', result.accountId]);
			});
		} else {
			this.networkService.api.updateNetwork(this.accountId, this.networkForm.value).subscribe((_) => {
				this.alertMessageService.success('The network was successfully updated.', { keepAfterRouteChange: true });
				this.loadNetwork();
			});
		}
	}

	deleteNetwork() {
		this.confirmService
			.confirm({
				title: 'Confirm Delete Network',
				html: 'This network will be deleted.  Would you like to continue?',
				icon: 'exclamation-triangle',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				confirmButtonColor: 'danger',
			})
			.then(
				(_) => {
					this.networkService.api.deleteNetwork(this.network.accountId, this.network.networkId).subscribe((_) => {
						this.alertMessageService.success('The network was successfully deleted', { keepAfterRouteChange: true });
						this.router.navigate(['networks']);
					});
				},
				(_) => {}
			);
	}

	showDownloadCsv(account: Account) {
		return true; //this.subscriptionService.isGlobalOrIsTypeAndStatusValid(account.subscriptionType, account.subscriptionStatus);
	}
}
