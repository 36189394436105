<app-root-monitor-cloud-data-table
	[isInitialized]="isInitialized"
	[showSearch]="true"
	[tableSettings]="tableSettings"
	[tableItems]="cameraStreams"
	(pagingChanged)="onPagingChanged($event)"
	[searchConfiguration]="searchConfiguration"
	[accountIds]="accountIds"
	[initialSearchFilter]="initialSearchFilter"
	(applyFilter)="onSearch($event)"></app-root-monitor-cloud-data-table>
