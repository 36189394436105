import { Injectable } from '@angular/core';
import { CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { AuditLog } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuditLogService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAuditLogs: (searchFilter?: SearchFilter): Observable<SearchFilterResult<AuditLog>> => {
				return this.cloudApiService.auditLogs.getAuditLogs(searchFilter);
			},
		};
	}
}
