import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudApiService } from '@razberi-ui/api/cloud-api';
import { UserCompliance, UserComplianceRegion } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserComplianceService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getUserCompliances: (accountIds: number[]): Observable<UserCompliance[]> => {
				return this.cloudApiService.userCompliance.getUserCompliances(accountIds);
			},
			getGdprAgreement: (): Observable<string> => {
				return this.cloudApiService.userCompliance.getGdprAgreement();
			},
			getGdprPdf: (): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.userCompliance.getGdprPdf();
			},
			signGdpr: (consented: boolean): Observable<void> => {
				return this.cloudApiService.userCompliance.signGdpr(consented);
			},
			getResellerAgreement: (region: UserComplianceRegion): Observable<string> => {
				return this.cloudApiService.userCompliance.getResellerAgreement(region);
			},
			getResellerPdf: (): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.userCompliance.getResellerPdf();
			},
			signReseller: (region: UserComplianceRegion, consented: boolean): Observable<void> => {
				return this.cloudApiService.userCompliance.signReseller(region, consented);
			},
		};
	}
}
