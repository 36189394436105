<div class="app-alert-type-selector" [ngClass]="{ 'filter-open': filterOpen }">
	<shared-form-custom-search-select
		[usingFilter]="true"
		[items]="availableAlertTypes"
		(filterOpenChange)="handleFilterOpen($event)"
		(selectModelChange)="updateAlertType($event)"
		[(selectModel)]="config.alertTypes">
	</shared-form-custom-search-select>
	<div class="filter-panel p-2 pb-4" *ngIf="filterOpen">
		<div *ngIf="alertGroups.length > 0" class="m-0 p-0 d-flex flex-wrap">
			<div *ngFor="let alertGroup of alertGroups; let i = index" class="px-3 pt-3 mb-3" style="flex: 0 0 50%">
				<div class="form-check">
					<h6 class="pb-2 mb-2 ps-3 border-bottom">
						<input
							type="checkbox"
							id="checkbox_{{ alertGroup.name }}"
							class="form-check-input"
							[(ngModel)]="alertGroup.allSelected"
							[ngModelOptions]="{ standalone: true }"
							(change)="alertGroupChecked($event, alertGroup)" />
						<label for="checkbox_{{ alertGroup.name }}" class="form-check-label">{{ alertGroup.name }}</label>
					</h6>
				</div>
				<div class="ms-3">
					<div *ngFor="let alertType of alertGroup.alertTypes" class="d-inline-flex m-1">
						<div class="form-check">
							<input
								type="checkbox"
								id="checkbox_{{ alertType.alertTypeId }}"
								class="form-check-input"
								[(ngModel)]="alertType.checked"
								[ngModelOptions]="{ standalone: true }"
								(change)="alertTypeChecked($event, alertType)" />
							<label for="checkbox_{{ alertType.alertTypeId }}" class="form-check-label">{{ alertType.name }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
