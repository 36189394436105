import { Injectable } from '@angular/core';
import { CloudApiService } from '@razberi-ui/api/cloud-api';
import { Tag } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TagService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getTags: (accountId: number): Observable<Tag[]> => {
				return this.cloudApiService.tags.getTags(accountId);
			},
			getTag: (accountId: number, tagId: number): Observable<Tag> => {
				return this.cloudApiService.tags.getTag(accountId, tagId);
			},
			createTag: (accountId: number, tag: Tag): Observable<Tag> => {
				return this.cloudApiService.tags.createTag(accountId, tag);
			},
			updateTag: (accountId: number, tagId: number, tag: Tag): Observable<void> => {
				return this.cloudApiService.tags.updateTag(accountId, tagId, tag);
			},
			deleteTag: (accountId: number, tagId: number): Observable<void> => {
				return this.cloudApiService.tags.deleteTag(accountId, tagId);
			},
			deleteTags: (accountId: number, tagIds: number[]): Observable<void> => {
				return this.cloudApiService.tags.deleteTags(accountId, tagIds);
			},
		};
	}
}
