<div *ngIf="this.settingsService.data.settings?.manage == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="this.settingsService.data.settings?.manage != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="disabledTaskTypes">Disabled Task Types</label>
		<div ngbTooltip="The manage tasks that will not be available." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<ng-container *ngIf="readonly !== true">
			<shared-multi-select [items]="availableManageTaskTypes"
								 [(selected)]="selectedManageTaskTypes"
								 (selectedChange)="selectedManageTaskTypesChanged($event)"
								 labelForId="value.taskTypeId"
								 keyToFilterOn="value.name"
								 clearable="true"
								 [closeOnSelect]="false"></shared-multi-select>
		</ng-container>
		<ng-container *ngIf="readonly === true">
			<div *ngIf="(selectedManageTaskTypes?.length || 0) === 0" class="mt-2">
				<shared-context-message type="info">No disabled task types.</shared-context-message>
			</div>
			<div *ngIf="selectedManageTaskTypes?.length > 0" class="mt-3 ms-4">
				<div *ngFor="let taskType of selectedManageTaskTypes; let i = index" class="mb-3">
					{{ i + 1 }} <span class="ms-2">{{ taskType.value?.name }}</span>
				</div>
			</div>
		</ng-container>
	</div>
</form>
