<div *ngIf="config.label" class="fs-4 mb-1">{{ config.label }}</div>
<div class="d-flex align-items-baseline image-uploader" [ngClass]="{ 'flex-row': config.type === 'horizontal', 'flex-column': config.type === 'vertical' }">
	<div
		class="image-uploader-image-wrapper mb-3"
		[ngStyle]="{ 'max-width': config.maxWidth + 'px' }"
		[ngClass]="{ round: config.shape === 'round', square: config.shape === 'square', rectangle: config.shape === 'rectangle' }">
		<img [src]="config.image ? config.image : config.placeholder" class="img-fluid" />
	</div>
	<div>
		<button class="btn btn-primary me-3" type="button" (click)="cropImage()"><fa-icon icon="upload"></fa-icon> Upload</button>
		<button class="btn btn-secondary me-3" type="button" (click)="removeImage()" [disabled]="!config.image"><fa-icon icon="times"></fa-icon> Remove</button>
		<div *ngIf="config.recommendedText" class="font-italic fw-light mt-2">{{ config.recommendedText }}</div>
	</div>
</div>
