<div class="modal-container">
	<div id="modal-header" class="modal-header">
		<h4 class="modal-title"><fa-icon icon="upload"></fa-icon><span class="ps-2">Upload Image</span></h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div id="modal-body" class="modal-body">
		<div class="d-flex">
			<div class="mx-5" [ngClass]="{ round: config.shape === 'round' }" [ngStyle]="{ 'max-width': config.maxWidth + 'px' }">
				<img [src]="croppedImage ? croppedImage : image" class="img-fluid" />
			</div>
			<div>
				<div class="fs-4 mb-2">{{ config.previewTitle }}</div>
				<div class="font-italic mb-3">{{ config.previewDescription }}</div>
				<button type="button" class="btn btn-primary" (click)="fileInput.click()">
					<span>Select image</span>
					<input #fileInput type="file" (change)="fileChangeEvent($event)" style="display: none" />
				</button>
			</div>
		</div>
		<div class="row" style="margin-top: 5%">
			<div *ngIf="imageChangedEvent" class="text-center col-md-12">
				<h5>Crop Image</h5>
				<image-cropper
					[imageChangedEvent]="imageChangedEvent"
					[maintainAspectRatio]="true"
					[aspectRatio]="config.aspectRatio"
					[resizeToWidth]="config.maxWidth"
					format="png"
					(imageCropped)="imageCropped($event)"
					(imageLoaded)="imageLoaded()"
					(cropperReady)="cropperReady()"
					(loadImageFailed)="loadImageFailed()">
				</image-cropper>
			</div>
		</div>
	</div>
	<div id="modal-footer" class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="close()">Cancel</button>
		<button type="button" class="btn btn-primary float-right" [disabled]="!croppedImage" (click)="uploadImage()">Save</button>
	</div>
</div>
