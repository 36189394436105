import { Component } from '@angular/core';
import { Account, AccountType, User } from '@razberi-ui/core/data-types';
import { AccountService } from '../../../services/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterBenefitsModalComponent } from '../register-benefits-modal/register-benefits-modal.component';
import { CloudApiConfigService, CreateAccountRequest } from '@razberi-ui/api/cloud-api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReCaptchaV3Service } from 'ng-recaptcha';

enum pageMode {
	formDisplay = 0,
	resultDisplay = 1,
}

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-register-account-public',
	templateUrl: './register-account-public.component.html',
	styleUrls: ['./register-account-public.component.scss'],
})
export class RegisterAccountPublicComponent {
    currentPageMode: pageMode = pageMode.formDisplay;
	pageModeOptions: any = pageMode;
	isValid: boolean;
	isAccountValid: boolean;
	formAccount: Account;
	storedAccount: Account;
	isUserValid: boolean;
	formUser: User;
	storedUser: User;
	result: string;
	hasError: boolean;
	selectedAccountType: AccountType;
	newAccountBenefitsDisplayed: boolean = false;

	constructor(
		private readonly accountsService: AccountService,
		private readonly modalService: NgbModal,
		private recaptchaV3Service: ReCaptchaV3Service,
		readonly configService: CloudApiConfigService
	) {}

	ngOnInit(): void {
		const benefitsModal = this.modalService.open(RegisterBenefitsModalComponent, {
			backdrop: 'static',
			centered: true,
			size: 'lg',
			keyboard: false,
		});

		benefitsModal.result.then(
			(result) => {
				this.selectedAccountType = result.data;
				this.newAccountBenefitsDisplayed = true;
			},
			(_) => {}
		);
	}

	onAccountIsValid(isValid: boolean) {
		this.isAccountValid = isValid;
		this.isValid = this.isAccountValid === true && this.isUserValid === true;
	}

	onUserIsValid(isValid: boolean) {
		this.isUserValid = isValid;
		this.isValid = this.isAccountValid === true && this.isUserValid === true;
	}

	onAccountChanges(formAccount: Account) {
		this.formAccount = formAccount;
	}

	onUserChanges(formUser: User) {
		this.formUser = formUser;
	}

	save() {
		if (this.isValid !== true) return;

		this.recaptchaV3Service.execute('createAccount').subscribe((token) => {
			this.formAccount.phone = this.formUser.phone;
			const request: CreateAccountRequest = {
				account: this.formAccount,
				user: this.formUser,
				reCaptchaResponseToken: '', // token,
			};
			this.accountsService.api.createAccountPublic(request).subscribe(
				(result) => {
					this.currentPageMode = pageMode.resultDisplay;
					this.hasError = false;
					this.result = 'The account was successfully created.  Please check your email for the link to validate your email address.';
				},
				(error) => {
					this.currentPageMode = pageMode.resultDisplay;
					this.hasError = true;
					this.result = `${error.error?.Message != null ? error.error.Message : 'Server Error'}`;
				}
			);
		});
	}

	retry() {
		this.currentPageMode = pageMode.formDisplay;
		if (this.formAccount != null) this.storedAccount = this.formAccount;
		if (this.formUser != null) this.storedUser = this.formUser;
	}
}
