import { Component, Input, SimpleChanges } from '@angular/core';
import {
	DiskStatus,
	EndpointDefenderStatus,
	GpuStatus,
	ManagedDeviceStatus,
	MemoryStatus,
	NicStatus,
	ProcessorStatus,
	RaidArrayStatus,
	SwitchStatus,
	UnitStatus,
	VmsServiceStatus,
	VolumeStatus,
} from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-shared-monitor-common-cell-device-status',
	templateUrl: './cell-device-status.component.html',
	styleUrls: ['./cell-device-status.component.scss'],
})
export class CellDeviceStatusComponent {
	@Input() data: any;
	@Input() isParent: boolean = false;
	@Input() deviceType: string = '';
	@Input() status: any;
	@Input() tooltip: string = '';
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	icon: string = '';
	color: string = '';
	text: string = '';

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = 'times';
		let color: string = 'status-red';
		let text: string = UnitStatus[UnitStatus.Error];

		if (changes.data && changes.data.currentValue) {
			this.isParent = changes.data.currentValue.isParent;
			this.deviceType = changes.data.currentValue.type;
			this.status = changes.data.currentValue.status;
			this.tooltip = changes.data.currentValue.tooltip;
			this.disabled = changes.data.currentValue.disabled;
			this.showText = changes.data.currentValue.showText;
		}

		if (this.isParent !== false || this.deviceType === 'Unit') {
			icon = this.status === UnitStatus.Online ? 'check' : this.status === UnitStatus.Warning ? 'exclamation' : 'times';
			color = this.status === UnitStatus.Online ? 'status-green' : this.status === UnitStatus.Warning ? 'status-orange' : 'status-red';
			text = UnitStatus[this.status];
		} else if (this.deviceType === 'Processor') {
			icon = this.status === ProcessorStatus.Normal ? 'check' : this.status === ProcessorStatus.Warning ? 'exclamation' : 'times';
			color = this.status === ProcessorStatus.Normal ? 'status-green' : this.status === ProcessorStatus.Warning ? 'status-orange' : 'status-red';
			text = ProcessorStatus[this.status];
		} else if (this.deviceType === 'Memory') {
			icon = this.status === MemoryStatus.Normal ? 'check' : this.status === MemoryStatus.Warning ? 'exclamation' : 'times';
			color = this.status === MemoryStatus.Normal ? 'status-green' : this.status === MemoryStatus.Warning ? 'status-orange' : 'status-red';
			text = MemoryStatus[this.status];
		} else if (this.deviceType === 'Gpu') {
			icon = this.status === GpuStatus.Normal ? 'check' : this.status === GpuStatus.Warning ? 'exclamation' : 'times';
			color = this.status === GpuStatus.Normal ? 'status-green' : this.status === GpuStatus.Warning ? 'status-orange' : 'status-red';
			text = GpuStatus[this.status];
		} else if (this.deviceType === 'Disk') {
			icon = this.status === DiskStatus.Normal ? 'check' : this.status === DiskStatus.Missing ? 'exclamation' : 'times';
			color = this.status === DiskStatus.Normal ? 'status-green' : this.status === DiskStatus.Missing ? 'status-orange' : 'status-red';
			text = DiskStatus[this.status];
		} else if (this.deviceType === 'RaidArray') {
			icon = this.status === RaidArrayStatus.Normal ? 'check' : this.status === RaidArrayStatus.Degraded ? 'exclamation' : 'times';
			color = this.status === RaidArrayStatus.Normal ? 'status-green' : this.status === RaidArrayStatus.Degraded ? 'status-orange' : 'status-red';
			text = RaidArrayStatus[this.status];
		} else if (this.deviceType === 'Volume') {
			icon = this.status === VolumeStatus.Normal ? 'check' : this.status === VolumeStatus.Warning ? 'exclamation' : 'times';
			color = this.status === VolumeStatus.Normal ? 'status-green' : this.status === VolumeStatus.Warning ? 'status-orange' : 'status-red';
			text = VolumeStatus[this.status];
		} else if (
			this.deviceType === 'Uplink1' ||
			this.deviceType === 'Uplink2' ||
			this.deviceType === 'Uplink2Fiber' ||
			this.deviceType === 'Sfp1' ||
			this.deviceType === 'Sfp2' ||
			this.deviceType === 'Sfp3' ||
			this.deviceType === 'Sfp4' ||
			this.deviceType === 'NIC'
		) {
			icon = this.status === NicStatus.Up ? 'check' : this.status === NicStatus.Disabled ? 'exclamation' : 'times';
			color = this.status === NicStatus.Up ? 'status-green' : this.status === NicStatus.Disabled ? 'status-orange' : 'status-red';
			text = NicStatus[this.status];
		} else if (this.deviceType === 'Switch') {
			icon = this.status === SwitchStatus.Online ? 'check' : this.status === SwitchStatus.Warning ? 'exclamation' : 'times';
			color = this.status === SwitchStatus.Online ? 'status-green' : this.status === SwitchStatus.Warning ? 'status-orange' : 'status-red';
			text = SwitchStatus[this.status];
		} else if (this.deviceType === 'EndpointDefender') {
			icon = this.status === EndpointDefenderStatus.Online ? 'check' : this.status === EndpointDefenderStatus.Warning ? 'exclamation' : 'times';
			color =
				this.status === EndpointDefenderStatus.Online
					? 'status-green'
					: this.status === EndpointDefenderStatus.Warning
					? 'status-orange'
					: 'status-red';
			text = EndpointDefenderStatus[this.status];
		} else if (this.deviceType === 'VmsService') {
			icon = this.status === VmsServiceStatus.Online ? 'check' : this.status === VmsServiceStatus.InvalidCredentials ? 'exclamation' : 'times';
			color =
				this.status === VmsServiceStatus.Online ? 'status-green' : this.status === VmsServiceStatus.InvalidCredentials ? 'status-orange' : 'status-red';
			text = VmsServiceStatus[this.status];
		} else if (this.deviceType === 'managed-device') {
			icon = this.status === ManagedDeviceStatus.Online ? 'check' : this.status === ManagedDeviceStatus.Unavailable ? 'exclamation' : 'times';
			color =
				this.status === ManagedDeviceStatus.Online ? 'status-green' : this.status === ManagedDeviceStatus.Unavailable ? 'status-orange' : 'status-red';
			text = ManagedDeviceStatus[this.status];
		}

		this.icon = icon;
		this.color = color;
		this.text = text;
	}
}
