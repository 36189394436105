import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'shared-cell-pills',
	templateUrl: './cell-pills.component.html',
	styleUrls: ['./cell-pills.component.scss'],
})
export class CellPillsComponent {
	@Input() items: any[] = [];

	firstTwoItems: any[] = [];

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.items && changes.items.currentValue) {
			this.firstTwoItems = [...this.items];
			if (this.firstTwoItems.length > 2) this.firstTwoItems.length = 2;
		} else this.firstTwoItems = changes.items.currentValue;
	}
}
