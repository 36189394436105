<div *ngIf="settingsService.data.settings?.monitorAgent == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.monitorAgent != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="cloudUri" class="form-label">{{ settingsService.settings?.global?.baseTitle }} URI</label>
		<div ngbTooltip="The URI used to access {{ settingsService.settings?.global?.baseTitle }} ."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="cloudUri" id="cloudUri" />
		<shared-input-validation-messages [control]="settingsForm.controls.cloudUri"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="cloudApiUri" class="form-label">CloudApi URI</label>
		<div ngbTooltip="The URI used to access the CloudApi." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="cloudApiUri" id="cloudApiUri" />
		<shared-input-validation-messages [control]="settingsForm.controls.cloudApiUri"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="sinkApiUri" class="form-label">SinkApi URI</label>
		<div ngbTooltip="The URI used to access the SinkApi." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="sinkApiUri" id="sinkApiUri" />
		<shared-input-validation-messages [control]="settingsForm.controls.sinkApiUri"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="settingsInterval" class="form-label">Settings Interval</label>
		<div ngbTooltip="How often the agent reloads settings." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="settingsInterval" id="settingsInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.settingsInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="updateInterval" class="form-label">Update Interval</label>
		<div ngbTooltip="How often the agent checks for updates." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="updateInterval" id="updateInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.updateInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="unitInterval" class="form-label">Unit Interval</label>
		<div ngbTooltip="How often the agent processes the cycle to update data and check for alerts."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="unitInterval" id="unitInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.unitInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="windowsScanInterval" class="form-label">Windows Scan Interval</label>
		<div ngbTooltip="How often the agent checks for available Windows updates."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="windowsScanInterval" id="windowsScanInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.windowsScanInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="disabledAlertTypes">Disabled Alert Types</label>
		<div ngbTooltip="Alert types the agent will ignore." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<ng-container *ngIf="readonly !== true">
			<app-multi-select [items]="availableAlertTypes"
							  [(selectedItems)]="selectedAlertTypes"
							  (selectedItemsChange)="selectedAlertTypesChanged($event)"
							  labelForId="value.alertTypeId"
							  keyToFilterOn="value.name"
							  clearable="true"
							  [closeOnSelect]="false"></app-multi-select>
		</ng-container>
		<ng-container *ngIf="readonly === true">
			<div *ngIf="(selectedAlertTypes?.length || 0) === 0" class="mt-2">
				<app-context-message type="info">No disabled alert types.</app-context-message>
			</div>
			<div *ngIf="selectedAlertTypes?.length > 0" class="mt-2 ms-4">
				<div *ngFor="let alertType of selectedAlertTypes; let i = index" class="mb-1">
					{{ i + 1 }} <span class="ms-2">{{ alertType.value?.name }}</span>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="mb-3 col-12">
		<label for="deviceOfflineThreshold" class="form-label">Device Offline Threshold</label>
		<div ngbTooltip="How many unit process cycles a device must be offline for before an alert is created."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="number" class="form-control" formControlName="deviceOfflineThreshold" id="deviceOfflineThreshold" />
		<shared-input-validation-messages [control]="settingsForm.controls.deviceOfflineThreshold"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input type="checkbox"
				   class="form-check-input"
				   formControlName="remoteApplicationDownloadEnabled"
				   id="remoteApplicationDownloadEnabled"
				   [attr.disabled]="readonly ? true : null" />
			<label class="form-check-label" for="remoteApplicationDownloadEnabled">Enable Remote Application Download</label>
			<div ngbTooltip="Whether or not to enable application downloads from the agent."
				 placement="right"
				 container="body"
				 class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
</form>
