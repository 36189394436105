import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Unit } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-unit-selector-modal',
	templateUrl: './unit-selector-modal.component.html',
	styleUrls: ['./unit-selector-modal.component.scss'],
})
export class UnitSelectorModalComponent {
	@Input() accountId: number;
	@Input() preSelectedUnits: Unit[] = [];

	selectedUnits: Unit[] = [];

	constructor(private readonly activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	selectedUnitsChanged(selectedUnits: Unit[]) {
		this.selectedUnits = selectedUnits;
	}

	ngOnDestroy() {}

	save() {
		this.activeModal.close({ selectedUnits: this.selectedUnits });
	}

	cancel() {
		this.activeModal.close({ selectedUnits: this.preSelectedUnits });
	}

	close() {
		this.activeModal.close({ selectedUnits: this.preSelectedUnits });
	}
}
