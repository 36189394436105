import { Injectable } from '@angular/core';
import { CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { User } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getUsers: (searchFilter?: SearchFilter): Observable<SearchFilterResult<User>> => {
				return this.cloudApiService.user.getUsers(searchFilter);
			},
			getUsersByAccountId: (accountId: number): Observable<User[]> => {
				return this.cloudApiService.user.getUsersByAccountId(accountId);
			},
			getAccountsUsers: (accountIds: number[]): Observable<User[]> => {
				return this.cloudApiService.user.getAccountsUsers(accountIds);
			},
			getUser: (userId: number): Observable<User> => {
				return this.cloudApiService.user.getUser(userId);
			},
			getCurrentUser: (): Observable<User> => {
				return this.cloudApiService.user.getCurrentUser();
			},
			createUser: (user: User): Observable<void> => {
				return this.cloudApiService.user.createUser(user);
			},
			updateUser: (user: User): Observable<void> => {
				return this.cloudApiService.user.updateUser(user);
			},
			updateCurrentUser: (user: User): Observable<void> => {
				return this.cloudApiService.user.updateCurrentUser(user);
			},
			deleteUser: (userId: number): Observable<void> => {
				return this.cloudApiService.user.deleteUser(userId);
			},
			setUserPassword: (userId: number, setPasswordDto: any): Observable<void> => {
				return this.cloudApiService.user.setUserPassword(userId, setPasswordDto);
			},
			setCurrentUserPassword: (setPasswordDto: any): Observable<void> => {
				return this.cloudApiService.user.setCurrentUserPassword(setPasswordDto);
			},
			getUserAvatar: (userId: number): Observable<any> => {
				return this.cloudApiService.user.getUserAvatar(userId);
			},
			getCurrentUserAvatar: (): Observable<any> => {
				return this.cloudApiService.user.getCurrentUserAvatar();
			},
			setUserAvatar: (userId: number, imageDto: any): Observable<any> => {
				return this.cloudApiService.user.setUserAvatar(userId, imageDto);
			},
			setCurrentUserAvatar: (imageDto: any): Observable<any> => {
				return this.cloudApiService.user.setCurrentUserAvatar(imageDto);
			},
			removeUserAvatar: (userId: number): Observable<void> => {
				return this.cloudApiService.user.removeUserAvatar(userId);
			},
			removeCurrentUserAvatar: (): Observable<void> => {
				return this.cloudApiService.user.removeCurrentUserAvatar();
			},
			getUserSessions: (userId): Observable<any> => {
				return this.cloudApiService.user.getUserSessions(userId);
			},
			getCurrentUserSessions: (): Observable<any> => {
				return this.cloudApiService.user.getCurrentUserSessions();
			},
			deleteUserSession: (userId, session): Observable<void> => {
				return this.cloudApiService.user.deleteUserSession(userId, session);
			},
			deleteCurrentUserSession: (session): Observable<void> => {
				return this.cloudApiService.user.deleteCurrentUserSession(session);
			},
		};
	}
}
