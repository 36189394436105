import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, CoreServerSettingsService, UserComplianceDto } from '@razberi-ui/api/cloud-api';
import { Account, AccountType } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageConfigService, TableSettings } from '@razberi-ui/shared';
import { Subscription, forkJoin } from 'rxjs';
import { UserComplianceService } from '../../../../../services/user-compliance.service';
import { UserService } from '../../../../../services/user.service';
import { AccountService } from '../../../../../services/account.service';
import { ReportService } from '../../../../../services/report.service';
import * as moment from 'moment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-compliance',
	templateUrl: './account-view-tab-compliance.component.html',
	styleUrls: ['./account-view-tab-compliance.component.scss'],
})
export class AccountViewTabComplianceComponent {
	subscriptions: Subscription = new Subscription();
	accountId?: number;
	account: Account;
	compliances: UserComplianceDto[] = [];
	tableSettings: TableSettings = { columnConfig: [] };
	complianceEnabled: boolean;
	isInitialized: boolean = false;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly pageConfigService: PageConfigService,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly userComplianceService: UserComplianceService,
		private readonly userService: UserService,
		private readonly accountService: AccountService,
		private readonly reportService: ReportService,
		private readonly settingsService: CoreServerSettingsService
	) {}

	ngOnInit() {
		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;

		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				if (settings?.compliance == null) {
					this.complianceEnabled = false;
					return;
				}
				this.complianceEnabled = settings?.compliance?.enablePrivacyPolicy === true || settings?.compliance?.enableResellerAgreement === true;
				this.getUserCompliances();
			})
		);
	}

	ngOnDestroy() {
		this.pageConfigService.data.toolbarConfig = null;
	}

	setTableConfig() {
		this.tableSettings = {
			useSearch: true,
			columnConfig: [
				{
					primaryKey: 'name',
					header: 'User Name',
					useForSearch: true,
					transform: (value) => {
						return value;
					},
				},
				{
					primaryKey: 'gdprConsented',
					header: 'Privacy Policy Consent',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return value == null
							? { text: '--' }
							: value === true
							? { text: this.formatDate(tableItem.gdprSignedTimestamp), icon: 'check-circle', iconClass: 'text-success' }
							: { text: this.formatDate(tableItem.gdprSignedTimestamp), icon: 'times-circle', iconClass: 'text-danger' };
					},
				},
				{
					primaryKey: 'resellerConsented',
					header: 'Reseller Agreement Consent',
					cellType: 'icon',
					hide: this.account?.type === AccountType.Customer,
					transform: (value, tableItem) => {
						return value == null
							? { text: '--' }
							: value === true
							? { text: this.formatDate(tableItem.resellerSignedTimestamp), icon: 'check-circle', iconClass: 'text-success' }
							: { text: this.formatDate(tableItem.resellerSignedTimestamp), icon: 'times-circle', iconClass: 'text-danger' };
					},
				},
			],
		};

		this.tableSettings.stickyHeaderButtons = [
			{ label: 'Privacy Policy', faIcon: 'download', clickHandlerFunction: (params) => this.downloadPrivacyPolicy() },
		];
		if (this.account?.type !== AccountType.Customer && this.settingsService?.data.settings?.compliance?.enableResellerAgreement === true)
			this.tableSettings.stickyHeaderButtons.push({
				label: 'Reseller Agreement',
				faIcon: 'download',
				clickHandlerFunction: (params) => this.downloadResellerAgreement(),
			});
		this.tableSettings.stickyHeaderButtons.push({ label: 'Download CSV', faIcon: 'download', clickHandlerFunction: (params) => this.downloadCsv() });
	}

	getUserCompliances() {
		this.compliances = [];
		this.account = null;

		forkJoin({
			account: this.accountService.api.getAccount(this.accountId),
			userCompliances: this.userComplianceService.api.getUserCompliances([this.accountId]),
			users: this.userService.api.getAccountsUsers([this.accountId]),
		}).subscribe({
			next: (result) => {
				this.account = result.account;
				this.setTableConfig();
				result.users
					?.sort((a, b) => {
						const result = a.lastName.localeCompare(b.lastName);
						return result !== 0 ? result : a.firstName.localeCompare(b.firstName);
					})
					.map((u) => {
						const compliance = result?.userCompliances?.find((uc) => uc.userId === u.userId);
						this.compliances.push({
							userId: u.userId,
							name: `${u.firstName} ${u.lastName}`,
							gdprConsented: compliance?.gdprConsented,
							gdprSignedTimestamp: compliance?.gdprSignedTimestamp,
							resellerConsented: compliance?.resellerConsented,
							resellerSignedTimestamp: compliance?.resellerSignedTimestamp,
						});
						this.isInitialized = true;
					});
			},
			error: (error) => {
				this.alertMessageService.error('Error getting user compliances.', error);
			},
		});
	}

	formatDate(date: Date) {
		if (date == null || moment(date).isValid() !== true) return '--';
		return moment(date).format('l LT');
	}

	downloadPrivacyPolicy() {
		this.userComplianceService.api.getGdprPdf().subscribe((response) => {
			this.reportService.helpers.saveAsFile(response);
		});
	}

	downloadResellerAgreement() {
		this.userComplianceService.api.getResellerPdf().subscribe((response) => {
			this.reportService.helpers.saveAsFile(response);
		});
	}

	downloadCsv() {
		this.reportService.api.getUserComplianceReportCsvByAccountId([this.accountId]).subscribe((response) => {
			this.reportService.helpers.saveAsFile(response);
		});
	}
}
