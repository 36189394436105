import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'shared-input-validation-messages',
	templateUrl: './input-validation-messages.component.html',
	styleUrls: ['./input-validation-messages.component.scss'],
})
export class InputValidationMessagesComponent {
	@Input() set control(value: AbstractControl) {
		this.initializeControl(value);
	}
	@Input() clearMessagesOnEmptyErrors: boolean = false;

	messages: string[] = [];

	initializeControl(control: AbstractControl) {
		if (control == null) return;

		control.valueChanges.pipe(debounceTime(300)).subscribe((_) => {
			if (control.dirty === true) this.setErrorMessages(control.errors);
			else if (this.clearMessagesOnEmptyErrors === true && (control.errors?.length ?? 0) == 0) this.messages = [];
		});

		// control.statusChanges.pipe(debounceTime(300)).subscribe((status) => {console.log('status', status, control);
		// 	this.setErrorMessages(control.errors);
		// });
	}

	setErrorMessages(value: ValidationErrors) {
		this.messages = [];

		// get control validation objects
		let e: {
			min?: number;
			max?: number;
			requiredLength?: number;
		} = {};

		const errors = value || [];

		for (const err in errors) {
			if (errors.hasOwnProperty(err)) {
				switch (err) {
					case 'required':
						this.messages.push(`Required`);
						break;
					case 'min':
						e = errors[err];
						this.messages.push(`Must be ${e['min']} or greater`);
						break;
					case 'max':
						e = errors[err];
						this.messages.push(`Must be ${e['max']} or lesser`);
						break;
					case 'minlength':
						e = errors[err];
						this.messages.push(`Must be at least ${e['requiredLength']} characters`);
						break;
					case 'maxlength':
						e = errors[err];
						this.messages.push(`Must be ${e['requiredLength']} characters or less`);
						break;
					case 'pattern':
						e = errors[err];
						this.messages.push(`Invalid format`);
						break;
					case 'email':
						e = errors[err];
						this.messages.push(`Invalid email address`);
						break;
					case 'date':
						e = errors[err];
						this.messages.push(`Invalid date`);
						break;
					case 'phone':
						e = errors[err];
						this.messages.push(`Invalid phone number`);
						break;
					case 'invalidFormat':
						e = errors[err];
						this.messages.push(`Invalid format`);
						break;
					case 'customMessage':
						this.messages.push(errors[err]);
						break;
				}
			}
		}
	}
}
