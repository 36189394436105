<div class="half-circle-graph position-relative d-flex justify-content-center align-items-end mb-3">
	<div class="graph-label me-1">0</div>
	<svg class="flex-grow-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291 153">
		<path
			fill="none"
			stroke="rgba(51, 51, 51, 0.1)"
			stroke-linecap="round"
			stroke-miterlimit="10"
			stroke-width="15"
			d="M7.5 145.5c0-76.2 61.8-138 138-138s138 61.8 138 138" />
		<path
			fill="none"
			#graphLine
			[attr.stroke]="strokeColor"
			stroke-linecap="round"
			stroke-miterlimit="10"
			stroke-width="15"
			d="M7.5 145.5c0-76.2 61.8-138 138-138s138 61.8 138 138" />
	</svg>
	<div class="graph-label ms-1">100</div>
	<div class="fs-2 w-100 text-center position-absolute">
		<span>{{ percentage || '--' }}%</span>
	</div>
</div>
