import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ManageTaskFile, ManageTaskParamsFileChangedEvent, ManageTaskType } from '@razberi-ui/core/data-types';
import { Subscription } from 'rxjs';
import { ManageService } from '../../../../services/manage.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-form-parameters',
	templateUrl: './parameters.component.html',
	styleUrls: ['./parameters.component.scss'],
})
export class ParametersComponent {
	@Input() accountId: number;
	@Input() manageTaskTypeId: number;
	@Input() parameters: string;
	@Input() taskFiles: ManageTaskFile[];
	@Input() readonly: boolean;
	@Output() parametersChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() filesSelected: EventEmitter<ManageTaskParamsFileChangedEvent> = new EventEmitter<ManageTaskParamsFileChangedEvent>();
	@Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscriptions: Subscription = new Subscription();
	availableTaskTypes: ManageTaskType[] = [];
	selectedTaskType: ManageTaskType;

	constructor(private readonly manageService: ManageService) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.manageService.api.getTaskTypes().subscribe((types) => {
				this.availableTaskTypes = types;
				this.trySetSelectedTask();
			})
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.manageTaskTypeId > 0) {
			this.trySetSelectedTask();
			this.makeValidWhenTaskParamsNotRequired();
			if (this.manageTaskTypeId === 6) {
				// handle cylance
				const event: ManageTaskParamsFileChangedEvent = { uploadFiles: [], clearExistingFiles: true };
				this.filesSelected.emit(event);
			}
		}
	}

	trySetSelectedTask() {
		if (this.availableTaskTypes == null || (this.manageTaskTypeId ?? 0) == 0) return;

		this.selectedTaskType = this.availableTaskTypes.find((t) => t.taskTypeId === this.manageTaskTypeId);
	}

	makeValidWhenTaskParamsNotRequired() {
		if (this.selectedTaskType?.requiresParams !== true) {
			this.onValid(true);
		}
	}

	onParametersChanged(parameters: string) {
		this.parametersChanged.emit(parameters);
	}

	onFilesSelected(event: ManageTaskParamsFileChangedEvent) {
		this.filesSelected.emit(event);
	}

	onValid(valid: boolean) {
		this.valid.emit(valid);
	}
}
