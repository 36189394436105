<div *ngIf="settingsService.data.settings?.sinkApi == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.sinkApi != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="accessTokenExpireTimeSpan" class="form-label">Access Token Expire TimeSpan</label>
		<div ngbTooltip="How long the access token is valid." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="accessTokenExpireTimeSpan" id="accessTokenExpireTimeSpan" />
		<shared-input-validation-messages [control]="settingsForm.controls.accessTokenExpireTimeSpan"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="refreshTokenExpireTimeSpan" class="form-label">Refresh Token Expire TimeSpan</label>
		<div ngbTooltip="How long the refresh token is valid." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="refreshTokenExpireTimeSpan" id="refreshTokenExpireTimeSpan" />
		<shared-input-validation-messages [control]="settingsForm.controls.refreshTokenExpireTimeSpan"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input class="form-check-input" type="checkbox" formControlName="enableSwagger" id="enableSwagger" />
			<label for="enableSwagger" class="form-check-label">Enable Swagger</label>
			<div ngbTooltip="Whether or not to enable swagger API metadata and testing."
				 placement="right"
				 container="body"
				 class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
</form>
