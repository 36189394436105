import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ImageCropperModule } from 'ngx-image-cropper';
import { INGXLoggerConfig, LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertMessageOutletComponent } from './components/alert-message-outlet/alert-message-outlet.component';
import { CodeEntryComponent } from './components/code-entry/code-entry.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ContextMessageTitleComponent } from './components/context-message/context-message-title/context-message-title.component';
import { ContextMessageComponent } from './components/context-message/context-message.component';
import { DataTableSearchComponent } from './components/data-table-search/data-table-search.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { FormCheckboxSelectComponent } from './components/form-checkbox-select/form-checkbox-select.component';
import { FormMultiSelectComponent } from './components/form-multi-select/form-multi-select.component';
import { FormSelectCountriesComponent } from './components/form-select-countries/form-select-countries.component';
import { FormSelectTimeZonesComponent } from './components/form-select-time-zones/form-select-time-zones.component';
import { FormSelectUsStatesComponent } from './components/form-select-us-states/form-select-us-states.component';
import { InputValidationMessagesComponent } from './components/input-validation-messages/input-validation-messages.component';
import { PageSidePanelOutletComponent } from './components/page-side-panel-outlet/page-side-panel-outlet.component';
import { PageToolbarOutletComponent } from './components/page-toolbar-outlet/page-toolbar-outlet.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { AppEnvironment } from './data-types/app-environment.model';
import { AutofocusIfDirective } from './directives/autofocus-if.directive';
import { Draggable } from './directives/draggable.directive';
import { FocusIfDirective } from './directives/focus-if.directive';
import { PagePortalOutletDirective } from './directives/page-portal-outlet.directive';
import { PagePortalDirective } from './directives/page-portal.directive';
import { NgbdSortableHeader } from './directives/sortable.directive';
import { ValueMatchValidatorDirective } from './directives/value-match.directive';
import { SharedVendorMaterialModule } from './modules/vendor-material.module';
import { EnumToOptionsPipe } from './pipes/enum-to-options.pipe';
import { ForEachNumberPipe } from './pipes/for-each-number.pipe';
import { AlertMessageService } from './services/alert-message.service';
import { ElectronService } from './services/electron.service';
import { NavService } from './services/nav.service';
import { PagePortalService } from './services/page-portal.service';
import { PageSidePanelService } from './services/page-side-panel.service';
import { UtilitiesService } from './services/utilities.service';
import { VersionService } from './services/version.service';
import { PageConfigService } from './services/page-config.service';
import { OnScrollEndDirective } from './directives/on-scroll-end.directive';
import { DateRangeSelectComponent } from './components/date-range-select/date-range-select.component';
import { ConfirmService } from './services/confirm.service';
import { FormCustomSearchSelectComponent } from './components/form-custom-search-select/form-custom-search-select.component';
import { FormDatePickerComponent } from './components/form-date-picker/form-date-picker.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { CellHeaderSubComponent } from './components/cell-types/cell-header-sub/cell-header-sub.component';
import { CellIconComponent } from './components/cell-types/cell-icon/cell-icon.component';
import { CellLinkButtonComponent } from './components/cell-types/cell-link-button/cell-link-button.component';
import { CellPillsComponent } from './components/cell-types/cell-pills/cell-pills.component';
import { CellRouterLinkComponent } from './components/cell-types/cell-router-link/cell-router-link.component';
import { CellStatusComponent } from './components/cell-types/cell-status/cell-status.component';
import { CellToggleSwitchComponent } from './components/cell-types/cell-toggle-switch/cell-toggle-switch.component';
import { VarDirective } from './directives/var.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		FontAwesomeModule,
		ImageCropperModule,
		InlineSVGModule.forRoot(),
		LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
		NgSelectModule,
		NgxSpinnerModule,
		SlickCarouselModule,
		QRCodeModule,
		SharedVendorMaterialModule,
	],
	declarations: [
		AutofocusIfDirective,
		FocusIfDirective,
		Draggable,
		PagePortalOutletDirective,
		PagePortalDirective,
		ValueMatchValidatorDirective,
		OnScrollEndDirective,
		NgbdSortableHeader,
		VarDirective,
		AlertMessageOutletComponent,
		ToggleSwitchComponent,
		ConfirmModalComponent,
		InputValidationMessagesComponent,
		ContextMessageComponent,
		ContextMessageTitleComponent,
		FormSelectUsStatesComponent,
		FormSelectCountriesComponent,
		FormSelectTimeZonesComponent,
		QrCodeComponent,
		PageToolbarOutletComponent,
		CodeEntryComponent,
		EnumToOptionsPipe,
		ForEachNumberPipe,
		PageSidePanelOutletComponent,
		DataTableSearchComponent,
		FormCheckboxSelectComponent,
		FormMultiSelectComponent,
		DataTableComponent,
		DateRangeSelectComponent,
		FormCustomSearchSelectComponent,
		FormDatePickerComponent,
		ImageUploaderComponent,
		ImageCropperComponent,
		CellHeaderSubComponent,
		CellIconComponent,
		CellLinkButtonComponent,
		CellPillsComponent,
		CellRouterLinkComponent,
		CellStatusComponent,
		CellToggleSwitchComponent,
		FileUploadComponent,
	],
	exports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule,
		SharedVendorMaterialModule,
		NgbModule,
		NgSelectModule,
		NgxSpinnerModule,
		FontAwesomeModule,
		SlickCarouselModule,
		ImageCropperModule,
		QRCodeModule,
		//directives
		AutofocusIfDirective,
		FocusIfDirective,
		Draggable,
		PagePortalOutletDirective,
		PagePortalDirective,
		ValueMatchValidatorDirective,
		OnScrollEndDirective,
		NgbdSortableHeader,
		VarDirective,
		// components
		AlertMessageOutletComponent,
		ToggleSwitchComponent,
		InputValidationMessagesComponent,
		ContextMessageComponent,
		ContextMessageTitleComponent,
		FormSelectUsStatesComponent,
		FormSelectCountriesComponent,
		FormSelectTimeZonesComponent,
		QrCodeComponent,
		PageToolbarOutletComponent,
		CodeEntryComponent,
		PageSidePanelOutletComponent,
		DataTableSearchComponent,
		FormCheckboxSelectComponent,
		FormMultiSelectComponent,
		DataTableComponent,
		DateRangeSelectComponent,
		FormCustomSearchSelectComponent,
		FormDatePickerComponent,
		ImageUploaderComponent,
		ImageCropperComponent,
		CellHeaderSubComponent,
		CellIconComponent,
		CellLinkButtonComponent,
		CellPillsComponent,
		CellRouterLinkComponent,
		CellStatusComponent,
		CellToggleSwitchComponent,
		FileUploadComponent,
		// pipes
		EnumToOptionsPipe,
		ForEachNumberPipe,
	],
})
export class SharedModule {
	constructor(private readonly logger: NGXLogger, private readonly appEnvironment: AppEnvironment) {
		const config: INGXLoggerConfig = logger.getConfigSnapshot();
		config.disableConsoleLogging = appEnvironment.production;
		logger.updateConfig(config);
	}

	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [
				AlertMessageService,
				ElectronService,
				NavService,
				PagePortalService,
				PageSidePanelService,
				UtilitiesService,
				VersionService,
				PageConfigService,
				ConfirmService,
			],
		};
	}
}
