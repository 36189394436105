<div class="confirm-container">
	<div class="modal-header">
		<h4 class="modal-title"><fa-icon icon="exclamation-circle"></fa-icon><span class="ps-2">Subscription Required</span></h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<p>A valid {{ this.subscriptions }} subscription is required to perform this action.</p>
		<p>
			<ng-container *ngIf="settingsService.data.settings?.global?.isPremise === true">
				Contact {{ settingsService.settings?.rootAccountName || 'your MonitorPremise host' }}
			</ng-container>
			<ng-container *ngIf="settingsService.data.settings?.global?.isPremise !== true">
				<a href="https://www.razberi.net/about-2/contact-us-video-surveillance-security/" target="_blank">Contact Razberi</a>
			</ng-container>
			for more details or to begin a free trial.
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="close()"><fa-icon icon="times"></fa-icon> Close</button>
	</div>
</div>
