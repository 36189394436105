<ng-container *ngIf="alert?.networkId > 0">
	<fa-icon icon="network-wired" size="lg" class="me-2"></fa-icon>
	<button class="btn btn-link ms-0 ps-0 mt-0 pt-0" (click)="viewNetwork()">{{ alert?.networkName || 'View Network' }}</button>
</ng-container>
<div *ngIf="alert?.message?.length > 0">
	<p *ngIf="alert?.message?.length <= 150">{{ alert?.message }}</p>
	<div *ngIf="alert?.message?.length > 150">
		<div *ngIf="showMore !== true">
			<p class="mb-1">{{ alert?.message.substring(0, 150) }}...</p>
			<button class="btn btn-link m-0 p-0" (click)="showMore = !showMore">show more</button>
		</div>
		<div *ngIf="showMore === true">
			<p class="mb-1" [innerHTML]="alert?.message.replaceAll('\r\n', '<br/>')"></p>
			<button class="btn btn-link m-0 p-0" (click)="showMore = !showMore">show less</button>
		</div>
	</div>
</div>
<p *ngIf="(alert?.message?.length || 0) == 0">--</p>
