import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ManageTaskFile, ManageTaskFileMode, ManageTaskParamsFileChangedEvent, PowerShellScriptManageTaskParams } from '@razberi-ui/core/data-types';
import { ManageService } from '../../../../../services/manage.service';
import { ReportService } from '../../../../../services/report.service';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { AlertMessageService } from '@razberi-ui/shared';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-form-parameters-powershell-script',
	templateUrl: './powershell-script.component.html',
	styleUrls: ['./powershell-script.component.scss'],
})
export class PowershellScriptComponent {
	@Input() accountId: number;
	@Input() parameters: string;
	@Input() taskFiles: ManageTaskFile[];
	@Input() readonly: boolean;
	@Output() parametersChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() filesSelected: EventEmitter<ManageTaskParamsFileChangedEvent> = new EventEmitter<ManageTaskParamsFileChangedEvent>();
	@Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

	resetSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	reset$: Observable<void> = this.resetSubject.asObservable();
	subscriptions: Subscription = new Subscription();
	deserialized: PowerShellScriptManageTaskParams;
	parametersForm: UntypedFormGroup;
	selectedFile: File;
	fileMode: ManageTaskFileMode = ManageTaskFileMode.New;
	fileModeOptions: any = ManageTaskFileMode;
	initialized: boolean = false;

	constructor(
		private readonly manageService: ManageService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly reportService: ReportService,
		private readonly alertMessageService: AlertMessageService
	) {}

	ngOnInit(): void {
		this.init();
	}

	init() {
		if (this.initialized !== true) {
			this.buildForm();
			this.subscriptions.add(
				this.parametersForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
					this.emitValidity();
				})
			);
			this.subscriptions.add(
				this.parametersForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
					this.parametersChanged.emit(JSON.stringify(value));
					this.emitValidity();
				})
			);
			this.initialized = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.init();
		this.tryLoadParams();
	}

	tryLoadParams() {
		this.deserialized = JSON.parse(this.parameters);
		this.parametersForm.reset();
		if (this.parameters?.length > 0) this.parametersForm.patchValue(this.deserialized);
		this.fileMode = this.taskFiles?.length > 0 ? ManageTaskFileMode.Existing : ManageTaskFileMode.New;
		this.emitValidity();
	}

	buildForm() {
		if (this.parametersForm != null) return;

		this.parametersForm = this.formBuilder.group({
			minVersion: [null],
		});
	}

	fileSelected(file: File) {
		const fileExtension = file.name.split('.').pop();
		if (fileExtension.toLowerCase() !== 'ps1') {
			this.selectedFile = null;
			this.alertMessageService.error('Only the following file extensions are valid: .ps1', null, { autoClose: true });
			this.emitValidity();
			this.resetSubject.next();
			return;
		}

		this.selectedFile = file;
		const event: ManageTaskParamsFileChangedEvent = { uploadFiles: [this.selectedFile], clearExistingFiles: true };
		this.filesSelected.emit(event);
		this.emitValidity();
	}

	resetFileEvent() {
		let event: ManageTaskParamsFileChangedEvent = { uploadFiles: [], clearExistingFiles: false };
		if (this.fileMode === ManageTaskFileMode.New) event = { uploadFiles: [this.selectedFile], clearExistingFiles: true };
		this.filesSelected.emit(event);
		this.emitValidity();
	}

	emitValidity() {
		const isValid = this.parametersForm.valid === true && (this.selectedFile != null || this.fileMode === ManageTaskFileMode.Existing);
		this.valid.emit(isValid);
	}

	downloadTaskFile(taskFile: ManageTaskFile) {
		this.manageService.api.getManageTaskFile(this.accountId, taskFile.manageTaskFileId).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting script file.', error);
			},
		});
	}
}
