import { Injectable } from '@angular/core';
import { AlertTotals, CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { Alert, AlertType } from '@razberi-ui/core/data-types';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAlerts: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Alert>> => {
				return this.cloudApiService.alerts.getAlerts(searchFilter);
			},
			getAlertTypes: (): Observable<AlertType[]> => {
				return this.cloudApiService.alerts.getAlertTypes();
			},
			getAllAlertTypes: (): Observable<AlertType[]> => {
				return this.cloudApiService.alerts.getAllAlertTypes();
			},
			getAlertTotals: (accountId: number): Observable<AlertTotals[]> => {
				return this.cloudApiService.alerts.getAlertTotals(accountId);
			},
			getDeviceAlertTotals: (accountId: number): Observable<AlertTotals[]> => {
				return this.cloudApiService.alerts.getDeviceAlertTotals(accountId);
			},
			getNetworkAlertsResult: (accountId: number, networkId: number, searchFilter?: SearchFilter): Observable<SearchFilterResult<Alert>> => {
				return this.cloudApiService.alerts.getNetworkAlertsResult(accountId, networkId, searchFilter);
			},
			getAlertsDays: (accountId: number, days?: number): Observable<Alert[]> => {
				const filter: SearchFilter = { accounts: [{ accountId: accountId }] };
				if (days) filter.startTimestamp = moment().subtract(days, 'd').startOf('d').utc().toDate();
				return this.cloudApiService.alerts.getAlerts(filter).pipe(map((result) => result.results));
			},
			getDeviceAlerts: (accountId: number, unitId: number, days?: number): Observable<Alert[]> => {
				const filter: SearchFilter = { accounts: [{ accountId: accountId, unitIds: [unitId] }] };
				if (days) filter.startTimestamp = moment().subtract(days, 'd').startOf('d').utc().toDate();
				return this.cloudApiService.alerts.getAlerts(filter).pipe(map((result) => result.results));
			},
			getNetworkAlerts: (
				accountId: number,
				networkId: number,
				managedDeviceKeys: string[],
				filter: SearchFilter,
				days?: number
			): Observable<SearchFilterResult<Alert>> => {
				filter.accounts = [{ accountId: accountId, networkIds: [networkId], managedDeviceKeys: managedDeviceKeys }];
				if (days) filter.startTimestamp = moment().subtract(days, 'd').startOf('d').utc().toDate();
				return this.cloudApiService.alerts.getNetworkAlertsResult(accountId, networkId, filter);
			},
			acknowledgeAlerts: (accountId: number, alertItemIds: number[]) => {
				return this.cloudApiService.alerts.acknowledgeAlerts(accountId, alertItemIds);
			},
			acknowledgeAlertsFilter: (searchFilter: SearchFilter): Observable<number> => {
				return this.cloudApiService.alerts.acknowledgeAlertsFilter(searchFilter);
			},
			closeAlerts: (accountId: number, alertItemIds: number[]) => {
				return this.cloudApiService.alerts.closeAlerts(accountId, alertItemIds);
			},
			closeAlertsFilter: (searchFilter: SearchFilter): Observable<number> => {
				return this.cloudApiService.alerts.closeAlertsFilter(searchFilter);
			},
		};
	}
}
