import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavService } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
	authHasInitialized: boolean = false;

	constructor(
		private readonly logger: NGXLogger,
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly navService: NavService
	) {}

	ngOnInit() {
		this.authService.streams.hasInitialized$.subscribe((hasInitialized) => {
			this.authHasInitialized = hasInitialized;
			console.log(this.authHasInitialized);
		});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.logger.trace('auth guard check');
		if (this.authService.data.isAuthenticated !== true) {
			// store the attempted URL for redirecting
			this.authService.data.redirectUrl = state.url;
			this.router.navigate(['/login']);
			return false;
		}

		if (route.data.accounts && route.data.accounts.indexOf(this.authService.data.account.type) === -1) {
			// account type not authorised so redirect to home page
			this.router.navigate([this.navService.data.authenticatedDefaultUrl]);
			return false;
		}

		if (route.data.roles && route.data.roles.some((u) => this.authService.helpers.userHasRole(u) == true) != true) {
			// user type not authorised so redirect to home page
			this.router.navigate([this.navService.data.authenticatedDefaultUrl]);
			return false;
		}

		return true;
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(route, state);
	}
}
