import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { ConfirmModalConfiguration } from '../data-types/confirm-modal-configuration.model';

@Injectable({
	providedIn: 'root',
})
export class ConfirmService {
	constructor(private readonly modalService: NgbModal) {}

	confirm(configuration: ConfirmModalConfiguration): Promise<any> {
		const modalRef = this.modalService.open(ConfirmModalComponent, { backdrop: 'static', centered: true });
		modalRef.componentInstance.configuration = configuration;
		return modalRef.result;
	}
}
