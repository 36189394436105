<div class="main-container" [ngClass]="{ 'page-bg': usePageLayout === true, 'card-bg': useCardLayout === true, 'fixed-bg': useFixedLayout === true }">
	<theme-razberi-core-sidenav
		*ngIf="usePageLayout === true"
		[isCollapsed]="isSidenavCollapsed"
		(collapse)="onSidenavCollapse($event)"></theme-razberi-core-sidenav>
	<shared-alert-message-outlet
		[isFullWidth]="useCardLayout === true || useFixedLayout === true"
		[isCollapsed]="isSidenavCollapsed"></shared-alert-message-outlet>
	<!-- page layout -->
	<div
		*ngIf="usePageLayout === true"
		class="page-container"
		[ngClass]="{ 'sidenav-collapsed': isSidenavCollapsed === true, 'sidenav-expanded': isSidenavCollapsed !== true }">
		<theme-razberi-core-toolbar></theme-razberi-core-toolbar>
		<div class="container-fluid page-outlet-container">
			<router-outlet></router-outlet>
		</div>
	</div>
	<!-- card layout -->
	<div *ngIf="useCardLayout === true" class="card-container">
		<div class="layout-card" [ngClass]="cardSizeClass">
			<theme-razberi-core-card-toolbar></theme-razberi-core-card-toolbar>
			<div class="container-fluid card-outlet-container">
				<router-outlet></router-outlet>
			</div>
			<theme-razberi-core-card-footer></theme-razberi-core-card-footer>
		</div>
	</div>
	<!-- fixed layout -->
	<div *ngIf="useFixedLayout === true" class="fixed-container">
		<theme-razberi-core-fixed-toolbar></theme-razberi-core-fixed-toolbar>
		<div class="fixed-outlet-container">
			<router-outlet></router-outlet>
		</div>
		<theme-razberi-core-fixed-footer></theme-razberi-core-fixed-footer>
	</div>
</div>
<ngx-spinner type="ball-pulse" size="medium" *ngIf="hideSpinner !== true"></ngx-spinner>
<ng-template [ngTemplateOutlet]="sidePanelOutletRef"></ng-template>
