<div ngbDropdown class="d-inline-block">
	<div ngbDropdownToggle id="dropdownLegend" class="cursor-pointer">Legend&nbsp;&nbsp;</div>
	<div ngbDropdownMenu aria-labelledby="dropdownLegend" class="p-4">
		<h6>Device Statuses</h6>
		<div class="ms-3">
			<div class="d-flex mb-2 mt-2">
				<div class="d-flex flex-1" style="margin-right: 10px">
					<div class="text-sm flex-1">Total</div>
					<div class="status-dot status-bg-none status-color ms-2 cursor-default">X</div>
				</div>
				<div class="d-flex flex-1">
					<div class="text-sm flex-1">Online</div>
					<div class="status-dot status-bg-green status-color ms-2 cursor-default">X</div>
				</div>
			</div>
			<div class="d-flex mb-2 mt-2">
				<div class="d-flex flex-1" style="margin-right: 10px">
					<div class="text-sm flex-1">Offline</div>
					<div class="status-dot status-bg-red status-color ms-2 cursor-default">X</div>
				</div>
				<div class="d-flex flex-1">
					<div class="text-sm flex-1">Unavailable</div>
					<div class="status-dot status-bg-orange status-color ms-2 cursor-default">X</div>
				</div>
			</div>
		</div>
		<h6>Device Types</h6>
		<div class="ms-3 mt-3">
			<app-root-monitor-cloud-network-d3-tree-diagram [drawLegendOnly]="true" legendType="device-types"></app-root-monitor-cloud-network-d3-tree-diagram>
		</div>
		<h6>Link Statuses</h6>
		<div class="ms-3 mt-3">
			<app-root-monitor-cloud-network-d3-tree-diagram [drawLegendOnly]="true" legendType="link-statuses"></app-root-monitor-cloud-network-d3-tree-diagram>
		</div>
	</div>
</div>
