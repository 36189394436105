import { Component } from '@angular/core';

@Component({
	selector: 'app-root-monitor-cloud-dashboard-outlet-iot-health',
	templateUrl: './dashboard-outlet-iot-health.component.html',
	styleUrls: ['./dashboard-outlet-iot-health.component.scss'],
})
export class DashboardOutletIotHealthComponent {
	alertTypeIds: number[] = [14, 15, 16, 20, 34];

	constructor() {}
}
