import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationService } from '../../../services/location.service';

@Component({
	selector: 'app-root-monitor-cloud-location-modal',
	templateUrl: './location-modal.component.html',
	styleUrls: ['./location-modal.component.scss'],
})
export class LocationModalComponent {
	@Input() locationId: number;
	@Input() accountId: number;

	location: Location;
	formLocation: Location;
	isValid: boolean = false;
	previousLocationSaved: boolean = false;
	resetSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	reset$: Observable<void>;

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly locationService: LocationService
	) {}

	ngOnInit() {
		this.reset$ = this.resetSubject.asObservable();

		if (this.locationId)
			this.locationService.api.getLocation(this.accountId, this.locationId).subscribe({
				next: (location: Location) => {
					this.location = location;
				},
				error: (error) => {
					this.alertMessageService.error('Error getting location.', error);
				},
			});
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(formLocation: Location) {
		this.formLocation = formLocation;
	}

	save() {
		this.saveInternal(false);
	}

	saveAndAdd() {
		this.saveInternal(true);
	}

	saveInternal(addAnother: boolean) {
		if (this.isValid) {
			let o: Observable<any>;
			let l: Location = { accountId: this.accountId, ...this.formLocation };
			if (this.locationId) {
				l.locationId = this.locationId;
				o = this.locationService.api.updateLocation(this.accountId, this.locationId, l);
			} else o = this.locationService.api.createLocation(this.accountId, l);
			o.subscribe({
				next: (_) => {
					this.alertMessageService.success(`Location ${this.locationId ? 'saved' : 'created'}.`);
					if (addAnother === true) {
						this.resetSubject.next();
						this.location = null;
						this.previousLocationSaved = true;
					} else this.activeModal.close();
				},
				error: (error) => {
					this.alertMessageService.error('Error saving location.', error);
				},
			});
		}
	}

	cancel() {
		this.previousLocationSaved === true ? this.activeModal.close() : this.activeModal.dismiss('Cancel');
	}

	close() {
		this.previousLocationSaved === true ? this.activeModal.close() : this.activeModal.dismiss('Cancel');
	}
}
