import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function PatternMessageValidator(pattern: string, customMessage: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors => {
		if (control.value && pattern) {
			const valid = new RegExp(pattern).test(control.value);
			if (valid !== true) {
				const error = { patternMessageValidator: control.value };
				control.setErrors(error);
				return { ...error, customMessage: customMessage };
			}
		}

		return null;
	};
}
