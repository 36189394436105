import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManagedDevice, ManagedDeviceType, Unit } from '@razberi-ui/core/data-types';
import { PageSidePanelService, TableSettings } from '@razberi-ui/shared';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-root-monitor-cloud-selected-units-list-modal',
	templateUrl: './selected-units-list-modal.component.html',
	styleUrls: ['./selected-units-list-modal.component.scss'],
})
export class SelectedUnitsListModalComponent {
	@Input() units: Observable<Unit[]>;

	tableSettings: TableSettings;

	constructor(private readonly activeModal: NgbActiveModal, private readonly sidePanelService: PageSidePanelService) {}

	ngOnInit() {
		this.tableSettings = {
			useSearch: false,
			useEdit: false,
			usePageSize: false,
			hidePagination: false,
			columnConfig: [
				{
					primaryKey: 'name',
					header: 'Device Name',
					useForSearch: false,
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return { text: value };
					},
					clickHandlerFunction: (params) => this.viewDeviceDetails(params),
				},
				{ primaryKey: 'modelName', header: 'Model', useForSearch: false },
				{ primaryKey: 'serialNumber', header: 'Serial Number', useForSearch: false },
				{ primaryKey: 'locationName', header: 'Location', useForSearch: false, transform: (value, tableItem) => value ?? '--' },
				{ primaryKey: 'tags', header: 'Tags', useForSearch: false, cellType: 'pills' },
				{
					primaryKey: 'status',
					header: 'Status',
					useForSearch: false,
					cellType: 'device-status',
					transform: (value, tableItem) => {
						return {
							deviceType: 'Unit',
							status: value,
						};
					},
				},
				{
					primaryKey: 'managedDevices',
					header: 'Devices Managed',
					useForSearch: false,
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return {
							text: value?.filter((md) => md.type !== ManagedDeviceType.Agent)?.length ?? 0,
							disabled: (value?.filter((md) => md.type !== ManagedDeviceType.Agent)?.length ?? 0) == 0,
							tooltip: this.getManagedDevicesTooltip(value),
						};
					},
					clickHandlerFunction: (params) => this.viewDeviceManaged(params),
				},
			],
		};
	}

	close() {
		this.activeModal.close({ success: false });
	}

	viewDeviceDetails(unit: Unit) {
		this.sidePanelService.helpers.openUnitSidePanel(unit.name, 'details', unit.accountId, unit.unitId, false, null);
	}

	viewDeviceManaged(unit: Unit) {
		this.sidePanelService.helpers.openUnitSidePanel(unit.name, 'managed', unit.accountId, unit.unitId, false, null);
	}

	getManagedDevicesTooltip(managedDevices: ManagedDevice[]) {
		const mds = managedDevices?.filter((md) => md.type !== ManagedDeviceType.Agent);
		if ((mds?.length || 0) == 0) return { text: 'No managed devices found.' };
		else return { text: `Click to view ${mds.length} managed devices.` };
	}
}
