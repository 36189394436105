import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { ManageTaskType } from '@razberi-ui/core/data-types';
import { AlertMessageService, CoreSearchItem, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { ManageService } from '../../../services/manage.service';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-settings-manage-form',
	templateUrl: './settings-manage-form.component.html',
	styleUrls: ['./settings-manage-form.component.scss'],
})
export class SettingsManageFormComponent implements OnInit {
	subscriptions: Subscription = new Subscription();
	formIsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly: boolean = true;
	settingsForm: UntypedFormGroup;
	availableManageTaskTypes: CoreSearchItem<ManageTaskType>[] = [];
	selectedManageTaskTypes: CoreSearchItem<ManageTaskType>[] = [];

	constructor(
		private readonly logger: NGXLogger,
		readonly settingsService: CoreServerSettingsService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly manageService: ManageService
	) {}

	ngOnInit(): void {
		this.buildForm();
		this.subscriptions.add(
			this.settingsForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.formIsValid$.next(this.settingsForm.valid === true);
			})
		);

		this.manageService.api.getAllTaskTypes().subscribe((taskTypes) => {
			if (taskTypes == null) return;
			this.availableManageTaskTypes = (taskTypes?.filter((t) => t.taskTypeId > 0) || []).map((taskType: ManageTaskType) => {
				return { value: taskType, label: taskType.name };
			});
			this.setSelectedTaskTypes();
		});

		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				if (settings?.manage != null) this.settingsForm?.patchValue(settings?.manage);
				else this.settingsForm.reset();
				this.setSelectedTaskTypes();
				this.configureToolbar();
			})
		);
	}

	setSelectedTaskTypes() {
		const selected = this.settingsForm?.controls?.disabledTaskTypes.value || [];
		this.selectedManageTaskTypes = this.availableManageTaskTypes.filter((tt) => selected.some((s) => s === tt.value.taskTypeId));
	}

	buildForm() {
		this.settingsForm = this.formBuilder.group({
			disabledTaskTypes: [[]],
		});
		this.settingsForm.disable();
	}

	configureToolbar() {
		const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
		if (this.readonly === true) {
			c.buttons.push({
				text: 'Edit',
				icon: 'edit',
				color: 'primary',
				isEnabled: this.settingsService.data.settings?.manage != null,
				click: () => {
					this.readonly = false;
					this.settingsForm.enable();
					this.configureToolbar();
				},
			});
		} else {
			c.buttons.push({
				text: 'Cancel',
				icon: 'times-circle',
				color: 'light',
				isEnabled: this.settingsService.data.settings?.manage != null,
				click: () => {
					this.readonly = true;
					this.settingsForm.disable();
					this.configureToolbar();
				},
			});
			c.buttons.push({
				text: 'Save ',
				icon: 'check-circle',
				color: 'primary',
				isEnabled$: this.formIsValid$.asObservable(),
				click: () => {
					this.saveSettings();
				},
			});
		}
		this.pageConfigService.data.toolbarConfig = c;
	}

	saveSettings() {
		this.settingsService.api.setManage(this.settingsForm.value).subscribe((_) => {
			this.readonly = true;
			this.settingsForm.disable();
			this.configureToolbar();
			this.alertMessageService.success('The Manage settings were updated successfully.');
			this.settingsService.api.getFullSettings().subscribe();
		});
	}

	selectedManageTaskTypesChanged(taskTypes: CoreSearchItem<ManageTaskType>[]) {
		this.settingsForm.controls.disabledTaskTypes.setValue(
			this.selectedManageTaskTypes?.map((item: CoreSearchItem<ManageTaskType>) => item.value.taskTypeId) || []
		);
		this.logger.debug('changed', this.settingsForm.controls.disabledTaskTypes.value);
	}
}
