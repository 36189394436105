<div class="col-6">
	<app-root-monitor-cloud-auth-password-form
		[isCurrentUser]="true"
		[requireExisting]="true"
		[autoFocus]="false"
		(isValid)="onIsValid($event)"
		(changes)="onChanges($event)"
		(submit)="onSubmit()"
		[reset$]="reset$"></app-root-monitor-cloud-auth-password-form>
</div>
