<div *ngIf="settingsService.data.settings?.aws == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.aws != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="accessKey" class="form-label">Access Key</label>
		<div ngbTooltip="The AWS access key to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="accessKey" id="accessKey" />
		<shared-input-validation-messages [control]="settingsForm.controls.accessKey"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="secretKey" class="form-label">Secret Key</label>
		<div ngbTooltip="The AWS secret key to use. Leave blank to keep the current secret key." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="secretKey" id="secretKey" />
		<shared-input-validation-messages [control]="settingsForm.controls.secretKey"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="region" class="form-label">Region</label>
		<div ngbTooltip="The AWS region name." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="region" id="region" />
		<shared-input-validation-messages [control]="settingsForm.controls.region"></shared-input-validation-messages>
	</div>
</form>
