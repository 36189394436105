import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { Account, AuthToken, LoginException, LoginResult, User, UserRole } from '@razberi-ui/core/data-types';
import { CloudApiConfigService, CloudApiConstants, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { PageConfigService } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private store: {
		hasInitialized: boolean;
		hasInitialized$: BehaviorSubject<boolean>;
		isAuthenticated: boolean;
		isAuthenticated$: BehaviorSubject<boolean>;
		preAuthAction: Promise<void>;
		postAuthAction: any;
		authToken: AuthToken;
		remember: boolean;
		redirectUrl: string;
		changePasswordToken: string;
		user: User;
		account: Account;
	} = {
		hasInitialized: false,
		hasInitialized$: new BehaviorSubject(undefined),
		isAuthenticated: undefined,
		isAuthenticated$: new BehaviorSubject(undefined),
		preAuthAction: undefined,
		postAuthAction: undefined,
		authToken: undefined,
		remember: true,
		redirectUrl: undefined,
		changePasswordToken: undefined,
		user: undefined,
		account: undefined,
	};

	constructor(
		private readonly logger: NGXLogger,
		private readonly httpClient: HttpClient,
		private readonly configService: CloudApiConfigService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly router: Router,
		private readonly pageConfigService: PageConfigService
	) {}

	get data() {
		const store = this.store;

		return {
			get hasInitialized(): boolean {
				return store.hasInitialized;
			},
			get isAuthenticated(): boolean {
				return store.isAuthenticated;
			},
			set preAuthAction(action: Promise<void>) {
				store.preAuthAction = action;
			},
			set postAuthAction(action: any) {
				store.postAuthAction = action;
			},
			get authToken(): AuthToken {
				return store.authToken;
			},
			get redirectUrl(): string {
				return store.redirectUrl;
			},
			set redirectUrl(value: string) {
				store.redirectUrl = value;
			},
			get changePasswordToken(): string {
				return store.changePasswordToken;
			},
			set changePasswordToken(value: string) {
				store.changePasswordToken = value;
			},
			get account(): Account {
				return store.account;
			},
			get user(): User {
				return store.user;
			},
			get storageAuthToken(): string {
				let ts = localStorage.getItem(CloudApiConstants.storageKeys.authToken);
				if (ts == null) ts = sessionStorage.getItem(CloudApiConstants.storageKeys.authToken);
				return ts;
			},
			get storageEmail(): string {
				let ts = localStorage.getItem(CloudApiConstants.storageKeys.email);
				if (ts == null) ts = sessionStorage.getItem(CloudApiConstants.storageKeys.email);
				return ts;
			},
			get gdprComplianceRequired(): boolean {
				return typeof store.authToken.gdprComplianceRequired === 'string'
					? store.authToken.gdprComplianceRequired === 'true'
					: store.authToken.gdprComplianceRequired;
			},
			set gdprComplianceRequired(value: boolean) {
				store.authToken.gdprComplianceRequired = value;
				if (store.remember === true) localStorage.setItem(CloudApiConstants.storageKeys.authToken, JSON.stringify(store.authToken));
				else sessionStorage.setItem(CloudApiConstants.storageKeys.authToken, JSON.stringify(store.authToken));
			},
			get resellerComplianceRequired(): boolean {
				return typeof store.authToken.resellerComplianceRequired === 'string'
					? store.authToken.resellerComplianceRequired === 'true'
					: store.authToken.resellerComplianceRequired;
			},
			set resellerComplianceRequired(value: boolean) {
				store.authToken.resellerComplianceRequired = value;
				if (this._remember === true) localStorage.setItem(CloudApiConstants.storageKeys.authToken, JSON.stringify(store.authToken));
				else sessionStorage.setItem(CloudApiConstants.storageKeys.authToken, JSON.stringify(store.authToken));
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get hasInitialized$(): Observable<boolean> {
				return store.hasInitialized$.asObservable();
			},
			get isAuthenticated$(): Observable<boolean> {
				return store.isAuthenticated$.asObservable();
			},
		};
	}

	private get internal() {
		return {
			handleLoginResponse: (token: any, remember: boolean): LoginResult => {
				this.store.authToken = token;
				this.store.user = JSON.parse(token.user);
				this.store.account = JSON.parse(token.account);
				this.store.remember = remember;
				if (remember) {
					localStorage.setItem(CloudApiConstants.storageKeys.authToken, JSON.stringify(this.store.authToken));
					localStorage.setItem(CloudApiConstants.storageKeys.email, this.store.user.email);
				} else {
					sessionStorage.setItem(CloudApiConstants.storageKeys.authToken, JSON.stringify(this.store.authToken));
					localStorage.removeItem(CloudApiConstants.storageKeys.email);
				}
				this.store.isAuthenticated = true;
				this.store.isAuthenticated$.next(this.store.isAuthenticated);
				this.pageConfigService.data.isAuthenticated = this.store.isAuthenticated;
				this.settingsService.api.getSettings().subscribe();
				return LoginResult.Success;
			},
			// handleApiTokenError: (error: any): LoginResult => {
			// 	//console.error('login error', error);
			// 	let result = LoginResult.Error;
			// 	if (error instanceof HttpErrorResponse) {
			// 		if (error.error && error.error.error && error.error.error === 'invalid_grant') {
			// 			const desc: string = error.error.error_description;
			// 			if (desc === 'The user email or password is incorrect.') result = LoginResult.InvalidCredentials;
			// 			if (desc.startsWith('The user password must be changed.')) {
			// 				this.store.changePasswordToken = desc.substring(desc.lastIndexOf('Token: ') + 'Token: '.length);
			// 				result = LoginResult.ChangePassword;
			// 			}
			// 		}
			// 	}

			// 	if (this.data.postAuthAction != null) {
			// 		this.data.postAuthAction(undefined, result);
			// 	}

			// 	return result;
			// },
		};
	}

	get helpers() {
		return {
			initialize: (): Promise<void> => {
				// manually create a promise so we can subscribe to settings & wait for it to load before initializing auth
				return new Promise((resolve) => {
					// wait for config to load
					this.configService.streams.config$.subscribe((config) => {
						if (config == null) return;
						let t: AuthToken;
						let ts: string;
						ts = localStorage.getItem(CloudApiConstants.storageKeys.authToken);
						if (ts) this.store.remember = true;
						else ts = sessionStorage.getItem(CloudApiConstants.storageKeys.authToken);
						if (ts) {
							t = JSON.parse(ts);
							if (t)
								this.helpers.refresh(t.refresh_token).subscribe((_) => {
									this.store.hasInitialized = true;
									this.store.hasInitialized$.next(this.store.hasInitialized);
									resolve(null);
								});
						} else {
							this.store.hasInitialized = true;
							this.store.hasInitialized$.next(this.store.hasInitialized);
							resolve(null);
						}
					});
				});
			},
			login: (email: string, password: string, remember: boolean): Observable<LoginResult> => {
				if (this.store.isAuthenticated === true) return of(LoginResult.Success);
				this.helpers.clearState(false, false);

				return this.httpClient
					.post<AuthToken>(
						`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.auth.token}`,
						`grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&client_id=monitor.razberi.net`,
						{ headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }
					)
					.pipe(
						map((token: AuthToken) => {
							// this.logger.info('login token', token);
							if (this.helpers.userHasRole(UserRole.Installer, JSON.parse(token.user)) == true) {
								this.helpers.clearState(true, true);
								return LoginResult.InvalidUser;
							}
							return this.internal.handleLoginResponse(token, remember);
						}),
						catchError((error) => {
							// this.logger.error('login error', error);
							this.helpers.clearState(true, true);
							if (error instanceof HttpErrorResponse && error.error && error.error.error && error.error.error_description) {
								const lex: LoginException = JSON.parse(error.error.error_description);
								if (lex) {
									if (lex.result === LoginResult.ChangePassword) this.store.changePasswordToken = lex.resultData;
									return of(lex.result);
								}
							}
							return of(LoginResult.Error);
						})
					);
			},
			ssoLogin: (refreshToken: string): Observable<LoginResult> => {
				if (this.data.isAuthenticated === true) return of(LoginResult.Success);
				this.logger.info('sso login', refreshToken);
				this.helpers.clearState(false, false);
				return this.helpers.refresh(refreshToken);
			},
			refresh: (refreshToken: string): Observable<LoginResult> => {
				return this.httpClient
					.post<AuthToken>(
						`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.auth.token}`,
						`grant_type=refresh_token&refresh_token=${encodeURIComponent(refreshToken)}&client_id=monitor.razberi.net`,
						{ headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }
					)
					.pipe(
						map((token: AuthToken) => {
							// this.logger.info('refresh token', token);
							if (this.helpers.userHasRole(UserRole.Installer, JSON.parse(token.user)) == true) {
								this.helpers.clearState(true, true);
								return LoginResult.InvalidUser;
							}
							return this.internal.handleLoginResponse(token, true);
						}),
						catchError((error) => {
							// this.logger.error('refresh error', error);
							this.helpers.clearState(true, true);
							if (error instanceof HttpErrorResponse && error.error && error.error.error && error.error.error_description) {
								const lex: LoginException = JSON.parse(error.error.error_description);
								if (lex) return of(lex.result);
							}
							return of(LoginResult.Error);
						})
					);
			},
			logout: (): Observable<void> => {
				if (this.store.isAuthenticated !== true) return of();
				return this.httpClient.post<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.auth.logout}`, {}).pipe(
					catchError((_) => {
						// this.logger.error('logout error', error);
						return of();
					}),
					finalize(() => {
						this.helpers.clearState(true, true);
						this.router.navigate(['/login']);
					})
				);
			},
			getLoginResultMessage: (loginResult: LoginResult) => {
				switch (loginResult) {
					case LoginResult.Success:
						return null;
					case LoginResult.InvalidCredentials:
						return 'The user email or password is incorrect.';
					case LoginResult.ChangePassword:
						return 'The user password must be changed.';
					case LoginResult.UserPending:
						return 'The user is pending.';
					case LoginResult.AccountPending:
						return 'The account is pending.';
					case LoginResult.UserNotAllowed:
						return 'The user is not allowed.';
					case LoginResult.UserLocked:
						return 'The user is locked.';
					case LoginResult.InvalidUser:
						return 'The user is inactive.';
					case LoginResult.InvalidAccount:
						return 'The account is inactive.';
					case LoginResult.InvalidClientId:
						return 'Invalid client Id.';
					case LoginResult.Offline:
						return 'Offline for maintenance.';
					case LoginResult.Saml2AssertionFailed:
						return 'The SAML2 assertion failed.';
					case LoginResult.Saml2MissingAttributes:
						return 'The SAML2 assertion is missing required user attributes.';
					case LoginResult.Saml2MissingGroup:
						return 'The SAML2 assertion is missing the user group.';
					case LoginResult.Saml2Error:
						return 'An unknown SAML2 login error has occurred.';
					default:
						return 'An unknown login error has occurred.';
				}
			},
			userHasRole: (role: UserRole, user: User = this.data.user): boolean => {
				if ((user?.roles?.length || 0) == 0) return false;
				return user.roles.find((r) => r == role) != null;
			},
			userHasAnyRole: (roles: UserRole[], user: User = this.data.user): boolean => {
				if ((user?.roles?.length || 0) == 0) return false;
				if ((roles?.length || 0) == 0) return false;
				for (let i = 0; i < roles.length; i++) {
					if (this.helpers.userHasRole(roles[i], user) == true) return true;
				}
				return false;
			},
			updateStorage: (authToken: any): void => {
				if (typeof authToken !== 'string') authToken = JSON.stringify(authToken);
				if (this.store.remember === true) localStorage.setItem(CloudApiConstants.storageKeys.authToken, authToken);
				else sessionStorage.setItem(CloudApiConstants.storageKeys.authToken, authToken);
			},
			clearState: (clearRedirectUrl: boolean, clearSettings: boolean): void => {
				this.store.isAuthenticated = false;
				this.store.isAuthenticated$.next(this.store.isAuthenticated);
				this.pageConfigService.data.isAuthenticated = this.store.isAuthenticated;
				this.store.authToken = null;
				this.store.user = null;
				this.store.account = null;
				if (clearRedirectUrl === true) {
					this.store.redirectUrl = null;
				}
				this.store.changePasswordToken = null;
				sessionStorage.clear();
				if (this.store.remember === true) localStorage.removeItem(CloudApiConstants.storageKeys.authToken);
				if (clearSettings === true) this.settingsService.api.getCoreSettings().subscribe();
			},
			updateAccount: (account: Account): void => {
				this.store.account = account;
			},
			updateUser: (user: User): void => {
				this.store.user = user;
			},
		};
	}

	get userEmailTokens() {
		return {
			requestResetPasswordToken: (resetPasswordEmailDto: any): Observable<void> => {
				return this.httpClient.post<any>(
					this.configService.data.config.apiHost + CloudApiConstants.apiPath.userEmailTokens.requestResetPasswordToken,
					resetPasswordEmailDto,
					{}
				);
			},
			verifyNewUserToken: (token: string): Observable<void> => {
				return this.httpClient.get<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userEmailTokens.verifyNewUserToken(token)}`
				);
			},
			verifyResetPasswordToken: (token: string): Observable<void> => {
				return this.httpClient.get<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userEmailTokens.verifyResetPasswordToken(token)}`
				);
			},
			finalizeNewUserToken: (token: string, password: string): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userEmailTokens.finalizeNewUserToken(token)}`,
					`"${password}"`,
					{ headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
				);
			},
			finalizeResetPasswordToken: (token: string, password: string): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userEmailTokens.finalizeResetPasswordToken(token)}`,
					`"${password}"`,
					{ headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
				);
			},
		};
	}
}
