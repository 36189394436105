import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag, Unit } from '@razberi-ui/core/data-types';
import { DynamicTag } from '@razberi-ui/shared';
import { SelectableObject, SelectableObjectMode } from '../../../../data-types/selectable-object.model';

@Component({
	selector: 'app-root-monitor-cloud-unit-selector-item',
	templateUrl: './unit-selector-item.component.html',
	styleUrls: ['./unit-selector-item.component.scss'],
})
export class UnitSelectorItemComponent {
	@Input() selectableUnit: SelectableObject<Unit>;
	@Output() viewDeviceDetails: EventEmitter<Unit> = new EventEmitter();

	selectableModeOptions: any = SelectableObjectMode;

	constructor() {}

	ngOnInit(): void {}

	selectUnit() {
		if (this.selectableUnit.onSelected != null) this.selectableUnit.onSelected(this.selectableUnit.object);
	}

	getItemTagDescSummary(tags: Tag[]): string {
		const summaryLimit = 1;
		if ((tags?.length ?? 0) === 0) return '--';
		return `${tags
			.slice(0, summaryLimit)
			.map((t) => t.name)
			.join(', ')}${tags.length > summaryLimit ? ' (+' + (tags.length - summaryLimit) + ')' : ''}`;
	}

	getItemTagDescFull(tags: Tag[]) {
		if ((tags?.length ?? 0) === 0) return '--';
		return tags.map((t) => t.name).join(', ');
	}

	getItemUpdateTagDescSummary(updateTags: DynamicTag[]): string {
		const summaryLimit = 1;
		if ((updateTags?.length ?? 0) === 0) return '--';
		return `${updateTags
			.slice(0, summaryLimit)
			.map((t) => t.title)
			.join(', ')}${updateTags.length > summaryLimit ? ' (+' + (updateTags.length - summaryLimit) + ')' : ''}`;
	}

	getItemUpdateTagDescFull(updateTags: DynamicTag[]) {
		if ((updateTags?.length ?? 0) === 0) return '--';
		return updateTags.map((t) => t.title).join(', ');
	}

	viewDetails() {
		this.viewDeviceDetails.emit(this.selectableUnit.object);
	}
}
