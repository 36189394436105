<div #tree style="width: 100%"></div>
<ul #dropdown [style.display]="showMenu === true ? 'block' : 'none'" role="menu" class="d3ContextMenu">
	<ng-container *ngIf="(network?.rootDeviceKey?.length || 0) == 0">
		<li role="presentation">
			<button type="button" role="menuitem" (click)="addManagedDevice(selectedNode)" class="px-1">
				<fa-icon icon="plus" class="mx-2 text-primary"></fa-icon>
				<span>Set Root Device</span>
			</button>
		</li>
	</ng-container>
	<ng-container *ngIf="network?.rootDeviceKey?.length > 0">
		<ng-container *ngIf="(selectedNode?.data?.managedDeviceId || 0) == 0">
			<li role="presentation">Device Not Available</li>
		</ng-container>
		<ng-container *ngIf="selectedNode?.data?.managedDeviceId > 0">
			<li role="presentation" *ngIf="selectedNode?.data?.managedDeviceId > 0">
				<button type="button" role="menuitem" (click)="viewDeviceDetails(selectedNode)" class="px-1">
					<fa-icon icon="eye" class="mx-2 text-primary"></fa-icon>
					<span>View Device Details</span>
				</button>
			</li>
			<li role="presentation" *ngIf="selectedNode?.data?.managedDeviceId > 0">
				<button type="button" role="menuitem" (click)="viewDeviceAlerts(selectedNode)" class="px-1">
					<fa-icon icon="bell" class="mx-2 text-primary"></fa-icon>
					<span>View Alerts</span>
				</button>
			</li>
			<ng-container *ngIf="canManageDevices === true">
				<li role="presentation" *ngIf="selectedNode?.data?.managedDeviceId > 0">
					<button type="button" role="menuitem" (click)="editManagedDevice(selectedNode)" class="px-1">
						<fa-icon icon="pencil" class="mx-2 text-primary"></fa-icon>
						<span>Edit Device</span>
					</button>
				</li>
			</ng-container>
			<ng-container *ngIf="canManageNetworks === true">
				<li role="presentation" *ngIf="selectedNode?.data?.managedDeviceId > 0">
					<button type="button" role="menuitem" (click)="addManagedDevice(selectedNode)" class="px-1">
						<fa-icon icon="link" class="mx-2 text-primary"></fa-icon>
						<span>Link Device</span>
					</button>
				</li>
				<li role="presentation" *ngIf="selectedNode?.data?.managedDeviceId > 0 && selectedNode?.data?.isNetworkRoot !== true">
					<button type="button" role="menuitem" (click)="setNetworkRoot(selectedNode)" class="px-1">
						<fa-icon icon="compass" class="mx-2 text-primary"></fa-icon>
						<span>Set as Root</span>
					</button>
				</li>
				<li role="presentation">
					<button type="button" role="menuitem" (click)="removeManagedDevice(selectedNode)" class="px-1">
						<fa-icon icon="trash" class="mx-2 text-danger"></fa-icon>
						<span>Remove Device</span>
					</button>
				</li>
			</ng-container>
		</ng-container>
	</ng-container>
</ul>
