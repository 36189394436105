import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorBlobInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				// only handle blob errors in this inerceptor
				if (error.error instanceof Blob !== true || error.error.type !== 'application/json') return throwError(error);
				return this.parseErrorBlob(error);
			})
		);
	}

	parseErrorBlob(err: HttpErrorResponse): Observable<any> {
		const reader: FileReader = new FileReader();
		const obs = new Observable((observer: any) => {
			reader.onloadend = (e) => {
				observer.error(
					new HttpErrorResponse({
						...err,
						error: JSON.parse(reader.result as string),
					})
				);
				observer.complete();
			};
		});
		reader.readAsText(err.error);
		return obs;
	}
}
