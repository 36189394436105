import { Component } from '@angular/core';
import { AuthService, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { NGXLogger } from 'ngx-logger';
import { DataStoreService } from '../../../services/data-store.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root-monitor-cloud-app-initalization',
	templateUrl: './app-initalization.component.html',
	styleUrls: ['./app-initalization.component.scss'],
})
export class AppInitalizationComponent {
	settingsInitialized: boolean = false;
	authInitialized: boolean = false;

	get progress(): number {
		let value = 0;
		if (this.settingsInitialized === true) {
			value += 50;
		}
		if (this.authInitialized === true) {
			value += 50;
		}
		return value;
	}

	get progressStyle(): string {
		return `${this.progress}%`;
	}

	constructor(
		private readonly logger: NGXLogger,
		private readonly settingsService: CoreServerSettingsService,
		private readonly authService: AuthService,
		private readonly dataStoreService: DataStoreService,
		private readonly router: Router
	) {}

	ngOnInit() {
		this.settingsService.helpers.initialize().then(() => {
			this.logger.debug('app-init: settings initialized');
			this.settingsInitialized = true;
			this.authService.helpers.initialize().then(() => {
				this.logger.debug('app-init: auth initialized');
				this.authInitialized = true;
				this.checkInitialized();
			});
		});
	}

	checkInitialized() {
		if (this.settingsInitialized === true && this.authInitialized === true) {
			const redirectUrl =
				[undefined, null, '/init'].includes(this.dataStoreService.data.initRedirectUrl) === true
					? '/dashboard'
					: this.dataStoreService.data.initRedirectUrl;
			this.logger.debug('app-init: redirect', redirectUrl);
			this.router.navigateByUrl(redirectUrl);
		}
	}
}
