import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { SsoSettings } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { AppRootMonitorCloudConstants } from '../../../app-root-monitor-cloud.constant';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-settings-sso-form',
	templateUrl: './settings-sso-form.component.html',
	styleUrls: ['./settings-sso-form.component.scss'],
})
export class SettingsSsoFormComponent {
	@Output() onEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
	subscriptions: Subscription = new Subscription();
	formIsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly: boolean = true;
	settingsForm: UntypedFormGroup;
	ssoSettings: SsoSettings;
	selectedTab: string = 'general';

	constructor(
		readonly settingsService: CoreServerSettingsService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly confirmService: ConfirmService
	) {}

	ngOnInit(): void {
		this.buildForm();
		this.subscriptions.add(
			this.settingsForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.formIsValid$.next(this.settingsForm.valid === true);
			})
		);

		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				this.ssoSettings = settings?.sso;
				this.loadForm();
				this.configureToolbar();
			})
		);
	}

	buildForm() {
		this.settingsForm = this.formBuilder.group({
			enable: [null],
			enforce: [null],
			hideLoginButton: [null],
			signRequests: [null],
			encryptAssertions: [null],
			adminGroup: [null, [Validators.required]],
			userGroup: [null, [Validators.required]],
			installerGroup: [null],
			spSignatureCertificate: [null, [Validators.pattern(AppRootMonitorCloudConstants.regex.certificate)]],
			spEncryptionCertificate: [null, [Validators.pattern(AppRootMonitorCloudConstants.regex.certificate)]],
			idpEntityId: [null, [Validators.required]],
			idpRequestUri: [null, [Validators.required, Validators.pattern(AppRootMonitorCloudConstants.regex.uri)]],
			idpCertificate: [null, [Validators.required, Validators.pattern(AppRootMonitorCloudConstants.regex.certificate)]],
		});
		this.settingsForm.disable();
	}

	loadForm() {
		if (this.ssoSettings != null) this.settingsForm?.patchValue(this.ssoSettings);
		else this.settingsForm.reset();
	}

	configureToolbar() {
		const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
		if (this.readonly === true) {
			c.buttons.push({
				text: 'Edit',
				icon: 'edit',
				color: 'primary',
				isEnabled: this.settingsService.data.settings?.sso != null,
				click: () => {
					this.readonly = false;
					this.settingsForm.enable();
					this.configureToolbar();
					this.onEditing.emit(true);
				},
			});
		} else {
			c.buttons.push({
				text: 'Cancel',
				icon: 'times-circle',
				color: 'light',
				isEnabled: this.settingsService.data.settings?.sso != null,
				click: () => {
					this.readonly = true;
					this.settingsForm.disable();
					this.loadForm();
					this.configureToolbar();
					this.onEditing.emit(false);
				},
			});
			c.buttons.push({
				text: 'Save',
				icon: 'check-circle',
				color: 'primary',
				isEnabled$: this.formIsValid$.asObservable(),
				click: () => {
					this.saveSettings();
				},
			});
		}

		this.pageConfigService.data.toolbarConfig = c;
	}

	confirmEnforceChange(event) {
		const msg = event.target.checked
			? 'Checking this forces all logins via SSO. Only do this if you have tested SSO login successfully.'
			: 'Un-Checking this allows form based login. Only do this if you do not wish to force all logins via SSO.';
		this.confirmService
			.confirm({
				title: 'Are you sure?',
				text: msg,
				icon: 'exclamation-triangle',
				confirmButtonText: 'Yes',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'check',
			})
			.then(
				(_) => {
					//leave alone
				},
				(_) => {
					//revert
					event.target.checked = !event.target.checked;
				}
			);
	}

	saveSettings() {
		this.settingsService.api.setSso(this.settingsForm.value).subscribe((_) => {
			this.readonly = true;
			this.settingsForm.disable();
			this.configureToolbar();
			this.alertMessageService.success('The SSO settings were updated successfully.');
			this.settingsService.api.getFullSettings().subscribe();
			this.onEditing.emit(false);
		});
	}
}
