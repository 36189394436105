import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, CoreServerSettingsService, UnitAndDeviceTotals } from '@razberi-ui/api/cloud-api';
import { PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { Observable, Subject, Subscription } from 'rxjs';
import { SubscriptionService } from '../../services/subscription.service';
import { Account, AccountType, UserRole } from '@razberi-ui/core/data-types';
import { AccountPanelToggleEvent } from '../../data-types/account-panel-toggle-event.model';
import { DeviceEditModalComponent } from './device-edit-modal/device-edit-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent {
	subscriptions: Subscription = new Subscription();
	canManageAccounts: boolean = false;
	groupByAccount: boolean = false;
	showRegisterButton: boolean = false;
	isInitialized: boolean = false;
	refreshSubject: Subject<void> = new Subject<void>();
	refresh$: Observable<void> = this.refreshSubject.asObservable();
	isRequestingUnitAndDeviceTotals: boolean = false;
	unitAndDeviceTotals: UnitAndDeviceTotals[] = [];

	constructor(
		private readonly modalService: NgbModal,
		private readonly pageConfigService: PageConfigService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly settingsService: CoreServerSettingsService
	) {}

	ngOnInit() {
		this.canManageAccounts = this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider;
		this.groupByAccount = this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider;
		this.initButtons();
		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				this.initButtons();
			})
		);
	}

	initButtons() {
		this.showRegisterButton =
			this.settingsService.data.settings?.cloudUI?.allowDevicePageRegistration === true &&
			(this.authService.helpers.userHasRole(UserRole.Administrator) == true ||
				this.authService.data.account.type === AccountType.Global ||
				this.authService.data.account.type === AccountType.Provider);
		if (this.canManageAccounts || this.showRegisterButton) {
			const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
			if (this.canManageAccounts)
				c.checkboxes.push({
					text: 'Group by account',
					icon: 'layer-group',
					isChecked: this.groupByAccount,
					change: (checked: boolean) => {
						this.onGroupByAccountChange(checked);
					},
				});
			if (this.showRegisterButton)
				c.buttons.push({
					text: 'Register Agent',
					icon: 'plus',
					color: 'primary',
					click: () => {
						this.registerDevice();
					},
				});
			this.pageConfigService.data.toolbarConfig = c;
		}
		this.isInitialized = true;
	}

	panelToggleEvent(event: AccountPanelToggleEvent) {
		// not used
	}

	ngOnDestroy() {
		if (this.canManageAccounts || this.showRegisterButton) this.pageConfigService.data.toolbarConfig = null;
		this.subscriptions.unsubscribe();
	}

	registerDevice() {
		const modal: NgbModalRef = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.result.then(
			(result) => {
				this.refreshSubject.next();
			},
			(_) => {}
		);
	}

	onGroupByAccountChange(checked: boolean) {
		this.groupByAccount = checked;
	}

	onUpdate() {
		this.refreshSubject.next();
	}

	showDownloadCsv(account: Account) {
		return this.subscriptionService.helpers.isGlobalOrIsTypeAndStatusValid(account.subscriptionType, account.subscriptionStatus);
	}
}
