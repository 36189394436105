<div *ngIf="settingsService.data.settings?.smtp == null">
	<app-context-message type="info">The settings could not be loaded.</app-context-message>
</div>
<form *ngIf="settingsService.data.settings?.smtp != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="host" class="form-label">Host</label>
		<div ngbTooltip="The SMTP host to use for email." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="host" id="host" />
		<shared-input-validation-messages [control]="settingsForm.controls.host"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="port" class="form-label">Port</label>
		<div ngbTooltip="The SMTP port to use for email." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="port" id="port" />
		<shared-input-validation-messages [control]="settingsForm.controls.port"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input type="checkbox" class="form-check-input" formControlName="useTls" id="useTls" />
			<label for="useTls" class="form-check-label">Use TLS</label>
			<div ngbTooltip="Whether or not to use TLS." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
	<div class="mb-3 col-12">
		<label for="from" class="form-label">From Address</label>
		<div ngbTooltip="The email address from which notifications are sent." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="from" id="from" />
		<shared-input-validation-messages [control]="settingsForm.controls.from"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="fromDisplay" class="form-label">From Address Display</label>
		<div ngbTooltip="The email address display name from which notifications are sent."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="fromDisplay" id="fromDisplay" />
		<shared-input-validation-messages [control]="settingsForm.controls.fromDisplay"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="username" class="form-label">Username</label>
		<div ngbTooltip="The SMTP username to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="username" id="username" autocomplete="new-password" />
		<shared-input-validation-messages [control]="settingsForm.controls.username"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="password" class="form-label">Password</label>
		<div ngbTooltip="The SMTP password to use. Leave blank to keep the current password."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="password" id="password" />
	</div>
</form>
