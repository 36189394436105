import { EnumAttributes } from '@razberi-ui/shared';

export enum AlertEmailFrequencyType {
	EveryAlert = 0,
	WeeklyReport = 1,
	EveryAlertAndWeeklyReport = 2,
}

export const AlertEmailFrequencyTypeAttributes = new Map<AlertEmailFrequencyType, EnumAttributes>([
	[AlertEmailFrequencyType.EveryAlert, { label: 'Every Alert' }],
	[AlertEmailFrequencyType.WeeklyReport, { label: 'Weekly Report' }],
	[AlertEmailFrequencyType.EveryAlertAndWeeklyReport, { label: 'Every Alert and Weekly Report' }],
]);
