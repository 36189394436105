<div *ngIf="settingsService.data.settings?.fileStore?.s3FileStoreSettings == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.fileStore?.s3FileStoreSettings != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="bucketPrefix" class="form-label">Bucket Prefix</label>
		<div ngbTooltip="The AWS S3 bucket prefix to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="bucketPrefix" id="bucketPrefix" />
		<shared-input-validation-messages [control]="settingsForm.controls.bucketPrefix"></shared-input-validation-messages>
	</div>
</form>
