<div id="modal-container" class="modal-container">
	<div id="modal-header" class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="map-marker-alt"></fa-icon><span class="ps-2">{{ locationId ? 'Edit' : 'New' }} Location</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div id="modal-body" class="modal-body">
		<app-root-monitor-cloud-location-form
			[location]="location"
			(isValid)="onIsValid($event)"
			(changes)="onChanges($event)"
			(submit)="save()"
			[reset$]="reset$"
			ngbAutoFocus></app-root-monitor-cloud-location-form>
	</div>
	<div id="modal-footer" class="modal-footer">
		<button id="btn-cancel" type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button id="btn-save-another" type="button" class="btn btn-dark float-right" (click)="saveAndAdd()" [disabled]="!isValid">
			<fa-icon icon="plus"></fa-icon> Save and Add Another
		</button>
		<button id="btn-save" type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="!isValid">
			<fa-icon icon="check"></fa-icon> Save
		</button>
	</div>
</div>
