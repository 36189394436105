export enum AlertMessageType {
	Success,
	Info,
	Warning,
	Error,
	Primary,
	Secondary,
	Light,
	Dark,
}
