import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertMessageService } from '@razberi-ui/shared';
import { UserService } from '../../../services/user.service';
import { PasswordFormData } from '../../../data-types/password-form-data.model';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-user-password-modal',
	templateUrl: './user-password-modal.component.html',
	styleUrls: ['./user-password-modal.component.scss'],
})
export class UserPasswordModalComponent {
	@Input() accountId: number;
	@Input() userId: number;
	isCurrentUser: boolean;
	isValid: boolean;
	formPassword: PasswordFormData;

	constructor(
		private activeModal: NgbActiveModal,
		private alertMessageService: AlertMessageService,
		private authService: AuthService,
		private userService: UserService
	) {
		this.isCurrentUser = false;
		this.isValid = false;
	}

	ngOnInit() {
		this.isCurrentUser = this.userId === this.authService.data.user.userId;
	}

	ngOnDestroy() {}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(formPassword: PasswordFormData) {
		this.formPassword = formPassword;
	}

	resetPassword() {
		this.userService.api.setUserPassword(this.userId, { ...this.formPassword }).subscribe({
			next: (_) => {
				this.alertMessageService.success(`Password ${this.isCurrentUser ? 'changed' : 'reset'}.`);
				this.activeModal.close();
			},
			error: (error) => {
				this.alertMessageService.error(`Error ${this.isCurrentUser ? 'changing' : 'resetting'} password.`, error);
			},
		});
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
