import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Account, User, UserStatus } from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { AccountService } from '../../../services/account.service';
import { Observable, mergeMap } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-user-modal',
	templateUrl: './user-modal.component.html',
	styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent {
	@Input() userId?: number;
	@Input() accountId: number;

	isCurrentUser: boolean = false;
	isPrimaryUser: boolean = false;
	user: User;
	isValid: boolean = false;
	formUser: User;

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly accountService: AccountService,
		private readonly userService: UserService
	) {}

	ngOnInit() {
		if (this.userId) {
			this.isCurrentUser = this.userId === this.authService.data.user.userId;
			this.userService.api
				.getUser(this.userId)
				.pipe(
					mergeMap((user: User) => {
						this.user = user;
						return this.accountService.api.getAccount(user.accountId);
					})
				)
				.subscribe({
					next: (account: Account) => (this.isPrimaryUser = account.primaryUserId === this.user.userId),
					error: (error) => this.alertMessageService.error('Error loading user.', error),
				});
		}
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(formUser: User) {
		this.formUser = formUser;
	}

	save() {
		let o: Observable<any>;
		let u: User;
		if (this.userId) {
			u = { ...this.user, ...this.formUser };
			if (this.isCurrentUser && this.isPrimaryUser != true) {
				u.roles = this.user.roles;
				if (!this.isPrimaryUser || this.user.status !== UserStatus.Pending) u.status = this.user.status;
			}
			o = this.userService.api.updateUser(u);
		} else {
			u = { accountId: this.accountId, ...this.formUser };
			u.status = UserStatus.Pending;
			o = this.userService.api.createUser(u);
		}
		o.subscribe({
			next: (_) => {
				this.alertMessageService.success(`User ${this.userId ? 'saved' : 'created'}.`);
				this.activeModal.close();
			},
			error: (error) => this.alertMessageService.error(`Error ${this.userId ? 'saving' : 'creating'} user.`, error),
		});
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
