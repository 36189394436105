import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CloudApiConstants } from '../cloud-api.constants';
import { UserMultiAuthTokenType } from '../data-types/user-multi-auth-token-type.model';
import { SearchFilter } from '../data-types/search-filter.model';
import { SearchFilterResult } from '../data-types/search-filter-result.model';
import {
	Account,
	AccountNote,
	AccountObjectIds,
	AccountSubscription,
	Alert,
	AlertProfile,
	AlertType,
	AuditLog,
	CameraStream,
	CoreServerSettings,
	AwsSettings,
	CloudAgentSettings,
	ComplianceSettings,
	FileStoreSettings,
	GlobalSettings,
	LoginSettings,
	ManageSettings,
	MonitorAgentSettings,
	CloudApiSettings,
	SettingsDashboard,
	SinkApiSettings,
	SmsSettings,
	SmtpSettings,
	SsoSettings,
	Location,
	ManageTask,
	ManageTaskExportRequest,
	ManageTaskType,
	ManagedDevice,
	Network,
	RecordingServer,
	SetupRegistrationResult,
	Tag,
	Unit,
	UnitFields,
	Update,
	UpdateType,
	User,
	UserCompliance,
	UserComplianceRegion,
	VmsService,
} from '@razberi-ui/core/data-types';
import { AlertTotals } from '../data-types/alert-totals.model';
import { RegisterUnitDto } from '../data-types/register-unit-dto.model';
import { UnitWarrantyDto } from '../data-types/unit-warranty-dto.model';
import { UpdateUnitDto } from '../data-types/update-unit-dto.model';
import { UnitAndDeviceTotals } from '../data-types/unit-and-device-totals.model';
import { UpdateManagedDeviceDto } from '../data-types/update-managed-device-dto.model';
import { CreateAccountRequest } from '../data-types/create-account-request.model';
import { DashboardDto } from '../data-types/dashboard-dto.model';
import { AlertNotificationTest } from '../data-types/alert-notification-test.model';
import { NetworkAvailableDevicesRequest } from '../data-types/network-available-devices-request.model';
import { CloudApiConfigService } from './cloud-api-config.service';

@Injectable({
	providedIn: 'root',
})
export class CloudApiService {
	blobResponse: any = { responseType: 'blob', observe: 'response' };

	constructor(private readonly configService: CloudApiConfigService, private readonly httpClient: HttpClient) {}

	get api() {
		return {
			getVersion: (): Observable<string> => {
				return this.httpClient.get<string>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.api.getVersion}`);
			},
		};
	}

	get accounts() {
		return {
			getAccountsFilter: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Account>> => {
				return this.httpClient.put<SearchFilterResult<Account>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountsFilter}`,
					searchFilter
				);
			},
			getAccounts: (): Observable<Account[]> => {
				return this.httpClient.get<Account[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccounts}`);
			},
			getProviderAccounts: (): Observable<Account[]> => {
				return this.httpClient.get<Account[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getProviderAccounts}`);
			},
			getAccount: (accountId: number): Observable<Account> => {
				return this.httpClient.get<Account>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccount(accountId)}`);
			},
			getCurrentAccount: (): Observable<Account> => {
				return this.httpClient.get<Account>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getCurrentAccount}`);
			},
			createAccount: (createAccountRequest: CreateAccountRequest): Observable<void> => {
				return this.httpClient.post<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.createAccount}`,
					createAccountRequest
				);
			},
			createAccountPublic: (createAccountRequest: CreateAccountRequest): Observable<void> => {
				return this.httpClient.post<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.createAccountPublic}`,
					createAccountRequest
				);
			},
			updateAccount: (account: Account): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.updateAccount(account.accountId)}`,
					account
				);
			},
			updateCurrentAccount: (account: Account): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.updateCurrentAccount}`, account);
			},
			deleteAccount: (accountId: number): Observable<void> => {
				return this.httpClient.delete<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.deleteAccount(accountId)}`);
			},
			getAccountLogo: (accountId: number): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountLogo(accountId)}`);
			},
			getAccountFavicon: (accountId: number): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountFavicon(accountId)}`);
			},
			getAccountCustomEmailFooter: (accountId: number): Observable<any> => {
				return this.httpClient.get<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountCustomEmailFooter(accountId)}`
				);
			},
			getCurrentAccountLogo: (): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getCurrentAccountLogo}`);
			},
			getCurrentAccountFavicon: (): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getCurrentAccountFavicon}`);
			},
			getCurrentAccountCustomEmailFooter: (): Observable<any> => {
				return this.httpClient.get<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getCurrentAccountCustomEmailFooter}`
				);
			},
			setAccountLogo: (accountId: number, imageDto: any): Observable<any> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setAccountLogo(accountId)}`,
					imageDto
				);
			},
			setAccountFavicon: (accountId: number, imageDto: any): Observable<any> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setAccountFavicon(accountId)}`,
					imageDto
				);
			},
			setAccountCustomEmailFooter: (accountId: number, customEmailFooter: string): Observable<any> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setAccountCustomEmailFooter(accountId)}`,
					{
						value: customEmailFooter,
					}
				);
			},
			setCurrentAccountLogo: (imageDto: any): Observable<any> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setCurrentAccountLogo}`,
					imageDto
				);
			},
			setCurrentAccountFavicon: (imageDto: any): Observable<any> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setCurrentAccountFavicon}`,
					imageDto
				);
			},
			setCurrentAccountCustomEmailFooter: (customEmailFooter: string): Observable<any> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setCurrentAccountCustomEmailFooter}`,
					{
						value: customEmailFooter,
					}
				);
			},
			removeAccountLogo: (accountId: number): Observable<any> => {
				return this.httpClient.delete<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.removeAccountLogo(accountId)}`
				);
			},
			removeAccountFavicon: (accountId: number): Observable<any> => {
				return this.httpClient.delete<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.removeAccountFavicon(accountId)}`
				);
			},
			removeAccountCustomEmailFooter: (accountId: number): Observable<any> => {
				return this.httpClient.delete<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.removeAccountCustomEmailFooter(accountId)}`
				);
			},
			removeCurrentAccountLogo: (): Observable<any> => {
				return this.httpClient.delete<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.removeCurrentAccountLogo}`);
			},
			removeCurrentAccountFavicon: (): Observable<any> => {
				return this.httpClient.delete<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.removeCurrentAccountFavicon}`);
			},
			removeCurrentAccountCustomEmailFooter: (): Observable<any> => {
				return this.httpClient.delete<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.removeCurrentAccountCustomEmailFooter}`
				);
			},
			getAccountNote: (accountId: number): Observable<AccountNote> => {
				return this.httpClient.get<AccountNote>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountNote(accountId)}`
				);
			},
			setAccountNote: (accountNote: AccountNote): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.setAccountNote(accountNote.accountId)}`,
					accountNote
				);
			},
			getAccountData: (accountId: number): Observable<Account> => {
				return this.httpClient.get<Account>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountData(accountId)}`);
			},
			getCurrentAccountData: (): Observable<Account> => {
				return this.httpClient.get<Account>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getCurrentAccountData}`);
			},
			getAccountsData: (accountIds: number[] = null): Observable<Account[]> => {
				return this.httpClient.put<Account[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.account.getAccountsData}`,
					accountIds
				);
			},
		};
	}

	get accountSubscriptions() {
		return {
			getAccountSubscription: (accountId: number): Observable<AccountSubscription> => {
				return this.httpClient.get<AccountSubscription>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.accountSubscription.getAccountSubscription(accountId)}`
				);
			},
			updateAccountSubscription: (accountSubscription: AccountSubscription): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.accountSubscription.updateAccountSubscription(
						accountSubscription.accountId
					)}`,
					accountSubscription
				);
			},
		};
	}

	get alerts() {
		return {
			getAlerts: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Alert>> => {
				return this.httpClient.put<SearchFilterResult<Alert>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.getAlerts}`,
					searchFilter
				);
			},
			getAlertTypes: (): Observable<AlertType[]> => {
				return this.httpClient.get<AlertType[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.getAlertTypes}`);
			},
			getAllAlertTypes: (): Observable<AlertType[]> => {
				return this.httpClient.get<AlertType[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.getAllAlertTypes}`);
			},
			getAlertTotals: (accountId: number): Observable<AlertTotals[]> => {
				return this.httpClient.get<AlertTotals[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.getAlertTotals(accountId)}`
				);
			},
			getDeviceAlertTotals: (accountId: number): Observable<AlertTotals[]> => {
				return this.httpClient.get<AlertTotals[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.getDeviceAlertTotals(accountId)}`
				);
			},
			getNetworkAlertsResult: (accountId: number, networkId: number, searchFilter?: SearchFilter): Observable<SearchFilterResult<Alert>> => {
				return this.httpClient.put<SearchFilterResult<Alert>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.getNetworkAlerts(accountId, networkId)}`,
					searchFilter
				);
			},
			acknowledgeAlerts: (accountId: number, alertItemIds: number[]) => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.acknowledgeAlerts(accountId)}`,
					alertItemIds
				);
			},
			acknowledgeAlertsFilter: (searchFilter: SearchFilter): Observable<number> => {
				return this.httpClient.put<number>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.acknowledgeAlertsFilter}`,
					searchFilter
				);
			},
			closeAlerts: (accountId: number, alertItemIds: number[]) => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.closeAlerts(accountId)}`,
					alertItemIds
				);
			},
			closeAlertsFilter: (searchFilter: SearchFilter): Observable<number> => {
				return this.httpClient.put<number>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alert.closeAlertsFilter}`,
					searchFilter
				);
			},
		};
	}

	get alertProfiles() {
		return {
			getAlertProfiles: (accountId: number): Observable<AlertProfile[]> => {
				return this.httpClient.get<AlertProfile[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.getAlertProfiles(accountId)}`
				);
			},
			getAlertProfile: (accountId: number, alertProfileId: number): Observable<AlertProfile> => {
				return this.httpClient.get<AlertProfile>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.getAlertProfile(accountId, alertProfileId)}`
				);
			},
			createAlertProfile: (accountId: number, alertProfileDto: AlertProfile): Observable<AlertProfile> => {
				return this.httpClient.post<AlertProfile>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.createAlertProfile(accountId)}`,
					alertProfileDto
				);
			},
			updateAlertProfile: (accountId: number, alertProfileId: number, alertProfileDto: AlertProfile): Observable<AlertProfile> => {
				return this.httpClient.put<AlertProfile>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.updateAlertProfile(accountId, alertProfileId)}`,
					alertProfileDto
				);
			},
			deleteAlertProfile: (accountId: number, alertProfileId: number): Observable<any> => {
				return this.httpClient.delete<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.deleteAlertProfile(accountId, alertProfileId)}`
				);
			},
			deleteAlertProfiles: (accountId: number, alertProfileIds: number[]): Observable<void> => {
				return this.httpClient.request<void>(
					'DELETE',
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.deleteAlertProfiles(accountId)}`,
					{
						body: alertProfileIds,
					}
				);
			},
			sendTest: (testDetailsDto: AlertNotificationTest) => {
				return this.httpClient.post<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.sendTest}`,
					testDetailsDto
				);
			},
			sendTestById: (accountId: number, alertProfileId: number): Observable<any> => {
				return this.httpClient.post<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.alertProfiles.sendTestById(accountId, alertProfileId)}`,
					null
				);
			},
		};
	}

	get auditLogs() {
		return {
			getAuditLogs: (searchFilter?: SearchFilter): Observable<SearchFilterResult<AuditLog>> => {
				return this.httpClient.put<SearchFilterResult<AuditLog>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.auditLog.getAuditLogs}`,
					searchFilter
				);
			},
		};
	}

	get dashboard() {
		return {
			getDashboardData: (searchFilter: SearchFilter): Observable<DashboardDto> => {
				return this.httpClient.put<DashboardDto>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getDashboard}`,
					searchFilter
				);
			},
			getCybersecurityDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.httpClient.put<SearchFilterResult<Unit>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getCybersecurityDevices}`,
					searchFilter
				);
			},
			getMalwareProtectionDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.httpClient.put<SearchFilterResult<Unit>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getMalwareProtectionDevices}`,
					searchFilter
				);
			},
			getCameraDefenseDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.httpClient.put<SearchFilterResult<Unit>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getCameraDefenseDevices}`,
					searchFilter
				);
			},
			getOfflineDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.httpClient.put<SearchFilterResult<Unit>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getOfflineDevices}`,
					searchFilter
				);
			},
			getCameraStreams: (searchFilter?: SearchFilter): Observable<SearchFilterResult<CameraStream>> => {
				return this.httpClient.put<SearchFilterResult<CameraStream>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getCameraStreams}`,
					searchFilter
				);
			},
			getRecordingServers: (searchFilter?: SearchFilter): Observable<SearchFilterResult<RecordingServer>> => {
				return this.httpClient.put<SearchFilterResult<RecordingServer>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.dashboard.getRecordingServers}`,
					searchFilter
				);
			},
		};
	}

	get locations() {
		return {
			getLocations: (accountId: number): Observable<Location[]> => {
				return this.httpClient.get<Location[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.location.getLocations(accountId)}`
				);
			},
			getLocation: (accountId: number, locationId: number): Observable<Location> => {
				return this.httpClient.get<Location>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.location.getLocation(accountId, locationId)}`
				);
			},
			createLocation: (accountId: number, location: Location): Observable<Location> => {
				return this.httpClient.post<Location>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.location.createLocation(accountId)}`,
					location
				);
			},
			updateLocation: (accountId: number, locationId: number, location: Location): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.location.updateLocation(accountId, locationId)}`,
					location
				);
			},
			deleteLocation: (accountId: number, locationId: number): Observable<void> => {
				return this.httpClient.delete<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.location.deleteLocation(accountId, locationId)}`
				);
			},
			deleteLocations: (accountId: number, locationIds: number[]): Observable<void> => {
				return this.httpClient.request<void>(
					'DELETE',
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.location.deleteLocations(accountId)}`,
					{ body: locationIds }
				);
			},
		};
	}

	get manage() {
		return {
			getTasks: (searchFilter?: SearchFilter): Observable<SearchFilterResult<ManageTask>> => {
				return this.httpClient.put<SearchFilterResult<ManageTask>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.getTasks}`,
					searchFilter
				);
			},
			getTask: (accountId: number, manageTaskId: number): Observable<ManageTask> => {
				return this.httpClient.get<ManageTask>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.getTask(accountId, manageTaskId)}`
				);
			},
			createTask: (accountId: number, formData: FormData): Observable<any> => {
				return this.httpClient.post(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.createTask(accountId)}`, formData, {
					reportProgress: true,
					observe: 'events',
				});
			},
			getManageTaskFile: (accountId: number, manageTaskFileId: number): Observable<HttpResponse<Blob>> => {
				return this.httpClient.get(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.getTaskFile(accountId, manageTaskFileId)}`,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			cancelTask: (accountId: number, manageTaskId: number): Observable<boolean> => {
				return this.httpClient.put<boolean>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.cancelTask(accountId, manageTaskId)}`,
					null
				);
			},
			getTaskTypes: (): Observable<ManageTaskType[]> => {
				return this.httpClient.get<ManageTaskType[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.getTaskTypes}`);
			},
			getAllTaskTypes: (): Observable<ManageTaskType[]> => {
				return this.httpClient.get<ManageTaskType[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.getAllTaskTypes}`);
			},
			getManageTasksCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.manageTasksCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getManageTaskInstancesCsv: (accountId: number, manageTaskId: number): Observable<HttpResponse<Blob>> => {
				return this.httpClient.get(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.manageTaskInstancesCsv(accountId, manageTaskId)}`,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			getManageTasksExport: (requests: ManageTaskExportRequest[]): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.manage.manageTasksExport}`, requests, {
					responseType: 'blob',
					observe: 'response',
				});
			},
		};
	}

	get managedDevices() {
		return {
			getManagedDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<ManagedDevice>> => {
				return this.httpClient.put<SearchFilterResult<ManagedDevice>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.managedDevice.getManagedDevices}`,
					searchFilter
				);
			},
			getManagedDevice: (accountId: number, managedDeviceId: number): Observable<ManagedDevice> => {
				return this.httpClient.get<ManagedDevice>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.managedDevice.getManagedDevice(accountId, managedDeviceId)}`
				);
			},
			getManagedDevicesForUnits: (accountObjectIds: AccountObjectIds): Observable<ManagedDevice[]> => {
				return this.httpClient.post<ManagedDevice[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.managedDevice.getManagedDevicesForUnits}`,
					accountObjectIds
				);
			},
			getManagedDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.managedDevice.managedDevicesCsv}`,
					searchFilter,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			updateManagedDevice: (accountId: number, deviceId: number, updateDto: UpdateManagedDeviceDto): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.managedDevice.updateManagedDevice(accountId, deviceId)}`,
					updateDto
				);
			},
		};
	}

	get multiAuth() {
		return {
			checkMultiAuth: (): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.multiAuth.checkMultiAuth}`);
			},
			multiAuthExists: (): Observable<boolean> => {
				return this.httpClient.get<boolean>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.multiAuth.multiAuthExists}`);
			},
			sendMultiAuth: (type: UserMultiAuthTokenType): Observable<void> => {
				return this.httpClient.get<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.multiAuth.sendMultiAuth(type)}`);
			},
			verifyMultiAuth: (token: string): Observable<any> => {
				return this.httpClient.post<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.multiAuth.verifyMultiAuth}`, {
					value: token,
				});
			},
			configureSmsMultiAuth: (phoneNumber: string): Observable<void> => {
				return this.httpClient.post<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.multiAuth.configureSmsMultiAuth}`, {
					value: phoneNumber,
				});
			},
		};
	}

	get networks() {
		return {
			getNetworks: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Network>> => {
				return this.httpClient.put<SearchFilterResult<Network>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.networks.getNetworks}`,
					searchFilter
				);
			},
			getNetwork: (accountId: number, networkId: number): Observable<Network> => {
				return this.httpClient.get<Network>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.networks.getNetwork(accountId, networkId)}`
				);
			},
			createNetwork: (accountId: number, network: Network): Observable<Network> => {
				return this.httpClient.post<Network>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.networks.createNetwork(accountId)}`,
					network
				);
			},
			updateNetwork: (accountId: number, network: Network): Observable<Network> => {
				return this.httpClient.put<Network>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.networks.updateNetwork(accountId)}`,
					network
				);
			},
			deleteNetwork: (accountId: number, networkId: number): Observable<void> => {
				return this.httpClient.delete<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.networks.deleteNetwork(accountId, networkId)}`
				);
			},
			getNetworkAvailableDevices: (params: NetworkAvailableDevicesRequest): Observable<SearchFilterResult<ManagedDevice>> => {
				return this.httpClient.put<SearchFilterResult<ManagedDevice>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.networks.getNetworkAvailableDevices}`,
					params
				);
			},
		};
	}

	get reports() {
		return {
			getAccountsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.accountsCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.devicesCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getOfflineDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.offlineDevicesCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getCameraStreamsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.cameraStreamsCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getRecordingServersCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.recordingServersCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getMalwareProtectionDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.malwareProtectionDevicesCsv}`,
					searchFilter,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			getCybersecurityDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.cybersecurityDevicesCsv}`,
					searchFilter,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			getCameraDefenseDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.cameraDefenseDevicesCsv}`,
					searchFilter,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			getLookupDevicesCsv: (lookup: string, searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.lookupDevicesCsv(lookup)}`,
					searchFilter,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			getAlertsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.alertsCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getNetworkAlertsCsv: (accountId: number, networkId: number, filter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.networkAlertsCsv(accountId, networkId)}`,
					filter,
					{
						responseType: 'blob',
						observe: 'response',
					}
				);
			},
			getAuditLogsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.auditLogsCsv}`, searchFilter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getAccountStatusReportPdf: (): Observable<HttpResponse<Blob>> => {
				return this.httpClient.get(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.accountStatusPdf}`, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getAccountStatusSingleReportPdf: (accountId: number): Observable<HttpResponse<Blob>> => {
				return this.httpClient.get(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.accountStatusSinglePdf(accountId)}`, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getAccountStatusMultiReportPdf: (accountIds: number[]): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.accountStatusMultiPdf}`, accountIds, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getUserComplianceReportCsvByFilter: (filter: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.userComplianceCsvFilter}`, filter, {
					responseType: 'blob',
					observe: 'response',
				});
			},
			getUserComplianceReportCsvByAccountId: (accountIds: number[]): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.reports.userComplianceCsv}`, accountIds, {
					responseType: 'blob',
					observe: 'response',
				});
			},
		};
	}

	get settings() {
		return {
			getSettings: (): Observable<CoreServerSettings> => {
				return this.httpClient.get<CoreServerSettings>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.getSettings}`);
			},
			getCoreSettings: (): Observable<CoreServerSettings> => {
				return this.httpClient.get<CoreServerSettings>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.getCoreSettings}`
				);
			},
			getFullSettings: (): Observable<CoreServerSettings> => {
				return this.httpClient.get<CoreServerSettings>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.getFullSettings}`
				);
			},
			getLoginSettings: (): Observable<LoginSettings> => {
				return this.httpClient.get<LoginSettings>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.getLoginSettings}`);
			},
			getSettingsDashboard: (): Observable<SettingsDashboard> => {
				return this.httpClient.get<SettingsDashboard>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.getSettingsDashboard}`
				);
			},
			exportSettings(): Observable<HttpResponse<Blob>> {
				return this.httpClient.put(
					`${this.configService.config.apiHost}${CloudApiConstants.apiPath.settings.exportSettings}`,
					{},
					{ responseType: 'blob', observe: 'response' }
				);
			},
			setGlobal: (global: GlobalSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setGlobal}`, global, {});
			},
			setSso: (sso: SsoSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setSso}`, sso, {});
			},
			setSmtp: (smtp: SmtpSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setSmtp}`, smtp, {});
			},
			setCloudApi: (cloudApi: CloudApiSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setCloudApi}`, cloudApi, {});
			},
			setSinkApi: (sinkApi: SinkApiSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setSinkApi}`, sinkApi, {});
			},
			setCloudAgent: (cloudAgent: CloudAgentSettings): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setCloudAgent}`,
					cloudAgent,
					{}
				);
			},
			setMonitorAgent: (monitorAgent: MonitorAgentSettings): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setMonitorAgent}`,
					monitorAgent,
					{}
				);
			},
			setManage: (manage: ManageSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setManage}`, manage, {});
			},
			setCompliance: (compliance: ComplianceSettings): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setCompliance}`,
					compliance,
					{}
				);
			},
			setAws: (aws: AwsSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setAws}`, aws, {});
			},
			setFileStore: (fileStore: FileStoreSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setFileStore}`, fileStore, {});
			},
			setSms: (sms: SmsSettings): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.setSms}`, sms, {});
			},
			testSmtp(): Observable<void> {
				return this.httpClient.post(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.settings.testSmtp}`, null, {});
			},
		};
	}

    get setup() {
        return {
            tokenRequiresCredentials: (token: string): Observable<boolean> => {
                return this.httpClient.post<boolean>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.setup.tokenRequiresCredentials}`, { token: token }, {});
            },
            registerToken: (token: string, email: string, password: string): Observable<SetupRegistrationResult> => {
                return this.httpClient.post<SetupRegistrationResult>(
                    `${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.setup.registerToken}`,
                    { token: token, accountEmail: email, accountPassword: password },
                    {}
                );
            },
            getBenefitsProvider: (): Observable<string> => {
                return this.httpClient.get<string>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.setup.getBenefitsProvider}`);
            },
            getBenefitsCustomer: (): Observable<string> => {
                return this.httpClient.get<string>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.setup.getBenefitsCustomer}`);
            }
        }
    }

	get tags() {
		return {
			getTags: (accountId: number): Observable<Tag[]> => {
				return this.httpClient.get<Tag[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.tag.getTags(accountId)}`);
			},
			getTag: (accountId: number, tagId: number): Observable<Tag> => {
				return this.httpClient.get<Tag>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.tag.getTag(accountId, tagId)}`);
			},
			createTag: (accountId: number, tag: Tag): Observable<Tag> => {
				return this.httpClient.post<Tag>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.tag.createTag(accountId)}`, tag);
			},
			updateTag: (accountId: number, tagId: number, tag: Tag): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.tag.updateTag(accountId, tagId)}`, tag);
			},
			deleteTag: (accountId: number, tagId: number): Observable<void> => {
				return this.httpClient.delete<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.tag.deleteTag(accountId, tagId)}`);
			},
			deleteTags: (accountId: number, tagIds: number[]): Observable<void> => {
				return this.httpClient.request<void>(
					'DELETE',
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.tag.deleteTags(accountId)}`,
					{ body: tagIds }
				);
			},
		};
	}

	get unit() {
		return {
			getUnits: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.httpClient.put<SearchFilterResult<Unit>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUnits}`,
					searchFilter
				);
			},
			getUnitsByAccountId: (accountId: number): Observable<Unit[]> => {
				return this.httpClient.get<Unit[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUnitsByAccountId(accountId)}`);
			},
			getUnitsByLookup: (lookup: string, filter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.httpClient.put<SearchFilterResult<Unit>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUnitsByLookup(lookup)}`,
					filter
				);
			},
			getAccountsUnits: (accountIds: number[] = null): Observable<Unit[]> => {
				return this.httpClient.put<Unit[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getAccountsUnits}`, accountIds);
			},
			getUnit: (accountId: number, unitIdOrKey: string): Observable<Unit> => {
				return this.httpClient.get<Unit>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUnit(accountId, unitIdOrKey)}`);
			},
			getUpdatesForUnit: (accountId: number, unitId: number): Observable<Update> => {
				return this.httpClient.get<Update>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUpdatesForUnit(accountId, unitId)}`
				);
			},
			registerUnit: (registerDto: RegisterUnitDto): Observable<void> => {
				return this.httpClient.post<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.registerUnit}`, registerDto);
			},
			updateUnit: (accountId: number, unitId: number, updateDto: UpdateUnitDto): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.updateUnit(accountId, unitId)}`,
					updateDto
				);
			},
			deleteUnit: (accountId: number, unitId: number): Observable<void> => {
				return this.httpClient.delete<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.deleteUnit(accountId, unitId)}`);
			},
			deleteUnitPermanently: (accountId: number, unitId: number): Observable<void> => {
				return this.httpClient.delete<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.deleteUnitPermanently(accountId, unitId)}`
				);
			},
			getAccountsVmsServices: (accountIds: number[] = null): Observable<VmsService[]> => {
				return this.httpClient.put<VmsService[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getAccountsVmsServices}`,
					accountIds
				);
			},
			getActivationCode: (accountId: number, unitIdOrKey: string): Observable<string> => {
				return this.httpClient.get<string>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getActivationCode(accountId, unitIdOrKey)}`
				);
			},
			getUnitFields: (accountId: number, unitId: number): Observable<UnitFields> => {
				return this.httpClient.get<UnitFields>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUnitFields(accountId, unitId)}`
				);
			},
			deleteUnitFields: (accountId: number, unitId: number): Observable<void> => {
				return this.httpClient.delete<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.deleteUnitFields(accountId, unitId)}`
				);
			},
			setUnitWarranty: (accountId: number, unitId: number, warrantyDto: UnitWarrantyDto): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.setUnitWarranty(accountId, unitId)}`,
					warrantyDto
				);
			},
			getUpdateTypes: (): Observable<UpdateType[]> => {
				return this.httpClient.get<UpdateType[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUpdateTypes}`);
			},
			getUnitAndDeviceTotals: (accountId: number): Observable<UnitAndDeviceTotals> => {
				return this.httpClient.get<UnitAndDeviceTotals>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.unit.getUnitAndDeviceTotals(accountId)}`
				);
			},
		};
	}

	get user() {
		return {
			getUsers: (searchFilter?: SearchFilter): Observable<SearchFilterResult<User>> => {
				return this.httpClient.put<SearchFilterResult<User>>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getUsers}`,
					searchFilter
				);
			},
			getUsersByAccountId: (accountId: number): Observable<User[]> => {
				return this.httpClient.get<User[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getUsersByAccountId(accountId)}`);
			},
			getAccountsUsers: (accountIds: number[]): Observable<User[]> => {
				return this.httpClient.put<User[]>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getAccountsUsers}`, accountIds);
			},
			getUser: (userId: number): Observable<User> => {
				return this.httpClient.get<User>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getUser(userId)}`);
			},
			getCurrentUser: (): Observable<User> => {
				return this.httpClient.get<User>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getCurrentUser}`);
			},
			createUser: (user: User): Observable<void> => {
				return this.httpClient.post<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.createUser}`, user);
			},
			updateUser: (user: User): Observable<void> => {
				return this.httpClient.put<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.updateUser(user.userId)}`, user);
			},
			updateCurrentUser: (userDto: User): Observable<void> => {
				return this.httpClient.put<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.updateCurrentUser}`, userDto);
			},
			deleteUser: (userId: number): Observable<void> => {
				return this.httpClient.delete<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.deleteUser(userId)}`);
			},
			setUserPassword: (userId: number, setPasswordDto: any): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.setUserPassword(userId)}`,
					setPasswordDto
				);
			},
			setCurrentUserPassword: (setPasswordDto: any): Observable<void> => {
				return this.httpClient.put<any>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.setCurrentUserPassword}`,
					setPasswordDto
				);
			},
			getUserAvatar: (userId: number): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getUserAvatar(userId)}`);
			},
			getCurrentUserAvatar: (): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getCurrentUserAvatar}`);
			},
			setUserAvatar: (userId: number, imageDto: any): Observable<any> => {
				return this.httpClient.put<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.setUserAvatar(userId)}`, imageDto);
			},
			setCurrentUserAvatar: (imageDto: any): Observable<any> => {
				return this.httpClient.put<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.setCurrentUserAvatar}`, imageDto);
			},
			removeUserAvatar: (userId: number): Observable<void> => {
				return this.httpClient.delete<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.removeUserAvatar(userId)}`);
			},
			removeCurrentUserAvatar: (): Observable<void> => {
				return this.httpClient.delete<void>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.removeCurrentUserAvatar}`);
			},
			getUserSessions: (userId): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getUserSessions(userId)}`);
			},
			getCurrentUserSessions: (): Observable<any> => {
				return this.httpClient.get<any>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.getCurrentUserSessions}`);
			},
			deleteUserSession: (userId, session): Observable<void> => {
				return this.httpClient.delete<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.deleteUserSession(userId, session)}`
				);
			},
			deleteCurrentUserSession: (session): Observable<void> => {
				return this.httpClient.delete<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.user.deleteCurrentUserSession(session)}`
				);
			},
		};
	}

	get userCompliance() {
		return {
			getUserCompliances: (accountIds: number[]): Observable<UserCompliance[]> => {
				return this.httpClient.put<UserCompliance[]>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.getUserCompliances}`,
					accountIds,
					{}
				);
			},
			getGdprAgreement: (): Observable<string> => {
				return this.httpClient.get<string>(`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.getGdprAgreement}`);
			},
			getGdprPdf: (): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.getGdprPdf}`,
					{},
					{ responseType: 'blob', observe: 'response' }
				);
			},
			signGdpr: (consented: boolean): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.signGdpr}/${consented}`,
					{},
					{ headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
				);
			},
			getResellerAgreement: (region: UserComplianceRegion): Observable<string> => {
				return this.httpClient.get<string>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.getResellerAgreement(region)}`
				);
			},
			getResellerPdf: (): Observable<HttpResponse<Blob>> => {
				return this.httpClient.put(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.getResellerPdf}`,
					{},
					{ responseType: 'blob', observe: 'response' }
				);
			},
			signReseller: (region: UserComplianceRegion, consented: boolean): Observable<void> => {
				return this.httpClient.put<void>(
					`${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.userCompliance.signReseller(region, consented)}`,
					{},
					{ headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
				);
			},
		};
	}
}
