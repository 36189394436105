import { ManagedDeviceType } from '@razberi-ui/core/data-types';

export class DataSearchConfiguration {
	showSearchTerm?: boolean = false;
	showDateRange?: boolean = false;
	showAccounts?: boolean = false;
	showAccountTypes?: boolean = false;
	showAccountStatuses?: boolean = false;
	showAccountSubscriptionTypes?: boolean = false;
	showAccountSubscriptionStatuses?: boolean = false;
	showUsers?: boolean = false;
	showUserRoles?: boolean = false;
	showUserStatuses?: boolean = false;
	showLocations?: boolean = false;
	showTags?: boolean = false;
	showUnits?: boolean = false;
	showUnitStatuses?: boolean = false;
	showAlertTypes?: boolean = false;
	showAlertStatuses?: boolean = false;
	showVmsServices?: boolean = false;
	showRecordingServers?: boolean = false;
	showCameras?: boolean = false;
	showManageTaskTypes?: boolean = false;
	showManageTaskStatuses?: boolean = false;
	forSubscribedAccounts?: boolean = false;
	showUpdateTypes?: boolean = false;
	showManagedDeviceTypes?: boolean = false;
	allowedManagedDeviceTypes?: ManagedDeviceType[] = [];
	excludedManagedDeviceTypes?: ManagedDeviceType[] = [];
	includeManagedDeviceAgentTypes?: boolean = false;
	showManagedDeviceStatuses?: boolean = false;
	includeManagedDeviceErrorStatus?: boolean = false;
	showNetworkLinkStatuses?: boolean = false;
}
