import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '../../services/utilities.service';
import { CoreSearchItem } from '../../data-types/core-search-item.model';

@Component({
	selector: 'shared-form-checkbox-select',
	templateUrl: './form-checkbox-select.component.html',
	styleUrls: ['./form-checkbox-select.component.scss'],
})
export class FormCheckboxSelectComponent {
	@Input() icon: string;
	@Input() label: string;
	@Input() items: any[];
	@Input() selected: any[];
	@Output() selectedChange: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() applyFilter: EventEmitter<void> = new EventEmitter();

	selectId: string;
	initialized: boolean;

	constructor(private readonly utils: UtilitiesService) {}

	ngOnInit() {
		this.selectId = this.utils.helpers.getRandomString();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.items?.length > 0) {
			this.items.forEach((item) => {
				item.selected = this.selected?.find((sel) => sel === item) != null;
			});
		}
	}

	onChange(checked: boolean, item: CoreSearchItem<any>) {
		item.selected = checked;
		this.updateSelected();
		this.selectedChange.emit(this.selected);
	}

	updateSelected() {
		this.selected = [];
		this.items.filter((item) => item.selected === true).forEach((item) => this.selected.push(item));
	}

	applySearchFilter() {
		this.applyFilter.emit();
	}
}
