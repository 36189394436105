<div class="fs-4 mb-2">Agents With Cybersecurity Weaknesses</div>
<app-root-monitor-cloud-unit-table
	[showAccount]="true"
	[showLocation]="true"
	[showTags]="true"
	[showCameraDefenseStatus]="true"
	[showMalwareProtectionStatus]="true"
	[showUpdates]="false"
	[dataSource]="'dashboard-cybersecurity'">
</app-root-monitor-cloud-unit-table>
