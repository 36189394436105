import { Injectable } from '@angular/core';
import { CloudApiService, UserMultiAuthTokenType } from '@razberi-ui/api/cloud-api';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MultiAuthService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			checkMultiAuth: (): Observable<any> => {
				return this.cloudApiService.multiAuth.checkMultiAuth();
			},
			multiAuthExists: (): Observable<boolean> => {
				return this.cloudApiService.multiAuth.multiAuthExists();
			},
			sendMultiAuth: (type: UserMultiAuthTokenType): Observable<void> => {
				return this.cloudApiService.multiAuth.sendMultiAuth(type);
			},
			verifyMultiAuth: (token: string): Observable<any> => {
				return this.cloudApiService.multiAuth.verifyMultiAuth(token);
			},
			configureSmsMultiAuth: (phoneNumber: string): Observable<void> => {
				return this.cloudApiService.multiAuth.configureSmsMultiAuth(phoneNumber);
			},
		};
	}
}
