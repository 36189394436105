import { Directive, AfterViewInit, Input, TemplateRef } from '@angular/core';
import { PagePortalService } from '../services/page-portal.service';
import { PagePortalOutletDirective } from './page-portal-outlet.directive';

@Directive({
	selector: '[pagePortal]',
})
export class PagePortalDirective {
	@Input() outletKey: string;

	constructor(private portalService: PagePortalService, private templateRef: TemplateRef<any>) {}

	ngOnInit(): void {
		const outlet: PagePortalOutletDirective = this.portalService.outlets[this.outletKey];
		outlet?.viewContainerRef?.createEmbeddedView(this.templateRef);
	}
}
