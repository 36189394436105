<div *ngIf="settingsService.data.settings?.global == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.global != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="environment" class="form-label">Environment</label>
		<div ngbTooltip="The {{ settingsService.settings?.global?.baseTitle }} application environment."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="environment" id="environment" />
	</div>
	<div class="mb-3 col-12">
		<label for="cloudUri" class="form-label">{{ settingsService.settings?.global?.baseTitle }} URI</label>
		<div ngbTooltip="The URI used to access {{ settingsService.settings?.global?.baseTitle }}."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="cloudUri" id="cloudUri" />
		<shared-input-validation-messages [control]="settingsForm.controls.cloudUri"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="cloudApiUri" class="form-label">CloudApi URI</label>
		<div ngbTooltip="The URI used to access the CloudApi." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="cloudApiUri" id="cloudApiUri" />
		<shared-input-validation-messages [control]="settingsForm.controls.cloudApiUri"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="sinkApiUri" class="form-label">SinkApi URI</label>
		<div ngbTooltip="The URI used to access the SinkApi." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="sinkApiUri" id="sinkApiUri" />
		<shared-input-validation-messages [control]="settingsForm.controls.sinkApiUri"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="settingsInterval" class="form-label">Settings Interval</label>
		<div ngbTooltip="How often the settings are reloaded." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="settingsInterval" id="settingsInterval" />
		<shared-input-validation-messages [control]="settingsForm.controls.settingsInterval"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input type="checkbox" class="form-check-input" formControlName="isPremise" id="isPremise" />
			<label for="isPremise" class="form-check-label">Is Premise</label>
			<div ngbTooltip="Whether or not the application is configured for premise."
				 placement="right"
				 container="body"
				 class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
	<div class="mb-3 col-12">
		<label for="baseTitle" class="form-label">Base Title</label>
		<div ngbTooltip="The base title used throughout the application." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="baseTitle" id="baseTitle" />
		<shared-input-validation-messages [control]="settingsForm.controls.baseTitle"></shared-input-validation-messages>
	</div>
</form>
