<div routerLinkActive="active" [routerLink]="['/dashboard', path]" class="dashboard-tab ps-3 pe-3 pt-3 pb-3 h-100 position-relative">
	<div class="d-flex flex-row justify-content-between align-items-top mb-2">
		<div class="flex-1">
			<h4>{{ heading }}</h4>
		</div>
		<div class="d-flex align-items-top">
			<div *ngIf="tooltip" [ngbTooltip]="tooltip" placement="bottom" container="body" class="ms-1">
				<fa-icon icon="info-circle" class="text-primary"></fa-icon>
			</div>
			<div *ngIf="statusIcon" [ngbTooltip]="statusTooltip" placement="bottom" container="body" class="ms-1">
				<fa-icon [icon]="statusIcon" [class]="statusIconClass" size="lg"></fa-icon>
			</div>
			<fa-icon [icon]="icon" size="xl" class="ms-1"></fa-icon>
		</div>
	</div>
	<ng-content></ng-content>
</div>
