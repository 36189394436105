import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

// factory object to return a custom validator function
export function valueMatchValidator(value: string): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const match = value === control.value;
		return match ? null : { valueMatch: { value1: value, value2: control.value } };
		// null error is returned to indicate VALID status, non-null value is returned to indicate INVALID status
	};
}

@Directive({
	selector: '[valueMatch]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: ValueMatchValidatorDirective,
			multi: true,
		},
	],
})
export class ValueMatchValidatorDirective implements Validator {
	@Input() valueMatch: string;

	validate(control: AbstractControl): { [key: string]: any } {
		return this.valueMatch ? valueMatchValidator(this.valueMatch)(control) : null;
	}
}
