import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Component, ModuleWithProviders, NgModule } from '@angular/core';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CoreThemesModule } from '@razberi-ui/core/themes';
import { CloudApiModule, CloudApiConfigService, CoreServerSettingsService, AuthService } from '@razberi-ui/api/cloud-api';
import { AppConfig, AppConfigKey, AppEnvironment, ErrorInterceptor, NavService, SharedModule, SpinnerInterceptor, VersionService } from '@razberi-ui/shared';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AppRootMonitorCloudRoutingModule } from './app-root-monitor-cloud-routing.module';
import { map, of, switchMap, takeWhile } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { InvalidSubscriptionModalComponent } from './components/subscriptions/invalid-subscription-modal/invalid-subscription-modal.component';
import { SubscriptionService } from './services/subscription.service';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';
import { GdprComponent } from './components/user-compliance/gdpr/gdpr.component';
import { ResellerComponent } from './components/user-compliance/reseller/reseller.component';
import { TagComponent } from './components/tags/tag/tag.component';
import { TagFormComponent } from './components/tags/tag-form/tag-form.component';
import { TagModalComponent } from './components/tags/tag-modal/tag-modal.component';
import { LocationFormComponent } from './components/locations/location-form/location-form.component';
import { LocationModalComponent } from './components/locations/location-modal/location-modal.component';
import { AuditLogsComponent } from './components/audit-logs/audit-logs.component';
import { AuditLogTableComponent } from './components/audit-logs/audit-log-table/audit-log-table.component';
import { PageSidePanelComponent } from './components/core/page-side-panel/page-side-panel.component';
import { DataTableCellTypesComponent } from './components/core/data-table-cell-types/data-table-cell-types.component';
import { ManagedDeviceDetailsSidePanelComponent } from './components/managed-devices/managed-device-details-side-panel/managed-device-details-side-panel.component';
import { UnitUpdatesSidePanelComponent } from './components/units/unit-updates-side-panel/unit-updates-side-panel.component';
import { UnitSidePanelComponent } from './components/units/unit-side-panel/unit-side-panel.component';
import { ManagedDeviceSidePanelComponent } from './components/managed-devices/managed-device-side-panel/managed-device-side-panel.component';
import { DevicesComponent } from './components/devices/devices.component';
import { DeviceEditFormComponent } from './components/devices/device-edit-form/device-edit-form.component';
import { DeviceEditModalComponent } from './components/devices/device-edit-modal/device-edit-modal.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { AccountAccordianComponent } from './components/accounts/account-accordian/account-accordian.component';
import { AccountSidePanelComponent } from './components/accounts/account-side-panel/account-side-panel.component';
import { DataTableSearchFormComponent } from './components/core/data-table-search-form/data-table-search-form.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertTableComponent } from './components/alerts/alert-table/alert-table.component';
import { AlertSidePanelComponent } from './components/alerts/alert-side-panel/alert-side-panel.component';
import { AlertTabsComponent } from './components/alerts/alert-tabs/alert-tabs.component';
import { AccountTableComponent } from './components/accounts/account-table/account-table.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsSmtpFormComponent } from './components/settings/settings-smtp-form/settings-smtp-form.component';
import { SettingsAwsFormComponent } from './components/settings/settings-aws-form/settings-aws-form.component';
import { SettingsCloudAgentFormComponent } from './components/settings/settings-cloud-agent-form/settings-cloud-agent-form.component';
import { SettingsCloudApiFormComponent } from './components/settings/settings-cloud-api-form/settings-cloud-api-form.component';
import { SettingsComplianceFormComponent } from './components/settings/settings-compliance-form/settings-compliance-form.component';
import { SettingsFilestoreFormComponent } from './components/settings/settings-filestore-form/settings-filestore-form.component';
import { SettingsGlobalFormComponent } from './components/settings/settings-global-form/settings-global-form.component';
import { SettingsLocalFilestoreFormComponent } from './components/settings/settings-local-filestore-form/settings-local-filestore-form.component';
import { SettingsManageFormComponent } from './components/settings/settings-manage-form/settings-manage-form.component';
import { SettingsMonitorAgentFormComponent } from './components/settings/settings-monitor-agent-form/settings-monitor-agent-form.component';
import { SettingsS3FilestoreFormComponent } from './components/settings/settings-s3-filestore-form/settings-s3-filestore-form.component';
import { SettingsSinkApiFormComponent } from './components/settings/settings-sink-api-form/settings-sink-api-form.component';
import { SettingsSsoFormComponent } from './components/settings/settings-sso-form/settings-sso-form.component';
import { SettingsTropoFormComponent } from './components/settings/settings-tropo-sms-form/settings-tropo-form.component';
import { SettingsTwilioFormComponent } from './components/settings/settings-twilio-sms-form/settings-twilio-form.component';
import { AccountFormComponent } from './components/accounts/account-form/account-form.component';
import { AccountModalComponent } from './components/accounts/account-modal/account-modal.component';
import { UserFormComponent } from './components/users/user-form/user-form.component';
import { UserModalComponent } from './components/users/user-modal/user-modal.component';
import { AccountNoteFormComponent } from './components/accounts/account-note-form/account-note-form.component';
import { AccountSubscriptionFormComponent } from './components/accounts/account-subscription-form/account-subscription-form.component';
import { AccountSubscriptionViewComponent } from './components/accounts/account-subscription-view/account-subscription-view.component';
import { AccountViewComponent } from './components/accounts/account-view/account-view.component';
import { AccountViewTabAlertProfilesComponent } from './components/accounts/account-view/tabs/account-view-tab-alert-profiles/account-view-tab-alert-profiles.component';
import { AccountViewTabBrandingComponent } from './components/accounts/account-view/tabs/account-view-tab-branding/account-view-tab-branding.component';
import { AccountViewTabComplianceComponent } from './components/accounts/account-view/tabs/account-view-tab-compliance/account-view-tab-compliance.component';
import { AccountViewTabDetailsComponent } from './components/accounts/account-view/tabs/account-view-tab-details/account-view-tab-details.component';
import { AccountViewTabLocationsComponent } from './components/accounts/account-view/tabs/account-view-tab-locations/account-view-tab-locations.component';
import { AccountViewTabSubscriptionComponent } from './components/accounts/account-view/tabs/account-view-tab-subscription/account-view-tab-subscription.component';
import { AccountViewTabTagsComponent } from './components/accounts/account-view/tabs/account-view-tab-tags/account-view-tab-tags.component';
import { AccountViewTabUsersComponent } from './components/accounts/account-view/tabs/account-view-tab-users/account-view-tab-users.component';
import { PageTabsComponent } from './components/core/page-tabs/page-tabs.component';
import { UserPasswordModalComponent } from './components/users/user-password-modal/user-password-modal.component';
import { AlertProfileModalComponent } from './components/alert-profiles/alert-profile-modal/alert-profile-modal.component';
import { SelectedUnitsListModalComponent } from './components/units/selected-units-list-modal/selected-units-list-modal.component';
import { AlertProfileFormComponent } from './components/alert-profiles/alert-profile-form/alert-profile-form.component';
import { AlertRecipientModalComponent } from './components/alert-profiles/alert-recipient-modal/alert-recipient-modal.component';
import { AlertRecipientsFormComponent } from './components/alert-profiles/alert-recipients-form/alert-recipients-form.component';
import { AlertTypeSelectorComponent } from './components/alerts/alert-type-selector/alert-type-selector.component';
import { LocationDevicesComponent } from './components/devices/location-devices/location-devices.component';
import { UnitTableComponent } from './components/units/unit-table/unit-table.component';
import { ManagedDeviceTableComponent } from './components/managed-devices/managed-device-table/managed-device-table.component';
import { UnitGlobalSearchModalComponent } from './components/units/unit-global-search-modal/unit-global-search-modal.component';
import { UnitOverrideWarrantyModalComponent } from './components/units/unit-override-warranty-modal/unit-override-warranty-modal.component';
import { UnitUpdatesTaskSelectorModalComponent } from './components/units/unit-updates-task-selector-modal/unit-updates-task-selector-modal.component';
import { UnitOverrideWarrantyFormComponent } from './components/units/unit-override-warranty-form/unit-override-warranty-form.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileDetailsComponent } from './components/profile/profile-details/profile-details.component';
import { ProfilePasswordComponent } from './components/profile/profile-password/profile-password.component';
import { ManageComponent } from './components/manage/manage.component';
import { ManageTaskDetailComponent } from './components/manage/manage-task-detail/manage-task-detail.component';
import { ManageTaskTabsComponent } from './components/manage/manage-task-tabs/manage-task-tabs.component';
import { ManageTaskFormComponent } from './components/manage/manage-task-form/manage-task-form.component';
import { ManageTaskInstanceOutputModalComponent } from './components/manage/manage-task-instance-output-modal/manage-task-instance-output-modal.component';
import { ManageTaskInstanceTableComponent } from './components/manage/manage-task-instance-table/manage-task-instance-table.component';
import { ManageTaskTableComponent } from './components/manage/manage-task-table/manage-task-table.component';
import { UnitSelectorComponent } from './components/units/unit-selector/unit-selector.component';
import { UnitSelectorItemComponent } from './components/units/unit-selector/unit-selector-item/unit-selector-item.component';
import { UnitSelectorListComponent } from './components/units/unit-selector/unit-selector-list/unit-selector-list.component';
import { UnitSelectorModalComponent } from './components/units/unit-selector/unit-selector-modal/unit-selector-modal.component';
import { DashboardOutletCameraDefenseComponent } from './components/dashboard/outlets/dashboard-outlet-camera-defense/dashboard-outlet-camera-defense.component';
import { DashboardOutletCybersecurityPostureComponent } from './components/dashboard/outlets/dashboard-outlet-cybersecurity-posture/dashboard-outlet-cybersecurity-posture.component';
import { DashboardOutletUnitUpdatesComponent } from './components/dashboard/outlets/dashboard-outlet-unit-updates/dashboard-outlet-unit-updates.component';
import { DashboardOutletIotHealthComponent } from './components/dashboard/outlets/dashboard-outlet-iot-health/dashboard-outlet-iot-health.component';
import { DashboardOutletMalwareProtectionComponent } from './components/dashboard/outlets/dashboard-outlet-malware-protection/dashboard-outlet-malware-protection.component';
import { DashboardOutletManageTasksComponent } from './components/dashboard/outlets/dashboard-outlet-manage-tasks/dashboard-outlet-manage-tasks.component';
import { DashboardOutletOfflineDevicesComponent } from './components/dashboard/outlets/dashboard-outlet-offline-devices/dashboard-outlet-offline-devices.component';
import { DashboardOutletRecordedStreamsComponent } from './components/dashboard/outlets/dashboard-outlet-recorded-streams/dashboard-outlet-recorded-streams.component';
import { DashboardOutletRecordingServersComponent } from './components/dashboard/outlets/dashboard-outlet-recording-servers/dashboard-outlet-recording-servers.component';
import { DashboardOutletServerHealthComponent } from './components/dashboard/outlets/dashboard-outlet-server-health/dashboard-outlet-server-health.component';
import { DashboardOutletTotalAlertsComponent } from './components/dashboard/outlets/dashboard-outlet-total-alerts/dashboard-outlet-total-alerts.component';
import { DashboardOutletVmsHealthComponent } from './components/dashboard/outlets/dashboard-outlet-vms-health/dashboard-outlet-vms-health.component';
import { DashboardToolbarControlsComponent } from './components/dashboard/dashboard-toolbar-controls/dashboard-toolbar-controls.component';
import { VmsCameraStreamsTableComponent } from './components/vms/vms-camera-streams-table/vms-camera-streams-table.component';
import { VmsRecordingServersTableComponent } from './components/vms//vms-recording-servers-table/vms-recording-servers-table.component';
import { CellAccountStatusComponent } from './components/core/cell-types/cell-account-status/cell-account-status.component';
import { CellAccountSubscriptionStatusComponent } from './components/core/cell-types/cell-account-subscription-status/cell-account-subscription-status.component';
import { CellAlertMessageComponent } from './components/core/cell-types/cell-alert-message/cell-alert-message.component';
import { CellAlertStatusComponent } from './components/core/cell-types/cell-alert-status/cell-alert-status.component';
import { CellUnitUpdatesComponent } from './components/core/cell-types/cell-unit-updates/cell-unit-updates.component';
import { CellUserRolesComponent } from './components/core/cell-types/cell-user-roles/cell-user-roles.component';
import { CellUnitTitleComponent } from './components/core/cell-types/cell-unit-title/cell-unit-title.component';
import { DataTableComponent } from './components/core/data-table/data-table.component';
import { NetworksComponent } from './components/networks/networks.component';
import { NetworkDetailComponent } from './components/networks/network-detail/network-detail.component';
import { NetworkListComponent } from './components/networks/network-list/network-list.component';
import { NetworkLinkDetailModalComponent } from './components/networks/network-link-detail-modal/network-link-detail-modal.component';
import { NetworkD3TreeDiagramComponent } from './components/networks/network-d3-tree-diagram/network-d3-tree-diagram.component';
import { NetworkD3TreeLegendComponent } from './components/networks/network-d3-tree-legend/network-d3-tree-legend.component';
import { NetworkTopologiesComponent } from './components/networks/network-topologies/network-topologies.component';
import { CellNetworkLinkStatusComponent } from './components/core/cell-types/cell-network-link-status/cell-network-link-status.component';
import { AppSharedMonitorCommonModule } from '@razberi-ui/app-shared/monitor-common';
import { LoginComponent } from './components/auth/login/login.component';
import { ActivateLoginComponent } from './components/auth/activate-login/activate-login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { LoginFormComponent } from './components/auth/login-form/login-form.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { MultiFactorAuthModalComponent } from './components/auth/multi-factor-auth-modal/multi-factor-auth-modal.component';
import { EmailFormComponent } from './components/auth/email-form/email-form.component';
import { PasswordFormComponent } from './components/auth/password-form/password-form.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { MultiAuthInterceptor } from './interceptors/multi-auth.interceptor';
import { AppInitalizationComponent } from './components/core/app-initalization/app-initalization.component';
import { DataStoreService } from './services/data-store.service';
import { ManageTasksComponent } from './components/manage/manage-tasks/manage-tasks.component';
import { ApplyWinUpdateComponent } from './components/manage/manage-task-form/parameters/apply-win-update//apply-win-update.component';
import { ExecPackageComponent } from './components/manage/manage-task-form/parameters/exec-package/exec-package.component';
import { InstallMsiComponent } from './components/manage/manage-task-form/parameters/install-msi/install-msi.component';
import { PowershellScriptComponent } from './components/manage/manage-task-form/parameters/powershell-script/powershell-script.component';
import { RestartWindowsServiceComponent } from './components/manage/manage-task-form/parameters/restart-windows-service/restart-windows-service.component';
import { RunApplicationComponent } from './components/manage/manage-task-form/parameters/run-application/run-application.component';
import { WinUpdateComponent } from './components/manage/manage-task-form/parameters/win-update/win-update.component';
import { ParametersComponent } from './components/manage/manage-task-form/parameters/parameters.component';
import { CellManageTaskStatusComponent } from './components/core/cell-types/cell-manage-task-status//cell-manage-task-status.component';
import { CellManageTaskInstanceSummaryComponent } from './components/core/cell-types/cell-manage-task-instance-summary/cell-manage-task-instance-summary.component';
import { SettingsSmsFormComponent } from './components/settings/settings-sms-form/settings-sms-form.component';
import { RegisterTokenPublicComponent } from './components/setup/register-token-public/register-token-public.component';
import { RegisterAccountPublicComponent } from './components/setup/register-account-public/register-account-public.component';
import { RegisterBenefitsModalComponent } from './components/setup/register-benefits-modal/register-benefits-modal.component';

export const APP_CONFIG: AppConfig = {
	key: AppConfigKey.MonitorCloud,
	title: 'MonitorCloud',
};

@Component({
	selector: 'app-root-monitor-cloud',
	template: ` <theme-razberi-core>
		<ng-template #sidePanelOutlet>
			<app-root-monitor-cloud-page-side-panel></app-root-monitor-cloud-page-side-panel>
		</ng-template>
	</theme-razberi-core>`,
})
export class AppRootMonitorCloudComponent {}

@NgModule({
	imports: [
        AppRootMonitorCloudRoutingModule,
        CoreThemesModule,
        SharedModule.forRoot(),
        CloudApiModule.forRoot(),
        AppSharedMonitorCommonModule.forRoot(),
        RecaptchaV3Module
    ],
	declarations: [
		AppRootMonitorCloudComponent,
		DashboardComponent,
		InvalidSubscriptionModalComponent,
		MaintenanceComponent,
		GdprComponent,
		ResellerComponent,
		TagComponent,
		TagFormComponent,
		TagModalComponent,
		LocationFormComponent,
		LocationModalComponent,
		AuditLogsComponent,
		AuditLogTableComponent,
		PageSidePanelComponent,
		DataTableCellTypesComponent,
		ManagedDeviceDetailsSidePanelComponent,
		UnitUpdatesSidePanelComponent,
		UnitSidePanelComponent,
		ManagedDeviceSidePanelComponent,
		DevicesComponent,
		DeviceEditFormComponent,
		DeviceEditModalComponent,
		AccountsComponent,
		AccountAccordianComponent,
		AccountSidePanelComponent,
		DataTableSearchFormComponent,
		AlertsComponent,
		AlertTableComponent,
		AlertSidePanelComponent,
		AlertTabsComponent,
		AccountTableComponent,
		SettingsComponent,
		SettingsSmtpFormComponent,
		SettingsAwsFormComponent,
		SettingsCloudAgentFormComponent,
		SettingsCloudApiFormComponent,
		SettingsComplianceFormComponent,
		SettingsFilestoreFormComponent,
		SettingsGlobalFormComponent,
		SettingsLocalFilestoreFormComponent,
		SettingsManageFormComponent,
		SettingsMonitorAgentFormComponent,
		SettingsS3FilestoreFormComponent,
		SettingsSinkApiFormComponent,
		SettingsSsoFormComponent,
		SettingsSmsFormComponent,
		SettingsTropoFormComponent,
		SettingsTwilioFormComponent,
		AccountFormComponent,
		AccountModalComponent,
		UserFormComponent,
		UserModalComponent,
		AccountNoteFormComponent,
		AccountSubscriptionFormComponent,
		AccountSubscriptionViewComponent,
		AccountViewComponent,
		AccountViewTabAlertProfilesComponent,
		AccountViewTabBrandingComponent,
		AccountViewTabComplianceComponent,
		AccountViewTabDetailsComponent,
		AccountViewTabLocationsComponent,
		AccountViewTabSubscriptionComponent,
		AccountViewTabTagsComponent,
		AccountViewTabUsersComponent,
		PageTabsComponent,
		UserPasswordModalComponent,
		AlertProfileModalComponent,
		SelectedUnitsListModalComponent,
		AlertProfileFormComponent,
		AlertRecipientModalComponent,
		AlertRecipientsFormComponent,
		AlertTypeSelectorComponent,
		LocationDevicesComponent,
		UnitTableComponent,
		ManagedDeviceTableComponent,
		UnitGlobalSearchModalComponent,
		UnitOverrideWarrantyModalComponent,
		UnitUpdatesTaskSelectorModalComponent,
		UnitOverrideWarrantyFormComponent,
		ProfileComponent,
		ProfileDetailsComponent,
		ProfilePasswordComponent,
		ManageComponent,
		ManageTaskDetailComponent,
		ManageTaskTabsComponent,
		ManageTaskFormComponent,
		ManageTaskInstanceOutputModalComponent,
		ManageTaskInstanceTableComponent,
		ManageTaskTableComponent,
		UnitSelectorComponent,
		UnitSelectorItemComponent,
		UnitSelectorListComponent,
		UnitSelectorModalComponent,
		DashboardOutletCameraDefenseComponent,
		DashboardOutletCybersecurityPostureComponent,
		DashboardOutletUnitUpdatesComponent,
		DashboardOutletIotHealthComponent,
		DashboardOutletMalwareProtectionComponent,
		DashboardOutletManageTasksComponent,
		DashboardOutletOfflineDevicesComponent,
		DashboardOutletRecordedStreamsComponent,
		DashboardOutletRecordingServersComponent,
		DashboardOutletServerHealthComponent,
		DashboardOutletTotalAlertsComponent,
		DashboardOutletVmsHealthComponent,
		DashboardToolbarControlsComponent,
		VmsCameraStreamsTableComponent,
		VmsRecordingServersTableComponent,
		CellAccountStatusComponent,
		CellAccountSubscriptionStatusComponent,
		CellAlertMessageComponent,
		CellAlertStatusComponent,
		CellUnitUpdatesComponent,
		CellUserRolesComponent,
		CellUnitTitleComponent,
		DataTableComponent,
		NetworksComponent,
		NetworkDetailComponent,
		NetworkListComponent,
		NetworkLinkDetailModalComponent,
		NetworkD3TreeDiagramComponent,
		NetworkD3TreeLegendComponent,
		NetworkTopologiesComponent,
		CellNetworkLinkStatusComponent,
		LoginComponent,
		ActivateLoginComponent,
		LogoutComponent,
		LoginFormComponent,
		ResetPasswordComponent,
		MultiFactorAuthModalComponent,
		EmailFormComponent,
		PasswordFormComponent,
		LoginFormComponent,
		AppInitalizationComponent,
		ManageTasksComponent,
		ApplyWinUpdateComponent,
		ExecPackageComponent,
		InstallMsiComponent,
		PowershellScriptComponent,
		RestartWindowsServiceComponent,
		RunApplicationComponent,
		WinUpdateComponent,
		ParametersComponent,
		CellManageTaskStatusComponent,
		CellManageTaskInstanceSummaryComponent,
		RegisterTokenPublicComponent,
		RegisterAccountPublicComponent,
		RegisterBenefitsModalComponent,
	],
	exports: [AppRootMonitorCloudComponent],
})
export class AppRootMonitorCloudModule {
	static forRoot(appEnvironment: AppEnvironment): ModuleWithProviders<AppRootMonitorCloudModule> {
		return {
			ngModule: AppRootMonitorCloudModule,
			providers: [
				{
					provide: AppConfig,
					useValue: APP_CONFIG,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: MaintenanceInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ErrorInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: SpinnerInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: AuthInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: MultiAuthInterceptor,
					multi: true,
				},
				{
					provide: APP_INITIALIZER,
					deps: [CloudApiConfigService],
					useFactory: (configService: CloudApiConfigService) => {
						return (): Promise<void> => configService.helpers.initialize();
					},
					multi: true,
				},
				{
					provide: APP_INITIALIZER,
					deps: [VersionService],
					useFactory: (versionService: VersionService) => {
						return (): Promise<any> => versionService.helpers.initialize();
					},
					multi: true,
				},
                { provide: RECAPTCHA_V3_SITE_KEY, useValue: appEnvironment.reCaptchaSiteKey },
			],
		};
	}

	private hasRedirectedInit: boolean = false;

	constructor(
		private readonly settingsService: CoreServerSettingsService,
		private readonly navService: NavService,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly dataStoreService: DataStoreService
	) {
		this.addSubscriptions();
		// redirect to init page for first url, if not already redirected
		// init page will redirect the user to the initial url once app initialization is complete
		this.navService.streams.navStartEvents$.pipe(takeWhile((value) => this.hasRedirectedInit !== true)).subscribe((events: NavigationStart) => {
			if (events == null) {
				return;
			}
			this.hasRedirectedInit = true;
			this.dataStoreService.data.initRedirectUrl = events.url;
			this.router.navigate(['/init']);
		});
	}

	addSubscriptions() {
		// set app titles etc.
		this.settingsService.streams.settings$.subscribe((settings) => {
			if (settings == null) return;
			this.navService.data.appTitle = this.settingsService.data.settings.global.baseTitle;
			this.navService.data.baseTitle = APP_CONFIG.title;
		});

		// ensure user is logged in for routes that do not allow anonymous
		this.authService.streams.hasInitialized$.subscribe((hasInitialized) => {
			if (hasInitialized !== true) return;
			this.navService.streams.navEndEvents$
				.pipe(
					map(() => this.activatedRoute),
					map((route) => route.firstChild),
					switchMap((route) => route?.data || of(null)),
					map((data) => {
						if (data == null) return;
						if (data?.allowAnonymous !== true && this.authService.data.isAuthenticated !== true) {
							this.router.navigate(['/login']);
						}
					})
				)
				.subscribe();

			// set the correct default url to load once logged in
			this.authService.streams.isAuthenticated$.subscribe((isAuthenticated) => {
				if (isAuthenticated === true) {
					const defaultUrl = this.subscriptionService.helpers.isGlobalOrIsValid()
						? this.navService.baseUrlValidSubscription
						: this.navService.baseUrlInvalidSubscription;
					this.navService.data.authenticatedDefaultUrl = this.authService.data.redirectUrl ? this.authService.data.redirectUrl : defaultUrl;
				}
			});
		});
	}
}
