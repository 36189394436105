<div class="d-flex flex-column">
	<ng-container *ngIf="readonly === true">
		<ng-container *ngTemplateOutlet="dataTable"></ng-container>
	</ng-container>
	<ng-container *ngIf="readonly !== true">
		<ul ngbNav #panel="ngbNav" activeId="all" class="nav-tabs page-content-tabs" (navChange)="onTabChange($event)">
			<li ngbNavItem="all">
				<a ngbNavLink><fa-icon icon="search-plus"></fa-icon> Find Devices</a>
				<ng-template ngbNavContent>
					<ng-container *ngTemplateOutlet="dataTable"></ng-container>
				</ng-template>
			</li>
			<li ngbNavItem="selected" *ngIf="selectedUnits?.length > 0">
				<a ngbNavLink><fa-icon icon="check"></fa-icon> Selected Devices ({{ selectedUnits?.length || 0 }})</a>
				<ng-template ngbNavContent>
					<ng-container *ngTemplateOutlet="dataTable"></ng-container>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="panel"></div>
	</ng-container>
</div>

<ng-template #dataTable>
	<div class="row py-2">
		<div class="col-sm-6 d-flex align-items-center justify-content-start">
			<ng-container *ngIf="(selectableUnits?.length || 0) > 0">
				<div *ngIf="readonly !== true" class="d-flex align-items-center me-3 pe-3" style="border-right: 1px solid #ccc">
					<span class="me-3" style="font-weight: 500">Select</span>
					<button class="btn btn-link p-0" (click)="selectAllUnits()">All</button>
					<button class="btn btn-link p-0 ms-3" (click)="selectNoUnits()">None</button>
				</div>
				<div>{{ pagingStatus }}</div>
			</ng-container>
		</div>
		<div class="col-sm-6 d-flex align-items-center justify-content-end">
			<div class="d-flex align-items-center">
				<span class="me-3" style="font-weight: 500">Display:</span>
				<button class="btn btn-link p-0" (click)="selectObjectMode(selectableModeOptions.card)"><fa-icon icon="th"></fa-icon></button>
				<button class="btn btn-link p-0 ms-3" (click)="selectObjectMode(selectableModeOptions.row)"><fa-icon icon="list"></fa-icon></button>
				<button class="btn btn-link p-0 ms-3" (click)="selectObjectMode(selectableModeOptions.expanded)"><fa-icon icon="expand"></fa-icon></button>
			</div>
			<div class="d-flex align-items-center ms-3 ps-3" style="border-left: 1px solid #ccc">
				<span class="me-3" style="font-weight: 500">Sort:</span>
				<select
					class="form-select form-select-sm"
					id="sortBy"
					naame="sortBy"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="selectedSortBy"
					(change)="selectedSortByChanged()">
					<option ngValue="ASC">ASC</option>
					<option ngValue="DESC">DESC</option>
				</select>
			</div>
			<div class="ms-3 ps-3 d-flex" style="border-left: 1px solid #ccc; position: relative">
				<button *ngIf="filtersAreApplied === true" type="button" class="btn btn-link me-1" (click)="onResetFilter()" title="Reset Filters">
					<fa-icon icon="times"></fa-icon>
				</button>
				<button type="button" class="btn btn-link" (click)="showFilters = !showFilters" title="Show Filters"><fa-icon icon="filter"></fa-icon></button>
				<div
					*ngIf="showFilters === true"
					class="p-3"
					style="background-color: white; border: 1px solid #ccc; position: absolute; top: 3rem; right: 0; z-index: 2">
					<div class="mb-3">
						<label for="searchTermControl" class="mb-1">Search Term</label>
						<input id="searchTermControl" naame="searchTermControl" class="form-control" [formControl]="searchTermControl" />
					</div>
					<div class="mb-3">
						<label for="status" class="mb-1">Status</label>
						<shared-form-checkbox-select
							#deviceStatusesSelect
							[items]="availableStatuses"
							[(selectedItems)]="selectedStatuses"></shared-form-checkbox-select>
					</div>
					<div class="mb-3">
						<label for="locations" class="mb-1">Locations</label>
						<shared-form-multi-select
							#locationsSelect
							labelForId="locations"
							[items]="availableLocations"
							keyToFilterOn="item.name"
							clearable="true"
							[(selected)]="selectedLocations"
							[closeOnSelect]="false"></shared-form-multi-select>
					</div>
					<div class="mb-3">
						<label for="tags" class="mb-1">Tags</label>
						<shared-form-multi-select
							#tagsSelect
							labelForId="tags"
							[items]="availableTags"
							keyToFilterOn="item.name"
							clearable="true"
							[(selected)]="selectedTags"
							[closeOnSelect]="false"></shared-form-multi-select>
					</div>
					<div class="mb-3">
						<label for="status" class="mb-1">Updates</label>
						<shared-form-multi-select
							#updateTypesSelect
							labelForId="updateTypes"
							[items]="availableUpdateTypes"
							keyToFilterOn="item.name"
							clearable="true"
							[(selected)]="selectedUpdateTypes"
							[closeOnSelect]="false"></shared-form-multi-select>
					</div>
					<hr />
					<div class="mt-auto d-flex flex-row align-items-center">
						<button type="button" class="btn btn-link me-auto" (click)="onResetFilter()">
							<fa-icon icon="trash-alt" class="me-1"></fa-icon>Reset
						</button>
						<div class="flex-1">&nbsp;</div>
						<button type="button" class="btn btn-secondary ms-auto" (click)="onCancelFilter()" #cancelBtn>
							<fa-icon icon="times" class="me-1"></fa-icon>Cancel
						</button>
						<button type="button" class="btn btn-primary ms-2" (click)="onApplyFilter()">
							<fa-icon icon="search" class="me-1"></fa-icon>Apply
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr class="w-100 mt-1" />
	<div style="flex: 1">
		<ng-container *ngIf="(selectableUnits?.length || 0) === 0">
			<div class="mt-3 p-5 w-100 text-center">
				<i>No Devices Available</i>
			</div>
		</ng-container>
		<ng-container *ngIf="(selectableUnits?.length || 0) > 0">
			<div *ngIf="selectedObjectMode === selectableModeOptions.row" class="d-flex mb-2" style="font-weight: 500">
				<div style="width: 25%">
					<span [ngStyle]="{ 'margin-left': readonly !== true ? '1.5rem' : '0' }">Device</span>
				</div>
				<div style="width: 75%" class="d-flex">
					<div style="flex: 0 0 19%; margin-right: 1%; overflow-x: hidden">Type</div>
					<div style="flex: 0 0 14%; margin-right: 1%; overflow-x: hidden">Status</div>
					<div style="flex: 0 0 19%; margin-right: 1%; overflow-x: hidden">Location</div>
					<div style="flex: 0 0 22%; margin-right: 1%; overflow-x: hidden">Tags</div>
					<div style="flex: 0 0 22%; overflow-x: hidden">Updates</div>
				</div>
			</div>
			<div class="d-flex justify-content-start align-content-start" style="flex-wrap: wrap">
				<app-root-monitor-cloud-unit-selector-item
					*ngFor="let selectable of selectableUnits"
					[selectableUnit]="selectable"
					style="margin-bottom: 1%"
					[ngStyle]="{
						flex: selectable.mode === selectableModeOptions.card ? '0' : '0 0 100%',
						'margin-right': selectable.mode === selectableModeOptions.card ? '1%' : '0'
					}"
					(viewDeviceDetails)="viewDeviceDetails($event)">
				</app-root-monitor-cloud-unit-selector-item>
			</div>
		</ng-container>
	</div>
	<div class="d-flex align-content-senter justify-content-end mt-3">
		<div *ngIf="totalRecords > selectedPageSize" style="flex: 1">
			<ngb-pagination
				[ellipses]="true"
				[maxSize]="3"
				[collectionSize]="totalRecords"
				[(page)]="selectedPage"
				[pageSize]="selectedPageSize"
				[boundaryLinks]="true"
				(pageChange)="getFilteredData()"></ngb-pagination>
		</div>
		<div class="justify-content-end">
			<label class="page-size d-flex">
				<div class="me-1">show</div>
				<select
					class="form-select form-select-sm"
					style="width: auto"
					name="selectedPageSize"
					[(ngModel)]="selectedPageSize"
					[ngModelOptions]="{ standalone: true }"
					(change)="selectedPageSizeChanged()">
					<option [ngValue]="5">5</option>
					<option [ngValue]="10">10</option>
					<option [ngValue]="20">20</option>
					<option [ngValue]="50">50</option>
				</select>
				<div class="ms-1">per page</div>
			</label>
		</div>
	</div>
</ng-template>
