import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'shared-cell-link-button',
	templateUrl: './cell-link-button.component.html',
	styleUrls: ['./cell-link-button.component.scss'],
})
export class CellLinkButtonComponent {
	@Input() data: any;

	icon: string;
	iconClass: string;
	iconSize: string;
	image: string;
	imageClass: string;
	text: string;
	subtexts: any[];
	disabled: boolean;
	tooltip: any;

	constructor() {
		this.disabled = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			this.icon = changes.data.currentValue.icon;
			this.iconClass = changes.data.currentValue.iconClass;
			this.iconSize = changes.data.currentValue.iconSize;
			this.image = changes.data.currentValue.image;
			this.imageClass = changes.data.currentValue.imageClass;
			this.text = changes.data.currentValue.text;
			this.subtexts = changes.data.currentValue.subtexts;
			this.disabled = changes.data.currentValue.disabled;
			this.tooltip = changes.data.currentValue.tooltip;
		}
	}

	onClick($event) {
		if (this.disabled) $event.stopPropagation();
	}
}
