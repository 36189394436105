<shared-context-message *ngIf="isInitialized !== true" context="info">Loading Data...</shared-context-message>
<ng-container *ngIf="isInitialized === true">
	<ul ngbNav #panel="ngbNav" [activeId]="selectedTab" class="nav-tabs page-content-tabs">
		<li ngbNavItem="tasks">
			<a ngbNavLink><fa-icon icon="tasks"></fa-icon> Task History</a>
			<ng-template ngbNavContent>
				<app-root-monitor-cloud-manage-task-table
					[accountIds]="accountIds"
					[sortAsc]="false"
					[dateRange]="dateRange"
					[showAccount]="showAccount"
					[showRefresh]="showRefresh"
					[showDownloadCsv]="showDownloadCsv"
					[showSearch]="showSearch"
					[dataSource]="dataSource"></app-root-monitor-cloud-manage-task-table>
			</ng-template>
		</li>
		<li ngbNavItem="os-updates">
			<a ngbNavLink><fa-icon icon="arrow-to-bottom"></fa-icon> OS Updates</a>
			<ng-template ngbNavContent>
				<app-root-monitor-cloud-unit-table
					[accountIds]="accountIds"
					[dateRange]="dateRange"
					[showAccount]="showAccount"
					[showSearch]="showSearch"
					[dataSource]="dataSource"
					[enableUpdateMode]="true"></app-root-monitor-cloud-unit-table>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="panel"></div>
</ng-container>
