import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreSearchItem } from '../../data-types/core-search-item.model';

@Component({
	selector: 'shared-data-table-search',
	templateUrl: './data-table-search.component.html',
	styleUrls: ['./data-table-search.component.scss'],
})
export class DataTableSearchComponent {
	@Input() selectedSearchItems: CoreSearchItem<any>[] = [];
	@Output() applyFilter: EventEmitter<void> = new EventEmitter();
	@Output() resetFilter: EventEmitter<void> = new EventEmitter();
	@Output() itemRemoved: EventEmitter<CoreSearchItem<any>> = new EventEmitter();

	isOpen: boolean = false;

	closeSearch() {
		this.isOpen = false;
	}

	onApplyFilter() {
		this.isOpen = false;
		this.applyFilter.emit();
	}

	onResetFilter() {
		this.isOpen = false;
		this.resetFilter.emit();
	}

	clearItem(searchItem: CoreSearchItem<any>) {
		event.stopPropagation();
		this.itemRemoved.emit(searchItem);
	}

	getHiddenItemsTooltip() {
		return this.selectedSearchItems
			?.slice(3)
			?.map((item) => {
				return `${item.fieldTitle}: ${item.label}`;
			})
			?.join('\r\n');
	}
}
