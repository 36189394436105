import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-alert-message',
	templateUrl: './cell-alert-message.component.html',
	styleUrls: ['./cell-alert-message.component.scss'],
})
export class CellAlertMessageComponent {
	@Input() alert: Alert;

	constructor(private readonly router: Router) {}

	ngOnChanges(changes: SimpleChanges) {}

	viewNetwork() {
		this.router.navigate(['/networks', this.alert?.networkId, 'account', this.alert?.accountId]);
	}
}
