import { Component } from '@angular/core';
import { AlertMessageService, PageConfigService } from '@razberi-ui/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { PasswordFormData } from '../../../data-types/password-form-data.model';

@Component({
	selector: 'app-root-monitor-cloud-profile-password',
	templateUrl: './profile-password.component.html',
	styleUrls: ['./profile-password.component.scss'],
})
export class ProfilePasswordComponent {
	formPassword: PasswordFormData;
	isValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	resetSubject: Subject<void> = new Subject<void>();
	reset$: Observable<void> = this.resetSubject.asObservable();

	constructor(
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly userService: UserService
	) {}

	ngOnInit() {
		this.pageConfigService.data.toolbarConfig = {
			buttons: [
				{
					text: 'Change Password',
					icon: 'check',
					color: 'primary',
					isEnabled$: this.isValidSubject.asObservable(),
					click: () => {
						this.onSubmit();
					},
				},
			],
		};
	}

	ngOnDestroy() {
		this.isValidSubject.complete();
		this.resetSubject.complete();
		this.pageConfigService.data.toolbarConfig = null;
	}

	onIsValid(isValid: boolean) {
		this.isValidSubject.next(isValid);
	}

	onChanges(formPassword: PasswordFormData) {
		this.formPassword = formPassword;
	}

	onSubmit() {
		if (this.isValidSubject.value) {
			this.userService.api.setCurrentUserPassword({ ...this.formPassword }).subscribe({
				next: (_) => {
					this.alertMessageService.success('Password changed.');
					this.resetSubject.next();
				},
				error: (error) => {
					this.alertMessageService.error('Error changing password.', error);
				},
			});
		}
	}
}
