<h4 class="card-title text-center">Reseller Agreement</h4>
<div *ngIf="pageStatus === pageStatusOptions.RegionSelect">
	<div class="row mt-3">
		<div class="col-sm-12 d-flex justify-content-center">
			<h6>Please select your region:</h6>
		</div>
	</div>
	<div class="row mt-3 mb-5">
		<div class="col-sm-12 d-flex justify-content-center">
			<button class="btn btn-light me-3" (click)="selectRegion(resellerRegionOptions.Americas)" [focusIf]="false">Americas</button>
			<button class="btn btn-light me-3" (click)="selectRegion(resellerRegionOptions.International)" [focusIf]="false">International</button>
		</div>
	</div>
</div>
<div *ngIf="pageStatus === pageStatusOptions.Agreement">
	<p class="card-text mt-4 text-center font-bold">You must read and consent to the following agreement.</p>
	<div
		[onScrollEnd]="scrollCompleteFunc"
		[innerHtml]="agreement"
		class="mt-4 border"
		style="padding: 1rem 1.5rem; min-height: 100px; max-height: 300px; overflow-y: auto"></div>
	<p *ngIf="message" class="card-text text-danger text-center mt-5 mb-3">{{ message }}</p>
	<div class="mt-5 text-end">
		<button class="btn btn-light ms-2" (click)="consent(false)">Do Not Agree</button>
		<button [disabled]="scrollIsComplete !== true" class="btn btn-primary ms-2" (click)="consent(true)">Agree</button>
	</div>
</div>
<div *ngIf="pageStatus === pageStatusOptions.Complete">Complete</div>
<div *ngIf="pageStatus === pageStatusOptions.Rejected">
	<div class="d-flex justify-content-center align-self-center mt-4">
		<fa-icon icon="info-circle" size="lg" class="text-primary me-3"></fa-icon>
		<h5 class="card-title text-center">Consent Rejected</h5>
	</div>
	<div class="text-center mt-3">
		<p>We are sorry that you did not consent to our Reseller agreement.</p>
		<p>Consent is required to continue your {{ configService.data.baseTitle || 'MonitorCloud' }} account.</p>
		<div class="mt-5">
			<ng-container *ngIf="isLoggedOut === true">
				<p class="h5">You have been logged out of the system.</p>
				<p class="mt-3 font-italic">Please return to the login page to restart the Reseller consent process.</p>
			</ng-container>
			<ng-container *ngIf="isLoggedOut !== true">
				<p class="font-italic">We are logging you out of the system.</p>
			</ng-container>
		</div>
	</div>
	<div class="text-center my-5">
		<button class="btn btn-primary" (click)="gotoLogin()" [disabled]="isLoggedOut !== true">Return to Login</button>
	</div>
</div>
