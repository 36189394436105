<div class="modal-header">
	<h4 class="modal-title"><fa-icon icon="exclamation-circle"></fa-icon><span class="ps-2">Multi Factor Authentication Required</span></h4>
</div>
<div class="modal-body py-4 px-5">
	<ng-container *ngIf="pageMode === pageModeOptions.TypeSelect">
		<div class="row">
			<div class="col-sm-12">
				<h6><b>AUTHENTICATION METHOD:</b></h6>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<p>Please select a multi factor authentication method, and click "Send Authentication Code" to proceed:</p>
			</div>
		</div>
		<div class="row mt-3 ms-4">
			<div class="col-sm-12">
				<div class="form-check">
					<input
						type="radio"
						class="form-check-input"
						id="selectedRegistrationModeEmail"
						name="selectedRegistrationMode"
						[value]="selectedTokenTypeOptions.Email"
						(change)="selectedTokenType = selectedTokenTypeOptions.Email"
						[checked]="selectedTokenType === selectedTokenTypeOptions.Email"
						autocomplete="off" />
					<label class="form-check-label" for="selectedRegistrationModeEmail">
						<fa-icon icon="envelope" size="lg" class="ms-1 me-2"></fa-icon>
						<span>EMAIL to {{ userEmail }}</span>
					</label>
				</div>
			</div>
		</div>
		<div class="row mt-1 ms-4" *ngIf="smsEnabled === true">
			<div class="col-sm-12">
				<div class="form-check">
					<input
						type="radio"
						class="form-check-input"
						id="selectedRegistrationModeSms"
						name="selectedRegistrationMode"
						[value]="selectedTokenTypeOptions.SMS"
						(change)="selectedTokenType = selectedTokenTypeOptions.SMS"
						[checked]="selectedTokenType === selectedTokenTypeOptions.SMS"
						[disabled]="userPhone === null || userPhoneVerified !== true"
						autocomplete="off" />
					<label class="form-check-label" for="selectedRegistrationModeSms">
						<fa-icon icon="message-sms" size="lg" class="ms-1 me-2"></fa-icon>
						<span *ngIf="userPhoneVerified === true">SMS to {{ userPhone }}</span>
						<span *ngIf="userPhone === null || userPhoneVerified !== true" class="font-bold">SMS authentication is not configured.</span>
					</label>
				</div>
			</div>
		</div>
		<div class="row ms-5" *ngIf="smsEnabled === true && (userPhone === null || userPhoneVerified !== true)">
			<div class="col-sm-12">
				<button class="btn btn-link" (click)="pageMode = pageModeOptions.ConfigureSMS">
					<div>Configure SMS Authentication</div>
				</button>
			</div>
		</div>
		<div class="row mt-3 ms-4" *ngIf="tokenExists === true">
			<div class="col-sm-12">
				<button class="btn btn-link" (click)="pageMode = pageModeOptions.CodeEntry">Already have a code?</button>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="pageMode === pageModeOptions.CodeEntry">
		<div class="row">
			<div class="col-sm-12">
				<h6><b>AUTHENTICATION CODE:</b></h6>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<p>Enter the provided authentication code from the email/SMS message, and select "Verify Code" to proceed.</p>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-sm-12">
				<form [formGroup]="authenticationForm" autocomplete="off" novalidate (submit)="verifyToken()">
					<div class="row mb-3">
						<div class="col-sm-12">
							<shared-code-entry
								id="authenticationCode"
								formControlName="authenticationCode"
								[characterCount]="6"
								[autoSubmit]="true"></shared-code-entry>
						</div>
						<shared-input-validation-messages
							[control]="authenticationForm.controls.authenticationCode"
							[clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
					</div>
				</form>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-sm-12">
				<h6>If you did not receive the authentication code:</h6>
				<button class="btn btn-link" (click)="pageMode = pageModeOptions.TypeSelect">Send New Authentication Code</button>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="pageMode === pageModeOptions.ConfigureSMS">
		<div class="row">
			<div class="col-sm-12">
				<h6><b>CONFIGURE SMS:</b></h6>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<p>Enter the phone number you would like to use to receive SMS messages, and select "Configure SMS" to proceed.</p>
			</div>
		</div>
		<form class="row mt-1" [formGroup]="smsConfigurationForm" autocomplete="off">
			<div class="col-sm-6">
				<div class="input-group">
					<span class="input-group-text"><fa-icon icon="message-sms"></fa-icon></span>
					<input
						type="text"
						class="form-control"
						id="phoneNumber"
						name="phoneNumber"
						placeholder="SMS phone number"
						formControlName="phoneNumber"
						autocomplete="off" />
				</div>
				<shared-input-validation-messages
					[control]="smsConfigurationForm.controls.phoneNumber"
					[clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
			</div>
		</form>
		<div class="row">
			<div class="col-sm-12 mt-3 text-muted">
				<fa-icon class="me-1" icon="info-circle"></fa-icon> For numbers outside of US, please include the country code: +X XXX XXX XXXX
			</div>
		</div>
	</ng-container>
</div>
<div class="modal-footer">
	<button *ngIf="pageMode === pageModeOptions.TypeSelect" type="button" class="btn btn-primary float-right" (click)="sendToken()" ngbAutoFocus>
		<fa-icon class="me-2" icon="paper-plane"></fa-icon> Send Authentication Code
	</button>
	<button
		*ngIf="pageMode === pageModeOptions.CodeEntry"
		type="button"
		class="btn btn-primary float-right"
		(click)="verifyToken()"
		[disabled]="authenticationForm.valid !== true">
		<fa-icon class="me-2" icon="check"></fa-icon> Verify Code
	</button>
	<button
		*ngIf="pageMode === pageModeOptions.ConfigureSMS"
		type="button"
		class="btn btn-primary float-right"
		(click)="configureSMS()"
		[disabled]="smsConfigurationForm.valid !== true">
		<fa-icon class="me-2" icon="sms"></fa-icon> Configure SMS
	</button>
	<button type="button" class="btn btn-secondary float-right" (click)="close()"><fa-icon icon="times"></fa-icon> Cancel</button>
</div>
