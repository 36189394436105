import { Component, Input, SimpleChanges } from '@angular/core';
import { Unit } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-unit-title',
	templateUrl: './cell-unit-title.component.html',
	styleUrls: ['./cell-unit-title.component.scss'],
})
export class CellUnitTitleComponent {
	@Input() unit: Unit;
	@Input() disabled: boolean = false;

	title: string;
	subTitle: string;

	ngOnChanges(changes: SimpleChanges) {
		this.title = this.unit?.name ?? '--';
		this.subTitle = this.unit?.name === this.unit?.serialNumber ? null : this.unit?.serialNumber;
	}

	onClick($event) {
		if (this.disabled) $event.stopPropagation();
	}
}
