import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@razberi-ui/api/cloud-api';
import { AccountType } from '@razberi-ui/core/data-types';
import { Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-unit-global-search-modal',
	templateUrl: './unit-global-search-modal.component.html',
	styleUrls: ['./unit-global-search-modal.component.scss'],
})
export class UnitGlobalSearchModalComponent {
	isValid: boolean = false;
	isSubmitted: boolean = false;
	lookup: string;
	lookupForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private readonly activeModal: NgbActiveModal, private readonly formBuilder: UntypedFormBuilder, private readonly authService: AuthService) {}

	ngOnInit() {
		this.lookupForm = this.formBuilder.group({
			lookup: [null, [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9]*$/)]],
		});

		if (this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)
			this.activeModal.dismiss('account');
		this.subscriptions.add(
			this.lookupForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid = status === 'VALID';
			})
		);
	}

	search() {
		if (this.lookupForm.valid) {
			this.lookup = this.lookupForm.value.lookup;
			this.isSubmitted = true;
		}
	}

	isInvalid(field: string) {
		return this.lookupForm.get(field).touched && this.lookupForm.get(field).invalid;
	}

	ok() {
		this.activeModal.close();
	}

	close() {
		this.activeModal.dismiss();
	}
}
