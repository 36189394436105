import { EnumAttributes } from '@razberi-ui/shared';

export enum NetworkLinkType {
	Unknown = 0,
	Monitored = 1,
	Unmonitored = 2,
	AgentInternalSwitch = 3,
}

export enum NetworkLinkStatus {
	Unknown = 0, // grey
	Connected = 1, // green
	Disconnected = 2, // red
	Unavailable = 3, // yellow
}

export const NetworkLinkStatusAttributes = new Map<NetworkLinkStatus, EnumAttributes>([
	[NetworkLinkStatus.Unknown, { label: 'Unknown' }],
	[NetworkLinkStatus.Connected, { label: 'Connected' }],
	[NetworkLinkStatus.Disconnected, { label: 'Disconnected' }],
	[NetworkLinkStatus.Unavailable, { label: 'Unavailable' }],
]);

export interface NetworkLink {
	networkLinkId: number;
	networkId: number;
	status?: NetworkLinkStatus;
	type?: NetworkLinkType;
	parentUnitKey: string;
	parentManagedDeviceKey: string;
	parentPort: string;
	childUnitKey: string;
	childManagedDeviceKey: string;
	childPort: string;
	// additional properties
	isDirty?: boolean;
	flagDelete?: boolean;
}
