import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'shared-context-message',
	templateUrl: './context-message.component.html',
	styleUrls: ['./context-message.component.scss'],
})
export class ContextMessageComponent implements OnInit {
	@Input() type: string;
	@Input() title: string;

	//@ContentChild('content') content: ElementRef;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	icon(type: string) {
		switch (type) {
			case 'success':
				return 'check-circle';
			case 'info':
				return 'info-circle';
			case 'warning':
				return 'exclamation-triangle';
			case 'danger':
				return 'exclamation-circle';
			case 'primary':
			case 'secondary':
			case 'light':
			case 'dark':
			default:
				return 'info-circle';
		}
	}

	// 'success'
	// 'info'
	// 'warning'
	// 'danger'
	// 'primary'
	// 'secondary'
	// 'light'
	// 'dark'
}
