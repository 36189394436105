<div class="fs-4 mb-2">Recording Services</div>
<ul ngbNav #panel="ngbNav" activeId="alerts" class="nav-tabs page-content-tabs">
	<li ngbNavItem="alerts">
		<a ngbNavLink><fa-icon icon="video-slash"></fa-icon> Inactive Services</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-vms-recording-servers-table [showAccount]="true" [dataSource]="'dashboard-recording-servers'" [getOffline]="true">
			</app-root-monitor-cloud-vms-recording-servers-table>
		</ng-template>
	</li>
	<li ngbNavItem="closed-alerts">
		<a ngbNavLink><fa-icon icon="video"></fa-icon> Active Services</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-vms-recording-servers-table [showAccount]="true" [dataSource]="'dashboard-recording-servers'" [getOffline]="false">
			</app-root-monitor-cloud-vms-recording-servers-table>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="panel"></div>
