import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
	selector: 'shared-cell-icon',
	templateUrl: './cell-icon.component.html',
	styleUrls: ['./cell-icon.component.scss'],
})
export class CellIconComponent {
	@Input() data: any;

	icon: string;
	iconClass: string;
	iconSize: string;
	image: string;
	imageClass: string;
	text: string;
	subtexts: any[];
	title: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			this.icon = changes.data.currentValue.icon;
			this.iconClass = changes.data.currentValue.iconClass;
			this.iconSize = changes.data.currentValue.iconSize;
			this.image = changes.data.currentValue.image;
			this.imageClass = changes.data.currentValue.imageClass;
			this.text = changes.data.currentValue.text;
			this.subtexts = changes.data.currentValue.subtexts;
			this.title = changes.data.currentValue.title;
		}
	}
}
