<div class="main-container">
	<shared-alert-message-outlet></shared-alert-message-outlet>
	<div class="view-container" class="collapsed">
		<theme-razberi-activation-toolbar></theme-razberi-activation-toolbar>
		<div class="page-container">
			<div class="page-content container-fluid">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
<ngx-spinner type="ball-pulse" size="medium"></ngx-spinner>
