<div id="modal-container" class="modal-container">
	<div id="modal-header" class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="unlock"></fa-icon><span class="ps-2">{{ isCurrentUser ? 'Change' : 'Reset' }} Password</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div id="modal-body" class="modal-body">
		<app-root-monitor-cloud-auth-password-form
			[isCurrentUser]="isCurrentUser"
			[requireExisting]="true"
			[autoFocus]="true"
			(isValid)="onIsValid($event)"
			(changes)="onChanges($event)"
			(submit)="resetPassword()"></app-root-monitor-cloud-auth-password-form>
	</div>
	<div id="modal-footer" class="modal-footer">
		<button id="btn-cancel" type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button id="btn-reset" type="button" class="btn btn-primary float-right" (click)="resetPassword()" [disabled]="!isValid">
			<fa-icon icon="unlock"></fa-icon> {{ isCurrentUser ? 'Change' : 'Reset' }}
		</button>
	</div>
</div>
