import { Injectable } from '@angular/core';
import { CreateAccountRequest, CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { Account, AccountNote } from '@razberi-ui/core/data-types';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAccountsFilter: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Account>> => {
				return this.cloudApiService.accounts.getAccountsFilter(searchFilter);
			},
			getAccounts: (): Observable<Account[]> => {
				return this.cloudApiService.accounts.getAccounts();
			},
			getProviderAccounts: (): Observable<Account[]> => {
				return this.cloudApiService.accounts.getProviderAccounts();
			},
			getAccount: (accountId: number): Observable<Account> => {
				return this.cloudApiService.accounts.getAccount(accountId);
			},
			getCurrentAccount: (): Observable<Account> => {
				return this.cloudApiService.accounts.getCurrentAccount();
			},
			createAccount: (createAccountRequest: CreateAccountRequest): Observable<void> => {
				return this.cloudApiService.accounts.createAccount(createAccountRequest);
			},
			createAccountPublic: (createAccountRequest: CreateAccountRequest): Observable<void> => {
				return this.cloudApiService.accounts.createAccountPublic(createAccountRequest);
			},
			updateAccount: (account: Account): Observable<void> => {
				return this.cloudApiService.accounts.updateAccount(account);
			},
			updateCurrentAccount: (account: Account): Observable<void> => {
				return this.cloudApiService.accounts.updateCurrentAccount(account);
			},
			deleteAccount: (accountId: number): Observable<void> => {
				return this.cloudApiService.accounts.deleteAccount(accountId);
			},
			getAccountLogo: (accountId: number): Observable<any> => {
				return this.cloudApiService.accounts.getAccountLogo(accountId);
			},
			getAccountFavicon: (accountId: number): Observable<any> => {
				return this.cloudApiService.accounts.getAccountFavicon(accountId);
			},
			getAccountCustomEmailFooter: (accountId: number): Observable<any> => {
				return this.cloudApiService.accounts.getAccountCustomEmailFooter(accountId);
			},
			getCurrentAccountLogo: (): Observable<any> => {
				return this.cloudApiService.accounts.getCurrentAccountLogo();
			},
			getCurrentAccountFavicon: (): Observable<any> => {
				return this.cloudApiService.accounts.getCurrentAccountFavicon();
			},
			getCurrentAccountCustomEmailFooter: (): Observable<any> => {
				return this.cloudApiService.accounts.getCurrentAccountCustomEmailFooter();
			},
			setAccountLogo: (accountId: number, imageDto: any): Observable<any> => {
				return this.cloudApiService.accounts.setAccountLogo(accountId, imageDto);
			},
			setAccountFavicon: (accountId: number, imageDto: any): Observable<any> => {
				return this.cloudApiService.accounts.setAccountFavicon(accountId, imageDto);
			},
			setAccountCustomEmailFooter: (accountId: number, customEmailFooter: string): Observable<any> => {
				return this.cloudApiService.accounts.setAccountCustomEmailFooter(accountId, customEmailFooter);
			},
			setCurrentAccountLogo: (imageDto: any): Observable<any> => {
				return this.cloudApiService.accounts.setCurrentAccountLogo(imageDto);
			},
			setCurrentAccountFavicon: (imageDto: any): Observable<any> => {
				return this.cloudApiService.accounts.setCurrentAccountFavicon(imageDto);
			},
			setCurrentAccountCustomEmailFooter: (customEmailFooter: string): Observable<any> => {
				return this.cloudApiService.accounts.setCurrentAccountCustomEmailFooter(customEmailFooter);
			},
			removeAccountLogo: (accountId: number): Observable<any> => {
				return this.cloudApiService.accounts.removeAccountLogo(accountId);
			},
			removeAccountFavicon: (accountId: number): Observable<any> => {
				return this.cloudApiService.accounts.removeAccountFavicon(accountId);
			},
			removeAccountCustomEmailFooter: (accountId: number): Observable<any> => {
				return this.cloudApiService.accounts.removeAccountCustomEmailFooter(accountId);
			},
			removeCurrentAccountLogo: (): Observable<any> => {
				return this.cloudApiService.accounts.removeCurrentAccountLogo();
			},
			removeCurrentAccountFavicon: (): Observable<any> => {
				return this.cloudApiService.accounts.removeCurrentAccountFavicon();
			},
			removeCurrentAccountCustomEmailFooter: (): Observable<any> => {
				return this.cloudApiService.accounts.removeCurrentAccountCustomEmailFooter();
			},
			getAccountNote: (accountId: number): Observable<AccountNote> => {
				return this.cloudApiService.accounts.getAccountNote(accountId);
			},
			setAccountNote: (accountNote: AccountNote): Observable<void> => {
				return this.cloudApiService.accounts.setAccountNote(accountNote);
			},
			getAccountData: (accountId: number): Observable<Account> => {
				return this.cloudApiService.accounts.getAccountData(accountId);
			},
			getCurrentAccountData: (): Observable<Account> => {
				return this.cloudApiService.accounts.getCurrentAccountData();
			},
			getAccountsData: (accountIds: number[] = null): Observable<Account[]> => {
				return this.cloudApiService.accounts.getAccountsData(accountIds);
			},
		};
	}

	get helpers() {
		return {
			getAccountLocation: (account: Account): string => {
				return `${account.city}, ${account.stateProvince}, ${account.countryRegion}`.trim();
			},
			getUniqueLocations: (accounts: Account[]): { name: string }[] => {
				let distinctLocations = [...new Set(accounts.map((a) => this.helpers.getAccountLocation(a)))];
				return distinctLocations.map((loc) => {
					return { name: loc };
				});
			},
		};
	}
}
