import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'shared-cell-router-link',
	templateUrl: './cell-router-link.component.html',
	styleUrls: ['./cell-router-link.component.scss'],
})
export class CellRouterLinkComponent {
	@Input() data: any;

	icon: string;
	iconClass: string;
	image: string;
	imageClass: string;
	text: string;
	route: string[];
	subtexts: any[];
	disabled: boolean;

	constructor(private readonly router: Router) {
		this.disabled = false;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.currentValue) {
			this.icon = changes.data.currentValue.icon;
			this.iconClass = changes.data.currentValue.iconClass;
			this.image = changes.data.currentValue.image;
			this.imageClass = changes.data.currentValue.imageClass;
			this.text = changes.data.currentValue.text;
			this.route = changes.data.currentValue.route;
			this.subtexts = changes.data.currentValue.subtexts;
			this.disabled = changes.data.currentValue.disabled;
		}
	}

	navigate($event) {
		if (this.disabled) $event.stopPropagation();
		this.router.navigate(this.route);
	}
}
