<div class="d-flex align-items-center justify-content-right">
	<button
		id="btn-download-report"
		type="button"
		class="btn btn-primary mx-1"
		(click)="downloadReport()"
		[disabled]="(dashboardService.data.selectedAccounts?.length || 0) == 0"
		ngbTooltip="Downloads a PDF Account Status Report for the {{
			isCustomer ? 'current account' : 'selected accounts'
		}}. The report contains an overview of the current open alerts and system health status, and identifies critical issues affecting your devices. A breakdown of the devices and appliances managed by {{
			settingsService.data.settings?.global?.baseTitle || 'MonitorCloud'
		}}™ is also shown, along with charts and graphs that show trends in alert frequencies over time."
		placement="bottom"
		tooltipClass="wider-tooltip">
		<fa-icon icon="download"></fa-icon> Download Report
	</button>
	<shared-form-multi-select
		class="mx-1"
		style="width: 500px"
		[style.display]="isCustomer !== true ? '' : 'none'"
		[dotsAfter]="2"
		[items]="availableAccounts"
		[(selected)]="selectedAccounts"
		(selectedChange)="onSelectedAccountsChange($event)"
		[clearable]="true"
		[closeOnSelect]="false">
	</shared-form-multi-select>
	<ng-select
		id="dateRange"
		[(ngModel)]="daysSelected"
		(ngModelChange)="onDateRangeSelect($event)"
		[multiple]="false"
		[searchable]="false"
		[clearable]="false"
		style="min-width: 125px">
		<ng-option [value]="7">Last 7 Days</ng-option>
		<ng-option [value]="30">Last 30 Days</ng-option>
		<ng-option [value]="60">Last 60 Days</ng-option>
		<ng-option [value]="90">Last 90 Days</ng-option>
		<ng-option [value]="-1">All</ng-option>
	</ng-select>
</div>
