import { Component } from '@angular/core';
import { AppConfig, AppConfigKey, NavService } from '@razberi-ui/shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'theme-razberi-core-card-toolbar',
	templateUrl: './theme-razberi-core-card-toolbar.component.html',
	styleUrls: ['./theme-razberi-core-card-toolbar.component.scss'],
})
export class ThemeRazberiCoreCardToolbarComponent {
	subscriptions: Subscription[];
	appConfigKeys: any = AppConfigKey;

	constructor(readonly appConfig: AppConfig, readonly navService: NavService) {}

	ngOnInit() {}
}
