import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import {
	ManageTask,
	ManageTaskConfirmationMessage,
	ManageTaskExportRequest,
	ManageTaskNotificationType,
	ManageTaskStatus,
	ManageTaskType,
} from '@razberi-ui/core/data-types';
import { MatchType } from '@razberi-ui/shared';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ManageService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getTasks: (searchFilter?: SearchFilter): Observable<SearchFilterResult<ManageTask>> => {
				return this.cloudApiService.manage.getTasks(searchFilter);
			},
			getTask: (accountId: number, manageTaskId: number): Observable<ManageTask> => {
				return this.cloudApiService.manage.getTask(accountId, manageTaskId);
			},
			createTask: (accountId: number, task: ManageTask, uploadFiles: File[]): Observable<any> => {
				task.targetUnitIds = [];
				task.units?.forEach((u) => task.targetUnitIds.push(u.unitId));
				task.units = null;

				let formData = new FormData();
				formData.append('manageTask', JSON.stringify(task));
				uploadFiles.forEach((f) => {
					formData.append('uploadFiles', f, f.name);
				});
				return this.cloudApiService.manage.createTask(accountId, formData);
			},
			getManageTaskFile: (accountId: number, manageTaskFileId: number): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.manage.getManageTaskFile(accountId, manageTaskFileId);
			},
			cancelTask: (accountId: number, manageTaskId: number): Observable<boolean> => {
				return this.cloudApiService.manage.cancelTask(accountId, manageTaskId);
			},
			getTaskTypes: (): Observable<ManageTaskType[]> => {
				return this.cloudApiService.manage.getTaskTypes();
			},
			getAllTaskTypes: (): Observable<ManageTaskType[]> => {
				return this.cloudApiService.manage.getAllTaskTypes();
			},
			getManageTasksCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.manage.getManageTasksCsv(searchFilter);
			},
			getManageTaskInstancesCsv: (accountId: number, manageTaskId: number): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.manage.getManageTaskInstancesCsv(accountId, manageTaskId);
			},
			getManageTasksExport: (requests: ManageTaskExportRequest[]): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.manage.getManageTasksExport(requests);
			},
		};
	}

	get helpers() {
		return {
			getDefaultTask: (accountId: number): ManageTask => {
				if (accountId == null) return null;

				return {
					manageTaskId: 0,
					accountId: accountId,
					manageTaskGuid: null,
					manageTaskTypeId: undefined,
					name: null,
					description: null,
					status: ManageTaskStatus.Scheduled,
					matchType: MatchType.Any,
					parameters: null,
					executeAt: new Date(),
					concurrent: 0,
					errorHandling: 0,
					locations: [],
					tags: [],
					units: [],
					notificationType: ManageTaskNotificationType.None,
				};
			},
			copyTask: (accountId: number, manageTask: ManageTask): ManageTask => {
				if (accountId == null) return null;

				if (manageTask == null) return manageTask;

				let copy = { ...manageTask };
				copy.manageTaskId = 0;
				copy.manageTaskGuid = null;
				copy.accountId = accountId;
				copy.status = ManageTaskStatus.Scheduled;
				copy.matchType = MatchType.Any;
				copy.name = `COPY OF: ${manageTask.name}`;
				copy.description = `COPY OF TASK: ${manageTask.manageTaskId} / ${manageTask.manageTaskGuid}; ` + (manageTask.description ?? '');
				copy.scheduledTimestamp = null;
				copy.executeAt = new Date();
				return copy;
			},
			buildTaskInstanceGroups: (tasks: ManageTask[]): any => {
				let key = 'manageTaskId';
				return tasks.reduce(
					(result, currentValue) => ({
						...result,
						[currentValue[key]]: (result[currentValue[key]] || []).concat(
							currentValue.manageTaskInstances?.map((mti) => mti.manageTaskInstanceId) ?? []
						),
					}),
					{}
				);
			},
			getTaskSaveConfirmation: (task: ManageTask): ManageTaskConfirmationMessage => {
				const deserialized = JSON.parse(task.parameters);
				const confMessage: ManageTaskConfirmationMessage = {
					message: '',
				};
				if (task.manageTaskTypeId === 1) {
					// custom script
					(confMessage.message =
						"In deploying this custom script, you and your company take full responsibility for the script's operation and cybersecurity."),
						(confMessage.buttonCancelText = 'Return to Form'),
						(confMessage.buttonText = 'OK, Commit Task');
				} else if (task.manageTaskTypeId == 3) {
					// win update
					if ((deserialized?.categories?.length ?? 0) == 0 && (deserialized.excludeKBs?.length ?? 0) == 0)
						confMessage.message += 'No criteria selected. Do you want to apply all available updates?<br/>';

					if (deserialized.allowReboot !== true) {
						confMessage.message += 'If you choose not to allow reboot, then some updates might fail to install.<br/>';
					}
				} else if (task.manageTaskId === 7) {
					// apply win updsate
					if (deserialized.allowReboot !== true) {
						confMessage.message += 'If you choose not to allow reboot, then some updates might fail to install.<br/>';
					}
				}

				return confMessage;
			},
		};
	}
}
