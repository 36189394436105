<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="eye"></fa-icon><span class="ps-2">Output: {{ manageTaskInstance?.unitName || 'Unknown' }}</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<div class="row mb-3">
			<div class="col">
				<h4>Output</h4>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<textarea id="output" rows="10" class="w-100" disabled="true">{{ manageTaskInstance?.output || '--' }}</textarea>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col">
				<h4>Files ({{ manageTaskInstance?.files?.length || 0 }})</h4>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<ng-container *ngIf="(manageTaskInstance?.files?.length || 0) === 0">
					<div class="w-100 p-3 text-center box-shadow font-italic">No Files Available</div>
				</ng-container>
				<ng-container *ngIf="manageTaskInstance?.files?.length > 0">
					<div class="px-5">
						<shared-data-table [tableSettings]="fileTableSettings" [tableItems]="manageTaskInstance?.files || []"></shared-data-table>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Close</button>
	</div>
</div>
