<div class="fs-4 mb-2">Camera Status</div>
<ul ngbNav #panel="ngbNav" activeId="alerts" class="nav-tabs page-content-tabs">
	<li ngbNavItem="alerts">
		<a ngbNavLink><fa-icon icon="cctv"></fa-icon> Cameras Not Recording</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-vms-camera-streams-table
				[showAccount]="true"
				[dataSource]="'dashboard-recorded-streams'"
				[cameraStreamStatuses]="[0, 2, 4]">
			</app-root-monitor-cloud-vms-camera-streams-table>
		</ng-template>
	</li>
	<li ngbNavItem="closed-alerts">
		<a ngbNavLink><fa-icon icon="cctv"></fa-icon> Cameras Recording</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-vms-camera-streams-table [showAccount]="true" [dataSource]="'dashboard-recorded-streams'" [cameraStreamStatuses]="[1]">
			</app-root-monitor-cloud-vms-camera-streams-table>
		</ng-template>
	</li>
	<li ngbNavItem="disabled-streams">
		<a ngbNavLink><fa-icon icon="cctv"></fa-icon> Disabled Cameras</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-vms-camera-streams-table [showAccount]="true" [dataSource]="'dashboard-recorded-streams'" [cameraStreamStatuses]="[3]">
			</app-root-monitor-cloud-vms-camera-streams-table>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="panel"></div>
