<shared-data-table-search
	[selectedSearchItems]="selectedSearchItems"
	(applyFilter)="applySearchFilter()"
	(resetFilter)="resetSearchFilter()"
	(itemRemoved)="removeSearchItem($event)">
	<ng-container dataTableSearchForm *ngTemplateOutlet="searchForm"></ng-container>
</shared-data-table-search>

<ng-template #searchForm>
	<div *ngIf="searchConfiguration?.showSearchTerm === true" class="mb-3">
		<label for="searchTerm" class="mb-1"><fa-icon icon="text"></fa-icon> Search Term</label>
		<input
			class="form-control"
			id="searchTerm"
			[(ngModel)]="trackedSearchItems[searchItemKeys.SearchTerm]"
			(keyup.enter)="applySearchFilter()"
			ngbAutofocus />
	</div>
	<div *ngIf="searchConfiguration?.showDateRange === true" class="mb-3">
		<shared-date-range-select [(dateRange)]="trackedSearchItems[searchItemKeys.DateRange]"></shared-date-range-select>
	</div>
	<div *ngIf="searchConfiguration?.showAccounts === true && canManageAccounts === true" class="mb-3">
		<label for="accounts" class="form-label"><fa-icon icon="building"></fa-icon> Accounts</label>
		<shared-form-multi-select
			labelForId="accounts"
			[items]="availableSearchItems[searchItemKeys.Accounts]"
			[(selected)]="trackedSearchItems[searchItemKeys.Accounts]"
			(selectedChange)="validateTrackedAccountData($event)"
			(applyFilter)="applySearchFilter()"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showAccountTypes === true && canManageAccounts === true" class="mb-3">
		<shared-form-checkbox-select
			icon="cog"
			label="Account Types"
			[items]="availableSearchItems[searchItemKeys.AccountTypes]"
			[(selected)]="trackedSearchItems[searchItemKeys.AccountTypes]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showAccountStatuses === true && canManageAccounts === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Account Status"
			[items]="availableSearchItems[searchItemKeys.AccountStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.AccountStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showAccountSubscriptionTypes === true && canManageAccounts === true" class="mb-3">
		<shared-form-checkbox-select
			icon="usd-circle"
			label="Account Subscription Types"
			[items]="availableSearchItems[searchItemKeys.AccountSubscriptionTypes]"
			[(selected)]="trackedSearchItems[searchItemKeys.AccountSubscriptionTypes]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showAccountSubscriptionStatuses === true && canManageAccounts === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Account Subscription Statuses"
			[items]="availableSearchItems[searchItemKeys.AccountSubscriptionStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.AccountSubscriptionStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showUsers === true" class="mb-3">
		<label for="users" class="mb-1"><fa-icon icon="users"></fa-icon> Users</label>
		<shared-form-multi-select
			labelForId="users"
			[items]="availableSearchItems[searchItemKeys.Users]"
			[(selected)]="trackedSearchItems[searchItemKeys.UserIds]"
			(applyFilter)="applySearchFilter()"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showUserRoles === true" class="mb-3">
		<shared-form-checkbox-select
			icon="user-cog"
			label="User Roles"
			[items]="availableSearchItems[searchItemKeys.UserRoles]"
			[(selected)]="trackedSearchItems[searchItemKeys.UserRoles]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showUserStatuses === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="User Status"
			[items]="availableSearchItems[searchItemKeys.UserStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.UserStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showLocations === true" class="mb-3">
		<label for="locations" class="mb-1"><fa-icon icon="map-marker-alt"></fa-icon> Locations</label>
		<shared-form-multi-select
			labelForId="locations"
			[items]="availableSearchItems[searchItemKeys.Locations]"
			[(selected)]="trackedSearchItems[searchItemKeys.Locations]"
			(selectedChange)="validateTrackedAccount($event)"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showTags === true" class="mb-3">
		<label for="tags" class="mb-1"><fa-icon icon="tags"></fa-icon> Tags</label>
		<shared-form-multi-select
			labelForId="tags"
			[items]="availableSearchItems[searchItemKeys.Tags]"
			[(selected)]="trackedSearchItems[searchItemKeys.Tags]"
			(selectedChanged)="validateTrackedAccount($event)"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showUnits === true" class="mb-3">
		<label for="units" class="mb-1"><fa-icon icon="server"></fa-icon> Agents</label>
		<shared-form-multi-select
			labelForId="units"
			[items]="availableSearchItems[searchItemKeys.Units]"
			[(selected)]="trackedSearchItems[searchItemKeys.Units]"
			(selectedChanged)="validateTrackedAccount($event)"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showUnitStatuses === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Device Status"
			[items]="availableSearchItems[searchItemKeys.UnitStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.UnitStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showAlertTypes === true" class="mb-3">
		<label for="alertTypes" class="mb-1"><fa-icon icon="bell"></fa-icon> Alert Types</label>
		<shared-form-multi-select
			labelForId="alertTypes"
			[items]="availableSearchItems[searchItemKeys.AlertTypes]"
			[(selected)]="trackedSearchItems[searchItemKeys.AlertTypes]"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showAlertStatuses === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Alert Status"
			[items]="availableSearchItems[searchItemKeys.AlertStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.AlertStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showVmsServices === true" class="mb-3">
		<label for="vmsServices" class="mb-1"><fa-icon icon="server"></fa-icon> VMS Services</label>
		<shared-form-multi-select
			labelForId="vmsServices"
			[items]="availableSearchItems[searchItemKeys.VmsServices]"
			[(selected)]="trackedSearchItems[searchItemKeys.VmsServices]"
			(selectedChanged)="validateTrackedAccount($event)"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showRecordingServers === true" class="mb-3">
		<label for="recordingServers" class="mb-1"><fa-icon icon="video"></fa-icon> Recording Servers</label>
		<shared-form-multi-select
			labelForId="recordingServers"
			[items]="availableSearchItems[searchItemKeys.RecordingServers]"
			[(selected)]="trackedSearchItems[searchItemKeys.recordingServers]"
			(selectedChanged)="validateTrackedAccount($event)"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showCameras === true" class="mb-3">
		<label for="cameras" class="mb-1"><fa-icon icon="cctv"></fa-icon> Cameras</label>
		<shared-form-multi-select
			labelForId="cameras"
			[items]="availableSearchItems[searchItemKeys.Cameras]"
			[(selected)]="trackedSearchItems[searchItemKeys.Cameras]"
			(selectedChanged)="validateTrackedAccount($event)"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showManageTaskTypes === true" class="mb-3">
		<label for="manageTaskTypes" class="mb-1"><fa-icon icon="clipboard-list"></fa-icon> Task Types</label>
		<shared-form-multi-select
			labelForId="manageTaskTypes"
			[items]="availableSearchItems[searchItemKeys.ManageTaskTypes]"
			[(selected)]="trackedSearchItems[searchItemKeys.manageTaskTypes]"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showManageTaskStatuses === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Task Status"
			[items]="availableSearchItems[searchItemKeys.ManageTaskStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.ManageTaskStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showUpdateTypes === true" class="mb-3">
		<label for="updateTypes" class="mb-1"><fa-icon icon="bell"></fa-icon> Update Types</label>
		<shared-form-multi-select
			labelForId="updateTypes"
			[items]="availableSearchItems[searchItemKeys.UpdateTypes]"
			[(selected)]="trackedSearchItems[searchItemKeys.UpdateTypes]"
			clearable="true"></shared-form-multi-select>
	</div>
	<div *ngIf="searchConfiguration?.showManagedDeviceTypes === true" class="mb-3">
		<shared-form-checkbox-select
			icon="server"
			label="Device Type"
			[items]="availableSearchItems[searchItemKeys.ManagedDeviceTypes]"
			[(selected)]="trackedSearchItems[searchItemKeys.ManagedDeviceTypes]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showManagedDeviceStatuses === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Device Status"
			[items]="availableSearchItems[searchItemKeys.ManagedDeviceStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.ManagedDeviceStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
	<div *ngIf="searchConfiguration?.showNetworkLinkStatuses === true" class="mb-3">
		<shared-form-checkbox-select
			icon="badge-check"
			label="Link Status"
			[items]="availableSearchItems[searchItemKeys.NetworkLinkStatuses]"
			[(selected)]="trackedSearchItems[searchItemKeys.NetworkLinkStatuses]"
			(applyFilter)="applySearchFilter()"></shared-form-checkbox-select>
	</div>
</ng-template>
