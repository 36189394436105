import { Component, Input } from '@angular/core';
import { PageSidePanelConfig, PageSidePanelService, PageSidePanelType } from '@razberi-ui/shared';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-root-monitor-cloud-page-side-panel',
	templateUrl: './page-side-panel.component.html',
	styleUrls: ['./page-side-panel.component.scss'],
})
export class PageSidePanelComponent {
	@Input() onEdit$: Subject<void> = new Subject<void>();

	config: PageSidePanelConfig;
	pageSidePanelTypeOptions: any = PageSidePanelType;

	constructor(private readonly sidePanelService: PageSidePanelService) {}

	ngOnInit() {
		this.sidePanelService.streams.sidePanelConfig$.subscribe((config) => {
			this.config = config;
		});
	}

	onEdit() {
		this.onEdit$.next();
	}
}
