import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { Account, AccountStatus, AccountType, EnumService, User } from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-form',
	templateUrl: './account-form.component.html',
	styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent {
	@Input() isCurrentAccount: boolean;
	@Input() isCurrentPrimaryUser: boolean;
	@Input() editorAccountType: AccountType;
	@Input() account: Account;
	@Input() admins: User[];
	@Input() providers: Account[];
	@Input() reset$?: Observable<void>;
	@Input() isPublicMode: boolean;
	@Input() defaultAccountType: AccountType;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<Account> = new EventEmitter<Account>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	accountForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();
	canEditProviders: boolean = false;
	isCustomer: boolean = false;
	isProvider: boolean = false;
	accountTypes: any[];
	accountStatuses: any[];

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly utils: UtilitiesService,
		private readonly enumService: EnumService,
		private readonly settingsService: CoreServerSettingsService
	) {
		this.accountTypes = this.enumService.helpers.getAccountTypes();
		this.accountStatuses = this.enumService.helpers.getAccountStatuses();
	}

	ngOnInit() {
		this.tryBuildForm();
		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.accountForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.accountForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.accountForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		this.subscriptions.add(
			this.accountForm
				.get('type')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => {
					this.isCustomer = value === AccountType.Customer;
					this.isProvider = value === AccountType.Provider;
					if (this.isCustomer && this.canEditProviders) this.accountForm.get('providerAccountId').enable();
					else this.accountForm.get('providerAccountId').disable();
					if (this.isProvider && this.canEditProviders) this.accountForm.get('salesforceId').enable();
					else this.accountForm.get('salesforceId').disable();
				})
		);
		this.accountForm.updateValueAndValidity();
	}

	tryBuildForm() {
		if (this.accountForm != null) {
			return;
		}
		this.accountForm = this.formBuilder.group({
			name: [null, [Validators.required]],
			type: [null, [Validators.required]],
			status: [null, [Validators.required]],
			primaryUserId: [null, [Validators.required]],
			phone: [null, this.isPublicMode !== true ? [Validators.required] : undefined],
			address: [null, [Validators.required]],
			address2: [null],
			city: [null, [Validators.required]],
			stateProvince: [null, [Validators.required]],
			zipPostalCode: [null, [Validators.required]],
			countryRegion: [null, [Validators.required]],
			providerAccountId: [null],
			salesforceId: [null],
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.tryBuildForm();
		let isCurrentAccount: boolean = changes.isCurrentAccount?.currentValue ?? this.isCurrentAccount;
		let isCurrentPrimaryUser: boolean = changes.isCurrentPrimaryUser?.currentValue ?? this.isCurrentPrimaryUser;
		let editorAccountType: AccountType = changes.editorAccountType?.currentValue ?? this.editorAccountType;
		this.canEditProviders = editorAccountType === AccountType.Global && this.isPublicMode !== true;
		let account: Account = changes.account?.currentValue ?? this.account;
		if (account) {
			this.isCustomer = account.type === AccountType.Customer;
			this.isProvider = account.type === AccountType.Provider;
			this.accountTypes = this.enumService.helpers.getAccountTypes(account.type === AccountType.Pool, account.type === AccountType.Global);
			this.accountStatuses = this.enumService.helpers.getAccountStatuses(
				account.status === AccountStatus.Pending,
				account.status === AccountStatus.Offline,
				account.status === AccountStatus.Deleted
			);
			if (isCurrentAccount) {
				this.accountForm.get('type').disable();
				this.accountForm.get('status').disable();
				if (isCurrentPrimaryUser) this.accountForm.get('primaryUserId').enable();
				else this.accountForm.get('primaryUserId').disable();
				this.accountForm.get('providerAccountId').disable();
				this.accountForm.get('salesforceId').disable();
			} else {
				this.accountForm.get('status').enable();
				this.accountForm.get('primaryUserId').enable();
				if (this.canEditProviders) {
					this.accountForm.get('type').enable();
					this.accountForm.get('phone').enable();
					if (this.isProvider) this.accountForm.get('salesforceId').enable();
					else if (this.isCustomer) this.accountForm.get('salesforceId').disable();
				} else if (this.isPublicMode === true) {
					this.accountForm.get('type').enable();
					this.accountForm.get('phone').disable();
					this.accountForm.get('providerAccountId').disable();
					this.accountForm.get('salesforceId').disable();
					this.accountForm.get('status').setValue(AccountStatus.Pending);
					this.accountForm.get('status').disable();
					this.accountForm.get('primaryUserId').disable();
				} else {
					this.accountForm.get('type').disable();
					this.accountForm.get('phone').enable();
					this.accountForm.get('providerAccountId').disable();
					this.accountForm.get('salesforceId').disable();
				}
			}
		} else {
			this.isProvider = false;
			this.accountForm.get('type').setValue(this.defaultAccountType != null ? this.defaultAccountType : AccountType.Customer);
			this.accountForm.get('status').setValue(AccountStatus.Pending);
			this.accountForm.get('status').disable();
			this.accountForm.get('countryRegion').setValue('USA');
			this.accountForm.get('primaryUserId').disable();
			this.accountForm.get('salesforceId').disable();
			this.accountTypes = this.enumService.helpers.getAccountTypes();
			this.accountStatuses = this.enumService.helpers.getAccountStatuses(true);
			if (this.canEditProviders) {
				this.accountForm.get('type').enable();
				this.accountForm.get('phone').enable();
				this.accountForm.get('providerAccountId').enable();
			} else if (this.isPublicMode === true) {
				this.accountForm.get('type').enable();
				this.accountForm.get('phone').disable();
				this.accountForm.get('providerAccountId').disable();
			} else {
				this.accountForm.get('type').disable();
				this.accountForm.get('phone').enable();
				this.accountForm.get('providerAccountId').disable();
			}
		}
		if (changes.account && changes.account.currentValue) this.accountForm.patchValue(changes.account.currentValue);
	}

	submitForm() {
		if (this.accountForm.valid) this.submit.emit();
	}

	onCountryRegionChange($event) {
		this.accountForm.get('stateProvince').setValue(null);
	}
}
