import { Component, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'shared-form-custom-search-select',
	templateUrl: './form-custom-search-select.component.html',
	styleUrls: ['./form-custom-search-select.component.scss'],
})
export class FormCustomSearchSelectComponent {
	@Input() id: string;
	@Input() selectModel: any[] = [];
	@Input() items: any[] = [];
	@Input() multiple: boolean = false;
	@Input() usingFilter: boolean = false;
	@Output() selectModelChange: BehaviorSubject<any[]> = new BehaviorSubject([]);
	@Output() filterOpenChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

	filterOpen: boolean = false;

	constructor() {}

	ngOnInit() {
		if (!this.id) this.id = `select_${this.getId()}`;
	}

	selectModelChanged(values) {
		this.selectModelChange.next(values);
	}

	clearFilter() {
		this.selectModel = [];
		this.selectModelChange.next([]);
	}

	toggleFilterOpen() {
		this.filterOpen = !this.filterOpen;
		this.filterOpenChange.next(this.filterOpen);
	}

	getId(): string {
		let result: string = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (var i = 0; i < 8; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));
		return result;
	}
}
