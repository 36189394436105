import { Component, Input } from '@angular/core';
import { ManagedDeviceType, Unit } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageSidePanelConfig, PageSidePanelService, UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, forkJoin, take } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { UnitService } from '../../../services/unit.service';
import { ManagedDeviceService } from '../../../services/managed-device.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-unit-side-panel',
	templateUrl: './unit-side-panel.component.html',
	styleUrls: ['./unit-side-panel.component.scss'],
})
export class UnitSidePanelComponent {
	@Input() onEdit$: Observable<void> = new Observable<void>();

	subscriptions: Subscription = new Subscription();
	config: PageSidePanelConfig;
	activeTabKey: string;
	unit: Unit;
	unitData: any;

	constructor(
		private readonly sidePanelService: PageSidePanelService,
		private readonly alertService: AlertService,
		private readonly unitService: UnitService,
		private readonly alertMessageService: AlertMessageService,
		private readonly managedDeviceService: ManagedDeviceService,
		private readonly subscriptionService: SubscriptionService,
		private readonly cloudApiSettingsService: CoreServerSettingsService
	) {}

	ngOnInit() {
		this.sidePanelService.streams.sidePanelConfig$.subscribe((config) => {
			if (config == null || config.unitId == null) {
				this.unit = null;
				this.activeTabKey = null;
				return;
			}

			if (config.unitId != null && config.unitId !== this.config?.unitId) {
				this.loadUnit(config);
			}

			this.config = config;
			this.activeTabKey = config?.activeTabKey ?? 'details';
		});

		this.onEdit$.subscribe((_) => {
			this.config.editAction(this.unit);
		});
	}

	loadUnit(config: PageSidePanelConfig) {
		forkJoin({
			notifications: this.alertService.api.getDeviceAlerts(config.accountId, config.unitId, 30),
			unit: this.unitService.api.getUnit(config.accountId, config.unitId.toString()),
			updates: this.unitService.api.getUpdatesForUnit(config.accountId, config.unitId),
		})
			.pipe(take(1))
			.subscribe({
				next: (result) => {
					let { notifications, unit, updates } = result;
					this.unit = unit;
					let selectedNode = {
						isParent: true,
						type: unit.type,
						key: unit.unitKey,
						detail: unit,
						status: unit.status,
					};
					let transformedNode = this.unitService.transform.transformNode(selectedNode);
					unit.managedDevices?.forEach((device) => {
						this.managedDeviceService.helpers.mapCustomProperties(device);
					});
					let childData = this.unitService.transform.buildChildData(unit);
					if (!this.subscriptionService.helpers.isGlobalOrIsStatusValid(unit.accountSubscriptionStatus))
						childData = childData.filter((child) => child.type === 'EndpointDefender');
					let displayUnit = { ...transformedNode, accountId: unit.accountId, childData: [] };
					let isDeviceThirdParty = displayUnit.Properties?.find((x) => x.Name === 'Type').FormattedValue === 'Other' ? true : false;
					let fieldsToHideThirdPartyOrPremise = [
						'License Date',
						'License Expiration',
						'Manufacture Date',
						'Warranty Expiration',
						'ApplianceDefense Expiration',
					];
					displayUnit.Properties?.forEach((prop) => {
						let fixedPropName = prop.Name.replace(/\u00A0/, ' ');
						let fieldMatches = fieldsToHideThirdPartyOrPremise.indexOf(fixedPropName) > -1;
						prop.DisplayField = true;
						if (fieldMatches && (isDeviceThirdParty || this.cloudApiSettingsService.data.settings?.global?.isPremise === true)) {
							prop.DisplayField = false;
						}
					});
					childData?.forEach((child) => {
						let childObj = this.unitService.transform.findChildObject(unit, child.type, child.key);
						let childNode = { isParent: false, type: child.type, key: child.key, detail: childObj, status: child.status, collapsed: true };
						displayUnit.childData.push(this.unitService.transform.transformNode(childNode));
					});

					this.unitData = {
						title: unit.name,
						activeTabKey: this.config.activeTabKey,
						// params: displayUnit,
						tabs: [
							{ title: 'Details', id: 'details', type: 'unit', data: displayUnit },
							{ title: 'Alerts', id: 'alerts', type: 'alerts', data: notifications, accountId: unit.accountId },
							{ title: 'Updates', id: 'updates', type: 'updates', data: updates, accountId: unit.accountId },
							{
								title: 'Network Devices',
								id: 'managed',
								type: 'managed',
								data: unit?.managedDevices?.filter((d) => d.type !== ManagedDeviceType.Agent),
								accountId: unit.accountId,
							},
						],
						editAction: this.config.editAction,
					};
				},
				error: (error) => {
					this.alertMessageService.error('Error getting device details.', error);
				},
			});
	}

	updateAlerts(event) {
		if (event) {
			this.alertService.api.getDeviceAlerts(this.config.accountId, this.config.unitId, 30).subscribe({
				next: (alerts) => {
					this.unitData.tabs[1].data = alerts;
				},
				error: (error) => {
					this.alertMessageService.error('Error getting device alerts.', error);
				},
			});
		}
	}
}
