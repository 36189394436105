<div class="row justify-content-center my-3 px-5">
	<div class="row justify-content-center">
		<div class="col-6">
			<shared-image-uploader [config]="logoConfig"></shared-image-uploader>
		</div>
		<div class="col-6">
			<shared-image-uploader [config]="faviconConfig"></shared-image-uploader>
		</div>
	</div>
	<div class="row justify-content-center mt-5">
		<div class="col-12">
			<form [formGroup]="customEmailFooterForm" autocomplete="off" novalidate>
				<div>
					<div class="fs-4 mb-4">Custom Email Footer</div>
					<textarea
						rows="10"
						class="w-75"
						id="customEmailFooter"
						formControlName="customEmailFooter"
						[maxLength]="customEmailFooterMaxLength"></textarea>
					<div class="text-muted font-italic">
						{{ customEmailFooterForm?.controls?.customEmailFooter?.value?.length || 0 }}/{{ customEmailFooterMaxLength }} Characters
					</div>
					<shared-input-validation-messages [control]="customEmailFooterForm.controls.customEmailFooter"></shared-input-validation-messages>
				</div>
			</form>
		</div>
		<div class="col-12 mt-3">
			<button
				class="btn btn-primary"
				(click)="submitForm()"
				[disabled]="customEmailFooterForm?.valid !== true || customEmailFooterForm?.controls?.customEmailFooter?.value === originalCustomEmailFooter">
				<fa-icon icon="check" class="me-2"></fa-icon>Save
			</button>
			<button
				class="btn btn-light ms-3"
				(click)="customEmailFooterForm?.controls?.customEmailFooter?.setValue(originalCustomEmailFooter)"
				[disabled]="customEmailFooterForm?.controls?.customEmailFooter?.value === originalCustomEmailFooter">
				<fa-icon icon="undo" class="me-2"></fa-icon>Reset
			</button>
			<button class="btn btn-secondary ms-3" (click)="removeCustomEmailFooter()" [disabled]="(originalCustomEmailFooter?.length || 0) == 0">
				<fa-icon icon="times" class="me-2"></fa-icon>Remove
			</button>
		</div>
	</div>
</div>
