<h4 class="card-title text-center">Reset your password</h4>
<div *ngIf="status === 0">
	<p class="card-text mt-3 mb-3">Loading...</p>
</div>
<div *ngIf="status === 1">
	<p class="card-text mt-3">A link to reset your password will be sent to your email.</p>
	<app-root-monitor-cloud-auth-email-form
		(isValid)="onEmailIsValid($event)"
		(changes)="onEmailChanges($event)"
		(submit)="email()"></app-root-monitor-cloud-auth-email-form>
	<div class="d-flex flex-row-reverse justify-content-between align-items-end">
		<button id="btn-email-link" type="button" class="btn btn-primary" (click)="email()" [disabled]="!isEmailValid">
			<fa-icon icon="unlock" class="me-1"></fa-icon>Email Reset Link
		</button>
	</div>
</div>
<div *ngIf="status === 2">
	<p class="card-text text-success text-center mt-5 mb-5">A link to reset your password has been sent to your email.</p>
</div>
<div *ngIf="status === 3">
	<p class="card-text mt-3">Set a new password for your login.</p>
	<app-root-monitor-cloud-auth-password-form
		[isCurrentUser]="true"
		[requireExisting]="false"
		(isValid)="onPasswordIsValid($event)"
		(changes)="onPasswordChanges($event)"
		(submit)="set()"></app-root-monitor-cloud-auth-password-form>
	<div class="d-flex flex-row-reverse justify-content-between align-items-end">
		<button id="btn-set-password" type="button" class="btn btn-primary" (click)="set()" [disabled]="!isPasswordValid">
			<fa-icon icon="user-lock" class="me-1"></fa-icon>Set Password
		</button>
	</div>
</div>
<div *ngIf="status === 4">
	<p class="card-text text-success text-center mt-5 mb-3">Your password has been set.</p>
	<div class="d-flex flex-row-reverse justify-content-center align-items-end">
		<button id="btn-login" type="button" class="btn btn-link" (click)="login()"><fa-icon icon="sign-in" class="me-1"></fa-icon>Login</button>
	</div>
</div>
<div *ngIf="status === 5">
	<p *ngIf="message" class="card-text text-danger text-center mt-5 mb-3">{{ message }}</p>
	<div class="d-flex flex-row-reverse justify-content-between align-items-end">
		<button id="btn-login" type="button" class="btn btn-link" (click)="login()"><fa-icon icon="sign-in" class="me-1"></fa-icon>Login</button>
		<button id="btn-reset-password" type="button" class="btn btn-link" (click)="reset()">
			<fa-icon icon="unlock" class="me-1"></fa-icon>Reset Password
		</button>
	</div>
</div>
