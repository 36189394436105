import { NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { SharedModule } from '@razberi-ui/shared';
import { ThemeRazberiActivationToolbarComponent } from './themes/razberi-activation/theme-razberi-activation-toolbar/theme-razberi-activation-toolbar.component';
import { ThemeRazberiActivationComponent } from './themes/razberi-activation/theme-razberi-activation.component';
import { ThemeRazberiCoreSidenavComponent } from './themes/razberi-core/theme-razberi-core-sidenav/theme-razberi-core-sidenav.component';
import { ThemeRazberiCoreToolbarComponent } from './themes/razberi-core/theme-razberi-core-toolbar/theme-razberi-core-toolbar.component';
import { ThemeRazberiCoreComponent } from './themes/razberi-core/theme-razberi-core.component';
import { ThemeRazberiCoreCardToolbarComponent } from './themes/razberi-core/theme-razberi-core-card-toolbar/theme-razberi-core-card-toolbar.component';
import { ThemeRazberiCoreCardFooterComponent } from './themes/razberi-core/theme-razberi-core-card-footer/theme-razberi-core-card-footer.component';
import { ThemeRazberiCoreFixedToolbarComponent } from './themes/razberi-core/theme-razberi-core-fixed-toolbar/theme-razberi-core-fixed-toolbar.component';
import { ThemeRazberiCoreFixedFooterComponent } from './themes/razberi-core/theme-razberi-core-fixed-footer/theme-razberi-core-fixed-footer.component';

@NgModule({
	imports: [SharedModule],
	declarations: [
		ThemeRazberiActivationComponent,
		ThemeRazberiActivationToolbarComponent,
		ThemeRazberiCoreComponent,
		ThemeRazberiCoreSidenavComponent,
		ThemeRazberiCoreToolbarComponent,
		ThemeRazberiCoreCardToolbarComponent,
		ThemeRazberiCoreCardFooterComponent,
		ThemeRazberiCoreFixedToolbarComponent,
		ThemeRazberiCoreFixedFooterComponent,
	],
	exports: [ThemeRazberiCoreComponent],
})
export class CoreThemesModule {
	constructor(private faConfig: FaConfig, private faLibrary: FaIconLibrary) {
		this.faConfig.defaultPrefix = 'fal';
		this.faConfig.fixedWidth = true;
		this.faLibrary.addIconPacks(fal);
		this.faLibrary.addIconPacks(fas);
	}
}
