import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { FileStoreSettingsProvider } from '@razberi-ui/core/data-types';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-settings-filestore-form',
	templateUrl: './settings-filestore-form.component.html',
	styleUrls: ['./settings-filestore-form.component.scss'],
})
export class SettingsFilestoreFormComponent implements OnInit {
	subscription: Subscription = new Subscription();
	providers: any = FileStoreSettingsProvider;
	selectedProvider: FileStoreSettingsProvider;

	constructor(readonly settingsService: CoreServerSettingsService) {}

	ngOnInit(): void {
		this.subscription.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				this.selectedProvider =
					settings != null
						? this.settingsService.helpers.parseFileStoreSettingsProvider(this.settingsService.data.settings?.fileStore?.provider)
						: FileStoreSettingsProvider.LocalFileStoreProvider;
			})
		);
	}
}
