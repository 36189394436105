import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageTaskInstance } from '@razberi-ui/core/data-types';
import { AlertMessageService, TableSettings, UtilitiesService } from '@razberi-ui/shared';
import { ManageService } from '../../../services/manage.service';
import { ReportService } from '../../../services/report.service';

@Component({
	selector: 'app-root-monitor-cloud-manage-task-instance-output-modal',
	templateUrl: './manage-task-instance-output-modal.component.html',
	styleUrls: ['./manage-task-instance-output-modal.component.scss'],
})
export class ManageTaskInstanceOutputModalComponent {
	@Input() accountId?: number;
	@Input() manageTaskInstance?: ManageTaskInstance;

	instance: ManageTaskInstance;
	fileTableSettings: TableSettings;

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly utils: UtilitiesService,
		private readonly manageService: ManageService,
		private readonly reportService: ReportService
	) {}

	ngOnInit() {
		const ts: TableSettings = {
			useSearch: false,
			useAdvancedSearch: false,
			useEdit: false,
			usePageSize: false,
			hidePagination: true,
			hideTotal: true,
			hideFilter: true,
			columnConfig: [
				{ primaryKey: 'fileName', header: 'File Name' },
				{
					primaryKey: 'contentLength',
					header: 'File Name',
					transform: (value) => {
						return this.utils.formatters.asMemoryCapacity(value);
					},
				},
				{
					primaryKey: null,
					header: '',
					cellType: 'link-button',
					transform: (value, tableItem) => {
						return { text: 'Download File', icon: null };
					},
					clickHandlerFunction: (value) => this.downloadTaskFile(value),
				},
			],
		};

		this.fileTableSettings = ts;
	}

	ngOnDestroy() {}

	onChanges(value: ManageTaskInstance) {
		this.instance = value;
	}

	downloadTaskFile(taskFile: any) {
		this.manageService.api.getManageTaskFile(this.accountId, taskFile.manageTaskFileId).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting script file.', error);
			},
		});
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
