<form>
	<div *ngIf="!tableSettings.hideFilter" class="d-flex justify-content-between p-2 data-table__filter">
		<div
			*ngIf="tableSettings.itemSelectMode === itemSelectModeOptions.Multi && tableItems?.length > 0"
			class="cursor-default pt-1 pb-1 me-2 rounded"
			style="border: 1px solid #999"
			[ngClass]="{ 'bg-primary text-white': selectedCount > 0, 'bg-light': selectedCount == 0 }"
			[title]="selectedCountTitle"
			ngbDropdown>
			<div class="ps-1 pe-1" ngbDropdownToggle>
				<fa-icon [icon]="selectedCount > 0 ? 'square-check' : 'square'" class="font-bold" size="lg"></fa-icon>
				{{ selectedCount }}
			</div>
			<div class="dropdown-menu" ngbDropdownMenu>
				<div class="dropdown-item" [ngClass]="{ 'bg-primary text-white': selectedCount > 0 }">{{ selectedCount }} Selected</div>
				<hr class="m-0" />
				<button ngbDropdownItem (click)="onMultiSelectTypeChange(multiSelectTypeOptions.None)" [disabled]="selectedCount == 0">None</button>
				<button
					ngbDropdownItem
					(click)="onMultiSelectTypeChange(multiSelectTypeOptions.Page)"
					[disabled]="selectedMultiSelectType === multiSelectTypeOptions.Page && pageSelected === true">
					Page
				</button>
				<button
					ngbDropdownItem
					*ngIf="tableSettings.allowSelectAll === true"
					(click)="onMultiSelectTypeChange(multiSelectTypeOptions.All)"
					[disabled]="selectedMultiSelectType === multiSelectTypeOptions.All && allSelected === true">
					All {{ total$ }} Results
				</button>
			</div>
		</div>
		<div *ngIf="!tableSettings.hideTotal" class="text flex-grow-1">showing {{ showing }} of {{ total$ }}</div>
		<div class="ml-auto data-table__buttons d-flex me-2">
			<div *ngIf="tableSettings.headerButtons && bulkSelection">
				<button
					*ngFor="let headerButton of tableSettings.headerButtons"
					type="button"
					class="btn btn-link px-1 py-0 mx-2"
					(click)="headerButton.clickHandlerFunction(tableItemsSelected$, tableItemsExcluded$, selectedMultiSelectType, selectedCount)">
					<div *ngIf="headerButton.icon" class="svg-icon d-inline" [inlineSVG]="'/assets/images/svg/' + headerButton.icon"></div>
					<fa-icon
						*ngIf="headerButton.faIcon"
						[icon]="headerButton.faIcon"
						[ngClass]="headerButton.faIconColor ? 'text-' + headerButton.faIconColor : ''"
						class="me-1"></fa-icon
					>{{ headerButton.label }}
				</button>
			</div>
			<div *ngIf="tableSettings.stickyHeaderButtons">
				<button
					*ngFor="let headerButton of tableSettings.stickyHeaderButtons"
					type="button"
					class="btn btn-link px-1 py-0 mx-2"
					(click)="headerButton.clickHandlerFunction(tableItemsSelected$, searchTerm, tableItemsExcluded$, selectedMultiSelectType, selectedCount)">
					<div *ngIf="headerButton.icon" class="svg-icon d-inline" [inlineSVG]="'/assets/images/svg/' + headerButton.icon"></div>
					<fa-icon
						*ngIf="headerButton.faIcon"
						[icon]="headerButton.faIcon"
						[ngClass]="headerButton.faIconColor ? 'text-' + headerButton.faIconColor : ''"
						class="me-1"></fa-icon
					>{{ headerButton.label }}
				</button>
			</div>
		</div>
		<div class="d-flex align-items-center">
			<label *ngIf="tableSettings.useDays && !allSelected" class="data-table__days">
				last
				<select class="form-select form-select-sm" style="width: auto" name="daysHistory" [(ngModel)]="days" (change)="daysChanged.emit(this.days)">
					<option [ngValue]="'30'">30</option>
					<option [ngValue]="'60'">60</option>
					<option [ngValue]="'90'">90</option>
					<option [ngValue]="'-1'">all</option>
				</select>
				days
			</label>
		</div>
		<div *ngIf="tableSettings.useSearch && !search">
			<fa-icon icon="search" (click)="displaySearch()" class="search-icon"></fa-icon>
		</div>
		<div *ngIf="tableSettings.useSearch && !allSelected && search" class="form-inline data-table__search">
			<div class="input-group input-group-sm">
				<div class="input-group-prepend">
					<div class="input-group-text"><fa-icon icon="search"></fa-icon></div>
				</div>
				<input class="form-control" type="text" name="searchTerm" [(ngModel)]="searchTerm" placeholder="Search..." (keyup.esc)="displaySearch()" />
			</div>
			<fa-icon icon="times" (click)="displaySearch()" class="search-icon"></fa-icon>
		</div>
		<div *ngIf="tableSettings.useAdvancedSearch">
			<ng-content select="[dataTableSearch]"></ng-content>
		</div>
	</div>
	<div class="table-responsive data-table">
		<shared-context-message type="secondary" *ngIf="tableSettings == null" class="p-2 pt-1"> Table not configured. </shared-context-message>
		<shared-context-message
			type="secondary"
			*ngIf="tableSettings && ((tableItems$ | async)?.length || 0) == 0 && tableSettings.hideEmptyResultMessage !== true"
			class="px-2 pb-1">
			{{ tableSettings?.emptyResultMessage || 'No results available.' }}
		</shared-context-message>
		<div #scrollTop></div>
		<table class="table" *ngIf="tableSettings && ((tableItems$ | async)?.length > 0 || tableSettings.hideEmptyResultMessage === true)">
			<thead class="data-table__header">
				<tr class="header-row">
					<th
						*ngIf="
							(tableSettings.itemSelectMode != null && tableSettings.itemSelectMode !== itemSelectModeOptions.None) ||
							tableSettings.checkboxAction != null
						"
						style="width: 10px">
						&nbsp;
					</th>
					<th
						scope="col"
						*ngFor="let column of tableSettings.columnConfig"
						[sortable]="column.disableSort !== true ? column.serverSortBy ?? column.primaryKey : null"
						[ngClass]="{ 'pe-none': column.disableSort, 'cursor-pointer': !column.disableSort }"
						[ngStyle]="getStyle(column)"
						(sort)="onSort($event)">
						<span [ngClass]="{ 'cursor-pointer': column.disableSort !== true, 'cursor-default': column.disableSort === true }">{{
							column.header
						}}</span>
					</th>
					<th *ngIf="tableSettings.tableRowButtons">&nbsp;</th>
					<th *ngIf="tableSettings.useEdit">
						{{ tableSettings.actionHeaderLabel }}
					</th>
				</tr>
			</thead>
			<tbody class="data-table__body">
				<ng-container *ngFor="let rowIndex = index; let tableItem; of: tableItems$ | async">
					<tr
						class="cell-row data-table__row"
						(click)="getData(tableItem)"
						[ngClass]="tableItem.childData && tableItem.childData.length > 0 && 'child-row'">
						<td
							*ngIf="
								(tableSettings.itemSelectMode != null && tableSettings.itemSelectMode !== itemSelectModeOptions.None) ||
								tableSettings.checkboxAction != null
							">
							<input
								type="checkbox"
								class="form-check"
								id="selectCheckbox_{{ tableId }}_{{ rowIndex }}"
								[checked]="tableItem.selected === true"
								(change)="onCheckItem($event, tableItem)" />
							<label class="form-label" for="selectCheckbox_{{ tableId }}_{{ rowIndex }}"></label>
						</td>
						<td
							[ngStyle]="getStyle(column)"
							*ngFor="let column of tableSettings.columnConfig; let i = index"
							[ngClass]="tableSettings.hasChildren && 'cursor-pointer'"
							class="white-space-normal"
							(click)="toggleChildren(tableItem)">
							<ng-container
								*ngIf="column.subscriptionValid === undefined || column.subscriptionValid === true || column.subscriptionRows === 'children'">
								<div class="d-flex flex-row">
									<div *ngIf="i === 0 && tableSettings.hasChildren" class="d-flex me-1">
										<fa-icon
											[icon]="tableItem.childData && tableItem.childData.length > 0 ? 'chevron-circle-down' : 'chevron-circle-right'"
											size="lg"
											class="text-primary"></fa-icon>
									</div>
									<div
										(click)="handleCellClick(column, tableItem)"
										style="overflow-wrap: break-word"
										class="d-flex cursor-default"
										*ngIf="!column.path && !column.cellType"
										[innerHTML]="transformValue(tableItem[column.primaryKey], column, tableItem)"></div>
									<a
										*ngIf="column.path"
										(click)="handleCellClick(column, tableItem)"
										class="d-flex"
										[routerLink]="createPathFromArray({ pathArray: column.path, value: tableItem })"
										[innerHTML]="tableItem[column.primaryKey]"></a>
									<shared-cell-icon
										*ngIf="column.cellType === 'icon'"
										[data]="transformValue(tableItem[column.primaryKey], column, tableItem)">
									</shared-cell-icon>
									<shared-cell-link-button
										*ngIf="column.cellType === 'link-button'"
										[data]="transformValue(tableItem[column.primaryKey], column, tableItem)"
										(click)="handleCellClick(column, tableItem)">
									</shared-cell-link-button>
									<shared-cell-toggle-switch
										*ngIf="column.cellType === 'toggle-switch'"
										[data]="transformValue(tableItem[column.primaryKey], column, tableItem)"
										(toggle)="handleCellClick(column, tableItem, $event)">
									</shared-cell-toggle-switch>
									<shared-cell-router-link
										*ngIf="column.cellType === 'router-link'"
										[data]="transformValue(tableItem[column.primaryKey], column, tableItem)">
									</shared-cell-router-link>
									<shared-cell-pills *ngIf="column.cellType === 'pills'" [items]="tableItem[column.primaryKey]"> </shared-cell-pills>
									<shared-cell-header-sub
										*ngIf="column.cellType === 'header-sub'"
										[data]="transformValue(tableItem[column.primaryKey], column, tableItem)">
									</shared-cell-header-sub>
									<shared-cell-status
										*ngIf="column.cellType === 'status'"
										[data]="transformValue(tableItem[column.primaryKey], column, tableItem)">
									</shared-cell-status>
									<ng-template
										[ngTemplateOutlet]="dataTableCellTypesRef"
										[ngTemplateOutletContext]="{ value: tableItem[column.primaryKey], column: column, tableItem: tableItem }"></ng-template>
								</div>
							</ng-container>
							<ng-container *ngIf="column.subscriptionValid === false && column.subscriptionRows !== 'children'">
								<div class="d-flex"><i>Subscription Required</i></div>
							</ng-container>
						</td>
						<td *ngIf="tableSettings.tableRowButtons">
							<div class="pull-right">
								<button
									*ngFor="let tableRowButton of tableSettings.tableRowButtons"
									class="btn btn-primary"
									[ngClass]="tableRowButton.class"
									type="button"
									(click)="tableRowButton.clickHandlerFunction(tableItem)">
									{{ tableRowButton.label }}
								</button>
							</div>
						</td>
						<td
							*ngIf="tableSettings.useEdit && itemHasEnabledEditActions(tableItem) === true"
							(click)="preventRowToggle($event)"
							class="data-table__edit">
							<div ngbDropdown (openChange)="isDropOpen($event)" class="d-flex flex-row-reverse">
								<button type="button" class="btn data-table__edit-icon" id="actionsDropdown_{{ tableId }}_{{ rowIndex }}" ngbDropdownToggle>
									<fa-icon icon="ellipsis-v" size="2x"></fa-icon>
								</button>
								<div
									ngbDropdownMenu
									aria-labelledby="actionsDropdown_{{ tableId }}_{{ rowIndex }}"
									class="data-table__menu dropdown-menu dropdown-menu-right">
									<ng-container *ngFor="let action of editAction(tableItem)">
										<button
											type="button"
											ngbDropdownItem
											*ngIf="itemIsEnabled(action, tableItem) === true"
											(click)="action.clickHandlerFunction(tableItem)"
											class="px-1"
											[ngClass]="{ bottom: action.bottom, 'd-flex': true }">
											<fa-icon
												*ngIf="action.faIcon"
												[icon]="action.faIcon"
												class="mx-2"
												[ngClass]="action.faIconColor ? 'text-' + action.faIconColor : 'text-primary'"></fa-icon>
											<div *ngIf="action.icon" class="svg-icon d-inline" [inlineSVG]="'/assets/images/svg/' + action.icon"></div>
											<span>{{ action.transformLabel != null ? action.transformLabel(tableItem) : action.label }}</span>
										</button>
									</ng-container>
								</div>
							</div>
						</td>
					</tr>
					<tr *ngFor="let data of tableItem.childData; let rowIndex2 = index" class="child-row">
						<td *ngIf="tableSettings.itemSelectMode !== itemSelectModeOptions.None">
							<div class="form-check">
								<input
									type="checkbox"
									class="form-control-input"
									id="selectChildCheckbox_{{ tableId }}_{{ rowIndex }}_{{ rowIndex2 }}"
									(change)="getChild($event, data, tableItem)" />
								<label class="form-control-label" for="selectChildCheckbox_{{ tableId }}_{{ rowIndex }}_{{ rowIndex2 }}"></label>
							</div>
						</td>
						<td [ngStyle]="getStyle(column)" *ngFor="let column of tableSettings.columnConfig">
							<ng-container
								*ngIf="column.subscriptionValid === undefined || column.subscriptionValid === true || column.subscriptionRows === 'parent'">
								<div class="d-flex flex-row">
									<div
										(click)="handleCellClick(column, data)"
										style="overflow-wrap: break-word"
										*ngIf="!column.path && !column.cellType"
										[innerHTML]="transformValue(data[column.primaryKey], column, data)"></div>
									<a
										*ngIf="column.path"
										(click)="handleCellClick(column, data)"
										[routerLink]="createPathFromArray({ pathArray: column.path, value: data })"
										[innerHTML]="transformValue(data[column.primaryKey], column, data)"></a>
									<div *ngIf="column.cellType === 'icon'" class="d-flex">
										<shared-cell-icon [data]="transformValue(data[column.primaryKey], column, data)"></shared-cell-icon>
									</div>
									<div *ngIf="column.cellType === 'link-button'" class="d-flex">
										<shared-cell-link-button
											[data]="transformValue(data[column.primaryKey], column, data)"
											(click)="handleCellClick(column, data)"></shared-cell-link-button>
									</div>
									<ng-content select="[dataTableCellTypes]" [ngTemplateOutletContext]="{ tableItem: data, column: column }"></ng-content>
								</div>
							</ng-container>
							<ng-container *ngIf="column.subscriptionValid === false && column.subscriptionRows !== 'parent'">
								<div class="d-flex"><i>Subscription Required</i></div>
							</ng-container>
						</td>
						<td *ngIf="tableSettings.useEdit"></td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
	<div class="d-flex justify-content-between p-2">
		<ngb-pagination
			*ngIf="tableSettings.hidePagination !== true"
			[ellipses]="true"
			[maxSize]="3"
			[collectionSize]="total$"
			[(page)]="page"
			[pageSize]="pageSize"
			[boundaryLinks]="true"></ngb-pagination>
		<div *ngIf="tableSettings.usePageSize" class="page-size">
			show
			<select class="form-select form-select-sm" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
				<option [ngValue]="10">10</option>
				<option [ngValue]="20">20</option>
				<option [ngValue]="50">50</option>
			</select>
			per page
		</div>
	</div>
</form>
