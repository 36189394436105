import { Injectable } from '@angular/core';
import { Unit } from '@razberi-ui/core/data-types';
import { MatchType, UtilitiesService } from '@razberi-ui/shared';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SearchFilterService {
	private store: {
		filter: object;
		unfilteredData: any[];
		filteredData: any[];
		filteredData$: BehaviorSubject<any[]>;
	} = {
		filter: {},
		unfilteredData: [],
		filteredData: [],
		filteredData$: new BehaviorSubject(undefined),
	};

	constructor(private readonly utils: UtilitiesService) {}

	get data() {
		const store = this.store;
		const internal = this.internal;

		return {
			get filter(): object {
				return store.filter;
			},
			set filter(value: object) {
				store.filter = value;
				internal.filterData().subscribe((result) => {
					store.filteredData = result;
					store.filteredData$.next(store.filteredData);
				});
			},
			set unfilteredData(value: any[]) {
				store.unfilteredData = value;
				internal.filterData().subscribe((result) => {
					store.filteredData = result;
					store.filteredData$.next(store.filteredData);
				});
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get filteredData$(): Observable<any[]> {
				return store.filteredData$.asObservable();
			},
		};
	}

	private get internal() {
		return {
			buildFilterQuery: (filter: any): any => {
				let query = {};
				for (let keys in filter) {
					if (filter[keys].constructor === Array && filter[keys].length > 0) {
						query[keys] = filter[keys];
					}
					if (filter[keys].constructor === Object) {
						let type = filter[keys].type;
						if (type === 'dateRange' || 'minMax') {
							let min = filter[keys].min;
							let max = filter[keys].max;
							if (min) filter[keys].min = min;
							if (max) filter[keys].max = max;
						}
						query[keys] = filter[keys];
					}
				}
				return query;
			},
			filterItem: (item: any, query: any): boolean => {
				for (let key in query) {
					if (item[key] === undefined) {
						if (query[key].values?.length === 0) continue;
						return false;
					}

					if (query[key].type === 'dateRange') {
						if (moment(item[key]).isBefore(moment(query[key].min), 'day')) return false;
						if (moment(item[key]).isAfter(moment(query[key].max), 'day')) return false;
					} else if (query[key].type === 'minMax') {
						if (query[key]['min'] != null && item[key] < query[key]['min']) return false;
						if (query[key]['max'] != null && item[key] > query[key]['max']) return false;
					} else if (query[key].type === 'compoundKey') {
						if (query[key].values.length === 0) continue;
						if (!query[key].values.some((qv: { [key: string]: any }) => query[key].keys.every((k: string) => qv[k] === item[k]))) return false;
						continue;
					} else if (query[key].type === 'or') {
						if (query[key].values.length === 0) continue;
						if (!query[key].values.some((qv: { [key: string]: any }) => query[key].keys.some((k: string) => qv[k] === item[k]))) return false;
						continue;
					} else if (query[key].type === 'objectArray') {
						let keyToFilterOn = query[key].keyToFilterOn;
						let mappedItemData = item[key].map((itemData) => itemData[keyToFilterOn]);
						let queryData = query[key].values;
						if (queryData.length === 0) continue;
						if (query[key].selectionType === MatchType.All && !queryData.every((r) => mappedItemData.includes(r))) return false;
						if (query[key].selectionType === MatchType.Any && !queryData.some((r) => mappedItemData.includes(r))) return false;
						if (query[key].selectionType === MatchType.None && queryData.some((r) => mappedItemData.includes(r))) return false;
						continue;
					} else if (query[key].type === 'object') {
						let keyToFilterOn = query[key].keyToFilterOn;
						if (query[key].values.length === 0) continue;
						if (!query[key].values.includes(item[key][keyToFilterOn])) return false;
					} else if (query[key].type === 'freeTextSearch') {
						if (!!query[key].value && !item[key].toLowerCase().includes(query[key].value.toLowerCase())) return false;
					} else if (query[key].type === 'filterChildren') {
						continue;
					} else if (query[key].type === 'property') {
						if ((query[key].values?.length || 0) === 0) continue;
						if (!query[key].values.includes(item[key])) return false;
						continue;
					} else if (!query[key].includes(item[key])) {
						return false;
					}
				}

				return true;
			},
			filterData: (): Observable<any[]> => {
				let unfilteredData = this.utils.helpers.deepClone(this.store.unfilteredData);
				let query: any = this.internal.buildFilterQuery(this.store.filter);
				let filteredData = unfilteredData.filter((item) => {
					let isIncluded = this.internal.filterItem(item, query);
					if (isIncluded) {
						for (let key in query) {
							if (query[key].type === 'filterChildren') {
								let childQuery = this.internal.buildFilterQuery(query[key].filter);
								let filteredChild = item[key].filter((child) => this.internal.filterItem(child, childQuery));
								item[key] = filteredChild;
							}
						}
					}
					return isIncluded;
				});
				return of([...filteredData]);
			},
			compare: (v1, v2): number => {
				return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
			},
		};
	}

	get helpers() {
		return {
			sortData: ({ data, keyToSortOn }) => {
				return [...data].sort((a, b) => {
					return this.internal.compare(a[keyToSortOn], b[keyToSortOn]);
				});
			},
			applyFilter: <T>(unfilteredItems: T[], filter: any): T[] => {
				let query: any = this.internal.buildFilterQuery(filter);
				let filteredData = unfilteredItems.filter((item) => {
					let isIncluded = this.internal.filterItem(item, query);
					if (isIncluded) {
						for (let key in query) {
							if (query[key].type === 'filterChildren') {
								let childQuery = this.internal.buildFilterQuery(query[key].filter);
								let filteredChild = item[key].filter((child) => this.internal.filterItem(child, childQuery));
								item[key] = filteredChild;
							}
						}
					}
					return isIncluded;
				});
				return [...filteredData];
			},
			applyManageTaskUnitFilter: (unfilteredItems: Unit[], taskUnits: Unit[]): Unit[] => {
				return taskUnits?.length > 0 ? unfilteredItems.filter((init) => taskUnits.some((u) => u.unitId === init.unitId) === true) : unfilteredItems;
			},
		};
	}
}
