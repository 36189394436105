<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title"><fa-icon icon="map-marker-alt"></fa-icon><span class="ps-2">Override Device Warranty</span></h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<app-root-monitor-cloud-unit-override-warranty-form
			[unit]="unit"
			[unitFields]="unitFields"
			(isValid)="onIsValid($event)"
			(changes)="onChanges($event)"
			(submit)="save()"
			ngbAutoFocus></app-root-monitor-cloud-unit-override-warranty-form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger float-right" (click)="delete()" *ngIf="isProduction !== true">
			<fa-icon icon="trash"></fa-icon> Delete Record
		</button>
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="!isValid"><fa-icon icon="check"></fa-icon> Save</button>
	</div>
</div>
