<form id="user-form" [formGroup]="userForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row">
		<div class="row mb-3">
			<div class="col-6">
				<label for="firstName" class="form-label">First name <span class="text-primary">*</span></label>
				<input type="text" class="form-control" id="firstName" formControlName="firstName" required />
				<shared-input-validation-messages [control]="userForm.controls.firstName"></shared-input-validation-messages>
			</div>
			<div class="col-6">
				<label for="lastName" class="form-label">Last name <span class="text-primary">*</span></label>
				<input type="text" class="form-control" id="lastName" formControlName="lastName" required />
				<shared-input-validation-messages [control]="userForm.controls.lastName"></shared-input-validation-messages>
			</div>
		</div>
		<div class="row mb-3">
			<div class="{{ canEditRoles === true ? 'col-6' : 'col' }}">
				<label for="email" class="form-label">Email address <span class="text-primary">*</span></label>
				<input type="email" class="form-control" id="email" formControlName="email" required />
				<shared-input-validation-messages [control]="userForm.controls.email"></shared-input-validation-messages>
			</div>
			<div *ngIf="canEditRoles === true" class="col-6">
				<label for="formRoles" class="form-label">Roles<span *ngIf="isPrimaryUser" class="text-primary text-xs ms-1"> primary</span></label>
				<ng-select
					labelForId="roles"
					[items]="userRoles"
					[clearSearchOnAdd]="true"
					formControlName="formRoles"
					[closeOnSelect]="false"
					[multiple]="true"
					placeholder="(Default User)"
					clearAllText="Clear"
					[bindLabel]="'name'"
					(change)="onUserRolesChanged($event)">
					<ng-template ng-option-tmp let-item="item" let-index="index">
						<div id="option-{{ item.value }}" [ngClass]="{ 'font-italic text-muted': item.available !== true }">
							{{ item.name }}
						</div>
					</ng-template>
					<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
						<div id="option-selected-{{ item.value }}" class="ng-value" *ngFor="let item of items | slice: 0:3">
							<span class="ng-value-label">{{ item.name }}</span>
							<span id="option-selected-clear-{{ item.value }}" class="ng-value-icon right" aria-hidden="true" (click)="clearUserRole(item)"
								>×</span
							>
						</div>
						<div class="ng-value" *ngIf="items.length > 3"><span class="ng-value-label">...</span></div>
						<div class="float-right advanced-search__total" style="margin-right: 40px; margin-top: 3px; padding: 2px 10px">
							{{ items.length }}
						</div>
					</ng-template>
				</ng-select>
			</div>
		</div>
		<div class="row mb-3">
			<div class="{{ canEditStatus === true ? 'col-6' : 'col' }}">
				<label for="phone" class="form-label">Phone number <span *ngIf="phoneRequired" class="text-primary">*</span></label>
				<input type="text" class="form-control" id="phone" formControlName="phone" />
				<shared-input-validation-messages [control]="userForm.controls.phone"></shared-input-validation-messages>
			</div>
			<div *ngIf="canEditStatus === true" class="col-6">
				<label for="status" class="form-label">Status <span class="text-primary">*</span></label>
				<select class="form-select" id="status" formControlName="status" required>
					<option [ngValue]="status.value" *ngFor="let status of userStatuses">{{ status.name }}</option>
				</select>
				<shared-input-validation-messages [control]="userForm.controls.status"></shared-input-validation-messages>
			</div>
		</div>
	</div>
	<div *ngIf="(user || !isPrimaryUser) && isPublicMode !== true" class="row mt-5">
		<div class="row mb-3">
			<div class="col">
				<label for="department" class="form-label">Department</label>
				<input type="text" class="form-control" id="department" formControlName="department" />
			</div>
			<div class="col">
				<label for="jobTitle" class="form-label">Job title</label>
				<input type="text" class="form-control" id="jobTitle" formControlName="jobTitle" />
			</div>
		</div>
		<div class="row mb-3">
			<div class="col">
				<label for="address" class="form-label">Address</label>
				<input type="text" class="form-control" id="address" formControlName="address" />
			</div>
		</div>
		<div class="row mb-3">
			<div class="col">
				<label for="address2" class="form-label">Address 2</label>
				<input type="text" class="form-control" id="address2" formControlName="address2" />
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-6">
				<label for="city" class="form-label">City</label>
				<input type="text" class="form-control" id="city" formControlName="city" />
			</div>
			<div class="col-4">
				<label for="stateProvince" class="form-label">State</label>
				<input type="text" class="form-control" id="stateProvince" formControlName="stateProvince" />
			</div>
			<div class="col-2">
				<label for="zipPostalCode" class="form-label">Zip code</label>
				<input type="text" class="form-control" id="zipPostalCode" formControlName="zipPostalCode" />
			</div>
		</div>
		<div class="row mb-3">
			<div class="col">
				<label for="countryRegion" class="form-label">Country</label>
				<input type="text" class="form-control" id="countryRegion" formControlName="countryRegion" />
			</div>
		</div>
	</div>
</form>
