<div *ngIf="settingsService.data.settings?.sms?.twilioSmsSettings == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.sms?.twilioSmsSettings != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="accountSid" class="form-label">Account SID</label>
		<div ngbTooltip="The account SID to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="accountSid" id="accountSid" />
		<shared-input-validation-messages [control]="settingsForm.controls.accountSid"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="authToken" class="form-label">Auth Token</label>
		<div ngbTooltip="The auth token to use. Leave blank to keep the current auth token."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="authToken" id="authToken" />
		<shared-input-validation-messages [control]="settingsForm.controls.authToken"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="outgoingNumber" class="form-label">Outgoing Number</label>
		<div ngbTooltip="The outgoing number to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="outgoingNumber" id="outgoingNumber" />
		<shared-input-validation-messages [control]="settingsForm.controls.outgoingNumber"></shared-input-validation-messages>
	</div>
</form>
