<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="link"></fa-icon>
			<span class="ps-2">
				<ng-container *ngIf="(sourceDevice?.managedDeviceId || 0) === 0"> Set Root Device </ng-container>
				<ng-container *ngIf="sourceDevice?.managedDeviceId > 0"> Link Device </ng-container>
			</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<div *ngIf="sourceDevice?.managedDeviceId > 0">
			<div class="h5">Source Device</div>
			<div class="d-flex box-shadow py-2 mb-2">
				<div class="flex-1 text-center px-2" style="border-right: 1px solid #dedede">
					<div class="h6">Name</div>
					<button class="btn btn-link" (click)="viewSelectedDetails(sourceDevice)">{{ sourceDevice?.name }}</button>
				</div>
				<div class="flex-1 text-center px-2" style="border-right: 1px solid #dedede">
					<div class="h6">Type</div>
					{{ getTypeLabel(sourceDevice?.type) }}
				</div>
				<div class="flex-1 text-center px-2" style="border-right: 1px solid #dedede">
					<div class="h6">Serial</div>
					{{ sourceDevice?.serialNumber }}
				</div>
				<div class="flex-1 text-center px-2">
					<div class="h6">MAC</div>
					{{ utils.formatters.asMacAddress(sourceDevice?.macAddress) }}
				</div>
			</div>
		</div>
		<div>
			<shared-context-message *ngIf="isInitialized !== true" type="dark" class="p-3">Loading data.</shared-context-message>
			<shared-context-message *ngIf="isInitialized === true && tableSettings == null" type="error" class="p-3"
				>Error displaying data.</shared-context-message
			>
			<ng-container *ngIf="isInitialized === true && tableSettings != null">
				<app-root-monitor-cloud-data-table
					[isInitialized]="isInitialized"
					[showSearch]="true"
					[tableSettings]="tableSettings"
					[tableItems]="availableDevices"
					(pagingChanged)="onPagingChanged($event)"
					[searchConfiguration]="searchConfiguration"
					[accountIds]="[accountId]"
					[initialSearchFilter]="initialSearchFilter"
					(applyFilter)="onSearch($event)"></app-root-monitor-cloud-data-table>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="!isValid">
			<fa-icon icon="check" class="me-2"></fa-icon>
			{{ selectedDevices?.length > 1 ? 'Select ' + selectedDevices?.length + ' Devices' : 'Select Device' }}
		</button>
	</div>
</div>
