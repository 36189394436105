import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { SharedNgbDateParserFormatter } from '../../formatters/ngb-date-parser.formatter';

@Component({
	selector: 'shared-form-date-picker',
	templateUrl: './form-date-picker.component.html',
	styleUrls: ['./form-date-picker.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: FormDatePickerComponent, multi: true },
		{ provide: NgbDateParserFormatter, useClass: SharedNgbDateParserFormatter },
		{ provide: NG_VALIDATORS, useExisting: FormDatePickerComponent, multi: true },
	],
})
export class FormDatePickerComponent implements ControlValueAccessor {
	@ViewChild('dp', { read: true }) public datePickerInput: NgbInputDatepicker;

	propagateChange = (_: any) => {};
	propagateTouch = () => {};

	@Input() label: string;
	@Input() formControlName: string;
	@Input() readonly: boolean = false;
	@Input() required: boolean = false;
	@Input() useTime: boolean = false;
	@Input() dateFormats: string[] = ['MM/DD/YY', 'M/D/YY', 'MM/D/YY', 'M/DD/YY', 'MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY'];

	value: any;
	controlDateValue: any;
	controlTimeValue: any;
	readableValue: string;
	isDisabled: boolean = false;
	datePattern: any = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)?\d{2}/;

	constructor(private readonly logger: NGXLogger) {}

	writeValue(input: any): void {
		this.value = this.getDate(input, this.useTime === true);
		if (this.value == null) return;

		this.controlDateValue = this.getControlDate(this.value);
		this.controlTimeValue = this.getControlTime(this.value);
		this.readableValue = this.getReadableValue(this.value);
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouch = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.isDisabled = isDisabled;
	}

	updateDate(input: any) {
		this.propagateTouch();
		if (typeof input === 'string') {
			var match = input.match(/^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/);
			if (match == null) {
				this.value = undefined;
				return;
			}
		}

		this.setValue();
		this.propagateChange(this.value);
	}

	updateTime(input: any) {
		this.propagateTouch();
		this.controlTimeValue.hour = input.hour;
		this.controlTimeValue.minute = input.minute;
		this.setValue();
		this.propagateChange(this.value);
	}

	public validate(control: AbstractControl): ValidationErrors | null {
		return this.datePickerInput ? this.datePickerInput.validate(control) : null;
	}

	getControlDate(value: Date) {
		if (!value) {
			return undefined;
		}

		const date = this.getMoment(value);
		if (date.isValid() !== true) {
			return undefined;
		}

		try {
			return {
				year: date.year(),
				month: date.month() + 1,
				day: date.date(),
			};
		} catch {
			return undefined;
		}
	}

	getControlTime(value: Date) {
		if (!value) {
			return undefined;
		}

		const date = this.getMoment(value);
		if (date.isValid() !== true) {
			return undefined;
		}

		try {
			return {
				hour: date.hours(),
				minute: date.minutes(),
			};
		} catch {
			return undefined;
		}
	}

	getReadableValue(value: Date) {
		if (!value) {
			return undefined;
		}

		const date = this.getMoment(value);
		if (date.isValid() !== true) {
			return undefined;
		}

		try {
			return this.useTime === true ? date.format('l LT') : date.format('l');
		} catch {
			return undefined;
		}
	}

	getMoment(date: Date) {
		return moment(new Date(date));
	}

	setValue() {
		try {
			this.logger.log(
				'test',
				this.controlDateValue.year,
				this.controlDateValue.month - 1,
				this.controlDateValue.day,
				this.controlTimeValue.hour,
				this.controlTimeValue.minute
			);
			var tempDate = new Date(
				this.controlDateValue.year,
				this.controlDateValue.month - 1,
				this.controlDateValue.day,
				this.controlTimeValue.hour,
				this.controlTimeValue.minute,
				0,
				0
			);
			if (this.useTime !== true) {
				tempDate.setHours(0);
				tempDate.setMinutes(0);
			}

			const tzOffset = tempDate.getTimezoneOffset();
			var date = this.getMoment(tempDate).utcOffset(tzOffset);

			if (date.isValid() !== true) {
				this.value = undefined;
				return undefined;
			}

			this.value = date.toDate();
		} catch {
			this.value = undefined;
			return;
		}
	}

	getDate(input: any, setTime: boolean) {
		if (input == null) return input;

		let tempDate;
		setTime = setTime === true && this.useTime === true;

		if (input.hasOwnProperty('year') === true) {
			// already in our structured format
			tempDate = input;
		} else {
			// otherwise parse it
			let momentDate;
			if (typeof input === 'string') {
				// is of type string
				momentDate = this.getMoment(new Date(input));
			} else {
				// else assume is date
				momentDate = this.getMoment(input);
			}

			if (momentDate.isValid() !== true) {
				this.value = undefined;
				return undefined;
			}

			tempDate = {
				year: momentDate.year(),
				month: momentDate.month(),
				day: momentDate.date(),
				hour: momentDate.hours(),
				minute: momentDate.minutes(),
			};

			if (this.useTime !== true) {
				tempDate.hour = 0;
				tempDate.minute = 0;
			}
		}

		var parsed = new Date(tempDate.year, tempDate.month, tempDate.day, tempDate.hour, tempDate.minute, 0, 0);
		const momentDate = this.getMoment(parsed);
		if (momentDate.isValid() !== true) {
			return undefined;
		}

		return momentDate.toDate();
	}
}
