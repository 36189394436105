import { Component, Input, Output, SimpleChanges } from '@angular/core';
import { AlertType } from '@razberi-ui/core/data-types';
import { BehaviorSubject } from 'rxjs';
import { AlertTypeSelectorConfig } from '../../../data-types/alert-type-selector-config.model';
import { AlertGroup } from '../../../data-types/alert-group.model';

@Component({
	selector: 'app-root-monitor-cloud-alert-type-selector',
	templateUrl: './alert-type-selector.component.html',
	styleUrls: ['./alert-type-selector.component.scss'],
})
export class AlertTypeSelectorComponent {
	@Input() config: AlertTypeSelectorConfig;
	@Output() alertTypesChange: BehaviorSubject<AlertType[]>;

	alertGroups: AlertGroup[];
	availableAlertTypes: AlertType[];
	filterOpen: boolean;

	constructor() {
		this.alertGroups = [];
		this.availableAlertTypes = [];
		this.filterOpen = false;
		this.alertTypesChange = new BehaviorSubject([]);
		this.config = {
			alertTypes: [],
			availableAlertTypes: [],
		};
	}

	ngOnInit() {
		this.config = this.config || { alertTypes: [], availableAlertTypes: [] };
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.config.currentValue.availableAlertTypes.length > 0) {
			this.availableAlertTypes = [...this.config.availableAlertTypes].sort((a, b) => a.name.localeCompare(b.name));
			//this.config.availableAlertTypes.sort((a, b) => a.name.localeCompare(b.name));
			this.groupAlerts();
		}
	}

	updateAlertType($event) {
		this.config.alertTypes = [...$event];
		this.groupAlerts();
	}

	groupAlerts() {
		let groups = [...this.config.availableAlertTypes].reduce((accumlator, item) => {
			let alertType: AlertType = this.config.alertTypes.find((alertItem) => alertItem.alertTypeId === item.alertTypeId);
			let checkBoxItem: any = { checked: !!alertType, ...item };
			if (!accumlator[item.category]) accumlator[item.category] = [];
			accumlator[item.category].push(checkBoxItem);
			return accumlator;
		}, {});

		let groupObjs: AlertGroup[] = Object.keys(groups).map((group) => {
			return <AlertGroup>{ name: group, alertTypes: groups[group], allSelected: !groups[group].some((at: any) => !at.checked) };
		});
		groupObjs.sort((a, b) => a.name.localeCompare(b.name)); // sort groups
		groupObjs.forEach((group) => group.alertTypes.sort((a, b) => a.name.localeCompare(b.name))); // sort alert types within groups
		this.alertGroups = [
			...groupObjs.filter((g) => g.name !== 'Expiration' && g.name !== 'Validation'),
			...groupObjs.filter((g) => g.name === 'Expiration'),
			...groupObjs.filter((g) => g.name === 'Validation'),
		]; // move Expiration group to end
		this.alertTypesChange.next([...this.config.alertTypes]);
	}

	alertTypeChecked($event, item: AlertType) {
		let group = this.alertGroups.filter((g) => g.name === item.category)[0];
		group.allSelected = !group.alertTypes.some((at: any) => !at.checked);
		let alertTypesCopy: AlertType[] = this.config.alertTypes.filter((alertType) => alertType.alertTypeId !== item.alertTypeId);
		if ($event.target.checked) alertTypesCopy.push(item);
		this.config.alertTypes = alertTypesCopy;
		this.alertTypesChange.next([...this.config.alertTypes]);
	}

	alertGroupChecked($event, group: AlertGroup) {
		if ($event.target.checked) {
			let removeGroup = [...this.config.alertTypes.filter((at) => at.category !== group.name)];
			let allInGroup = [...this.config.availableAlertTypes.filter((at) => at.category === group.name)];
			this.config.alertTypes = [...removeGroup, ...allInGroup];
			this.groupAlerts();
		} else {
			this.config.alertTypes = [...this.config.alertTypes.filter((at) => at.category !== group.name)];
			this.groupAlerts();
		}
	}

	clearFilter() {
		this.config.alertTypes = [];
		this.groupAlerts();
	}

	handleFilterOpen($event) {
		this.filterOpen = $event;
	}
}
