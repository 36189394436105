<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="info-circle"></fa-icon>
			<span class="ps-2"> Learn the Benefits of a {{ configService.data.config?.baseTitle || 'MonitorCloud' }} Account </span>
		</h4>
	</div>
	<div class="modal-body">
		<div class="container">
			<div class="row mt-3 mb-3" style="border-bottom: 1px solid #dee2e6">
				<div class="col-sm-12 d-flex justify-content-center">
					<h6 class="me-3">Selected account type:</h6>
					<div class="me-3">
						<label>
							<input
								type="radio"
								name="selectedAccountType"
								[value]="accountTypeOptions.Provider"
								(change)="selectType(accountTypeOptions.Provider)"
								[checked]="true"
								[focusIf]="false" />
							<span class="ms-1">Provider</span>
						</label>
					</div>
					<div class="me-3">
						<label>
							<input
								type="radio"
								name="selectedAccountType"
								[value]="accountTypeOptions.Customer"
								(change)="selectType(accountTypeOptions.Customer)"
								[checked]="false"
								[focusIf]="false" />
							<span class="ms-1">Customer</span>
						</label>
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-sm-12">
					<div [innerHTML]="benefits"></div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary float-right" [disabled]="selectedAccountType == null" (click)="close()" [focusIf]="true">
			<fa-icon *ngIf="selectedAccountType != null" icon="check"></fa-icon>
			{{ selectedAccountType == null ? 'Continue' : 'Continue as  ' + accountTypeOptions[selectedAccountType] }}
		</button>
	</div>
</div>
