import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
	EnumItem,
	EnumService,
	InstallMsiManageTaskParams,
	ManageTaskFile,
	ManageTaskFileMode,
	ManageTaskMsiAction,
	ManageTaskParamsFileChangedEvent,
} from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { ManageService } from '../../../../../services/manage.service';
import { ReportService } from '../../../../../services/report.service';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'razberi-ui-install-msi',
	templateUrl: './install-msi.component.html',
	styleUrls: ['./install-msi.component.scss'],
})
export class InstallMsiComponent {
	@Input() accountId: number;
	@Input() parameters: string;
	@Input() taskFiles: ManageTaskFile[];
	@Input() readonly: boolean;
	@Output() parametersChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() filesSelected: EventEmitter<ManageTaskParamsFileChangedEvent> = new EventEmitter<ManageTaskParamsFileChangedEvent>();
	@Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

	resetSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	reset$: Observable<void> = this.resetSubject.asObservable();
	subscriptions: Subscription = new Subscription();
	deserialized: InstallMsiManageTaskParams;
	parametersForm: UntypedFormGroup;
	selectedFile: File = null;
	fileMode: ManageTaskFileMode = ManageTaskFileMode.New;
	fileModeOptions: any = ManageTaskFileMode;
	initialized: boolean = false;
	actions: EnumItem[];

	constructor(
		private readonly manageService: ManageService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly reportService: ReportService,
		private readonly alertMessageService: AlertMessageService,
		private readonly enumService: EnumService
	) {}

	ngOnInit(): void {
		this.actions = this.enumService.helpers.getManageTaskMsiActions().filter((item: EnumItem) => item.value !== ManageTaskMsiAction.None);
		this.init();
	}

	init() {
		if (this.initialized !== true) {
			this.buildForm();
			this.subscriptions.add(
				this.parametersForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
					this.emitValidity();
				})
			);
			this.subscriptions.add(
				this.parametersForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
					this.parametersChanged.emit(JSON.stringify(value));
					this.emitValidity();
				})
			);
			this.initialized = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.init();
		this.tryLoadParams();
	}

	tryLoadParams() {
		this.deserialized = JSON.parse(this.parameters);
		this.parametersForm.reset();
		if (this.parameters?.length > 0) this.parametersForm.patchValue(this.deserialized);
		if (this.deserialized?.action == null) this.parametersForm.patchValue({ action: ManageTaskMsiAction.Install });
		if (this.deserialized?.allowReboot == null) this.parametersForm.patchValue({ allowReboot: false });
		if (this.readonly) {
			this.parametersForm.disable();
		} else {
			this.parametersForm.enable();
		}
		this.fileMode = this.taskFiles?.length > 0 ? ManageTaskFileMode.Existing : ManageTaskFileMode.New;
		this.emitValidity();
	}

	buildForm() {
		if (this.parametersForm != null) return;

		this.parametersForm = this.formBuilder.group({
			action: [ManageTaskMsiAction.Install, [Validators.required]],
			url: [null, [Validators.pattern(/^(https?:\/\/)\S+$/)]],
			args: [null, []],
			allowReboot: [false, []],
		});
	}

	fileSelected(file: File) {
		const fileExtension = file.name.split('.').pop();
		if (fileExtension.toLowerCase() !== 'msi') {
			this.selectedFile = null;
			this.alertMessageService.error('Only the following file extensions are valid: .msi', null, { autoClose: true });
			this.emitValidity();
			this.resetSubject.next();
			return;
		}

		this.selectedFile = file;
		const event: ManageTaskParamsFileChangedEvent = { uploadFiles: [this.selectedFile], clearExistingFiles: true };
		this.filesSelected.emit(event);
		this.emitValidity();
	}

	resetFileEvent() {
		let event: ManageTaskParamsFileChangedEvent = { uploadFiles: [], clearExistingFiles: false };
		if (this.fileMode === ManageTaskFileMode.New) event = { uploadFiles: [this.selectedFile], clearExistingFiles: true };
		this.filesSelected.emit(event);
		this.emitValidity();
	}

	emitValidity() {
		const isValid =
			this.parametersForm.valid === true &&
			((!!this.selectedFile && !this.parametersForm.get('url').value) || (!this.selectedFile && !!this.parametersForm.get('url').value));
		this.valid.emit(isValid);
	}

	downloadTaskFile(taskFile: ManageTaskFile) {
		this.manageService.api.getManageTaskFile(this.accountId, taskFile.manageTaskFileId).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting MSI file.', error);
			},
		});
	}
}
