<ng-container *ngIf="(manageTask?.manageTaskId || 0) === 0 && (availableAccounts?.length || 0) === 0">
	<div class="w-100 p-5 text-center box-shadow">There are no accounts available with a valid Manage subscription.</div>
</ng-container>
<ng-container *ngIf="manageTask?.manageTaskId > 0 || availableAccounts?.length > 0">
	<form [formGroup]="taskForm" autocomplete="off" novalidate>
		<div class="mb-3">
			<div class="col">
				<h4>Task Details</h4>
			</div>
		</div>
		<div class="row px-3" *ngIf="(manageTask?.manageTaskId || 0) === 0">
			<div class="mb-3 col-sm-12">
				<label for="accountId" class="form-label">Account <span class="text-primary">*</span></label>
				<select class="form-select" id="accountId" name="accountId" formControlName="accountId" (change)="changeTaskAccount()" required>
					<option *ngFor="let account of availableAccounts" [ngValue]="account.accountId">{{ account.name }}</option>
				</select>
				<shared-input-validation-messages [control]="taskForm.controls.accountId"></shared-input-validation-messages>
			</div>
		</div>
		<div class="px-3">
			<div class="mb-3 col-sm-12">
				<label for="name" class="form-label">Task Name <span class="text-primary">*</span></label>
				<input type="text" class="form-control" formControlName="name" id="name" required [readonly]="manageTask?.manageTaskId > 0" />
				<shared-input-validation-messages [control]="taskForm.controls.name"></shared-input-validation-messages>
			</div>
			<div class="mb-3 col-sm-12">
				<label for="description" class="form-label">Task Description</label>
				<input type="text" class="form-control" formControlName="description" id="description" [readonly]="manageTask?.manageTaskId > 0" />
			</div>
			<div class="mb-3 col-sm-12">
				<label for="notificationType" class="form-label">Notifications <span class="text-primary">*</span></label>
				<select class="form-select" id="notificationType" name="notificationType" formControlName="notificationType" required>
					<option *ngFor="let notificationTypeKey of notificationTypeKeys" [ngValue]="notificationTypeKey.value">
						{{ notificationTypeKey.text }}{{ notificationTypeKey.value == notificationTypeOptions.Both ? ' (Email + SMS)' : '' }}
					</option>
				</select>
				<shared-input-validation-messages [control]="taskForm.controls.notificationType"></shared-input-validation-messages>
			</div>
			<div class="mb-3 col-sm-12">
				<label for="manageTaskTypeId" class="form-label">Task Type <span class="text-primary">*</span></label>
				<select class="form-select" id="manageTaskTypeId" name="manageTaskTypeId" formControlName="manageTaskTypeId" required>
					<optgroup *ngFor="let group of availableManageTaskGroups" [label]="group.name">
						<option *ngFor="let type of group.types" [ngValue]="type.taskTypeId">{{ type.name }}</option>
					</optgroup>
				</select>
				<shared-input-validation-messages [control]="taskForm.controls.taskTypeId"></shared-input-validation-messages>
			</div>
		</div>
		<div class="row mt-5 mb-3">
			<div class="col-sm-12">
				<h4>Task Parameters</h4>
			</div>
		</div>
		<div class="row px-3">
			<div class="col-sm-12">
				<app-root-monitor-cloud-manage-task-form-parameters
					[accountId]="taskForm?.controls?.accountId?.value"
					[manageTaskTypeId]="taskForm?.controls?.manageTaskTypeId?.value"
					[parameters]="taskData?.task?.parameters"
					[taskFiles]="taskData?.task?.manageTaskFiles"
					[readonly]="manageTask?.manageTaskId > 0"
					class="w-100"
					(parametersChanged)="onParametersChanged($event)"
					(filesSelected)="onFilesSelected($event)"
					(valid)="onValid($event)"></app-root-monitor-cloud-manage-task-form-parameters>
				<shared-input-validation-messages [control]="taskForm.controls.parameters"></shared-input-validation-messages>
			</div>
		</div>
		<div class="row mt-5 mb-3">
			<div class="col-sm-12">
				<h4>Task Targets</h4>
			</div>
		</div>
		<div class="row px-3">
			<div class="col-sm-12">
				<app-root-monitor-cloud-unit-selector
					[accountId]="taskForm?.controls?.accountId?.value"
					[preSelectedUnits]="taskForm.controls?.units?.value"
					(selectedUnitsChanged)="selectedUnitsChanged($event)"
					[readonly]="manageTask?.manageTaskId > 0"></app-root-monitor-cloud-unit-selector>
				<shared-input-validation-messages [control]="taskForm.controls.units"></shared-input-validation-messages>
			</div>
		</div>
		<div class="row mt-5 mb-3">
			<div class="col-sm-12">
				<h4>Task Options</h4>
			</div>
		</div>
		<div class="px-3">
			<div class="row mb-3">
				<div class="col-sm-12">
					<div class="form-check">
						<input
							type="checkbox"
							class="form-check-input"
							id="startImmediately"
							name="startImmediately"
							[(ngModel)]="executeImmediately"
							[ngModelOptions]="{ standalone: true }" />
						<label for="startImmediately" class="form-check-label">Start Immediately</label>
					</div>
				</div>
			</div>
			<div class="row mb-3" *ngIf="executeImmediately !== true">
				<div class="col-sm-12 col-md-6">
					<shared-form-date-picker
						label="Start At"
						formControlName="executeAt"
						[useTime]="true"
						[readonly]="manageTask?.manageTaskId > 0"></shared-form-date-picker>
					<shared-input-validation-messages [control]="taskForm.controls.executeAt"></shared-input-validation-messages>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-sm-12">
					<div class="form-check">
						<input
							type="checkbox"
							class="form-check-input"
							id="concurrent"
							name="concurrent"
							[(ngModel)]="setConcurrency"
							[ngModelOptions]="{ standalone: true }" />
						<label for="concurrent" class="form-check-label">Set Concurrency</label>
						<ng-template #concurrencydTooltip>
							The number instances that will run the task at the same time.
							<br /><br />
							Set to '0' to run all instances at once.
						</ng-template>
						<div [ngbTooltip]="concurrencydTooltip" placement="right" container="body" class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-3" *ngIf="setConcurrency === true">
				<div class="col-sm-12 col-6-md mb-4">
					<input type="text" class="form-control" formControlName="concurrent" id="concurrent" required [readonly]="manageTask?.manageTaskId > 0" />
					<shared-input-validation-messages [control]="taskForm.controls.concurrent"></shared-input-validation-messages>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-sm-12">
					<div class="form-check">
						<input
							type="checkbox"
							class="form-check-input"
							id="errorHandling"
							name="errorHandling"
							[(ngModel)]="setErrorThreshold"
							[ngModelOptions]="{ standalone: true }" />
						<label for="errorHandling" class="form-check-label">Set Error Threshold</label>
						<ng-template #errorThresholdTooltip>
							The maximum number of instances that experience errors before a task is failed.
							<br /><br />
							Set to “0” for the task to continue regardless of instance status.
						</ng-template>
						<div [ngbTooltip]="errorThresholdTooltip" placement="right" container="body" class="d-inline ms-1 align-self-baseline">
							<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-3" *ngIf="setErrorThreshold === true">
				<div class="col-sm-12 col-6-md mb-4">
					<input
						type="text"
						class="form-control"
						formControlName="errorHandling"
						id="errorHandling"
						required
						[readonly]="manageTask?.manageTaskId > 0" />
					<shared-input-validation-messages [control]="taskForm.controls.errorHandling"></shared-input-validation-messages>
				</div>
			</div>
		</div>
	</form>
</ng-container>
