import { HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class UtilitiesService {
	constructor(private readonly spinnerService: NgxSpinnerService, private readonly zone: NgZone) {}

	get helpers() {
		return {
			saveAsFile(response: HttpResponse<Blob>): void {
				const cdHeader = response.headers.get('Content-Disposition');
				const filename = cdHeader.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
				const a = document.createElement('a');
				const objectUrl = URL.createObjectURL(response.body);
				a.href = objectUrl;
				a.download = filename;
				a.click();
				URL.revokeObjectURL(objectUrl);
				a.remove();
			},
			saveLocalFile(blob: Blob, filename: string): void {
				const a = document.createElement('a');
				const objectUrl = URL.createObjectURL(blob);
				a.href = objectUrl;
				a.download = filename;
				a.click();
				URL.revokeObjectURL(objectUrl);
				a.remove();
			},
			getRandomString(chars: number = 8): string {
				let result: string = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for (var i = 0; i < chars; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));
				return result;
			},
			generateUUID: (): string => {
				return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = (Math.random() * 16) | 0,
						v = c == 'x' ? r : (r & 0x3) | 0x8;
					return v.toString(16);
				});
			},
			compareStrings(a: string, b: string, ascending: boolean): number {
				a = a ? a.toString() : '';
				b = b ? b.toString() : '';
				if (ascending) return b.localeCompare(a);
				else return a.localeCompare(b);
			},
			compareDateStrings(a: string, b: string, ascending: boolean): number {
				let aTimeValue: number = new Date(a).getTime();
				let bTimeValue: number = new Date(b).getTime();
				if (ascending) return bTimeValue - aTimeValue;
				else return aTimeValue - bTimeValue;
			},
			isPlainObject: (obj) => {
				return Object.prototype.toString.call(obj) === '[object Object]';
			},
			// global
			openSpinner$: (): Observable<any> => {
				this.spinnerService.show();
				return new Observable<any>((subscriber) => {
					const closeSpinner = () => this.spinnerService.hide();
					subscriber.next(closeSpinner);
				});
			},
			addSpacesBeforeCapitals(value: string): string {
				return value?.replace(/([A-Z])/g, ' $1').trim();
			},
			getParameterByName(name: string, url: string = window.location.href): string {
				const u: URL = new URL(url);
				if (!u.searchParams.has(name) && u.hash.indexOf(name) == -1) return null;

				let value: string = u.searchParams.get(name);
				if (!value) value = u.hash.slice(u.hash.indexOf(name)).replace(name + '=', '');

				if (!value) return null;
				return decodeURIComponent(value);
			},
			setParameterByName(name: string, value: string, url: string = window.location.href): string {
				const u: URL = new URL(url, window.location.origin);
				u.searchParams.set(name, encodeURIComponent(value));
				return u.toString();
			},
			base64UrlEncode(s: string): string {
				var enc = new TextEncoder();
				return this.base64UrlEncodeBytes(enc.encode(s));
			},
			base64UrlEncodeBytes(bytes: ArrayBuffer): string {
				return window
					.btoa(Array.from(new Uint8Array(bytes), (b) => String.fromCharCode(b)).join(''))
					.replace(/\+/g, '-')
					.replace(/\//g, '_')
					.replace(/=+$/, '');
			},
			base64UrlDecode(s: string): string {
				var dec = new TextDecoder('utf-8');
				return dec.decode(this.base64UrlDecodeBytes(s));
			},
			base64UrlDecodeBytes(s: string): ArrayBuffer {
				const m = s.length % 4;
				return Uint8Array.from(
					window.atob(
						s
							.replace(/-/g, '+')
							.replace(/_/g, '/')
							.padEnd(s.length + (m === 0 ? 0 : 4 - m), '=')
					),
					(c) => c.charCodeAt(0)
				).buffer;
			},
			getPagingStatus(page, pageSize, totalItems): string {
				const maxCount = page * pageSize;
				const showing = totalItems === 0 ? '0' : `${maxCount - pageSize + 1}-${maxCount > totalItems ? totalItems : maxCount}`;
				return `showing ${showing} of ${totalItems}`;
			},
			deepClone: <T>(target: T): T => {
				if (target === null) return target;
				if (target instanceof Date) return new Date(target.getTime()) as any;
				if (target instanceof Array) {
					const cp = [] as any[];
					(target as any[]).forEach((v) => {
						cp.push(v);
					});
					return cp.map((n: any) => this.helpers.deepClone<any>(n)) as any;
				}
				if (typeof target === 'object' && Object.keys(target).length !== 0) {
					const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
					Object.keys(cp).forEach((k) => {
						cp[k] = this.helpers.deepClone<any>(cp[k]);
					});
					return cp as T;
				}
				return target;
			},
			hasValue: (obj): boolean => {
				return obj != null;
			},
		};
	}

	get formatters() {
		return {
			asIs: (sourceText: any): any => {
				return sourceText;
			},
			asBoolean: (value: boolean): string => {
				return value === null ? null : value ? 'True' : 'False';
			},
			asMacAddress(value: string) {
				if (value?.length !== 12) return value;
				return value
					.toUpperCase()
					.match(/.{1,2}/g)
					.join('-');
			},
			asMemoryCapacity(b) {
				var thresh = 1024;
				if (Math.abs(b) < thresh) {
					return b + ' B';
				}
				var units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				var u = -1;
				do {
					b /= thresh;
					++u;
				} while (Math.abs(b) >= thresh && u < units.length - 1);
				return b.toFixed(1) + ' ' + units[u];
			},
			asSpeed(b) {
				var thresh = 1000;
				if (Math.abs(b) < thresh) {
					return b + ' bps';
				}
				var units = ['Kbps', 'Mbps', 'Gbps', 'Tbps'];
				var u = -1;
				do {
					b /= thresh;
					++u;
				} while (Math.abs(b) >= thresh && u < units.length - 1);
				return (b.toFixed(1) + ' ' + units[u]).replace('.0', '');
			},
			asUtcDate: (dateString: string): string => {
				return moment.utc(dateString).isValid() ? moment.utc(dateString).format('l') : '--';
			},
			asLocalDate: (dateString: string): string => {
				return moment(dateString).isValid() ? moment(dateString).format('l') : '--';
			},
			asLocalTime: (timeString: string): string => {
				return moment(timeString).isValid() ? moment(timeString).format('l LTS') : '--';
			},
			asIpAddress: (rawString: string): string => {
				return rawString?.replace(',', ', ');
			},
		};
	}
}
