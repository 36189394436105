import { Component, Input, SimpleChanges } from '@angular/core';
import { ManageTaskStatus } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-manage-task-status',
	templateUrl: './cell-manage-task-status.component.html',
	styleUrls: ['./cell-manage-task-status.component.scss'],
})
export class CellManageTaskStatusComponent {
	@Input() status: ManageTaskStatus;
	@Input() tooltip: string;
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	icon: string;
	color: string;
	text: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = 'question';
		let color: string = 'status-black';
		let text: string = 'N/A';

		switch (this.status) {
			case ManageTaskStatus.Scheduled:
				icon = 'clock';
				color = 'status-black';
				text = ManageTaskStatus[this.status];
				break;
			case ManageTaskStatus.Cancelled:
				icon = 'ban';
				color = 'status-orange';
				text = ManageTaskStatus[this.status];
				break;
			case ManageTaskStatus.Executing:
				icon = 'play';
				color = 'status-blue';
				text = ManageTaskStatus[this.status];
				break;
			case ManageTaskStatus.Succeeded:
				icon = 'check';
				color = 'status-green';
				text = ManageTaskStatus[this.status];
				break;
			case ManageTaskStatus.Failed:
				icon = 'times';
				color = 'status-red';
				text = ManageTaskStatus[this.status];
				break;
			default:
				break;
		}

		this.icon = icon;
		this.color = color;
		this.text = text;
	}
}
