import { Component, Input } from '@angular/core';
import { User, UserRole, UserRoleAttributes } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-user-roles',
	templateUrl: './cell-user-roles.component.html',
	styleUrls: ['./cell-user-roles.component.scss'],
})
export class CellUserRolesComponent {
	@Input() user: User;
	@Input() displayCount: number = 3;

	UserStatusOptions: any = UserRole;

	constructor() {}

	getRoleName(role: number) {
		return UserRoleAttributes.get(role)?.label;
	}

	roleIsValid(role: number) {
		return Object.keys(UserRole).includes(role.toString());
	}
}
