<form id="account-subscription-form" [formGroup]="accountSubscriptionForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row mb-3">
		<div class="col-6">
			<label for="type">Subscription type <span class="text-primary">*</span></label>
			<select class="form-select" id="type" name="type" formControlName="type" required>
				<option *ngFor="let type of types" [ngValue]="type.value">{{ type.name }}</option>
			</select>
			<shared-input-validation-messages [control]="accountSubscriptionForm.controls.type"></shared-input-validation-messages>
		</div>
		<div *ngIf="enabled" class="col-6">
			<label for="maxDevices">Maximum devices <span class="text-primary">*</span></label>
			<input type="text" class="form-control" id="maxDevices" name="maxDevices" formControlName="maxDevices" />
			<shared-input-validation-messages [control]="accountSubscriptionForm.controls.maxDevices"></shared-input-validation-messages>
		</div>
	</div>
	<div *ngIf="enabled" class="row mb-3 col">
		<label for="startDate" class="form-label">Duration <span class="text-primary">*</span></label>
		<div class="">
			<div *ngFor="let duration of durations; let i = index" class="form-check form-check-inline">
				<input
					type="radio"
					class="form-check-input"
					id="duration_{{ duration.value }}"
					name="duration"
					[value]="duration.value"
					[checked]="selectedDuration === i"
					(change)="onDurationChange(i)" />
				<label class="form-check-label" for="duration_{{ duration.value }}">{{ duration.label }}</label>
			</div>
		</div>
	</div>
	<div *ngIf="enabled" class="row mb-3">
		<div class="col-6">
			<shared-form-date-picker id="startDate" formControlName="startDate" [required]="true" label="Start Date"></shared-form-date-picker>
			<shared-input-validation-messages [control]="accountSubscriptionForm.controls.startDate"></shared-input-validation-messages>
		</div>
		<div class="col-6">
			<shared-form-date-picker id="endDate" formControlName="endDate" [required]="true" label="End Date"></shared-form-date-picker>
			<shared-input-validation-messages [control]="accountSubscriptionForm.controls.endDate"></shared-input-validation-messages>
		</div>
	</div>
	<div *ngIf="enabled && showStatus" class="row"><div class="col">Status</div></div>
	<div *ngIf="enabled && showStatus" class="row">
		<div class="col text-{{ statusColor }}"><fa-icon [icon]="statusIcon"></fa-icon> {{ status }} ({{ accountStat.devices }} registered devices)</div>
	</div>
	<div *ngIf="enabled && showStatus" class="row mt-5">
		<div class="col">
			<fa-icon icon="usd-circle" class="text-success"></fa-icon> Your account may be invoiced for new subscriptions. Trial subscriptions are free.
		</div>
	</div>
	<div *ngIf="enabled && showStatus && timestamp" class="row mt-5 text-muted">
		<div class="col"><fa-icon icon="calendar-edit"></fa-icon> Last updated: {{ timestamp | date: 'short' }}.</div>
	</div>
</form>
