import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-shared-monitor-common-dashboard-carousel-card',
	templateUrl: './dashboard-carousel-card.component.html',
	styleUrls: ['./dashboard-carousel-card.component.scss'],
})
export class DashboardCarouselCardComponent implements OnInit {
	@Input() isActive: boolean;
	@Input() heading: string;
	@Input() icon: string;
	@Input() path: string;
	@Input() tooltip: string;
	@Input() statusIcon: string;
	@Input() statusIconClass: string;
	@Input() statusTooltip: string;

	constructor() {
		this.isActive = false;
		this.heading = '';
	}

	ngOnInit() {}
}
