import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { PagePortalService } from '../services/page-portal.service';

@Directive({
	selector: '[pagePortalOutlet]',
})
export class PagePortalOutletDirective implements OnInit {
	@Input() outletKey: string;

	constructor(private portalService: PagePortalService, public viewContainerRef: ViewContainerRef) {}

	ngOnInit(): void {
		this.portalService.registerOutlet(this);
	}
}
