import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@razberi-ui/shared';
import { CloudApiService } from './services/cloud-api.service';
import { CloudApiConfigService } from './services/cloud-api-config.service';
import { CoreServerSettingsService } from './services/core-server-settings.service';
import { AuthService } from './services/auth.service';
import { MultiAuthService } from './services/multi-auth.service';

@NgModule({
	imports: [SharedModule],
})
export class CloudApiModule {
	static forRoot(): ModuleWithProviders<CloudApiModule> {
		return {
			ngModule: CloudApiModule,
			providers: [AuthService, CloudApiService, CloudApiConfigService, CoreServerSettingsService, MultiAuthService],
		};
	}
}
