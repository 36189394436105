<form id="form-login" class="row" [formGroup]="loginForm" (keyup.enter)="submitForm()" autocomplete="on" novalidate>
	<div class="col-sm-12 mb-3">
		<div class="input-group">
			<span class="input-group-text"><fa-icon icon="user"></fa-icon></span>
			<input type="text" class="form-control" id="email" name="email" placeholder="Email" formControlName="email" required autocomplete="username" />
		</div>
		<shared-input-validation-messages [control]="loginForm.controls.email" [clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
	</div>
	<div class="col-sm-12 mb-3">
		<div class="input-group">
			<span class="input-group-text"><fa-icon icon="lock"></fa-icon></span>
			<input
				type="password"
				class="form-control"
				id="password"
				placeholder="Password"
				formControlName="password"
				required
				autocomplete="current-password" />
		</div>
		<shared-input-validation-messages [control]="loginForm.controls.password" [clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
	</div>
	<div class="col-sm-12">
		<div *ngIf="hideRememberMe !== true" class="form-check mb-3 ms-2">
			<input type="checkbox" class="form-check-input" id="remember" name="remember" formControlName="remember" />
			<label class="form-check-label" for="remember">Remember me</label>
		</div>
	</div>
</form>
