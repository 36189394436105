import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Account, AccountStatus, AccountType, AuditLog, Unit } from '@razberi-ui/core/data-types';
import { AlertMessageService, DateRange, PageSidePanelService, PageSidePanelType, TableServerSidePagingConfig, TableSettings } from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import * as moment from 'moment';
import {
	DataSearchConfiguration,
	CloudApiConfigService,
	CoreServerSettingsService,
	SearchFilter,
	SearchFilterResult,
	AuthService,
} from '@razberi-ui/api/cloud-api';
import { AuditLogService } from '../../../services/audit-log.service';
import { UnitService } from '../../../services/unit.service';
import { DeviceEditModalComponent } from '../../devices/device-edit-modal/device-edit-modal.component';
import { DashboardService } from '../../../services/dashboard.service';
import { ReportService } from '../../../services/report.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-audit-log-table',
	templateUrl: './audit-log-table.component.html',
	styleUrls: ['./audit-log-table.component.scss'],
})
export class AuditLogTableComponent {
	@Input() accountIds: number[];
	@Input() dateRange: DateRange;
	@Input() showAccount: boolean = false;
	@Input() showLocation: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() auditLogsData: AuditLog[];
	@Input() dataSource: string;
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	auditLogs: AuditLog[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isDataSourceReady: boolean = false;
	isInitialized: boolean = false;

	constructor(
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly auditLogsService: AuditLogService,
		private readonly reportService: ReportService,
		private readonly dashboardService: DashboardService,
		private readonly configService: CloudApiConfigService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly sidePanelService: PageSidePanelService,
		private readonly unitService: UnitService
	) {}

	ngOnInit() {
		if (this.dataSource?.startsWith('dashboard')) {
			this.subscriptions.add(
				this.dashboardService.streams.selectedAccounts$.pipe(distinctUntilChanged()).subscribe((accounts: Account[]) => {
					if (accounts) {
						this.accountIds = accounts.map((account: Account) => account.accountId);
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getAuditLogs();
					}
				})
			);
			this.subscriptions.add(
				this.dashboardService.streams.selectedDateRange$.pipe(distinctUntilChanged()).subscribe((dateRange: DateRange) => {
					if (dateRange) {
						this.dateRange = dateRange;
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getAuditLogs();
					}
				})
			);
		} else {
			this.initialSearchFilter = { startTimestamp: moment().startOf('day').subtract(30, 'days').toDate() };
			if (
				!this.accountIds?.length &&
				this.authService.data.account.type === AccountType.Global &&
				this.settingsService.data.settings?.cloudUI?.preloadSearchFilterAccounts === true
			)
				this.initialSearchFilter.accounts = [1, 2, 3, 4, 5].map((accountId: number) => {
					return { accountId: accountId };
				});
		}
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showDateRange: true,
			showAccounts: this.showAccount,
			showLocations: true,
			showTags: true,
			showUsers: true,
			forSubscribedAccounts: true,
		};

		const ts: TableSettings = {
			useAdvancedSearch: this.showSearch,
			useEdit: false,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.auditLogsData ? false : true,
			serverSidePagingAndFilterConfig: this.auditLogsData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'auditLogId',
			headerButtons: [],
			stickyHeaderButtons: [],
			columnConfig: [
				{
					primaryKey: 'timestamp',
					header: 'Timestamp',
					useForSearch: true,
					width: '220px',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return { text: moment(value).format('l LTS'), icon: 'clock' };
					},
				},
				{
					primaryKey: 'accountName',
					header: 'Account',
					cellType: 'router-link',
					transform: (value, tableItem) => {
						if (value) {
							const c: any = {
								text: value,
								route: ['/accounts', tableItem.accountId],
								subtexts: [],
								disabled: tableItem.accountStatus === AccountStatus.Pending,
							};
							if (tableItem.accountFaviconUrl) c.image = `${this.configService.data.config.apiHost}${tableItem.accountFaviconUrl}`;
							else c.icon = 'building';
							return c;
						} else return { text: '--', disabled: true };
					},
				},
				{
					primaryKey: 'userName',
					header: 'User',
					cellType: 'icon',
					transform: (value, tableItem) => {
						if (value) {
							const c: any = { text: value };
							if (tableItem.userAvatarUrl) c.image = `${this.configService.data.config.apiHost}${tableItem.userAvatarUrl}`;
							else c.icon = 'user-circle';
							return c;
						} else return { text: '--' };
					},
				},
				{
					primaryKey: 'unitName',
					header: 'Device',
					cellType: 'link-button',
					transform: (value) => {
						return !value ? { text: '--', disabled: true } : { text: value, icon: 'server' };
					},
					clickHandlerFunction: (params) => {
						this.sidePanelService.helpers.openUnitSidePanel(
							params.unitName,
							'details',
							params.accountId,
							params.unitId,
							this.unitService.helpers.userCanEditUnit(),
							(device) => {
								this.editDevice(device);
							}
						);
					},
				},
				{
					primaryKey: 'networkName',
					header: 'Network',
					cellType: 'router-link',
					transform: (value, tableItem) => {
						return !value
							? { text: '--', disabled: true }
							: {
									text: value,
									icon: 'network-wired',
									route: ['/networks', tableItem.networkId, 'account', tableItem.accountId],
									subtexts: [],
							  };
					},
				},
				{
					primaryKey: 'unitLocationName',
					header: 'Location',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return !value ? { text: '--' } : { text: value, icon: 'map-marker-alt' };
					},
				},
				{ primaryKey: 'message', header: 'Message' },
			],
		};
		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getAuditLogs();
				},
			});
		if (!this.showAccount || (this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)) {
			const accountNameIdx = ts.columnConfig.findIndex((col) => col.primaryKey === 'accountName');
			if (accountNameIdx >= 0) ts.columnConfig.splice(accountNameIdx, 1);
		}
		if (!this.showLocation) {
			const unitLocationNameIdx = ts.columnConfig.findIndex((col) => col.primaryKey === 'unitLocationName');
			if (unitLocationNameIdx >= 0) ts.columnConfig.splice(unitLocationNameIdx, 1);
		}
		this.tableSettings = ts;

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getAuditLogs();
				})
			);

		this.getAuditLogs();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized) this.getAuditLogs();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getAuditLogs() {
		if (this.auditLogsData) {
			this.auditLogs = [...this.auditLogsData];
			this.isInitialized = true;
		} else {
			if (!this.dataSource || this.isDataSourceReady) {
				const filter: SearchFilter = {
					pageNumber: this.pageNumber,
					pageSize: this.pageSize,
					accounts: this.accountIds?.map((accountId: number) => {
						return { accountId: accountId };
					}),
					...this.dateRange,
					...this.searchFilter,
				};
				this.auditLogsService.api.getAuditLogs(filter).subscribe((sfr: SearchFilterResult<AuditLog>) => {
					this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber: sfr.pageNumber, pageSize: sfr.pageSize, totalCount: sfr.totalCount };
					this.auditLogs = sfr.results;
					this.isInitialized = true;
				});
			}
		}
	}

	editDevice(device: Unit) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = device.accountId;
		modal.componentInstance.unitId = device.unitId;
		modal.result.then(
			(_) => {
				this.getAuditLogs();
			},
			(_) => {}
		);
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getAuditLogs();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getAuditLogs();
	}

	downloadCsv() {
		const filter: SearchFilter = {
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			...this.dateRange,
			...this.searchFilter,
		};
		this.reportService.api.getAuditLogsCsv(filter).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting audit logs CSV.', error);
			},
		});
	}
}
