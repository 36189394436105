import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserMultiAuthTokenMode, UserMultiAuthTokenType } from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { AuthService, CoreServerSettingsService, MultiAuthService } from '@razberi-ui/api/cloud-api';

enum PageMode {
	TypeSelect = 0,
	CodeEntry = 1,
	ConfigureSMS = 2,
}

@Component({
	selector: 'app-root-monitor-cloud-auth-multi-factor-auth-modal',
	templateUrl: './multi-factor-auth-modal.component.html',
	styleUrls: ['./multi-factor-auth-modal.component.scss'],
})
export class MultiFactorAuthModalComponent {
	pageMode: PageMode = PageMode.TypeSelect;
	pageModeOptions: any = PageMode;
	selectedTokenType: UserMultiAuthTokenType = UserMultiAuthTokenType.Email;
	selectedTokenTypeOptions: any = UserMultiAuthTokenType;
	authenticationForm: UntypedFormGroup;
	smsConfigurationForm: UntypedFormGroup;
	tokenExists: boolean = false;
	userEmail: string;
	userPhone: string;
	userPhoneVerified: boolean = false;
	smsEnabled: boolean = false;

	constructor(
		private activeModal: NgbActiveModal,
		private readonly authService: AuthService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly alertMessageService: AlertMessageService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly multiAuthService: MultiAuthService
	) {}

	ngOnInit() {
		this.buildAuthenticationForm();
		this.buildSmsConfigurationForm();
		this.userEmail = this.authService.data.user.email.replace(/(\w{1})(.*)(\w{1})@(.*)/, '$1******$3@$4');
		this.userPhone = this.authService.data.user.phone?.replace(/(\d{1})(.*)(\d{4})/, '$1******$3');
		this.userPhoneVerified = this.authService.data.user.phone?.length > 0 && this.authService.data.user.phoneVerifiedTimestamp != null;
		this.settingsService.streams.settings$.subscribe((settings) => {
			this.smsEnabled = settings?.sms?.provider?.length > 0 ? true : false;
		});
		this.multiAuthService.api.multiAuthExists().subscribe((result) => {
			this.tokenExists = result;
		});
	}

	buildAuthenticationForm() {
		this.authenticationForm = this.formBuilder.group({
			authenticationCode: [null, [Validators.required, Validators.pattern('^[0-9]{6}$')]],
		});
	}

	buildSmsConfigurationForm() {
		this.smsConfigurationForm = this.formBuilder.group({
			phoneNumber: [null, [Validators.required, Validators.pattern('[- +()0-9]{6,}')]],
		});
	}

	sendToken() {
		this.multiAuthService.api.sendMultiAuth(this.selectedTokenType).subscribe((_) => {
			this.tokenExists = false;
			this.pageMode = PageMode.CodeEntry;
		});
	}

	configureSMS() {
		this.multiAuthService.api.configureSmsMultiAuth(this.smsConfigurationForm.controls.phoneNumber.value).subscribe((_) => {
			this.tokenExists = false;
			this.pageMode = PageMode.CodeEntry;
		});
	}

	verifyToken() {
		this.multiAuthService.api.verifyMultiAuth(this.authenticationForm.controls.authenticationCode.value).subscribe((token) => {
			this.tokenExists = false;
			if (token.mode === UserMultiAuthTokenMode.Configure) {
				this.authService.helpers.refresh(this.authService.data.authToken.refresh_token).subscribe((_) => {
					this.alertMessageService.clear();
					this.alertMessageService.success(`${UserMultiAuthTokenType[token.type]} was successfully configured for Multi Factor Authentication.`);
					this.activeModal.close({ success: true });
				});
			} else {
				this.alertMessageService.clear();
				this.alertMessageService.success('Multi Factor Authentication was successfully verified.');
				this.activeModal.close({ success: true });
			}
		});
	}

	close() {
		this.alertMessageService.info('Multi Factor Authentication was cancelled.');
		this.activeModal.close({ success: false });
	}
}
