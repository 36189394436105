import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { constants } from '../../shared.constants';

@Component({
	selector: 'shared-form-select-us-states',
	templateUrl: './form-select-us-states.component.html',
	styleUrls: ['./form-select-us-states.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FormSelectUsStatesComponent,
			multi: true,
		},
	],
})
export class FormSelectUsStatesComponent implements ControlValueAccessor {
	@Output() change: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild(NgSelectComponent) select: NgSelectComponent;

	propagateChange = (_: any) => {};
	propagateTouch = () => {};
	value: any;
	isReadonly: boolean = false;
	options: any = constants.usStates.map((s) => {
		return { value: s.abbreviation, label: s.name };
	});

	setValue(obj: any): void {
		this.value = obj;
	}

	writeValue(obj: any): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isReadonly = isDisabled;
	}

	onChange(value: any) {
		this.propagateTouch();
		this.propagateChange(value);
		this.change.emit();
	}
}
