<div *ngIf="settingsService.data.settings?.sms?.tropoSmsSettings == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.sms?.tropoSmsSettings != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="username" class="form-label">Username</label>
		<div ngbTooltip="The username to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="username" id="username" />
		<shared-input-validation-messages [control]="settingsForm.controls.username"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="password" class="form-label">Password</label>
		<div ngbTooltip="The password to use. Leave blank to keep the current password."
			 placement="right"
			 container="body"
			 class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="password" id="password" />
		<shared-input-validation-messages [control]="settingsForm.controls.password"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="outgoingNumber" class="form-label">Outgoing Number</label>
		<div ngbTooltip="The outgoing number to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="outgoingNumber" id="outgoingNumber" />
		<shared-input-validation-messages [control]="settingsForm.controls.outgoingNumber"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="internationalOutgoingNumber" class="form-label">International Outgoing Number</label>
		<div ngbTooltip="The international outgoing number to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="internationalOutgoingNumber" id="internationalOutgoingNumber" />
		<shared-input-validation-messages [control]="settingsForm.controls.internationalOutgoingNumber"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="textToken" class="form-label">Text Token</label>
		<div ngbTooltip="The text token to use." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="textToken" id="textToken" />
		<shared-input-validation-messages [control]="settingsForm.controls.textToken"></shared-input-validation-messages>
	</div>
</form>
