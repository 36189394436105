import { Component } from '@angular/core';
import { CloudApiConfigService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
	constructor(readonly configService: CloudApiConfigService) {}
}
