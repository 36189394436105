import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
	DataSearchConfiguration,
	CloudApiConfigService,
	CoreServerSettingsService,
	SearchFilter,
	SearchFilterResult,
	AuthService,
} from '@razberi-ui/api/cloud-api';
import { Account, AccountStatus, AccountType, UserRole } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, TableServerSidePagingConfig, TableSettings } from '@razberi-ui/shared';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { AccountService } from '../../../services/account.service';
import { ReportService } from '../../../services/report.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-table',
	templateUrl: './account-table.component.html',
	styleUrls: ['./account-table.component.scss'],
})
export class AccountTableComponent {
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() accountsData: Account[];
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	accounts: Account[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isInitialized: boolean = false;
	complianceEnabled: boolean = false;

	constructor(
		private readonly router: Router,
		private readonly alertMessageService: AlertMessageService,
		private readonly confirmService: ConfirmService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly accountService: AccountService,
		private readonly reportService: ReportService,
		private readonly configService: CloudApiConfigService,
		private readonly settingsService: CoreServerSettingsService
	) {}

	ngOnInit() {
		this.initialSearchFilter = {};
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showAccounts: true,
			showAccountTypes: this.authService.data.account.type === AccountType.Global,
			showAccountStatuses: true,
			showAccountSubscriptionTypes: true,
			showAccountSubscriptionStatuses: true,
		};

		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				this.complianceEnabled = settings?.compliance?.enablePrivacyPolicy === true || settings?.compliance?.enableResellerAgreement === true;
				this.buildTable();
			})
		);
	}

	buildTable() {
		const ts: TableSettings = {
			useAdvancedSearch: this.showSearch,
			useEdit: true,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.accountsData ? false : true,
			serverSidePagingAndFilterConfig: this.accountsData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'accountId',
			headerButtons: [],
			stickyHeaderButtons: [],
			columnConfig: [
				{
					primaryKey: 'name',
					header: 'Name',
					cellType: 'router-link',
					transform: (value, tableItem) => {
						const c: any = {
							text: value,
							route: ['/accounts', tableItem.accountId],
							subtexts: [],
							disabled:
								tableItem.status === AccountStatus.Pending ||
								(tableItem.isSelf === true && this.authService.helpers.userHasRole(UserRole.Administrator) == true),
						};
						if (tableItem.faviconUrl) c.image = `${this.configService.data.config.apiHost}${tableItem.faviconUrl}`;
						else c.icon = 'building';
						return c;
					},
				},
				{
					primaryKey: 'type',
					header: 'Type',
					hide: this.authService.data.account.type !== AccountType.Global,
					transform: (value, tableItem) => AccountType[value],
				},
				{
					primaryKey: 'location',
					header: 'Location',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return { icon: 'map-marker-alt', text: value };
					},
				},
				{
					primaryKey: 'subscriptionType',
					header: 'Subscription',
					cellType: 'account-subscription-status',
					transform: (value, tableItem) => {
						return {
							type: tableItem.subscriptionType ?? 0,
							status: tableItem.subscriptionStatus ?? 0,
							maxDevices: tableItem.subscriptionMaxDevices ?? 0,
							startDate: tableItem.subscriptionStartDate,
							endDate: tableItem.subscriptionEndDate,
							devices: tableItem.devices ?? 0,
						};
					},
				},
				{
					primaryKey: 'alerts',
					header: 'Alerts',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return { icon: 'bell', text: `${tableItem.openAlerts} open / ${tableItem.criticalAlerts} critical` };
					},
				},
				{
					primaryKey: 'providerAccountId',
					header: 'Managed By',
					hide: this.authService.data.account.type !== AccountType.Global,
					cellType: 'router-link',
					transform: (value, tableItem) => {
						if (value) {
							const c: any = { text: tableItem.providerAccountName, route: ['/accounts', value] };
							if (tableItem.providerAccountFaviconUrl)
								c.image = `${this.configService.data.config.apiHost}${tableItem.providerAccountFaviconUrl}`;
							else c.icon = 'building';
							return c;
						} else return { text: '--', disabled: true };
					},
				},
				{
					primaryKey: 'status',
					header: 'Status',
					cellType: 'account-status',
				},
			],
			editActions: (tableItem) => {
				return [
					{
						label: 'Edit',
						faIcon: 'edit',
						clickHandlerFunction: (params) => {
							this.editAccount(params);
						},
						enabled:
							(this.authService.data.account.type === AccountType.Global ||
								(this.authService.data.account.type === AccountType.Provider &&
									(this.authService.helpers.userHasRole(UserRole.Administrator) == true || !tableItem.isSelf))) &&
							tableItem.status !== AccountStatus.Pending,
					},
					{
						label: 'Delete',
						faIcon: 'trash',
						faIconColor: 'danger',
						clickHandlerFunction: (params) => {
							this.deleteAccount(params);
						},
						enabled:
							(this.authService.data.account.type === AccountType.Global ||
								(this.authService.data.account.type === AccountType.Provider &&
									this.authService.helpers.userHasRole(UserRole.Administrator) == true)) &&
							tableItem.status !== AccountStatus.Pending &&
							!tableItem.isSelf,
					},
				];
			},
		};
		if (this.showDownloadCsv && this.subscriptionService.helpers.isGlobalOrIsValid())
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (
			this.complianceEnabled === true &&
			this.authService.data.account.type === AccountType.Global &&
			this.authService.helpers.userHasRole(UserRole.Administrator) == true
		)
			ts.stickyHeaderButtons.push({
				label: 'User Compliance',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadUserCompliances();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getAccounts();
				},
			});
		this.tableSettings = ts;

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getAccounts();
				})
			);

		this.getAccounts();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized) this.getAccounts();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getAccounts() {
		if (this.accountsData) {
			this.accounts = [...this.accountsData];
			this.isInitialized = true;
		} else {
			const filter: SearchFilter = {
				pageNumber: this.pageNumber,
				pageSize: this.pageSize,
				...this.searchFilter,
			};
			this.accountService.api.getAccountsFilter(filter).subscribe((sfr: SearchFilterResult<Account>) => {
				this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber: sfr.pageNumber, pageSize: sfr.pageSize, totalCount: sfr.totalCount };
				this.accounts = sfr.results;
				this.isInitialized = true;
			});
		}
	}

	editAccount(account: any) {
		this.router.navigate(['/accounts', account.accountId]);
	}

	deleteAccount(account: any) {
		if (account.isSelf) {
			this.alertMessageService.error('Cannot delete self.');
			return;
		}

		if (account.accountId < 100) {
			this.alertMessageService.error('Cannot delete built-in accounts.');
			return;
		}

		if (account.status === AccountStatus.Pending) {
			this.alertMessageService.error('Cannot delete pending accounts.');
			return;
		}

		this.confirmService
			.confirm({
				title: 'Delete account?',
				text: `Are you sure you want to delete account "${account.name}"? Type the name of the account to confirm.`,
				passphraseTitle: `Account name: ${account.name}`,
				passphrase: account.name,
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(
				(_) => {
					this.accountService.api.deleteAccount(account.accountId).subscribe({
						next: (result) => {
							this.alertMessageService.success('Account deleted.');
							this.getAccounts();
						},
						error: (error) => {
							this.alertMessageService.error('Error deleting account.', error);
						},
					});
				},
				(_) => {}
			);
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getAccounts();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getAccounts();
	}

	downloadCsv() {
		this.reportService.api.getAccountsCsv({ ...this.searchFilter }).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting accounts CSV.', error);
			},
		});
	}

	downloadUserCompliances() {
		this.reportService.api.getUserComplianceReportCsvByFilter({ ...this.searchFilter }).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting user compliance CSV.', error);
			},
		});
	}
}
