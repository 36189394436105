// This base class contains common member variables and methods shared by derived classes.

export class SortableViewBase {
	displayOptions: any = {
		filterString: '',
		sortColumn: 'location',
		sortDecending: false,
		pageSize: 15,
		currentPage: 1,
		maxSize: 5,
	};

	constructor() {}

	sortArrowClass(column: string): string {
		let result: string = '';
		if (column === this.displayOptions.sortColumn) {
			result = 'float-end';
			if (this.displayOptions.sortDecending) result += 'arrow-down';
			else result += 'arrow-up';
		}
		return result;
	}

	// update the given display options based on sorting situation and return the new display options
	// (this is done when a new sort is requested)
	protected updateDisplayOptions(displayOptions: any, sortColumn: string, keepDecending: boolean): any {
		if (displayOptions.sortColumn.toLowerCase() === sortColumn.toLowerCase() && !keepDecending)
			displayOptions.sortDecending = !displayOptions.sortDecending;
		displayOptions.sortColumn = sortColumn;
		displayOptions.currentPage = 1;

		return displayOptions;
	}

	// sort given source items with the given display options and compare function
	protected getSortedItems(displayOptions: any, sourceItems: any[], stringCompareFn: (a: string, b: string, accending: boolean) => number): any[] {
		return sourceItems.sort((a: any, b: any) => {
			let sortField: string = displayOptions.sortColumn;
			// assuming 'sortColumn' is set to field name of the object; also assume we just need simple string compare
			return stringCompareFn(a[sortField], b[sortField], displayOptions.sortDecending);
		});
	}

	// select item list displayed on current page from the total items based on given display options
	protected selectPageItems(displayOptions: any, itemsAllPages: any[]) {
		let items: any[] = [];
		let start: number = (displayOptions.currentPage - 1) * displayOptions.pageSize;
		let end: number = start + displayOptions.pageSize - 1;
		if (end >= itemsAllPages.length) end = itemsAllPages.length - 1;
		for (let i = start; i <= end; i++) items.push(itemsAllPages[i]);
		return items;
	}
}
