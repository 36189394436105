import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CloudApiConstants, CloudApiConfigService, CoreServerSettingsService, AuthService } from '@razberi-ui/api/cloud-api';
import { NavService, PageConfigService, PageFooterConfiguration, UtilitiesService } from '@razberi-ui/shared';
import { LoginResult, LoginSettings } from '@razberi-ui/core/data-types';
import { LoginFormData } from '../../../data-types/login-form-data.model';

@Component({
	selector: 'app-root-monitor-cloud-auth-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	loginData: LoginFormData;
	isValid: boolean = false;
	ssoEnabled: boolean;
	ssoEnforced: boolean;
	hideSsoLoginButton: boolean;
	message: string = '';
	isSsoLogin: boolean;
	isLoaded: boolean;

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly navService: NavService,
		readonly configService: CloudApiConfigService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly utils: UtilitiesService,
		private readonly pageConfigService: PageConfigService
	) {}

	ngOnInit() {
		if (this.authService.data.isAuthenticated === true) {
			console.log('already logged in url', this.navService.data.authenticatedDefaultUrl);
			this.router.navigate([this.navService.data.authenticatedDefaultUrl]);
		}

		const errorParam: string = this.utils.helpers.getParameterByName('error');
		const tokenParam: string = this.utils.helpers.getParameterByName('token');
		if (errorParam) {
			const error: LoginResult = LoginResult[errorParam];
			this.handleLoginResult(error);
		} else if (tokenParam) {
			this.isSsoLogin = true;
			const refreshToken: string = this.utils.helpers.base64UrlDecode(tokenParam);
			this.authService.helpers.ssoLogin(refreshToken).subscribe((result: LoginResult) => {
				this.isLoaded = true;
				this.handleLoginResult(result);
			});
		}

		this.settingsService.api.getLoginSettings().subscribe((loginSettings: LoginSettings) => {
			this.ssoEnabled = loginSettings?.ssoEnabled ?? false;
			this.ssoEnforced = loginSettings?.ssoEnforced ?? false;
			this.hideSsoLoginButton = loginSettings?.hideSsoLoginButton ?? false;
			if (loginSettings != null && !this.isSsoLogin) this.isLoaded = true;
			this.setFooterConfig();
		});

		this.setFooterConfig();
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(loginData: LoginFormData) {
		this.loginData = loginData;
	}

	setFooterConfig() {
		const footerConfig: PageFooterConfiguration = {
			buttons: [],
			checkboxes: [],
		};

		if (this.isLoaded === true && this.ssoEnabled === true && this.ssoEnforced !== true && this.hideSsoLoginButton === true) {
			footerConfig.buttons.push({
				text: 'SSO',
				click: () => {
					this.ssoLogin();
				},
			});
		}

		this.pageConfigService.data.footerConfig = footerConfig;
	}

	login() {
		if (this.isValid) {
			if (this.ssoEnforced) {
				this.message = 'SSO login is enforced.';
				return;
			}
			this.message = null;
			this.authService.helpers
				.login(this.loginData.email, this.loginData.password, this.loginData.remember)
				.subscribe((result: LoginResult) => this.handleLoginResult(result));
		}
	}

	ssoLogin() {
		if (!this.ssoEnabled) {
			this.message = 'SSO login is not enabled.';
			return;
		}
		// redirect user to SSO login page
		window.location.href = `${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.auth.samlSignIn}`;
	}

	handleLoginResult(result: LoginResult) {
		if (result === LoginResult.Success) {
			let url: string = this.authService.data.redirectUrl ? this.authService.data.redirectUrl : this.navService.data.authenticatedDefaultUrl;
			this.authService.data.redirectUrl = null;
			this.router.navigate([url]);
		} else if (result === LoginResult.ChangePassword) {
			const token: string = this.authService.data.changePasswordToken;
			this.authService.data.changePasswordToken = null;
			this.router.navigate(['/reset-password', token]);
		} else {
			this.message = this.authService.helpers.getLoginResultMessage(result);
		}
	}

	reset() {
		this.router.navigate(['/reset-password']);
	}
}
