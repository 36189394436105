import { Component, Input, SimpleChanges } from '@angular/core';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { AccountStat, AccountSubscription, AccountSubscriptionStatus, AccountSubscriptionType } from '@razberi-ui/core/data-types';
import * as moment from 'moment';

@Component({
	selector: 'app-root-monitor-cloud-account-subscription-view',
	templateUrl: './account-subscription-view.component.html',
	styleUrls: ['./account-subscription-view.component.scss'],
})
export class AccountSubscriptionViewComponent {
	@Input() accountSubscription: AccountSubscription;
	@Input() accountStat: AccountStat;

	type: string;
	status: string;
	statusColor: string;

	constructor(readonly settingsService: CoreServerSettingsService) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.accountSubscription && changes.accountSubscription.currentValue) {
			const subscription: AccountSubscription = changes.accountSubscription?.currentValue ?? this.accountSubscription;
			const stat: AccountStat = changes.accountStat?.currentValue ?? this.accountStat;
			this.type = AccountSubscriptionType[subscription.type];
			this.status = AccountSubscriptionStatus[subscription.status];
			this.statusColor =
				subscription.status === AccountSubscriptionStatus.Active
					? 'success'
					: subscription.status === AccountSubscriptionStatus.Expiring || stat.unitTotal > subscription.maxDevices
					? 'warning'
					: subscription.status === AccountSubscriptionStatus.Expired
					? 'danger'
					: 'dark';
			if (subscription.type > AccountSubscriptionType.None) {
				if (subscription.status === AccountSubscriptionStatus.Expiring) {
					const days: number = moment.utc(subscription.endDate).diff(moment.utc().startOf('day'), 'days');
					this.status = `${AccountSubscriptionStatus[subscription.status]} in ${days} day${days !== 1 ? 's' : ''}`;
				} else if (stat.unitTotal > subscription.maxDevices) this.status = 'Too many devices';
			}
		}
	}
}
