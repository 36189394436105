import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[onScrollEnd]',
})
export class OnScrollEndDirective {
	@Input() set onScrollEnd(completeFunction: any) {
		this.completeFunction = completeFunction;
	}

	@HostListener('scroll', ['$event'])
	onScroll(event) {
		let tracker = event.target;
		let limit = tracker.scrollHeight - tracker.clientHeight;
		if (event.target.scrollTop >= limit - 5) {
			if (this.completeFunction != null) this.completeFunction();
		}
	}

	private completeFunction: any;

	constructor(private el: ElementRef) {}

	ngOnInit() {
		if (this.el.nativeElement.scrollHeight <= this.el.nativeElement.clientHeight) this.completeFunction();
	}
}
