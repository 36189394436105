<form [formGroup]="parametersForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="categories" class="form-label">Category</label>
		<div
			ngbTooltip="(Optional) Specify one or more update categories. Specify none for all updates."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<div *ngIf="readonly !== true">
			<shared-form-multi-select
				#categorySelect
				labelForId="categories"
				[items]="categoryChoices"
				[selected]="selectedCategories"
				(selectedChange)="onCategoriesSelect($event)"
				clearable="true"
				showBadge="true"
				[closeOnSelect]="false"></shared-form-multi-select>
		</div>
		<div *ngIf="readonly === true">
			<input type="text" class="form-control" [value]="getReadOnlyValue('categories')" [readonly]="readonly" />
		</div>
	</div>
	<div class="mb-3 col-12">
		<label for="excludeKBs" class="form-label">Exclude KBs</label>
		<div
			ngbTooltip="(Optional) Specify one or more Microsoft Knowledge Base (KB) article IDs to exclude. You can exclude multiple IDs using comma-separated values. Valid formats: KB9876543 or 9876543."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<input *ngIf="readonly === true" type="text" class="form-control" formControlName="excludeKBs" id="excludeKBs" [readonly]="readonly" />
		<input *ngIf="readonly !== true" type="text" class="form-control" id="excludeKBs" formControlName="excludeKBs" />
		<shared-input-validation-messages [control]="parametersForm.controls.excludeKBs"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<input *ngIf="readonly !== true" class="me-1" type="checkbox" formControlName="allowReboot" (change)="onAllowRebootChange($event)" />
		<input *ngIf="readonly === true" class="me-1" type="checkbox" formControlName="allowReboot" disabled="true" />
		<span class="ms-1">Allow Reboot</span>
		<div
			ngbTooltip="(Optional) Specify whether to reboot the instance after installing Windows Updates. The default value is True, which means that instances reboot if any updates are installed. Warning: If you specify False, then some updates might fail to install."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
	</div>
	<div class="mb-3 col-12">
		<button type="button" class="btn btn-link" (click)="launchWinUpdatePage()">Search Windows Update page using specified parameters</button>
	</div>
</form>
