import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Account, ManagedDevice, Network, Tag, Location, ManagedDeviceType, AccountType, AccountStatus, Unit } from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { LocationService } from '../../../services/location.service';
import { TagService } from '../../../services/tag.service';
import { Observable, map, mergeMap, of } from 'rxjs';
import { LocationModalComponent } from '../../locations/location-modal/location-modal.component';
import { TagModalComponent } from '../../tags/tag-modal/tag-modal.component';
import { AuthService, RegisterUnitDto, UpdateManagedDeviceDto, UpdateUnitDto } from '@razberi-ui/api/cloud-api';
import { UnitService } from '../../../services/unit.service';
import { ManagedDeviceService } from '../../../services/managed-device.service';
import { DeviceEditFormData } from '../../../data-types/device-edit-form-data.model';
import { AccountService } from '../../../services/account.service';

@Component({
	selector: 'app-root-monitor-cloud-device-edit-modal',
	templateUrl: './device-edit-modal.component.html',
	styleUrls: ['./device-edit-modal.component.scss'],
})
export class DeviceEditModalComponent {
	@Input() accountId?: number;
	@Input() network: Network;
	@Input() unitId?: number;
	@Input() managedDevice?: ManagedDevice;

	modalTitle: string;
	userAccountId: number;
	canEditAccount: boolean;
	accounts: Account[];
	locations: Location[];
	tags: Tag[];
	isValid: boolean = false;
	deviceEditData: DeviceEditFormData;
	deviceEditForm: DeviceEditFormData;

	constructor(
		private readonly modalService: NgbModal,
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly accountsService: AccountService,
		private readonly unitService: UnitService,
		private readonly managedDeviceService: ManagedDeviceService,
		private readonly locationService: LocationService,
		private readonly tagService: TagService
	) {}

	ngOnInit() {
		this.modalTitle = `${this.unitId > 0 ? 'Edit' : 'Register'} ${
			!this.managedDevice || this.managedDevice.type === ManagedDeviceType.Agent ? 'Agent' : 'Device'
		}`;
		this.userAccountId = this.authService.data.user.accountId;
		this.canEditAccount =
			(this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider) &&
			(!this.managedDevice || this.managedDevice.type === ManagedDeviceType.Agent);
		if (this.accountId && this.unitId) {
			this.accountsService.api
				.getAccounts()
				.pipe(
					mergeMap((accounts: Account[]) => {
						this.accounts = accounts.filter((a) => a.status !== AccountStatus.Pending && a.status !== AccountStatus.Deleted);
						if (this.accountId && this.unitId) return this.unitService.api.getUnit(this.accountId, this.unitId.toString());
						else return of(null);
					}),
					map((unit: Unit) => {
						if (unit != null && unit.serialNumber && unit.modelName) {
							if (this.managedDevice && this.managedDevice.type !== ManagedDeviceType.Agent) {
								let deviceFromApi = unit.managedDevices?.find((x) => x.managedDeviceId === this.managedDevice.managedDeviceId);
								return {
									serialNumber: deviceFromApi?.serialNumber,
									modelName: deviceFromApi?.modelName,
									name: deviceFromApi?.name,
									accountId: unit.accountId,
									locationId: deviceFromApi?.cloudLocationId,
									tagIds: deviceFromApi?.tags?.map((t) => t.tagId),
								};
							} else {
								return {
									serialNumber: unit.serialNumber,
									modelName: unit.modelName,
									name: unit.name,
									accountId: unit.accountId,
									locationId: unit.locationId,
									tagIds: unit.tags?.map((t) => t.tagId),
								};
							}
						} else {
							return null;
						}
					})
				)
				.subscribe({
					next: (deviceEditData: DeviceEditFormData) => {
						this.deviceEditData = deviceEditData;
						if (deviceEditData.accountId === this.userAccountId) this.onAccountChange(this.userAccountId);
					},
					error: (error) => {
						this.alertMessageService.error('Error loading device.', error);
					},
				});
		} else {
			this.accountsService.api.getAccounts().subscribe({
				next: (accounts: Account[]) => {
					this.accounts = accounts.filter((a) => a.status !== AccountStatus.Pending && a.status !== AccountStatus.Deleted);
					this.onAccountChange(this.userAccountId);
				},
				error: (error) => {
					this.alertMessageService.error('Error loading accounts.', error);
				},
			});
		}
	}

	onAccountChange(accountId: number) {
		const account = this.accounts.find((a) => a.accountId === accountId);
		this.locationService.api.getLocations(account.accountId).subscribe({
			next: (locations: Location[]) => {
				this.locations = locations;
			},
			error: (error) => {
				this.alertMessageService.error('Error loading account locations.', error);
			},
		});
		this.tagService.api.getTags(account.accountId).subscribe({
			next: (tags: Tag[]) => {
				this.tags = tags;
			},
			error: (error) => {
				this.alertMessageService.error('Error loading account tags.', error);
			},
		});
	}

	onNewLocation() {
		const modal = this.modalService.open(LocationModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = this.deviceEditForm.accountId;
		modal.result.then(
			(result) => {
				this.onAccountChange(this.deviceEditForm.accountId);
			},
			(_) => {}
		);
	}

	onNewTag() {
		const modal = this.modalService.open(TagModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = this.deviceEditForm.accountId;
		modal.result.then(
			(result) => {
				this.onAccountChange(this.deviceEditForm.accountId);
			},
			(_) => {}
		);
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(deviceEditForm: DeviceEditFormData) {
		this.deviceEditForm = deviceEditForm;
		if (this.canEditAccount !== true) this.deviceEditForm.accountId = this.accountId;
	}

	save() {
		let o: Observable<void>;
		if (this.accountId && this.unitId) {
			if (this.managedDevice != null && this.managedDevice.type) {
				if (this.managedDevice.type === ManagedDeviceType.Agent) {
					const d: UpdateUnitDto = { ...this.deviceEditForm };
					o = this.unitService.api.updateUnit(this.accountId, this.unitId, d);
				} else {
					const d: UpdateManagedDeviceDto = { locationId: this.deviceEditForm.locationId, tagIds: this.deviceEditForm.tagIds };
					o = this.managedDeviceService.api.updateManagedDevice(this.accountId, this.managedDevice.managedDeviceId, d);
				}
			} else {
				const d: UpdateUnitDto = { ...this.deviceEditForm };
				o = this.unitService.api.updateUnit(this.accountId, this.unitId, d);
			}
		} else {
			const d: RegisterUnitDto = { ...this.deviceEditForm };
			o = this.unitService.api.registerUnit(d);
		}
		o.subscribe({
			next: (_) => {
				this.alertMessageService.success(`Device ${this.unitId ? 'saved' : 'registered'}.`);

				if (this.network) {
					var el = this.network.managedDevices.find((x) => x.managedDeviceId === this.managedDevice.managedDeviceId);
					el.name = this.deviceEditForm.name;
					el.cloudLocationId = this.deviceEditForm.locationId;
					el.tags = this.tags.filter((x) => this.deviceEditForm.tagIds?.includes(x.tagId));

					this.activeModal.close(this.network);
				} else {
					this.activeModal.close();
				}
			},
			error: (error) => {
				this.alertMessageService.error(`Error ${this.unitId ? 'saving' : 'registering'} device.`, error);
			},
		});
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
