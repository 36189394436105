<shared-page-side-panel-outlet (onEdit)="onEdit()">
	<app-root-monitor-cloud-managed-device-side-panel
		*ngIf="config?.panelType === pageSidePanelTypeOptions.ManagedDevice"
		[onEdit$]="onEdit$"></app-root-monitor-cloud-managed-device-side-panel>
	<app-root-monitor-cloud-unit-side-panel
		*ngIf="config?.panelType === pageSidePanelTypeOptions.Unit"
		[onEdit$]="onEdit$"></app-root-monitor-cloud-unit-side-panel>
	<app-root-monitor-cloud-account-side-panel
		*ngIf="config?.panelType === pageSidePanelTypeOptions.Account"
		[onEdit$]="onEdit$"></app-root-monitor-cloud-account-side-panel>
</shared-page-side-panel-outlet>
