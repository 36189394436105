import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Unit } from '@razberi-ui/core/data-types';
import { UnitSelectorModalComponent } from './unit-selector-modal/unit-selector-modal.component';

@Component({
	selector: 'app-root-monitor-cloud-unit-selector',
	templateUrl: './unit-selector.component.html',
	styleUrls: ['./unit-selector.component.scss'],
})
export class UnitSelectorComponent {
	@Input() accountId;
	@Input() preSelectedUnits: Unit[] = [];
	@Input() readonly: boolean = false;
	@Output() selectedUnitsChanged: EventEmitter<Unit[]> = new EventEmitter<Unit[]>();

	selectedUnits: Unit[] = [];

	constructor(private readonly modalService: NgbModal) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.preSelectedUnits?.currentValue != null) {
			this.selectedUnits = changes.preSelectedUnits.currentValue;
		}
	}

	editSelectedUnits() {
		const modal = this.modalService.open(UnitSelectorModalComponent, { size: 'xl', backdrop: 'static', centered: true });
		modal.componentInstance.accountId = this.accountId;
		modal.componentInstance.preSelectedUnits = this.selectedUnits;
		modal.result.then(
			(result) => {
				this.selectedUnits = result?.selectedUnits || [];
				this.selectedUnitsChanged.next(this.selectedUnits);
			},
			(_) => {}
		);
	}
}
