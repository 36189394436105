import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LoginFormData } from '../../../data-types/login-form-data.model';
import { CloudApiConstants } from '@razberi-ui/api/cloud-api';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-auth-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
	@Input() autoFocus: boolean = true;
	@Input() hideRememberMe: boolean;
	@Input() reset$?: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<LoginFormData> = new EventEmitter<LoginFormData>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();
	loginForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private readonly el: ElementRef, private readonly fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.loginForm = this.fb.group({
			email: [null, [Validators.required, Validators.email]],
			password: [null, [Validators.required, Validators.minLength(6)]],
			remember: [false],
		});

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.loginForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.loginForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.loginForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		let email: string = localStorage.getItem(CloudApiConstants.storageKeys.email);
		if (email) {
			this.loginForm.get('email').setValue(email);
			this.loginForm.get('remember').setValue(true);
		}
		if (this.autoFocus) {
			let control: any;
			if (email) control = this.el.nativeElement.querySelector('[formControlName="password"]');
			else control = this.el.nativeElement.querySelector('[formControlName="email"]');
			if (control) control.focus();
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	submitForm() {
		if (this.loginForm.valid) this.submit.emit();
	}

	isInvalid(field: string) {
		return this.loginForm.get(field).touched && this.loginForm.get(field).invalid;
	}
}
