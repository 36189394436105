<div class="layout-card-header">
	<ng-container *ngIf="appConfig.key === appConfigKeys.MonitorCloud">
		<div class="justify-content-center w-100 mt-1 mb-2">
			<div class="text-center">
				<img class="logo-img ms-3" src="/assets/razberi/images/logos/logo.png" alt="logo" height="60px" />
				<h4 class="p-0 m-0 font-italic" style="color: #fee">Razberi {{ navService.data.appTitle }}</h4>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="appConfig.key !== appConfigKeys.MonitorCloud">
		<img src="assets/razberi/images/logos/razberi_24x24.png" class="me-3" />
		<div class="ml-3 title">
			<span class="me-2">{{ navService.data.appTitle }}{{ (navService.streams.navBreadcrumbs$ | async)?.length > 0 ? ' > ' : '' }}</span>
			<span *ngFor="let crumb of navService.streams.navBreadcrumbs$ | async; let i = index; let last = last">
				<a *ngIf="!last" [routerLink]="[crumb.url]">{{ crumb.title }}</a>
				<span *ngIf="!last" class="mx-2">&gt;</span>
				<span *ngIf="last">{{ crumb.title }}</span>
			</span>
		</div>
	</ng-container>
</div>
