<div class="d-flex flex-row align-items-center">
	<div *ngIf="isInitialized && accountsResult" class="mx-2">{{ pagingStatus }}</div>
	<div class="ms-auto me-2">
		<button type="button" class="btn btn-link px-1 py-0 mx-2" (click)="onUpdate()"><fa-icon icon="sync" class="me-1"></fa-icon>Refresh</button>
		<button *ngIf="showSerialNumberSearch" type="button" class="btn btn-link px-1 py-0 mx-2" (click)="showSerialSearch()">
			<fa-icon icon="search" class="me-1"></fa-icon>Global Search
		</button>
	</div>
	<div>
		<app-root-monitor-cloud-data-table-search-form
			*ngIf="showSearch === true && searchConfig != null && initialSearchFilter != null"
			[searchConfiguration]="searchConfig"
			[initialSearchFilter]="initialSearchFilter"
			(applyFilter)="onSearch($event)"></app-root-monitor-cloud-data-table-search-form>
	</div>
</div>
<div *ngIf="isInitialized && accountsResult && accountsResult.totalCount > 0" class="shared-accordion mt-1">
	<ngb-accordion #acc="ngbAccordion" [activeIds]="expandedAccountIds" (panelChange)="onPanelChange($event)">
		<ngb-panel *ngFor="let account of accountsResult.results; let rowIndex = index" [id]="account.accountId">
			<ng-template ngbPanelHeader let-opened="opened" class="test">
				<button ngbPanelToggle class="toggle-button" [ngClass]="{ opened: opened === true }">
					<fa-icon [icon]="opened === true ? 'chevron-down' : 'chevron-right'" size="md" class="me-2"></fa-icon>
					<div class="px-1 text-start d-flex flex-row align-items-center" style="width: 30%">
						<div class="me-1">
							<img *ngIf="account.faviconUrl" [src]="getImageUrl(account.faviconUrl)" class="icon-sm" />
							<fa-icon *ngIf="!account.faviconUrl" icon="building" size="lg"></fa-icon>
						</div>
						<div class="toggle-title" [ngClass]="{ opened: opened === true }">{{ account.name }}</div>
					</div>
					<div class="px-1 text-start d-flex flex-row align-items-center" style="width: 20%">
						<div class="me-1"><fa-icon icon="map-marker-alt" size="lg"></fa-icon></div>
						<div>{{ account.location }}</div>
					</div>
					<ng-container
						*ngIf="viewModeSelected === viewModeRef.Alerts"
						[ngTemplateOutlet]="headerAlerts"
						[ngTemplateOutletContext]="{ $implicit: account }"></ng-container>
					<ng-container
						*ngIf="viewModeSelected === viewModeRef.Tasks"
						[ngTemplateOutlet]="headerTasks"
						[ngTemplateOutletContext]="{ $implicit: account }"></ng-container>
					<div class="ms-auto" (click)="preventRowToggle($event)">
						<div ngbDropdown class="d-flex float-right">
							<button type="button" class="btn btn-ellipsys after-none" id="accordionActionsDropdown_{{ rowIndex }}" ngbDropdownToggle>
								<fa-icon icon="ellipsis-v" size="2x"></fa-icon>
							</button>
							<div ngbDropdownMenu class="accordion__menu dropdown-menu dropdown-menu-right">
								<button ngbDropdownItem (click)="getAccountDetails(account, 'details', false)" class="d-flex px-1">
									<fa-icon icon="eye" size="lg" class="text-primary mx-2"></fa-icon> View Account
								</button>
								<button ngbDropdownItem (click)="getAccountDetails(account, 'alerts', true)" class="d-flex px-1">
									<fa-icon icon="bell" size="lg" class="text-primary mx-2"></fa-icon> View Alerts
								</button>
							</div>
						</div>
					</div>
				</button>
			</ng-template>
			<ng-template ngbPanelContent>
				<div class="accordion-body-content">
					<ng-container
						[ngTemplateOutlet]="panelTemplate"
						[ngTemplateOutletContext]="{
							$implicit: account,
							hasSubscription: hasSubscription(account),
							refresh$: refresh$,
							onUpdate: onUpdate
						}">
					</ng-container>
				</div>
			</ng-template>
		</ngb-panel>
	</ngb-accordion>
	<div class="d-flex justify-content-between p-2">
		<ngb-pagination
			[ellipses]="true"
			[maxSize]="3"
			[collectionSize]="accountsResult.totalCount"
			[page]="accountsResult.pageNumber"
			[pageSize]="accountsResult.pageSize"
			[boundaryLinks]="true"
			(pageChange)="onPageChange($event)"></ngb-pagination>
		<label *ngIf="showPageSize" class="page-size">
			<div class="d-flex flex-nowrap align-items-center">
				show
				<select
					class="form-select form-select-sm ms-2 me-2"
					style="width: auto"
					name="pageSize"
					[ngModel]="pageSize"
					(ngModelChange)="onPageSizeChange($event)">
					<option [ngValue]="10">10</option>
					<option [ngValue]="20">20</option>
					<option [ngValue]="50">50</option>
				</select>
				per page
			</div>
		</label>
	</div>
</div>
<div *ngIf="isInitialized && accountsResult && accountsResult.totalCount === 0" class="p-5 font-italic text-center box-shadow">
	{{ emptyResultMessage?.length > 0 ? emptyResultMessage : 'No accounts found.' }}
</div>

<ng-template #headerAlerts let-account>
	<div class="px-1 text-start" style="width: 15%">
		<div>
			<fa-icon icon="server" size="md"></fa-icon>
			{{ account.unitTotal }} Agent{{ account.unitTotal !== 1 ? 's' : '' }}
		</div>
		<div style="font-size: 0.8rem; font-style: italic">
			<fa-icon icon="router" size="sm" class="me-1" style="margin-left: 0.1rem"></fa-icon>
			{{ account.managedDeviceTotal }} Network Device{{ account.managedDeviceTotal !== 1 ? 's' : '' }}
		</div>
	</div>
	<div class="px-1 text-start" style="width: 15%">
		<div *ngIf="hasSubscription(account) && account.accountId !== 1">
			<div><fa-icon icon="bell" size="lg"></fa-icon>{{ account.openAlerts }} open / {{ account.criticalAlerts }} critical</div>
		</div>
		<div *ngIf="!hasSubscription(account) || account.accountId === 1">--</div>
	</div>
	<div class="px-1 text-start" style="width: 20%">
		<div *ngIf="isGlobal && account.accountId !== 1 && account.providerAccountName" class="d-flex flex-row align-items-center">
			<div class="me-1">
				<img *ngIf="account.providerAccountFaviconUrl" [src]="getImageUrl(account.providerAccountFaviconUrl)" class="icon-sm" />
				<fa-icon *ngIf="!account.providerAccountFaviconUrl" icon="building" size="lg"></fa-icon>
			</div>
			<div>{{ account.providerAccountName }}</div>
		</div>
		<div *ngIf="!isGlobal || account.accountId === 1 || !account.providerAccountName">--</div>
	</div>
</ng-template>

<ng-template #headerTasks let-account>
	<div class="px-1 text-start" style="width: 50%">
		<div *ngIf="hasSubscription(account) && account.accountId !== 1">
			<fa-icon icon="tasks" size="md"></fa-icon> {{ account.manageTaskTotal || '0' }} Tasks
		</div>
		<div *ngIf="!hasSubscription(account) || account.accountId === 1">--</div>
	</div>
</ng-template>
