<ul ngbNav #panel="ngbNav" [activeId]="activeTabKey" class="nav-tabs page-side-panel-tabs">
	<li ngbNavItem="details">
		<a ngbNavLink>Details</a>
		<ng-template ngbNavContent>
			<ng-container [ngTemplateOutlet]="details"></ng-container>
		</ng-template>
	</li>
	<li *ngIf="hasSubscription === true" ngbNavItem="alerts">
		<a ngbNavLink>Alerts</a>
		<ng-template ngbNavContent>
			<ng-container [ngTemplateOutlet]="alerts"></ng-container>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="panel"></div>

<ng-template #details>
	<div class="side-panel-row">
		<div class="side-panel-label">Name</div>
		<div class="side-panel-value">{{ account?.name || '--' }}</div>
	</div>
	<div class="side-panel-row">
		<div class="side-panel-label">Location</div>
		<div class="side-panel-value">{{ account?.city || '--' }}</div>
	</div>
	<div class="side-panel-row">
		<div class="side-panel-label">Date Created</div>
		<div class="side-panel-value">{{ dateFormat(account?.createTimestamp) }}</div>
	</div>
	<div class="side-panel-row">
		<div class="side-panel-label">Last Login</div>
		<div class="side-panel-value">{{ dateFormat(account?.updateTimestamp) }}</div>
	</div>
	<div class="side-panel-row">
		<div class="side-panel-label">Primary Contact</div>
		<div class="side-panel-value">{{ account?.primaryUser.firstName || '--' }} {{ account?.primaryUser.lastName }}</div>
	</div>
	<div class="side-panel-row">
		<div class="side-panel-label">Email</div>
		<div class="side-panel-value">{{ account?.primaryUser.email || '--' }}</div>
	</div>
	<div class="side-panel-row">
		<div class="side-panel-label">Phone</div>
		<div class="side-panel-value">{{ account?.primaryUser.phone || '--' }}</div>
	</div>
</ng-template>

<ng-template #alerts>
	<ng-container *ngIf="(accountAlerts?.length || 0) == 0">
		<div class="pt-3">No alerts available.</div>
	</ng-container>
	<ng-container *ngIf="accountAlerts?.length > 0">
		alerts
		<!-- <app-alert-list
			[accountId]="managedDevice?.accountId"
			[alertItemsFromServer]="managedDevice?.alerts"
			(updateAlerts)="update($event)"
			(daysChanged)="daysChangedForNode($event, i)"></app-alert-list> -->
	</ng-container>
</ng-template>
