export enum RaidArrayType {
	Raid0 = 0,
	Raid1 = 1,
	Raid1E = 2,
	Raid3 = 3,
	Raid4 = 4,
	Raid5 = 5,
	Raid6 = 6,
	Raid10 = 10,
	Raid50 = 50,
	Jbod = 99,
}
