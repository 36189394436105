import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { AccountType, SettingsDashboard, UserRole } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageConfigService } from '@razberi-ui/shared';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-root-monitor-cloud-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
	settingsDashboard: SettingsDashboard;
	showSmtpTestEmailButton: boolean = true;

	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
		readonly settingsService: CoreServerSettingsService,
		private readonly pageToolbarService: PageConfigService,
		private readonly alertMessageService: AlertMessageService
	) {}

	ngOnInit(): void {
		if (this.authService.data.account.type !== AccountType.Global || this.authService.helpers.userHasRole(UserRole.Administrator) != true) {
			this.alertMessageService.warning('Access to the settings page is denied.', { autoClose: true, keepAfterRouteChange: true });
			this.router.navigate(['/']);
		}

		forkJoin({
			settings: this.settingsService.api.getFullSettings(),
			dashboard: this.settingsService.api.getSettingsDashboard(),
		}).subscribe((results) => {
			this.settingsDashboard = results?.dashboard;
		});
	}

	ngOnDestroy() {
		this.pageToolbarService.data.toolbarConfig = null;
	}

	sendTestEmail() {
		this.settingsService.api.testSmtp().subscribe((_) => {
			this.alertMessageService.success('The SMTP test email has been sent.');
		});
	}

	onSmtpFormEditing($event) {
		this.showSmtpTestEmailButton = !$event;
	}
}
