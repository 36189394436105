import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ToggleSwitchEvent } from '../../../data-types/toggle-switch-event.model';

@Component({
	selector: 'shared-cell-toggle-switch',
	templateUrl: './cell-toggle-switch.component.html',
	styleUrls: ['./cell-toggle-switch.component.scss'],
})
export class CellToggleSwitchComponent {
	@Input() data: any;
	@Output() toggle: EventEmitter<ToggleSwitchEvent> = new EventEmitter<ToggleSwitchEvent>();

	value: boolean = false;
	disabled: boolean = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		this.value = changes.data.currentValue.value;
		this.disabled = changes.data.currentValue.disabled;
	}

	onToggle($event: ToggleSwitchEvent) {
		this.value = $event.value;
		this.toggle.emit({
			value: this.value,
			cancel: () => {
				this.value = !$event.value;
				$event.cancel?.();
			},
		});
	}
}
