<form [formGroup]="passwordForm" (keyup.enter)="submitForm()" autocomplete="new-password" novalidate>
	<div class="row">
		<div class="col">
			<div *ngIf="isCurrentUser && requireExisting" class="mb-3">
				<label for="existing" class="form-label">Old Password <span class="text-primary">*</span></label>
				<input type="password" class="form-control" id="existing" formControlName="existing" minlength="6" required />
				<!-- <div *ngIf="hasErrors('existing')" class="invalid-feedback">
					<div *ngIf="hasError('existing', 'required')">Old password is required.</div>
					<div *ngIf="hasError('existing', 'minlength')">The password must be at least six characters long.</div>
				</div> -->
				<shared-input-validation-messages
					[control]="passwordForm.controls.existing"
					[clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
			</div>
			<div class="mb-3">
				<label for="new" class="form-label">New Password <span class="text-primary">*</span></label>
				<input type="password" class="form-control" id="new" formControlName="new" minlength="6" required autocomplete="new-password" />
				<!-- <div *ngIf="hasErrors('new')" class="invalid-feedback">
					<div *ngIf="hasError('new', 'required')">New password is required.</div>
					<div *ngIf="hasError('new', 'minlength')">The password must be at least six characters long.</div>
				</div> -->
				<shared-input-validation-messages [control]="passwordForm.controls.new" [clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
			</div>
			<div class="mb-3">
				<label for="confirm" class="form-label">Confirm Password <span class="text-primary">*</span></label>
				<input type="password" class="form-control" id="confirm" formControlName="confirm" required autocomplete="new-password" />
				<!-- <div *ngIf="hasErrors('confirm')" class="invalid-feedback">
					<div *ngIf="hasError('confirm', 'required')">Confirm password is required.</div>
					<div *ngIf="hasError('confirm', 'mustmatch')">Confirm password does not match.</div>
				</div> -->
				<shared-input-validation-messages
					[control]="passwordForm.controls.confirm"
					[clearMessagesOnEmptyErrors]="true"></shared-input-validation-messages>
			</div>
			<div *ngIf="!isCurrentUser" class="form-check">
				<input type="checkbox" class="form-check-input" id="mustChange" formControlName="mustChange" />
				<label class="form-check-label" for="mustChange">Must change on next login</label>
			</div>
		</div>
	</div>
</form>
