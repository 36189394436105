<div *ngIf="settingsService.data.settings?.fileStore == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<ng-container *ngIf="settingsService.data.settings?.fileStore != null">
	<div class="mb-3">
		<label for="tags">Selected Provider</label>
		<div class="mt-3">
			<div class="form-check">
				<input
					type="radio"
					id="providerLocal"
					name="providerLocal"
					class="form-check-input"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="selectedProvider"
					[value]="providers.LocalFileStoreProvider" />
				<label for="providerLocal" class="form-check-label">Local</label>
			</div>
			<div class="form-check">
				<input
					type="radio"
					id="providerS3"
					name="providerS3"
					class="form-check-input"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="selectedProvider"
					[value]="providers.S3FileStoreProvider" />
				<label for="providerS3" class="form-check-label">S3</label>
			</div>
		</div>
	</div>
	<hr class="w-100 my-4" />
	<ng-container *ngIf="selectedProvider === providers.LocalFileStoreProvider">
		<app-root-monitor-cloud-settings-local-filestore-form></app-root-monitor-cloud-settings-local-filestore-form>
	</ng-container>
	<ng-container *ngIf="selectedProvider === providers.S3FileStoreProvider">
		<app-root-monitor-cloud-settings-s3-filestore-form></app-root-monitor-cloud-settings-s3-filestore-form>
	</ng-container>
</ng-container>
