import { Injectable } from '@angular/core';
import { DashboardDto, CloudApiService, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { SubscriptionService } from './subscription.service';
import { AccountService } from './account.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account, CameraStream, RecordingServer, Unit } from '@razberi-ui/core/data-types';
import { DateRange } from '@razberi-ui/shared';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class DashboardService {
	private store: {
		isInitialized: boolean;
		isInitialized$: BehaviorSubject<boolean>;
		dashboardData: DashboardDto;
		dashboardData$: BehaviorSubject<DashboardDto>;
		availableAccounts: Account[];
		availableAccounts$: BehaviorSubject<Account[]>;
		selectedAccounts: Account[];
		selectedAccounts$: BehaviorSubject<Account[]>;
		defaultDateRangeDays: number;
		selectedDateRange: DateRange;
		selectedDateRange$: BehaviorSubject<DateRange>;
	} = {
		isInitialized: false,
		isInitialized$: new BehaviorSubject(undefined),
		dashboardData: undefined,
		dashboardData$: new BehaviorSubject(undefined),
		availableAccounts: undefined,
		availableAccounts$: new BehaviorSubject(undefined),
		selectedAccounts: undefined,
		selectedAccounts$: new BehaviorSubject(undefined),
		defaultDateRangeDays: 30,
		selectedDateRange: undefined,
		selectedDateRange$: new BehaviorSubject(undefined),
	};

	constructor(
		private readonly cloudApiService: CloudApiService,
		private readonly subscriptionService: SubscriptionService,
		private readonly accountsService: AccountService
	) {}

	get data() {
		const store = this.store;

		return {
			get dashboardData(): DashboardDto {
				return store.dashboardData;
			},
			set dashboardData(dashboardData: DashboardDto) {
				store.dashboardData = dashboardData;
				store.dashboardData$.next(store.dashboardData);
			},
			get availableAccounts(): Account[] {
				return store.availableAccounts;
			},
			set availableAccounts(availableAccounts: Account[]) {
				store.availableAccounts = availableAccounts;
				store.availableAccounts$.next(store.availableAccounts);
			},
			get selectedAccounts(): Account[] {
				return store.selectedAccounts;
			},
			set selectedAccounts(selectedAccounts: Account[]) {
				store.selectedAccounts = selectedAccounts;
				store.selectedAccounts$.next(store.selectedAccounts);
			},
			get defaultDateRangeDays(): number {
				return store.defaultDateRangeDays;
			},
			get defaultDateRange(): DateRange {
				return {
					startTimestamp: moment.utc().subtract(store.defaultDateRangeDays, 'day').startOf('d').toDate(),
					endTimestamp: null,
				};
			},
			get selectedDateRange(): DateRange {
				return store.selectedDateRange;
			},
			set selectedDateRange(selectedDateRange: DateRange) {
				store.selectedDateRange = selectedDateRange;
				store.selectedDateRange$.next(store.selectedDateRange);
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get isInitialized$(): Observable<boolean> {
				return store.isInitialized$.asObservable();
			},
			get dashboardData$(): Observable<DashboardDto> {
				return store.dashboardData$.asObservable();
			},
			get availableAccounts$(): Observable<Account[]> {
				return store.availableAccounts$.asObservable();
			},
			get selectedAccounts$(): Observable<Account[]> {
				return store.selectedAccounts$.asObservable();
			},
			get selectedDateRange$(): Observable<DateRange> {
				return store.selectedDateRange$.asObservable();
			},
		};
	}

	get api() {
		return {
			getDashboardData: (searchFilter: SearchFilter): void => {
				this.cloudApiService.dashboard.getDashboardData(searchFilter).subscribe((dashboardData: DashboardDto) => {
					Object.keys(dashboardData).forEach((key) => {
						if (dashboardData[key] < 0) dashboardData[key] = 0;
					});
					this.data.dashboardData = dashboardData;
				});
			},
			getCybersecurityDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.cloudApiService.dashboard.getCybersecurityDevices(searchFilter);
			},
			getMalwareProtectionDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.cloudApiService.dashboard.getMalwareProtectionDevices(searchFilter);
			},
			getCameraDefenseDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.cloudApiService.dashboard.getCameraDefenseDevices(searchFilter);
			},
			getOfflineDevices: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Unit>> => {
				return this.cloudApiService.dashboard.getOfflineDevices(searchFilter);
			},
			getCameraStreams: (searchFilter?: SearchFilter): Observable<SearchFilterResult<CameraStream>> => {
				return this.cloudApiService.dashboard.getCameraStreams(searchFilter);
			},
			getRecordingServers: (searchFilter?: SearchFilter): Observable<SearchFilterResult<RecordingServer>> => {
				return this.cloudApiService.dashboard.getRecordingServers(searchFilter);
			},
		};
	}

	get helpers() {
		return {
			initialize: (): void => {
				if (this.store.isInitialized === true) return;
				this.data.selectedDateRange = this.data.defaultDateRange;
				this.accountsService.api.getAccountsData().subscribe((accounts: Account[]) => {
					this.data.availableAccounts = accounts
						.filter((account: Account) => this.subscriptionService.helpers.isGlobalOrIsValid(account.subscription))
						.sort((a1: Account, a2: Account) => (a1.name > a2.name ? 1 : -1));
					this.store.isInitialized = true;
					this.store.isInitialized$.next(this.store.isInitialized);
				});
			},
			refresh: (): void => {
				if (this.store.isInitialized === true) {
					const filter = this.helpers.getSearchFilter();
					this.api.getDashboardData(filter);
				}
			},
			getSearchFilter: (): SearchFilter => {
				const sf: SearchFilter = {
					accounts: this.data.selectedAccounts?.map((account: Account) => {
						return { accountId: account.accountId };
					}),
				};
				if (this.data.selectedDateRange != null) {
					if (this.data.selectedDateRange.startTimestamp) sf.startTimestamp = this.data.selectedDateRange.startTimestamp;
					if (this.data.selectedDateRange.endTimestamp) sf.endTimestamp = this.data.selectedDateRange.endTimestamp;
				}
				return sf;
			},
		};
	}
}
