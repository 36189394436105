<form [formGroup]="parametersForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<label for="package" class="form-label">Package <span class="text-primary">*</span></label>
		<div
			ngbTooltip="Upload a zip package containing the scripts to execute. Max file upload size: 2 GB."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<div *ngIf="readonly !== true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div class="pb-2">
					<div class="form-check">
						<input
							type="radio"
							id="fileExisting"
							name="fileExisting"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.Existing"
							(change)="resetFileEvent()" />
						<label for="fileExisting" class="form-check-label">Use Previous Package</label>
					</div>
					<div class="form-check ms-3">
						<input
							type="radio"
							id="fileNew"
							name="fileNew"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.New"
							(change)="resetFileEvent()" />
						<label for="fileNew" class="form-check-label">Upload New Package</label>
					</div>
				</div>
				<div class="p-3 box-shadow">
					<ng-contaier *ngIf="fileMode === fileModeOptions.Existing">
						<div class="mb-3 font-italic">Use the existing package from the copied task:</div>
						<div *ngFor="let taskFile of taskFiles">
							<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">
								<input class="me-3" type="checkbox" readonly="readonly" [checked]="true" />
								{{ taskFile.fileName }}
							</button>
						</div>
					</ng-contaier>
					<ng-contaier *ngIf="fileMode === fileModeOptions.New">
						<div class="mb-3 font-italic">Upload a new package:</div>
						<shared-file-upload [reset$]="reset$" name="package" (fileSelected)="fileSelected($event)"></shared-file-upload>
					</ng-contaier>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) === 0">
				<shared-file-upload [reset$]="reset$" name="package" (fileSelected)="fileSelected($event)"></shared-file-upload>
			</ng-container>
		</div>
		<div *ngIf="readonly === true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div *ngFor="let taskFile of taskFiles">
					<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">{{ taskFile.fileName }}</button>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) == 0">
				<i>No files available</i>
			</ng-container>
		</div>
	</div>
	<div class="mb-3 col-12">
		<label for="command" class="form-label">Command <span class="text-primary">*</span></label>
		<div
			ngbTooltip="Specify the command to execute, including arguments, relative to the package root."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<input type="text" class="form-control" id="command" name="command" formControlName="command" required />
		<shared-input-validation-messages [control]="parametersForm.controls.command"></shared-input-validation-messages>
	</div>
	<div class="mb-3 col-12">
		<label for="outputFiles" class="form-label">Output Files</label>
		<div
			ngbTooltip="(Optional) Provide file glob patterns to specify output files to include in the task result."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="lg"></fa-icon>
		</div>
		<textarea class="form-control" id="outputFiles" name="outputFiles" formControlName="outputFiles"></textarea>
	</div>
</form>
