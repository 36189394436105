<div class="sidenav" [ngClass]="{ collapsed: isCollapsed }">
	<div class="sidenav-header">
		<div class="sidenav-header-menu">
			<fa-icon icon="bars" size="lg" (click)="toggleCollapse()"></fa-icon>
		</div>
		<ng-container *ngIf="!isCollapsed">
			<img *ngIf="accountLogo" [src]="accountLogo" class="sidenav-header-logo" />
			<img *ngIf="!accountLogo" src="assets/razberi/images/logos/razberi_24x24.png" />
			<span *ngIf="!accountLogo" class="ms-2 cursor-default">{{ navService.data.baseTitle }}</span>
		</ng-container>
	</div>
	<div class="sidenav-item-container">
		<div class="sidenav-item" *ngFor="let item of navService.streams.navItems$ | async" [ngClass]="{ error: navService.data.hasError === true }">
			<a *ngIf="item.show" (click)="setNavItem(item)" [ngClass]="{ active: item.active === true, error: navService.data.hasError === true }">
				<div class="sidenav-item-icon">
					<fa-icon *ngIf="!item.image" [icon]="item.icon" size="lg"></fa-icon>
					<img *ngIf="item.image" [src]="item.image" class="avatar icon-sm" />
				</div>
				<span *ngIf="!isCollapsed">{{ item.title }}</span>
			</a>
		</div>
	</div>
	<div class="sidenav-footer">
		<img *ngIf="!isCollapsed" src="assets/razberi/images/logos/logo.png" />
		<img *ngIf="isCollapsed" src="assets/razberi/images/logos/razberi_24x24.png" />
		<div class="d-flex align-items-center mt-2 cursor-default">
			<div *ngIf="!isCollapsed" class="text-secondary text-sm me-2">{{ navService.data.baseTitle }}</div>
			<div class="text-secondary text-sm">v{{ version }}</div>
		</div>
		<div class="d-flex align-items-center mt-2 cursor-default">
			<a href="https://acresecurity.com/legal/razberi-monitor-data-protection-statement" target="_blank" class="text-xs text-secondary">Privacy</a>
		</div>
	</div>
</div>
