<div *ngIf="unitData?.tabs?.length > 0">
	<ul ngbNav #panel="ngbNav" [activeId]="unitData.activeTabKey" class="nav-tabs page-side-panel-tabs">
		<li *ngFor="let tab of unitData.tabs" [ngbNavItem]="tab.id">
			<a ngbNavLink>{{ tab.title }}</a>
			<ng-template ngbNavContent *ngIf="tab.type === 'unit'">
				<div (click)="tab.data.Collapsed = !tab.data.Collapsed" class="device-title cursor-pointer w-100 mt-3 mb-3">
					<div *ngIf="tab.data.TypeLabel?.length > 0" class="device-type w-100">{{ tab.data.TypeLabel }}</div>
					<div class="d-inline-flex align-items-top w-100">
						<app-shared-monitor-common-cell-device-status
							[isParent]="tab.data.IsParent"
							[deviceType]="tab.data.Type"
							[status]="tab.data.Status"
							[showText]="false"></app-shared-monitor-common-cell-device-status>
						<div class="device-name ms-3 me-auto">{{ tab.data.Name }}</div>
						<fa-icon class="device-collapse" size="lg" [icon]="tab.data.Collapsed === true ? 'chevron-down' : 'chevron-up'"></fa-icon>
					</div>
				</div>
				<div [ngbCollapse]="tab.data.Collapsed">
					<div *ngFor="let prop of tab.data.Properties | slice: 0:5">
						<div class="side-panel-row" *ngIf="prop.FormattedValue != null">
							<div class="side-panel-label">{{ prop.Name }}</div>
							<div class="side-panel-value">{{ prop.FormattedValue }}</div>
						</div>
					</div>
					<ng-container *ngIf="tab.data.Properties?.length > 5">
						<ng-container *ngIf="tab.data.Preview !== true" (click)="tab.data.Preview = !tab.data.Preview">
							<div class="text-end mb-2">
								<button class="btn btn-outline-link" (click)="tab.data.Preview = !tab.data.Preview" style="color: #fff">
									<fa-icon icon="plus"></fa-icon> Show {{ tab.data.Properties?.length > 5 ? tab.data.Properties?.length - 5 : 0 }} more
								</button>
							</div>
						</ng-container>
						<ng-container *ngIf="tab.data.Preview === true">
							<div *ngFor="let prop of tab.data.Properties | slice: 5">
								<div class="side-panel-row" *ngIf="prop.FormattedValue != null && prop.DisplayField !== false">
									<div class="side-panel-label">{{ prop.Name }}</div>
									<div class="side-panel-value">{{ prop.FormattedValue }}</div>
								</div>
							</div>
							<div class="text-end mb-2">
								<button class="btn btn-outline-link" (click)="tab.data.Preview = !tab.data.Preview" style="color: #fff">
									<fa-icon icon="minus"></fa-icon> Show less
								</button>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<div *ngFor="let childDevice of tab.data.childData">
					<div (click)="childDevice.Collapsed = !childDevice.Collapsed" class="device-title cursor-pointer w-100 mb-3">
						<div *ngIf="childDevice.TypeLabel?.length > 0" class="device-type w-100">{{ childDevice.TypeLabel }}</div>
						<div class="d-inline-flex align-items-top w-100">
							<app-shared-monitor-common-cell-device-status
								[isParent]="childDevice.IsParent"
								[deviceType]="childDevice.Type"
								[status]="childDevice.Status"
								[showText]="false"></app-shared-monitor-common-cell-device-status>
							<div class="device-name ms-3 me-auto">{{ childDevice.Name }}</div>
							<fa-icon class="device-collapse" size="lg" [icon]="childDevice.Collapsed === true ? 'chevron-down' : 'chevron-up'"></fa-icon>
						</div>
					</div>
					<div [ngbCollapse]="childDevice.Collapsed" class="mb-2">
						<div *ngFor="let prop of childDevice.Properties">
							<div class="side-panel-row" *ngIf="prop.FormattedValue != null">
								<div class="side-panel-label">{{ prop.Name }}</div>
								<div class="side-panel-value">{{ prop.FormattedValue }}</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template ngbNavContent *ngIf="tab.type === 'alerts'">
				<app-root-monitor-cloud-alert-side-panel [accountId]="tab.accountId" [alertItemsFromServer]="tab.data" (updateAlerts)="updateAlerts($event)">
				</app-root-monitor-cloud-alert-side-panel>
			</ng-template>
			<ng-template ngbNavContent *ngIf="tab.type === 'updates'">
				<app-root-monitor-cloud-unit-updates-side-panel [updates]="tab.data"></app-root-monitor-cloud-unit-updates-side-panel>
			</ng-template>
			<ng-template ngbNavContent *ngIf="tab.type === 'managed'">
				<div class="mt-3">
					<ng-container *ngIf="(tab.data?.length || 0) == 0">
						<div class="mt-4">No network devices were found.</div>
					</ng-container>
					<ng-container *ngFor="let device of tab.data">
						<div class="mb-2">
							<div class="d-inline-flex align-items-top w-100 cursor-pointer" (click)="device.expanded = !device.expanded">
								<app-shared-monitor-common-cell-device-status
									[device]="device"
									[showText]="false"></app-shared-monitor-common-cell-device-status>
								<div class="device-name ms-3 me-auto">
									<div>{{ device.name || 'Unknown Device' }}</div>
									<div class="text-secondary font-thick">
										{{ device.typeFormatted || 'Unknown Type' }}
									</div>
								</div>
								<fa-icon class="device-collapse" size="lg" [icon]="device.expanded !== true ? 'chevron-down' : 'chevron-up'"></fa-icon>
							</div>
							<div *ngIf="device.expanded === true" class="mt-2">
								<app-root-monitor-cloud-managed-device-details-side-panel
									[managedDevice]="device"></app-root-monitor-cloud-managed-device-details-side-panel>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="panel"></div>
</div>
