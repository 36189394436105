import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountType } from '@razberi-ui/core/data-types';
import { Subscription } from 'rxjs';
import { SetupService } from '../../../services/setup.service';
import { CloudApiConfigService } from '@razberi-ui/api/cloud-api';

enum modalState {
	typeSelect = 0,
	benefitsDisplay = 1,
}

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-register-benefits-modal',
	templateUrl: './register-benefits-modal.component.html',
	styleUrls: ['./register-benefits-modal.component.scss'],
})
export class RegisterBenefitsModalComponent {
    subscriptions: Subscription[] = [];
	selectedAccountType: AccountType;
	accountTypeOptions: any = AccountType;
	benefits: string;

	constructor(
        private activeModal: NgbActiveModal,
        private readonly setupService: SetupService,
        readonly configService: CloudApiConfigService
    ) {}

	ngOnInit(): void {
		this.selectedAccountType = AccountType.Provider;
		this.selectType(this.selectedAccountType);
	}

	selectType(value: AccountType): void {
		this.selectedAccountType = value;

		if (value === AccountType.Customer) {
			this.subscriptions.push(
				this.setupService.api.getBenefitsCustomer().subscribe((result) => {
					this.benefits = result;
				})
			);
		} else if (value === AccountType.Provider) {
			this.subscriptions.push(
				this.setupService.api.getBenefitsProvider().subscribe((result) => {
					this.benefits = result;
				})
			);
		}
	}

	action() {}

	close(): void {
		this.activeModal.close({
			data: this.selectedAccountType,
		});
	}

	cancel(): void {
		this.activeModal.close({
			data: AccountType.Customer,
		});
	}
}
