import { Component, EventEmitter, Output } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { CoreSearchItem } from '@razberi-ui/shared';
import { Account, AccountType } from '@razberi-ui/core/data-types';
import { Subscription } from 'rxjs';
import { ReportService } from '../../../services/report.service';
import { NGXLogger } from 'ngx-logger';
import { AuthService, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import * as moment from 'moment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-dashboard-toolbar-controls',
	templateUrl: './dashboard-toolbar-controls.component.html',
	styleUrls: ['./dashboard-toolbar-controls.component.scss'],
})
export class DashboardToolbarControlsComponent {
	@Output() paramsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

	subscriptions: Subscription = new Subscription();
	availableAccounts: CoreSearchItem<Account>[] = [];
	selectedAccounts: CoreSearchItem<Account>[] = [];
	isCustomer: boolean = true;
	daysSelected: number;

	constructor(
		readonly dashboardService: DashboardService,
		private readonly authService: AuthService,
		private readonly reportService: ReportService,
		private readonly logger: NGXLogger,
		readonly settingsService: CoreServerSettingsService
	) {}

	ngOnInit() {
		this.daysSelected = this.dashboardService.data.defaultDateRangeDays;
		this.isCustomer = this.authService.data.account.type == AccountType.Customer;

		this.subscriptions.add(
			this.dashboardService.streams.availableAccounts$.subscribe((accounts: Account[]) => {
				this.availableAccounts =
					accounts?.map((account: Account) => {
						return { value: account, label: account.name };
					}) ?? [];
				this.selectedAccounts = [...this.availableAccounts];
				if (this.authService.data.account.type === AccountType.Global) {
					this.selectedAccounts = this.selectedAccounts.filter(
						(accountItem: CoreSearchItem<Account>) =>
							accountItem.value.type === AccountType.Global && accountItem.value.name.indexOf('Test Automation') === -1
					);
				}
				this.dashboardService.data.selectedAccounts = this.selectedAccounts.map((item) => item.value);
			})
		);
	}

	onSelectedAccountsChange($event: any) {
		// this.dashboardService.data.selectedAccounts = $event;
		this.paramsChanged.emit();
	}

	onDateRangeSelect($event: any) {
		const dateRange = {
			startTimestamp: this.daysSelected === -1 ? null : moment().utc().startOf('day').subtract(this.daysSelected, 'day').toDate(),
			endTimestamp: null,
		};
		this.dashboardService.data.selectedDateRange = dateRange;
		this.paramsChanged.emit();
	}

	downloadReport() {
		const accountIds: number[] = this.dashboardService.data.selectedAccounts?.map((a) => a.accountId) ?? [];
		this.reportService.api.getAccountStatusMultiReportPdf(accountIds).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.logger.error('Error getting account report.', error);
			},
		});
	}
}
