import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { Observable, Subject } from 'rxjs';
import { SubscriptionService } from '../../services/subscription.service';
import { AccountType, UserRole } from '@razberi-ui/core/data-types';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
	canManageAccounts: boolean = false;
	groupByAccount: boolean = false;
	showProfilesButton: boolean = false;
	isInitialized: boolean = false;
	refreshSubject: Subject<void> = new Subject<void>();
	refresh$: Observable<void> = this.refreshSubject.asObservable();

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly pageConfigService: PageConfigService,
		private readonly subsctiptionService: SubscriptionService
	) {}

	ngOnInit() {
		this.canManageAccounts = this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider;
		this.groupByAccount = this.authService.data.account.type === AccountType.Global;
		this.showProfilesButton =
			this.subsctiptionService.helpers.isGlobalOrIsValid(this.authService.data.account.subscription) &&
			this.authService.helpers.userHasRole(UserRole.Administrator) == true;
		if (this.canManageAccounts || this.showProfilesButton) {
			const toolbarConfig: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
			if (this.canManageAccounts)
				toolbarConfig.checkboxes.push({
					text: 'Group by account',
					icon: 'layer-group',
					isChecked: this.groupByAccount,
					change: (checked: boolean) => {
						this.onGroupByAccountChange(checked);
					},
				});
			if (this.showProfilesButton === true)
				toolbarConfig.buttons.push({
					text: 'Alert Profiles',
					icon: 'cogs',
					color: 'primary',
					click: () => this.router.navigate(['admin', 'alert-profiles']),
				});
			this.pageConfigService.data.toolbarConfig = toolbarConfig;
		}
		this.isInitialized = true;
	}

	onGroupByAccountChange(checked: boolean) {
		this.groupByAccount = checked;
	}

	onUpdate() {
		this.refreshSubject.next();
	}
}
