import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { SmsSettingsProvider } from '@razberi-ui/core/data-types';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-settings-sms-form',
	templateUrl: './settings-sms-form.component.html',
	styleUrls: ['./settings-sms-form.component.scss'],
})
export class SettingsSmsFormComponent implements OnInit {
	subscription: Subscription = new Subscription();
	providers: any = SmsSettingsProvider;
	selectedProvider: SmsSettingsProvider;

	constructor(readonly settingsService: CoreServerSettingsService) {}

	ngOnInit(): void {
		this.subscription.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				this.selectedProvider =
					settings != null
						? this.settingsService.helpers.parseSmsSettingsProvider(this.settingsService.data.settings?.sms?.provider)
						: SmsSettingsProvider.TwilioSmsProvider;
			})
		);
	}
}
