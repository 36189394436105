import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSearchConfiguration, CoreServerSettingsService, SearchFilter, AuthService } from '@razberi-ui/api/cloud-api';
import { Account, AccountType, ManageTask, ManageTaskExportRequest, ManageTaskStatus, Unit } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, DateRange, TableItemSelectMode, TableServerSidePagingConfig, TableSettings } from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged, forkJoin } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { ManageService } from '../../../services/manage.service';
import { ReportService } from '../../../services/report.service';
import { DashboardService } from '../../../services/dashboard.service';
import * as moment from 'moment';
import { SelectedUnitsListModalComponent } from '../../units/selected-units-list-modal/selected-units-list-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-table',
	templateUrl: './manage-task-table.component.html',
	styleUrls: ['./manage-task-table.component.scss'],
})
export class ManageTaskTableComponent {
	@Input() accountIds: number[];
	@Input() taskStatusIds: number[];
	@Input() dateRange: DateRange;
	@Input() showAccount: boolean = false;
	@Input() showRefresh: boolean = false;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() sortAsc: boolean = true;
	@Input() tasksData: ManageTask[];
	@Input() devicesData: Unit[];
	@Input() dataSource: string;
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	tasks: ManageTask[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isDataSourceReady: boolean = false;
	isInitialized: boolean = false;

	constructor(
		private readonly router: Router,
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly manageService: ManageService,
		private readonly reportService: ReportService,
		private readonly dashboardService: DashboardService,
		private readonly confirmService: ConfirmService,
		private readonly settingsService: CoreServerSettingsService
	) {}

	ngOnInit() {
		if (this.dataSource?.startsWith('dashboard')) {
			this.subscriptions.add(
				this.dashboardService.streams.selectedAccounts$.pipe(distinctUntilChanged()).subscribe((accounts: Account[]) => {
					if (accounts) {
						this.accountIds = accounts.map((account: Account) => account.accountId);
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getTasks();
					}
				})
			);
			this.subscriptions.add(
				this.dashboardService.streams.selectedDateRange$.pipe(distinctUntilChanged()).subscribe((dateRange: DateRange) => {
					if (dateRange) {
						this.dateRange = dateRange;
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getTasks();
					}
				})
			);
		} else {
			this.initialSearchFilter = {
				startTimestamp: moment().startOf('day').subtract(30, 'days').toDate(),
				manageTaskStatuses: this.taskStatusIds?.map((id) => ManageTaskStatus[ManageTaskStatus[id]]),
			};
			if (
				!this.accountIds?.length &&
				this.authService.data.account.type === AccountType.Global &&
				this.settingsService.data.settings?.cloudUI?.preloadSearchFilterAccounts === true
			)
				this.initialSearchFilter.accounts = [1, 2, 3, 4, 5].map((accountId: number) => {
					return { accountId: accountId };
				});
		}
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showDateRange: !this.dataSource,
			showAccounts: this.showAccount === true && this.dataSource?.startsWith('dashboard') !== true,
			showManageTaskTypes: true,
			showManageTaskStatuses: true,
			forSubscribedAccounts: true,
		};

		const ts: TableSettings = {
			useAdvancedSearch: this.showSearch,
			useEdit: true,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.tasksData ? false : true,
			serverSidePagingAndFilterConfig: this.tasksData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'manageTaskId',
			columnConfig: [
				{ primaryKey: 'accountName', header: 'Account', disableSort: true },
				{
					primaryKey: 'name',
					header: 'Name',
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return { text: value, tooltip: { text: `Task Id: ${tableItem.manageTaskGuid}`, placement: 'right' } };
					},
					clickHandlerFunction: (params) => this.viewTaskDetails(params),
				},
				{ primaryKey: 'manageTaskTypeName', header: 'Type', disableSort: true },
				{ primaryKey: 'status', header: 'Status', useForSearch: true, cellType: 'manage-task-status' },
				{
					primaryKey: 'executeAt',
					header: 'Started',
					disableSort: true,
					transform: (value, tableItem) => this.setStartedTimestamp(tableItem.startTimestamp ?? tableItem.executeAt),
				},
				// { primaryKey: 'executeAt', header: 'Start At', disableSort: true, transform: (value, tableItem) =>  moment(value).isValid() ? moment(value).format('MM/DD/YY @ HH:mm') : '--' },
				// { primaryKey: 'startTimestamp', header: 'Started', disableSort: true, transform: (value, tableItem) => this.getTimeDiff(tableItem.executeAt, tableItem.startTimestamp, true) },
				{
					primaryKey: 'completeTimestamp',
					header: 'Run Time',
					disableSort: true,
					transform: (value, tableItem) => this.getTimeDiff(tableItem.startTimestamp, tableItem.completeTimestamp, false),
				},
				{
					primaryKey: 'devices',
					header: 'Targets',
					disableSort: true,
					useForSearch: true,
					cellType: 'link-button',
					transform: (_, tableItem) => {
						return { text: `${tableItem.units?.length || 0} devices`, icon: 'server' };
					},
					clickHandlerFunction: (params) => this.showMatchingDevicesModal(params),
				},
				{ primaryKey: 'manageTaskInstances', header: 'Devices', cellType: 'manage-task-instance-summary', disableSort: true },
			],
			headerButtons: [
				{
					label: 'Export Selected',
					faIcon: 'download',
					clickHandlerFunction: (params) => {
						this.exportTasks(params.value);
					},
				},
			],
			stickyHeaderButtons: [],
			editActions: (tableItem) => {
				return [
					{
						label: 'View Task Details',
						faIcon: 'info-circle',
						clickHandlerFunction: (params) => {
							this.viewTaskDetails(params);
						},
					},
					{
						label: 'Export Task',
						faIcon: 'download',
						clickHandlerFunction: (params) => {
							this.exportTasks([params]);
						},
					},
					{
						label: 'Copy Task',
						faIcon: 'copy',
						clickHandlerFunction: (params) => {
							this.router.navigate(['manage/task/copy', params.manageTaskId, 'account', params.accountId]);
						},
						enabled:
							tableItem.manageTaskId > 0 &&
							this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user),
					},
					{
						label: 'Cancel Task',
						faIcon: 'ban',
						clickHandlerFunction: (params) => {
							this.cancelTask(params);
						},
						enabled:
							tableItem.status === ManageTaskStatus.Scheduled &&
							this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user),
					},
				];
			},
		};

		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getTasks();
				},
			});
		if (!this.showAccount || (this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)) {
			const accountNameIdx = ts.columnConfig.findIndex((col) => col.primaryKey === 'accountName');
			if (accountNameIdx >= 0) ts.columnConfig.splice(accountNameIdx, 1);
		}
		this.tableSettings = ts;

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getTasks();
				})
			);

		this.getTasks();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized) this.getTasks();
	}

	setStartedTimestamp(value) {
		return moment(value).isValid() ? moment(value).format('MM/DD/YY @ HH:mm') : '--';
	}

	getTasks() {
		if (this.tasksData) {
			this.tasks = [...this.tasksData];
			this.tableSettings.itemSelectMode = this.tasks?.length > 1 ? TableItemSelectMode.Multi : TableItemSelectMode.None;
			this.isInitialized = true;
		} else {
			const filter: SearchFilter = {
				pageNumber: this.pageNumber,
				pageSize: this.pageSize,
				sortAsc: this.sortAsc,
				accounts: this.accountIds?.map((accountId: number) => {
					return { accountId: accountId };
				}),
				manageTaskStatuses: this.taskStatusIds?.map((taskStatusId) => {
					return taskStatusId as ManageTaskStatus;
				}),
				...this.dateRange,
				...this.searchFilter,
			};

			forkJoin({
				tasks: this.manageService.api.getTasks(filter),
			}).subscribe({
				next: (data) => {
					let { pageNumber, pageSize, totalCount, results } = data.tasks;
					this.tasks = results;
					this.tableSettings.itemSelectMode = this.tasks?.length > 1 ? TableItemSelectMode.Multi : TableItemSelectMode.None;
					this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber, pageSize, totalCount };
					this.isInitialized = true;
				},
				error: (error) => {
					this.alertMessageService.error('Error getting alert profiles.', error);
				},
			});
		}
	}

	getTimeDiff(a: Date, b: Date, showComparer: boolean) {
		var m1 = moment(a).isValid() ? moment(a) : null;
		var m2 = moment(b).isValid() ? moment(b) : null;
		if (m1 == null || m2 == null) return '--';
		var comparerer = m1.isBefore(m2) ? '+' : '';
		var duration = moment.duration(m2.diff(m1));
		if (duration.isValid() !== true) return '--';
		var days = duration.asDays();
		var hours = duration.asHours();
		var mins = duration.asMinutes();
		var secs = duration.asSeconds();
		if (days > 1 || days < -1) {
			var value = `${showComparer === true ? comparerer + ' ' : ''}${Math.floor(days)} day${Math.floor(days) == 1 ? '' : 's'}`;
			var remainder = hours % 24;
			if (remainder >= 1 || remainder <= -1) {
				value = `${value} ${Math.floor(remainder)} hr${Math.floor(remainder) == 1 ? '' : 's'}`;
			}
			return value;
		}

		if (hours > 1 || hours < -1) {
			var value = `${showComparer === true ? comparerer + ' ' : ''}${Math.floor(hours)} hr${Math.floor(hours) == 1 ? '' : 's'}`;
			var remainder = mins % 60;
			if (remainder >= 1 || remainder <= -1) {
				value = `${value} ${Math.floor(remainder)} min${Math.floor(remainder) == 1 ? '' : 's'}`;
			}
			return value;
		}

		if (mins > 1 || mins < -1) return `${showComparer === true ? comparerer + ' ' : ''}${Math.floor(mins)} min${Math.floor(mins) == 1 ? '' : 's'}`;

		return `${showComparer === true ? comparerer + ' ' : ''}${Math.floor(secs)} sec${Math.floor(secs) == 1 ? '' : 's'}`;
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getTasks();
		this.dashboardService.helpers.refresh();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getTasks();
		this.dashboardService.helpers.refresh();
	}

	downloadCsv() {
		const filter: SearchFilter = {
			pageNumber: this.pageNumber,
			pageSize: this.pageSize,
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			manageTaskStatuses: this.taskStatusIds?.map((taskStatusId) => {
				return taskStatusId as ManageTaskStatus;
			}),
			...this.dateRange,
			...this.searchFilter,
		};
		this.manageService.api.getManageTasksCsv(filter).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting tasks CSV.', error);
			},
		});
	}

	showMatchingDevicesModal(task: ManageTask): void {
		const modalRef = this.modalService.open(SelectedUnitsListModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modalRef.componentInstance.devices = task.units;
	}

	viewTaskDetails(task: ManageTask) {
		this.router.navigate(['manage', 'task', task.manageTaskId, 'account', task.accountId]);
	}

	cancelTask(task: ManageTask) {
		this.confirmService
			.confirm({
				title: 'Cancel Task?',
				text: `Are you sure you want to cancel task '${task.name}' / ${task.manageTaskGuid}?`,
				icon: 'ban',
				confirmButtonText: 'Cancel Task',
				confirmButtonColor: 'primary',
				confirmButtonIcon: 'ban',
			})
			.then(
				(_) => {
					this.manageService.api.cancelTask(task.accountId, task.manageTaskId).subscribe({
						next: (result) => {
							this.alertMessageService.success(`Task ${task.manageTaskGuid} cancelled.`);
							this.getTasks();
						},
						error: (error) => {
							this.alertMessageService.error('Error cancelling task.', error);
						},
					});
				},
				(_) => {}
			);
	}

	exportTasks(tasks: ManageTask[]) {
		const requests: ManageTaskExportRequest[] = [];
		tasks.forEach((obj) => {
			const accountId: number = obj['accountId'];
			let request = requests.find((r) => r.accountId === accountId);
			if (request == null) {
				request = { accountId: accountId, taskInstanceGroups: [] };
				requests.push(request);
			}
			const clone = { ...obj };
			clone.manageTaskInstances = null; // reset here (in case stale), so all instances are included in the export
			const taskInstanceGroups = this.manageService.helpers.buildTaskInstanceGroups([clone]);
			request.taskInstanceGroups = { ...request.taskInstanceGroups, ...taskInstanceGroups };
		});
		this.manageService.api.getManageTasksExport(requests).subscribe((result) => this.reportService.helpers.saveAsFile(result));
	}
}
