import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordFormData } from '../../../data-types/password-form-data.model';
import { EmailFormData } from '../../../data-types/email-form-data.model';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
	emailData: EmailFormData;
	isEmailValid: boolean = false;
	passwordData: PasswordFormData;
	isPasswordValid: boolean = false;
	token: string = null;
	status: number = 0; // 0: loading; 1: email (no token); 2: email success; 3: password (token); 4: password success; 5: error
	message: string;
	version: string;

	constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly authService: AuthService) {}

	ngOnInit() {
		if (this.authService.data.isAuthenticated) this.router.navigate(['/']);

		this.token = this.activatedRoute.snapshot.paramMap.get('token');
		if (this.token) {
			this.authService.userEmailTokens.verifyResetPasswordToken(this.token).subscribe({
				next: (_) => {
					this.status = 3;
				},
				error: (_) => {
					this.message = 'The link to reset your password has expired.';
					this.status = 5;
				},
			});
		} else this.status = 1;
	}

	onEmailIsValid(isValid: boolean) {
		this.isEmailValid = isValid;
	}

	onEmailChanges(emailData: EmailFormData) {
		this.emailData = emailData;
	}

	onPasswordIsValid(isValid: boolean) {
		this.isPasswordValid = isValid;
	}

	onPasswordChanges(passwordData: PasswordFormData) {
		this.passwordData = passwordData;
	}

	email() {
		if (this.isEmailValid) {
			this.message = null;
			this.authService.userEmailTokens.requestResetPasswordToken(this.emailData).subscribe({
				next: (_) => {
					this.status = 2;
				},
				error: (_) => {
					this.message = 'There was an error sending the reset password email.';
					this.status = 5;
				},
			});
		}
	}

	tryAgain() {
		this.message = null;
		this.status = 1;
	}

	set() {
		if (this.isPasswordValid) {
			this.message = null;
			this.authService.userEmailTokens.finalizeResetPasswordToken(this.token, this.passwordData.new).subscribe({
				next: (_) => {
					this.status = 4;
				},
				error: (_) => {
					this.message = 'Your password could not be set.';
					this.status = 5;
				},
			});
		}
	}

	login() {
		this.router.navigate(['/login']);
	}

	reset() {
		this.router.navigate(['/reset-password']);
	}
}
