<div class="d-flex">
	<ng-container *ngIf="isDisabled !== true && readonly !== true">
		<div class="input-group d-flex flex-column">
			<label *ngIf="label?.length > 0" for="formControlName" class="form-label">{{ label }} <span class="text-primary">*</span></label>
			<div class="d-flex">
				<input
					class="form-control"
					ngbDatepicker
					#dp="ngbDatepicker"
					[id]="formControlName"
					[name]="formControlName"
					placeholder="No date set"
					[(ngModel)]="controlDateValue"
					(ngModelChange)="updateDate($event)"
					(change)="updateDate($event.target.value)"
					[required]="required" />
				<div class="input-group-append" *ngIf="isDisabled !== true && readonly !== true">
					<button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" [disabled]="isDisabled" type="button">
						<fa-icon icon="calendar"></fa-icon>
					</button>
				</div>
			</div>
		</div>
		<div class="ms-3" *ngIf="useTime === true">
			<ngb-timepicker
				[(ngModel)]="controlTimeValue"
				[ngModelOptions]="{ standalone: true }"
				[meridian]="true"
				(ngModelChange)="updateTime($event)"></ngb-timepicker>
		</div>
	</ng-container>
	<ng-container *ngIf="isDisabled === true || readonly === true">
		<div class="flex-grow-1 input-group d-flex flex-column">
			<label *ngIf="label?.length > 0" for="formControlName" class="form-label">{{ label }}</label>
			<div class="d-flex">
				<input
					class="form-control"
					[id]="formControlName"
					[name]="formControlName"
					placeholder="No date set"
					[(ngModel)]="readableValue"
					[disabled]="isDisabled"
					[readonly]="readonly === true" />
			</div>
		</div>
	</ng-container>
</div>
