import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DataStoreService {
	private store: {
		initRedirectUrl: string;
		manageUpdateData: any;
	} = {
		initRedirectUrl: undefined,
		manageUpdateData: null,
	};

	constructor() {}

	get data() {
		const store = this.store;

		return {
			get initRedirectUrl(): string {
				return store.initRedirectUrl;
			},
			set initRedirectUrl(value: string) {
				store.initRedirectUrl = value;
			},
			get manageUpdateData(): any {
				return store.manageUpdateData;
			},
			set manageUpdateData(value: any) {
				store.manageUpdateData = value;
			},
		};
	}
}
