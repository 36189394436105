import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@razberi-ui/api/cloud-api';
import { LoginResult } from '@razberi-ui/core/data-types';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private _refreshingToken: boolean;
	private _refreshingToken$: BehaviorSubject<boolean>;

	constructor(private authService: AuthService, private router: Router) {
		this._refreshingToken = false;
		this._refreshingToken$ = new BehaviorSubject<boolean>(false);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (
			req.url.indexOf('/api/') > -1 &&
			req.url.indexOf('/api/auth/token') === -1 &&
			req.url.indexOf('/api/reset-password') === -1 &&
			req.url.indexOf('/api/settings/login') === -1 &&
			req.url.indexOf('/api/settings/core') === -1
		) {
			if (req.url.indexOf('rename') > -1 || req.url.indexOf('location') > -1) req = this.addAccessToken(req, true);
			else req = this.addAccessToken(req);
			return next.handle(req).pipe(
				catchError((res) => {
					if (res.status === 401) {
						if (!this._refreshingToken) {
							this._refreshingToken = true;
							this._refreshingToken$.next(true);
							// let parsedToken: AuthToken = JSON.parse(this.authService.data.authToken);
							return this.authService.helpers.refresh(this.authService.data.authToken.refresh_token).pipe(
								switchMap((result: LoginResult) => {
									if (result === LoginResult.Success) {
										this._refreshingToken = false;
										this._refreshingToken$.next(false);
										return next.handle(this.addAccessToken(req.clone()));
									} else this.router.navigate(['/login']);
								})
							);
						} else {
							return this._refreshingToken$.pipe(
								filter((refreshing) => !refreshing),
								take(1),
								switchMap(() => next.handle(this.addAccessToken(req)))
							);
						}
					}

					return throwError(res);
				})
			);
		}
		return next.handle(req);
	}

	private addAccessToken(req: HttpRequest<any>, addContentType?: boolean): HttpRequest<any> {
		// let accessToken: AuthToken = JSON.parse(this.authService.data.authToken);
		// if (!accessToken) return req;
		if (this.authService.data.authToken == null) return req;
		let tokenType: string = this.authService.data.authToken.token_type;
		let httpHeaders: HttpHeaders;

		if (addContentType)
			httpHeaders = new HttpHeaders({
				Authorization: this.capitalize(tokenType) + ' ' + this.authService.data.authToken.access_token,
				'Content-Type': 'application/json',
			});
		else httpHeaders = new HttpHeaders({ Authorization: this.capitalize(tokenType) + ' ' + this.authService.data.authToken.access_token });

		return req.clone({ headers: httpHeaders });
	}

	private capitalize(source: string): string {
		return source.charAt(0).toUpperCase() + source.slice(1);
	}
}
