import { Component } from '@angular/core';
import { NavService } from '@razberi-ui/shared';

@Component({
	selector: 'theme-razberi-core-fixed-toolbar',
	templateUrl: './theme-razberi-core-fixed-toolbar.component.html',
	styleUrls: ['./theme-razberi-core-fixed-toolbar.component.scss'],
})
export class ThemeRazberiCoreFixedToolbarComponent {
	constructor(readonly navService: NavService) {}
}
