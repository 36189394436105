import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { Observable, Subject } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { Account, AccountType } from '@razberi-ui/core/data-types';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-manage-tasks',
	templateUrl: './manage-tasks.component.html',
	styleUrls: ['./manage-tasks.component.scss'],
})
export class ManageTasksComponent {
	canManageAccounts: boolean = false;
	groupByAccount: boolean = false;
	showNewTaskButton: boolean = false;
	isInitialized: boolean = false;
	refreshSubject: Subject<void> = new Subject<void>();
	refresh$: Observable<void> = this.refreshSubject.asObservable();

	constructor(
		private readonly router: Router,
		private readonly pageConfigService: PageConfigService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService
	) {}

	ngOnInit() {
		this.canManageAccounts = this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider;
		this.groupByAccount = this.authService.data.account.type === AccountType.Global;
		this.showNewTaskButton = this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user);

		if (this.canManageAccounts === true || this.showNewTaskButton === true) {
			const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
			if (this.canManageAccounts === true)
				c.checkboxes.push({
					text: 'Group by account',
					icon: 'layer-group',
					isChecked: this.groupByAccount,
					change: (checked: boolean) => {
						this.onGroupByAccountChange(checked);
					},
				});
			if (this.showNewTaskButton === true)
				c.buttons.push({
					text: 'New Task',
					icon: 'plus',
					color: 'primary',
					click: () => {
						this.showNewTask();
					},
				});
			this.pageConfigService.data.toolbarConfig = c;
		}

		this.isInitialized = true;
	}

	ngOnDestroy() {
		if (this.canManageAccounts === true || this.showNewTaskButton === true) this.pageConfigService.data.toolbarConfig = null;
	}

	showNewTask() {
		this.router.navigate(['manage', 'task', '0']);
	}

	onGroupByAccountChange(checked: boolean) {
		this.groupByAccount = checked;
	}

	onUpdate() {
		this.refreshSubject.next();
	}

	showDownloadCsv(account: Account) {
		return this.subscriptionService.helpers.hasManageReadAccess(account, this.authService.data.user);
	}
}
