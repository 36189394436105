import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSearchConfiguration, CoreServerSettingsService, SearchFilter, AuthService } from '@razberi-ui/api/cloud-api';
import {
	Account,
	AccountType,
	EnumService,
	ManageTask,
	ManagedDevice,
	ManagedDeviceStatus,
	ManagedDeviceType,
	ManagedDeviceTypeAttributes,
	Network,
	Unit,
	UserRole,
} from '@razberi-ui/core/data-types';
import {
	AlertMessageService,
	DateRange,
	MatchType,
	PageSidePanelService,
	TableItemSelectMode,
	TableServerSidePagingConfig,
	TableSettings,
	UtilitiesService,
} from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged, forkJoin } from 'rxjs';
import { ManagedDeviceService } from '../../../services/managed-device.service';
import { DashboardService } from '../../../services/dashboard.service';
import { ReportService } from '../../../services/report.service';
import * as moment from 'moment';
import { DeviceEditModalComponent } from '../../devices/device-edit-modal/device-edit-modal.component';
import { UnitService } from '../../../services/unit.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-managed-device-table',
	templateUrl: './managed-device-table.component.html',
	styleUrls: ['./managed-device-table.component.scss'],
})
export class ManagedDeviceTableComponent {
	@Input() accountIds: number[];
	@Input() dateRange: DateRange;
	@Input() showAccount: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() sortAsc: boolean = true;
	@Input() managedDevicesData: ManagedDevice[];
	@Input() network: Network;
	@Input() dataSource: string;
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	managedDevices: ManagedDevice[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isDataSourceReady: boolean = false;
	isInitialized: boolean = false;

	deviceDetailsConfig: any;
	deviceDetailsVisible: boolean = false;

	devicesSearchFilter: any = {
		tags: {
			type: 'objectArray',
			keyToFilterOn: 'tagId',
			selectionType: MatchType.Any,
			values: [],
		},
		location: {
			type: 'object',
			keyToFilterOn: 'locationId',
			values: [],
		},
	};

	constructor(
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly managedDeviceService: ManagedDeviceService,
		private readonly dashboardService: DashboardService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly pageSidePanelService: PageSidePanelService,
		private readonly utils: UtilitiesService,
		private readonly reportService: ReportService,
		private readonly enumService: EnumService,
		private readonly unitService: UnitService
	) {}

	ngOnInit() {
		this.buildTableSettings();

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getManagedDevices();
				})
			);

		this.searchConfiguration = {
			showSearchTerm: true,
			showAccounts: this.showAccount === true && this.dataSource?.startsWith('dashboard') !== true,
			forSubscribedAccounts: true,
			showLocations: true,
			showTags: true,
			showManagedDeviceTypes: true,
			includeManagedDeviceAgentTypes: this.dataSource === 'network-detail',
			showManagedDeviceStatuses: this.dataSource?.startsWith('dashboard') !== true,
		};

		if (this.dataSource?.startsWith('dashboard')) {
			this.subscriptions.add(
				this.dashboardService.streams.selectedAccounts$.pipe(distinctUntilChanged()).subscribe((accounts: Account[]) => {
					if (accounts) {
						this.accountIds = accounts.map((account: Account) => account.accountId);
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getManagedDevices();
					}
				})
			);
			this.subscriptions.add(
				this.dashboardService.streams.selectedDateRange$.pipe(distinctUntilChanged()).subscribe((dateRange: DateRange) => {
					if (dateRange) {
						this.dateRange = dateRange;
						this.isDataSourceReady = this.accountIds?.length > 0 && this.dateRange !== undefined;
						if (this.isDataSourceReady === true) this.getManagedDevices();
					}
				})
			);
		} else {
			this.initialSearchFilter = {};
			if (
				!this.accountIds?.length &&
				this.authService.data.account.type === AccountType.Global &&
				this.settingsService.data.settings?.cloudUI?.preloadSearchFilterAccounts === true
			)
				this.initialSearchFilter.accounts = [1, 2, 3, 4, 5].map((accountId: number) => {
					return { accountId: accountId };
				});

			this.searchFilter = { ...this.initialSearchFilter };
			this.getManagedDevices();
		}
	}

	buildTableSettings() {
		const ts: TableSettings = {
			useAdvancedSearch: this.showSearch,
			useEdit: true,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.managedDevicesData ? false : true,
			serverSidePagingAndFilterConfig: this.managedDevicesData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'managedDeviceId',
			columnConfig: [
				{
					primaryKey: 'status',
					header: '',
					cellType: 'device-status',
					transform: (value, tableItem) => {
						return {
							deviceType: 'managed-device',
							status: value,
							showText: false,
						};
					},
				},
				{
					primaryKey: 'name',
					header: 'Name',
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return { text: tableItem.type === ManagedDeviceType.Agent ? tableItem?.unit?.name ?? value ?? '---' : value ?? '---' };
					},
					clickHandlerFunction: (params) => {
						params.type === ManagedDeviceType.Agent ? this.viewDeviceDetails(params) : this.viewManagedDeviceDetails(params);
					},
				},
				{ primaryKey: 'accountName', header: 'Account', disableSort: true },
				{
					primaryKey: 'modelName',
					header: 'Model',
					cellType: 'header-sub',
					transform: (value, tableItem) => {
						return {
							header: tableItem.manufacturer,
							sub: tableItem.modelName,
						};
					},
				},
				{
					primaryKey: 'type',
					header: 'Type',
					transform: (value, tableItem) => {
						return ManagedDeviceTypeAttributes.get(value)?.label;
					},
				},
				{
					primaryKey: 'serialNumber',
					header: 'Serial / MAC',
					cellType: 'header-sub',
					transform: (value, tableItem) => {
						return {
							header: tableItem.serialNumber,
							sub: this.utils.formatters.asMacAddress(tableItem.macAddress),
						};
					},
				},
				{
					primaryKey: 'ipAddress',
					header: 'Address',
					transform: (value, tableItem) => {
						return tableItem.ipAddress?.length > 0
							? tableItem.ipAddress
							: tableItem.macAddress?.length > 0
							? this.utils.formatters.asMacAddress(tableItem.macAddress)
							: tableItem.host?.length > 0
							? tableItem.host
							: tableItem.targetUrl?.length > 0
							? `${tableItem.targetUrl.slice(0, 48)}${tableItem.targetUrl.length > 48 ? '...' : ''}`
							: '--';
					},
				},
				{
					primaryKey: 'unit',
					header: 'Agent',
					cellType: 'link-button',
					disableSort: true,
					transform: (value, tableItem) => {
						return { text: tableItem.type === ManagedDeviceType.Agent ? '---' : value?.name || '--' };
					},
					clickHandlerFunction: (params) => this.viewDeviceDetails(params),
				},
			],
			headerButtons: [],
			stickyHeaderButtons: [],
			editActions: [
				{
					faIcon: 'info-circle',
					transformLabel: (params) => {
						return `View ${params.type === ManagedDeviceType.Agent ? 'Agent' : 'Device'} Details`;
					},
					clickHandlerFunction: (params) => {
						params.type === ManagedDeviceType.Agent ? this.viewDeviceDetails(params) : this.viewManagedDeviceDetails(params);
					},
				},
				{
					label: 'View Alerts',
					faIcon: 'bell',
					clickHandlerFunction: (params) => {
						this.viewManagedDeviceAlerts(params);
					},
				},
			],
		};

		if (this.authService.data.account.type !== AccountType.Customer || this.authService.helpers.userHasRole(UserRole.Administrator) == true) {
			ts.editActions.push({
				faIcon: 'edit',
				transformLabel: (params) => {
					return 'Edit ' + (params.type === ManagedDeviceType.Agent ? 'Agent' : 'Device');
				},
				clickHandlerFunction: (params) => {
					this.editManagedDevice(params);
				},
			});
		}

		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getManagedDevices();
				},
			});
		if (!this.showAccount || (this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)) {
			const accountNameIdx = ts.columnConfig.findIndex((col) => col.primaryKey === 'accountName');
			if (accountNameIdx >= 0) ts.columnConfig.splice(accountNameIdx, 1);
		}
		this.tableSettings = ts;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized === true) this.getManagedDevices();
	}

	setStartedTimestamp(value) {
		return moment(value).isValid() ? moment(value).format('MM/DD/YY @ HH:mm') : '--';
	}

	getManagedDevices() {
		if (this.managedDevicesData) {
			this.managedDevices = [...this.managedDevicesData];
			this.tableSettings.itemSelectMode = this.managedDevices?.length > 1 ? TableItemSelectMode.Multi : TableItemSelectMode.None;
			this.isInitialized = true;
		} else {
			const filter: SearchFilter = {
				pageNumber: this.pageNumber,
				pageSize: this.pageSize,
				sortAsc: this.sortAsc,
				accounts: this.accountIds?.map((accountId: number) => {
					return { accountId: accountId };
				}),
				//...this.dateRange,
				...this.searchFilter,
			};

			if (this.dataSource === 'dashboard-offline') {
				filter.managedDeviceStatuses = [ManagedDeviceStatus.Error, ManagedDeviceStatus.Offline];
				if ((filter.managedDeviceTypes?.length || 0) == 0) {
					filter.managedDeviceTypes = this.enumService.helpers.getManagedDeviceTypes(false)?.map((t) => t.value);
				}
			} else if (this.dataSource === 'network-detail') {
				if ((this.network?.networkId || 0) == 0) {
					this.alertMessageService.warning('The network provided for the managed devices is not valid');
					this.managedDevices = [];
					this.isInitialized = true;
					return;
				}
				let existingAccountFilter = filter.accounts.find((af) => af.accountId === this.network?.accountId);
				if (existingAccountFilter != null) existingAccountFilter.networkIds = [this.network?.networkId];
				else filter.accounts = [{ accountId: this.network?.accountId, networkIds: [this.network?.networkId] }];
			} else {
				if ((filter.managedDeviceTypes?.length || 0) == 0) {
					filter.managedDeviceTypes = this.enumService.helpers.getManagedDeviceTypes(false)?.map((t) => t.value);
				}
				if (
					filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Offline) == true &&
					filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Error) != true
				) {
					filter.managedDeviceStatuses.push(ManagedDeviceStatus.Error);
				}
			}

			forkJoin({
				managedDevices: this.managedDeviceService.api.getManagedDevices(filter),
			}).subscribe({
				next: (data) => {
					let { pageNumber, pageSize, totalCount, results } = data.managedDevices;
					this.managedDevices = results;
					this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber, pageSize, totalCount };
					this.isInitialized = true;
				},
				error: (error) => {
					this.alertMessageService.error('Error getting managed devices.', error);
				},
			});
		}
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getManagedDevices();
		this.dashboardService.helpers.refresh();
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getManagedDevices();
		this.dashboardService.helpers.refresh();
	}

	downloadCsv() {
		const filter: SearchFilter = {
			pageNumber: this.pageNumber,
			pageSize: this.pageSize,
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			//...this.dateRange,
			...this.searchFilter,
		};

		if (this.dataSource === 'dashboard-offline') {
			filter.managedDeviceStatuses = [ManagedDeviceStatus.Error, ManagedDeviceStatus.Offline];
			if ((filter.managedDeviceTypes?.length || 0) == 0) {
				filter.managedDeviceTypes = this.enumService.helpers.getManagedDeviceTypes(false)?.map((t) => t.value);
			}
		} else if (this.dataSource === 'network-detail') {
			if ((this.network?.networkId || 0) == 0) {
				this.alertMessageService.warning('The network provided for the managed devices is not valid');
				this.managedDevices = [];
				this.isInitialized = true;
				return;
			}
			filter.accounts = [{ accountId: this.network?.accountId, networkIds: [this.network?.networkId] }];
		} else {
			if ((filter.managedDeviceTypes?.length || 0) == 0) {
				filter.managedDeviceTypes = this.enumService.helpers.getManagedDeviceTypes(false)?.map((t) => t.value);
			}
			if (
				filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Offline) == true &&
				filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Error) != true
			) {
				filter.managedDeviceStatuses.push(ManagedDeviceStatus.Error);
			}
		}

		this.managedDeviceService.api.getManagedDevicesCsv(filter).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting tasks CSV.', error);
			},
		});
	}

	getSearchFilter(task: ManageTask) {
		let filter = { ...this.devicesSearchFilter };
		filter.location.values = task.locations.map((loc) => loc.locationId);
		filter.tags.values = task.tags.map((tag) => tag.tagId);
		filter.tags.selectionType = task.matchType as MatchType;
		return filter;
	}

	viewDeviceDetails(managedDevice: ManagedDevice) {
		console.log(managedDevice);
		this.pageSidePanelService.helpers.openUnitSidePanel(
			managedDevice.unit.name,
			'details',
			managedDevice.accountId,
			managedDevice.unitId,
			this.unitService.helpers.userCanEditUnit(),
			(unit) => {
				this.editUnit(unit);
			}
		);
	}

	viewManagedDeviceDetails(managedDevice: ManagedDevice) {
		this.pageSidePanelService.helpers.openManagedDeviceSidePanelById(
			managedDevice.name,
			'details',
			managedDevice.accountId,
			managedDevice.unitId,
			managedDevice.managedDeviceId
		);
	}

	viewManagedDeviceAlerts(managedDevice: ManagedDevice) {
		this.pageSidePanelService.helpers.openManagedDeviceSidePanelById(
			managedDevice.name,
			'alerts',
			managedDevice.accountId,
			managedDevice.unitId,
			managedDevice.managedDeviceId
		);
	}

	editUnit(device: Unit) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = device.accountId;
		modal.componentInstance.unitId = device.unitId;
		modal.result.then(
			(result) => {
				this.update.emit();
			},
			(_) => {}
		);
	}

	editManagedDevice(device: ManagedDevice) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = device.accountId;
		modal.componentInstance.unitId = device.unitId;
		modal.componentInstance.managedDeviceData = device?.managedDeviceId > 0 ? device : null;
		modal.result.then(
			(result) => {
				this.update.emit();
			},
			(_) => {}
		);
	}
}
