<ng-container>
	<div class="row">
		<div class="col-sm-6">
			<label for="recipientName">Add Recipient</label>
			<ng-select
				#select
				[labelForId]="'recipientName'"
				[searchable]="false"
				[items]="accountUsers"
				(change)="addExistingUser($event)"
				placeholder="Select Recipient Name"
				bindLabel="name">
				<ng-template ng-header-tmp>
					<input style="width: 100%; line-height: 24px" type="text" (input)="select.filter($event.target.value)" />
				</ng-template>
				<ng-template ng-footer-tmp>
					<div (click)="addNewUser()" class="add-new-user text-primary cursor-pointer">+ Add new recipient</div>
				</ng-template>
			</ng-select>
		</div>
	</div>
</ng-container>
