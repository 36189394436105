import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DataSearchConfiguration, SearchFilter, SearchAccountFilter, AuthService } from '@razberi-ui/api/cloud-api';
import { CoreSearchItem } from '@razberi-ui/shared';
import { Tag, Location, Account, Unit, VmsService, RecordingServer, Camera, EnumService } from '@razberi-ui/core/data-types';
import { Observable, Subscription, forkJoin, map, of } from 'rxjs';
import { AccountType, EnumItem, User } from '@razberi-ui/core/data-types';
import { AccountService } from '../../../services/account.service';
import { UnitService } from '../../../services/unit.service';
import { AlertService } from '../../../services/alert.service';
import { UserService } from '../../../services/user.service';
import { ManageService } from '../../../services/manage.service';
import { SubscriptionService } from '../../../services/subscription.service';
import * as moment from 'moment';

enum SearchItemKey {
	SearchTerm = 0,
	Accounts = 1,
	AccountTypes = 2,
	AccountStatuses = 3,
	AccountSubscriptionTypes = 4,
	AccountSubscriptionStatuses = 5,
	Users = 6,
	UserRoles = 7,
	UserStatuses = 8,
	Locations = 9,
	Tags = 10,
	Units = 11,
	UnitStatuses = 12,
	AlertTypes = 13,
	AlertStatuses = 14,
	VmsServices = 15,
	RecordingServers = 16,
	Cameras = 17,
	ManageTaskTypes = 18,
	ManageTaskStatuses = 19,
	UpdateTypes = 20,
	ManagedDeviceTypes = 21,
	ManagedDeviceStatuses = 22,
	NetworkLinkStatuses = 23,
	DateRange = 24,
	Networks = 25,
	ManagedDevices = 26,
}

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-data-table-search-form',
	templateUrl: './data-table-search-form.component.html',
	styleUrls: ['./data-table-search-form.component.scss'],
})
export class DataTableSearchFormComponent {
	@Input() searchConfiguration: DataSearchConfiguration;
	@Input() accountIds: number[];
	@Input() alertTypeIds: number[];
	@Input() manageTaskTypeIds: number[];
	@Input() updateTypeIds: number[];
	@Input() initialSearchFilter: SearchFilter;
	@Input() refreshData$: Observable<void>;
	@Output() applyFilter: EventEmitter<SearchFilter> = new EventEmitter();

	subscriptions: Subscription = new Subscription();
	searchItemKeys: any = SearchItemKey;
	availableSearchItems: Object = {};
	trackedSearchItems: Object = {};
	selectedSearchItems: CoreSearchItem<any>[] = [];
	searchFilter: SearchFilter = {};
	canManageAccounts: boolean = false;

	constructor(
		private readonly authService: AuthService,
		private readonly enumService: EnumService,
		private readonly accountService: AccountService,
		private readonly userService: UserService,
		private readonly unitService: UnitService,
		private readonly alertService: AlertService,
		private readonly manageService: ManageService,
		private readonly subscriptionService: SubscriptionService
	) {}

	ngOnInit() {
		this.canManageAccounts = this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider;

		this.initialSearchFilter = { ...this.getDefaultFilter(), ...this.initialSearchFilter };
		this.accountIds?.map((accountId) => {
			this.initialSearchFilter.accounts.push(this.getSearchAccountFilter(accountId));
		});
		this.alertTypeIds?.map((alertTypeId) => {
			this.initialSearchFilter.alertTypeIds.push(alertTypeId);
		});
		this.manageTaskTypeIds?.map((manageTaskTypeId) => {
			this.initialSearchFilter.manageTaskTypeIds.push(manageTaskTypeId);
		});
		this.updateTypeIds?.map((updateTypeId) => {
			this.initialSearchFilter.updateTypeIds.push(updateTypeId);
		});

		if (this.refreshData$)
			this.subscriptions.add(
				this.refreshData$.subscribe((_) => {
					this.initSearchData().subscribe();
				})
			);

		this.initSearchData().subscribe((_) => {
			this.searchFilter = { ...this.initialSearchFilter };
			this.initTrackedSearchItems();
			this.setSelectedSearchItems();
		});
	}

	initSearchData() {
		this.availableSearchItems[SearchItemKey.AccountTypes] = this.getEnumSearchItems(
			'Account Type',
			SearchItemKey.AccountTypes,
			this.enumService.helpers.getAccountTypes(
				this.authService.data.account.type === AccountType.Global,
				this.authService.data.account.type === AccountType.Global
			)
		);
		this.availableSearchItems[SearchItemKey.AccountStatuses] = this.getEnumSearchItems(
			'Account Status',
			SearchItemKey.AccountStatuses,
			this.enumService.helpers.getAccountStatuses()
		);
		this.availableSearchItems[SearchItemKey.AccountSubscriptionTypes] = this.getEnumSearchItems(
			'Account Subscription Type',
			SearchItemKey.AccountSubscriptionTypes,
			this.enumService.helpers.getAccountSubscriptionTypes()
		);
		this.availableSearchItems[SearchItemKey.AccountSubscriptionStatuses] = this.getEnumSearchItems(
			'Account Subscription Status',
			SearchItemKey.AccountSubscriptionStatuses,
			this.enumService.helpers.getAccountSubscriptionStatuses()
		);
		this.availableSearchItems[SearchItemKey.UserRoles] = this.getEnumSearchItems(
			'User Role',
			SearchItemKey.UserRoles,
			this.enumService.helpers.getUserRoles()
		);
		this.availableSearchItems[SearchItemKey.UserStatuses] = this.getEnumSearchItems(
			'User Status',
			SearchItemKey.UserStatuses,
			this.enumService.helpers.getUserStatuses()
		);
		this.availableSearchItems[SearchItemKey.UnitStatuses] = this.getEnumSearchItems(
			'Agent Status',
			SearchItemKey.UnitStatuses,
			this.enumService.helpers.getDeviceStatuses()
		);
		this.availableSearchItems[SearchItemKey.AlertStatuses] = this.getEnumSearchItems(
			'Alert Status',
			SearchItemKey.AlertStatuses,
			this.enumService.helpers.getAlertStatuses()
		);
		this.availableSearchItems[SearchItemKey.ManageTaskStatuses] = this.getEnumSearchItems(
			'Manage Task Status',
			SearchItemKey.ManageTaskStatuses,
			this.enumService.helpers.getManageTaskStatuses()
		);
		this.availableSearchItems[SearchItemKey.ManagedDeviceTypes] = this.getEnumSearchItems(
			'Network Device Type',
			SearchItemKey.ManagedDeviceTypes,
			this.enumService.helpers
				.getManagedDeviceTypes()
				?.filter(
					(type) =>
						(this.searchConfiguration.allowedManagedDeviceTypes?.length ?? 0) == 0 ||
						this.searchConfiguration.allowedManagedDeviceTypes.find((allowed) => allowed == type.value) != null
				)
				?.filter(
					(type) =>
						(this.searchConfiguration.excludedManagedDeviceTypes?.length ?? 0) == 0 ||
						this.searchConfiguration.excludedManagedDeviceTypes.find((excluded) => excluded == type.value) == null
				)
		);
		this.availableSearchItems[SearchItemKey.ManagedDeviceStatuses] = this.getEnumSearchItems(
			'Network Device Status',
			SearchItemKey.ManagedDeviceStatuses,
			this.enumService.helpers.getManagedDeviceStatuses(this.searchConfiguration?.includeManagedDeviceErrorStatus === true)
		);
		this.availableSearchItems[SearchItemKey.NetworkLinkStatuses] = this.getEnumSearchItems(
			'Network Link Status',
			SearchItemKey.NetworkLinkStatuses,
			this.enumService.helpers.getNetworkLinkStatuses()
		);

		const dataSources = {
			accounts:
				this.searchConfiguration.showAccounts === true ||
				this.searchConfiguration.showLocations === true ||
				this.searchConfiguration.showTags === true ||
				this.searchConfiguration.showUnits === true
					? this.accountService.api.getAccountsData(this.accountIds)
					: of(null),
			users: this.searchConfiguration?.showUsers === true ? this.userService.api.getAccountsUsers(this.accountIds) : of(null),
			units: this.searchConfiguration?.showUnits === true ? this.unitService.api.getAccountsUnits(this.accountIds) : of(null),
			vmsServices: this.searchConfiguration?.showVmsServices === true ? this.unitService.api.getAccountsVmsServices() : of(null),
			alertTypes: this.searchConfiguration?.showAlertTypes === true ? this.alertService.api.getAlertTypes() : of(null),
			manageTaskTypes: this.searchConfiguration?.showManageTaskTypes === true ? this.manageService.api.getTaskTypes() : of(null),
			updateTypes: this.searchConfiguration?.showUpdateTypes === true ? this.unitService.api.getUpdateTypes() : of(null),
		};
		return forkJoin(dataSources).pipe(
			map((result) => {
				let { accounts, users, units, alertTypes, vmsServices, manageTaskTypes, updateTypes } = result;
				const filteredAccounts =
					this.searchConfiguration.forSubscribedAccounts === true
						? accounts?.filter((account: Account) => this.subscriptionService.helpers.isGlobalOrIsValid(account.subscription) === true)
						: accounts;
				const filteredLocations = filteredAccounts
					?.reduce((acc: Location[], account: Account) => acc.concat(account.locations), [])
					.sort((l1: Location, l2: Location) => (l1.name > l2.name ? 1 : -1));
				const filteredTags = filteredAccounts
					?.reduce((acc: Tag[], account: Account) => acc.concat(account.tags), [])
					.sort((l1: Tag, l2: Tag) => (l1.name > l2.name ? 1 : -1));
				const filteredUnits = units?.filter((unit: Unit) => filteredAccounts.some((account: Account) => account.accountId === unit.accountId));
				const filteredUsers = users?.filter((user: User) => filteredAccounts.some((account: Account) => account.accountId === user.accountId));
				const filteredVmsServices = vmsServices?.filter((vmsService: VmsService) =>
					filteredAccounts.some((account: Account) => account.accountId === vmsService.accountId)
				);
				const filteredRecordingServers = filteredVmsServices
					?.reduce((acc: RecordingServer[], vmsService: VmsService) => acc.concat(vmsService.recordingServers), [])
					.sort((r1: RecordingServer, r2: RecordingServer) => (r1.name > r2.name ? 1 : -1));
				const filteredCameras = filteredVmsServices
					?.reduce((acc: Camera[], vmsService: VmsService) => acc.concat(vmsService.cameras), [])
					.sort((c1: Camera, c2: Camera) => (c1.name > c2.name ? 1 : -1));

				this.availableSearchItems[SearchItemKey.Accounts] = this.getDataSearchItems(
					'Account',
					SearchItemKey.Accounts,
					(account) => account.name,
					filteredAccounts
				);
				this.availableSearchItems[SearchItemKey.Locations] = this.getDataSearchItems(
					'Location',
					SearchItemKey.Locations,
					(location) => location.name,
					filteredLocations
				);
				this.availableSearchItems[SearchItemKey.Tags] = this.getDataSearchItems('Tag', SearchItemKey.Tags, (tag) => tag.name, filteredTags);
				this.availableSearchItems[SearchItemKey.Users] = this.getDataSearchItems(
					'User',
					SearchItemKey.Users,
					(user) => user.firstName + ' ' + user.lastName,
					filteredUsers
				);
				this.availableSearchItems[SearchItemKey.Units] = this.getDataSearchItems('Agent', SearchItemKey.Units, (unit) => unit.name, filteredUnits);
				this.availableSearchItems[SearchItemKey.VmsServices] = this.getDataSearchItems(
					'VMS Service',
					SearchItemKey.VmsServices,
					(vmsService) => vmsService.name,
					filteredVmsServices
				);
				this.availableSearchItems[SearchItemKey.RecordingServers] = this.getDataSearchItems(
					'Recording Server',
					SearchItemKey.RecordingServers,
					(recordingServer) => recordingServer.name,
					filteredRecordingServers
				);
				this.availableSearchItems[SearchItemKey.Cameras] = this.getDataSearchItems(
					'Camera',
					SearchItemKey.Cameras,
					(camera) => camera.name,
					filteredCameras
				);
				this.availableSearchItems[SearchItemKey.AlertTypes] = this.getDataSearchItems(
					'Alert Type',
					SearchItemKey.AlertTypes,
					(alertType) => alertType.name,
					alertTypes
				);
				this.availableSearchItems[SearchItemKey.ManageTaskTypes] = this.getDataSearchItems(
					'Manage Task Type',
					SearchItemKey.ManageTaskTypes,
					(manageTaskType) => manageTaskType.name,
					manageTaskTypes
				);
				this.availableSearchItems[SearchItemKey.UpdateTypes] = this.getDataSearchItems(
					'Update Type',
					SearchItemKey.UpdateTypes,
					(updateType) => updateType.name,
					updateTypes
				);
			})
		);
	}

	getEnumSearchItems(fieldTitle: string, fieldKey: SearchItemKey, enumItems: EnumItem[]): CoreSearchItem<any>[] {
		return enumItems?.map((enumItem: EnumItem) => {
			return {
				fieldTitle: fieldTitle,
				fieldKey: fieldKey,
				label: enumItem.name,
				value: enumItem,
			};
		});
	}

	getDataSearchItems(fieldTitle: string, fieldKey: SearchItemKey, labelFunc: any, objects: any[]): CoreSearchItem<any>[] {
		return (
			objects?.map((object) => {
				return {
					fieldTitle: fieldTitle,
					fieldKey: fieldKey,
					label: labelFunc(object),
					value: object,
				};
			}) ?? []
		);
	}

	applySearchFilter() {
		this.searchFilter = this.getFilterFromTrackedSearchItems();
		this.setSelectedSearchItems();
		this.applyFilter.emit(this.searchFilter);
	}

	resetSearchFilter() {
		this.searchFilter = { ...this.initialSearchFilter };
		this.initTrackedSearchItems();
		this.setSelectedSearchItems();
		this.applyFilter.emit(this.searchFilter);
	}

	initTrackedSearchItems() {
		// track account data
		this.trackedSearchItems[SearchItemKey.Accounts] = [];
		this.searchFilter.accounts?.map((account) => {
			const availableAccount = this.availableSearchItems[SearchItemKey.Accounts].find((item) => item.value.accountId === account.accountId);
			if (availableAccount != null) {
				this.trackedSearchItems[SearchItemKey.Accounts].push(availableAccount);
				const trackAccountProperty = (searchItemKey: SearchItemKey, idPropertyName: string, objects: any) => {
					this.trackedSearchItems[searchItemKey] = [];
					objects?.map((id) => {
						const found = this.availableSearchItems[searchItemKey].find(
							(item) => item.value.accountId === account.accountId && item.value[idPropertyName] === id
						);
						if (found != null) {
							this.trackedSearchItems[searchItemKey].push(found);
						}
					});
				};
				trackAccountProperty(SearchItemKey.Units, 'unitId', account.unitIds);
				trackAccountProperty(SearchItemKey.Networks, 'networkId', account.networkIds);
				trackAccountProperty(SearchItemKey.Locations, 'locationId', account.locationIds);
				trackAccountProperty(SearchItemKey.Tags, 'tagId', account.tagIds);
				trackAccountProperty(SearchItemKey.VmsServices, 'vmsServiceId', account.vmsServiceIds);
				trackAccountProperty(SearchItemKey.RecordingServers, 'recordingServerId', account.recordingServerIds);
				trackAccountProperty(SearchItemKey.Cameras, 'cameraId', account.cameraIds);
				trackAccountProperty(SearchItemKey.ManagedDevices, 'managedDeviceId', account.managedDeviceIds);
			}
		});

		// track enum data
		const trackEnumItem = (searchItemKey: SearchItemKey, values: any) => {
			this.trackedSearchItems[searchItemKey] = [];
			values?.map((value) => {
				const found = this.availableSearchItems[searchItemKey].find((item) => item.value === value);
				if (found != null) {
					this.trackedSearchItems[searchItemKey].push(found);
				}
			});
		};
		trackEnumItem(SearchItemKey.AccountTypes, this.searchFilter.accountTypes);
		trackEnumItem(SearchItemKey.AccountStatuses, this.searchFilter.accountStatuses);
		trackEnumItem(SearchItemKey.AccountSubscriptionTypes, this.searchFilter.accountSubscriptionTypes);
		trackEnumItem(SearchItemKey.AccountSubscriptionStatuses, this.searchFilter.accountSubscriptionStatuses);
		trackEnumItem(SearchItemKey.UserRoles, this.searchFilter.userRoles);
		trackEnumItem(SearchItemKey.UserStatuses, this.searchFilter.userStatuses);
		trackEnumItem(SearchItemKey.UnitStatuses, this.searchFilter.unitStatuses);
		trackEnumItem(SearchItemKey.AlertStatuses, this.searchFilter.alertStatuses);
		trackEnumItem(SearchItemKey.ManageTaskStatuses, this.searchFilter.manageTaskStatuses);
		trackEnumItem(SearchItemKey.ManagedDeviceTypes, this.searchFilter.managedDeviceTypes);
		trackEnumItem(SearchItemKey.ManagedDeviceStatuses, this.searchFilter.managedDeviceStatuses);
		trackEnumItem(SearchItemKey.NetworkLinkStatuses, this.searchFilter.networkLinkStatuses);

		// track Id data
		const trackIdItem = (searchItemKey: SearchItemKey, idPropertyName: string, ids: any) => {
			this.trackedSearchItems[searchItemKey] = [];
			ids?.map((id) => {
				const found = this.availableSearchItems[searchItemKey].find((item) => item.value[idPropertyName] === id);
				if (found != null) {
					this.trackedSearchItems[searchItemKey].push(found);
				}
			});
		};
		trackIdItem(SearchItemKey.Users, 'userId', this.searchFilter.userIds);
		trackIdItem(SearchItemKey.AlertTypes, 'alertTypeId', this.searchFilter.accountTypes);
		trackIdItem(SearchItemKey.ManageTaskTypes, 'manageTaskTypeId', this.searchFilter.accountTypes);
		trackIdItem(SearchItemKey.UpdateTypes, 'updateTypeId', this.searchFilter.accountTypes);

		// track other data
		this.trackedSearchItems[SearchItemKey.SearchTerm] = this.searchFilter.searchTerm;
		this.trackedSearchItems[SearchItemKey.DateRange] = { startTimestamp: this.searchFilter.startTimestamp, endTimestamp: this.searchFilter.endTimestamp };
	}

	getFilterFromTrackedSearchItems(): SearchFilter {
		const filter = this.getDefaultFilter();

		// filter account data
		this.trackedSearchItems[SearchItemKey.Accounts]?.map((item) => {
			const saf = this.getSearchAccountFilter(item.value.accountId);
			const filterAccountProperty = (filterProperty: any[], idPropertyName: string, items: CoreSearchItem<any>[]) => {
				filterProperty = filterProperty ?? [];
				const accountItems = items?.filter((item) => item.value.accountId === saf.accountId);
				accountItems?.map((item) => filterProperty.push(item.value[idPropertyName]));
			};
			filterAccountProperty(saf.unitIds, 'unitId', this.trackedSearchItems[SearchItemKey.Units]);
			filterAccountProperty(saf.networkIds, 'networkId', this.trackedSearchItems[SearchItemKey.Networks]);
			filterAccountProperty(saf.locationIds, 'locationId', this.trackedSearchItems[SearchItemKey.Locations]);
			filterAccountProperty(saf.tagIds, 'tagId', this.trackedSearchItems[SearchItemKey.Tags]);
			filterAccountProperty(saf.vmsServiceIds, 'vmsServiceId', this.trackedSearchItems[SearchItemKey.VmsServices]);
			filterAccountProperty(saf.recordingServerIds, 'recordingServerId', this.trackedSearchItems[SearchItemKey.RecordingServers]);
			filterAccountProperty(saf.cameraIds, 'cameraId', this.trackedSearchItems[SearchItemKey.Cameras]);
			filterAccountProperty(saf.managedDeviceIds, 'managedDeviceId', this.trackedSearchItems[SearchItemKey.ManagedDevices]);
			filter.accounts.push(saf);
		});

		// filter enum data
		const filterEnumItem = (filterProperty: any[], items: CoreSearchItem<any>[]) => {
			filterProperty = filterProperty ?? [];
			items?.map((item) => filterProperty.push(item.value.value));
		};
		filterEnumItem(filter.accountTypes, this.trackedSearchItems[SearchItemKey.AccountTypes]);
		filterEnumItem(filter.accountStatuses, this.trackedSearchItems[SearchItemKey.AccountStatuses]);
		filterEnumItem(filter.accountSubscriptionStatuses, this.trackedSearchItems[SearchItemKey.AccountSubscriptionTypes]);
		filterEnumItem(filter.userRoles, this.trackedSearchItems[SearchItemKey.AccountSubscriptionStatuses]);
		filterEnumItem(filter.userStatuses, this.trackedSearchItems[SearchItemKey.UserRoles]);
		filterEnumItem(filter.unitStatuses, this.trackedSearchItems[SearchItemKey.UnitStatuses]);
		filterEnumItem(filter.alertStatuses, this.trackedSearchItems[SearchItemKey.AlertStatuses]);
		filterEnumItem(filter.manageTaskStatuses, this.trackedSearchItems[SearchItemKey.ManageTaskStatuses]);
		filterEnumItem(filter.managedDeviceTypes, this.trackedSearchItems[SearchItemKey.ManagedDeviceTypes]);
		filterEnumItem(filter.managedDeviceStatuses, this.trackedSearchItems[SearchItemKey.ManagedDeviceStatuses]);
		filterEnumItem(filter.networkLinkStatuses, this.trackedSearchItems[SearchItemKey.NetworkLinkStatuses]);

		// filter Id data
		const filterIdItem = (filterProperty: any[], idPropertyName: string, items: CoreSearchItem<any>[]) => {
			filterProperty = filterProperty ?? [];
			items?.map((item) => filterProperty.push(item.value[idPropertyName]));
		};
		filterIdItem(filter.userIds, 'userId', this.trackedSearchItems[SearchItemKey.Users]);
		filterIdItem(filter.alertTypeIds, 'alertTypeId', this.trackedSearchItems[SearchItemKey.AlertTypes]);
		filterIdItem(filter.manageTaskTypeIds, 'manageTaskTypeId', this.trackedSearchItems[SearchItemKey.ManageTaskTypes]);
		filterIdItem(filter.updateTypeIds, 'updateTypeId', this.trackedSearchItems[SearchItemKey.UpdateTypes]);

		// filter other data
		filter.searchTerm = this.trackedSearchItems[SearchItemKey.SearchTerm];
		filter.startTimestamp = this.trackedSearchItems[SearchItemKey.DateRange]?.startTimestamp;
		filter.endTimestamp = this.trackedSearchItems[SearchItemKey.DateRange]?.endTimestamp;

		return filter;
	}

	setSelectedSearchItems() {
		const selected: CoreSearchItem<any>[] = [];

		if (this.searchConfiguration?.showSearchTerm === true && this.trackedSearchItems[SearchItemKey.SearchTerm]?.length > 0) {
			selected.push({
				fieldTitle: 'Search Term',
				fieldKey: SearchItemKey.SearchTerm,
				label: this.trackedSearchItems[SearchItemKey.SearchTerm],
				value: this.trackedSearchItems[SearchItemKey.SearchTerm],
			});
		}

		const checkSelected = (isValid: boolean, searchItemKey: SearchItemKey) => {
			if (isValid !== true) {
				return;
			}
			const tracked = this.trackedSearchItems[searchItemKey];
			if (tracked?.length > 0) {
				const available = this.availableSearchItems[searchItemKey];
				tracked.map((item) => {
					const found = available?.find((s) => s === item);
					if (found != null) {
						selected.push(found);
					}
				});
			}
		};

		checkSelected(this.searchConfiguration?.showAccounts, SearchItemKey.Accounts);
		checkSelected(this.searchConfiguration?.showLocations, SearchItemKey.Locations);
		checkSelected(this.searchConfiguration?.showTags, SearchItemKey.Tags);
		checkSelected(this.searchConfiguration?.showUnits, SearchItemKey.Units);
		checkSelected(this.searchConfiguration?.showVmsServices, SearchItemKey.VmsServices);
		checkSelected(this.searchConfiguration?.showRecordingServers, SearchItemKey.RecordingServers);
		checkSelected(this.searchConfiguration?.showCameras, SearchItemKey.Cameras);
		checkSelected(this.searchConfiguration?.showAccountTypes, SearchItemKey.AccountTypes);
		checkSelected(this.searchConfiguration?.showAccountStatuses, SearchItemKey.AccountStatuses);
		checkSelected(this.searchConfiguration?.showAccountSubscriptionTypes, SearchItemKey.AccountSubscriptionTypes);
		checkSelected(this.searchConfiguration?.showAccountSubscriptionStatuses, SearchItemKey.AccountSubscriptionStatuses);
		checkSelected(this.searchConfiguration?.showUsers, SearchItemKey.Users);
		checkSelected(this.searchConfiguration?.showUserRoles, SearchItemKey.UserRoles);
		checkSelected(this.searchConfiguration?.showUserStatuses, SearchItemKey.UserStatuses);
		checkSelected(this.searchConfiguration?.showUnitStatuses, SearchItemKey.UnitStatuses);
		checkSelected(this.searchConfiguration?.showAlertTypes, SearchItemKey.AlertTypes);
		checkSelected(this.searchConfiguration?.showAlertStatuses, SearchItemKey.AlertStatuses);
		checkSelected(this.searchConfiguration?.showManageTaskTypes, SearchItemKey.ManageTaskTypes);
		checkSelected(this.searchConfiguration?.showManageTaskStatuses, SearchItemKey.ManageTaskStatuses);
		checkSelected(this.searchConfiguration?.showUpdateTypes, SearchItemKey.UpdateTypes);
		checkSelected(this.searchConfiguration?.showManagedDeviceTypes, SearchItemKey.ManagedDeviceTypes);
		checkSelected(this.searchConfiguration?.showManagedDeviceStatuses, SearchItemKey.ManagedDeviceStatuses);
		checkSelected(this.searchConfiguration?.showNetworkLinkStatuses, SearchItemKey.NetworkLinkStatuses);
		this.selectedSearchItems = selected;
	}

	removeSearchItem(item: CoreSearchItem<any>) {
		const tracked = this.trackedSearchItems[item.fieldKey];
		if (tracked.constructor === Array) {
			const removed = tracked?.filter((tracked) => tracked !== item);
			this.trackedSearchItems[item.fieldKey] = [...removed];
		} else {
			this.trackedSearchItems[item.fieldKey] = null;
		}

		this.applySearchFilter();
	}

	validateTrackedAccountData(items: CoreSearchItem<Account>[]) {
		const accountIds: any[] = this.trackedSearchItems[SearchItemKey.Accounts]?.map((item) => item.value.accountId) ?? [];
		const validate = (searchItemKey: SearchItemKey) => {
			const tracked =
				this.trackedSearchItems[searchItemKey]?.filter((item) => accountIds.findIndex((accountId) => accountId === item.value.accountId) > -1) ?? [];
			this.trackedSearchItems[searchItemKey] = [...tracked];
		};
		validate(SearchItemKey.Units);
		validate(SearchItemKey.Networks);
		validate(SearchItemKey.Locations);
		validate(SearchItemKey.Tags);
		validate(SearchItemKey.VmsServices);
		validate(SearchItemKey.RecordingServers);
		validate(SearchItemKey.Cameras);
		validate(SearchItemKey.ManagedDevices);
	}

	validateTrackedAccount(items: CoreSearchItem<any>[]) {
		this.trackedSearchItems[SearchItemKey.Accounts] = this.trackedSearchItems[SearchItemKey.Accounts] ?? [];
		const accountIds = items
			?.map((item) => item.value.accountId)
			.filter((accountId: number, index: number, self: number[]) => self.indexOf(accountId) === index);
		accountIds?.map((accountId) => {
			const trackedAccount = this.trackedSearchItems[SearchItemKey.Accounts]?.find((item) => item.value.accountId === accountId);
			if (trackedAccount == null) {
				const availableAccount = this.availableSearchItems[SearchItemKey.Accounts]?.find((item) => item.value.accountId === accountId);
				if (availableAccount != null) {
					this.trackedSearchItems[SearchItemKey.Accounts] = [...this.trackedSearchItems[SearchItemKey.Accounts], availableAccount];
				}
			}
		});
	}

	getSearchAccountFilter(accountId: number): SearchAccountFilter {
		return {
			accountId: accountId,
			unitIds: [],
			networkIds: [],
			locationIds: [],
			tagIds: [],
			vmsServiceIds: [],
			recordingServerIds: [],
			cameraIds: [],
			managedDeviceIds: [],
			managedDeviceKeys: [],
		};
	}

	getDefaultFilter(): SearchFilter {
		return {
			accounts: [],
			accountTypes: [],
			accountStatuses: [],
			accountSubscriptionTypes: [],
			accountSubscriptionStatuses: [],
			userIds: [],
			userRoles: [],
			userStatuses: [],
			unitStatuses: [],
			alertTypeIds: [],
			alertStatuses: [],
			recordingServerStatuses: [],
			cameraStreamStatuses: [],
			manageTaskTypeIds: [],
			manageTaskStatuses: [],
			updateTypeIds: [],
			managedDeviceTypes: [],
			managedDeviceStatuses: [],
			networkLinkStatuses: [],
			startTimestamp: moment().subtract(30, 'd').startOf('d').utc().toDate(),
			endTimestamp: null,
			searchTerm: null,
			sortAsc: true,
			pageNumber: 1,
			pageSize: 10,
		};
	}
}
