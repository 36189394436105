<div id="account-modal" class="modal-container">
	<div id="modal-header" class="modal-header">
		<h4 class="modal-title"><fa-icon icon="search"></fa-icon><span class="ps-2">Global Device Search</span></h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div id="modal-body" class="modal-body">
		<form id="form-lookup" class="form-inline" [formGroup]="lookupForm" (keyup.enter)="search()" autocomplete="off" novalidate>
			<div class="d-flex">
				<div class="input-group" style="width: 50%">
					<div class="input-group-prepend">
						<span class="input-group-text"><fa-icon icon="key"></fa-icon></span>
					</div>
					<input
						type="text"
						class="form-control"
						placeholder="Device name, serial number, or service tag"
						id="lookup"
						formControlName="lookup"
						ngbAutoFocus />
				</div>
				<button id="btn-search" type="button" class="btn btn-primary ms-2" [disabled]="!isValid" (click)="search()">
					<fa-icon icon="search"></fa-icon> Search
				</button>
			</div>
			<shared-input-validation-messages [control]="lookupForm.controls.lookup"></shared-input-validation-messages>
		</form>
		<div *ngIf="isSubmitted !== true" class="modal-body">Enter at least 3 characters to search across all accounts.</div>
		<div *ngIf="isSubmitted === true" class="modal-body">
			<app-root-monitor-cloud-unit-table [showAccount]="true" [lookup]="lookup" [dataSource]="'lookup'"></app-root-monitor-cloud-unit-table>
		</div>
	</div>
	<div id="modal-footer" class="modal-footer">
		<button id="btn-close" type="button" class="btn btn-secondary float-right" (click)="close()"><fa-icon icon="check"></fa-icon> Close</button>
	</div>
</div>
