import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavService } from '@razberi-ui/shared';
import { Subscription } from 'rxjs';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AccountSubscriptionType, AccountType, UserRole } from '@razberi-ui/core/data-types';
import { LocationStrategy } from '@angular/common';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { GdprComponent } from './components/user-compliance/gdpr/gdpr.component';
import { ResellerComponent } from './components/user-compliance/reseller/reseller.component';
import { AuthenticatedUserGuard } from './guards/authenticated-user.guard';
import { AuditLogsComponent } from './components/audit-logs/audit-logs.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { AccountViewComponent } from './components/accounts/account-view/account-view.component';
import { SelfAccountGuard } from './guards/self-account.guard';
import { AccountViewTabUsersComponent } from './components/accounts/account-view/tabs/account-view-tab-users/account-view-tab-users.component';
import { AccountViewTabDetailsComponent } from './components/accounts/account-view/tabs/account-view-tab-details/account-view-tab-details.component';
import { AccountViewTabLocationsComponent } from './components/accounts/account-view/tabs/account-view-tab-locations/account-view-tab-locations.component';
import { AccountViewTabTagsComponent } from './components/accounts/account-view/tabs/account-view-tab-tags/account-view-tab-tags.component';
import { AccountViewTabAlertProfilesComponent } from './components/accounts/account-view/tabs/account-view-tab-alert-profiles/account-view-tab-alert-profiles.component';
import { AccountViewTabSubscriptionComponent } from './components/accounts/account-view/tabs/account-view-tab-subscription/account-view-tab-subscription.component';
import { AccountViewTabBrandingComponent } from './components/accounts/account-view/tabs/account-view-tab-branding/account-view-tab-branding.component';
import { AccountViewTabComplianceComponent } from './components/accounts/account-view/tabs/account-view-tab-compliance/account-view-tab-compliance.component';
import { DevicesComponent } from './components/devices/devices.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileDetailsComponent } from './components/profile/profile-details/profile-details.component';
import { ProfilePasswordComponent } from './components/profile/profile-password/profile-password.component';
import { CloudApiConstants, CloudApiConfigService, AuthService } from '@razberi-ui/api/cloud-api';
import { DashboardOutletServerHealthComponent } from './components/dashboard/outlets/dashboard-outlet-server-health/dashboard-outlet-server-health.component';
import { DashboardOutletIotHealthComponent } from './components/dashboard/outlets/dashboard-outlet-iot-health/dashboard-outlet-iot-health.component';
import { DashboardOutletCybersecurityPostureComponent } from './components/dashboard/outlets/dashboard-outlet-cybersecurity-posture/dashboard-outlet-cybersecurity-posture.component';
import { DashboardOutletMalwareProtectionComponent } from './components/dashboard/outlets/dashboard-outlet-malware-protection/dashboard-outlet-malware-protection.component';
import { DashboardOutletCameraDefenseComponent } from './components/dashboard/outlets/dashboard-outlet-camera-defense/dashboard-outlet-camera-defense.component';
import { DashboardOutletOfflineDevicesComponent } from './components/dashboard/outlets/dashboard-outlet-offline-devices/dashboard-outlet-offline-devices.component';
import { DashboardOutletTotalAlertsComponent } from './components/dashboard/outlets/dashboard-outlet-total-alerts/dashboard-outlet-total-alerts.component';
import { DashboardOutletVmsHealthComponent } from './components/dashboard/outlets/dashboard-outlet-vms-health/dashboard-outlet-vms-health.component';
import { DashboardOutletRecordedStreamsComponent } from './components/dashboard/outlets/dashboard-outlet-recorded-streams/dashboard-outlet-recorded-streams.component';
import { DashboardOutletRecordingServersComponent } from './components/dashboard/outlets/dashboard-outlet-recording-servers/dashboard-outlet-recording-servers.component';
import { DashboardOutletManageTasksComponent } from './components/dashboard/outlets/dashboard-outlet-manage-tasks/dashboard-outlet-manage-tasks.component';
import { DashboardOutletUnitUpdatesComponent } from './components/dashboard/outlets/dashboard-outlet-unit-updates/dashboard-outlet-unit-updates.component';
import { ManageComponent } from './components/manage/manage.component';
import { ManageTaskDetailComponent } from './components/manage/manage-task-detail/manage-task-detail.component';
import { NetworksComponent } from './components/networks/networks.component';
import { NetworkDetailComponent } from './components/networks/network-detail/network-detail.component';
import { NetworkTopologiesComponent } from './components/networks/network-topologies/network-topologies.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ActivateLoginComponent } from './components/auth/activate-login/activate-login.component';
import { AppInitalizationComponent } from './components/core/app-initalization/app-initalization.component';
import { ManageTasksComponent } from './components/manage/manage-tasks/manage-tasks.component';
import { RegisterTokenPublicComponent } from './components/setup/register-token-public/register-token-public.component';
import { RegisterAccountPublicComponent } from './components/setup/register-account-public/register-account-public.component';

const routes: Routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Dashboard' },
		children: [
			{
				path: '',
				redirectTo: 'server-health',
				pathMatch: 'full',
			},
			{
				path: 'server-health',
				component: DashboardOutletServerHealthComponent,
				data: { title: 'Server Health' },
			},
			{
				path: 'iot-health',
				component: DashboardOutletIotHealthComponent,
				data: { title: 'IoT Health' },
			},
			{
				path: 'cybersecurity-posture',
				component: DashboardOutletCybersecurityPostureComponent,
				data: { title: 'Cybersecurity Posture' },
			},
			{
				path: 'malware-protection',
				component: DashboardOutletMalwareProtectionComponent,
				data: { title: 'Malware Protection' },
			},
			{
				path: 'camera-defense',
				component: DashboardOutletCameraDefenseComponent,
				data: { title: 'CameraDefense' },
			},
			{
				path: 'offline-agents',
				component: DashboardOutletOfflineDevicesComponent,
				data: { title: 'Offline Devices' },
			},
			{
				path: 'offline-devices',
				component: DashboardOutletOfflineDevicesComponent,
				data: { title: 'Offline Devices' },
			},
			{
				path: 'total-alerts',
				component: DashboardOutletTotalAlertsComponent,
				data: { title: 'Total Alerts' },
			},
			{
				path: 'vms-health',
				component: DashboardOutletVmsHealthComponent,
				data: { title: 'VMS Health' },
			},
			{
				path: 'recorded-streams',
				component: DashboardOutletRecordedStreamsComponent,
				data: { title: 'Recorded Streams' },
			},
			{
				path: 'recording-servers',
				component: DashboardOutletRecordingServersComponent,
				data: { title: 'Recording Servers' },
			},
			{
				path: 'manage-tasks',
				component: DashboardOutletManageTasksComponent,
				data: { title: 'Manage Tasks' },
			},
			{
				path: 'device-updates',
				component: DashboardOutletUnitUpdatesComponent,
				data: { title: 'Device Updates' },
			},
		],
	},
	{
		path: 'devices',
		component: DevicesComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Devices', icon: 'server' },
	},
	{
		path: 'alerts',
		component: AlertsComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Alerts', icon: 'bell' },
	},
	{
		path: 'manage',
		component: ManageComponent,
		canActivate: [AuthenticatedUserGuard],
		canActivateChild: [AuthenticatedUserGuard],
		children: [
			{
				path: '',
				redirectTo: 'tasks',
				pathMatch: 'full',
			},
			{
				path: 'tasks',
				component: ManageTasksComponent,
				canActivate: [AuthenticatedUserGuard],
				data: { title: 'Manage > Tasks', icon: 'clipboard-list' },
			},
			{
				path: 'task/:manageTaskId/account/:accountId',
				component: ManageTaskDetailComponent,
				canActivate: [AuthenticatedUserGuard],
				data: { title: 'Manage > Task Detail', icon: 'clipboard' },
			},
			{
				path: 'task/copy/:manageTaskId/account/:accountId',
				component: ManageTaskDetailComponent,
				canActivate: [AuthenticatedUserGuard],
				data: { title: 'Copy Task > {{name}}', icon: 'clipboard' },
			},
			{
				path: 'task/preload',
				component: ManageTaskDetailComponent,
				canActivate: [AuthenticatedUserGuard],
				data: { title: 'Manage > New Task', icon: 'clipboard' },
			},
			{
				path: 'task/:manageTaskId',
				redirectTo: 'task/:manageTaskId/account/0',
				pathMatch: 'full',
			},
			{
				path: 'task/copy/:manageTaskId',
				redirectTo: 'task/copy/:manageTaskId/account/0',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'audit-log',
		component: AuditLogsComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Audit Log', icon: 'list-alt' },
	},
	{
		path: 'networks',
		component: NetworksComponent,
		canActivate: [AuthenticatedUserGuard],
		canActivateChild: [AuthenticatedUserGuard],
		children: [
			{
				path: '',
				redirectTo: 'list',
				pathMatch: 'full',
			},
			{
				path: 'list',
				component: NetworkTopologiesComponent,
				canActivate: [AuthenticatedUserGuard],
				data: { title: 'Networks > Topologies', icon: 'network-wired' },
			},
			{
				path: ':networkId/account/:accountId',
				component: NetworkDetailComponent,
				canActivate: [AuthenticatedUserGuard],
				data: { title: 'Networks > Network Detail', icon: 'network-wired' },
			},
			{
				path: ':networkId',
				redirectTo: ':networkId/account/0',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'accounts',
		component: AccountsComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Accounts', icon: 'city', accounts: [AccountType.Global, AccountType.Provider] },
	},
	{
		path: 'accounts/:accountId',
		component: AccountViewComponent,
		canActivate: [AuthenticatedUserGuard, SelfAccountGuard],
		canActivateChild: [AuthenticatedUserGuard, SelfAccountGuard],
		data: { title: '{{name}}', icon: 'building', accounts: [AccountType.Global, AccountType.Provider] },
		children: [
			{
				path: '',
				redirectTo: 'users',
				pathMatch: 'full',
			},
			{
				path: 'users',
				component: AccountViewTabUsersComponent,
				data: { title: 'Users', icon: 'users' },
			},
			{
				path: 'details',
				component: AccountViewTabDetailsComponent,
				data: { title: 'Details', icon: 'building' },
			},
			{
				path: 'locations',
				component: AccountViewTabLocationsComponent,
				data: { title: 'Locations', icon: 'map-marker-alt' },
			},
			{
				path: 'tags',
				component: AccountViewTabTagsComponent,
				data: { title: 'Tags', icon: 'tags' },
			},
			{
				path: 'alert-profiles',
				component: AccountViewTabAlertProfilesComponent,
				data: { title: 'Alert Profiles', icon: 'bell', forAllowedSubscriptionTypes: [AccountSubscriptionType.Monitor, AccountSubscriptionType.Manage] },
			},
			{
				path: 'subscription',
				component: AccountViewTabSubscriptionComponent,
				data: { title: 'Subscription', icon: 'usd-circle' },
			},
			{
				path: 'branding',
				component: AccountViewTabBrandingComponent,
				data: { title: 'Branding', icon: 'image', forAccounts: [AccountType.Global, AccountType.Provider] },
			},
			{
				path: 'compliance',
				component: AccountViewTabComplianceComponent,
				data: { title: 'Compliance', icon: 'file-contract' },
			},
		],
	},
	{
		path: 'admin',
		component: AccountViewComponent,
		canActivate: [AuthenticatedUserGuard],
		canActivateChild: [AuthenticatedUserGuard],
		data: { title: 'Admin: {{name}}', icon: 'tools', roles: [UserRole.Administrator] },
		children: [
			{
				path: '',
				redirectTo: 'users',
				pathMatch: 'full',
			},
			{
				path: 'users',
				component: AccountViewTabUsersComponent,
				data: { title: 'Users', icon: 'users' },
			},
			{
				path: 'details',
				component: AccountViewTabDetailsComponent,
				data: { title: 'Details', icon: 'building' },
			},
			{
				path: 'locations',
				component: AccountViewTabLocationsComponent,
				data: { title: 'Locations', icon: 'map-marker-alt' },
			},
			{
				path: 'tags',
				component: AccountViewTabTagsComponent,
				data: { title: 'Tags', icon: 'tags' },
			},
			{
				path: 'alert-profiles',
				component: AccountViewTabAlertProfilesComponent,
				data: { title: 'Alert Profiles', icon: 'bell', forAllowedSubscriptionTypes: [AccountSubscriptionType.Monitor, AccountSubscriptionType.Manage] },
			},
			{
				path: 'subscription',
				component: AccountViewTabSubscriptionComponent,
				data: { title: 'Subscription', icon: 'usd-circle' },
			},
			{
				path: 'branding',
				component: AccountViewTabBrandingComponent,
				data: { title: 'Branding', icon: 'image', accounts: [AccountType.Global, AccountType.Provider] },
			},
			{
				path: 'compliance',
				component: AccountViewTabComplianceComponent,
				data: { title: 'Compliance', icon: 'file-contract' },
			},
		],
	},
	{
		path: 'profile',
		component: ProfileComponent,
		canActivate: [AuthenticatedUserGuard],
		canActivateChild: [AuthenticatedUserGuard],
		data: { title: 'Profile', icon: 'user' },
		children: [
			{
				path: '',
				redirectTo: 'details',
				pathMatch: 'full',
			},
			{
				path: 'details',
				component: ProfileDetailsComponent,
				data: { title: 'Details', icon: 'address-card' },
			},
			{
				path: 'password',
				component: ProfilePasswordComponent,
				data: { title: 'Password', icon: 'lock' },
			},
		],
	},
	{
		path: 'user-compliance/gdpr',
		component: GdprComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'GDPR Compliance', icon: 'unlock', useCardLayout: true },
	},
	{
		path: 'user-compliance/reseller',
		component: ResellerComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Reseller Compliance', icon: 'unlock', useCardLayout: true },
	},
	{
		path: 'settings',
		component: SettingsComponent,
		canActivate: [AuthenticatedUserGuard],
		data: { title: 'Settings', icon: 'cog', accounts: [AccountType.Global], roles: [UserRole.Administrator] },
	},
	{
		path: 'login',
		component: LoginComponent,
		data: { title: 'Login', icon: 'sign-in', useCardLayout: true, cardSize: 'xs', allowAnonymous: true },
	},
	{
		path: 'logout',
		component: LogoutComponent,
		data: { title: 'Logout', icon: 'sign-out' },
	},
	{
		path: 'reset-password',
		component: ResetPasswordComponent,
		data: { title: 'Reset Password', icon: 'unlock', useCardLayout: true, cardSize: 'xs', allowAnonymous: true },
	},
	{
		path: 'reset-password/:token',
		component: ResetPasswordComponent,
		data: { title: 'Reset Password', icon: 'unlock', useCardLayout: true, cardSize: 'xs', allowAnonymous: true },
	},
	{
		path: 'activate-login/:token',
		component: ActivateLoginComponent,
		data: { title: 'Activate Login', icon: 'user-lock', useCardLayout: true, cardSize: 'xs', allowAnonymous: true },
	},
    {
		path: 'setup/register/token/:token',
		component: RegisterTokenPublicComponent,
		data: { title: 'Register Device', icon: 'unlock', useCardLayout: true, cardSize: 'xs', allowAnonymous: true },
	},
    {
		path: 'setup/register/account',
		component: RegisterAccountPublicComponent,
		data: { title: 'Register New Account', icon: 'unlock', useCardLayout: true, allowAnonymous: true },
	},
	{
		path: 'maintenance',
		component: MaintenanceComponent,
		data: { title: 'Maintenance', icon: 'cogs', useCardLayout: true, cardSize: 'xs', allowAnonymous: true },
	},
	{
		path: 'init',
		component: AppInitalizationComponent,
		data: { title: 'Initialization', icon: 'cogs', useCardLayout: true, cardSize: 'xxxs', allowAnonymous: true, hideSpinner: true },
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'dashboard',
	},
	{ path: '**', redirectTo: 'dashboard' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
})
export class AppRootMonitorCloudRoutingModule {
	isAuthenticated: boolean = false;
	subscriptions: Subscription = new Subscription();

	constructor(
		private readonly navService: NavService,
		private readonly location: LocationStrategy,
		private readonly authService: AuthService,
		private readonly configService: CloudApiConfigService
	) {
		this.subscriptions.add(
			this.authService.streams.isAuthenticated$.subscribe((isAuthenticated) => {
				if (isAuthenticated !== true) return;
				this.setNavItems();
			})
		);

		this.subscriptions.add(
			this.navService.streams.navEndEvents$.subscribe((_) => {
				if (this.authService.data.isAuthenticated !== true) return;
				this.setNavItems();
			})
		);
	}

	setNavItems() {
		const menuItems = [
			{
				id: 'dashboard',
				routerLink: '/dashboard',
				title: 'Dashboard',
				icon: 'th-large',
				active: false,
				show: true,
				requiredSubscriptionTypes: [AccountSubscriptionType.Monitor, AccountSubscriptionType.Manage],
			},
			{
				id: 'devices',
				routerLink: '/devices',
				title: 'Devices',
				icon: 'server',
				active: false,
				show: true,
				requiredSubscriptionTypes: null,
			},
			{
				id: 'alerts',
				routerLink: '/alerts',
				title: 'Alerts',
				icon: 'bell',
				active: false,
				show: true,
				requiredSubscriptionTypes: [AccountSubscriptionType.Monitor, AccountSubscriptionType.Manage],
			},
			{
				id: 'manage',
				routerLink: '/manage',
				title: 'Manage',
				icon: 'tasks',
				active: false,
				show: true,
				requiredSubscriptionTypes: [AccountSubscriptionType.Manage],
				allowActiveClick: true,
			},
			{
				id: 'networks',
				routerLink: '/networks',
				title: 'Networks',
				icon: 'network-wired',
				active: false,
				show: true,
				requiredSubscriptionTypes: [AccountSubscriptionType.Manage],
				allowActiveClick: true,
			},
			{
				id: 'audit-log',
				routerLink: '/audit-log',
				title: 'Audit Log',
				icon: 'list-alt',
				active: false,
				show: true,
				requiredSubscriptionTypes: [AccountSubscriptionType.Monitor, AccountSubscriptionType.Manage],
			},
			{
				id: 'accounts',
				routerLink: '/accounts',
				title: 'Accounts',
				icon: 'city',
				active: false,
				show: this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider,
				requiredSubscriptionTypes: null,
			},
			{
				id: 'admin',
				routerLink: '/admin',
				title: 'Admin',
				icon: 'tools',
				active: false,
				show: this.authService.helpers.userHasRole(UserRole.Administrator) == true,
				requiredSubscriptionTypes: null,
			},
			{
				id: 'profile',
				routerLink: '/profile',
				title:
					this.authService.data.user.firstName && this.authService.data.user.lastName
						? `${this.authService.data.user.firstName} ${this.authService.data.user.lastName}`
						: this.authService.data.user.email,
				icon: 'user',
				image: this.authService.data.user.avatarToken
					? `${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.images.getImage(this.authService.data.user.avatarToken)}`
					: null,
				active: false,
				show: true,
				requiredSubscriptionTypes: null,
			},
			{
				id: 'settings',
				routerLink: '/settings',
				title: 'Settings',
				icon: 'cog',
				active: false,
				show: this.authService.data.account.type === AccountType.Global && this.authService.helpers.userHasRole(UserRole.Administrator) == true,
				requiredSubscriptionTypes: null,
			},
			{
				id: 'logout',
				routerLink: '/logout',
				title: 'Logout',
				icon: 'sign-out',
				active: false,
				show: true,
				requiredSubscriptionTypes: null,
			},
		];

		menuItems.forEach((navItem) => {
			navItem.active = false;
			if (this.location.path()?.length > 0 && this.location.path() !== '/') {
				if (this.location.path() === navItem.routerLink) {
					navItem.active = true;
				}
			}
		});

		this.navService.data.navItems = menuItems;
	}
}
