import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Unit, UnitFields } from '@razberi-ui/core/data-types';
import { AppEnvironment } from '@razberi-ui/shared';
import * as moment from 'moment';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-unit-override-warranty-form',
	templateUrl: './unit-override-warranty-form.component.html',
	styleUrls: ['./unit-override-warranty-form.component.scss'],
})
export class UnitOverrideWarrantyFormComponent {
	@Input() unit: Unit;
	@Input() unitFields: UnitFields;
	@Input() reset$?: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<UnitFields> = new EventEmitter<UnitFields>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();
	expiryForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	isProduction: boolean = false;
	defaultWarrantyExpiration: string;
	defaultApplianceDefenseExpiration: string;

	constructor(private readonly environment: AppEnvironment, private readonly fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.isProduction = this.environment.production;
		this.expiryForm = this.fb.group({
			overrideWarrantyExpiration: [null, []],
			overrideApplianceDefenseExpiration: [null, []],
			manufactureTimestamp: [null, [Validators.required]],
			activationTimestamp: [null, []],
		});

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.expiryForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.expiryForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.expiryForm.valueChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.changes.emit({ ...this.expiryForm.getRawValue() });
			})
		);
		this.expiryForm.updateValueAndValidity();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.unitFields && changes.unitFields.currentValue) {
			let current: UnitFields = changes.unitFields.currentValue;
			this.defaultWarrantyExpiration = moment.utc(current.warrantyExpiration).isValid() ? moment.utc(current.warrantyExpiration).format('l') : '--';
			this.defaultApplianceDefenseExpiration = moment.utc(current.applianceDefenseExpiration).isValid()
				? moment.utc(current.applianceDefenseExpiration).format('l')
				: '--';
			this.expiryForm.patchValue({
				overrideWarrantyExpiration: moment(current.overrideWarrantyExpiration).toDate(),
				overrideApplianceDefenseExpiration: moment(current.overrideApplianceDefenseExpiration).toDate(),
				manufactureTimestamp: moment(current.manufactureTimestamp).toDate(),
				activationTimestamp: moment(current.activationTimestamp).toDate(),
			});
		}
	}

	submitForm() {
		if (this.expiryForm.valid) this.submit.emit();
	}
}
