import { Component, Input } from '@angular/core';
import { ManagedDevice, ManagedDeviceStatus, ManagedDeviceType, ManagedDeviceTypeAttributes } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageSidePanelConfig, PageSidePanelService, UtilitiesService } from '@razberi-ui/shared';
import { Observable, Subscription, forkJoin, take } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { ManagedDeviceService } from '../../../services/managed-device.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-managed-device-side-panel',
	templateUrl: './managed-device-side-panel.component.html',
	styleUrls: ['./managed-device-side-panel.component.scss'],
})
export class ManagedDeviceSidePanelComponent {
	@Input() onEdit$: Observable<void> = new Observable<void>();

	subscriptions: Subscription = new Subscription();
	config: PageSidePanelConfig;
	activeTabKey: string;
	managedDevice: ManagedDevice;
	managedDeviceTypes: any = ManagedDeviceType;

	constructor(
		private readonly sidePanelService: PageSidePanelService,
		private readonly alertService: AlertService,
		private readonly managedDeviceService: ManagedDeviceService,
		private readonly alertMessageService: AlertMessageService,
		private readonly utils: UtilitiesService
	) {}

	ngOnInit() {
		this.sidePanelService.streams.sidePanelConfig$.subscribe((config) => {
			if (config == null || (config.managedDeviceId == null && config.managedDeviceRef == null)) {
				this.managedDevice = null;
				this.activeTabKey = null;
				return;
			}

			if (config.managedDeviceId != null && config.managedDeviceId !== this.config?.managedDeviceId) {
				this.loadManagedDevice(config);
			}

			this.config = config;
			this.activeTabKey = config?.activeTabKey ?? 'details';
		});
	}

	loadManagedDevice(config: PageSidePanelConfig) {
		forkJoin({
			device: this.managedDeviceService.api.getManagedDevice(config.accountId, config.managedDeviceId),
			notifications: this.alertService.api.getDeviceAlerts(config.accountId, config.unitId, 30),
		})
			.pipe(take(1))
			.subscribe({
				next: (result) => {
					let { notifications, device } = result;
					device.alerts = notifications.filter((x) => x.sourceKey == device.managedDeviceKey).slice();
					this.managedDevice = device;
				},
				error: (error) => {
					this.alertMessageService.error('Error getting managed device details.', error);
				},
			});
	}

	getDeviceType() {
		return ManagedDeviceTypeAttributes.get(this.managedDevice?.type)?.label ?? '--';
	}

	getDeviceStatus() {
		return this.managedDevice?.status != null ? ManagedDeviceStatus[this.managedDevice?.status] : '--';
	}

	formatMacAddress(macAddress: string) {
		if ((macAddress?.length || 0) == 0) return '--';
		return this.utils.formatters.asMacAddress(macAddress);
	}

	updateManagedDeviceAlerts(event) {
		if (event) {
			this.alertService.api.getDeviceAlerts(this.config.accountId, this.config.managedDeviceId, 30).subscribe({
				next: (alerts) => {
					this.managedDevice.alerts = alerts?.filter((x) => x.sourceKey == event.managedDeviceKey).slice();
				},
				error: (error) => {
					this.alertMessageService.error('Error getting device alerts.', error);
				},
			});
		}
	}
}
