import { Component } from '@angular/core';

@Component({
	selector: 'app-root-monitor-cloud-dashboard-outlet-server-health',
	templateUrl: './dashboard-outlet-server-health.component.html',
	styleUrls: ['./dashboard-outlet-server-health.component.scss'],
})
export class DashboardOutletServerHealthComponent {
	alertTypeIds: number[] = [2, 3, 9, 10, 11, 18, 25];

	constructor() {}
}
