import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserComplianceGuard } from './user-compliance.guard';
import { AuthGuard } from './auth.guard';

@Injectable({ providedIn: 'root' })
export class AuthenticatedUserGuard implements CanActivate, CanActivateChild {
	constructor(private readonly authGuard: AuthGuard, private readonly userComplianceGuard: UserComplianceGuard) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// check auth guard
		const authGuardValid = this.authGuard.canActivate(route, state);
		if (authGuardValid !== true) return false;
		// check user compliance guard
		const userComplianceGuardValid = this.userComplianceGuard.canActivate(route, state);
		if (userComplianceGuardValid !== true) return false;
		// valid
		return true;
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
