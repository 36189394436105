import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, DataSearchConfiguration, SearchFilter } from '@razberi-ui/api/cloud-api';
import { ManagedDeviceStatus, Network, UserRole } from '@razberi-ui/core/data-types';
import { Subscription, forkJoin } from 'rxjs';
import { NetworkService } from '../../../services/network.service';
import { AlertMessageService, ConfirmService } from '@razberi-ui/shared';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-network-list',
	templateUrl: './network-list.component.html',
	styleUrls: ['./network-list.component.scss'],
})
export class NetworkListComponent {
	@Input() readonly: boolean = false;
	@ViewChildren('d3Container') d3Containers?: QueryList<ElementRef>;

	subscriptions: Subscription = new Subscription();
	accountIds: number[];
	networks: Network[];
	pageNumber: number = 1;
	pageSize: number = 10;
	totalCount: number = 0;
	initialSearchFilter: SearchFilter;
	searchFilter: SearchFilter;
	searchConfiguration: DataSearchConfiguration;
	isInitialized: boolean;
	showFilters: boolean = false;
	canManage: boolean = false;

	constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly networkService: NetworkService,
		private readonly alertMessageService: AlertMessageService,
		private readonly confirmService: ConfirmService
	) {}

	ngOnInit(): void {
		this.canManage = this.authService.helpers.userHasRole(UserRole.Administrator) == true;
		this.initialSearchFilter = { accounts: [] };
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfiguration = {
			showSearchTerm: true,
			showDateRange: false,
			showAccounts: true,
			forSubscribedAccounts: true,
			showNetworkLinkStatuses: true,
			showManagedDeviceStatuses: true,
			includeManagedDeviceErrorStatus: false,
		};
		this.getNetworks();
	}

	getNetworks() {
		const filter: SearchFilter = {
			pageNumber: this.pageNumber,
			pageSize: this.pageSize,
			sortAsc: true, //this.sortAsc,
			...this.searchFilter,
		};

		if (
			filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Offline) == true &&
			filter.managedDeviceStatuses?.some((s) => s === ManagedDeviceStatus.Error) != true
		) {
			filter.managedDeviceStatuses.push(ManagedDeviceStatus.Error);
		}

		forkJoin({
			networks: this.networkService.api.getNetworks(filter),
		}).subscribe({
			next: (data) => {
				let { pageNumber, pageSize, totalCount, results } = data.networks;
				this.networks = results;
				this.pageNumber = pageNumber;
				this.pageSize = pageSize;
				this.totalCount = totalCount;
				this.isInitialized = true;
				setTimeout(() => {
					this.d3Containers?.forEach(
						(ref) => (ref.nativeElement.scrollTop = ref.nativeElement.scrollHeight > 300 ? (ref.nativeElement.scrollHeight - 300) / 2 : 0)
					);
				}, 0);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting networks.', error);
			},
		});
	}

	onFilterSearch(searchFilter) {
		this.searchFilter = searchFilter;
		this.getNetworks();
	}

	onPagingEvent() {
		this.getNetworks();
	}

	viewNetwork(network) {
		this.router.navigate(['networks', network.networkId, 'account', network.accountId]);
	}

	getStatsWarningCount(network) {
		return network?.managedDevices?.filter((md) => md.status === 1)?.length || 0;
	}

	getStatsErrorCount(network) {
		return network?.managedDevices?.filter((md) => md.status === 2)?.length || 0;
	}

	deleteNetwork(network: Network) {
		this.confirmService
			.confirm({
				title: 'Confirm Delete Network',
				html: 'The selected network will be deleted.  Would you like to continue?',
				icon: 'exclamation-triangle',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				confirmButtonColor: 'danger',
			})
			.then(
				(_) => {
					this.networkService.api.deleteNetwork(network.accountId, network.networkId).subscribe((_) => {
						this.alertMessageService.success('The network was successfully deleted');
						this.getNetworks();
					});
				},
				(_) => {}
			);
	}
}
