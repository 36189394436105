import { Component, Input } from '@angular/core';
import { DateRange } from '@razberi-ui/shared';

@Component({
	selector: 'app-root-monitor-cloud-manage-task-tabs',
	templateUrl: './manage-task-tabs.component.html',
	styleUrls: ['./manage-task-tabs.component.scss'],
})
export class ManageTaskTabsComponent {
	@Input() accountIds: number[];
	@Input() dateRange: DateRange;
	@Input() showAccount: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSearch: boolean = true;
	@Input() dataSource: string;
	@Input() selectedTab: string = 'tasks';

	isInitialized: boolean = false;

	constructor() {}

	ngOnInit() {
		this.isInitialized = true;
	}
}
