import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiFactorAuthModalComponent } from '../components/auth/multi-factor-auth-modal/multi-factor-auth-modal.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class MultiAuthInterceptor implements HttpInterceptor {
	constructor(private readonly modalService: NgbModal, private readonly router: Router, private spinnerService: NgxSpinnerService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.indexOf('/api/') > -1 && req.url.indexOf('/api/auth/token') === -1 && req.url.indexOf('/api/reset-password') === -1) {
			return next.handle(req).pipe(
				catchError((res) => {
					if (res.status === 401 && res.error?.Message === 'Invalid MFA.') {
						this.spinnerService.hide();
						var modal = this.modalService.open(MultiFactorAuthModalComponent, {
							backdrop: 'static',
							centered: true,
							keyboard: false,
							size: 'lg',
						});
						const observable = from(modal.result);
						return observable.pipe(
							mergeMap((result) => {
								if (result.success !== true) {
									return throwError(() => new Error());
								} else {
									return next.handle(req);
								}
							})
						);
					}
					return throwError(res);
				})
			);
		}
		return next.handle(req);
	}
}
