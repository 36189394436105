import { Component, Input } from '@angular/core';
import { AlertProfile } from '@razberi-ui/core/data-types';
import { NGXLogger } from 'ngx-logger';
import { UnitService } from '../../../services/unit.service';
import { AlertProfileService } from '../../../services/alert-profile.service';
import { UserService } from '../../../services/user.service';
import { AccountService } from '../../../services/account.service';
import { AlertMessageService, MatchType } from '@razberi-ui/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin, of } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { LocationService } from '../../../services/location.service';
import { TagService } from '../../../services/tag.service';
import { AlertData } from '../../../data-types/alert-data.model';

@Component({
	selector: 'app-root-monitor-cloud-alert-profile-modal',
	templateUrl: './alert-profile-modal.component.html',
	styleUrls: ['./alert-profile-modal.component.scss'],
})
export class AlertProfileModalComponent {
	@Input() accountId: number;
	@Input() alertProfileId?: number;

	alertData: AlertData;
	alertProfile: AlertProfile;
	formAlertProfile: AlertProfile;
	isValid: boolean;

	constructor(
		private readonly logger: NGXLogger,
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly accountService: AccountService,
		private readonly userService: UserService,
		private readonly alertProfileService: AlertProfileService,
		private readonly alertService: AlertService,
		private readonly unitService: UnitService,
		private readonly locationService: LocationService,
		private readonly tagService: TagService
	) {
		this.isValid = false;
	}

	ngOnInit() {
		forkJoin({
			alertProfile: this.alertProfileId ? this.alertProfileService.api.getAlertProfile(this.accountId, this.alertProfileId) : of(this.alertProfile),
			alertTypes: this.alertService.api.getAlertTypes(),
			account: this.accountService.api.getAccount(this.accountId),
			accountLocations: this.locationService.api.getLocations(this.accountId),
			accountTags: this.tagService.api.getTags(this.accountId),
			accountDevices: this.unitService.api.getUnitsByAccountId(this.accountId),
			accountUsers: this.userService.api.getUsersByAccountId(this.accountId),
			existingAlertProfileNames: this.alertProfileService.api.getAlertProfiles(this.accountId),
		}).subscribe({
			next: (alertData) => {
				let { alertProfile, alertTypes, account, accountLocations, accountTags, accountDevices, accountUsers, existingAlertProfileNames } = alertData;
				alertProfile = alertProfile || {
					alertProfileId: 0,
					accountId: this.accountId,
					name: null,
					description: null,
					enabled: true,
					matchType: MatchType.Any,
					alertTypes: [],
					locations: [],
					tags: [],
					recipients: [],
				};
				this.alertData = {
					alertProfile: { accountId: this.accountId, ...alertProfile }, // ensure acctId is set for new profiles
					accountName: account.name,
					existingNames: existingAlertProfileNames.map((profile) => profile.name),
					accountDevices: accountDevices || [],
					availableAlertTypes: [...alertTypes],
					availableLocations: [...accountLocations],
					availableTags: [...accountTags],
					accountUsers: [...accountUsers],
				};
			},
			error: (error) => {
				this.logger.error('error getting alertData', error);
				this.alertMessageService.error('Error getting alert profile data.', error);
			},
		});
	}

	ngOnDestroy() {}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(formAlertProfile: AlertProfile) {
		this.formAlertProfile = formAlertProfile;
	}

	save() {
		if (this.isValid) {
			let o: Observable<any>;
			let ad: AlertProfile = { accountId: this.accountId, ...this.formAlertProfile };
			if (this.alertProfileId) {
				ad.alertProfileId = this.alertProfileId;
				o = this.alertProfileService.api.updateAlertProfile(this.accountId, this.alertProfileId, ad);
			} else o = this.alertProfileService.api.createAlertProfile(this.accountId, ad);
			o.subscribe({
				next: (result) => {
					this.alertMessageService.success(`Alert profile ${this.alertProfileId ? 'saved' : 'created'}.`);
					this.activeModal.close();
				},
				error: (error) => {
					this.alertMessageService.error('Error saving alert profile.', error);
				},
			});
		}
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
