<div class="row mb-5">
	<div class="col-sm-12 container">
		<div class="row box-shadow p-4">
			<div class="col-md-2">
				<fa-icon icon="cog" class="text-secondary" style="font-size: 4rem"></fa-icon>
			</div>
			<div class="col-md-4">
				<div class="font-bold mt-2">{{ settingsService.data.settings?.global?.baseTitle }} URI:</div>
				<div>
					{{ settingsService.data.settings?.global?.cloudUri || '--' }}
				</div>
				<div class="font-bold mt-2">CloudApi URI:</div>
				<div>
					{{ settingsService.data.settings?.global?.cloudApiUri || '--' }}
				</div>
				<div class="font-bold mt-2">SinkApi URI:</div>
				<div>
					{{ settingsService.data.settings?.global?.sinkApiUri || '--' }}
				</div>
			</div>
			<div class="col-md-4">
				<div class="font-bold mt-2">SMTP Host:</div>
				<div>
					{{ settingsService.data.settings?.smtp?.host || '--' }}
				</div>
				<div class="font-bold mt-2">File Store Proivder:</div>
				<div>
					{{ settingsService.data.settings?.fileStore?.provider || '--' }}
				</div>
				<div class="font-bold mt-2">SMS Provider:</div>
				<div>
					{{ settingsService.data.settings?.sms?.provider || '--' }}
				</div>
			</div>
			<div class="col-md-2">
				<div class="font-bold mt-2">Accounts:</div>
				<div>
					{{ settingsDashboard?.accounts || '--' }}
				</div>
				<div class="font-bold mt-2">Devices:</div>
				<div>
					{{ settingsDashboard?.devices || '--' }}
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-sm-3">
		<ul ngbNav #navSetup="ngbNav" class="nav-pills page-content-tabs-vertical" orientation="vertical">
			<li ngbNavItem>
				<a ngbNavLink>Global</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">Global Settings</span>
						</div>
						<app-root-monitor-cloud-settings-global-form></app-root-monitor-cloud-settings-global-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>CloudApi</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">CloudApi Settings</span>
						</div>
						<app-root-monitor-cloud-settings-cloud-api-form></app-root-monitor-cloud-settings-cloud-api-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>SinkApi</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">SinkApi Settings</span>
						</div>
						<app-root-monitor-cloud-settings-sink-api-form></app-root-monitor-cloud-settings-sink-api-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>CloudAgent</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">CloudAgent Settings</span>
						</div>
						<app-root-monitor-cloud-settings-cloud-agent-form></app-root-monitor-cloud-settings-cloud-agent-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>MonitorAgent</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">MonitorAgent Settings</span>
						</div>
						<app-root-monitor-cloud-settings-monitor-agent-form></app-root-monitor-cloud-settings-monitor-agent-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>AWS</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">AWS Settings</span>
						</div>
						<app-root-monitor-cloud-settings-aws-form></app-root-monitor-cloud-settings-aws-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>SMTP</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3 d-flex">
							<div class="flex-1">
								<span class="h4">SMTP Settings</span>
							</div>
							<div>
								<button *ngIf="showSmtpTestEmailButton === true && settingsService.data.settings?.smtp?.host?.length > 0"
										class="btn btn-primary"
										(click)="sendTestEmail()">
									Send Test Email
								</button>
							</div>
						</div>
						<app-root-monitor-cloud-settings-smtp-form (onEditing)="onSmtpFormEditing($event)"></app-root-monitor-cloud-settings-smtp-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>SSO</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3 d-flex">
							<div class="flex-1">
								<span class="h4">SSO Settings</span>
							</div>
						</div>
						<app-root-monitor-cloud-settings-sso-form></app-root-monitor-cloud-settings-sso-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>SMS</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">SMS Settings</span>
						</div>
						<app-root-monitor-cloud-settings-sms-form></app-root-monitor-cloud-settings-sms-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>File Store</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">File Store Settings</span>
						</div>
						<app-root-monitor-cloud-settings-filestore-form></app-root-monitor-cloud-settings-filestore-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>Manage</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">Manage Settings</span>
						</div>
						<app-root-monitor-cloud-settings-manage-form></app-root-monitor-cloud-settings-manage-form>
					</div>
				</ng-template>
			</li>
			<li ngbNavItem>
				<a ngbNavLink>Compliance</a>
				<ng-template ngbNavContent>
					<div class="py-3 px-5">
						<div class="mb-3">
							<span class="h4">Compliance Settings</span>
						</div>
						<app-root-monitor-cloud-settings-compliance-form></app-root-monitor-cloud-settings-compliance-form>
					</div>
				</ng-template>
			</li>
		</ul>
	</div>
	<div class="col-sm-9 px-3" style="border-left: 1px solid #dedede" [ngbNavOutlet]="navSetup"></div>
</div>
