<div class="multi-select">
	<ng-select
		[labelForId]="labelForId"
		[bindLabel]="bindLabel"
		[items]="items"
		[(ngModel)]="selected"
		[multiple]="true"
		[clearable]="clearable"
		[closeOnSelect]="closeOnSelect"
		[clearSearchOnAdd]="clearSearchOnAdd"
		(change)="onChange()"
		(clear)="clearAll()"
		(keyup.enter)="applySearchFilter()">
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
			<div id="option-selected-{{ item.key }}" class="ng-value" *ngFor="let item of selected | slice: 0:dotsAfter">
				<span class="ng-value-label">{{ item[bindLabel] }}</span>
				<span id="option-selected-clear-{{ item.key }}" class="ng-value-icon right" aria-hidden="true" (click)="clearItem(item)">×</span>
			</div>
			<div class="ng-value" *ngIf="selected.length > dotsAfter"><span class="ng-value-label">...</span></div>
			<div class="float-right advanced-search__total" style="margin-right: 40px; margin-top: 3px" *ngIf="showBadge">{{ selected.length }}</div>
		</ng-template>
	</ng-select>
</div>
