import { Injectable } from '@angular/core';
import { CloudApiService, NetworkAvailableDevicesRequest, SearchFilter, SearchFilterResult } from '@razberi-ui/api/cloud-api';
import { ManagedDevice, ManagedDeviceStatus, Network, NetworkLink, NetworkLinkStatus, NetworkLinkType } from '@razberi-ui/core/data-types';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NetworkService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getNetworks: (searchFilter?: SearchFilter): Observable<SearchFilterResult<Network>> => {
				return this.cloudApiService.networks.getNetworks(searchFilter).pipe(
					map((sfr) => {
						sfr?.results?.map((r) => this.helpers.calculateNetworkStats(r));
						return sfr;
					})
				);
			},
			getNetwork: (accountId: number, networkId: number): Observable<Network> => {
				return this.cloudApiService.networks.getNetwork(accountId, networkId).pipe(
					map((r) => {
						this.helpers.calculateNetworkStats(r);
						return r;
					})
				);
			},
			createNetwork: (accountId: number, network: Network): Observable<Network> => {
				return this.cloudApiService.networks.createNetwork(accountId, network);
			},
			updateNetwork: (accountId: number, network: Network): Observable<Network> => {
				return this.cloudApiService.networks.updateNetwork(accountId, network);
			},
			deleteNetwork: (accountId: number, networkId: number): Observable<void> => {
				return this.cloudApiService.networks.deleteNetwork(accountId, networkId);
			},
			getNetworkAvailableDevices: (
				searchFilter: SearchFilter,
				network: Network,
				sourceDevice: ManagedDevice
			): Observable<SearchFilterResult<ManagedDevice>> => {
				var params: NetworkAvailableDevicesRequest = { searchFilter: searchFilter, network: network, sourceDevice: sourceDevice };
				return this.cloudApiService.networks.getNetworkAvailableDevices(params);
			},
		};
	}

	get helpers() {
		return {
			calculateNetworkStats: (network: Network): void => {
				network.deviceTotalCount = network?.managedDevices?.length || 0;
				network.deviceErrorCount = network?.managedDevices?.filter((md) => md.status === 1 || md.status === 2 || md.status === 3)?.length || 0;
				network.linkTotalCount = network?.networkLinks?.length || 0;
				network.linkErrorCount = network?.networkLinks?.filter((md) => md.status === 2 || md.status === 3)?.length || 0;
				network.totalErrorCount = network.deviceErrorCount + network.linkErrorCount;
			},
			getDefaultNetwork: (accountId: number): Network => {
				if (accountId == null) return null;

				return {
					networkId: 0,
					accountId: accountId,
					name: null,
					rootUnitKey: null,
					rootDeviceKey: null,
					locationId: null,
					networkLinks: [],
					managedDevices: [],
				};
			},
			setNetworkRoot: (network: Network, root: ManagedDevice): void => {
				if (network == null || root == null) return;

				if ((network.networkLinks?.length || 0) === 0) {
					if (network.rootUnitKey?.length > 0 && network.rootDeviceKey?.length > 0)
						network.managedDevices = network.managedDevices?.filter(
							(md) => (md.unitKey === network.rootUnitKey && md.managedDeviceKey === network.rootDeviceKey) !== true
						);
					network.rootUnitKey = root.unitKey;
					network.rootDeviceKey = root.managedDeviceKey;
					network.managedDevices.push(root);
				} else {
					// process links on existing network
					const originalRootUnitKey = network.rootUnitKey;
					const originalRootDeviceKey = network.rootDeviceKey;
					network.rootUnitKey = root.unitKey;
					network.rootDeviceKey = root.managedDeviceKey;
					let processUnitKey = root.unitKey;
					let processDeviceKey = root.managedDeviceKey;
					let links: NetworkLink[] = [];
					while (processUnitKey?.length > 0 && processDeviceKey?.length > 0) {
						if (processUnitKey === originalRootUnitKey && processDeviceKey === originalRootDeviceKey) {
							processUnitKey = null;
							processDeviceKey = null;
						} else {
							const upstreamLink = network.networkLinks?.find(
								(nl) => nl.childUnitKey === processUnitKey && nl.childManagedDeviceKey === processDeviceKey
							);
							if (upstreamLink != null) {
								links.push(upstreamLink);
								processUnitKey = upstreamLink.parentUnitKey;
								processDeviceKey = upstreamLink.parentManagedDeviceKey;
							}
						}
					}

					links?.map((nl) => {
						const clone: NetworkLink = JSON.parse(JSON.stringify(nl));
						nl.parentUnitKey = clone.childUnitKey;
						nl.parentManagedDeviceKey = clone.childManagedDeviceKey;
						nl.parentPort = clone.childPort;
						nl.childUnitKey = clone.parentUnitKey;
						nl.childManagedDeviceKey = clone.parentManagedDeviceKey;
						nl.childPort = clone.parentPort;
						nl.isDirty = true;
					});
				}
			},
			updateDeviceDetails: (network: Network, device: ManagedDevice): void => {
				if (network == null || device == null) return;

				let existingDeviceId: number = network.managedDevices?.findIndex((md) => md.managedDeviceId !== device.managedDeviceId);
				network.managedDevices[existingDeviceId].cloudLocationId = device.cloudLocationId;
				network.managedDevices[existingDeviceId].tags = device.tags;
			},
			addNetworkLink: (
				network: Network,
				origin: ManagedDevice,
				destination: ManagedDevice,
				linkType: NetworkLinkType,
				linkStatus: NetworkLinkStatus
			): NetworkLink => {
				if (
					network == null ||
					origin == null ||
					destination == null ||
					network.networkLinks?.some(
						(l) =>
							l.parentUnitKey === origin.unitKey &&
							l.parentManagedDeviceKey === origin.managedDeviceKey &&
							l.childUnitKey === destination.unitKey &&
							l.childManagedDeviceKey === destination.managedDeviceKey
					)
				)
					return;

				const link: NetworkLink = {
					networkLinkId: 0,
					networkId: network.networkId,
					type: linkType,
					status: linkStatus,
					parentUnitKey: origin.unitKey,
					parentManagedDeviceKey: origin.managedDeviceKey,
					parentPort: '0',
					childUnitKey: destination.unitKey,
					childManagedDeviceKey: destination.managedDeviceKey,
					childPort: '0',
					isDirty: true,
				};

				network.networkLinks.push(link);
				if (network.managedDevices.some((md) => md.managedDeviceId === origin.managedDeviceId) != true) network.managedDevices.push(origin);
				if (network.managedDevices.some((md) => md.managedDeviceId === destination.managedDeviceId) != true) network.managedDevices.push(destination);
				return link;
			},
			removeDevice: (network: Network, device: ManagedDevice): void => {
				if (network == null || device == null) return;

				if (network.rootUnitKey === device.unitKey && network.rootDeviceKey === device.managedDeviceKey) {
					network.rootUnitKey = null;
					network.rootDeviceKey = null;
					network.networkLinks = [];
					network.managedDevices = [];
					return;
				}

				let toProcess = [{ unitKey: device.unitKey, deviceKey: device.managedDeviceKey }];
				let maxCounter = 0;
				while (true) {
					// safety counter with max 1000 nodes
					if (maxCounter > 10000) break;
					maxCounter++;
					// process node level
					let processNext = [];
					toProcess?.map((keys) => {
						const upstreamLink = network.networkLinks?.find(
							(nl) => nl.childUnitKey === keys.unitKey && nl.childManagedDeviceKey === keys.deviceKey
						);
						if (upstreamLink != null) {
							network.networkLinks = network.networkLinks?.filter((nl) => nl !== upstreamLink);
						}

						network.managedDevices = network.managedDevices?.filter(
							(md) => (md.unitKey === keys.unitKey && md.managedDeviceKey === keys.deviceKey) != true
						);

						const children = network.networkLinks?.filter(
							(nl) => nl.parentUnitKey === keys.unitKey && nl.parentManagedDeviceKey === keys.deviceKey
						);
						if (children?.length > 0)
							processNext = processNext.concat(
								children.map((c) => {
									return { unitKey: c.childUnitKey, deviceKey: c.childManagedDeviceKey };
								})
							);
					});

					toProcess = [].concat(processNext);
					if (toProcess?.length == 0) break;
				}
			},
			checkNetworkModified: (current: Network, original: Network): boolean => {
				if (current.networkId == null || current.networkId === 0) {
					return current.name?.length > 0 || current.rootUnitKey?.length > 0 || current.rootDeviceKey?.length > 0 || current.networkLinks?.length > 0;
				} else {
					return (
						current.name !== original.name ||
						current.rootUnitKey !== original.rootUnitKey ||
						current.rootDeviceKey !== original.rootDeviceKey ||
						current.locationId !== original.locationId ||
						current.networkLinks?.some((cl) => cl.isDirty === true) === true || // check for modified links
						original.networkLinks?.some(
							(ol) =>
								current.networkLinks?.some(
									(cl) =>
										cl.parentUnitKey === ol.parentUnitKey &&
										cl.parentManagedDeviceKey === ol.parentManagedDeviceKey &&
										cl.parentPort === ol.parentPort &&
										cl.childUnitKey === ol.childUnitKey &&
										cl.childManagedDeviceKey === ol.childManagedDeviceKey &&
										cl.childPort === ol.childPort
								) !== true
						)
					); // check for deleted links
				}
			},
			getNetworkDeviceStatusCssColor: (status: ManagedDeviceStatus): string => {
				switch (status) {
					case ManagedDeviceStatus.Online:
						return 'green';
					case ManagedDeviceStatus.Offline:
					case ManagedDeviceStatus.Error:
						return 'red';
					case ManagedDeviceStatus.Unavailable:
						return 'orange';
					default:
						return 'light';
				}
			},
			getNetworkLinktatusCssColor: (linkStatus: NetworkLinkStatus): string => {
				switch (linkStatus) {
					case NetworkLinkStatus.Unavailable:
						return 'orange';
					case NetworkLinkStatus.Connected:
						return 'green';
					case NetworkLinkStatus.Disconnected:
						return 'red';
					case NetworkLinkStatus.Unknown:
					default:
						return 'light';
				}
			},
			getNetworkLinkSvgStrokeArray: (linkStatus: NetworkLinkStatus): string => {
				switch (linkStatus) {
					case NetworkLinkStatus.Unavailable:
						return '10, 10';
					case NetworkLinkStatus.Disconnected:
						return '15, 15';
					case NetworkLinkStatus.Connected:
						return '0, 0';
					case NetworkLinkStatus.Unknown:
					default:
						return '5, 5';
				}
			},
		};
	}
}
