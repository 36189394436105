import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlertMessageType } from '../data-types/alert-message-type.model';
import { AlertMessage } from '../data-types/alert-message.model';

@Injectable({
	providedIn: 'root',
})
export class AlertMessageService {
	private subject = new Subject<AlertMessage>();
	private defaultId = 'default-alert';

	// enable subscribing to alerts observable
	onAlert(id = this.defaultId): Observable<AlertMessage> {
		return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
	}

	// convenience methods
	success(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Success,
				message,
				autoClose: true,
			})
		);
	}

	info(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Info,
				message,
			})
		);
	}

	warning(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Warning,
				message,
			})
		);
	}

	error(message: string, error?: any, options?: any) {
		const a: AlertMessage = new AlertMessage({
			...options,
			type: AlertMessageType.Error,
			message,
		});
		if (error && error instanceof HttpErrorResponse && error.error && error.error.Message) a.message = error.error.Message;
		this.alert(a);
	}

	primary(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Primary,
				message,
			})
		);
	}

	secondary(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Secondary,
				message,
			})
		);
	}

	light(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Light,
				message,
			})
		);
	}

	dark(message: string, options?: any) {
		this.alert(
			new AlertMessage({
				...options,
				type: AlertMessageType.Dark,
				message,
			})
		);
	}

	// main alert method
	alert(alert: AlertMessage) {
		alert.id = alert.id || this.defaultId;
		this.subject.next(alert);
	}

	// clear alerts
	clear(id = this.defaultId) {
		this.subject.next(new AlertMessage({ id }));
	}
}
