import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertMessage } from '../../data-types/alert-message.model';
import { AlertMessageType } from '../../data-types/alert-message-type.model';
import { AlertMessageService } from '../../services/alert-message.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NavService } from '../../services/nav.service';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'shared-alert-message-outlet',
	templateUrl: './alert-message-outlet.component.html',
	styleUrls: ['./alert-message-outlet.component.scss'],
})
export class AlertMessageOutletComponent implements OnInit {
	@Input() id: string = 'default-alert';
	@Input() fade: boolean = true;
	@Input() isCollapsed: boolean;
	@Input() isFullWidth: boolean = false;

	alerts: AlertMessage[] = [];
	subscriptions: Subscription[] = [];

	constructor(private readonly alertMessageService: AlertMessageService, private readonly navService: NavService) {}

	ngOnInit() {
		// subscribe to new alert notifications
		this.subscriptions.push(
			this.alertMessageService.onAlert(this.id).subscribe((alert) => {
				// clear alerts when an empty alert is received
				if (!alert.message) {
					// filter out alerts without 'keepAfterRouteChange' flag
					this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange === true);

					// remove 'keepAfterRouteChange' flag on the rest
					this.alerts.forEach((x) => delete x.keepAfterRouteChange);

					return;
				}

				// add alert to array
				this.alerts.push(alert);

				// auto close alert if required
				if (alert.autoClose) setTimeout(() => this.close(alert), 5000);
			})
		);

		// clear alerts on location change
		this.subscriptions.push(
			this.navService.streams.navStartEvents$.subscribe((_) => {
				this.alertMessageService.clear(this.id);
			})
		);
	}

	close(alert: AlertMessage) {
		// check if already removed to prevent error on auto close
		if (!this.alerts.includes(alert)) return;

		if (this.fade) {
			// fade out alert
			this.alerts.find((x) => x === alert).fade = true;

			// remove alert after faded out
			setTimeout(() => {
				this.alerts = this.alerts.filter((x) => x !== alert);
			}, 250);
		} else {
			// remove alert
			this.alerts = this.alerts.filter((x) => x !== alert);
		}
	}

	type(alertMessage: AlertMessage) {
		if (!alertMessage) return;

		const alertMessageTypes = {
			[AlertMessageType.Success]: 'success',
			[AlertMessageType.Info]: 'info',
			[AlertMessageType.Warning]: 'warning',
			[AlertMessageType.Error]: 'danger',
			[AlertMessageType.Primary]: 'primary',
			[AlertMessageType.Secondary]: 'secondary',
			[AlertMessageType.Light]: 'light',
			[AlertMessageType.Dark]: 'dark',
		};

		return alertMessageTypes[alertMessage.type];
	}

	icon(alertMessage: AlertMessage) {
		if (!alertMessage) return;

		const alertMessageIcons = {
			[AlertMessageType.Success]: 'check-circle',
			[AlertMessageType.Info]: 'info-circle',
			[AlertMessageType.Warning]: 'exclamation-triangle',
			[AlertMessageType.Error]: 'exclamation-circle',
			[AlertMessageType.Primary]: 'info-circle',
			[AlertMessageType.Secondary]: 'info-circle',
			[AlertMessageType.Light]: 'info-circle',
			[AlertMessageType.Dark]: 'info-circle',
		};

		return alertMessageIcons[alertMessage.type];
	}

	class(alertMessage: AlertMessage) {
		if (!alertMessage) return;
		const c: string[] = [];
		if (alertMessage.fade) c.push('fade');
		return c;
	}
}
