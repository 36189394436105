<div class="d-flex flex-column">
	<div class="container-fluid mt-3">
		<div class="box-shadow ps-3 py-2 d-flex align-items-center">
			<div>Networks Available: {{ totalCount || 0 }}</div>
			<div class="flex-1"></div>
			<div class="me-4">
				<app-root-monitor-cloud-network-d3-tree-legend></app-root-monitor-cloud-network-d3-tree-legend>
			</div>
			<div class="me-3">
				<!-- <app-data-search
					advancedSearch
					[configuration]="searchConfig"
					[accountIds]="accountIds"
					[initialSearchFilter]="initialSearchFilter"
					(search)="onFilterSearch($event)"
					(close)="onFilterClose()"></app-data-search> -->
				<app-root-monitor-cloud-data-table-search-form
					*ngIf="searchConfiguration != null && initialSearchFilter != null"
					dataTableSearch
					[searchConfiguration]="searchConfiguration"
					[accountIds]="accountIds"
					[initialSearchFilter]="initialSearchFilter"
					[refreshData$]="refreshData$"
					(applyFilter)="onFilterSearch($event)">
				</app-root-monitor-cloud-data-table-search-form>
			</div>
		</div>
		<div class="mt-3">
			<ng-container *ngTemplateOutlet="dataTable"></ng-container>
		</div>
	</div>
</div>

<ng-template #dataTable>
	<div>
		<ng-container *ngIf="(networks?.length || 0) === 0">
			<div class="mt-3 p-5 w-100 text-center">
				<i>No Networks Available</i>
			</div>
		</ng-container>
		<ng-container *ngIf="(networks?.length || 0) > 0">
			<div class="d-flex" style="flex-wrap: wrap">
				<div *ngFor="let network of networks" class="p-3" style="border: 1px solid silver; margin-right: 1%; margin-bottom: 1%; width: 32.3%">
					<div class="d-flex pb-2 align-items-center" style="border-bottom: 1px solid #ccc">
						<div class="flex-1">
							<h6 (click)="viewNetwork(network)" class="cursor-pointer mb-0">{{ network.name }}</h6>
						</div>
						<div class="d-flex align-items-center">
							<span
								title="Total Devices: {{ network?.deviceTotalCount || 0 }}"
								class="status-dot status-bg-none status-color me-1 cursor-default"
								>{{ network?.deviceTotalCount || 0 }}</span
							>
							<span
								*ngIf="network?.totalErrorCount > 0"
								title="Network Issues: {{ network?.totalErrorCount || 0 }}"
								class="status-dot status-bg-red status-color me-1 cursor-default"
								>{{ network?.totalErrorCount || 0 }}</span
							>
							<div ngbDropdown class="d-flex flex-row-reverse">
								<button type="button" ngbDropdownToggle class="btn after-none p-0"><fa-icon icon="ellipsis-v" size="2x"></fa-icon></button>
								<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
									<button type="button" ngbDropdownItem (click)="viewNetwork(network)" class="px-1 d-flex align-items-center">
										<fa-icon icon="eye" class="mx-2 text-primary"></fa-icon>
										<span>View Network</span>
									</button>
									<button
										*ngIf="canManage === true"
										type="button"
										ngbDropdownItem
										(click)="deleteNetwork(network)"
										class="px-1 d-flex align-items-center">
										<fa-icon icon="trash" class="mx-2 text-danger"></fa-icon>
										<span>Delete Network</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-2 network-list-tree-d3-container" style="height: 300px" #d3Container>
						<app-root-monitor-cloud-network-d3-tree-diagram [network]="network" [readonly]="true"></app-root-monitor-cloud-network-d3-tree-diagram>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="d-flex align-content-senter justify-content-end mt-3">
		<div *ngIf="totalCount > pageSize" style="flex: 1">
			<ngb-pagination
				[ellipses]="true"
				[maxSize]="3"
				[collectionSize]="totalCount"
				[(page)]="pageNumber"
				[pageSize]="pageSize"
				[boundaryLinks]="true"
				(pageChange)="onPagingEvent()"></ngb-pagination>
		</div>
		<div class="justify-content-end">
			<label class="page-size d-flex align-items-center">
				<span class="me-2">show</span>
				<select
					class="form-select form-select-sm"
					style="width: auto"
					name="pageSize"
					[(ngModel)]="pageSize"
					[ngModelOptions]="{ standalone: true }"
					(change)="onPagingEvent()">
					<option [ngValue]="5">5</option>
					<option [ngValue]="10">10</option>
					<option [ngValue]="20">20</option>
					<option [ngValue]="50">50</option>
				</select>
				<span class="ms-2">per page</span>
			</label>
		</div>
	</div>
</ng-template>
