<shared-context-message *ngIf="isInitialized !== true" type="dark" class="p-3">Loading data.</shared-context-message>
<shared-context-message *ngIf="isInitialized === true && tableSettings == null" type="error" class="p-3">Error displaying data.</shared-context-message>
<app-root-monitor-cloud-data-table
	[isInitialized]="isInitialized"
	[showSearch]="true"
	[tableSettings]="tableSettings"
	[tableItems]="managedDevices"
	(pagingChanged)="onPagingChanged($event)"
	[searchConfiguration]="searchConfiguration"
	[accountIds]="accountIds"
	[initialSearchFilter]="initialSearchFilter"
	(applyFilter)="onSearch($event)"></app-root-monitor-cloud-data-table>
