import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustMatch } from '@razberi-ui/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PasswordFormData } from '../../../data-types/password-form-data.model';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-auth-password-form',
	templateUrl: './password-form.component.html',
	styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent {
	@Input() isCurrentUser: boolean = false;
	@Input() requireExisting: boolean = false;
	@Input() autoFocus: boolean = true;
	@Input() reset$?: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<PasswordFormData> = new EventEmitter<PasswordFormData>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();
	passwordForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private readonly el: ElementRef, private readonly fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.tryBuildForm();

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.passwordForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.passwordForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.passwordForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		if (this.autoFocus) {
			let control: any = this.el.nativeElement.querySelector(`[formControlName="${this.isCurrentUser && this.requireExisting ? 'xxisting' : 'new'}"]`);
			if (control)
				setTimeout(() => {
					control.focus();
				}, 100);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.tryBuildForm();
		let isCurrentUser: boolean = changes.isCurrentUser?.currentValue ?? this.isCurrentUser;
		let requireExisting: boolean = changes.requireExisting?.currentValue ?? this.requireExisting;
		if (changes.isCurrentUser || changes.requireExisting) {
			if (isCurrentUser) {
				if (requireExisting) this.passwordForm.get('existing').enable();
				else this.passwordForm.get('existing').disable();
				this.passwordForm.get('mustChange').setValue(false);
			} else {
				this.passwordForm.get('existing').disable();
				this.passwordForm.get('mustChange').setValue(true);
			}
		}
	}

	tryBuildForm() {
		if (this.passwordForm != null) {
			return;
		}

		this.passwordForm = this.fb.group(
			{
				existing: [null, [Validators.required, Validators.minLength(6)]],
				new: [null, [Validators.required, Validators.minLength(6)]],
				confirm: [null, [Validators.required]],
				mustChange: [null],
			},
			{ validators: [MustMatch('new', 'confirm')] }
		);
	}

	submitForm() {
		if (this.passwordForm.valid) this.submit.emit();
	}

	isInvalid(field: string) {
		return this.passwordForm.get(field).touched && this.passwordForm.get(field).invalid;
	}

	hasErrors(field: string) {
		return this.passwordForm.get(field).touched && this.passwordForm.get(field).errors;
	}

	hasError(field: string, error: string) {
		return this.passwordForm.get(field).hasError(error);
	}
}
