<div class="page-side-panel-container" [ngClass]="isVisible === true ? 'visible' : ''">
	<div class="d-flex">
		<h4 class="w-100">{{ config?.title || '--' }}</h4>
		<fa-icon
			*ngIf="config?.canEdit === true && config?.editAction != null"
			icon="pencil"
			size="xl"
			(click)="editClick()"
			class="cursor-pointer justify-self-end me-2"></fa-icon>
		<fa-icon icon="times" size="xl" (click)="closePanel()" class="cursor-pointer justify-self-end"></fa-icon>
	</div>
	<ng-content></ng-content>
</div>
