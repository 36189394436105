import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Unit, UpdateCategory, UpdateType, WindowsUpdateManageTaskParams } from '@razberi-ui/core/data-types';
import { AlertMessageService, UtilitiesService } from '@razberi-ui/shared';
import { ManageService } from '../../../services/manage.service';
import { UnitService } from '../../../services/unit.service';

@Component({
	selector: 'app-root-monitor-cloud-unit-updates-task-selector-modal',
	templateUrl: './unit-updates-task-selector-modal.component.html',
	styleUrls: ['./unit-updates-task-selector-modal.component.scss'],
})
export class UnitUpdatesTaskSelectorModalComponent {
	@Input() accountId: number;
	@Input() units: Unit[] = [];
	@Input() manageTaskTypeId: number;

	isValid: boolean = false;
	updateTypes: UpdateType[] = [];
	updateTypeCategoryGroups: Map<UpdateCategory, UpdateType[]>;

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly manageService: ManageService,
		private readonly unitService: UnitService,
		readonly utils: UtilitiesService,
		private readonly alertMessageService: AlertMessageService
	) {}

	ngOnInit(): void {
		this.unitService.api.getUpdateTypes().subscribe((types) => {
			this.updateTypes = types;
			this.processAvailableUpdateTypes();
		});
	}

	processAvailableUpdateTypes() {
		const unitUpdateTags = this.units?.map((u) => u.updateTags);
		const mergedUpdateTags = [].concat.apply([], unitUpdateTags);
		this.updateTypeCategoryGroups = this.updateTypes
			?.filter((ut) => ut.updateTypeId != 3 && mergedUpdateTags.some((uut) => uut.referenceTypeId === ut.updateTypeId))
			?.map((ut) => {
				ut['isSelected'] = true;
				return ut;
			})
			.reduce((entryMap, e) => entryMap.set(UpdateCategory[e.category], [...(entryMap.get(UpdateCategory[e.category]) || []), e]), new Map());
		this.checkValidity();
	}

	ngOnDestroy() {}

	checkValidity() {
		this.isValid = (this.updateTypeCategoryGroups?.size || 0) == 0 || this.updateTypes.some((ut) => ut['isSelected'] === true);
	}

	save() {
		// only working with Windows Updates for now - this will likely need to change in the future
		const selectedTypes = this.updateTypes.filter((ut) => ut['isSelected'] === true)?.map((ut) => ut.taskParamType);
		if (this.updateTypeCategoryGroups?.size > 0 && (selectedTypes?.length ?? 0) === 0) {
			this.alertMessageService.error('At least one update type must be selected.');
			return;
		}

		const task = this.manageService.helpers.getDefaultTask(this.accountId);
		task.manageTaskTypeId = 3;
		task.units = this.units;
		const params = <WindowsUpdateManageTaskParams>{ categories: selectedTypes };
		task.parameters = JSON.stringify(params);
		this.activeModal.close({ task: task });
	}

	cancel() {
		this.activeModal.close({ task: null });
	}

	close() {
		this.activeModal.close({ task: null });
	}
}
