import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function MustEqual(value: string, caseSensitive: boolean): ValidatorFn {
	return (control: AbstractControl): ValidationErrors => {
		if (control.value && value) {
			const v1 = caseSensitive === true ? control.value : control.value.toLowerCase();
			const v2 = caseSensitive === true ? value : value.toLowerCase();

			if (v1 !== v2) {
				control.setErrors({ mustequal: control.value });
				return { mustequal: control.value };
			}
		}

		return null;
	};
}
