<div id="account-modal" class="modal-container">
	<div id="modal-header" class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="building"></fa-icon><span class="ps-2">{{ accountId ? 'Edit' : 'New' }} Account</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div id="modal-body" class="modal-body">
		<app-root-monitor-cloud-account-form
			[isCurrentAccount]="isCurrentAccount"
			[isCurrentPrimaryUser]="isCurrentPrimaryUser"
			[editorAccountType]="editorAccountType"
			[account]="account"
			[providers]="providers"
			(isValid)="onAccountIsValid($event)"
			(changes)="onAccountChanges($event)"
			(submit)="save()"
			ngbAutoFocus></app-root-monitor-cloud-account-form>
		<div *ngIf="!accountId" class="modal-body mt-3">
			<h5 class="text-center">Primary Administrator</h5>
			<app-root-monitor-cloud-user-form
				[isCurrentUser]="false"
				[isPrimaryUser]="true"
				(isValid)="onUserIsValid($event)"
				(changes)="onUserChanges($event)"
				(submit)="save()"></app-root-monitor-cloud-user-form>
		</div>
	</div>
	<div id="modal-footer" class="modal-footer">
		<button id="btn-cancel" type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button id="btn-save" type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="!isValid">
			<fa-icon icon="check"></fa-icon> Save
		</button>
	</div>
</div>
