import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageTask, ManageTaskExportRequest, ManageTaskNotificationType, ManageTaskStatus } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, NavService, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subscription, map, timer } from 'rxjs';
import { ManageService } from '../../../services/manage.service';
import { SubscriptionService } from '../../../services/subscription.service';
import { ReportService } from '../../../services/report.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DataStoreService } from '../../../services/data-store.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService, MultiAuthService } from '@razberi-ui/api/cloud-api';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-detail',
	templateUrl: './manage-task-detail.component.html',
	styleUrls: ['./manage-task-detail.component.scss'],
})
export class ManageTaskDetailComponent {
	subscriptions: Subscription = new Subscription();
	refreshSubscription: Subscription;
	refreshActive: boolean = false;
	refreshRemaining: number;
	isLoadingTask: boolean;
	private readonly refreshTimerValue: number = 30;
	accountId: number;
	manageTaskId: number;

	preloadMode: boolean = false;
	preloadKey: string;
	copyMode: boolean = false;

	manageTask: ManageTask;
	taskStatusOptions: any = ManageTaskStatus;
	modifiedData: any;
	isValid: boolean = false;
	isValid$: BehaviorSubject<boolean> = new BehaviorSubject(this.isValid);
	notificationTypeOptions: any = ManageTaskNotificationType;

	constructor(
		private readonly logger: NGXLogger,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly pageConfigService: PageConfigService,
		private readonly alertMessageService: AlertMessageService,
		private readonly navService: NavService,
		private readonly authService: AuthService,
		private readonly manageService: ManageService,
		private readonly confirmService: ConfirmService,
		private readonly subscriptionService: SubscriptionService,
		private readonly reportService: ReportService,
		private readonly dataStoreService: DataStoreService,
		private readonly multiAuthService: MultiAuthService
	) {}

	ngOnInit(): void {
		if (this.router.url.indexOf('task/preload') >= 0) this.preloadMode = true;
		else if (this.router.url.indexOf('task/copy') >= 0) this.copyMode = true;

		this.activatedRoute.params.subscribe((params) => {
			this.accountId = params['accountId'] != null ? Number(params['accountId']) : 0;
			if (this.accountId === 0) this.accountId = this.authService.data.account.accountId;
			this.manageTaskId = params['manageTaskId'] != null ? Number(params['manageTaskId']) : 0;
			this.loadManageTask();
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.stopRefresh();
		this.navService.helpers.removeTitlePlaceholder('{{name}}');
	}

	loadManageTask() {
		this.isLoadingTask = true;
		if (this.manageTaskId > 0) {
			if (this.copyMode == true) {
				this.multiAuthService.api.checkMultiAuth().subscribe({
					next: (_) => {
						this.getExistingTask().subscribe();
					},
					error: (_) => {
						this.router.navigate(['manage']);
						this.alertMessageService.warning('Multi Factor Authentication is required to copy a Manage task.');
						this.isLoadingTask = false;
					},
				});
			} else {
				this.getExistingTask().subscribe((_) => {
					if (this.manageTask.manageTaskId > 0) {
						if (this.manageTask.status === ManageTaskStatus.Scheduled || this.manageTask.status === ManageTaskStatus.Executing) this.startRefresh();
						else this.stopRefresh();
					}
				});
			}
		} else {
			const canWrite = this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user);
			if (canWrite !== true) {
				this.router.navigate(['manage']);
				this.alertMessageService.warning('You are not authorized to create a new Manage task.');
			}

			this.multiAuthService.api.checkMultiAuth().subscribe({
				next: (_) => {
					this.loadNewTask();
					this.isLoadingTask = false;
				},
				error: (_) => {
					this.router.navigate(['manage']);
					this.alertMessageService.warning('Multi Factor Authentication is required to create a new Manage task.');
					this.isLoadingTask = false;
				},
			});
		}
	}

	getExistingTask() {
		return this.manageService.api.getTask(this.accountId, this.manageTaskId).pipe(
			map(
				(manageTask: ManageTask) => {
					this.manageTask = this.copyMode == true ? this.manageService.helpers.copyTask(this.accountId, manageTask) : manageTask;
					this.navService.helpers.addTitlePlaceholder({ key: '{{name}}', value: this.manageTask?.name ?? '--' });
					this.configureToolbar();
					this.isLoadingTask = false;
				},
				(error) => {
					this.alertMessageService.error('Error loading task.', error, { autoClose: true });
					this.loadNewTask();
					this.isLoadingTask = false;
				}
			)
		);
	}

	loadNewTask() {
		this.accountId = this.authService.data.account.accountId;
		this.manageTaskId = 0;
		this.manageTask = this.preloadMode === true ? this.getPreloadTask() : this.manageService.helpers.getDefaultTask(this.accountId);
		this.navService.helpers.addTitlePlaceholder({ key: '{{name}}', value: 'New Task' });
		this.configureToolbar();
	}

	getPreloadTask() {
		let task = this.manageService.helpers.getDefaultTask(this.accountId);
		const preloadData = this.dataStoreService.data.manageUpdateData;
		if (preloadData == null || preloadData.accountId === 0) return task; // message preload task is not valid

		this.accountId = preloadData.accountId;
		return preloadData;
	}

	configureToolbar() {
		const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
		if ((this.manageTask?.manageTaskId || 0) === 0 || this.copyMode === true) {
			if (this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user)) {
				c.buttons.push({
					text: 'Commit Task',
					icon: 'check-circle',
					color: 'primary',
					isEnabled$: this.isValid$.asObservable(),
					click: () => {
						this.saveTask();
					},
				});
				if (this.copyMode === true)
					c.buttons.push({
						text: 'Cancel Copy',
						icon: 'times-circle',
						color: 'light',
						click: () => {
							this.cancelCopyTask();
						},
					});
			}
		} else {
			if (this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user)) {
				if (this.manageTask?.status === ManageTaskStatus.Scheduled)
					c.buttons.push({
						text: 'Cancel Task',
						icon: 'ban',
						color: 'danger',
						click: () => {
							this.cancelTask();
						},
					});

				c.buttons.push({
					text: 'Copy Task',
					icon: 'copy',
					color: 'primary',
					click: () => {
						this.router.navigate(['manage/task/copy', this.manageTaskId, 'account', this.accountId]);
					},
				});
			}

			if (this.manageTask?.manageTaskInstances?.length > 0)
				c.buttons.push({
					text: 'Export Task',
					icon: 'download',
					color: 'light',
					click: () => {
						this.exportTask();
					},
				});
		}
		c.buttons.push({
			text: 'View Tasks',
			icon: 'tasks',
			color: 'light',
			click: () => {
				this.router.navigate(['manage']);
			},
		});
		this.pageConfigService.data.toolbarConfig = c;
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
		this.isValid$.next(this.isValid);
	}

	onChanges(modifiedData: any) {
		this.modifiedData = modifiedData;
	}

	onSubmit() {
		this.saveTask();
	}

	saveTask() {
		const confirmation = this.manageService.helpers.getTaskSaveConfirmation({ ...this.modifiedData.taskForm });
		if (confirmation?.message?.length > 0) {
			this.confirmService
				.confirm({
					title: 'Please confirm',
					html: confirmation.message,
					icon: 'exclamation-triangle',
					confirmButtonText: confirmation?.buttonText ?? 'Commit Task',
					confirmButtonColor: 'primary',
					cancelButtonText: confirmation.buttonCancelText,
				})
				.then(
					(_) => {
						this.createTask();
					},
					(_) => {}
				);
		} else {
			this.createTask();
		}
	}

	createTask() {
		if (this.isValid === true) {
			if (this.modifiedData.clearExistingFiles === true) {
				this.modifiedData.taskForm.manageTaskFiles = [];
			}

			this.manageService.api
				.createTask(this.modifiedData.taskForm.accountId, { ...this.modifiedData.taskForm }, this.modifiedData.uploadFiles)
				.subscribe({
					next: (result) => {
						if (result.type === HttpEventType.UploadProgress) {
							this.logger.log('upload progress', (100 * result.loaded) / result.total);
						} else if (result instanceof HttpResponse) {
							const created = result.body;
							this.alertMessageService.success(`Task ${created.manageTaskGuid} created.`, { keepAfterRouteChange: true });
							this.router.navigate(['manage', 'task', created.manageTaskId, 'account', created.accountId]);
						}
					},
					error: (error) => {
						this.alertMessageService.error('Error creating task.', error);
					},
				});
		}
	}

	cancelTask() {
		this.confirmService
			.confirm({
				title: 'Cancel Task?',
				text: `Are you sure you want to cancel this task?`,
				icon: 'ban',
				confirmButtonText: 'Cancel Task',
				confirmButtonColor: 'primary',
				confirmButtonIcon: 'ban',
			})
			.then(
				(_) => {
					this.manageService.api.cancelTask(this.accountId, this.manageTaskId).subscribe({
						next: (_) => {
							this.alertMessageService.success(`Task ${this.manageTask?.manageTaskGuid ?? this.manageTaskId} cancelled.`);
							this.loadManageTask();
						},
						error: (error) => {
							this.alertMessageService.error('Error cancelling task.', error);
						},
					});
				},
				(_) => {}
			);
	}

	toggleRefresh() {
		if (this.refreshActive === true && this.refreshSubscription != null && this.refreshSubscription.closed != true) {
			this.refreshSubscription.unsubscribe();
			this.refreshSubscription = null;
			this.refreshActive = false;
		} else {
			this.loadManageTask();
			this.startRefresh();
		}
	}

	startRefresh() {
		if (this.refreshActive !== true) {
			this.refreshActive = true;
			this.refreshRemaining = this.refreshTimerValue;
			const source = timer(0, 1000);
			this.refreshSubscription = source
				.pipe(
					map((time) => {
						if (this.refreshRemaining > 0) this.refreshRemaining--;
						if (time % this.refreshTimerValue === 0 && this.isLoadingTask !== true) {
							this.loadManageTask();
							this.refreshRemaining = this.refreshTimerValue;
						}
					})
				)
				.subscribe();
		}
	}

	stopRefresh() {
		if (this.refreshSubscription != null) {
			this.refreshSubscription.unsubscribe();
			this.refreshSubscription = null;
		}

		this.refreshActive = false;
	}

	exportTask() {
		const taskInstanceGroups = this.manageService.helpers.buildTaskInstanceGroups([this.manageTask]);
		const request: ManageTaskExportRequest = { accountId: this.accountId, taskInstanceGroups: taskInstanceGroups };
		this.manageService.api.getManageTasksExport([request]).subscribe((result) => this.reportService.helpers.saveAsFile(result));
	}

	cancelCopyTask() {
		this.alertMessageService.info('Copy task cancelled.', { keepAfterRouterChange: true, autoClose: true });
		this.router.navigate(['manage', 'task', this.manageTaskId, 'account', this.accountId]);
	}
}
