import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CloudApiConfig } from '../data-types/cloud-api-config.model';
import { NGXLogger } from 'ngx-logger';

@Injectable({
	providedIn: 'root',
})
export class CloudApiConfigService {
	private store: {
		config: CloudApiConfig;
		config$: BehaviorSubject<CloudApiConfig>;
	} = {
		config: undefined,
		config$: new BehaviorSubject(undefined),
	};

	constructor(private readonly logger: NGXLogger, private readonly http: HttpClient) {}

	get data() {
		const store = this.store;

		return {
			get config(): CloudApiConfig {
				return store.config;
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get config$(): Observable<CloudApiConfig> {
				return store.config$.asObservable();
			},
		};
	}

	get helpers() {
		return {
			initialize: (): Promise<void> => {
				// manually create a promise so we can subscribe to config & wait for it to load before initializing others
				return new Promise((resolve) => {
					this.http
						.get<CloudApiConfig>('/assets/config/config.json')
						.pipe(
							map((config) => {
								this.logger.debug('Config service data', config);
								this.store.config = config;
								this.store.config$.next(config);
								resolve(null);
							})
						)
						.subscribe();
				});
			},
		};
	}
}
