import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors => {
		const c1 = control.get(controlName);
		const c2 = control.get(matchingControlName);

		if (c2.errors && !c2.errors.mustMatch) return null;

		if (c1.value !== c2.value) {
			c2.setErrors({ mustmatch: c1.value });
			return { mustmatch: c1.value };
		}

		return null;
	};
}
