import { Component, NgModule } from '@angular/core';
import { AppRootMonitorCloudModule } from '@razberi-ui/app-root/monitor-cloud';
import { AppEnvironment } from '@razberi-ui/shared';
import { environment } from '../environments/environment';

@Component({
	selector: 'monitor-cloud',
	template: `<app-root-monitor-cloud></app-root-monitor-cloud>`,
})
export class AppRootComponent {}

@NgModule({
	declarations: [AppRootComponent],
	imports: [AppRootMonitorCloudModule.forRoot(environment)],
	bootstrap: [AppRootComponent],
	providers: [
		{
			provide: AppEnvironment,
			useValue: environment,
		},
	],
})
export class AppModule {}
