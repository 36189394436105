import { Component } from '@angular/core';
import { AuthService } from '@razberi-ui/api/cloud-api';
import { AccountType } from '@razberi-ui/core/data-types';
import { PageConfigService } from '@razberi-ui/shared';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-root-monitor-cloud-audit-logs',
	templateUrl: './audit-logs.component.html',
	styleUrls: ['./audit-logs.component.scss'],
})
export class AuditLogsComponent {
	canManageAccounts: boolean = false;
	groupByAccount: boolean = false;
	isInitialized: boolean = false;
	refreshSubject: Subject<void> = new Subject<void>();
	refresh$: Observable<void> = this.refreshSubject.asObservable();

	constructor(private readonly pageConfigService: PageConfigService, private readonly authService: AuthService) {
		this.canManageAccounts = false;
		this.groupByAccount = false;
		this.isInitialized = false;
		this.refreshSubject = new Subject<void>();
		this.refresh$ = this.refreshSubject.asObservable();
	}

	ngOnInit() {
		this.canManageAccounts = this.authService.data.account.type === AccountType.Global || this.authService.data.account.type === AccountType.Provider;
		this.groupByAccount = this.authService.data.account.type === AccountType.Global;
		if (this.canManageAccounts)
			this.pageConfigService.data.toolbarConfig = {
				checkboxes: [
					{
						text: 'Group by account',
						icon: 'layer-group',
						isChecked: this.groupByAccount,
						change: (checked: boolean) => {
							this.onGroupByAccountChange(checked);
						},
					},
				],
			};
		this.isInitialized = true;
	}

	onGroupByAccountChange(checked: boolean) {
		this.groupByAccount = checked;
	}

	onUpdate() {
		this.refreshSubject.next();
	}
}
