import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-root-monitor-cloud-alert-recipients-form',
	templateUrl: './alert-recipients-form.component.html',
	styleUrls: ['./alert-recipients-form.component.scss'],
})
export class AlertRecipientsFormComponent {
	@Input() accountUsers: any[] = [];
	@Output() addRecipient: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	ngOnChanges() {
		this.accountUsers = this.accountUsers.map((user) => {
			return {
				name: `${user.firstName} ${user.lastName}`,
				email: user.email || null,
				phone: user.phone || null,
				emailPreferred: true,
				phonePreferred: false,
			};
		});
	}

	addExistingUser(user) {
		this.addRecipient.emit({ ...user });
	}

	addNewUser() {
		this.addRecipient.emit(null);
	}
}
