import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-shared-monitor-common-dashboard-label-value',
	templateUrl: './dashboard-label-value.component.html',
	styleUrls: ['./dashboard-label-value.component.scss'],
})
export class DashboardLabelValueComponent implements OnInit {
	@Input() icon: string;
	@Input() iconContext: string;
	@Input() label: string;
	@Input() value: string;
	@Input() clickable: boolean;
	@Input() tooltip: string;

	constructor() {}

	ngOnInit(): void {}
}
