<div *ngIf="settingsService.data.settings?.compliance == null">
	<shared-context-message type="info">The settings could not be loaded.</shared-context-message>
</div>
<form *ngIf="settingsService.data.settings?.compliance != null" [formGroup]="settingsForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input type="checkbox" class="form-check-input" formControlName="enablePrivacyPolicy" id="enablePrivacyPolicy" />
			<label for="enablePrivacyPolicy" class="form-check-label">Enable Privacy Policy</label>
			<div ngbTooltip="Whether or not to enable the privacy policy." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input class="form-check-input" type="checkbox" formControlName="enableResellerAgreement" id="enableResellerAgreement" />
			<label for="enableResellerAgreement" class="form-check-label">Enable Reseller Agreement</label>
			<div ngbTooltip="Whether or not to enable the privacy policy." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
</form>
