import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-shared-monitor-common-dashboard-half-circle-graph',
	templateUrl: './dashboard-half-circle-graph.component.html',
	styleUrls: ['./dashboard-half-circle-graph.component.scss'],
})
export class DashboardHalfCircleGraphComponent implements OnChanges, AfterViewInit {
	@Input() percentage: number;
	@ViewChild('graphLine') graphLine: ElementRef;

	get strokeColor() {
		if (this.percentage < 40) return '#ba3f3f';
		if (this.percentage > 39 && this.percentage < 81) return '#e5912d';
		if (this.percentage > 80) return '#52a761';
	}

	constructor() {
		this.percentage = 0;
	}

	ngOnChanges() {
		if (this.graphLine) {
			let path = this.graphLine.nativeElement;
			let offset = length - length / (100 / Math.round(this.percentage));
			path.style.strokeDashoffset = offset;
		}
	}

	ngAfterViewInit() {
		let path = this.graphLine.nativeElement;
		length = path.getTotalLength();
		let offset = length - length / (100 / Math.round(this.percentage));
		path.style.transition = path.style.WebkitTransition = 'none';
		path.style.strokeDasharray = length + ' ' + length;
		path.style.strokeDashoffset = length;
		path.getBoundingClientRect();
		path.style.transition = path.style.WebkitTransition = 'stroke-dashoffset 1s ease-in-out';
		path.style.strokeDashoffset = offset;
	}
}
