<div class="checkbox-filter">
	<div *ngIf="label != null" class="mb-1"><fa-icon *ngIf="icon != null" [icon]="icon"></fa-icon> {{ label }}</div>
	<div class="d-flex">
		<div *ngFor="let item of items" class="form-check form-check-inline">
			<input
				type="checkbox"
				class="form-check-input"
				id="checkbox-select_{{ selectId }}_{{ item.key }}"
				name="checkbox-select_{{ selectId }}_{{ item.key }}"
				[(ngModel)]="item.selected"
				(change)="onChange($event.target.checked, item)"
				(keyup.enter)="applySearchFilter()" />
			<label for="checkbox-select_{{ selectId }}_{{ item.key }}" class="form-check-label">{{ item.label }}</label>
		</div>
	</div>
</div>
