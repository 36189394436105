import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfirmModalConfiguration } from '../../data-types/confirm-modal-configuration.model';
import { MustEqual } from '../../validators/must-equal.validator';

@Component({
	selector: 'shared-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
	@Input() configuration: ConfirmModalConfiguration;
	passphraseRequired: boolean;
	passphraseForm: UntypedFormGroup;
	isValid: boolean;
	subscriptions: Subscription;

	constructor(private activeModal: NgbActiveModal, private fb: UntypedFormBuilder) {
		this.passphraseRequired = false;
		this.isValid = false;
		this.subscriptions = new Subscription();
	}

	ngOnInit() {
		this.passphraseRequired = this.configuration.passphrase && this.configuration.passphrase.length > 0;
		if (this.passphraseRequired === true) {
			this.passphraseForm = this.fb.group({
				passphrase: [null, [Validators.required, MustEqual(this.configuration.passphrase, false)]],
			});
			this.subscriptions.add(
				this.passphraseForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
					this.isValid = status === 'VALID';
				})
			);
		} else this.isValid = true;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	yes() {
		if (this.passphraseRequired === true && this.passphraseForm.valid !== true) return;

		this.activeModal.close(true);
	}

	no() {
		this.activeModal.dismiss(false);
	}

	close() {
		this.activeModal.dismiss(undefined);
	}

	isInvalid(field: string) {
		return this.passphraseForm.get(field).touched && this.passphraseForm.get(field).invalid;
	}

	hasErrors(field: string) {
		return this.passphraseForm.get(field).touched && this.passphraseForm.get(field).errors;
	}

	hasError(field: string, error: string) {
		return this.passphraseForm.get(field).hasError(error);
	}
}
