import { SortDirection } from './sortable.model';

export class DataPagingStore {
	private store: {
		idPropertyName: string;
		matchFunc: any;
		initialized: boolean;
		sourceItems: any[];
		proceesedItems: any[];
		selectedItems: any[];
		allSelected: boolean;
		pageIndex: number;
		pageSize: number;
		total: number;
		sortColumn: string;
		sortDirection: SortDirection;
		searchTerm: string;
	} = {
		idPropertyName: null,
		matchFunc: null,
		initialized: false,
		sourceItems: [],
		proceesedItems: [],
		selectedItems: [],
		allSelected: false,
		pageIndex: 1,
		pageSize: 10,
		total: 0,
		sortColumn: '',
		sortDirection: 'desc',
		searchTerm: '',
	};

	constructor() {}

	get data() {
		const store = this.store;
		const helpers = this.helpers;

		return {
			get sourceItems(): any[] {
				return store.sourceItems;
			},
			get items(): any[] {
				return store.proceesedItems;
			},
			get selectedItems(): any[] {
				return store.selectedItems;
			},
			get allSelected(): boolean {
				return store.allSelected;
			},
			get pageIndex(): number {
				return store.pageIndex;
			},
			set pageIndex(pageIndex: number) {
				store.pageIndex = pageIndex;
				helpers.process();
			},
			get pageSize(): number {
				return store.pageSize;
			},
			set pageSize(pageSize: number) {
				store.pageSize = pageSize;
				helpers.process();
			},
			get total(): number {
				return store.total;
			},
			get sortColumn(): string {
				return store.sortColumn;
			},
			set sortColumn(sortColumn: string) {
				store.sortColumn = sortColumn;
				helpers.process();
			},
			get sortDirection(): SortDirection {
				return store.sortDirection;
			},
			set sortDirection(sortDirection: SortDirection) {
				store.sortDirection = sortDirection;
				helpers.process();
			},
			get searchTerm(): string {
				return store.searchTerm;
			},
			set searchTerm(searchTerm: string) {
				store.searchTerm = searchTerm;
				helpers.process();
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			// get isAuthenticated$(): Observable<boolean> {
			// 	return store.isAuthenticated$.asObservable();
			// },
		};
	}

	get helpers() {
		return {
			init: (idPropertyName: string, matchFunc: any): void => {
				this.store.idPropertyName = idPropertyName;
				this.store.matchFunc = matchFunc;
				this.store.initialized = true;
			},
			load: (sourceItems: any[]) => {
				this.store.sourceItems = sourceItems ?? [];
				this.helpers.process();
			},
			reset: (): void => {
				this.store.selectedItems = [];
				this.store.allSelected = false;
				this.store.searchTerm = null;
				this.store.pageIndex = 1;
				this.store.pageSize = 10;
				this.store.sortColumn = '';
				this.store.sortDirection = 'desc';
			},
			process: (): void => {
				if (this.store.initialized !== true) return;

				// const { sortColumn, sortDirection, pageSize, page, searchTerm, allSelected } = this._state;
				// 1. sort
				let items = this.helpers.sort(this.store.sourceItems, this.store.sortColumn, this.store.sortDirection);
				// 2. filter
				if (this.store.searchTerm?.length > 0) {
					items = items.filter((alertItem) => this.store.matchFunc(alertItem, this.store.searchTerm));
				}
				// 3. paginate
				items = items.slice((this.store.pageIndex - 1) * this.store.pageSize, (this.store.pageIndex - 1) * this.store.pageSize + this.store.pageSize);
				// 4. select all
				items = items.map((item) => {
					item.selected = this.store.allSelected;
					return item;
				});

				this.store.proceesedItems = items;
				this.store.total = this.store.proceesedItems?.length ?? 0;
				this.store.selectedItems = items.map((item) => item).filter((item) => item.selected === true);
			},
			selectAll: (): void => {
				this.store.allSelected = !this.store.allSelected;
			},
			selectItem: (item: any): void => {
				item.selected = !item.selected;
				this.helpers.patchSelectedItem(item);
			},
			patchSelectedItem: (patchItem: any): void => {
				const mustAddItem = patchItem.selected;
				const selectedItems = this.store.selectedItems;

				if (mustAddItem) {
					this.store.selectedItems = [...selectedItems, patchItem];
				} else {
					let filteredItems = selectedItems.filter((item) => item[this.store.idPropertyName] !== patchItem[this.store.idPropertyName]);
					this.store.selectedItems = [...filteredItems];
				}
			},
			compare: (v1, v2) => {
				return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
			},
			sort: (items: any[], column: string, direction: string): any[] => {
				if (direction === '') {
					return items;
				} else {
					return [...items].sort((a, b) => {
						const res = this.helpers.compare(a[column], b[column]);
						return direction === 'asc' ? res : -res;
					});
				}
			},
			matches: (item: any, term: string) => {
				return (
					item.alertTypeName.toLowerCase().includes(term.toLowerCase()) ||
					item.message.toLowerCase().includes(term.toLowerCase()) ||
					item.unitName.toLowerCase().includes(term.toLowerCase()) ||
					item.serialNumber.toLowerCase().includes(term.toLowerCase()) ||
					item[item.status].toLowerCase().includes(term.toLowerCase())
				);
			},
		};
	}
}
