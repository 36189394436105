import { Component } from '@angular/core';

@Component({
	selector: 'app-root-monitor-cloud-manage',
	templateUrl: './manage.component.html',
	styleUrls: ['./manage.component.scss'],
})
export class ManageComponent {
	constructor() {}
}
