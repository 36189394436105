<div *ngIf="(groupedUpdates?.size || 0) === 0" class="mt-4">No updates were found for this device.</div>
<div *ngFor="let group of groupedUpdates | keyvalue" class="mt-3">
	<div class="pb-3" style="font-weight: 500; color: #fff; font-size: medium; border-bottom: 1px solid #999">
		{{ getUpdateType(group.key)?.name }} ({{ group.value?.length }})
	</div>
	<div class="px-3 mt-3">
		<ng-container *ngFor="let update of group.value">
			<div (click)="update.collapsed = !update.collapsed" class="device-title cursor-pointer w-100 mb-2">
				<div class="d-inline-flex align-items-top w-100">
					<fa-icon class="device-collapse ms-0" size="lg" [icon]="update.collapsed === true ? 'chevron-down' : 'chevron-up'"></fa-icon>
					<h6 class="me-auto ms-3" style="font-weight: 400">{{ update.title }}</h6>
				</div>
			</div>
			<div [ngbCollapse]="update.collapsed === true" class="px-2 mb-3" style="border-top: 1px solid #999; border-bottom: 1px solid #999">
				<div class="side-panel-row" style="border-top: none">
					<div class="side-panel-label" style="width: 35%">Description</div>
					<div class="side-panel-value" style="width: 65%; color: #ccc">{{ update.description || '--' }}</div>
				</div>
				<div *ngIf="shouldShowLink(update.link) === true" class="side-panel-row">
					<div class="side-panel-label" style="width: 35%">Link</div>
					<div class="side-panel-value" style="width: 65%">
						<a *ngIf="update.link?.length > 0" href="{{ update.link }}" target="_blank" style="color: White"
							>Visit Link <fa-icon icon="external-link"></fa-icon
						></a>
						<span *ngIf="(update.link?.length || 0) == 0">--</span>
					</div>
				</div>
				<div class="side-panel-row">
					<div class="side-panel-label" style="width: 35%">Size</div>
					<div class="side-panel-value" style="width: 65%; color: #ccc">{{ utils.formatters.asMemoryCapacity(update.size) || '--' }}</div>
				</div>
				<div class="side-panel-row">
					<div class="side-panel-label" style="width: 35%">Available Date</div>
					<div class="side-panel-value" style="width: 65%; color: #ccc">
						{{ update.availableTimestamp != null ? (update.availableTimestamp | date: 'MM/dd/yy @ HH:mm') : '--' }}
					</div>
				</div>
				<div class="side-panel-row">
					<div class="side-panel-label" style="width: 35%">Scan Date</div>
					<div class="side-panel-value" style="width: 65%; color: #ccc">
						{{ update.scanTimestamp != null ? (update.scanTimestamp | date: 'MM/dd/yy @ HH:mm') : '--' }}
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
