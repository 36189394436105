import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tag } from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { TagService } from '../../../services/tag.service';

@Component({
	selector: 'app-root-monitor-cloud-tag-modal',
	templateUrl: './tag-modal.component.html',
	styleUrls: ['./tag-modal.component.scss'],
})
export class TagModalComponent {
	@Input() accountId: number;
	@Input() tagId: number;

	tag: Tag;
	formTag: Tag;
	isValid: boolean;
	previousTagSaved: boolean = false;
	resetSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	reset$: Observable<void>;

	constructor(private activeModal: NgbActiveModal, private alertMessageService: AlertMessageService, private readonly tagService: TagService) {
		this.isValid = false;
	}

	ngOnInit() {
		this.reset$ = this.resetSubject.asObservable();

		if (this.tagId)
			this.tagService.api.getTag(this.accountId, this.tagId).subscribe({
				next: (tag: Tag) => {
					this.tag = tag;
				},
				error: (error) => {
					this.alertMessageService.error('Error getting tag.', error);
				},
			});
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(formTag: Tag) {
		this.formTag = formTag;
	}

	save() {
		this.saveInternal(false);
	}

	saveAndAdd() {
		this.saveInternal(true);
	}

	saveInternal(addAnother: boolean) {
		if (this.isValid) {
			let o: Observable<any>;
			let t: Tag = { accountId: this.accountId, ...this.formTag };
			if (this.tagId) {
				t.tagId = this.tagId;
				o = this.tagService.api.updateTag(this.accountId, this.tagId, t);
			} else o = this.tagService.api.createTag(this.accountId, t);
			o.subscribe({
				next: (result) => {
					this.alertMessageService.success(`Tag ${this.tagId ? 'saved' : 'created'}.`);
					if (addAnother === true) {
						this.resetSubject.next();
						this.tag = null;
						this.previousTagSaved = true;
					} else this.activeModal.close();
				},
				error: (error) => {
					this.alertMessageService.error('Error saving tag.', error);
				},
			});
		}
	}

	cancel() {
		this.previousTagSaved === true ? this.activeModal.close() : this.activeModal.dismiss('Cancel');
	}

	close() {
		this.previousTagSaved === true ? this.activeModal.close() : this.activeModal.dismiss('X');
	}
}
