import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Tag } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, PageConfigService, TableItemSelectMode, TableSettings } from '@razberi-ui/shared';
import { TagService } from '../../../../../services/tag.service';
import { TagModalComponent } from '../../../../tags/tag-modal/tag-modal.component';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-tags',
	templateUrl: './account-view-tab-tags.component.html',
	styleUrls: ['./account-view-tab-tags.component.scss'],
})
export class AccountViewTabTagsComponent {
	accountId?: number;
	tags: Tag[];
	tableSettings: TableSettings;
	isInitialized: boolean = false;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly confirmService: ConfirmService,
		private readonly authService: AuthService,
		private readonly tagService: TagService
	) {
		this.tags = [];
		this.tableSettings = {
			useSearch: true,
			itemSelectMode: TableItemSelectMode.Multi,
			useEdit: true,
			usePageSize: true,
			hidePagination: false,
			uniqueId: 'tagId',
			columnConfig: [
				{
					primaryKey: 'name',
					header: 'Name',
					useForSearch: true,
					cellType: 'link-button',
					width: '400px',
					transform: (value) => {
						return { text: value, icon: 'tag' };
					},
					clickHandlerFunction: (params, val) => {
						this.manageTag(params);
					},
				},
				{ primaryKey: 'description', header: 'Description', useForSearch: true },
			],
			headerButtons: [
				{
					label: 'Delete',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteTags(params.value);
					},
				},
			],
			editActions: [
				{
					label: 'Edit',
					faIcon: 'edit',
					clickHandlerFunction: (params) => {
						this.manageTag(params);
					},
				},
				{
					label: 'Delete',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteTag(params);
					},
				},
			],
		};
	}

	ngOnInit() {
		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;
		this.pageConfigService.data.toolbarConfig = {
			buttons: [
				{
					text: 'New Tag',
					icon: 'plus',
					color: 'primary',
					click: () => {
						this.manageTag();
					},
				},
			],
		};
		this.getTags();
	}

	ngOnDestroy() {
		this.pageConfigService.data.toolbarConfig = null;
	}

	getTags() {
		this.tagService.api.getTags(this.accountId ?? this.authService.data.account.accountId).subscribe({
			next: (tags: Tag[]) => {
				this.tags = tags.sort((t1: Tag, t2: Tag) => (t1.name > t2.name ? 1 : -1));
				this.isInitialized = true;
			},
			error: (error) => {
				this.alertMessageService.error('Error getting tags.', error);
			},
		});
	}

	manageTag(tag?: Tag) {
		const modal = this.modalService.open(TagModalComponent, { backdrop: 'static', centered: true });
		if (tag) modal.componentInstance.tagId = tag.tagId;
		modal.componentInstance.accountId = this.accountId ?? this.authService.data.account.accountId;
		modal.result.then(
			(_) => {
				this.getTags();
			},
			(_) => {}
		);
	}

	deleteTag(tag: Tag) {
		this.confirmService
			.confirm({
				title: 'Delete tag?',
				text: `Are you sure you want to delete ${tag.name}?`,
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(() => {
				this.tagService.api.deleteTag(this.accountId, tag.tagId).subscribe({
					next: (result) => {
						this.alertMessageService.success('Tag deleted.');
						this.getTags();
					},
					error: (error) => {
						this.alertMessageService.error('Error deleting tag.', error);
					},
				});
			});
	}

	deleteTags(tags: Tag[]) {
		this.confirmService
			.confirm({
				title: 'Delete tags?',
				text: `Are you sure you want to delete ${tags.length} tags?`,
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(() => {
				let tagIds = tags.map((tag) => tag.tagId);
				this.tagService.api.deleteTags(this.accountId, tagIds).subscribe({
					next: (result) => {
						this.alertMessageService.success('Tags deleted.');
						this.getTags();
					},
					error: (error) => {
						this.alertMessageService.error('Error deleting tags.', error);
					},
				});
			});
	}
}
