import { Injectable } from '@angular/core';
import { PagePortalOutletDirective } from '../directives/page-portal-outlet.directive';

@Injectable({
	providedIn: 'root',
})
export class PagePortalService {
	outlets = new Map<string, PagePortalOutletDirective>();

	registerOutlet(outlet: PagePortalOutletDirective) {
		this.outlets[outlet.outletKey] = outlet;
	}

	clearOutlet(outletKey: string) {
		const outlet: PagePortalOutletDirective = this.outlets[outletKey];
		if (outlet != null) outlet.viewContainerRef.clear();
	}
}
