<div class="fs-4 mb-2">Agents Without CameraDefense Enabled</div>
<app-root-monitor-cloud-unit-table
	[showLocation]="false"
	[showTags]="false"
	[showAccount]="true"
	[showCameraDefenseStatus]="true"
	[showMalwareProtectionStatus]="true"
	[showUpdates]="false"
	[dataSource]="'dashboard-camera-defense'">
</app-root-monitor-cloud-unit-table>
