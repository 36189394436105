<div *ngIf="(dataPagingStore.data.sourceItems?.length || 0) == 0" class="mt-4">No alerts were found for this device.</div>
<form *ngIf="dataPagingStore.data.sourceItems?.length > 0" class="side-panel-alerts">
	<div class="alert-items-header py-2">
		<div *ngIf="(dataPagingStore.data.selectedItems?.length ?? 0) == 0" class="d-flex flex-row align-items-center justify-content-between">
			<div>{{ pagingStatus }}</div>
			<div class="alert-range-label">
				<i>Last 30 days</i>
			</div>
			<div>
				<input placeholder="Search..." class="form-control ms-2 search" type="text" name="searchTerm" [(ngModel)]="dataPagingStore.data.searchTerm" />
			</div>
		</div>
		<div *ngIf="dataPagingStore.data.selectedItems?.length > 0" class="d-flex flex-row align-items-center justify-content-between">
			<div class="font-italic">{{ dataPagingStore.data.selectedItems.length }} selected</div>
			<div>
				<button type="button" class="btn btn-link text-light mx-1" (click)="acknowledgeSelectedAlerts()">
					<fa-icon icon="eye" class="me-1"></fa-icon>Acknowledge
				</button>
				<button type="button" class="btn btn-link text-light mx-1" (click)="closeSelectedAlerts()">
					<fa-icon icon="bell-slash" class="me-1"></fa-icon>Close
				</button>
			</div>
		</div>
	</div>
	<ng-container *ngIf="dataPagingStore.data.items?.length > 0">
		<div class="alert-item row" *ngFor="let alertItem of dataPagingStore.data.items">
			<div class="alert-item-content">
				<div class="alert-item-header">
					<div class="form-check">
						<input
							type="checkbox"
							class="form-check-input"
							id="select_{{ alertItem.alertId }}"
							[ngModel]="alertItem.selected"
							[ngModelOptions]="{ standalone: true }"
							(change)="dataPagingStore.helpers.selectItem(alertItem)" />
						<label class="form-check-label" for="select_{{ alertItem.alertId }}">{{ alertItem.alertTypeName }}</label>
					</div>
					<app-root-monitor-cloud-cell-alert-status [alertStatus]="alertItem.status"></app-root-monitor-cloud-cell-alert-status>
				</div>
				<div class="ms-4">
					<div class="alert-item-message">{{ alertItem.message }}</div>
					<div class="d-flex flex-row w-100">
						<div class="alert-item-timestamp">
							{{ alertItem.timestamp | date: 'MM/dd/yyyy' }}
							{{ alertItem.timestamp | date: 'HH:mm:ss' }}
						</div>
						<div class="alert-item-serial ms-auto">{{ alertItem.serialNumber }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-row alert-items-footer align-items-center">
			<ngb-pagination
				[ellipses]="true"
				[maxSize]="1"
				[collectionSize]="dataPagingStore.data.total"
				[(page)]="dataPagingStore.data.pageIndex"
				[pageSize]="dataPagingStore.data.pageSize"
				[boundaryLinks]="true"></ngb-pagination>
			<label class="page-size d-flex align-items-center justify-content-center ms-auto flex-column">
				<div class="me-1">show</div>
				<select class="form-select form-select-sm" style="width: auto" name="pageSize" [(ngModel)]="dataPagingStore.data.pageSize">
					<option [ngValue]="10">10</option>
					<option [ngValue]="20">20</option>
					<option [ngValue]="50">50</option>
				</select>
				<div class="ms-1">per page</div>
			</label>
		</div>
	</ng-container>
	<ng-container *ngIf="(dataPagingStore.data.items?.length || 0) == 0">
		<div class="mt-2 font-italic">No matching alerts found.</div>
	</ng-container>
</form>
