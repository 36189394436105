<form [formGroup]="parametersForm" autocomplete="off" novalidate>
	<div class="mb-3 col-12">
		<shared-context-message type="info"> The application must support silent mode and include the correct arguments. </shared-context-message>
	</div>
	<div class="mb-3 col-12">
		<div class="form-check">
			<input
				type="checkbox"
				class="form-check-input"
				name="rebootDevice"
				id="rebootDevice"
				formControlName="rebootDevice"
				[readonly]="readonly === true" />
			<label class="form-check-label" for="rebootDevice">Reboot Device</label>
			<div
				ngbTooltip="(Optional) Specify whether to reboot the device after installing the application. The default value is False."
				placement="right"
				container="body"
				class="d-inline ms-1 align-self-baseline">
				<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
			</div>
		</div>
	</div>
	<div class="mb-3 col-12">
		<label for="args" class="form-label">Arguments</label>
		<div
			ngbTooltip="Specify any arguments that should be used when running the application."
			placement="right"
			container="body"
			class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<input type="text" class="form-control" formControlName="args" id="args" [readonly]="readonly" />
	</div>
	<div class="mb-3 col-12">
		<label for="file" class="form-label">Application File</label>
		<div ngbTooltip="Max file upload size: 2 GB." placement="right" container="body" class="d-inline ms-1 align-self-baseline">
			<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
		</div>
		<div *ngIf="readonly !== true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div class="pb-2">
					<div class="form-check">
						<input
							type="radio"
							id="fileExisting"
							name="fileExisting"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.Existing"
							(change)="resetFileEvent()" />
						<label for="fileExisting" class="form-check-label">Use Previous Application File</label>
					</div>
					<div class="form-check ms-3">
						<input
							type="radio"
							id="fileNew"
							name="fileNew"
							class="form-check-input"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="fileMode"
							[value]="fileModeOptions.New"
							(change)="resetFileEvent()" />
						<label for="fileNew" class="form-check-label">Upload New Application File</label>
					</div>
				</div>
				<div class="p-3 box-shadow">
					<ng-contaier *ngIf="fileMode === fileModeOptions.Existing">
						<div class="mb-3 font-italic">Use the existing Application File from the copied task:</div>
						<div *ngFor="let taskFile of taskFiles">
							<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">
								<input class="me-3" type="checkbox" readonly="readonly" [checked]="true" />
								{{ taskFile.fileName }}
							</button>
						</div>
					</ng-contaier>
					<ng-contaier *ngIf="fileMode === fileModeOptions.New">
						<div class="mb-3 font-italic">Upload a new Application File:</div>
						<shared-file-upload [reset$]="reset$" name="file" (fileSelected)="fileSelected($event)"></shared-file-upload>
					</ng-contaier>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) === 0">
				<shared-file-upload [reset$]="reset$" name="file" (fileSelected)="fileSelected($event)"></shared-file-upload>
			</ng-container>
		</div>
		<div *ngIf="readonly === true">
			<ng-container *ngIf="taskFiles?.length > 0">
				<div *ngFor="let taskFile of taskFiles">
					<button class="btn btn-link" (click)="downloadTaskFile(taskFile)">{{ taskFile.fileName }}</button>
				</div>
			</ng-container>
			<ng-container *ngIf="(taskFiles?.length || 0) == 0">
				<i>No files available</i>
			</ng-container>
		</div>
	</div>
</form>
