import { Component, OnInit } from '@angular/core';
import { AppConfig } from '@razberi-ui/shared';

@Component({
	selector: 'theme-razberi-activation-toolbar',
	templateUrl: './theme-razberi-activation-toolbar.component.html',
	styleUrls: ['./theme-razberi-activation-toolbar.component.scss'],
})
export class ThemeRazberiActivationToolbarComponent implements OnInit {
	constructor(readonly appConfig: AppConfig) {}

	ngOnInit(): void {}
}
