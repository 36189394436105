import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';

[UntilDestroy({ checkProperties: true })];
@Component({
	selector: 'shared-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
	@Input() name: string;
	@Input() reset$: Observable<void>;
	@Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();

	@ViewChild('fileInput') fileInput: ElementRef;

	private subscriptions: Subscription = new Subscription();
	private file: File = null;

	constructor() {}

	ngOnInit(): void {
		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe(() =>
					setTimeout(() => {
						this.fileInput.nativeElement.value = null;
					}, 0)
				)
			);
	}

	// on file select
	onChange(event) {
		this.file = event.target.files[0];
		this.fileSelected.emit(this.file);
	}
}
