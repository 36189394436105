import { Alert } from './alert.model';
import { ManagedDeviceLldpNeighbor } from './managed-device-lldp-neighbor.model';
import { NetworkLinkStatus, NetworkLinkType } from './network-link.model';
import { Tag } from './tag.model';
import { Unit } from './unit.model';
import { EnumAttributes } from '@razberi-ui/shared';

export enum ManagedDeviceType {
	Unknown = 0,
	Agent = 1,
	InternalSwitch = 2,
	EndpointDefender = 3,
	SnmpDevice = 4,
	CngeSwitch = 5,
	PingDevice = 6,
	HttpDevice = 7,
}

export const ManagedDeviceTypeAttributes = new Map<ManagedDeviceType, EnumAttributes>([
	[ManagedDeviceType.Unknown, { label: 'Unknown' }],
	[ManagedDeviceType.Agent, { label: 'Agent' }],
	[ManagedDeviceType.InternalSwitch, { label: 'Internal Switch' }],
	[ManagedDeviceType.EndpointDefender, { label: 'Endpoint Defender' }],
	[ManagedDeviceType.SnmpDevice, { label: 'SNMP Device' }],
	[ManagedDeviceType.CngeSwitch, { label: 'CNGE Switch' }],
	[ManagedDeviceType.PingDevice, { label: 'Ping Device' }],
	[ManagedDeviceType.HttpDevice, { label: 'Http Device' }],
]);

export enum ManagedDeviceStatus {
	Online = 0,
	Unavailable = 1,
	Error = 2,
	Offline = 3,
}

export interface ManagedDevice {
	managedDeviceId: number;
	managedDeviceKey: string;
	accountId: number;
	unitId: number;
	unitKey: string;
	name: string;
	location: string;
	type: ManagedDeviceType;
	status: ManagedDeviceStatus;
	statusMessage: string;
	manufacturer: string;
	modelName: string;
	serialNumber: string;
	ports: number;
	firmware: string;
	macAddress: string;
	ipAddress: string;
	subnetMask: string;
	gateway: string;
	dnsServer: string;
	connectionType: string;
	dhcpServerEnabled: boolean;
	thumbprint?: string;
	customData: string;
	registerTimestamp: Date;
	createTimestamp: Date;
	updateTimestamp: Date;
	lldpNeighbors: ManagedDeviceLldpNeighbor[];
	alerts: Alert[];
	unit: Unit;
	cloudLocation?: Location;
	tags?: Tag[];
	processedLocationName?: string;
	// network related properties
	isNetworkRoot?: boolean;
	networkGuid?: string;
	networkParentGuid?: string;
	// UI properties
	nameFormatted?: string;
	typeFormatted?: string;
	typeFormattedPlural?: string;
	cloudLocationId?: number;
	suppliedSourceLinkType?: NetworkLinkType;
	suppliedSourceLinkStatus?: NetworkLinkStatus;
	// Agent DTO properties
	registered?: boolean;
	registeredTo?: string;
	cameraDefenseStatus?: any;
}

export interface ManagedDeviceExtensionInternalSwitch {
	maximumPoEPower?: number;
	poeConsumptionPower?: number;
	firmwareLicenseName?: string;
	firmwareLicenseCustomer?: string;
	firmwareLicenseDate?: Date;
	firmwareLicenseExpiration?: Date;
	firmwareLicenseFeatures?: string;
	cameraDefenseEnabled?: boolean;
	cameraDefenseStatus?: number; // enum
	cameraHealthEnabled?: boolean;
	cameraHealthStatus?: number; // enum
	location?: string;
	isLicensed?: boolean;
	internalSwitchType?: number; // enum
}

export interface ManagedDeviceExtensionEndpointDefender {
	maximumPoEPower?: number;
	poeConsumptionPower?: number;
	firmwareLicenseName?: string;
	firmwareLicenseCustomer?: string;
	firmwareLicenseDate?: Date;
	firmwareLicenseExpiration?: Date;
	firmwareLicenseFeatures?: string;
	cameraDefenseEnabled?: boolean;
	cameraDefenseStatus?: number; // enum
	cameraHealthEnabled?: boolean;
	cameraHealthStatus?: number; // enum
	location?: string;
	isLicensed?: boolean;
	internalSwitchType?: number; // enum
}

export interface ManagedDeviceExtensionSnmpDevice {
	maximumPoEPower?: number;
	poeConsumptionPower?: number;
	snmpServices?: number;
}

export interface ManagedDeviceExtensionCngeDevice {
	maximumPoEPower?: number;
	poeConsumptionPower?: number;
}

export interface ManagedDeviceExtensionAgent {}
