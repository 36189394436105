import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { Location } from '@razberi-ui/core/data-types';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-location-form',
	templateUrl: './location-form.component.html',
	styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent {
	@Input() location: Location;
	@Input() reset$: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<Location> = new EventEmitter<Location>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();
	locationForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private readonly logger: NGXLogger, private readonly fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.locationForm = this.fb.group({
			name: [null, [Validators.required]],
			description: [null, [Validators.required]],
		});

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.logger.log('reset');
						this.locationForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.locationForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.locationForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		this.locationForm.updateValueAndValidity();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.location?.currentValue) this.locationForm.patchValue(changes.location.currentValue);
	}

	submitForm() {
		if (this.locationForm.valid) this.submit.emit();
	}
}
