<ng-container *ngIf="selectableUnit.isHidden !== true">
	<div
		class="selectable-object-item"
		[ngClass]="{
			'card-type': selectableUnit.mode === selectableModeOptions.card,
			'row-type': selectableUnit.mode === selectableModeOptions.row,
			'expanded-type': selectableUnit.mode === selectableModeOptions.expanded
		}">
		<div class="d-flex align-items-stretch cursor-default">
			<div class="item-title d-flex">
				<div *ngIf="selectableUnit.readonly !== true">
					<input
						type="checkbox"
						class="cursor-pointer mx-3"
						id="selectUnit_{{ selectableUnit.object.unitId }}"
						[(ngModel)]="selectableUnit.isSelected"
						[ngModelOptions]="{ standalone: true }"
						(change)="selectUnit()" />
				</div>
				<div>
					<ng-container *ngIf="selectableUnit.mode === selectableModeOptions.card">
						<div style="white-space: nowrap">
							<shared-cell-header-sub [header]="selectableUnit.object.name" (click)="viewDetails()"></shared-cell-header-sub>
							<div *ngIf="selectableUnit.object.location != null" class="text-muted">
								{{ selectableUnit.object.location?.name != null ? selectableUnit.object.location?.name : '--' }}
							</div>
							<div *ngIf="selectableUnit.object.tags?.length > 0" class="text-muted" [title]="getItemTagDescFull(selectableUnit.object.tags)">
								{{ getItemTagDescSummary(selectableUnit.object.tags) }}
							</div>
							<div class="mt-2">
								<app-shared-monitor-common-cell-device-status
									deviceType="Unit"
									[status]="selectableUnit.object?.status"></app-shared-monitor-common-cell-device-status>
							</div>
							<div *ngIf="selectableUnit.object.updateTags?.length > 0" class="d-flex align-items-center mt-2" style="flex-wrap: wrap">
								<app-root-monitor-cloud-cell-unit-updates
									[updateTags]="selectableUnit.object.updateTags"
									[displayCount]="1"></app-root-monitor-cloud-cell-unit-updates>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="selectableUnit.mode === selectableModeOptions.row">
						<shared-cell-header-sub [header]="selectableUnit.object.name" (click)="viewDetails()"></shared-cell-header-sub>
					</ng-container>
					<ng-container *ngIf="selectableUnit.mode === selectableModeOptions.expanded">
						<div>
							<shared-cell-header-sub [header]="selectableUnit.object.name" (click)="viewDetails()"></shared-cell-header-sub>
							<div class="mt-2">
								<app-shared-monitor-common-cell-device-status
									deviceType="Unit"
									[status]="selectableUnit.object?.status"></app-shared-monitor-common-cell-device-status>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div *ngIf="selectableUnit.mode === selectableModeOptions.row" class="data-container d-flex align-items-start justify-content-start">
				<div style="flex: 0 0 19%; margin-right: 1%">
					<shared-cell-header-sub
						[header]="selectableUnit.object.modelName ?? '--'"
						[sub]="selectableUnit.object.manufacturer"
						(click)="viewDetails()"></shared-cell-header-sub>
				</div>
				<div style="flex: 0 0 14%; margin-right: 1%">
					<app-shared-monitor-common-cell-device-status
						deviceType="Unit"
						[status]="selectableUnit.object?.status"></app-shared-monitor-common-cell-device-status>
				</div>
				<div style="flex: 0 0 19%; margin-right: 1%">
					<span class="text-muted">{{ selectableUnit.object.location?.name || '--' }}</span>
				</div>
				<div style="flex: 0 0 22%; margin-right: 1%">
					<span class="text-muted" [title]="getItemTagDescFull(selectableUnit.object.tags)">{{
						getItemTagDescSummary(selectableUnit.object.tags)
					}}</span>
				</div>
				<div style="flex: 0 0 22%">
					<app-root-monitor-cloud-cell-unit-updates
						[updateTags]="selectableUnit.object.updateTags"
						[displayCount]="1"></app-root-monitor-cloud-cell-unit-updates>
				</div>
			</div>
			<div *ngIf="selectableUnit.mode === selectableModeOptions.expanded" class="data-container ms-3 me-3 ps-4">
				<div class="d-flex pb-2" style="flex-wrap: wrap">
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">Make:</span>
						<span class="text-muted">{{ selectableUnit.object.manufacturer || '--' }}</span>
					</div>
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">Model:</span>
						<span class="text-muted">{{ selectableUnit.object.modelName || '--' }}</span>
					</div>
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">Created:</span>
						<span class="text-muted">{{ (selectableUnit.object.createTimestamp | date: 'MM/dd/yy') || '--' }}</span>
					</div>
				</div>
				<div class="d-flex pb-2" style="flex-wrap: wrap">
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">Loc:</span>
						<span class="text-muted">{{ selectableUnit.object.location?.name || '--' }}</span>
					</div>
					<div>
						<span class="me-2" style="font-weight: 500">Tags:</span>
						<span class="text-muted">{{ getItemTagDescFull(selectableUnit.object.tags) }}</span>
					</div>
				</div>
				<div class="d-flex pb-2" style="flex-wrap: wrap">
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">Last Boot:</span>
						<span class="text-muted">{{ (selectableUnit.object.lastBoot | date: 'MM/dd/yy') || '--' }}</span>
					</div>
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">O/S:</span>
						<span class="text-muted">{{ selectableUnit.object.operatingSystem || '--' }}</span>
					</div>
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">Bios:</span>
						<span class="text-muted">{{ selectableUnit.object.bios || '--' }}</span>
					</div>
				</div>
				<div class="d-flex pb-2" style="flex-wrap: wrap">
					<div class="me-4">
						<span class="me-2" style="font-weight: 500">.NET:</span>
						<span class="text-muted">{{ selectableUnit.object.dotNetVersion || '--' }}</span>
					</div>
					<div>
						<span class="me-2" style="font-weight: 500">Powershell:</span>
						<span class="text-muted">{{ selectableUnit.object.powershellVersion || '--' }}</span>
					</div>
				</div>
				<div *ngIf="selectableUnit.object.updateTags?.length > 0" class="d-flex align-items-center" style="flex-wrap: wrap">
					<span class="me-2" style="font-weight: 500">Updates:</span>
					<app-root-monitor-cloud-cell-unit-updates
						[updateTags]="selectableUnit.object.updateTags"
						[displayCount]="3"></app-root-monitor-cloud-cell-unit-updates>
				</div>
			</div>
		</div>
	</div>
</ng-container>
