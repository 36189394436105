import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AuthService } from '@razberi-ui/api/cloud-api';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-auth-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
	subscriptions: Subscription = new Subscription();

	constructor(private readonly authService: AuthService) {}

	ngOnInit() {
		this.subscriptions.add(this.authService.helpers.logout().subscribe((_) => {}));
	}
}
