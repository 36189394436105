import { Component, Input } from '@angular/core';
import { DateRange } from '@razberi-ui/shared';

@Component({
	selector: 'app-root-monitor-cloud-alert-tabs',
	templateUrl: './alert-tabs.component.html',
	styleUrls: ['./alert-tabs.component.scss'],
})
export class AlertTabsComponent {
	@Input() accountIds: number[];
	@Input() alertTypeIds: number[];
	@Input() dateRange: DateRange;
	@Input() showAccount: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() dataSource: string;

	isInitialized: boolean = false;

	constructor() {}

	ngOnInit() {
		this.isInitialized = true;
	}
}
