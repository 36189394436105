<div class="fs-4 mb-2">Offline Devices</div>
<ul ngbNav #panel="ngbNav" [activeId]="selectedTab" class="nav-tabs page-content-tabs">
	<li ngbNavItem="units">
		<a ngbNavLink><fa-icon icon="server"></fa-icon> Agents</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-unit-table
				[showLocation]="true"
				[showTags]="true"
				[showAccount]="true"
				[showRefresh]="true"
				[showSearch]="true"
				[dataSource]="'dashboard-offline'"></app-root-monitor-cloud-unit-table>
		</ng-template>
	</li>
	<li ngbNavItem="managed-devices">
		<a ngbNavLink><fa-icon icon="router"></fa-icon> Network Devices</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-managed-device-table [showAccount]="true" [dataSource]="'dashboard-offline'"></app-root-monitor-cloud-managed-device-table>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="panel"></div>
