<div class="container mt-5">
	<div class="row justify-content-center">
		<div class="col-{{ canManageSubscription ? '8' : '6' }}">
			<app-root-monitor-cloud-account-subscription-form
				*ngIf="canManageSubscription && accountSubscription && accountStat"
				[accountSubscription]="accountSubscription"
				[accountStat]="accountStat"
				(isValid)="onIsValid($event)"
				(changes)="onChanges($event)"
				(submit)="onSubmit()"></app-root-monitor-cloud-account-subscription-form>
			<app-root-monitor-cloud-account-subscription-view
				*ngIf="!canManageSubscription && accountSubscription && accountStat"
				[accountSubscription]="accountSubscription"
				[accountStat]="accountStat"></app-root-monitor-cloud-account-subscription-view>
		</div>
	</div>
</div>
