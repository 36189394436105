import { Component } from '@angular/core';
import { UserComplianceRegion } from '@razberi-ui/core/data-types';
import { UserComplianceService } from '../../../services/user-compliance.service';
import { AlertMessageService, NavService, PageConfigService } from '@razberi-ui/shared';
import { Router } from '@angular/router';
import { AuthService } from '@razberi-ui/api/cloud-api';

enum pageStatus {
	RegionSelect = 0,
	Agreement = 1,
	Rejected = 2,
	Consented = 3,
}

@Component({
	selector: 'razberi-ui-reseller',
	templateUrl: './reseller.component.html',
	styleUrls: ['./reseller.component.scss'],
})
export class ResellerComponent {
	pageStatusOptions: any = pageStatus;
	pageStatus: pageStatus = pageStatus.RegionSelect;
	token: string;
	message: string;
	agreement: string;
	resellerRegion: UserComplianceRegion;
	resellerRegionOptions: any = UserComplianceRegion;
	scrollCompleteFunc: any;
	scrollIsComplete: boolean = false;
	isLoggedOut: boolean = false;

	constructor(
		private readonly userComplianceService: UserComplianceService,
		private readonly authService: AuthService,
		private readonly alertMessageService: AlertMessageService,
		private readonly router: Router,
		private readonly navService: NavService,
		readonly configService: PageConfigService
	) {}

	ngOnInit(): void {
		this.scrollCompleteFunc = this.markScrollComplete.bind(this);
	}

	selectRegion(region: UserComplianceRegion) {
		this.resellerRegion = region;
		this.userComplianceService.api.getResellerAgreement(this.resellerRegion).subscribe((result) => {
			this.pageStatus = pageStatus.Agreement;
			this.agreement = result;
		});
		this.getAgreement();
	}

	getAgreement() {}

	markScrollComplete() {
		this.scrollIsComplete = true;
	}

	consent(consented: boolean) {
		this.userComplianceService.api.signReseller(this.resellerRegion, consented).subscribe({
			next: (_) => {
				this.pageStatus = consented === true ? pageStatus.Consented : pageStatus.Rejected;
				this.alertMessageService.success('The Reseller Agreement was successfully signed');
				this.authService.data.resellerComplianceRequired = !consented;
				if (consented === true) {
					this.router.navigate([this.authService.data.redirectUrl ?? this.navService.data.authenticatedDefaultUrl]);
					this.alertMessageService.success('The Reseller Agreement was successfully signed.', { keepAfterRouteChange: true });
				} else {
					this.authService.helpers.logout().subscribe((_) => {
						this.isLoggedOut = true;
					});
				}
			},
			error: (_) => {
				this.message = 'Your Reseller agreement could not be signed.';
			},
		});
	}

	gotoLogin() {
		this.router.navigate(['/login']);
	}
}
