import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
	DataSearchConfiguration,
	CloudApiConfigService,
	CoreServerSettingsService,
	SearchFilter,
	SearchFilterResult,
	AuthService,
} from '@razberi-ui/api/cloud-api';
import { Account, AccountStatus, AccountType, Unit, UpdateType, UserRole } from '@razberi-ui/core/data-types';
import {
	AlertMessageService,
	ConfirmModalComponent,
	ConfirmService,
	PageSidePanelService,
	TableColumnSetting,
	TableItemSelectMode,
	TableServerSidePagingConfig,
	TableSettings,
} from '@razberi-ui/shared';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { UnitService } from '../../../services/unit.service';
import { ReportService } from '../../../services/report.service';
import { DashboardService } from '../../../services/dashboard.service';
import { Router } from '@angular/router';
import { DeviceEditModalComponent } from '../../devices/device-edit-modal/device-edit-modal.component';
import { HttpResponse } from '@angular/common/http';
import { UnitGlobalSearchModalComponent } from '../unit-global-search-modal/unit-global-search-modal.component';
import { UnitOverrideWarrantyModalComponent } from '../unit-override-warranty-modal/unit-override-warranty-modal.component';
import { UnitUpdatesTaskSelectorModalComponent } from '../unit-updates-task-selector-modal/unit-updates-task-selector-modal.component';
import { DataStoreService } from '../../../services/data-store.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-unit-table',
	templateUrl: './unit-table.component.html',
	styleUrls: ['./unit-table.component.scss'],
})
export class UnitTableComponent {
	@Input() accountIds: number[];
	@Input() showLocation: boolean = true;
	@Input() showTags: boolean = true;
	@Input() showAccount: boolean = false;
	@Input() showUpdates: boolean = true;
	@Input() showCameraDefenseStatus: boolean = false;
	@Input() showMalwareProtectionStatus: boolean = false;
	@Input() showRefresh: boolean = true;
	@Input() showDownloadCsv: boolean = true;
	@Input() showSerialNumberSearch: boolean = false;
	@Input() showSearch: boolean = true;
	@Input() showPageSize: boolean = true;
	@Input() unitData: Unit[];
	@Input() dataSource: string;
	@Input() lookup: string;
	@Input() enableUpdateMode: boolean = false;
	@Input() refresh$: Observable<void>;
	@Output() update: EventEmitter<void> = new EventEmitter<void>();

	pageNumber: number = 1;
	pageSize: number = 10;
	units: Unit[] = [];
	initialSearchFilter: SearchFilter = {};
	searchFilter: SearchFilter = {};
	searchConfiguration: DataSearchConfiguration;
	tableSettings: TableSettings;
	subscriptions: Subscription = new Subscription();
	isDataSourceReady: boolean;
	isInitialized: boolean;
	updateTypes: UpdateType[] = [];

	constructor(
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly confirmService: ConfirmService,
		private readonly authService: AuthService,
		private readonly subscriptionService: SubscriptionService,
		private readonly unitService: UnitService,
		private readonly reportService: ReportService,
		private readonly dashboardService: DashboardService,
		private readonly settingsService: CoreServerSettingsService,
		private readonly configService: CloudApiConfigService,
		private readonly dataStoreService: DataStoreService,
		private readonly router: Router,
		private readonly pageSidePanelService: PageSidePanelService
	) {}

	ngOnInit() {
		this.buildTableSettings();

		if (this.refresh$)
			this.subscriptions.add(
				this.refresh$.subscribe((_) => {
					this.getDevices();
				})
			);

		if (this.enableUpdateMode === true) {
			this.unitService.api.getUpdateTypes().subscribe((types) => {
				this.updateTypes = types;
				this.initDataSource();
			});
		} else {
			this.initDataSource();
		}
	}

	initDataSource() {
		this.searchConfiguration = {
			showSearchTerm: true,
			showAccounts: this.showAccount === true && this.dataSource?.startsWith('dashboard') !== true,
			showLocations: true,
			showTags: true,
			showUnitStatuses: this.dataSource === 'dashboard-offline' ? false : true,
			showUpdateTypes: true,
		};

		if (this.dataSource?.startsWith('dashboard')) {
			this.subscriptions.add(
				this.dashboardService.streams.selectedAccounts$.pipe(distinctUntilChanged()).subscribe((accounts: Account[]) => {
					if (accounts) {
						this.accountIds = accounts.map((account: Account) => account.accountId);
						this.isDataSourceReady = this.accountIds?.length > 0;
						if (this.isDataSourceReady === true) this.getDevices();
					}
				})
			);
		} else if (this.dataSource === 'lookup') {
			this.isDataSourceReady = true;
			if (this.lookup) this.getDevices();
		} else {
			if (
				!this.accountIds?.length &&
				this.authService.data.account.type === AccountType.Global &&
				this.settingsService.data.settings?.cloudUI?.preloadSearchFilterAccounts === true
			) {
				this.initialSearchFilter.accounts = [1, 2, 3, 4, 5].map((accountId: number) => {
					return { accountId: accountId };
				});
			}

			this.searchFilter = { ...this.initialSearchFilter };
			this.isDataSourceReady = true;
			this.getDevices();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.isInitialized) {
			if (changes.lookup?.currentValue) {
				this.lookup = changes.lookup.currentValue;
				this.isDataSourceReady = true;
			}
			this.getDevices();
		}
	}

	buildTableSettings() {
		const tableHeaderButtons = this.getTableHeaderButtons();
		const ts: TableSettings = {
			itemSelectMode: tableHeaderButtons?.length > 0 ? TableItemSelectMode.Multi : TableItemSelectMode.None,
			useAdvancedSearch: this.showSearch,
			useEdit: true,
			usePageSize: this.showPageSize,
			hidePagination: false,
			hideTotal: false,
			hideFilter: false,
			useServerSidePagingAndFiltering: this.unitData ? false : true,
			serverSidePagingAndFilterConfig: this.unitData ? null : { pageNumber: 1, pageSize: 10, totalCount: 0 },
			uniqueId: 'unitId',
			headerButtons: tableHeaderButtons,
			stickyHeaderButtons: [],
			columnConfig: [
				{
					primaryKey: 'status',
					header: '',
					cellType: 'device-status',
					transform: (value, tableItem) => {
						return {
							deviceType: 'Unit',
							status: value,
							showText: false,
						};
					},
				},
				{
					primaryKey: 'name',
					header: 'Name',
					cellType: 'unit-title',
					clickHandlerFunction: (params) => {
						this.openSidePanel(params, 'details');
					},
				},
				{
					primaryKey: 'manufacturer',
					header: 'Type',
					cellType: 'header-sub',
					transform: (value, tableItem) => {
						return {
							header: tableItem.manufacturer,
							sub: tableItem.modelName,
						};
					},
				},
				{
					primaryKey: 'accountName',
					header: 'Account',
					cellType: 'router-link',
					transform: (value, tableItem) => {
						if (value) {
							const c: any = {
								text: value,
								route: ['/accounts', tableItem.accountId],
								subtexts: [],
								disabled: tableItem.accountStatus === AccountStatus.Pending,
							};
							if (tableItem.accountFaviconUrl) c.image = `${this.configService.data.config.apiHost}${tableItem.accountFaviconUrl}`;
							else c.icon = 'building';
							return c;
						} else return { text: '--', disabled: true };
					},
				},
				{
					primaryKey: 'locationName',
					header: 'Location',
					cellType: 'icon',
					transform: (value, tableItem) => {
						return !value ? { text: '--' } : { text: value, icon: 'map-marker-alt' };
					},
				},
				{ primaryKey: 'tags', header: 'Tags', cellType: 'pills' },
				{
					primaryKey: 'updateTags',
					header: 'Updates',
					cellType: 'unit-updates',
				},
				{ primaryKey: 'cameraDefenseEnabled', header: 'CameraDefense', cellType: 'camera-defense-status' },
				{ primaryKey: 'malwareProtectionEnabled', header: 'Malware Protection', cellType: 'malware-protection-status' },
			],
			editActions: [
				{
					label: 'View Details',
					faIcon: 'eye',
					clickHandlerFunction: (params) => {
						if (!this.subscriptionService.helpers.isGlobalOrIsStatusValid(params.accountSubscriptionStatus)) {
							this.subscriptionService.helpers.showInvalidSubscriptionModal();
							return;
						}
						this.openSidePanel(params, 'details');
					},
				},
				{
					label: 'View Alerts',
					faIcon: 'bell',
					clickHandlerFunction: (params) => {
						if (!this.subscriptionService.helpers.isGlobalOrIsStatusValid(params.accountSubscriptionStatus)) {
							this.subscriptionService.helpers.showInvalidSubscriptionModal();
							return;
						}
						this.openSidePanel(params, 'alerts');
					},
				},
				{
					label: 'View Updates',
					faIcon: 'arrow-to-bottom',
					clickHandlerFunction: (params) => {
						if (!this.subscriptionService.helpers.isGlobalOrIsStatusValid(params.accountSubscriptionStatus)) {
							this.subscriptionService.helpers.showInvalidSubscriptionModal();
							return;
						}
						this.openSidePanel(params, 'updates');
					},
				},
			],
		};
		if (this.showSerialNumberSearch)
			ts.stickyHeaderButtons.push({
				label: 'Global Search',
				faIcon: 'search',
				clickHandlerFunction: () => {
					this.showSerialSearch();
				},
			});
		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});
		if (this.showRefresh)
			ts.stickyHeaderButtons.push({
				label: 'Refresh',
				faIcon: 'sync',
				clickHandlerFunction: () => {
					if (this.refresh$) this.update.emit();
					else this.getDevices();
				},
			});
		if (
			this.showAccount != true ||
			(this.authService.data.account.type !== AccountType.Global && this.authService.data.account.type !== AccountType.Provider)
		) {
			const accountIdx: number = ts.columnConfig.findIndex((columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'accountName');
			if (accountIdx >= 0) ts.columnConfig.splice(accountIdx, 1);
		}
		if (this.showLocation != true) {
			const locationIdx: number = ts.columnConfig.findIndex((columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'locationName');
			if (locationIdx >= 0) ts.columnConfig.splice(locationIdx, 1);
		}
		if (this.showTags != true) {
			const tagsIdx: number = ts.columnConfig.findIndex((columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'tags');
			if (tagsIdx >= 0) ts.columnConfig.splice(tagsIdx, 1);
		}
		if (this.showUpdates != true) {
			const updatesTagsIdx: number = ts.columnConfig.findIndex((columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'updateTags');
			if (updatesTagsIdx >= 0) ts.columnConfig.splice(updatesTagsIdx, 1);
		}
		if (this.showCameraDefenseStatus != true) {
			const cameraDefenseStatusIdx: number = ts.columnConfig.findIndex(
				(columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'cameraDefenseEnabled'
			);
			if (cameraDefenseStatusIdx >= 0) ts.columnConfig.splice(cameraDefenseStatusIdx, 1);
		}
		if (this.showMalwareProtectionStatus != true) {
			const malwareProtectionStatusIdx: number = ts.columnConfig.findIndex(
				(columnSetting: TableColumnSetting) => columnSetting.primaryKey === 'malwareProtectionEnabled'
			);
			if (malwareProtectionStatusIdx >= 0) ts.columnConfig.splice(malwareProtectionStatusIdx, 1);
		}
		if (this.authService.data.account.type !== AccountType.Customer || this.authService.helpers.userHasRole(UserRole.Administrator) == true) {
			ts.editActions.push({
				label: 'Edit Agent',
				faIcon: 'edit',
				clickHandlerFunction: (params) => {
					this.editUnit(params);
				},
			});
		}
		if (
			this.enableUpdateMode === true &&
			this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user)
		) {
			ts.editActions.push({
				label: 'Create Update Task',
				faIcon: 'tasks',
				clickHandlerFunction: (params) => {
					this.createTaskSingleDevice(params);
				},
			});
		} else {
			if (this.authService.data.account.type === AccountType.Global) {
				ts.editActions.push({
					label: 'View Activation Code',
					faIcon: 'eye',
					clickHandlerFunction: (params) => {
						this.displayActivationCode(params);
					},
				});
				if (this.settingsService.data.settings?.cloudUI?.allowUnitWarrantyOverride === true) {
					var expirationAction = {
						label: 'Override Warranty Expiration',
						faIcon: 'edit',
						clickHandlerFunction: (params) => {
							this.displayOverrideWarranty(params);
						},
					};
					const activationCodeIdx: number = ts.editActions.findIndex((itm: any) => itm.label === 'View Activation Code') + 1;
					ts.editActions.splice(activationCodeIdx, 0, expirationAction);
				}
			}
			if (
				this.settingsService.data.settings?.cloudUI?.allowUnitDelete === true &&
				(this.authService.data.account.type !== AccountType.Customer || this.authService.helpers.userHasRole(UserRole.Administrator) == true)
			) {
				ts.editActions.push({
					label: 'Delete Agent',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteDevice(params);
					},
				});
			}
			if (this.authService.data.account.type === AccountType.Global && this.settingsService.data.settings?.cloudUI?.allowUnitDeletePermanent === true) {
				ts.editActions.push({
					label: 'Permanently Delete Agent',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.permanentlyDeleteDevice(params);
					},
				});
			}
		}

		this.tableSettings = ts;
	}

	getTableHeaderButtons() {
		if (this.enableUpdateMode === true && this.subscriptionService.helpers.hasManageWriteAccess(this.authService.data.account, this.authService.data.user))
			return [
				{
					label: 'Create Update Task',
					faIcon: 'tasks',
					clickHandlerFunction: (params) => {
						this.createTask(params.value);
					},
				},
			];
		else if (this.authService.data.account.type !== AccountType.Customer || this.authService.helpers.userHasRole(UserRole.Administrator) == true)
			return [
				{
					label: 'Delete Agents',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteDevices(params.value);
					},
				},
			];
		else return [];
	}

	openSidePanel(unit: Unit, activeTabKey: string) {
		this.pageSidePanelService.helpers.openUnitSidePanel(
			unit.name,
			activeTabKey,
			unit.accountId,
			unit.unitId,
			this.unitService.helpers.userCanEditUnit(),
			(unit) => {
				this.editUnit(unit);
			}
		);
	}

	getDevices() {
		if (this.unitData) {
			this.units = [...this.unitData];
			this.isInitialized = true;
		} else {
			if (!this.dataSource || this.isDataSourceReady) {
				const sf: SearchFilter = {
					pageNumber: this.pageNumber,
					pageSize: this.pageSize,
					accounts: this.accountIds?.map((accountId: number) => {
						return { accountId: accountId };
					}),
					sortAsc: true,
					...this.searchFilter,
				};

				if (this.enableUpdateMode === true && (sf.updateTypeIds?.length || 0) == 0) sf.updateTypeIds = this.updateTypes.map((ut) => ut.updateTypeId);

				let o: Observable<SearchFilterResult<Unit>>;
				if (this.dataSource === 'dashboard-cybersecurity') o = this.dashboardService.api.getCybersecurityDevices(sf);
				else if (this.dataSource === 'dashboard-malware-protection') o = this.dashboardService.api.getMalwareProtectionDevices(sf);
				else if (this.dataSource === 'dashboard-camera-defense') o = this.dashboardService.api.getCameraDefenseDevices(sf);
				else if (this.dataSource === 'dashboard-offline') o = this.dashboardService.api.getOfflineDevices(sf);
				else if (this.dataSource === 'lookup' && this.lookup) o = this.unitService.api.getUnitsByLookup(this.lookup, sf);
				else o = this.unitService.api.getUnits(sf);
				o.subscribe((result) => {
					let { pageNumber, pageSize, totalCount, results } = result;
					this.tableSettings.serverSidePagingAndFilterConfig = { pageNumber, pageSize, totalCount };
					this.units = results;
					this.isInitialized = true;
				});
			}
		}
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.getDevices();
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
		this.getDevices();
	}

	editUnit(unit: Unit) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.accountId = unit.accountId;
		modal.componentInstance.unitId = unit.unitId;
		modal.result.then(
			(_) => {
				this.update.emit();
			},
			(_) => {}
		);
	}

	deleteDevice(device: Unit) {
		this.confirmService.confirm({ title: 'Delete Agent?', text: `Are you sure you want to delete '${device.name}'?` }).then(
			(_) => {
				this.unitService.api.deleteUnit(device.accountId, device.unitId).subscribe({
					next: (_) => {
						this.alertMessageService.success('Device deleted.');
						this.update.emit();
					},
					error: (error) => {
						this.alertMessageService.error('Error deleting Agent.', error);
					},
				});
			},
			(_) => {}
		);
	}

	deleteDevices(devices: Unit[]) {
		this.confirmService
			.confirm({
				title: 'Delete devices?',
				text: `Are you sure you want to delete ${devices.length} devices?`,
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(
				(_) => {
					devices.forEach((device: Unit) => {
						this.unitService.api.deleteUnit(device.accountId, device.unitId).subscribe({
							next: (_) => {
								this.alertMessageService.success('Device deleted.');
								this.update.emit();
							},
							error: (error) => {
								this.alertMessageService.error('Error deleting user.', error);
							},
						});
					});
				},
				(_) => {}
			);
	}

	permanentlyDeleteDevice(device: Unit) {
		this.confirmService
			.confirm({
				title: 'Permanently delete Agent?',
				text: `Are you sure you want to permanently delete '${device.name}'?`,
				passphraseTitle: `Type 'delete' to confirm:`,
				passphrase: 'delete',
				icon: 'trash',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'trash',
			})
			.then(
				(_) => {
					this.unitService.api.deleteUnitPermanently(device.accountId, device.unitId).subscribe({
						next: (_) => {
							this.alertMessageService.success('Device permanently deleted.');
							this.update.emit();
						},
						error: (error) => {
							this.alertMessageService.error('Error permanently deleting Agent.', error);
						},
					});
				},
				(_) => {}
			);
	}

	createTaskSingleDevice(device: Unit) {
		this.createTask([device]);
	}

	createTask(devices: Unit[]) {
		const unitIds = [...new Set(devices?.map((d) => d.unitId))];
		if (unitIds?.length == 0) {
			this.alertMessageService.warning('TNo Agents are selected.');
			return;
		}

		const accountIds = [...new Set(devices?.map((d) => d.accountId))];
		if (accountIds?.length !== 1) {
			this.alertMessageService.warning('Tasks can only be created for Agents within a single account.');
			return;
		}

		const modal = this.modalService.open(UnitUpdatesTaskSelectorModalComponent, { size: 'md', backdrop: 'static', centered: true });
		modal.componentInstance.accountId = accountIds[0];
		modal.componentInstance.units = devices;
		modal.componentInstance.manageTaskTypeId = 3;
		modal.result.then(
			(result) => {
				if (result?.task == null) return;

				this.dataStoreService.data.manageUpdateData = result.task;
				this.router.navigate(['manage', 'task', 'preload']);
			},
			(_) => {}
		);
	}

	downloadCsv() {
		// default to all update types if none are selected in filter
		if (this.enableUpdateMode === true && this.searchFilter != null && (this.searchFilter.updateTypeIds?.length || 0) == 0)
			this.searchFilter.updateTypeIds = this.updateTypes.map((ut) => ut.updateTypeId);

		let filter: SearchFilter = {
			accounts: this.accountIds?.map((accountId: number) => {
				return { accountId: accountId };
			}),
			sortAsc: true,
			...this.searchFilter,
		};
		let o: Observable<HttpResponse<Blob>>;
		if (this.dataSource === 'dashboard-cybersecurity') o = this.reportService.api.getCybersecurityDevicesCsv(filter);
		else if (this.dataSource === 'dashboard-malware-protection') o = this.reportService.api.getMalwareProtectionDevicesCsv(filter);
		else if (this.dataSource === 'dashboard-camera-defense') o = this.reportService.api.getCameraDefenseDevicesCsv(filter);
		else if (this.dataSource === 'dashboard-offline') o = this.reportService.api.getOfflineDevicesCsv(filter);
		else if (this.dataSource === 'lookup' && this.lookup) o = this.reportService.api.getLookupDevicesCsv(this.lookup, filter);
		else o = this.reportService.api.getDevicesCsv(filter);
		o.subscribe({
			next: (response: HttpResponse<Blob>) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting Agents CSV.', error);
			},
		});
	}

	showSerialSearch() {
		const modal = this.modalService.open(UnitGlobalSearchModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
	}

	displayActivationCode(device: Unit) {
		this.subscriptions.add(
			this.unitService.api.getActivationCode(device.accountId, device.unitKey).subscribe((result) => {
				const modalRef = this.modalService.open(ConfirmModalComponent, {
					backdrop: 'static',
					centered: true,
				});

				modalRef.componentInstance.configuration = {
					title: `${device.serialNumber}: Activation Code`,
					html: `<div class="text-center">The activation code for ${device.serialNumber} is:</div><div class="py-3 text-center h1">${result}</div>`,
                    hideCancelButton: true,
					confirmButtonText: 'Close',
				};

				modalRef.result.then((_) => {}), (_) => {};
			})
		);
	}

	displayOverrideWarranty(device: Unit) {
		this.subscriptions.add(
			this.unitService.api.getUnitFields(device.accountId, device.unitId).subscribe((result) => {
				const modal = this.modalService.open(UnitOverrideWarrantyModalComponent, { backdrop: 'static', centered: true });
				modal.componentInstance.accountId = result.accountId;
				modal.componentInstance.unitId = result.unitId;
				modal.componentInstance.unit = device;
				modal.componentInstance.unitFields = result;
				modal.result.then(
					(_) => {
						this.update.emit();
					},
					(_) => {}
				);
			})
		);
	}
}
