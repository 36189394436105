import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';
import { NavService } from '@razberi-ui/shared';
import { AccountType, UserRole } from '@razberi-ui/core/data-types';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Injectable({ providedIn: 'root' })
export class SelfAccountGuard implements CanActivate, CanActivateChild {
	constructor(
		private router: Router,
		private authService: AuthService,
		private subscriptionService: SubscriptionService,
		private navigationService: NavService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authService.data.account.type === AccountType.Provider && this.authService.helpers.userHasRole(UserRole.Administrator) != true) {
			const accountId = route.paramMap.has('accountId') === true ? Number(route.paramMap.get('accountId')) : undefined;
			if (accountId && isNaN(accountId) !== true && accountId === this.authService.data.account.accountId) {
				const defaultUrl = this.subscriptionService.helpers.isGlobalOrIsValid()
					? this.navigationService.baseUrlValidSubscription
					: this.navigationService.baseUrlInvalidSubscription;
				this.router.navigate([defaultUrl]);
				return false;
			}
		}
		return true;
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
