<form id="tag-form" [formGroup]="tagForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row">
		<div class="col">
			<div class="mb-3">
				<label for="name" class="form-label">Name <span class="text-primary">*</span></label>
				<input type="text" class="form-control" id="name" formControlName="name" />
				<shared-input-validation-messages [control]="tagForm.controls.name"></shared-input-validation-messages>
			</div>
			<div class="mb-3">
				<label for="description" class="form-label">Description <span class="text-primary">*</span></label>
				<input type="text" class="form-control" id="description" formControlName="description" />
				<shared-input-validation-messages [control]="tagForm.controls.description"></shared-input-validation-messages>
			</div>
		</div>
	</div>
</form>
