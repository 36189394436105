import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from '@razberi-ui/api/cloud-api';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class UserComplianceGuard implements CanActivate, CanActivateChild {
	constructor(private readonly logger: NGXLogger, private router: Router, private authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.logger.trace('user compliance guard check');
		// check GDPR user compliance
		if (this.authService.data.gdprComplianceRequired === true && route.url.join('/') !== 'user-compliance/gdpr') {
			// store the attempted URL for redirecting
			this.authService.data.redirectUrl = state.url;
			this.router.navigate(['/user-compliance/gdpr']);
			return false;
		}

		// check Reseller user compliance
		if (
			this.authService.data.gdprComplianceRequired !== true &&
			this.authService.data.resellerComplianceRequired === true &&
			route.url.join('/') !== 'user-compliance/reseller'
		) {
			// store the attempted URL for redirecting
			this.authService.data.redirectUrl = state.url;
			this.router.navigate(['/user-compliance/reseller']);
			return false;
		}

		return true;
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
