import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[focusIf]',
})
export class FocusIfDirective {
	private focus;

	@Input() set focusIf(condition: boolean) {
		this.focus = condition != false;
	}

	constructor(private el: ElementRef) {}

	ngAfterViewChecked() {
		if (this.focus === true || typeof this.focus === 'undefined') this.el.nativeElement.focus();
		else if (document.activeElement?.id === this.el.nativeElement.id) this.el.nativeElement.blur();
	}
}
