<div id="modal-container" class="modal-container">
	<div id="modal-header" class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="server"></fa-icon><span class="ps-2">{{ modalTitle ?? 'Edit Device' }}</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div id="modal-body" class="modal-body">
		<app-root-monitor-cloud-device-edit-form
			[userAccountId]="userAccountId"
			[canEditAccount]="canEditAccount"
			[deviceEditData]="deviceEditData"
			[accounts]="accounts"
			[locations]="locations"
			[tags]="tags"
			(accountChange)="onAccountChange($event)"
			(newLocation)="onNewLocation()"
			(newTag)="onNewTag()"
			(isValid)="onIsValid($event)"
			(changes)="onChanges($event)"
			(submit)="save()"
			ngbAutoFocus>
		</app-root-monitor-cloud-device-edit-form>
	</div>
	<div id="modal-footer" class="modal-footer">
		<button id="btn-cancel" type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button id="btn-save" type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="!isValid">
			<fa-icon icon="check"></fa-icon> Save
		</button>
	</div>
</div>
