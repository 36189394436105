<div class="d-flex justify-content-start align-items-center" (click)="onClick($event)">
	<div *ngIf="icon || image" class="d-flex justify-content-center align-self-start p-0 me-1 icon-sm">
		<fa-icon *ngIf="icon" [icon]="icon" [size]="iconSize ?? 'md'" class="{{ iconClass }}" [title]="tooltip?.text || ''"></fa-icon>
		<img *ngIf="image" [src]="image" class="icon-sm {{ imageClass }}" [title]="tooltip?.text || ''" />
	</div>
	<button *ngIf="!disabled" type="button" class="btn btn-link p-0 text-start" [title]="tooltip?.text || ''">
		{{ text }}
	</button>
	<span *ngIf="disabled" [title]="tooltip?.text || ''">
		{{ text }}
	</span>
	<span *ngFor="let subtext of subtexts" class="text-xs {{ subtext.class }} ml-1">{{ subtext.text }}</span>
</div>
<ng-template #tipContent>{{ tooltip?.text }}</ng-template>
