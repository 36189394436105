<shared-data-table
	*ngIf="isInitialized === true && tableSettings != null"
	[tableSettings]="tableSettings"
	[tableItems]="tableItems"
	(daysChanged)="daysChanged.emit($event)"
	(getChildren)="getChildren.emit($event)"
	(childAction)="childAction.emit($event)"
	(selectedItems)="selectedItems.emit($event)"
	(toggleRow)="toggleRow.emit($event)"
	(pagingChanged)="onPagingChanged($event)"
	(sortingChanged)="sortingChanged.emit($event)">
	<app-root-monitor-cloud-data-table-search-form
		*ngIf="showSearch === true && searchConfiguration != null && initialSearchFilter != null"
		dataTableSearch
		[searchConfiguration]="searchConfiguration"
		[accountIds]="accountIds"
		[alertTypeIds]="alertTypeIds"
		[manageTaskTypeIds]="manageTaskTypeIds"
		[updateTypeIds]="updateTypeIds"
		[initialSearchFilter]="initialSearchFilter"
		[refreshData$]="refreshData$"
		(applyFilter)="applyFilter.emit($event)"></app-root-monitor-cloud-data-table-search-form>
	<ng-template #dataTableCellTypes let-value="value" let-column="column" let-tableItem="tableItem">
		<app-root-monitor-cloud-data-table-cell-types [value]="value" [column]="column" [tableItem]="tableItem"></app-root-monitor-cloud-data-table-cell-types>
	</ng-template>
</shared-data-table>
