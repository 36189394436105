import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudApiService, SearchFilter } from '@razberi-ui/api/cloud-api';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	constructor(private readonly cloudApiService: CloudApiService) {}

	get api() {
		return {
			getAccountsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getAccountsCsv(searchFilter);
			},
			getDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getDevicesCsv(searchFilter);
			},
			getOfflineDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getOfflineDevicesCsv(searchFilter);
			},
			getCameraStreamsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getCameraStreamsCsv(searchFilter);
			},
			getRecordingServersCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getRecordingServersCsv(searchFilter);
			},
			getMalwareProtectionDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getMalwareProtectionDevicesCsv(searchFilter);
			},
			getCybersecurityDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getCybersecurityDevicesCsv(searchFilter);
			},
			getCameraDefenseDevicesCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getCameraDefenseDevicesCsv(searchFilter);
			},
			getLookupDevicesCsv: (lookup: string, searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getLookupDevicesCsv(lookup, searchFilter);
			},
			getAlertsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getAlertsCsv(searchFilter);
			},
			getNetworkAlertsCsv: (
				accountId: number,
				networkId: number,
				managedDeviceKeys: string[],
				searchFilter?: SearchFilter
			): Observable<HttpResponse<Blob>> => {
				searchFilter = searchFilter ?? { accounts: null };
				searchFilter.accounts = [{ accountId: accountId, networkIds: [networkId], managedDeviceKeys: managedDeviceKeys }];
				return this.cloudApiService.reports.getNetworkAlertsCsv(accountId, networkId, searchFilter);
			},
			getAuditLogsCsv: (searchFilter?: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getAuditLogsCsv(searchFilter);
			},
			getAccountStatusReportPdf: (): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getAccountStatusReportPdf();
			},
			getAccountStatusSingleReportPdf: (accountId: number): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getAccountStatusSingleReportPdf(accountId);
			},
			getAccountStatusMultiReportPdf: (accountIds: number[]): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getAccountStatusMultiReportPdf(accountIds);
			},
			getUserComplianceReportCsvByFilter: (searchFilter: SearchFilter): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getUserComplianceReportCsvByFilter(searchFilter);
			},
			getUserComplianceReportCsvByAccountId: (accountIds: number[]): Observable<HttpResponse<Blob>> => {
				return this.cloudApiService.reports.getUserComplianceReportCsvByAccountId(accountIds);
			},
		};
	}

	get helpers() {
		return {
			saveAsFile: (response: HttpResponse<Blob>): void => {
				const cdHeader = response.headers.get('Content-Disposition');
				const filename = cdHeader.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
				const a = document.createElement('a');
				const objectUrl = URL.createObjectURL(response.body);
				a.href = objectUrl;
				a.download = filename;
				a.click();
				URL.revokeObjectURL(objectUrl);
				a.remove();
			},
		};
	}
}
