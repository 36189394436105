import { Component, Input } from '@angular/core';
import { DynamicTag } from '@razberi-ui/shared';

@Component({
	selector: 'app-root-monitor-cloud-cell-unit-updates',
	templateUrl: './cell-unit-updates.component.html',
	styleUrls: ['./cell-unit-updates.component.scss'],
})
export class CellUnitUpdatesComponent {
	@Input() updateTags: DynamicTag[];
	@Input() displayCount: number = 2;

	constructor() {}

	getCssContext(updateTypeId: number): string {
		switch (updateTypeId) {
			case 1:
				return 'danger';
			case 2:
				return 'warning';
			case 3:
			default:
				return 'dark';
		}
	}
}
