import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSearchConfiguration, SearchFilter } from '@razberi-ui/api/cloud-api';
import { ManageTaskInstance, Location, ManageTaskExportRequest, ManageTask } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageSidePanelService, TableItemSelectMode, TableServerSidePagingConfig, TableSettings } from '@razberi-ui/shared';
import { ReportService } from '../../../services/report.service';
import { ManageService } from '../../../services/manage.service';
import * as moment from 'moment';
import { ManageTaskInstanceOutputModalComponent } from '../manage-task-instance-output-modal/manage-task-instance-output-modal.component';

@Component({
	selector: 'app-root-monitor-cloud-manage-task-instance-table',
	templateUrl: './manage-task-instance-table.component.html',
	styleUrls: ['./manage-task-instance-table.component.scss'],
})
export class ManageTaskInstanceTableComponent {
	@Input() accountId: number;
	@Input() manageTaskId: number;
	@Input() manageTaskInstances: ManageTaskInstance[] = [];

	pageNumber: number;
	pageSize: number;
	isInitialized: boolean = false;
	showDownloadCsv: boolean = false;
	initialSearchFilter: SearchFilter;
	searchFilter: SearchFilter;
	searchConfig: DataSearchConfiguration;
	filteredTaskInstances: ManageTaskInstance[] = [];

	tableSettings: TableSettings = {
		columnConfig: [],
	};

	constructor(
		private readonly alertMessageService: AlertMessageService,
		private readonly modalService: NgbModal,
		private readonly reportService: ReportService,
		private readonly manageService: ManageService,
		private readonly pageSidePanelService: PageSidePanelService
	) {}

	ngOnInit(): void {
		this.searchFilter = { ...this.initialSearchFilter };
		this.searchConfig = { showSearchTerm: true, showManageTaskStatuses: true, showLocations: true, showTags: true };
		this.isInitialized = true;
	}

	ngOnChanges(changes) {
		if (changes?.manageTaskInstances?.currentValue) {
			this.loadInstances(changes?.manageTaskInstances?.currentValue);
		}
	}

	loadInstances(instances: ManageTaskInstance[]) {
		this.manageTaskInstances = instances || [];
		this.filteredTaskInstances = instances || [];
		this.showDownloadCsv = (this.manageTaskInstances?.length || 0) > 0;

		const ts: TableSettings = {
			useSearch: false,
			useAdvancedSearch: true,
			itemSelectMode: (this.manageTaskInstances?.length || 0) > 1 ? TableItemSelectMode.Multi : TableItemSelectMode.None,
			useEdit: true,
			usePageSize: (this.manageTaskInstances?.length || 0) > 0,
			hidePagination: (this.manageTaskInstances?.length || 0) === 0,
			columnConfig: [
				{
					primaryKey: 'unitName',
					header: 'Device',
					cellType: 'link-button',
					transform: (value) => {
						return !value ? { text: '--', disabled: true } : { text: value, icon: 'server' };
					},
					clickHandlerFunction: (params) => {
						this.pageSidePanelService.helpers.openUnitSidePanel(params.unitName, 'details', this.accountId, params.unitId, false, null);
					},
				},
				{
					primaryKey: 'unitLocation',
					header: 'Location',
					cellType: 'icon',
					transform: (value) => {
						return value == null ? { text: '--' } : { text: value.name, icon: 'map-marker-alt' };
					},
				},
				{ primaryKey: 'unitTags', header: 'Tags', useForSearch: true, cellType: 'pills' },
				{ primaryKey: 'status', header: 'Execution Status', useForSearch: true, cellType: 'manage-task-status' },
				{
					primaryKey: 'startTimestamp',
					header: 'Started',
					transform: (value, tableItem) => {
						return value != null ? moment(value).format('MM/DD @ HH:mm:ss') : '--';
					},
				},
				{ primaryKey: 'runTime', header: 'Run Time' },
				{
					primaryKey: 'updateTimestamp',
					header: 'Updated',
					transform: (value, tableItem) => {
						return value != null ? moment(value).format('MM/DD @ HH:mm:ss') : '--';
					},
				},
				{
					primaryKey: null,
					header: 'Output',
					useForSearch: false,
					cellType: 'link-button',
					transform: (value, tableItem) => {
						return {
							text: tableItem.output != null ? 'View' : '--',
							disabled: tableItem.output == null,
						};
					},
					clickHandlerFunction: (params) => {
						this.viewTaskOutput(params);
					},
				},
				{
					primaryKey: null,
					header: 'Files',
					useForSearch: true,
					cellType: 'link-button',
					transform: (value, tableItem) => {
						return {
							text: tableItem.files?.length > 0 ? 'View' : '--',
							disabled: (tableItem.files?.length ?? 0) === 0,
						};
					},
					clickHandlerFunction: (params) => {
						this.viewTaskOutput(params);
					},
				},
			],
			headerButtons: [
				{
					label: 'Export Selected',
					faIcon: 'download',
					clickHandlerFunction: (params) => {
						this.exportTaskInstances(params.value);
					},
				},
			],
			stickyHeaderButtons: [],
			editActions: (tableItem) => {
				return [
					{
						label: 'View Output/Files',
						faIcon: 'eye',
						clickHandlerFunction: (params) => {
							this.viewTaskOutput(params);
						},
						enabled: tableItem.output != null,
					},
					{
						label: 'Export Device Files',
						faIcon: 'download',
						clickHandlerFunction: (params) => {
							this.exportTaskInstances([params]);
						},
						enabled: (tableItem) => {
							return tableItem.completeTimestamp != null;
						},
					},
				];
			},
		};

		if (this.showDownloadCsv)
			ts.stickyHeaderButtons.push({
				label: 'Download CSV',
				faIcon: 'download',
				clickHandlerFunction: () => {
					this.downloadCsv();
				},
			});

		this.tableSettings = ts;
	}

	onPagingChanged(paging: TableServerSidePagingConfig) {
		this.pageNumber = paging.pageNumber;
		this.pageSize = paging.pageSize;
	}

	onSearch(searchFilter: SearchFilter) {
		this.searchFilter = searchFilter;
		this.filterTaskInstances();
	}

	filterTaskInstances() {
		this.filteredTaskInstances = this.manageTaskInstances?.filter((mti) => {
			return (
				((this.searchFilter.searchTerm?.length ?? 0) === 0 || mti.unitName?.toUpperCase().indexOf(this.searchFilter.searchTerm.toUpperCase()) > -1) &&
				(this.searchFilter.manageTaskStatuses == null || this.searchFilter.manageTaskStatuses.some((mts) => mts === mti.status) === true) &&
				(this.searchFilter.accounts == null ||
					this.searchFilter.accounts[0].locationIds == null ||
					this.searchFilter.accounts[0].locationIds.some((id) => mti.unitLocation?.locationId === id) === true) &&
				(this.searchFilter.accounts == null ||
					this.searchFilter.accounts[0].tagIds == null ||
					this.searchFilter.accounts[0].tagIds.some((id) => mti.unitTags?.some((ut) => ut.tagId === id) === true) === true)
			);
		});
	}

	viewTaskOutput(instance: ManageTaskInstance) {
		const modal = this.modalService.open(ManageTaskInstanceOutputModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modal.componentInstance.accountId = this.accountId;
		modal.componentInstance.manageTaskInstance = instance;
		modal.result.then(
			(_) => {},
			(_) => {}
		);
	}

	exportTaskInstances(instances: ManageTaskInstance[]) {
		const request: ManageTaskExportRequest = { accountId: this.accountId, taskInstanceGroups: [] };
		const task: ManageTask = this.manageService.helpers.getDefaultTask(this.accountId);
		task.accountId = this.accountId;
		task.manageTaskId = this.manageTaskId;
		task.manageTaskInstances = instances;
		request.taskInstanceGroups = this.manageService.helpers.buildTaskInstanceGroups([task]);
		this.manageService.api.getManageTasksExport([request]).subscribe((result) => this.reportService.helpers.saveAsFile(result));
	}

	downloadCsv() {
		if (!this.accountId || !this.manageTaskId) {
			this.alertMessageService.error('Account Id or Manage Task Id has not been set.');
			return;
		}

		this.manageService.api.getManageTaskInstancesCsv(this.accountId, this.manageTaskId).subscribe({
			next: (response) => {
				this.reportService.helpers.saveAsFile(response);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting task devices CSV.', error);
			},
		});
	}

	exportTasks(tasks: ManageTask[]) {
		const requests: ManageTaskExportRequest[] = [];
		tasks.forEach((obj) => {
			const accountId: number = obj['accountId'];
			let request = requests.find((r) => r.accountId === accountId);
			if (request == null) {
				request = { accountId: accountId, taskInstanceGroups: [] };
				requests.push(request);
			}
			const clone = { ...obj };
			clone.manageTaskInstances = null; // reset here (in case stale), so all instances are included in the export
			const taskInstanceGroups = this.manageService.helpers.buildTaskInstanceGroups([clone]);
			request.taskInstanceGroups = { ...request.taskInstanceGroups, ...taskInstanceGroups };
		});
		this.manageService.api.getManageTasksExport(requests).subscribe((result) => this.reportService.helpers.saveAsFile(result));
	}
}
