<form id="account-form" [formGroup]="accountForm" (keyup.enter)="submitForm()" autocomplete="off" novalidate>
	<div class="row">
		<div class="col">
			<div class="row mb-3">
				<div class="{{ isCurrentAccount || (!account && !canEditProviders) ? 'col' : 'col-6' }}">
					<label for="name">Account name <span class="text-primary">*</span></label>
					<input type="text" class="form-control" id="name" formControlName="name" required />
					<shared-input-validation-messages [control]="accountForm.controls.name"></shared-input-validation-messages>
				</div>
				<div
					*ngIf="(!isCurrentAccount && canEditProviders) || isPublicMode === true"
					class="{{ !account || isPublicMode === true ? 'col-6' : 'col-3' }}">
					<label for="type">Type <span class="text-primary">*</span></label>
					<select class="form-select" id="type" formControlName="type" required>
						<option [ngValue]="type.value" *ngFor="let type of accountTypes">{{ type.name }}</option>
					</select>
					<shared-input-validation-messages [control]="accountForm.controls.type"></shared-input-validation-messages>
				</div>
				<div *ngIf="!isCurrentAccount && account && isPublicMode !== true" class="{{ !canEditProviders ? 'col-6' : 'col-3' }}">
					<label for="status">Status <span class="text-primary">*</span></label>
					<select class="form-select" id="status" formControlName="status" required>
						<option [ngValue]="status.value" *ngFor="let status of accountStatuses">{{ status.name }}</option>
					</select>
					<shared-input-validation-messages [control]="accountForm.controls.status"></shared-input-validation-messages>
				</div>
			</div>
			<div *ngIf="canEditProviders" class="row mb-3">
				<div *ngIf="isCustomer" class="col">
					<label for="providerAccountId">Provider account</label>
					<select class="form-select" id="providerAccountId" formControlName="providerAccountId">
						<option [ngValue]="null"></option>
						<option [ngValue]="provider.accountId" *ngFor="let provider of providers">{{ provider.name }}</option>
					</select>
				</div>
				<div *ngIf="isProvider" class="col">
					<label for="salesforceId">Reseller Id</label>
					<input type="text" class="form-control" id="salesforceId" formControlName="salesforceId" />
					<shared-input-validation-messages [control]="accountForm.controls.salesforceId"></shared-input-validation-messages>
				</div>
			</div>
		</div>
	</div>
	<div class="row" [ngClass]="{ 'mt-5': isPublicMode !== true }">
		<div class="col">
			<div *ngIf="isPublicMode !== true" class="row mb-3">
				<div class="col">
					<label for="phone">Phone number <span class="text-primary">*</span></label>
					<input type="text" class="form-control" id="phone" formControlName="phone" required />
					<shared-input-validation-messages [control]="accountForm.controls.phone"></shared-input-validation-messages>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<label for="address">Address <span class="text-primary">*</span></label>
					<input type="text" class="form-control" id="address" formControlName="address" required />
					<shared-input-validation-messages [control]="accountForm.controls.address"></shared-input-validation-messages>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<label for="address2">Address 2</label>
					<input type="text" class="form-control" id="address2" formControlName="address2" />
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-5">
					<label for="city">City <span class="text-primary">*</span></label>
					<input type="text" class="form-control" id="city" formControlName="city" required />
					<shared-input-validation-messages [control]="accountForm.controls.city"></shared-input-validation-messages>
				</div>
				<div class="col-4">
					<label for="stateProvince">State/Province <span class="text-primary">*</span></label>
					<shared-form-select-us-states
						*ngIf="accountForm.value.countryRegion === 'USA'"
						id="stateProvince"
						class="shared-form-select"
						formControlName="stateProvince"></shared-form-select-us-states>
					<input
						*ngIf="accountForm.value.countryRegion !== 'USA'"
						type="text"
						class="form-control"
						id="stateProvince"
						formControlName="stateProvince" />
					<!-- <input type="text" class="form-control" id="stateProvince" formControlName="stateProvince" [ngClass]="{ 'is-invalid': isInvalid('stateProvince') }" required /> -->
					<shared-input-validation-messages [control]="accountForm.controls.stateProvince"></shared-input-validation-messages>
				</div>
				<div class="col-3">
					<label for="zipPostalCode">Zip/Postal Code <span class="text-primary">*</span></label>
					<input type="text" class="form-control" id="zipPostalCode" formControlName="zipPostalCode" required />
					<shared-input-validation-messages [control]="accountForm.controls.zipPostalCode"></shared-input-validation-messages>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<label for="countryRegion">Country/Region <span class="text-primary">*</span></label>
					<shared-form-select-countries
						id="countryRegion"
						class="shared-form-select"
						formControlName="countryRegion"
						(change)="onCountryRegionChange($event)"></shared-form-select-countries>
					<!-- <input type="text" class="form-control" id="countryRegion" formControlName="countryRegion" [ngClass]="{ 'is-invalid': isInvalid('countryRegion') }" required /> -->
					<shared-input-validation-messages [control]="accountForm.controls.country"></shared-input-validation-messages>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="account && isPublicMode !== true" class="row mt-5">
		<div class="col">
			<label for="primaryUserId">Primary administrator <span class="text-primary">*</span></label>
			<select class="form-select" id="primaryUserId" formControlName="primaryUserId" required>
				<option [ngValue]="admin.userId" *ngFor="let admin of admins">{{ admin.firstName }} {{ admin.lastName }} ({{ admin.email }})</option>
			</select>
			<shared-input-validation-messages [control]="accountForm.controls.primaryUserId"></shared-input-validation-messages>
		</div>
	</div>
</form>
