<div class="app-custom-ng-select d-flex align-items-center" [ngClass]="{ 'filter-open': filterOpen }">
	<div class="ms-2"><fa-icon icon="search" size="xl"></fa-icon></div>
	<ng-select
		#searchDropdown
		class="flex-grow-1"
		[labelForId]="id"
		multiple="true"
		bindLabel="name"
		placeholder="Search..."
		[items]="items"
		[clearable]="false"
		[(ngModel)]="selectModel"
		[ngModelOptions]="{ standalone: true }"
		(change)="selectModelChanged($event)">
		<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
			<div class="ng-value" *ngFor="let item of items | slice: 0:3">
				<span class="ng-value-label">{{ item.name }}</span>
				<span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
			</div>
			<div *ngIf="items.length > 3"><span>...</span></div>
			<div class="search-total">{{ items.length }}</div>
		</ng-template>
	</ng-select>
	<div class="me-2" (click)="clearFilter()"><fa-icon icon="times" size="xl"></fa-icon></div>
	<div *ngIf="usingFilter" class="me-2" (click)="toggleFilterOpen()"><fa-icon icon="filter" size="xl"></fa-icon></div>
</div>
