import { Injectable } from '@angular/core';
import { EnumItem } from '../data-types/enum-item.model';
import { AccountType } from '../data-types/account-type.model';
import { AccountStatus } from '../data-types/account-status.model';
import { AccountSubscriptionType } from '../data-types/account-subscription-type.model';
import { AccountSubscriptionStatus } from '../data-types/account-subscription-status.model';
import { AlertEmailFrequencyType, AlertEmailFrequencyTypeAttributes } from '../data-types/alert-email-frequency-type.model';
import { UserRole, UserRoleAttributes } from '../data-types/user-role.model';
import { UserStatus } from '../data-types/user-status.model';
import { UnitStatus } from '../data-types/unit-status.model';
import { AlertStatus } from '../data-types/alert-status.model';
import { ManageTaskStatus } from '../data-types/manage-task-status.model';
import { ManagedDeviceStatus, ManagedDeviceType, ManagedDeviceTypeAttributes } from '../data-types/managed-device.model';
import { NetworkLinkStatus, NetworkLinkStatusAttributes } from '../data-types/network-link.model';
import { ManageTaskMsiAction } from '../data-types/manage-task-msi-action.model';

@Injectable({
	providedIn: 'root',
})
export class EnumService {
	constructor() {}

	get helpers() {
		return {
			getAccountTypes: (includePool: boolean = false, includeGlobal: boolean = false): EnumItem[] => {
				const items: string[] = Object.keys(AccountType);
				let types: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: AccountType[item], name: item };
				});
				if (!includePool) types = types.filter((type: EnumItem) => type.value !== AccountType.Pool);
				if (!includeGlobal) types = types.filter((type: EnumItem) => type.value !== AccountType.Global);
				return types;
			},
			getAccountStatuses: (includePending: boolean = false, includeOffline: boolean = false, includeDeleted: boolean = false): EnumItem[] => {
				const items: string[] = Object.keys(AccountStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: AccountStatus[item], name: item };
				});
				if (!includePending) statuses = statuses.filter((status: EnumItem) => status.value !== AccountStatus.Pending);
				if (!includeOffline) statuses = statuses.filter((status: EnumItem) => status.value !== AccountStatus.Offline);
				if (!includeDeleted) statuses = statuses.filter((status: EnumItem) => status.value !== AccountStatus.Deleted);
				return statuses;
			},
			getAccountSubscriptionTypes: (includeManage: boolean = true): EnumItem[] => {
				const items: string[] = Object.keys(AccountSubscriptionType);
				let types: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: AccountSubscriptionType[item], name: item };
				});
				if (!includeManage) types = types.filter((type: EnumItem) => type.value !== AccountSubscriptionType.Manage);
				return types;
			},
			getAccountSubscriptionStatuses: (): EnumItem[] => {
				const items: string[] = Object.keys(AccountSubscriptionStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: AccountSubscriptionStatus[item], name: item };
				});
				return statuses;
			},
			getAlertEmailFrequencyTypes: (): EnumItem[] => {
				const items: string[] = Object.keys(AlertEmailFrequencyType);
				let types: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: AlertEmailFrequencyType[item], name: AlertEmailFrequencyTypeAttributes.get(AlertEmailFrequencyType[item])?.label ?? item };
				});
				return types;
			},
			getUserRoles: (useFriendlyNames = true, includeNone: boolean = false): EnumItem[] => {
				const items: string[] = Object.keys(UserRole);
				let types: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: UserRole[item], name: useFriendlyNames === true ? UserRoleAttributes.get(UserRole[item])?.label ?? item : item };
				});
				if (includeNone !== true) types = types.filter((type: EnumItem) => type.value !== UserRole.None);
				return types;
			},
			getUserStatuses: (includePending: boolean = false, includeLocked: boolean = false): EnumItem[] => {
				const items: string[] = Object.keys(UserStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: UserStatus[item], name: item };
				});
				if (!includePending) statuses = statuses.filter((status: EnumItem) => status.value !== UserStatus.Pending);
				if (!includeLocked) statuses = statuses.filter((status: EnumItem) => status.value !== UserStatus.Locked);
				return statuses;
			},
			getDeviceStatuses: (): EnumItem[] => {
				const items: string[] = Object.keys(UnitStatus);
				let statuses = items.splice(items.length / 2).map((item: string) => {
					return { value: UnitStatus[item], name: item };
				});
				return statuses;
			},
			getAlertStatuses: (includeClosed: boolean = false): EnumItem[] => {
				const items: string[] = Object.keys(AlertStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: AlertStatus[item], name: item };
				});
				if (!includeClosed) statuses = statuses.filter((status: EnumItem) => status.value !== AlertStatus.Closed);
				return statuses;
			},
			getManageTaskStatuses: (): EnumItem[] => {
				const items: string[] = Object.keys(ManageTaskStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: ManageTaskStatus[item], name: item };
				});
				return statuses;
			},
			getManagedDeviceTypes: (includeAgent: boolean = true): EnumItem[] => {
				const items: string[] = Object.keys(ManagedDeviceType);
				let types: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: ManagedDeviceType[item], name: ManagedDeviceTypeAttributes.get(ManagedDeviceType[item])?.label ?? item };
				});
				if (includeAgent !== true) types = types.filter((type: EnumItem) => type.value !== ManagedDeviceType.Agent);
				return types;
			},
			getManagedDeviceStatuses: (includeError: boolean = true): EnumItem[] => {
				const items: string[] = Object.keys(ManagedDeviceStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: ManagedDeviceStatus[item], name: item };
				});
				if (includeError !== true) statuses = statuses.filter((status: EnumItem) => status.value !== ManagedDeviceStatus.Error);
				return statuses;
			},
			getNetworkLinkStatuses: (): EnumItem[] => {
				const items: string[] = Object.keys(NetworkLinkStatus);
				let statuses: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: NetworkLinkStatus[item], name: NetworkLinkStatusAttributes.get(NetworkLinkStatus[item])?.label ?? item };
				});
				return statuses;
			},
			getManageTaskMsiActions(): EnumItem[] {
				const items: string[] = Object.keys(ManageTaskMsiAction);
				let actions: EnumItem[] = items.splice(items.length / 2).map((item: string) => {
					return { value: ManageTaskMsiAction[item], name: item };
				});
				return actions;
			},
		};
	}
}
