<div class="code-entry-container">
	<input
		#ctrl
		class="code-entry-input"
		*ngFor="let idx of characterCount | forEachNumber"
		id="codeentry_input_{{ idx }}"
		maxlength="1"
		(keyup)="inputChange(idx, $event)"
		[focusIf]="idxAutofocus === idx"
		(click)="inputClicked(idx)"
		[value]="characters[idx]?.length > 0 ? characters[idx] : ''"
		[ngClass]="{ valid: characters[idx]?.length > 0 }" />
</div>

<!-- [(ngModel)]="characters[idx]"
        (ngModelChange)="modelChange(idx, $event)" -->
