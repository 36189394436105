import { Component, Input } from '@angular/core';
import { ImageCropperConfig } from '../../data-types/image-cropper-config.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
	selector: 'shared-image-cropper',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent {
	@Input() image: any = '/assets/core/images/img/avatar.jpg';
	@Input() config: ImageCropperConfig;
	title = 'angular-image-uploader';
	fileData = null;
	imageChangedEvent: any = '';
	croppedImage: any = '';

	constructor(private activeModal: NgbActiveModal) {}

	ngOnInit() {}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

	uploadImage() {
		let imageData = this.decryptImage(this.croppedImage);
		this.activeModal.close({ success: true, imageData: this.croppedImage });
	}

	decryptImage(base64) {
		if (base64) {
			if (base64.includes('base64')) {
				let contentType = base64.split(';')[0];
				let byteCharacters = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
				let byteNumbers = new Array(byteCharacters.length);

				for (var i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}

				let byteArray = new Uint8Array(byteNumbers);
				let blob = new Blob([byteArray], { type: contentType });

				return blob;
			}
		}
	}

	close() {
		this.activeModal.close({ success: false });
	}
}
