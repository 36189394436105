import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordFormData } from '../../../data-types/password-form-data.model';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-auth-activate-login',
	templateUrl: './activate-login.component.html',
	styleUrls: ['./activate-login.component.scss'],
})
export class ActivateLoginComponent {
	token: string;
	passwordData: PasswordFormData;
	isValid: boolean;
	status: number; // 0: loading; 1: token valid; 2: success; 3: error
	message: string;

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
		this.token = null;
		this.isValid = false;
		this.status = 0;
	}

	ngOnInit() {
		if (this.authService.data.isAuthenticated || !this.activatedRoute.snapshot.paramMap.has('token')) this.router.navigate(['/']);

		this.token = this.activatedRoute.snapshot.paramMap.get('token');
		if (!this.token) this.router.navigate(['/']);

		this.authService.userEmailTokens.verifyNewUserToken(this.token).subscribe({
			next: (_) => {
				this.status = 1;
			},
			error: (_) => {
				this.message = 'The link to activate your login has expired.';
				this.status = 3;
			},
		});
	}

	onIsValid(isValid: boolean) {
		this.isValid = isValid;
	}

	onChanges(passwordData: PasswordFormData) {
		this.passwordData = passwordData;
	}

	activate() {
		if (this.isValid) {
			this.message = null;
			this.authService.userEmailTokens.finalizeNewUserToken(this.token, this.passwordData.new).subscribe({
				next: (_) => {
					this.status = 2;
				},
				error: (error) => {
					this.message = 'Your login could not be activated.';
					this.status = 3;
				},
			});
		}
	}

	login() {
		this.router.navigate(['/login']);
	}

	reset() {
		this.router.navigate(['/reset-password']);
	}
}
