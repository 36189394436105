import { Component, Input, SimpleChanges } from '@angular/core';
import { Update, UpdateType } from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';

@Component({
	selector: 'app-root-monitor-cloud-unit-updates-side-panel',
	templateUrl: './unit-updates-side-panel.component.html',
	styleUrls: ['./unit-updates-side-panel.component.scss'],
})
export class UnitUpdatesSidePanelComponent {
	@Input() updates: Update[];

	groupedUpdates: Map<number, Update[]>;
	updateTypes: UpdateType[] = [];

	constructor(readonly utils: UtilitiesService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.updates?.currentValue != null) {
			this.updates.forEach((u) => (u['collapsed'] = true));
			this.groupedUpdates = this.updates
				.sort((a, b) => (a.title > b.title ? 1 : -1))
				.reduce((entryMap, e) => entryMap.set(e.updateTypeId, [...(entryMap.get(e.updateTypeId) || []), e]), new Map());

			this.updateTypes = [];
			const flags = new Set();
			this.updates.forEach((entry) => {
				if (flags.has(entry.updateTypeId)) {
					return false;
				}
				flags.add(entry.updateTypeId);
				this.updateTypes.push(entry.updateType);
				return true;
			});
		}
	}

	getUpdateType(updateTypeId: number) {
		return this.updateTypes.find((ut) => ut.updateTypeId === updateTypeId);
	}

	shouldShowLink(link: string): boolean {
		const excludedLinks = ['http://sysdev.microsoft.com/support/default.aspx'];
		return link?.length > 0 && excludedLinks.some((l) => l.toUpperCase() === link.toUpperCase()) !== true;
	}
}
