import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { AlertMessageService, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-settings-smtp-form',
	templateUrl: './settings-smtp-form.component.html',
	styleUrls: ['./settings-smtp-form.component.scss'],
})
export class SettingsSmtpFormComponent implements OnInit {
	@Output() onEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscriptions: Subscription = new Subscription();
	formIsValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	readonly: boolean = true;
	settingsForm: UntypedFormGroup;

	constructor(
		readonly settingsService: CoreServerSettingsService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService
	) {}

	ngOnInit(): void {
		this.buildForm();
		this.subscriptions.add(
			this.settingsForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.formIsValid$.next(this.settingsForm.valid === true);
			})
		);

		this.subscriptions.add(
			this.settingsService.streams.settings$.subscribe((settings) => {
				if (settings?.smtp!= null) this.settingsForm?.patchValue(settings?.smtp);
				else this.settingsForm.reset();
				this.configureToolbar();
			})
		);
	}

	buildForm() {
		this.settingsForm = this.formBuilder.group({
			host: [null, Validators.required],
			port: [null, Validators.required],
			useTls: [null],
			from: [null, Validators.required],
			fromDisplay: [null, Validators.required],
			username: [null, Validators.required],
			password: [null],
		});
		this.settingsForm.disable();
	}

	configureToolbar() {
		const c: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
		if (this.readonly === true) {
			c.buttons.push({
				text: 'Edit',
				icon: 'edit',
				color: 'primary',
				isEnabled: this.settingsService.data.settings?.smtp != null,
				click: () => {
					this.readonly = false;
					this.settingsForm.enable();
					this.configureToolbar();
					this.onEditing.emit(true);
				},
			});
		} else {
			c.buttons.push({
				text: 'Cancel',
				icon: 'times-circle',
				color: 'light',
				isEnabled: this.settingsService.data.settings?.smtp != null,
				click: () => {
					this.readonly = true;
					this.settingsForm.disable();
					this.configureToolbar();
					this.onEditing.emit(false);
				},
			});
			c.buttons.push({
				text: 'Save',
				icon: 'check-circle',
				color: 'primary',
				isEnabled$: this.formIsValid$.asObservable(),
				click: () => {
					this.saveSettings();
				},
			});
		}
		this.pageConfigService.data.toolbarConfig = c;
	}

	saveSettings() {
		this.settingsService.api.setSmtp(this.settingsForm.value).subscribe((_) => {
			this.readonly = true;
			this.settingsForm.disable();
			this.configureToolbar();
			this.alertMessageService.success('The SMTP settings were updated successfully.');
			this.settingsService.api.getFullSettings().subscribe();
			this.settingsForm.controls.newPassword.setValue(null);
			this.onEditing.emit(false);
		});
	}
}
