<div class="date-range">
	<div class="mb-1"><fa-icon icon="calendar"></fa-icon> Date Range</div>
	<div class="d-flex">
		<div class="form-check me-2">
			<input type="radio" class="form-check-input" id="date-range_0" name="date-range_0" [(ngModel)]="radioVal" [value]="0" (change)="setAll()" />
			<label class="form-check-label" for="date-range_0">All</label>
		</div>
		<div *ngFor="let option of options; let i = index" class="form-check me-2">
			<input
				type="radio"
				class="form-check-input"
				id="date-range_{{ selectId }}_{{ i + 1 }}"
				name="date-range_{{ selectId }}_{{ i + 1 }}"
				[(ngModel)]="radioVal"
				[value]="i + 1"
				(change)="setDays(option)" />
			<label class="form-check-label" for="date-range_{{ selectId }}_{{ i + 1 }}">{{ option }} days</label>
		</div>
		<div class="form-check me-2">
			<input
				type="radio"
				class="form-check-input"
				id="date-range_{{ selectId }}_{{ options.length + 1 }}"
				name="date-range_{{ options.length + 1 }}"
				[(ngModel)]="radioVal"
				[value]="options.length + 1"
				(change)="setCustom()" />
			<label class="form-check-label" for="date-range_{{ selectId }}_{{ options.length + 1 }}">Custom</label>
		</div>
	</div>
	<form class="d-flex mt-2" *ngIf="displayDate">
		<div class="mb-3 d-none">
			<div class="input-group">
				<input
					name="datepicker_{{ selectId }}"
					class="form-control"
					ngbDatepicker
					#datepicker="ngbDatepicker"
					[autoClose]="'outside'"
					[displayMonths]="2"
					[dayTemplate]="t"
					outsideDays="hidden"
					[startDate]="startDate"
					(dateSelect)="onDateSelection($event)" />
				<ng-template #t let-date let-focused="focused">
					<span
						class="custom-day"
						[class.focused]="focused"
						[class.range]="isRange(date)"
						[class.faded]="isHovered(date) || isInside(date)"
						(mouseenter)="hoveredDate = date"
						(mouseleave)="hoveredDate = null">
						{{ date.day }}
					</span>
				</ng-template>
			</div>
		</div>
		<div class="mb-3">
			<div class="input-group">
				<input
					type="text"
					#dpStartDate
					class="form-control"
					id="dpStartDate_{{ selectId }}"
					name="dpStartDate_{{ selectId }}"
					placeholder="yyyy-mm-dd"
					[value]="formatter.format(startDate)"
					(input)="onStartInput(dpStartDate.value)" />
				<div class="input-group-append">
					<button type="button" class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
				</div>
			</div>
		</div>
		<div class="ms-2">
			<div class="input-group">
				<input
					type="text"
					#dpEndDate
					class="form-control"
					id="dpEndDate_{{ selectId }}"
					name="dpEndDate_{{ selectId }}"
					placeholder="yyyy-mm-dd"
					[value]="formatter.format(endDate)"
					(input)="onEndInput(dpEndDate.value)" />
				<div class="input-group-append">
					<button type="button" class="btn btn-outline-secondary" (click)="datepicker.toggle()"><fa-icon icon="calendar-alt"></fa-icon></button>
				</div>
			</div>
		</div>
	</form>
</div>
