import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account, AccountNote, AccountStatus, AccountType, User, UserRole, UserStatus } from '@razberi-ui/core/data-types';
import { AlertMessageService, PageConfigService } from '@razberi-ui/shared';
import { AccountService } from '../../../../../services/account.service';
import { UserService } from '../../../../../services/user.service';
import { BehaviorSubject, Observable, mergeMap } from 'rxjs';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-details',
	templateUrl: './account-view-tab-details.component.html',
	styleUrls: ['./account-view-tab-details.component.scss'],
})
export class AccountViewTabDetailsComponent {
	accountId?: number;
	isCurrentAccount: boolean = false;
	isCurrentPrimaryUser: boolean = false;
	editorAccountType: AccountType = AccountType.Customer;
	showNote: boolean = false;
	account: Account;
	accountNote: AccountNote;
	admins: User[];
	providers: Account[] = [];
	formAccount: Account;
	formAccountNote: AccountNote;
	isAccountValid: boolean;
	isAccountNoteValid: boolean;
	isValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly authService: AuthService,
		private readonly accountService: AccountService,
		private readonly userService: UserService
	) {}

	ngOnInit() {
		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;
		this.editorAccountType = this.authService.data.account.type;
		this.isCurrentAccount = this.accountId === this.authService.data.account.accountId;
		this.isCurrentPrimaryUser = this.isCurrentAccount && this.authService.data.account.primaryUserId === this.authService.data.user.userId;
		this.pageConfigService.data.toolbarConfig = {
			buttons: [
				{
					text: 'Save Account',
					icon: 'check',
					color: 'primary',
					isEnabled$: this.isValidSubject.asObservable(),
					click: () => {
						this.onSubmit();
					},
				},
			],
		};

		this.getAccount(false);
		this.getAccountAdmins();
		if (this.editorAccountType === AccountType.Global) {
			this.showNote = true;
			this.getProviderAccounts();
			this.getAccountNote();
		} else this.isAccountNoteValid = true;
	}

	ngOnDestroy() {
		this.isValidSubject.complete();
		this.pageConfigService.data.toolbarConfig = null;
	}

	getAccount(updateState: boolean) {
		this.accountService.api.getAccount(this.accountId).subscribe({
			next: (account: Account) => {
				this.account = account;
				if (updateState) this.authService.helpers.updateAccount(account);
			},
			error: (error) => this.alertMessageService.error('Error loading account.', error),
		});
	}

	getAccountAdmins() {
		this.userService.api.getUsersByAccountId(this.accountId).subscribe({
			next: (users: User[]) =>
				(this.admins = users.filter((u) => this.authService.helpers.userHasRole(UserRole.Administrator, u) && u.status === UserStatus.Enabled)),
			error: (error) => this.alertMessageService.error('Error loading account admins.', error),
		});
	}

	getProviderAccounts() {
		this.accountService.api.getProviderAccounts().subscribe({
			next: (accounts: Account[]) => (this.providers = accounts.filter((a) => a.status === AccountStatus.Enabled)),
			error: (error) => this.alertMessageService.error('Error loading provider accounts.', error),
		});
	}

	getAccountNote() {
		this.accountService.api.getAccountNote(this.accountId).subscribe({
			next: (accountNote: AccountNote) => (this.accountNote = accountNote),
			error: (error) => this.alertMessageService.error('Error loading account note.', error),
		});
	}

	onAccountIsValid(isValid: boolean) {
		this.isAccountValid = isValid;
		this.isValidSubject.next(this.isAccountValid && this.isAccountNoteValid);
	}

	onAccountNoteIsValid(isValid: boolean) {
		this.isAccountNoteValid = isValid;
		this.isValidSubject.next(this.isAccountValid && this.isAccountNoteValid);
	}

	onAccountChanges(formAccount: Account) {
		this.formAccount = formAccount;
	}

	onAccountNoteChanges(formAccountNote: AccountNote) {
		this.formAccountNote = formAccountNote;
	}

	onSubmit() {
		if (this.isValidSubject.value) {
			let o: Observable<void>;
			if (this.showNote)
				o = this.accountService.api.updateAccount({ ...this.account, ...this.formAccount }).pipe(
					mergeMap(() => {
						return this.accountService.api.setAccountNote({ ...this.accountNote, ...this.formAccountNote, accountId: this.accountId });
					})
				);
			else o = this.accountService.api.updateAccount({ ...this.account, ...this.formAccount });
			o.subscribe({
				next: (_) => {
					this.alertMessageService.success('Account saved.');
					this.getAccount(this.isCurrentAccount);
				},
				error: (error) => this.alertMessageService.error('Error saving account.', error),
			});
		}
	}
}
