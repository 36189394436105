import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Account, AccountStatus, AccountType, User, UserRole, UserStatus } from '@razberi-ui/core/data-types';
import { AlertMessageService } from '@razberi-ui/shared';
import { AccountService } from '../../../services/account.service';
import { Observable } from 'rxjs';
import { AuthService, CreateAccountRequest } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-account-modal',
	templateUrl: './account-modal.component.html',
	styleUrls: ['./account-modal.component.scss'],
})
export class AccountModalComponent {
	@Input() accountId?: number;

	isCurrentAccount: boolean = false;
	isCurrentPrimaryUser: boolean = false;
	editorAccountType: AccountType = AccountType.Customer;
	account: Account;
	providers: Account[] = [];
	isValid: boolean = false;
	isAccountValid: boolean = false;
	formAccount: Account;
	isUserValid: boolean = false;
	formUser: User;

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly authService: AuthService,
		private readonly accountsService: AccountService
	) {}

	ngOnInit() {
		this.editorAccountType = this.authService.data.account.type;
		if (this.accountId) {
			this.isCurrentAccount = this.accountId === this.authService.data.account.accountId;
			this.isCurrentPrimaryUser = this.isCurrentAccount && this.authService.data.account.primaryUserId === this.authService.data.user.userId;
			this.isUserValid = true;
			this.accountsService.api.getAccount(this.accountId).subscribe({
				next: (account: Account) => (this.account = account),
				error: (error) => this.alertMessageService.error('Error loading account.', error),
			});
		}
		if (this.editorAccountType === AccountType.Global) this.getProviderAccounts();
	}

	getProviderAccounts() {
		this.accountsService.api.getProviderAccounts().subscribe((accounts: Account[]) => {
			this.providers = accounts.filter((a) => a.status === AccountStatus.Enabled);
		});
	}

	onAccountIsValid(isValid: boolean) {
		this.isAccountValid = isValid;
		this.isValid = this.isAccountValid && this.isUserValid;
	}

	onUserIsValid(isValid: boolean) {
		this.isUserValid = isValid;
		this.isValid = this.isAccountValid && this.isUserValid;
	}

	onAccountChanges(formAccount: Account) {
		this.formAccount = formAccount;
	}

	onUserChanges(formUser: User) {
		this.formUser = formUser;
	}

	save() {
		if (this.isValid) {
			let o: Observable<void>;
			let a: Account;
			if (this.accountId) {
				a = { ...this.account, ...this.formAccount };
				if (this.isCurrentAccount) {
					a.type = this.account.type;
					a.status = this.account.status;
				}
				o = this.accountsService.api.updateAccount(a);
			} else {
				a = { ...this.formAccount };
				if (this.editorAccountType == AccountType.Provider) a.type = AccountType.Customer;
				a.status = AccountStatus.Pending;
				let u: User = { ...this.formUser, roles: [UserRole.Administrator], status: UserStatus.Pending };
				let r: CreateAccountRequest = { account: a, user: u, reCaptchaResponseToken: null };
				o = this.accountsService.api.createAccount(r);
			}
			o.subscribe({
				next: (_) => {
					this.alertMessageService.success(`Account ${this.accountId ? 'saved' : 'created'}.`);
					this.activeModal.close();
				},
				error: (error) => {
					this.alertMessageService.error(`Error ${this.accountId ? 'saving' : 'creating'} account.`, error);
				},
			});
		}
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
