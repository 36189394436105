import { EnumAttributes } from '@razberi-ui/shared';

export enum UserRole {
	None = 0,
	Administrator = 1,
	Installer = 2,
	ManageAdmin = 3,
}

export const UserRoleAttributes = new Map<UserRole, EnumAttributes>([
	[UserRole.None, { label: 'None' }],
	[UserRole.Administrator, { label: 'Administrator' }],
	[UserRole.Installer, { label: 'Installer' }],
	[UserRole.ManageAdmin, { label: 'Manage Admin' }],
]);
