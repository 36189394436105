import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
	selector: 'shared-code-entry',
	templateUrl: './code-entry.component.html',
	styleUrls: ['./code-entry.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CodeEntryComponent,
			multi: true,
		},
	],
})
export class CodeEntryComponent implements ControlValueAccessor {
	@ViewChild('input', { static: true, read: ElementRef }) inputElementRef: ElementRef;
	@Input() characterCount: number;
	@Input() digitsOnly: boolean;

	propagateChange = (_: any) => {};
	propagateTouch = () => {};
	value: string;
	isReadonly = false;
	idxAutofocus = 0;
	id: string;
	characters: string[];

	constructor(private readonly utils: UtilitiesService) {
		this.id = `codeentry_${this.utils.helpers.getRandomString()}`;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.characterCount?.currentValue) {
			this.characters = new Array<string>(this.characterCount);
			this.characters = [];
			for (let i = 0; i < this.characterCount; ++i) {
				this.characters.push('');
			}
		}
	}

	setValue(obj: any): void {
		this.value = obj;
	}

	writeValue(obj: any): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.propagateTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isReadonly = isDisabled;
	}

	inputChange(idx: number, $event: any) {
		if ($event.key === 'Shift') return;
		else if ($event.key === 'Backspace') {
			this.idxAutofocus = idx > 0 ? idx - 1 : 0;
			this.characters[this.idxAutofocus] = '';
		} else if ($event.key === 'Delete') {
			if (this.characters[idx]?.length > 0) this.characters[idx] = '';
		} else {
			const matches = $event.target.value.match('^[a-zA-Z0-9]$');
			if (matches == null) {
				$event.target.value = '';
				this.characters[idx] = '';
				return;
			}

			this.characters[idx] = $event.target.value.toUpperCase();
			this.idxAutofocus = idx + 1 < this.characterCount ? idx + 1 : -1;
			this.characters[this.idxAutofocus] = '';
		}

		this.value = this.characters.join('');
		this.propagateTouch();
		this.propagateChange(this.value);
	}

	inputClicked(idx: number) {
		this.idxAutofocus = idx;
		this.characters[this.idxAutofocus] = '';

		this.value = this.characters.join('');
		this.propagateTouch();
		this.propagateChange(this.value);
	}
}
