import { Component } from '@angular/core';
import { CloudApiConstants, CloudApiConfigService, AuthService } from '@razberi-ui/api/cloud-api';
import { User } from '@razberi-ui/core/data-types';
import { AlertMessageService, ImageUploaderConfig, PageConfigService } from '@razberi-ui/shared';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-root-monitor-cloud-profile-details',
	templateUrl: './profile-details.component.html',
	styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent {
	user: User;
	isPrimaryUser: boolean = false;
	isValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	formUser: User;

	avatarConfig: ImageUploaderConfig = {
		image: null,
		placeholder: '/assets/core/images/img/avatar.jpg',
		type: 'horizontal',
		shape: 'round',
		maxWidth: '120',
		aspectRatio: 1,
		recommendedText: '240x240 jpg/png recommended',
		uploadImageAction: (image) => {
			this.userService.api.setCurrentUserAvatar({ imageData: image }).subscribe({
				next: (_) => {
					this.getCurrentUser(true);
					this.alertMessageService.success('Avatar uploaded.');
				},
				error: (error) => {
					this.alertMessageService.error('Error uploading avatar.', error);
				},
			});
		},
		removeImageAction: () => {
			this.userService.api.removeCurrentUserAvatar().subscribe({
				next: (_) => {
					this.getCurrentUser(true);
					this.alertMessageService.success('Avatar removed.');
				},
				error: (error) => {
					this.alertMessageService.error('Error removing avatar.', error);
				},
			});
		},
	};

	constructor(
		private readonly configService: CloudApiConfigService,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly authService: AuthService,
		private readonly userService: UserService
	) {}

	ngOnInit() {
		this.pageConfigService.data.toolbarConfig = {
			buttons: [
				{
					text: 'Save Profile',
					icon: 'check',
					color: 'primary',
					isEnabled$: this.isValidSubject.asObservable(),
					click: () => {
						this.onSubmit();
					},
				},
			],
		};
		this.isPrimaryUser = this.authService.data.account.primaryUserId === this.authService.data.user.userId;
		this.getCurrentUser(false);
	}

	ngOnDestroy() {
		this.isValidSubject.complete();
		this.pageConfigService.data.toolbarConfig = null;
	}

	getCurrentUser(updateState: boolean) {
		this.userService.api.getCurrentUser().subscribe({
			next: (user) => {
				this.user = user;
				if (user.avatarToken)
					this.avatarConfig.image = `${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.images.getImage(user.avatarToken)}`;
				else this.avatarConfig.image = null;
				if (updateState) this.authService.helpers.updateUser(user);
			},
			error: (error) => {
				this.alertMessageService.error('Error getting current user.', error);
			},
		});
	}

	onIsValid(isValid: boolean) {
		this.isValidSubject.next(isValid);
	}

	onChanges(formUser: User) {
		this.formUser = formUser;
	}

	onSubmit() {
		if (this.isValidSubject.value) {
			this.userService.api.updateCurrentUser({ ...this.user, ...this.formUser }).subscribe({
				next: (_) => {
					this.getCurrentUser(true);
					this.alertMessageService.success('Profile saved.');
				},
				error: (error) => {
					this.alertMessageService.error('Error saving profile.', error);
				},
			});
		}
	}
}
