import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RestartWindowsServiceManageTaskParams } from '@razberi-ui/core/data-types';
import { Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-manage-task-form-parameters-restart-windows-service',
	templateUrl: './restart-windows-service.component.html',
	styleUrls: ['./restart-windows-service.component.scss'],
})
export class RestartWindowsServiceComponent {
	@Input() accountId: number;
	@Input() parameters: string;
	@Input() readonly: boolean;
	@Output() parametersChanged: EventEmitter<string> = new EventEmitter<string>();
	@Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

	subscriptions: Subscription = new Subscription();
	deserialized: RestartWindowsServiceManageTaskParams;
	parametersForm: UntypedFormGroup;

	constructor(private readonly formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.buildForm();
		this.subscriptions.add(
			this.parametersForm.statusChanges.pipe(distinctUntilChanged()).subscribe((_) => {
				this.emitValidity();
			})
		);
		this.subscriptions.add(
			this.parametersForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.parametersChanged.emit(JSON.stringify(value));
			})
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.buildForm();
		this.deserialized = JSON.parse(this.parameters);
		this.parametersForm.reset();
		if (this.parameters?.length > 0) this.parametersForm.patchValue(this.deserialized);
		this.emitValidity();
	}

	buildForm() {
		if (this.parametersForm != null) return;

		this.parametersForm = this.formBuilder.group({
			serviceName: [null, [Validators.required]],
		});
	}

	emitValidity() {
		this.valid.emit(this.parametersForm.valid === true);
	}
}
