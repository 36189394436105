import { Component, Input, SimpleChanges } from '@angular/core';
import { ManageTaskInstance, ManageTaskStatus } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-manage-task-instance-summary',
	templateUrl: './cell-manage-task-instance-summary.component.html',
	styleUrls: ['./cell-manage-task-instance-summary.component.scss'],
})
export class CellManageTaskInstanceSummaryComponent {
	@Input() instances: ManageTaskInstance[];

	scheduled: number;
	executing: number;
	succeeded: number;
	failed: number;
	cancelled: number;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		this.instances = changes?.instances?.currentValue || [];
		this.process();
	}

	process() {
		this.scheduled = this.instances?.filter((mti) => mti.status === ManageTaskStatus.Scheduled)?.length;
		this.executing = this.instances?.filter((mti) => mti.status === ManageTaskStatus.Executing)?.length;
		this.succeeded = this.instances?.filter((mti) => mti.status === ManageTaskStatus.Succeeded)?.length;
		this.failed = this.instances?.filter((mti) => mti.status === ManageTaskStatus.Failed)?.length;
		this.cancelled = this.instances?.filter((mti) => mti.status === ManageTaskStatus.Cancelled)?.length;
	}

	getTooltip(type: string) {
		if (type === 'scheduled') return `${this.scheduled} device${this.scheduled != 1 ? 's are' : ' is'} scheduled.`;
		else if (type === 'executing') return `${this.executing} device${this.executing != 1 ? 's are' : ' is'} executing.`;
		else if (type === 'succeeded') return `${this.succeeded} device${this.succeeded != 1 ? 's have' : ' has'} succeeded.`;
		else if (type === 'failed') return `${this.failed} device${this.failed != 1 ? 's have' : ' has'} failed.`;
		else if (type === 'cancelled') return `${this.cancelled} device${this.cancelled != 1 ? 's have been' : ' was'} cancelled.`;
		else return null;
	}
}
