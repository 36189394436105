import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CloudApiConstants, CloudApiConfigService, CoreServerSettingsService, AuthService } from '@razberi-ui/api/cloud-api';
import { Account, AccountType, User, UserRole, UserStatus } from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, PageConfigService, TableItemSelectMode, TableSettings, ToggleSwitchEvent } from '@razberi-ui/shared';
import { AccountService } from '../../../../../services/account.service';
import { UserService } from '../../../../../services/user.service';
import { mergeMap } from 'rxjs';
import { UserModalComponent } from '../../../../users/user-modal/user-modal.component';
import { UserPasswordModalComponent } from '../../../../users/user-password-modal/user-password-modal.component';

@Component({
	selector: 'app-root-monitor-cloud-account-view-tab-users',
	templateUrl: './account-view-tab-users.component.html',
	styleUrls: ['./account-view-tab-users.component.scss'],
})
export class AccountViewTabUsersComponent {
	accountId?: number;
	account: Account;
	users: User[];
	showNewUserButton: boolean;
	ssoEnabled: boolean;
	ssoEnforced: boolean;
	tableSettings: TableSettings;
	isInitialized: boolean = false;

	constructor(
		private readonly configService: CloudApiConfigService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly modalService: NgbModal,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageConfigService: PageConfigService,
		private readonly confirmService: ConfirmService,
		private readonly authService: AuthService,
		private readonly accountService: AccountService,
		private readonly userService: UserService,
		private readonly settingsService: CoreServerSettingsService
	) {
		this.users = [];
		this.showNewUserButton = false;
		this.ssoEnabled = this.settingsService.data.settings?.sso?.enable === true;
		this.ssoEnforced = this.settingsService.data.settings?.sso?.enforce === true;
		this.tableSettings = {
			useSearch: true,
			itemSelectMode: TableItemSelectMode.Multi,
			useEdit: true,
			usePageSize: true,
			hidePagination: false,
			uniqueId: 'userId',
			columnConfig: [
				{
					primaryKey: 'firstName',
					header: 'Name',
					useForSearch: true,
					cellType: 'link-button',
					transform: (value, tableItem) => {
						const c: any = { text: `${tableItem.firstName} ${tableItem.lastName}` };
						c.disabled = this.ssoEnforced;
						if (tableItem.avatarToken) {
							c.image = `${this.configService.data.config.apiHost}${CloudApiConstants.apiPath.images.getImage(tableItem.avatarToken)}`;
							c.imageClass = 'avatar';
						} else c.icon = 'user-circle';
						return c;
					},
					clickHandlerFunction: (params, val) => {
						this.manageUser(params);
					},
				},
				{ primaryKey: 'lastName', header: 'Last Name', useForSearch: true, hide: true },
				// {
				// 	primaryKey: 'roles', header: 'Roles', useForSearch: true, transform: (value, tableItem) => {
				// 		const isPrimary = tableItem.isPrimary ? ' <span class="text-primary text-xs ms-1">primary</span>' : '';
				// 		const isPending = tableItem.status === UserStatus.Pending ? ' <span class="text-danger text-xs ms-1">pending</span>' : '';
				// 		const isLocked = tableItem.status === UserStatus.Locked ? ' <span class="text-danger text-xs ms-1">locked</span>' : '';
				//         const roleString = value?.length > 0 ? value.map(v => UserRole[v])?.join(', ') : "(Default User)"
				// 		return `${roleString}${isPrimary}${isPending}${isLocked}`;
				// 	}
				// },
				{ primaryKey: 'email', header: 'Email Address', useForSearch: true },
				{ primaryKey: 'phone', header: 'Phone Number', useForSearch: true },
				{ primaryKey: 'department', header: 'Department', useForSearch: true },
				{ primaryKey: 'jobTitle', header: 'Job Title', useForSearch: true },
				{ primaryKey: 'roles', header: 'Roles', cellType: 'user-roles' },
				{
					primaryKey: 'status',
					header: 'Enabled',
					useForSearch: false,
					cellType: 'toggle-switch',
					transform: (value, tableItem) => {
						return {
							value: value === UserStatus.Enabled,
							disabled: value === UserStatus.Pending || value == UserStatus.Locked || tableItem.isPrimary || tableItem.isSelf,
						};
					},
					clickHandlerFunction: (params, event) => {
						this.toggleStatus(params, event);
					},
				},
			],
			headerButtons: [
				{
					label: 'Delete',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteUsers(params.value);
					},
				},
			],
			stickyHeaderButtons: [
				{
					label: 'Refresh',
					faIcon: 'sync',
					clickHandlerFunction: () => {
						this.getUsers();
					},
				},
			],
			editActions: [
				{ label: 'Edit', faIcon: 'edit', enabled: !this.ssoEnforced, clickHandlerFunction: (params) => this.manageUser(params) },
				{ label: 'Reset Password', faIcon: 'unlock', enabled: !this.ssoEnforced, clickHandlerFunction: (params) => this.resetPassword(params) },
				{
					label: 'Delete',
					faIcon: 'trash',
					faIconColor: 'danger',
					clickHandlerFunction: (params) => {
						this.deleteUser(params);
					},
				},
			],
		};
	}

	ngOnInit() {
		if (this.activatedRoute.snapshot.parent.paramMap.has('accountId'))
			this.accountId = Number(this.activatedRoute.snapshot.parent.paramMap.get('accountId'));
		else this.accountId = this.authService.data.account.accountId;

		this.showNewUserButton =
			(this.authService.data.account.type === AccountType.Global && this.accountId !== this.authService.data.account.accountId) ||
			this.authService.helpers.userHasRole(UserRole.Administrator) == true;
		if (this.showNewUserButton) {
			this.pageConfigService.data.toolbarConfig = {
				buttons: [
					{
						text: 'New User',
						icon: 'user-plus',
						color: 'primary',
						click: () => {
							this.manageUser();
						},
					},
				],
			};
		}
		this.settingsService.streams.settings$.subscribe((settings) => {
			this.ssoEnabled = settings?.sso?.enable === true;
			this.ssoEnforced = settings?.sso?.enforce === true;
		});

		this.getUsers();
	}

	getUsers() {
		this.accountService.api
			.getAccount(this.accountId)
			.pipe(
				mergeMap((account: Account) => {
					this.account = account;
					return this.userService.api.getUsersByAccountId(this.accountId);
				})
			)
			.subscribe({
				next: (users: User[]) => {
					this.users = users
						.map((user: User) => {
							return {
								...user,
								isPrimary: this.account.primaryUserId === user.userId,
								isSelf: this.authService.data.user.userId === user.userId,
							};
						})
						.sort((u1: User, u2: User) => (u1.lastName > u2.lastName ? 1 : u1.lastName < u2.lastName ? -1 : u1.firstName > u2.firstName ? 1 : -1));
					this.isInitialized = true;
				},
				error: (_) => {
					this.alertMessageService.error('Error getting users.');
				},
			});
	}

	manageUser(user?: User) {
		const modal = this.modalService.open(UserModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
		if (user) modal.componentInstance.userId = user.userId;
		modal.componentInstance.accountId = this.accountId;
		modal.result.then(
			(_) => {
				this.getUsers();
			},
			(_) => {}
		);
	}

	resetPassword(user: User) {
		const modal = this.modalService.open(UserPasswordModalComponent, { backdrop: 'static', centered: true });
		modal.componentInstance.userId = user.userId;
		modal.componentInstance.accountId = this.accountId;
		modal.result.then(
			(_) => {
				this.getUsers();
			},
			(_) => {}
		);
	}

	toggleStatus(user: any, event: ToggleSwitchEvent) {
		if (user.isPrimary && !event.value) {
			this.alertMessageService.error('Cannot disable primary administrator.');
			event.cancel();
			return;
		}

		if (user.isSelf && !event.value) {
			this.alertMessageService.error('Cannot disable self.');
			event.cancel();
			return;
		}

		user.status = event.value ? 1 : 2;
		this.userService.api.updateUser(user).subscribe({
			next: (_) => {
				this.alertMessageService.success('Status updated.');
			},
			error: (error) => {
				//event.cancel();
				this.users.find((a) => a.userId === user.userId).status = !event.value ? 1 : 2;
				this.alertMessageService.error('Error updating status.');
			},
		});
	}

	deleteUsers(users: any) {
		if (users.some((user) => user.isPrimary)) {
			this.alertMessageService.error('Cannot delete primary administrator.');
			return;
		}

		if (users.some((user) => user.isSelf)) {
			this.alertMessageService.error('Cannot delete self.');
			return;
		}

		if (users.some((user) => user.userId < 100)) {
			this.alertMessageService.error('Cannot delete built-in users.');
			return;
		}

		this.confirmService
			.confirm({
				title: 'Delete users?',
				text: `Are you sure you want to delete ${users.length} users?`,
				icon: 'user-times',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'user-times',
			})
			.then(
				(_) => {
					users.forEach((user) => {
						this.userService.api.deleteUser(user.userId).subscribe({
							next: (_) => {
								this.alertMessageService.success('User deleted.');
								this.getUsers();
							},
							error: (error) => {
								this.alertMessageService.error('Error deleting user.');
							},
						});
					});
				},
				(_) => {}
			);
	}

	deleteUser(user: any) {
		if (user.isPrimary) {
			this.alertMessageService.error('Cannot delete primary administrator.');
			return;
		}

		if (user.isSelf) {
			this.alertMessageService.error('Cannot delete self.');
			return;
		}

		if (user.userId < 100) {
			this.alertMessageService.error('Cannot delete built-in users.');
			return;
		}

		this.confirmService
			.confirm({
				title: 'Delete user?',
				text: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
				icon: 'user-times',
				confirmButtonText: 'Delete',
				confirmButtonColor: 'danger',
				confirmButtonIcon: 'user-times',
			})
			.then(
				(_) => {
					this.userService.api.deleteUser(user.userId).subscribe({
						next: (_) => {
							this.alertMessageService.success('User deleted.');
							this.getUsers();
						},
						error: (error) => {
							this.alertMessageService.error('Error deleting user.');
						},
					});
				},
				(_) => {}
			);
	}
}
