<div
	class="data-table-search d-flex align-items-center py-2 cursor-pointer position-relative"
	(click)="isOpen = !isOpen"
	title="Click to {{ isOpen === true ? 'close' : 'open' }} filters."
	[ngClass]="{ 'not-empty': selectedSearchItems?.length > 0 }">
	<div><fa-icon icon="filter" size="lg" [ngClass]="{ 'text-primary': selectedSearchItems?.length > 0 }"></fa-icon></div>
	<div *ngIf="selectedSearchItems?.length > 0" ngbDropdown #dropSelected="ngbDropdown" display="dynamic" class="ms-2">
		<button
			type="button"
			(mouseenter)="$event.stopPropagation(); dropSelected.open()"
			class="data-table-search-selected after-none cursor-default"
			[ngClass]="{ active: dropSelected.isOpen() }">
			{{ selectedSearchItems?.length || 0 }} Selected
		</button>
		<div *ngIf="isOpen !== true" ngbDropdownMenu (mouseleave)="$event.stopPropagation(); dropSelected.close()" class="box-shadow" style="right: 0">
			<div
				*ngFor="let item of selectedSearchItems"
				class="d-flex cursor-default px-2 py-1 ws-nowrap"
				title="{{ item.fieldTitle }}: {{ item.tooltipValue ?? item.label }}"
				(click)="$event.stopPropagation()">
				<span class="flex-1">{{ item.label || 'N/A' }}</span>
				<fa-icon
					icon="times"
					size="md"
					class="ms-2 cursor-pointer text-primary"
					(click)="$event.stopPropagation(); clearItem(item)"
					title="Click to remove this filter"></fa-icon>
			</div>
		</div>
	</div>
	<div
		*ngIf="selectedSearchItems?.length > 0"
		class="ms-2 text-muted cursor-pointer"
		(click)="$event.stopPropagation(); onResetFilter()"
		title="Click to reset all filters.">
		<fa-icon icon="times" size="lg"></fa-icon>
	</div>
</div>
<div style="position: relative">
	<div style="position: absolute; width: 600px; right: 0; top: 0">
		<div *ngIf="isOpen === true" class="data-table-search-panel position-absolute d-flex flex-column">
			<div class="p-3">
				<ng-content select="[dataTableSearchForm]"></ng-content>
				<div class="data-table-search-panel-footer d-flex align-items-center">
					<button type="button" class="btn btn-link me-auto" (click)="onResetFilter()"><fa-icon icon="trash-alt"></fa-icon>Reset Filters</button>
					<div class="flex-1"></div>
					<button type="button" class="btn btn-secondary me-3" (click)="closeSearch()" #cancelBtn>
						<fa-icon icon="times" class="me-1"></fa-icon>Cancel
					</button>
					<button type="button" class="btn btn-primary" (click)="onApplyFilter()"><fa-icon icon="search" class="me-1"></fa-icon>Apply Filters</button>
				</div>
			</div>
		</div>
	</div>
</div>
