<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="bell"></fa-icon><span class="ps-2">{{ alertProfileId ? 'Edit' : 'New' }} Alert Profile</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<app-root-monitor-cloud-alert-profile-form
			[alertData]="alertData"
			(isValid)="onIsValid($event)"
			(changes)="onChanges($event)"
			(submit)="save()"
			ngbAutoFocus></app-root-monitor-cloud-alert-profile-form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="!isValid"><fa-icon icon="check"></fa-icon> Save</button>
	</div>
</div>
