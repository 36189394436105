<ul ngbNav #panel="ngbNav" activeId="alerts" class="nav-tabs page-content-tabs">
	<li ngbNavItem="alerts">
		<a ngbNavLink><fa-icon icon="bell"></fa-icon> Open Alerts</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-alert-table
				*ngIf="isInitialized === true"
				[accountIds]="accountIds"
				[alertTypeIds]="alertTypeIds"
				[dateRange]="dateRange"
				[showAccount]="showAccount"
				[showRefresh]="showRefresh"
				[showDownloadCsv]="showDownloadCsv"
				[dataSource]="dataSource"></app-root-monitor-cloud-alert-table>
		</ng-template>
	</li>
	<li ngbNavItem="closed-alerts">
		<a ngbNavLink><fa-icon icon="bell-slash"></fa-icon> Closed Alerts</a>
		<ng-template ngbNavContent>
			<app-root-monitor-cloud-alert-table
				*ngIf="isInitialized === true"
				[accountIds]="accountIds"
				[alertTypeIds]="alertTypeIds"
				[dateRange]="dateRange"
				[showAccount]="showAccount"
				[showRefresh]="showRefresh"
				[showDownloadCsv]="showDownloadCsv"
				[dataSource]="dataSource"
				[getClosed]="true"></app-root-monitor-cloud-alert-table>
		</ng-template>
	</li>
</ul>
<div [ngbNavOutlet]="panel"></div>
