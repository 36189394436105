import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'shared-form-multi-select',
	templateUrl: './form-multi-select.component.html',
	styleUrls: ['./form-multi-select.component.scss'],
})
export class FormMultiSelectComponent {
	@Input() items: any[] = [];
	@Input() selected: any[] = [];
	@Input() dotsAfter: number = 4;
	@Input() clearable: boolean = false;
	@Input() closeOnSelect: boolean = true;
	@Input() clearSearchOnAdd: boolean = true;
	@Input() labelForId: string;
	@Input() bindLabel: string = 'label';
	@Input() showBadge: boolean = false;
	@Output() selectedChange: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() applyFilter: EventEmitter<void> = new EventEmitter();

	constructor() {}

	onChange() {
		this.selectedChange.emit(this.selected);
	}

	clearAll() {
		this.selected = [];
		this.selectedChange.emit(this.selected);
	}

	clearItem(item: any) {
		this.selected = this.selected?.filter((selected) => selected !== item);
		this.selectedChange.emit(this.selected);
	}

	applySearchFilter() {
		this.applyFilter.emit();
	}
}
