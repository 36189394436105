import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountNote } from '@razberi-ui/core/data-types';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-account-note-form',
	templateUrl: './account-note-form.component.html',
	styleUrls: ['./account-note-form.component.scss'],
})
export class AccountNoteFormComponent {
	@Input() accountNote: AccountNote;
	@Input() reset$?: Observable<void>;
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() changes: EventEmitter<AccountNote> = new EventEmitter<AccountNote>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	accountNoteForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	constructor(private readonly fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.accountNoteForm = this.fb.group({ note: [null] });

		if (this.reset$)
			this.subscriptions.add(
				this.reset$.subscribe((_) =>
					setTimeout(() => {
						this.accountNoteForm.reset();
					}, 0)
				)
			);
		this.subscriptions.add(
			this.accountNoteForm.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
				this.isValid.emit(status === 'VALID');
			})
		);
		this.subscriptions.add(
			this.accountNoteForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				this.changes.emit({ ...value });
			})
		);
		this.accountNoteForm.updateValueAndValidity();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.accountNote && changes.accountNote.currentValue) this.accountNoteForm.patchValue(changes.accountNote.currentValue);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	submitForm() {
		if (this.accountNoteForm.valid) this.submit.emit();
	}
}
