import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavService, PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'theme-razberi-core-toolbar',
	templateUrl: './theme-razberi-core-toolbar.component.html',
	styleUrls: ['./theme-razberi-core-toolbar.component.scss'],
})
export class ThemeRazberiCoreToolbarComponent implements OnInit {
	routes: { title: string; url: string }[] = [];
	placeholders: { key: string; value: string }[] = [];
	crumbs: { title: string; url: string }[] = [];
	configuration: PageToolbarConfiguration = { buttons: [], checkboxes: [] };
	subscriptions: Subscription = new Subscription();
	buttonSubscriptions: Subscription;
	checkboxSubscriptions: Subscription;

	constructor(private changeDetectorRef: ChangeDetectorRef, readonly navService: NavService, readonly pageConfigService: PageConfigService) {}

	ngOnInit() {
		this.navService.streams.navEndEvents$.subscribe((_) => {
			this.configuration = { buttons: [], checkboxes: [] };
		});

		this.subscriptions.add(
			this.pageConfigService.streams.toolbarConfig$.subscribe((configuration: PageToolbarConfiguration) => {
				if (this.buttonSubscriptions) this.buttonSubscriptions.unsubscribe();
				if (this.checkboxSubscriptions) this.checkboxSubscriptions.unsubscribe();

				this.buttonSubscriptions = new Subscription();
				this.checkboxSubscriptions = new Subscription();

				let c: PageToolbarConfiguration = configuration;
				if (!c) c = { buttons: [], checkboxes: [] };

				c.buttons?.forEach((b) => {
					if (b.isEnabled$ == null) b.isEnabled = true;
					else
						this.buttonSubscriptions.add(
							b.isEnabled$.subscribe((isEnabled: boolean) => {
								b.isEnabled = isEnabled;
								this.changeDetectorRef.detectChanges();
							})
						);
				});

				c.checkboxes?.forEach((cb) => {
					if (!cb.isEnabled$) cb.isEnabled = true;
					else
						this.checkboxSubscriptions.add(
							cb.isEnabled$.subscribe((isEnabled: boolean) => {
								cb.isEnabled = isEnabled;
								this.changeDetectorRef.detectChanges();
							})
						);
				});

				this.configuration = c;
				this.changeDetectorRef.detectChanges();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
