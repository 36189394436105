<div id="card-footer" class="layout-card-footer d-flex align-items-center justify-content-end">
	<div>
		<button
			*ngFor="let button of configuration?.buttons"
			type="button"
			class="btn btn-link btn-sm m-0 me-3 pe-3 p-0 btn-{{ button.color }}"
			style="border-right: 1px solid"
			[disabled]="!button.isEnabled"
			(click)="button.click()">
			<fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>
			{{ button.text }}
		</button>
		<div *ngFor="let checkbox of configuration?.checkboxes; let rowIndex = index" class="form-check me-3">
			<input
				type="checkbox"
				class="form-check-input"
				id="page-toolbar-checkbox_{{ rowIndex }}"
				[ngModel]="checkbox.isChecked"
				[disabled]="!checkbox.isEnabled"
				(change)="checkbox.change($event.target.checked)" />
			<label for="page-toolbar-checkbox_{{ rowIndex }}" class="form-check-label">
				<fa-icon *ngIf="checkbox.icon" [icon]="checkbox.icon"></fa-icon>
				{{ checkbox.text }}
			</label>
		</div>
	</div>
	<div class="text-muted">v{{ version }}</div>
</div>
