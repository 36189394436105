import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, map } from 'rxjs';

interface VersionModel {
	version: string;
}

@Injectable({
	providedIn: 'root',
})
export class VersionService {
	private store: {
		version: string;
		version$: BehaviorSubject<string>;
	} = {
		version: undefined,
		version$: new BehaviorSubject(undefined),
	};

	constructor(private readonly logger: NGXLogger, private readonly http: HttpClient) {}

	get data() {
		const store = this.store;

		return {
			get version(): string {
				return store.version;
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get version$(): Observable<string> {
				return store.version$.asObservable();
			},
		};
	}

	get helpers() {
		return {
			initialize: (): Promise<void> => {
				// manually create a promise so we can subscribe to config & wait for it to load before initializing others
				return new Promise((resolve) => {
					this.http
						.get<VersionModel>('assets/version.json')
						.pipe(
							map((model) => {
								this.logger.debug('Version data', model);
								this.store.version = model.version;
								this.store.version$.next(this.store.version);
								resolve(null);
							})
						)
						.subscribe();
				});
			},
		};
	}
}
