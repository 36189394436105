import { Injectable } from '@angular/core';
import {
	AwsSettings,
	CloudAgentSettings,
	CoreServerSettings,
	ComplianceSettings,
	FileStoreSettings,
	FileStoreSettingsProvider,
	GlobalSettings,
	LocalFileStoreSettings,
	LoginSettings,
	ManageSettings,
	MonitorAgentSettings,
	CloudApiSettings,
	S3FileStoreSettings,
	SettingsDashboard,
	SinkApiSettings,
	SmsSettings,
	SmsSettingsProvider,
	SmtpSettings,
	SsoSettings,
	TropoSmsSettings,
	TwilioSmsSettings,
} from '@razberi-ui/core/data-types';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CloudApiService } from './cloud-api.service';
import { CloudApiConfigService } from './cloud-api-config.service';
import { HttpResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Injectable({
	providedIn: 'root',
})
export class CoreServerSettingsService {
	private store: {
		settings: CoreServerSettings;
		settings$: BehaviorSubject<CoreServerSettings>;
		loginSettings: LoginSettings;
		loginSettings$: BehaviorSubject<LoginSettings>;
	} = {
		settings: undefined,
		settings$: new BehaviorSubject(undefined),
		loginSettings: undefined,
		loginSettings$: new BehaviorSubject(undefined),
	};

	constructor(private readonly logger: NGXLogger, private readonly monitorCloudApi: CloudApiService, private readonly configService: CloudApiConfigService) {}

	get data() {
		const store = this.store;

		return {
			get settings(): CoreServerSettings {
				return store.settings;
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get settings$(): Observable<CoreServerSettings> {
				return store.settings$.asObservable();
			},
		};
	}

	get api() {
		return {
			getSettings: (): Observable<CoreServerSettings> => {
				return this.monitorCloudApi.settings.getSettings().pipe(
					map((settings) => {
						this.store.settings = settings;
						this.store.settings$.next(settings);
						return settings;
					})
				);
			},
			getCoreSettings: (): Observable<CoreServerSettings> => {
				return this.monitorCloudApi.settings.getCoreSettings().pipe(
					map((settings) => {
						this.store.settings = settings;
						this.store.settings$.next(settings);
						return settings;
					})
				);
			},
			getFullSettings: (): Observable<CoreServerSettings> => {
				return this.monitorCloudApi.settings.getFullSettings().pipe(
					map((settings) => {
						this.store.settings = settings;
						this.store.settings$.next(settings);
						return settings;
					})
				);
			},
			getLoginSettings: (): Observable<LoginSettings> => {
				return this.monitorCloudApi.settings.getLoginSettings();
			},
			getSettingsDashboard: (): Observable<SettingsDashboard> => {
				return this.monitorCloudApi.settings.getSettingsDashboard();
			},
			exportSettings(): Observable<HttpResponse<Blob>> {
				return this.monitorCloudApi.settings.exportSettings();
			},
			setGlobal: (global: GlobalSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setGlobal(global);
			},
			setSso: (sso: SsoSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setSso(sso);
			},
			setSmtp: (smtp: SmtpSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setSmtp(smtp);
			},
			setCloudApi: (cloudApi: CloudApiSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setCloudApi(cloudApi);
			},
			setSinkApi: (sinkApi: SinkApiSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setSinkApi(sinkApi);
			},
			setCloudAgent: (cloudAgent: CloudAgentSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setCloudAgent(cloudAgent);
			},
			setMonitorAgent: (monitorAgent: MonitorAgentSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setMonitorAgent(monitorAgent);
			},
			setManage: (manage: ManageSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setManage(manage);
			},
			setCompliance: (compliance: ComplianceSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setCompliance(compliance);
			},
			setAws: (aws: AwsSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setAws(aws);
			},
			setFileStore: (fileStore: FileStoreSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setFileStore(fileStore);
			},
			setSms: (sms: SmsSettings): Observable<void> => {
				return this.monitorCloudApi.settings.setSms(sms);
			},
			testSmtp(): Observable<void> {
				return this.monitorCloudApi.settings.testSmtp();
			},
		};
	}

	get helpers() {
		return {
			initialize: (): Promise<void> => {
				// manually create a promise so we can subscribe to config & wait for it to load before initializing settings
				return new Promise((resolve) => {
					// wait for config to load
					this.configService.streams.config$.subscribe((config) => {
						if (config == null) return;
						this.monitorCloudApi.settings
							.getCoreSettings()
							.pipe(
								map((result) => {
									this.logger.debug('Settings service data', result);
									this.store.settings = result;
									this.store.settings$.next(result);
								})
							)
							.subscribe();
					});
					// resolve initialize promise without waiting for settings to load
					resolve(null);
				});
			},
			setS3FileStore: (s3: S3FileStoreSettings): Observable<void> => {
				const filestore: FileStoreSettings = {
					provider: null,
					localFileStoreSettings: null,
					s3FileStoreSettings: s3,
				};
				return this.api.setFileStore(filestore);
			},
			setLocalFileStore: (localFileStore: LocalFileStoreSettings): Observable<void> => {
				const filestore: FileStoreSettings = {
					provider: null,
					localFileStoreSettings: localFileStore,
					s3FileStoreSettings: null,
				};
				return this.api.setFileStore(filestore);
			},
			setTropoSms: (tropo: TropoSmsSettings): Observable<void> => {
				const sms: SmsSettings = {
					provider: null,
					tropoSmsSettings: tropo,
					twilioSmsSettings: null,
				};
				return this.api.setSms(sms);
			},
			setTwilioSms: (twilio: TwilioSmsSettings): Observable<void> => {
				const sms: SmsSettings = {
					provider: null,
					tropoSmsSettings: null,
					twilioSmsSettings: twilio,
				};
				return this.api.setSms(sms);
			},
			parseFileStoreSettingsProvider: (value: string): FileStoreSettingsProvider => {
				if (value == null) return FileStoreSettingsProvider.LocalFileStoreProvider;
				return FileStoreSettingsProvider[value];
			},
			parseSmsSettingsProvider: (value: string): SmsSettingsProvider => {
				if (value == null) return SmsSettingsProvider.TwilioSmsProvider;
				return SmsSettingsProvider[value];
			},
		};
	}
}
