<div *ngIf="accountSubscription && accountStat && accountSubscription.type > 0">
	<div class="row">
		<div class="col-6 h5 fw-bold">Subscription type</div>
		<div class="col-6 h5 fw-bold">{{ type }}</div>
	</div>
	<div class="row">
		<div class="col-6 h5 fw-bold">Status</div>
		<div class="col-6 h5 fw-bold text-{{ statusColor }}">{{ status }}</div>
	</div>
	<div class="row">
		<div class="col-6 h5">Max devices</div>
		<div class="col-6 h5">{{ accountSubscription.maxDevices }} ({{ accountStat.devices }} registered)</div>
	</div>
	<div class="row">
		<div class="col-6 h5">Start date</div>
		<div class="col-6 h5">{{ accountSubscription.startDate | date: 'shortDate' }}</div>
	</div>
	<div class="row">
		<div class="col-6 h5">End date</div>
		<div class="col-6 h5">{{ accountSubscription.endDate | date: 'shortDate' }}</div>
	</div>
</div>
<div *ngIf="accountSubscription && accountStat && accountSubscription.type === 0">
	<h4 class="mb-3 h5">Upgrade your {{ settingsService.settings?.global?.baseTitle || 'MonitorCloud' }} account with a subscription.</h4>
	<ul class="h5">
		<li>Real-time alerts and notifications</li>
		<li>Server Health Dashboard</li>
		<li>IoT Health Dashboard</li>
		<li>Cybersecurity Dashboard</li>
		<li>Audit logs</li>
		<li>Feature updates</li>
	</ul>
	<h6>
		<ng-container *ngIf="settingsService.settings?.global?.isPremise === true">
			Contact {{ settingsService.settings?.rootAccountName || 'your MonitorPremise host' }}
		</ng-container>
		<ng-container *ngIf="settingsService.settings?.global?.isPremise !== true">
			<a href="https://www.razberi.net/about-2/contact-us-video-surveillance-security/" target="_blank">Contact Razberi</a>
		</ng-container>
		for more details or to begin a free trial.
	</h6>
</div>
