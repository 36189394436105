import { Component, Input, SimpleChanges } from '@angular/core';
import { AlertStatus } from '@razberi-ui/core/data-types';

@Component({
	selector: 'app-root-monitor-cloud-cell-alert-status',
	templateUrl: './cell-alert-status.component.html',
	styleUrls: ['./cell-alert-status.component.scss'],
})
export class CellAlertStatusComponent {
	@Input() status: AlertStatus;
	@Input() tooltip: string;
	@Input() disabled: boolean = false;
	@Input() showText: boolean = true;

	icon: string;
	color: string;
	text: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		let icon: string = 'times';
		let color: string = 'status-black';
		let text: string = AlertStatus[AlertStatus.Closed];

		if (this.status === AlertStatus.New) {
			icon = 'bell';
			color = 'status-red';
			text = AlertStatus[AlertStatus.New];
		} else if (this.status === AlertStatus.Acknowledged) {
			icon = 'eye';
			color = 'status-orange';
			text = AlertStatus[AlertStatus.Acknowledged];
		} else if (this.status === AlertStatus.Closed) {
			icon = 'times';
			color = 'status-black';
			text = AlertStatus[AlertStatus.Closed];
		}

		this.icon = icon;
		this.color = color;
		this.text = text;
	}
}
