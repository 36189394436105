import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageSidePanelConfig, PageSidePanelType } from '../data-types/page-side-panel-config.model';

@Injectable({
	providedIn: 'root',
})
export class PageSidePanelService {
	private store: {
		isOpen: boolean;
		isOpen$: BehaviorSubject<boolean>;
		sidePanelConfig: PageSidePanelConfig;
		sidePanelConfig$: BehaviorSubject<PageSidePanelConfig>;
	} = {
		isOpen: false,
		isOpen$: new BehaviorSubject(undefined),
		sidePanelConfig: undefined,
		sidePanelConfig$: new BehaviorSubject(undefined),
	};

	constructor() {}

	get data() {
		const store = this.store;

		return {
			get isOpen(): boolean {
				return store.isOpen;
			},
			set isOpen(value: boolean) {
				store.isOpen = value;
				store.isOpen$.next(value);
			},
			get sidePanelConfig(): PageSidePanelConfig {
				return store.sidePanelConfig;
			},
			set sidePanelConfig(value: PageSidePanelConfig) {
				store.sidePanelConfig = value;
				store.sidePanelConfig$.next(value);
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get isOpen$(): Observable<boolean> {
				return store.isOpen$.asObservable();
			},
			get sidePanelConfig$(): Observable<PageSidePanelConfig> {
				return store.sidePanelConfig$.asObservable();
			},
		};
	}

	get helpers() {
		return {
			openManagedDeviceSidePanelByRef: (title: string, activeTabKey: string, managedDeviceRef: string): void => {
				if (
					this.data.sidePanelConfig?.managedDeviceRef != null &&
					this.data.sidePanelConfig.managedDeviceRef === managedDeviceRef &&
					this.data.sidePanelConfig?.activeTabKey?.length > 0 &&
					this.data.sidePanelConfig?.activeTabKey === activeTabKey
				) {
					this.helpers.closeSidePanel();
					return;
				}

				this.data.isOpen = true;
				this.data.sidePanelConfig = {
					title: title,
					activeTabKey: activeTabKey,
					panelType: PageSidePanelType.ManagedDevice,
					managedDeviceRef: managedDeviceRef,
				};
			},
			openManagedDeviceSidePanelById: (title: string, activeTabKey: string, accountId: number, unitId: number, managedDeviceId: number): void => {
				if (
					this.data.sidePanelConfig?.managedDeviceId != null &&
					this.data.sidePanelConfig.managedDeviceId === managedDeviceId &&
					this.data.sidePanelConfig?.activeTabKey?.length > 0 &&
					this.data.sidePanelConfig?.activeTabKey === activeTabKey
				) {
					this.helpers.closeSidePanel();
					return;
				}

				this.data.isOpen = true;
				this.data.sidePanelConfig = {
					title: title,
					activeTabKey: activeTabKey,
					panelType: PageSidePanelType.ManagedDevice,
					accountId: accountId,
					unitId: unitId,
					managedDeviceId: managedDeviceId,
				};
			},
			openUnitSidePanel: (title: string, activeTabKey: string, accountId: number, unitId: number, canEdit: boolean, editAction: any): void => {
				if (
					this.data.sidePanelConfig?.unitId != null &&
					this.data.sidePanelConfig.unitId === unitId &&
					this.data.sidePanelConfig?.activeTabKey?.length > 0 &&
					this.data.sidePanelConfig?.activeTabKey === activeTabKey
				) {
					this.helpers.closeSidePanel();
					return;
				}

				this.data.isOpen = true;
				this.data.sidePanelConfig = {
					title: title,
					activeTabKey: activeTabKey,
					panelType: PageSidePanelType.Unit,
					accountId: accountId,
					unitId: unitId,
					canEdit: canEdit,
					editAction: editAction,
				};
			},
			openAccountSidePanel: (title: string, activeTabKey: string, accountId: number) => {
				if (
					this.data.sidePanelConfig?.accountId != null &&
					this.data.sidePanelConfig.accountId === accountId &&
					this.data.sidePanelConfig?.activeTabKey?.length > 0 &&
					this.data.sidePanelConfig?.activeTabKey === activeTabKey
				) {
					this.helpers.closeSidePanel();
					return;
				}

				this.data.isOpen = true;
				this.data.sidePanelConfig = {
					title: title,
					activeTabKey: activeTabKey,
					panelType: PageSidePanelType.Account,
					accountId: accountId,
				};
			},
			closeSidePanel: (): void => {
				this.data.isOpen = false;
				this.data.sidePanelConfig = null;
			},
		};
	}
}
