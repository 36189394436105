import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertData } from '../../../data-types/alert-data.model';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertEmailFrequencyTypeAttributes, AlertRecipient, AlertType, Location, Tag, Unit, User } from '@razberi-ui/core/data-types';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { AlertMessageService, ConfirmService, MatchType, TableSettings, ToggleSwitchEvent, UtilitiesService } from '@razberi-ui/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchFilterService } from '../../../services/search-filter.service';
import { AlertProfileService } from '../../../services/alert-profile.service';
import { SelectedUnitsListModalComponent } from '../../units/selected-units-list-modal/selected-units-list-modal.component';
import { AlertRecipientModalComponent } from '../alert-recipient-modal/alert-recipient-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-alert-profile-form',
	templateUrl: './alert-profile-form.component.html',
	styleUrls: ['./alert-profile-form.component.scss'],
})
export class AlertProfileFormComponent {
	@Input() alertData: AlertData;

	@Output() changes: EventEmitter<any> = new EventEmitter<any>();
	@Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() submit: EventEmitter<void> = new EventEmitter<void>();

	alertDetail: UntypedFormGroup;
	existingNames: string[] = [];
	availableAlertTypes: AlertType[] = [];
	availableLocations: Location[] = [];
	availableTags: Tag[] = [];
	subscriptions: Subscription = new Subscription();

	enabled: boolean = false;
	matchType: MatchType = MatchType.Any;
	matchTypeRef: any = MatchType;
	alertTypes: AlertType[] = [];
	locations: Location[] = [];
	tags: Tag[] = [];
	recipients: AlertRecipient[] = [];
	unfilteredDevices: Unit[] = [];
	filteredDevices: Unit[] = [];
	users: User[] = [];

	devicesSearchFilter: any = {
		tags: {
			type: 'objectArray',
			keyToFilterOn: 'tagId',
			selectionType: MatchType.Any,
			values: [],
		},
		locationId: {
			type: 'property',
			values: [],
		},
	};

	tableSettings: TableSettings = {
		useSearch: true,
		useEdit: true,
		usePageSize: false,
		columnConfig: [
			{ primaryKey: 'name', header: 'Recipient Name', useForSearch: true },
			{
				primaryKey: 'alertProfileCeipientId',
				header: 'Notifications',
				useForSearch: true,
				transform: (value, tableItem) => this.generateNotificationText(value, tableItem),
			},
			{ primaryKey: 'email', header: 'Email Address', useForSearch: true },
			{
				primaryKey: 'alertEmailFrequencyType',
				header: 'Email Frequency',
				useForSearch: true,
				transform: (value) => AlertEmailFrequencyTypeAttributes.get(value)?.label,
			},
			{ primaryKey: 'phone', header: 'SMS Phone Number', useForSearch: true },
		],
		editActions: [
			{ label: 'Edit Recipient', faIcon: 'edit', clickHandlerFunction: (params) => this.editRecipient(params) },
			{ label: 'Send Test', faIcon: 'paper-plane', clickHandlerFunction: (params) => this.sendTestAlert(params) },
			{ label: 'Delete', faIcon: 'trash', faIconColor: 'danger', clickHandlerFunction: (params) => this.deleteRecipient(params) },
		],
	};

	constructor(
		private readonly modalService: NgbModal,
		private readonly searchFilterService: SearchFilterService,
		private readonly confirmService: ConfirmService,
		private readonly alertMessageService: AlertMessageService,
		private readonly alertProfileService: AlertProfileService,
		private readonly utils: UtilitiesService
	) {}

	ngOnInit() {
		this.buildForm();
		this.subscriptions.add(
			this.searchFilterService.streams.filteredData$.subscribe((filteredData) => {
				this.filteredDevices = [...(filteredData ?? [])];
			})
		);
		this.subscriptions.add(this.alertDetail.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => this.isValid.emit(status === 'VALID')));
		this.subscriptions.add(
			this.alertDetail.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
				if (this.alertDetail.valid) this.changes.emit({ ...value });
			})
		);
		this.isValid.emit(this.alertDetail.valid);
	}

	ngOnChanges() {
		this.buildForm();
		if (this.alertData) {
			this.setAlertDetail(this.alertData);
			this.existingNames = this.alertData.alertProfile?.alertProfileId
				? this.alertData.existingNames.filter((name) => name !== this.alertData.alertProfile.name)
				: this.alertData.existingNames;
			this.availableAlertTypes = this.alertData.availableAlertTypes;
			this.availableLocations = this.alertData.availableLocations;
			this.availableTags = this.alertData.availableTags;
			this.unfilteredDevices = this.alertData.accountDevices;
			this.users = this.alertData.accountUsers;
			this.applySearchFilter();
		}
	}

	buildForm() {
		if (this.alertDetail != null) {
			return;
		}

		this.alertDetail = new UntypedFormGroup({
			accountName: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
			name: new UntypedFormControl('', [Validators.required, this.validateName.bind(this)]),
			description: new UntypedFormControl(''),
			enabled: new UntypedFormControl(false, [Validators.required]),
			matchType: new UntypedFormControl(MatchType.Any),
			alertTypes: new UntypedFormControl([]),
			tags: new UntypedFormControl([]),
			locations: new UntypedFormControl([]),
			recipients: new UntypedFormControl([]),
		});
	}

	generateNotificationText(value, tableItem) {
		if (tableItem.emailPreferred === true && tableItem.phonePreferred === true) {
			return 'Email and SMS';
		} else if (tableItem.emailPreferred === true && tableItem.phonePreferred !== true) {
			return 'Email';
		} else if (tableItem.emailPreferred !== true && tableItem.phonePreferred === true) {
			return 'SMS';
		} else {
			return null;
		}
	}

	setAlertDetail(data: AlertData) {
		let ad = data.alertProfile;
		if (ad) {
			this.enabled = ad.enabled ?? true;
			this.alertTypes =
				ad.alertTypes?.map((item) => {
					return { checked: true, ...item };
				}) || [];
			this.matchType = ad.matchType ?? MatchType.Any;
			this.devicesSearchFilter.tags.selectionType = this.matchType as MatchType;
			this.locations = ad.locations || [];
			this.tags = ad.tags || [];
			this.recipients =
				ad.recipients?.map((recipient) => {
					return { uuid: this.utils.helpers.generateUUID(), ...recipient };
				}) || [];
			this.updateLocationsFilter(ad.locations || []);
			this.updateTagFilter(ad.tags || []);
			this.alertDetail.setValue({
				accountName: data.accountName || '',
				name: ad.name || '',
				description: ad.description || '',
				enabled: this.enabled,
				matchType: this.matchType,
				alertTypes: this.alertTypes,
				locations: this.locations,
				tags: this.tags,
				recipients: this.recipients,
			});
		}
	}

	submitForm() {
		if (this.alertDetail.valid) this.submit.emit();
	}

	updateLocations(locations: Location[]) {
		this.alertDetail.controls.locations.setValue([...locations]);
		this.updateLocationsFilter(locations);
	}

	updateLocationsFilter(locations: Location[]) {
		this.devicesSearchFilter.locationId.values = [...locations.map((loc) => loc.locationId)];
		this.applySearchFilter();
	}

	updateTags(tags: Tag[]) {
		this.alertDetail.controls.tags.setValue([...tags]);
		this.updateTagFilter(tags);
	}

	updateTagFilter(tags: Tag[]) {
		this.devicesSearchFilter.tags.values = [...tags.map((tag) => tag.tagId)];
		this.applySearchFilter();
	}

	applySearchFilter() {
		this.filteredDevices = this.searchFilterService.helpers.applyFilter(this.unfilteredDevices, this.devicesSearchFilter);
	}

	updateMatchType() {
		this.devicesSearchFilter.tags.selectionType = this.matchType as MatchType;
		this.alertDetail.controls.matchType.setValue(this.matchType);
		this.applySearchFilter();
	}

	touchControls() {
		Object.keys(this.alertDetail.controls).forEach((field) => {
			const control = this.alertDetail.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}

	showSelectedDevicesModal() {
		const modalRef = this.modalService.open(SelectedUnitsListModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modalRef.componentInstance.units = this.filteredDevices;
	}

	sendTestAlert(recipient: AlertRecipient) {
		this.confirmService
			.confirm({
				title: 'Send test notification?',
				text: `Do you want to send a test alert notification to ${recipient.name}?`,
				icon: 'paper-plane',
				confirmButtonText: 'Send Test',
				confirmButtonColor: 'primary',
				confirmButtonIcon: 'paper-plane',
			})
			.then(
				(_) => {
					this.alertProfileService.api
						.sendTest({
							alertName: this.alertDetail.controls.name.value,
							recipientName: recipient.name,
							email: recipient.email,
							phone: recipient.phone,
							emailPreferred: recipient.emailPreferred,
							phonePreferred: recipient.phonePreferred,
						})
						.subscribe({
							next: (result) => {
								this.alertMessageService.success(`Successfully sent test alert notification to ${recipient.name}.`);
							},
							error: (error) => {
								this.alertMessageService.error(`There was an error while sending a test alert notification to ${recipient.name}.`, error);
							},
						});
				},
				(_) => {}
			);
	}

	deleteRecipient(recipient: AlertRecipient) {
		let r: any = recipient;
		this.recipients = this.recipients.filter((item: any) => item.uuid !== r.uuid);
		this.alertDetail.controls.recipients.setValue([...this.recipients]);
	}

	addRecipient(recipient: AlertRecipient) {
		this.editRecipient(recipient);
	}

	editRecipient(recipient: AlertRecipient) {
		const modalRef = this.modalService.open(AlertRecipientModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
		modalRef.componentInstance.recipient = recipient;
		modalRef.result.then(
			(result) => {
				if (!result.uuid) {
					// new recipient
					this.recipients = [...this.recipients, { ...result, uuid: this.utils.helpers.generateUUID() }];
					this.alertDetail.controls.recipients.setValue([...this.recipients]);
					console.log(result);
					this.sendTestAlert(result);
				} else {
					// replace existing
					let index = this.recipients.findIndex((item: any) => item.uuid === result.uuid);
					this.recipients.splice(index, 1, <AlertRecipient>result);
					this.recipients = [...this.recipients]; // need to set it again so it detects changes
					this.alertDetail.controls.recipients.setValue([...this.recipients]);
				}
			},
			(_) => {}
		);
	}

	toggleEnabled(event: ToggleSwitchEvent) {
		this.alertDetail.controls.enabled.setValue(event.value);
	}

	handleAlertTypesChanged(alertTypes: AlertType[]) {
		this.alertDetail?.controls.alertTypes.setValue([...alertTypes]);
	}

	validateName(control: AbstractControl): any {
		let existingNames = this.existingNames;
		let hasSameName = existingNames.find((name) => name === control.value);
		return !!hasSameName ? { hasSameName: true } : null;
	}
}
