<div class="modal-container">
	<div class="modal-header">
		<h4 class="modal-title">
			<fa-icon icon="user-edit"></fa-icon><span class="ps-2">{{ recipient?.uuid ? 'Edit' : 'New' }} Alert Recipient</span>
		</h4>
		<button type="button" class="close" (click)="close()"><fa-icon icon="times" size="lg"></fa-icon></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="recipientForm" (keyup.enter)="save()" ngbAutoFocus>
			<div class="row">
				<div class="col">
					<div class="mb-3 col-6">
						<label for="name" class="form-label">Recipient Name <span class="text-primary">*</span></label>
						<input type="text" class="form-control" id="name" formControlName="name" required />
						<shared-input-validation-messages [control]="recipientForm.controls.name"></shared-input-validation-messages>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="my-2">
						<div class="col">
							<label>Notification Preference <span class="text-primary">*</span></label>
						</div>
					</div>
					<div class="mb-3 d-flex">
						<div class="w-25">
							<div class="col-form-label form-check">
								<input
									type="checkbox"
									class="form-check-input"
									id="emailPreferred"
									formControlName="emailPreferred"
									(change)="toggleAlertEmailDistributionTypes()" />
								<label for="emailPreferred" class="form-check-label">Email Address</label>
							</div>
						</div>
						<div class="w-50">
							<input type="text" class="form-control" id="email" formControlName="email" />
							<shared-input-validation-messages [control]="recipientForm.controls.email"></shared-input-validation-messages>
						</div>
					</div>
					<div *ngIf="showAlertEmailDistributionTypes === true" class="mb-3 d-flex">
						<div class="w-25">
							<div class="col-form-label">
								<label for="alertEmailFrequencyType">Email Frequency</label>
							</div>
						</div>
						<div class="w-50">
							<select class="form-select" id="alertEmailFrequencyType" formControlName="alertEmailFrequencyType">
								<option [ngValue]="type.value" *ngFor="let type of alertEmailFrequencyTypes">{{ type.name }}</option>
							</select>
						</div>
					</div>
					<div class="mb-3 d-flex">
						<div class="w-25">
							<div class="col-form-label form-check">
								<input type="checkbox" class="form-check-input" id="phonePreferred" formControlName="phonePreferred" />
								<label for="phonePreferred" class="form-check-label">SMS Phone Number</label>
							</div>
						</div>
						<div class="w-50">
							<input
								type="text"
								class="form-control"
								id="phone"
								formControlName="phone"
								placeholder="ex. +1-214-555-1234 (country code is required)" />
							<shared-input-validation-messages [control]="recipientForm.controls.phone"></shared-input-validation-messages>
						</div>
					</div>
					<div
						class="w-100 text-danger"
						*ngIf="recipientForm.controls.emailPreferred.value !== true && recipientForm.controls.phonePreferred.value !== true">
						<div class="pt-3 d-block">At least one notification option must be selected.</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary float-right" (click)="cancel()"><fa-icon icon="times"></fa-icon> Cancel</button>
		<button type="button" class="btn btn-primary float-right" (click)="save()" [disabled]="recipientForm.invalid">
			<fa-icon icon="check"></fa-icon> Save
		</button>
	</div>
</div>
