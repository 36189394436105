import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Route, Router, Routes } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { KeyValuePair } from '../data-types/key-value-pair.model';
import { NavBreadcrumb } from '../data-types/nav-breadcrumb.model';
import { NavItem } from '../data-types/nav-item.model';

@Injectable({
	providedIn: 'root',
})
export class NavService {
	public readonly baseUrlValidSubscription: string = '/';
	public readonly baseUrlInvalidSubscription: string = '/devices';

	private store: {
		baseTitle: string;
		appTitle: string;
		appSubTitle: string;
		breadcrumbsTitle: string;
		hasError: boolean;
		navItems: NavItem[];
		navItems$: BehaviorSubject<NavItem[]>;
		navBreadcrumbs: NavBreadcrumb[];
		navBreadcrumbs$: BehaviorSubject<NavBreadcrumb[]>;
		navAllEvents$: BehaviorSubject<Event>;
		navStartEvents$: BehaviorSubject<Event>;
		navEndEvents$: BehaviorSubject<Event>;
		titlePlaceholders: KeyValuePair[];
		authenticatedDefaultUrl: string;
	} = {
		baseTitle: 'Monitor',
		appTitle: 'Monitor',
		appSubTitle: null,
		breadcrumbsTitle: null,
		hasError: undefined,
		navItems: undefined,
		navItems$: new BehaviorSubject(undefined),
		navBreadcrumbs: undefined,
		navBreadcrumbs$: new BehaviorSubject(undefined),
		navAllEvents$: new BehaviorSubject<Event>(undefined),
		navStartEvents$: new BehaviorSubject<Event>(undefined),
		navEndEvents$: new BehaviorSubject<Event>(undefined),
		titlePlaceholders: [],
		authenticatedDefaultUrl: '/',
	};

	public routeParams: {
		accountId: Number;
	} = {
		accountId: undefined,
	};

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private title: Title) {
		// expose router events
		this.router.events
			.pipe(
				map((event) => {
					this.store.navAllEvents$.next(event);
					if (event instanceof NavigationStart) {
						this.store.navStartEvents$.next(event);
					} else if (event instanceof NavigationEnd) {
						this.store.navEndEvents$.next(event);
					}
				})
			)
			.subscribe();

		// update common parameter values
		this.store.navEndEvents$
			.pipe(
				filter((event) => event !== undefined),
				map(() => this.activatedRoute),
				map((route) => route.firstChild),
				switchMap((route) => route.paramMap),
				map((data) => {
					const accountId = data.has('accountId') === true ? Number(data.get('accountId')) : undefined;
					this.routeParams.accountId = isNaN(accountId) !== true && accountId > 0 ? accountId : undefined;
				})
			)
			.subscribe();

		// build breadcrumbs/set title
		this.store.navEndEvents$
			.pipe(
				map(() => {
					// this.store.titlePlaceholders = [];
					this.helpers.buildBreadcrumbs();
					this.helpers.setTitle();
				})
			)
			.subscribe();
	}

	get data() {
		const store = this.store;
		const helpers = this.helpers;

		return {
			get baseTitle(): string {
				return store.baseTitle;
			},
			set baseTitle(value: string) {
				store.baseTitle = value;
			},
			get appTitle(): string {
				return store.appTitle;
			},
			set appTitle(value: string) {
				store.appTitle = value;
			},
			get appSubTitle(): string {
				return store.appSubTitle;
			},
			set appSubTitle(value: string) {
				store.appSubTitle = value;
			},
			get breadcrumbsTitle(): string {
				return store.breadcrumbsTitle;
			},
			set breadcrumbsTitle(value: string) {
				store.breadcrumbsTitle = value;
			},
			get hasError(): boolean {
				return store.hasError;
			},
			set hasError(value: boolean) {
				store.hasError = value;
			},
			get navItems(): NavItem[] {
				return store.navItems;
			},
			set navItems(navItems: NavItem[]) {
				store.navItems = navItems || [];
				store.navItems$.next(store.navItems);
			},
			get navBreadcrumbs(): NavBreadcrumb[] {
				return store.navBreadcrumbs;
			},
			set navBreadcrumbs(navBreadcrumbs: NavBreadcrumb[]) {
				store.navBreadcrumbs = navBreadcrumbs || [];
				store.navBreadcrumbs$.next(store.navBreadcrumbs);
			},
			get authenticatedDefaultUrl(): string {
				return store.authenticatedDefaultUrl ?? '/';
			},
			set authenticatedDefaultUrl(value: string) {
				store.authenticatedDefaultUrl = value ?? '/';
			},
		};
	}

	get streams() {
		const store = this.store;

		return {
			get navItems$(): Observable<NavItem[]> {
				return store.navItems$.asObservable();
			},
			get navBreadcrumbs$(): Observable<NavBreadcrumb[]> {
				return store.navBreadcrumbs$.asObservable();
			},
			get navAllEvents$(): Observable<Event> {
				return store.navAllEvents$.asObservable();
			},
			get navStartEvents$(): Observable<Event> {
				return store.navStartEvents$.asObservable();
			},
			get navEndEvents$(): Observable<Event> {
				return store.navEndEvents$.asObservable();
			},
		};
	}

	get helpers() {
		return {
			buildBreadcrumbs: (): void => {
				const func = (route: ActivatedRoute, url: string = '', breadcrumbs: NavBreadcrumb[] = []): NavBreadcrumb[] => {
					//If no routeConfig is avalailable we are on the root path
					let config: Routes = this.router.config;
					let title = route.routeConfig?.data?.title || '';
					let path = route.routeConfig?.path || '';
					let checkForParent = (breadcrumbs?.length || 0) == 0 && path.length > 0;

					// process placeholders
					this.store.titlePlaceholders.forEach((p: { key: string; value: string }) => {
						title = title.replace(p.key, p.value);
					});

					// set parent link
					if (checkForParent === true) {
						let parentRoute: Route = config.find((r: Route) => r.path === path.substring(0, path.lastIndexOf('/')));
						if (parentRoute != null && parentRoute.path !== path) {
							if (parentRoute?.data != null && parentRoute.data['title']?.length > 0 && parentRoute.path?.length > 0) {
								breadcrumbs.push({ title: parentRoute.data['title'], url: path.substring(0, path.lastIndexOf('/')) });
							}
						}
					}

					// If the route is dynamic route such as ':id', remove it
					const lastRoutePart = path.split('/').pop();
					const isDynamicRoute = lastRoutePart.startsWith(':');
					if (isDynamicRoute && !!route.snapshot) {
						const paramName = lastRoutePart.split(':')[1];
						path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
						// title = ''; // route.snapshot.params[paramName]; EEROR => was replacing page title with parameter
					}

					//In the routeConfig the complete path is not available,
					//so we rebuild it each time
					const nextUrl = path?.length > 0 ? `${url}/${path}` : url;

					const breadcrumb: NavBreadcrumb = {
						title: title,
						url: path?.length > 0 && route.firstChild == null ? nextUrl : undefined,
					};

					// Only adding route with non-empty label
					const newBreadcrumbs = breadcrumb.title ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

					if (route.firstChild != null) {
						//If we are not on our current path yet,
						//there will be more children to look after, to build our breadcumb
						return func(route.firstChild, nextUrl, newBreadcrumbs);
					}
					return newBreadcrumbs;
				};

				const result = func(this.activatedRoute.root);
				this.data.navBreadcrumbs = result;
			},
			setTitle: (): void => {
				let title: string = this.store.appTitle;
				this.data?.navBreadcrumbs?.forEach((item) => {
					let itemTitle: string = item.title;
					if (itemTitle?.length > 0) {
						this.store.titlePlaceholders.forEach((p: { key: string; value: string }) => {
							itemTitle = itemTitle.replace(p.key, p.value);
						});
						itemTitle = itemTitle.replace(/{{.+}}/g, '');
						title += ` > ${itemTitle}`;
					}
				});
				this.title.setTitle(title);
			},
			addTitlePlaceholder: (kvp: KeyValuePair): void => {
				if (this.store.titlePlaceholders.some((ph) => ph.key === kvp.key))
					this.store.titlePlaceholders.find((ph) => ph.key === kvp.key).value = kvp.value;
				else this.store.titlePlaceholders.push(kvp);
				this.helpers.buildBreadcrumbs();
				this.helpers.setTitle();
			},
			removeTitlePlaceholder: (key: string): void => {
				if (this.store.titlePlaceholders.some((ph) => ph.key === key)) {
					this.store.titlePlaceholders = this.store.titlePlaceholders.filter((ph) => ph.key !== key);

					this.helpers.buildBreadcrumbs();
					this.helpers.setTitle();
				}
			},
		};
	}
}
