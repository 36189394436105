import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EnumItem, EnumService } from '@razberi-ui/core/data-types';
import { UtilitiesService } from '@razberi-ui/shared';
import { Subscription, distinctUntilChanged } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-alert-recipient-modal',
	templateUrl: './alert-recipient-modal.component.html',
	styleUrls: ['./alert-recipient-modal.component.scss'],
})
export class AlertRecipientModalComponent {
	@Input() recipient: any;

	recipientForm: UntypedFormGroup;
	subscriptions: Subscription = new Subscription();

	alertEmailFrequencyTypes: EnumItem[];
	showAlertEmailDistributionTypes: boolean = false;

	constructor(private readonly activeModal: NgbActiveModal, public readonly utils: UtilitiesService, public readonly enumService: EnumService) {}

	ngOnInit() {
		this.recipientForm = new UntypedFormGroup(
			{
				name: new UntypedFormControl(null, [Validators.required]),
				email: new UntypedFormControl(null, [this.requiredIf('emailPreferred'), Validators.email]),
				phone: new UntypedFormControl(null, [this.requiredIf('phonePreferred')]),
				emailPreferred: new UntypedFormControl(true),
				phonePreferred: new UntypedFormControl(false),
				alertEmailFrequencyType: new UntypedFormControl(null),
			},
			{ validators: this.preferenceValidator }
		);

		this.alertEmailFrequencyTypes = this.enumService.helpers.getAlertEmailFrequencyTypes();

		this.subscriptions.add(
			this.recipientForm
				.get('emailPreferred')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => this.recipientForm.get('email').updateValueAndValidity())
		);
		this.subscriptions.add(
			this.recipientForm
				.get('phonePreferred')
				.valueChanges.pipe(distinctUntilChanged())
				.subscribe((value) => this.recipientForm.get('phone').updateValueAndValidity())
		);

		this.recipientForm.setValue({
			name: this.recipient?.name || null,
			email: this.recipient?.email || null,
			phone: this.recipient?.phone || null,
			emailPreferred: this.utils.helpers.hasValue(this.recipient?.emailPreferred) ? this.recipient?.emailPreferred : true,
			phonePreferred: this.utils.helpers.hasValue(this.recipient?.phonePreferred) ? this.recipient?.phonePreferred : false,
			alertEmailFrequencyType: this.recipient?.alertEmailFrequencyType || null,
		});

		this.toggleAlertEmailDistributionTypes();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	requiredIf(controlName: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			const c = control.parent?.get(controlName);
			if (c?.value === true && !(control.value && control.valid)) return { requiredIf: controlName };
		};
	}

	preferenceValidator(form: UntypedFormGroup): ValidationErrors {
		return !form.get('emailPreferred').value && !form.get('phonePreferred').value ? { preference: true } : null;
	}

	toggleAlertEmailDistributionTypes() {
		const emailPreferred = this.recipientForm.get('emailPreferred').value;
		if (emailPreferred === true) {
			this.showAlertEmailDistributionTypes = true;
			this.recipientForm.get('alertEmailFrequencyType').setValue(this.recipient?.alertEmailFrequencyType || 0);
		} else {
			this.showAlertEmailDistributionTypes = false;
			this.recipientForm.get('alertEmailFrequencyType').setValue(null);
		}
	}

	save() {
		if (this.recipientForm.valid) this.activeModal.close({ ...this.recipientForm.value, uuid: this.recipient?.uuid });
	}

	cancel() {
		this.activeModal.dismiss('Cancel');
	}

	close() {
		this.activeModal.dismiss('X');
	}
}
