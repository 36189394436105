import { PageSidePanelTab } from './page-side-panel-tab.model';

export interface PageSidePanelConfig {
	panelType: PageSidePanelType;
	title: string;
	activeTabKey: string;
	tabs?: PageSidePanelTab[];
	accountId?: number;
	managedDeviceId?: number;
	managedDeviceRef?: string;
	unitId?: number;
	canEdit?: boolean;
	editAction?: any;
}

export enum PageSidePanelType {
	Unit = 0,
	ManagedDevice = 1,
	Account = 2,
}
