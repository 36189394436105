import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountType, UserRole } from '@razberi-ui/core/data-types';
import { PageConfigService, PageToolbarConfiguration } from '@razberi-ui/shared';
import { Observable, Subject } from 'rxjs';
import { AccountModalComponent } from './account-modal/account-modal.component';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-accounts',
	templateUrl: './accounts.component.html',
	styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent {
	showNewAccountButton: boolean = false;
	isInitialized: boolean = false;
	refreshSubject: Subject<void> = new Subject<void>();
	refresh$: Observable<void> = this.refreshSubject.asObservable();

	constructor(private readonly modalService: NgbModal, private readonly pageConfigService: PageConfigService, private readonly authService: AuthService) {}

	ngOnInit() {
		this.showNewAccountButton =
			this.authService.data.account.type === AccountType.Global ||
			(this.authService.data.account.type === AccountType.Provider && this.authService.helpers.userHasRole(UserRole.Administrator) == true);
		if (this.showNewAccountButton === true) {
			const c: PageToolbarConfiguration = {
				buttons: [
					{
						text: 'New Account',
						icon: 'plus',
						color: 'primary',
						click: () => {
							this.createAccount();
						},
					},
				],
			};
			this.pageConfigService.data.toolbarConfig = c;
		}
		this.isInitialized = true;
	}

	createAccount() {
		const modal = this.modalService.open(AccountModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
		modal.result.then(
			(result) => {
				this.refreshSubject.next();
			},
			(_) => {}
		);
	}

	onUpdate() {
		this.refreshSubject.next();
	}
}
