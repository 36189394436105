import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
	AccountType,
	ManagedDevice,
	ManagedDeviceStatus,
	ManagedDeviceType,
	Network,
	NetworkLink,
	NetworkLinkStatus,
	UserRole,
} from '@razberi-ui/core/data-types';
import { AlertMessageService, ConfirmService, PageSidePanelService, UtilitiesService } from '@razberi-ui/shared';
import { DeviceEditModalComponent } from '../../devices/device-edit-modal/device-edit-modal.component';
import { NetworkLinkDetailModalComponent } from '../network-link-detail-modal/network-link-detail-modal.component';
import { NetworkService } from '../../../services/network.service';
import * as d3 from 'd3';
import { AuthService } from '@razberi-ui/api/cloud-api';

@Component({
	selector: 'app-root-monitor-cloud-network-d3-tree-diagram',
	templateUrl: './network-d3-tree-diagram.component.html',
	styleUrls: ['./network-d3-tree-diagram.component.scss'],
})
export class NetworkD3TreeDiagramComponent {
	@ViewChild('tree', { static: true }) tree?: ElementRef;
	@ViewChild('dropdown', { static: true }) dropdown?: ElementRef;

	@Input() rootId: number;
	@Input() accountId: number;
	@Input() network: Network;
	@Input() width: number;
	@Input() height: number;
	@Input() readonly: boolean;
	@Input() drawLegendOnly: boolean;
	@Input() legendType: string;

	@Output() networkChanged: EventEmitter<Network> = new EventEmitter<Network>();

	internalDevices: ManagedDevice[] = [];
	showMenu: boolean = false;
	selectedNode: any;
	deviceDetailsConfig: any;
	deviceDetailsVisible: boolean = false;
	canManageNetworks: boolean = false;
	canManageDevices: boolean = false;

	@HostListener('click', ['$event'])
	clicked($event: MouseEvent) {
		this.hideMenu();
	}
	@HostListener('document:click', ['$event'])
	clickedOut($event: MouseEvent) {
		this.hideMenu();
	}

	constructor(
		private readonly authService: AuthService,
		private readonly networkService: NetworkService,
		private readonly modalService: NgbModal,
		private readonly renderer: Renderer2,
		private readonly confirmService: ConfirmService,
		private readonly alertMessageService: AlertMessageService,
		private readonly pageSidePanelService: PageSidePanelService,
		private readonly utils: UtilitiesService
	) {}

	ngOnInit(): void {
		this.canManageNetworks = this.authService.helpers.userHasRole(UserRole.Administrator) == true;
		this.canManageDevices =
			this.authService.data.account.type !== AccountType.Customer || this.authService.helpers.userHasRole(UserRole.Administrator) == true;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.accountId = this.accountId > 0 ? this.accountId : this.authService.data.account?.accountId;
		this.processNetwork();
	}

	processNetwork() {
		if (this.drawLegendOnly === true) {
			this.drawNetworkLegend();
			return;
		}

		if (
			this.network == null ||
			(this.network.networkId == 0 && ((this.network.rootUnitKey?.length || 0) == 0 || (this.network.rootDeviceKey?.length || 0) == 0))
		) {
			this.network = this.networkService.helpers.getDefaultNetwork(this.accountId);
			this.drawEmptyNetwork();
		} else {
			this.internalDevices = this.network.managedDevices?.length > 0 ? JSON.parse(JSON.stringify(this.network.managedDevices)) : [];

			var root = this.internalDevices.find((d) => d.unitKey === this.network.rootUnitKey && d.managedDeviceKey === this.network.rootDeviceKey);
			if (root == null) {
				root = this.getMissingManagedDevice(this.network.rootUnitKey, this.network.rootDeviceKey);
				this.internalDevices?.push(root);
			}

			// add missing device placeholders
			this.network?.networkLinks?.map((nl) => {
				let parent = this.internalDevices.find((d) => d.unitKey === nl.parentUnitKey && d.managedDeviceKey === nl.parentManagedDeviceKey);
				if (parent == null) this.internalDevices?.push(this.getMissingManagedDevice(nl.parentUnitKey, nl.parentManagedDeviceKey));
				let child = this.internalDevices.find((d) => d.unitKey === nl.childUnitKey && d.managedDeviceKey === nl.childManagedDeviceKey);
				if (child == null) this.internalDevices?.push(this.getMissingManagedDevice(nl.childUnitKey, nl.childManagedDeviceKey));
			});

			this.internalDevices?.map((d) => (d.networkGuid = this.utils.helpers.generateUUID()));
			root.isNetworkRoot = true;
			root.networkParentGuid = null;
			this.network.networkLinks?.map((l: NetworkLink) => {
				var child = this.internalDevices.find((d) => d.unitKey === l.childUnitKey && d.managedDeviceKey === l.childManagedDeviceKey);
				if (child != null) {
					child.isNetworkRoot = false;
					var parent = this.internalDevices.find((d) => d.unitKey === l.parentUnitKey && d.managedDeviceKey === l.parentManagedDeviceKey);
					child.networkParentGuid = parent.networkGuid;
				}
			});

			this.drawPopulatedNetwork();
		}
	}

	drawNetworkLegend() {
		if (this.legendType === 'link-statuses') {
			let margin = { top: 0, right: 0, bottom: 0, left: 0 };
			let element: any = this.tree?.nativeElement;
			this.width = 210;
			this.height = 100;

			// reset div containing SVQ
			this.renderer.setProperty(this.tree?.nativeElement, 'innerHTML', null);
			// load SVG
			let svg = d3
				.select(element)
				.append('svg')
				.attr('width', this.width)
				.attr('height', this.height)
				.attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
				.append('g')
				.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

			svg.append('line')
				.attr('x1', 100)
				.attr('y1', 5)
				.attr('x2', 210)
				.attr('y2', 5)
				.attr('class', (d: any) => {
					return this.getLinkCssClass(NetworkLinkStatus.Connected);
				})
				.style('stroke-width', 2)
				.style('stroke-dasharray', (d: any) => {
					return this.getLinkStrokeArray(NetworkLinkStatus.Connected);
				});

			svg.append('text').attr('dx', 0).attr('dy', 10).attr('font-size', 14).text('Connected');

			svg.append('line')
				.attr('x1', 100)
				.attr('y1', 35)
				.attr('x2', 210)
				.attr('y2', 35)
				.attr('class', (d: any) => {
					return this.getLinkCssClass(NetworkLinkStatus.Unavailable);
				})
				.style('stroke-width', 2)
				.style('stroke-dasharray', (d: any) => {
					return this.getLinkStrokeArray(NetworkLinkStatus.Unavailable);
				});

			svg.append('text').attr('dx', 0).attr('dy', 40).attr('font-size', 14).text('Unavailable');

			svg.append('line')
				.attr('x1', 100)
				.attr('y1', 65)
				.attr('x2', 210)
				.attr('y2', 65)
				.attr('class', (d: any) => {
					return this.getLinkCssClass(NetworkLinkStatus.Disconnected);
				})
				.style('stroke-width', 2)
				.style('stroke-dasharray', (d: any) => {
					return this.getLinkStrokeArray(NetworkLinkStatus.Disconnected);
				});

			svg.append('text').attr('dx', 0).attr('dy', 70).attr('font-size', 14).text('Disconnected');

			svg.append('line')
				.attr('x1', 100)
				.attr('y1', 95)
				.attr('x2', 210)
				.attr('y2', 95)
				.attr('class', (d: any) => {
					return this.getLinkCssClass(null);
				})
				.style('stroke-width', 2)
				.style('stroke-dasharray', (d: any) => {
					return this.getLinkStrokeArray(null);
				});

			svg.append('text').attr('dx', 0).attr('dy', 100).attr('font-size', 14).text('Unknown');
		} else if (this.legendType === 'device-types') {
			let margin = { top: 0, right: 0, bottom: 0, left: 0 };
			let element: any = this.tree?.nativeElement;
			this.width = 210;
			this.height = 60;

			// reset div containing SVQ
			this.renderer.setProperty(this.tree?.nativeElement, 'innerHTML', null);
			// load SVG
			let svg = d3
				.select(element)
				.append('svg')
				.attr('width', this.width)
				.attr('height', this.height)
				.attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
				.append('g')
				.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

			svg.append('rect')
				.attr('x', 150)
				.attr('y', 1)
				.attr('width', 14)
				.attr('height', 14)
				.attr('fill', 'none')
				.attr('stroke', '#212529')
				.attr('stroke-width', '1');

			svg.append('text').attr('x', 0).attr('y', 10).attr('font-size', 14).text('Agent');

			svg.append('circle').attr('cx', 157).attr('cy', 36).attr('r', 8).attr('fill', 'none').attr('stroke', '#212529').attr('stroke-width', '1');

			svg.append('text').attr('x', 0).attr('y', 40).attr('font-size', 14).text('Managed Device');
		}
	}

	drawEmptyNetwork() {
		let margin = { top: 0, right: 0, bottom: 0, left: 0 };
		let element: any = this.tree?.nativeElement;
		let root: any;
		const network = [{ name: 'Root Device', managedDeviceId: 0, networkParentGuid: null, isNetworkRoot: true }];

		let stratify = d3
			.stratify()
			.id((d: any) => d.networkGuid)
			.parentId((d: any) => d.networkParentGuid);
		root = stratify(network);
		const tree = this.getTree(root);

		// reset div containing SVQ
		this.renderer.setProperty(this.tree?.nativeElement, 'innerHTML', null);
		// load SVG
		let svg = d3
			.select(element)
			.append('svg')
			.attr('width', this.width)
			.attr('height', this.height)
			.attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// create nodes
		let node = svg
			.selectAll('.node')
			.data(root)
			.enter()
			.append('g')
			.attr('class', 'node')
			.attr('transform', (d: any) => {
				return 'translate(' + (d.y + 65) + ',' + d.x + ')';
			});
		node.append('circle')
			.attr('r', 20)
			.attr('class', (d: any) => {
				return this.getNodeCssClass(d);
			});
		node.append('text')
			.attr('dx', -35)
			.attr('dy', -35)
			.attr('font-size', 12)
			.attr('font-weight', 'bold')
			.text((d: any) => `${d.data?.name}`);
		node.append('text')
			.attr('dx', -11)
			.attr('dy', 8)
			.attr('font-size', 32)
			.attr('font-weight', 'bold')
			.attr('stroke', 'white')
			.attr('fill', 'white')
			.text((d: any) => '+');

		if (this.readonly !== true) {
			node.append('circle')
				.attr('r', 20)
				.attr('fill', 'transparent')
				.classed('cursor-pointer', true)
				.on('click', (d, i) => this.clickTreeNode(d, i))
				.on('contextmenu', (d, i) => {
					d.preventDefault();
					this.clickTreeNode(d, i);
				});
		}
	}

	getTree(root) {
		const widthFactor = this.readonly === true ? 150 : root.height < 3 ? 250 : root.height > 6 ? 150 : 200;
		this.width = this.internalDevices?.length < 1 ? 125 : root.height * widthFactor + widthFactor + widthFactor;
		this.height = this.calculateRootMaxHeight(root);

		const tree = d3
			.tree()
			.size([this.internalDevices?.length < 1 ? this.height : this.height * 0.85, this.internalDevices?.length < 1 ? this.width : this.width * 0.7])(
			root
		);

		return tree;
	}

	calculateRootMaxHeight(root) {
		// const root = this.internalData?.find(d => d.Id === this.rootId);
		let heightFactor = 0;
		let keys: any[] = [];
		let toProcess = [root];
		while (true) {
			let processNext = [];
			toProcess?.map((device) => {
				let entry: any = { id: device.data?.managedDeviceId, depth: device.depth + 1 };
				keys.push(entry);
				const children = device.children;
				if (children?.length > 0) processNext = processNext.concat(children);
			});

			toProcess = [].concat(processNext);
			if (toProcess?.length == 0) break;
		}

		const grouped = this.groupBy(keys, (key) => key.depth);
		const array = Array.from(grouped)?.map((obj) => {
			return { depth: obj[0], objects: obj[1] };
		});
		if (array.some((g) => g.objects?.length > 1) != true) return 250;
		const factor = Math.max(...array.map((obj) => obj.depth * 0.75 * (obj.objects?.length ?? 1)));
		return 100 + factor * 50;
	}

	groupBy(list, keyGetter) {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	}

	drawPopulatedNetwork() {
		let margin = { top: 10, right: 10, bottom: 10, left: 10 };
		let element: any = this.tree?.nativeElement;
		let root: any;

		// set hierarchical structure
		let stratify = d3
			.stratify()
			.id((d: any) => d.networkGuid)
			.parentId((d: any) => d.networkParentGuid);
		root = stratify(this.internalDevices);

		let diagonal = d3
			.linkHorizontal()
			.x((d: any) => {
				return d.y;
			})
			.y((d: any) => {
				return d.x;
			});

		const tree = this.getTree(root);

		// reset div containing SVQ
		this.renderer.setProperty(this.tree?.nativeElement, 'innerHTML', null);
		// load SVG
		let svg = d3
			.select(element)
			.append('svg')
			.attr('width', this.width)
			.attr('height', this.height)
			.attr('viewBox', '0 0 ' + this.width + ' ' + this.height)
			//.attr("style", "border: 1px solid black")
			//.attr("viewBox", "0 0 300 250")
			//.attr("preserveAspectRatio", "xMinYMid slice")
			//.attr("viewBox", "0 0 1250 750")
			//.classed("svg-content-responsive", true)
			.append('g')
			//.attr("transform", "translate(0,0)");
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// create links
		let links = tree.links();
		svg.selectAll('.link')
			.data(links)
			.enter()
			.append('path')
			.attr('class', (d: any) => {
				let link = this.network?.networkLinks?.find(
					(nl) =>
						nl.parentUnitKey === d?.source?.data?.unitKey &&
						nl.parentManagedDeviceKey === d?.source?.data?.managedDeviceKey &&
						nl.childUnitKey === d?.target?.data?.unitKey &&
						nl.childManagedDeviceKey === d?.target?.data?.managedDeviceKey
				);
				return link == null ? this.getLinkCssClass(null) : this.getLinkCssClass(link.status);
			})
			.style('stroke-dasharray', (d: any) => {
				let link = this.network?.networkLinks?.find(
					(nl) =>
						nl.parentUnitKey === d?.source?.data?.unitKey &&
						nl.parentManagedDeviceKey === d?.source?.data?.managedDeviceKey &&
						nl.childUnitKey === d?.target?.data?.unitKey &&
						nl.childManagedDeviceKey === d?.target?.data?.managedDeviceKey
				);
				return link == null ? this.getLinkStrokeArray(null) : this.getLinkStrokeArray(link.status);
			})
			.attr('d', (d: any) => {
				return diagonal(d);
			});

		// create nodes
		let node = svg
			.selectAll('.node')
			.data(root)
			.enter()
			.append('g')
			.attr('class', 'node')
			.attr('transform', (d: any) => {
				return 'translate(' + d.y + ',' + d.x + ')';
			});

		node.filter(function (d: any, i) {
			return d.data.type !== ManagedDeviceType.Agent;
		})
			.append('circle')
			.attr('r', 8)
			.attr('class', (d: any) => {
				return this.getNodeCssClass(d);
			});
		node.filter(function (d: any, i) {
			return d.data.type !== ManagedDeviceType.Agent;
		})
			.append('circle')
			.attr('r', 8)
			.attr('fill', 'none')
			.attr('stroke', '#212529')
			.attr('stroke-width', '1');

		node.filter(function (d: any, i) {
			return d.data.type === ManagedDeviceType.Agent;
		})
			.append('rect')
			.attr('x', -7)
			.attr('y', -7)
			.attr('width', 14)
			.attr('height', 14)
			.attr('class', (d: any) => {
				return this.getNodeCssClass(d);
			});
		node.filter(function (d: any, i) {
			return d.data.type === ManagedDeviceType.Agent;
		})
			.append('rect')
			.attr('x', -7)
			.attr('y', -7)
			.attr('width', 14)
			.attr('height', 14)
			.attr('fill', 'none')
			.attr('stroke', '#212529')
			.attr('stroke-width', '1');

		if (this.readonly !== true) {
			node.append('circle')
				.attr('dx', 7)
				.attr('dy', -7)
				.attr('r', 14)
				.attr('fill', 'transparent')
				.classed('cursor-pointer', true)
				.on('click', (d, i) => this.clickTreeNode(d, i))
				.on('contextmenu', (d, i) => {
					d.preventDefault();
					this.clickTreeNode(d, i);
				});
		}

		node.append('text')
			.attr('x', 0)
			.attr('y', 27)
			.attr('class', 'd3-label')
			.text((d: any) => `${d.data?.name?.length > 20 ? d.data?.name?.substring(0, 20) : d.data?.name}${d.data?.name?.length > 20 ? '...' : ''}`);

		node.filter(function (d: any, i) {
			return d.data.processedLocationName?.length > 0;
		})
			.append('text')
			.attr('x', 0)
			.attr('y', 44)
			.attr('class', 'd3-label')
			.text(
				(d: any) =>
					`${d.data.processedLocationName?.length > 20 ? d.data.processedLocationName?.substring(0, 20) : d.data.processedLocationName}${
						d.data.processedLocationName?.length > 20 ? '...' : ''
					}`
			);
	}

	clickTreeNode(event: MouseEvent, node: any) {
		event.stopPropagation();
		if (node?.data?.managedDeviceId > 0 && node?.data?.managedDeviceId === this.selectedNode?.data?.managedDeviceId) {
			this.hideMenu();
		} else {
			this.selectedNode = node;
			if (node?.data?.managedDeviceKey?.length > 0) {
				let element: any = this.dropdown?.nativeElement;
				element.style.left = event.pageX + 'px';
				element.style.top = event.pageY + 'px';
				this.showMenu = true;
			} else {
				this.addManagedDevice(this.selectedNode);
			}
		}
	}

	getNodeCssClass(node: any) {
		const color = this.networkService.helpers.getNetworkDeviceStatusCssColor(node?.data?.status);
		return `svg-network-node-status-${color}`;
	}

	getLinkCssClass(linkStatus: NetworkLinkStatus) {
		const color = this.networkService.helpers.getNetworkLinktatusCssColor(linkStatus);
		return `svg-network-link-status-${color}`;
	}

	getLinkStrokeArray(linkStatus: NetworkLinkStatus) {
		return this.networkService.helpers.getNetworkLinkSvgStrokeArray(linkStatus);
	}

	hideMenu() {
		this.selectedNode = null;
		this.showMenu = false;
	}

	showNotWorkingYet(selectedNode) {
		this.alertMessageService.warning('This functionality has not been implemented yet.');
	}

	editManagedDevice(selectedNode) {
		const modal = this.modalService.open(DeviceEditModalComponent, { backdrop: 'static', centered: true, size: 'md' });
		modal.componentInstance.accountId = this.accountId;
		modal.componentInstance.network = this.network;
		modal.componentInstance.unitId = selectedNode?.data?.unitId;
		modal.componentInstance.managedDeviceData = selectedNode?.data?.managedDeviceId > 0 ? selectedNode?.data : null;

		modal.result.then(
			(result) => {
				this.networkChanged.emit(result);
				this.processNetwork();
			},
			(rejected) => {}
		);
	}

	addManagedDevice(selectedNode) {
		const modal = this.modalService.open(NetworkLinkDetailModalComponent, { backdrop: 'static', centered: true, size: 'xl' });
		modal.componentInstance.accountId = this.accountId;
		modal.componentInstance.network = this.network;
		modal.componentInstance.sourceDevice = selectedNode?.data?.managedDeviceId > 0 ? selectedNode?.data : null;
		modal.result.then(
			(result) => {
				this.networkChanged.emit(result);
				this.processNetwork();
			},
			(rejected) => {}
		);
	}

	removeManagedDevice(selectedNode) {
		if (selectedNode?.data == null) {
			this.alertMessageService.error('The selected device is not valid.');
			return;
		}

		this.confirmService
			.confirm({
				title: 'Confirm Remove Device',
				html: "The selected device and all of it's children will be removed from this network.  Would you like to continue?",
				icon: 'exclamation-triangle',
				confirmButtonText: 'Remove',
				cancelButtonText: 'Cancel',
				confirmButtonColor: 'primary',
			})
			.then(
				(_) => {
					this.networkService.helpers.removeDevice(this.network, selectedNode?.data);
					this.internalDevices = this.internalDevices?.filter((device) => device.managedDeviceKey !== selectedNode?.data.managedDeviceKey) || [];
					this.networkChanged.emit(this.network);
					this.processNetwork();
				},
				(_) => {}
			);
	}

	setNetworkRoot(selectedNode) {
		if (selectedNode?.data == null) {
			this.alertMessageService.error('The selected device is not valid.');
			return;
		}

		this.confirmService
			.confirm({
				title: 'Confirm New Network Root',
				html: 'The selected device will become the new root of this network.  Would you like to continue?',
				icon: 'exclamation-triangle',
				confirmButtonText: 'Set as Root',
				cancelButtonText: 'Cancel',
				confirmButtonColor: 'primary',
			})
			.then(
				(_) => {
					this.networkService.helpers.setNetworkRoot(this.network, selectedNode?.data);
					this.networkChanged.emit(this.network);
					this.processNetwork();
				},
				(_) => {}
			);
	}

	viewDeviceDetails(selectedNode) {
		selectedNode?.data?.type === ManagedDeviceType.Agent
			? this.openUnitSidePanel(selectedNode?.data, 'details')
			: this.openManagedDeviceSidePanel(selectedNode?.data, 'details');
	}

	viewDeviceAlerts(selectedNode) {
		selectedNode?.data?.type === ManagedDeviceType.Agent
			? this.openUnitSidePanel(selectedNode?.data, 'alerts')
			: this.openManagedDeviceSidePanel(selectedNode?.data, 'alerts');
	}

	openUnitSidePanel(managedDevice: ManagedDevice, activeTabKey: string) {
		this.pageSidePanelService.helpers.openUnitSidePanel(
			managedDevice.unit?.name ?? '--',
			activeTabKey,
			managedDevice.accountId,
			managedDevice.unitId,
			false,
			null
		);
	}

	openManagedDeviceSidePanel(managedDevice: ManagedDevice, activeTabKey: string) {
		this.pageSidePanelService.helpers.openManagedDeviceSidePanelById(
			managedDevice.name,
			activeTabKey,
			managedDevice.accountId,
			managedDevice.unitId,
			managedDevice.managedDeviceId
		);
	}

	getMissingManagedDevice(unitKey: string, managedDeviceKey: string): ManagedDevice {
		return {
			managedDeviceId: 0,
			managedDeviceKey: managedDeviceKey,
			accountId: 0,
			unitId: 0,
			unitKey: unitKey,
			name: `${this.utils.formatters.asMacAddress(managedDeviceKey)}`,
			type: 0,
			status: ManagedDeviceStatus.Unavailable,
			statusMessage: null,
			location: null,
			manufacturer: null,
			modelName: null,
			serialNumber: null,
			macAddress: null,
			ipAddress: null,
			subnetMask: null,
			gateway: null,
			dnsServer: null,
			dhcpServerEnabled: null,
			customData: null,
			ports: 0,
			firmware: null,
			connectionType: null,
			registerTimestamp: null,
			updateTimestamp: null,
			createTimestamp: null,
			lldpNeighbors: [],
			alerts: [],
			unit: null,
			processedLocationName: null,
		};
	}
}
