import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { NavService, PagePortalService } from '@razberi-ui/shared';
import { NavigationStart, Router } from '@angular/router';
import { DashboardDto, CoreServerSettingsService } from '@razberi-ui/api/cloud-api';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
	selector: 'app-root-monitor-cloud-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	@ViewChild('slickModal') slickCarousel: SlickCarouselComponent;

	config = {
		slidesToShow: 6,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		infinite: false,
		speed: 300,
		prevArrow: "<img class='slick-prev' src='assets/core/images/svg/angle-left.svg'>",
		nextArrow: "<img class='slick-next' src='assets/core/images/svg/angle-right.svg'>",
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1250,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};

	activeCards = [
		{ templateName: 'server-health' },
		{ templateName: 'vms-health' },
		{ templateName: 'iot-health' },
		{ templateName: 'cybersecurity-posture' },
		{ templateName: 'manage-tasks' },
		{ templateName: 'offline-devices' },
		{ templateName: 'total-alerts' },
	];

	subscriptions: Subscription = new Subscription();
	dashboardData: DashboardDto;
	showSlick = true;

	constructor(
		private readonly dashboardService: DashboardService,
		private readonly navService: NavService,
		readonly settingsService: CoreServerSettingsService,
		private readonly pagePortalService: PagePortalService,
		private readonly router: Router
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.navService.streams.navStartEvents$.subscribe((event: NavigationStart) => {
				if (event.url.startsWith('/dashboard') !== true) this.pagePortalService.clearOutlet('toolbarOutlet');
			})
		);

		this.subscriptions.add(
			this.dashboardService.streams.isInitialized$.subscribe((isInitialized: boolean) => {
				if (isInitialized === true) {
					this.getDashboardData();
				}
			})
		);

		this.subscriptions.add(
			this.dashboardService.streams.dashboardData$.subscribe((dashboardData: DashboardDto) => {
				this.dashboardData = dashboardData;
			})
		);

		this.dashboardService.helpers.initialize();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.slickCarousel) {
			// hide slick
			this.showSlick = false;
			// remove slides
			this.slickCarousel.slides.forEach((s) => this.slickCarousel.removeSlide(s));
			this.slickCarousel.slides = [];
			// remove slick
			this.slickCarousel.unslick();

			// need a timeout here or slick won't re-initialize
			setTimeout(() => {
				if (this.slickCarousel.initialized !== true) {
					// init slick
					this.slickCarousel.initSlick();
					this.showSlick = true;
				}
			}, 0);
		}
	}

	onParamsChanged($event) {
		this.getDashboardData();
	}

	getDashboardData() {
		const filter = this.dashboardService.helpers.getSearchFilter();
		this.dashboardService.api.getDashboardData(filter);
	}

	navigate(path) {
		this.router.navigate(['/dashboard', path]);
	}
}
