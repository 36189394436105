<div class="fs-4 mb-2">Agents Without Malware Protection</div>
<app-root-monitor-cloud-unit-table
	[showLocation]="false"
	[showTags]="false"
	[showAccount]="true"
	[showCameraDefenseStatus]="true"
	[showMalwareProtectionStatus]="true"
	[showUpdates]="false"
	[dataSource]="'dashboard-malware-protection'">
</app-root-monitor-cloud-unit-table>
