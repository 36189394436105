<div class="container-fluid">
	<form [formGroup]="networkForm" autocomplete="off" novalidate>
		<div class="w-100 mt-4">
			<ng-container *ngIf="isInitialized !== true">
				<shared-context-message>Loading network.</shared-context-message>
			</ng-container>
			<ng-container *ngIf="isInitialized === true">
				<ng-container *ngIf="(network?.networkId || 0) === 0">
					<h3 class="mb-4">Create New Network</h3>
					<shared-context-message *ngIf="availableAccounts.length === 0" context="info" class="d-flex justify-content-center mb-3">
						There are no accounts available with a valid 'Manage' or 'Trial' subscription.
					</shared-context-message>
					<ng-container *ngIf="availableAccounts?.length > 1 && isGlobalOrProviderAccount === true">
						<div class="d-flex justify-content-center mb-3">
							<div style="width: 50%">
								<div class="d-flex">
									<label for="accountId" class="form-label">Account <span class="text-primary">*</span></label>
									<div
										*ngIf="showTooltip"
										placement="right"
										container="body"
										class="ms-1"
										ngbTooltip="A network view can only be created for accounts with valid 'Manage' or 'Trial' subscriptions.">
										<fa-icon icon="info-circle" class="text-primary" size="md"></fa-icon>
									</div>
								</div>
								<select
									class="form-select"
									id="accountId"
									name="accountId"
									formControlName="accountId"
									(change)="networkAccountChanged($event)"
									required>
									<option *ngFor="let account of availableAccounts" [ngValue]="account.accountId">{{ account.name }}</option>
								</select>
								<shared-input-validation-messages [control]="networkForm.controls.accountId"></shared-input-validation-messages>
							</div>
						</div>
					</ng-container>
					<ng-container *ngTemplateOutlet="titleRow; context: { form: networkForm }"></ng-container>
					<ng-container *ngTemplateOutlet="diagram; context: {}"></ng-container>
				</ng-container>
				<ng-container *ngIf="network?.networkId > 0">
					<div *ngIf="networkIsModified === true" class="mb-3">
						<shared-context-message type="info">Network contains unsaved changes.</shared-context-message>
					</div>
					<ng-container *ngTemplateOutlet="titleRow; context: { form: networkForm }"></ng-container>
					<ng-container *ngTemplateOutlet="detail; context: {}"></ng-container>
				</ng-container>
			</ng-container>
		</div>
	</form>
</div>

<ng-template #detail>
	<ul ngbNav #navDetail="ngbNav" activeId="diagram" class="nav-tabs page-content-tabs mt-3" orientation="horizontal">
		<li ngbNavItem="diagram">
			<a ngbNavLink><fa-icon icon="chart-network" class="me-3" size="lg"></fa-icon>Diagram</a>
			<ng-template ngbNavContent>
				<ng-container *ngTemplateOutlet="diagram; context: {}"></ng-container>
			</ng-template>
		</li>
		<li ngbNavItem="alerts">
			<a ngbNavLink><fa-icon icon="bell" class="me-3" size="lg"></fa-icon>Alerts ({{ alertsTotal || 0 }})</a>
			<ng-template ngbNavContent>
				<div class="pt-3">
					<shared-context-message type="info">Alerts shown for last 30 days</shared-context-message>
					<app-root-monitor-cloud-alert-table
						[accountIds]="[network?.accountId]"
						[network]="network"
						dataSource="network-detail"></app-root-monitor-cloud-alert-table>
				</div>
			</ng-template>
		</li>
		<li ngbNavItem="devices">
			<a ngbNavLink><fa-icon icon="server" class="me-3" size="lg"></fa-icon>Devices ({{ devicesTotal || 0 }})</a>
			<ng-template ngbNavContent>
				<div class="pt-3">
					<shared-context-message *ngIf="(network?.managedDevices?.length || 0) == 0" context="info"
						>No managed devices could be found</shared-context-message
					>
					<app-root-monitor-cloud-managed-device-table
						*ngIf="network?.managedDevices?.length > 0"
						[accountIds]="[network?.accountId]"
						[showDownloadCsv]="showDownloadCsv(account)"
						[network]="network"
						dataSource="network-detail"></app-root-monitor-cloud-managed-device-table>
				</div>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="navDetail"></div>
</ng-template>

<ng-template #titleRow let-form="form">
	<div class="d-flex align-items-center" [formGroup]="form">
		<div class="flex-1">
			<div
				*ngIf="editNetworkName === true || (network?.networkId || 0) === 0"
				class="d-flex align-items-center"
				[ngStyle]="{ 'justify-content': network?.networkId > 0 ? 'left' : 'center' }">
				<div style="width: 50%">
					<div class="d-flex align-items-top">
						<div class="w-100">
							<label *ngIf="(network?.networkId || 0) === 0" for="name" class="form-label"
								>Network Name <span class="text-primary">*</span></label
							>
							<input type="text" class="form-control" formControlName="name" id="name" required />
							<shared-input-validation-messages [control]="networkForm.controls.name"></shared-input-validation-messages>
						</div>
						<div *ngIf="network?.networkId > 0" class="ms-3 mt-2">
							<fa-icon *ngIf="network?.networkId > 0" icon="times" size="xl" class="cursor-pointer" (click)="resetEditNetworkName()"></fa-icon>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="editNetworkName !== true && network?.networkId > 0 && network?.name != null" class="d-flex align-items-center">
				<h3>{{ network.name }}</h3>
				<fa-icon icon="pencil" size="xl" class="ms-3 cursor-pointer" (click)="editNetworkName = true"></fa-icon>
			</div>
		</div>
		<div *ngIf="network?.networkId > 0" class="d-flex align-content-right align-items-center">
			<div class="me-3">
				<app-root-monitor-cloud-network-d3-tree-legend></app-root-monitor-cloud-network-d3-tree-legend>
			</div>
			<div class="d-flex me-3">
				<span title="Total Devices: {{ network?.deviceTotalCount || 0 }}" class="status-dot status-bg-none status-color me-1 cursor-default">{{
					network?.deviceTotalCount || 0
				}}</span>
				<span
					*ngIf="network?.totalErrorCount > 0"
					title="Network Issues: {{ network?.totalErrorCount || 0 }}"
					class="status-dot status-bg-red status-color me-1 cursor-default"
					>{{ network?.totalErrorCount || 0 }}</span
				>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #diagram>
	<ng-container *ngIf="network != null">
		<div class="text-center">
			<app-root-monitor-cloud-network-d3-tree-diagram
				[accountId]="accountId"
				[network]="network"
				(networkChanged)="networkChanged($event)"></app-root-monitor-cloud-network-d3-tree-diagram>
		</div>
		<div class="text-center">
			<div *ngIf="(networkForm?.value?.rootDeviceKey?.length || 0) === 0" class="d-block text-muted">(Click to set Root Device)</div>
			<shared-input-validation-messages [control]="networkForm.controls.rootDeviceKey"></shared-input-validation-messages>
		</div>
	</ng-container>
</ng-template>
